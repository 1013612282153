import type { ChangeEventValue, Coords } from "google-map-react";
import React, { useEffect, useState } from "react";
import MapPolygonStyles from "../../constants/MapPolygonStyles";
import Location from "../../interfaces/Location";
import SpotRemark from "../../interfaces/SpotRemark";
import type { TownBorder } from "../../interfaces/TownBorder";
import SpotReportMapSmallMarker from "../SpotReportMapSmallMarker";
import GmapsUI, { PolygonByH3Index, PolygonByWkt } from "../ui/GmapsUI";
import type { MapApi } from "../ui/GmapsUI";
import MAPActionsContentForSpotRemark from "./controls/MAPActionsContentForSpotRemark";
import MAPDrawerContent from "./controls/MAPDrawerContent";
import MAPIndicatorContent from "./controls/MAPIndicatorContent";
import MAPModalContent from "./controls/MAPModalContent";
import MAPToolboxContentForDestLatLng from "./controls/MAPToolboxContentForDestLatLng";
import MAPToolboxContentForHex from "./controls/MAPToolboxContentForHex";
import MAPToolboxContentForPaths from "./controls/MAPToolboxContentForPaths";
import MAPToolboxContentForRadius from "./controls/MAPToolboxContentForRadius";
import MAPToolboxContentForSpotMarker from "./controls/MAPToolboxContentForSpotMarker";
import MAPToolboxContentForSpotRemark from "./controls/MAPToolboxContentForSpotRemark";
import useMapAreaPicker from "./hooks/useMapAreaPicker";
import usePathMode from "./hooks/usePathModeForSpotRemark";
import useHexMode from "./hooks/useHexMode";
import useTownBordersMode from "./hooks/useTownBordersMode";
import useRadiusMode from "./hooks/useRadiusMode";

export type PickMode =
  | "Paths"
  | "TownBorder"
  | "Hex"
  | "Radius"
  | "Spot"
  | "Dest";

const DEFAULT_PICK_MODE: PickMode = "Paths";

interface Props {
  gmapsApiKey: string;
  center: Coords;
  zoom?: number;
  wkt: string;
  unEditableWkt?: string | null;
  showToast?: boolean;
  notNull?: boolean;
  editable: boolean;
  spot?: SpotRemark;
  locationsForHeatMap?: Location[];
  onUpdateByWkt?: (multiPolygonsWkt: string) => Promise<void>;
  onDestroy?: () => Promise<void>;
}

type Coordinates = {
  lat: number | undefined;
  lng: number | undefined;
} | null;

export default function SpotRemarkMapAreaPicker(props: Props) {
  const {
    gmapsApiKey,
    center,
    zoom = 15,
    wkt,
    unEditableWkt,
    showToast = true,
    notNull = false,
    editable,
    spot,
    locationsForHeatMap,
    onDestroy,
    onUpdateByWkt,
  } = props;

  const [mapApi, setMapApi] = useState<MapApi | undefined>();
  const [heatMap, setHeatMap] = useState<
    google.maps.visualization.HeatmapLayer | undefined
  >();

  const [pickMode, setPickMode] = useState<PickMode>(DEFAULT_PICK_MODE);
  const [openDestroyDialog, setOpenDestroyDialog] = useState<boolean>(false);
  const [isLoadSave, setIsLoadSave] = useState(false);
  const [selectType, setSelectType] = useState<string>("bicycle");

  const [bicycleLatLng, setBicycleLatLng] = useState<Coordinates>();
  const [parkingLatLng, setParkingLatLng] = useState<Coordinates>();
  const [enterLatLng, setEnterLatLng] = useState<Coordinates>();
  const [boxLatLng, setBoxLatLng] = useState<Coordinates>();
  const [destLatLng, setDestLatLng] = useState<Coordinates>();

  useEffect(() => {
    if (spot) {
      setBicycleLatLng({
        lat: spot.bicycle_lat,
        lng: spot.bicycle_lng,
      });
      setParkingLatLng({
        lat: spot.parking_lat,
        lng: spot.parking_lng,
      });
      setEnterLatLng({
        lat: spot.enter_lat,
        lng: spot.enter_lng,
      });
      setBoxLatLng({
        lat: spot.box_lat,
        lng: spot.box_lng,
      });
      setDestLatLng({
        lat: spot.dest_lat,
        lng: spot.dest_lng,
      });
    } else {
      setBicycleLatLng({
        lat: $("#spot_remark_bicycle_lat").val(),
        lng: $("#spot_remark_bicycle_lng").val(),
      });
      setParkingLatLng({
        lat: $("#spot_remark_parking_lat").val(),
        lng: $("#spot_remark_parking_lng").val(),
      });
      setEnterLatLng({
        lat: $("#spot_remark_enter_lat").val(),
        lng: $("#spot_remark_enter_lng").val(),
      });
      setBoxLatLng({
        lat: $("#spot_remark_box_lat").val(),
        lng: $("#spot_remark_box_lng").val(),
      });
      setDestLatLng({
        lat: $("#spot_remark_dest_lat").val(),
        lng: $("#spot_remark_dest_lng").val(),
      });
    }
  }, []);

  // 共通のHooks
  const {
    refMap,
    selectedFeature, // 選択中のエリア
    setSelectedFeature, // 選択中のエリアを保存
    addArea, // 選択エリアを追加して更新するメソッド
    subArea, // 選択エリアを除外して更新するメソッド
    destroyAll, // 全て削除するメソッド
    toggleFeature, // エリアの選択、未選択を切り替えるメソッド
    resetSelectedFeatures, // 選択中のエリアを全て解除する
  } = useMapAreaPicker({
    onUpdateByWkt,
    onDestroy,
    wkt,
    notNull,
    showToast,
    map: mapApi?.map,
  });

  // パスで選択モードのHooks
  const { editMode, setEditMode } = usePathMode({
    map: mapApi?.map,
    pickMode,
    wkt,
    selectedFeature,
    editable,
  });

  // 町域選択機能のHooks
  const {
    townBorders,
    hoverTownBorder,
    setHoverTownBorder,
    handleClickTownBorder,
    fetchTownBordersByCenter,
    fetchTownBordersByKeyword,
    changeCenter,
    townBordersCenter,
    centerTown,
  } = useTownBordersMode({ toggleFeature, defaultCenter: center });

  // Hex選択機能のHooks
  const {
    hexSize,
    setHexSize,
    hoverHexIndex,
    clearHoverHexIndex,
    setHoverIndexByMouseCursor,
    handleClickHexArea,
  } = useHexMode({ toggleFeature });

  // Radius選択機能のHooks
  const {
    radiusSize,
    latitude,
    longitude,
    setRadiusSize,
    setLatitude,
    setLongitude,
  } = useRadiusMode({ toggleFeature });

  // GoogleMapAPIの読み込み完了時に実行
  const handleMapLoad = (map: google.maps.Map, maps: typeof google.maps) => {
    setMapApi({ map, maps });

    // Hexモード時、選択済みエリアにマウスオーバーした時にホバー効果が出るように
    map.data.addListener("mouseover", setHoverIndexByMouseCursor);

    // 読み込み完了時に一度だけ描画
    renderHeatMap(map, maps);
  };

  // パスで選択モード中にマップ範囲内をクリックした時に現在の選択状態を保存する
  const handleClickMap = (event) => {
    if (!props.editable) {
      return;
    }
    if (pickMode === "Paths") {
      mapApi?.map.data.toGeoJson((feature) => {
        // @ts-ignore
        setSelectedFeature(feature as google.maps.Data.Feature);
      });
    }

    // 円で指定するモードの時にmapをクリックすると円を作成する
    if (pickMode === "Radius") {
      // クリックした座標とフォームに入力されている半径で円の作成を行う
      // @ts-ignore
      const myLatLng = new google.maps.LatLng(event.lat, event.lng);
      // @ts-ignore
      const circle = new google.maps.Circle({
        strokeOpacity: 0.7,
        strokeWeight: 2,
        fillOpacity: 0.3,
        center: myLatLng,
        radius: radiusSize,
      });

      // mapに表示円をさせる
      mapApi!.map.data.add({
        // @ts-ignore
        geometry: new google.maps.Data.Polygon([getCirclePath(circle)]),
      });
    }

    if (pickMode == "Dest") {
      setDestLatLng({
        lat: event.lat,
        lng: event.lng,
      });
      $("#spot_remark_dest_lat").val(event.lat);
      $("#spot_remark_dest_lng").val(event.lng);
    }

    if (pickMode == "Spot") {
      switch (selectType) {
        case "bicycle":
          setBicycleLatLng({
            lat: event.lat,
            lng: event.lng,
          });
          $("#spot_remark_bicycle_lat").val(event.lat);
          $("#spot_remark_bicycle_lng").val(event.lng);
          break;
        case "parking":
          setParkingLatLng({
            lat: event.lat,
            lng: event.lng,
          });
          $("#spot_remark_parking_lat").val(event.lat);
          $("#spot_remark_parking_lng").val(event.lng);
          break;
        case "enter":
          setEnterLatLng({
            lat: event.lat,
            lng: event.lng,
          });
          $("#spot_remark_enter_lat").val(event.lat);
          $("#spot_remark_enter_lng").val(event.lng);
          break;
        case "box":
          setBoxLatLng({
            lat: event.lat,
            lng: event.lng,
          });
          $("#spot_remark_box_lat").val(event.lat);
          $("#spot_remark_box_lng").val(event.lng);
          break;
        default:
      }
    }
  };

  const createBicycleMarkers = () => {
    if (!(bicycleLatLng?.lat && bicycleLatLng?.lng)) {
      return;
    }

    return (
      <SpotReportMapSmallMarker
        icon="bicycle"
        lat={bicycleLatLng.lat}
        lng={bicycleLatLng.lng}
        index={0}
        badgeText="駐輪場所"
      />
    );
  };

  const createParkingMarkers = () => {
    if (!(parkingLatLng?.lat && parkingLatLng?.lng)) {
      return;
    }

    return (
      <SpotReportMapSmallMarker
        icon="parking"
        lat={parkingLatLng.lat}
        lng={parkingLatLng.lng}
        index={0}
        badgeText="駐車場所"
      />
    );
  };

  const createEnterMarkers = () => {
    if (!(enterLatLng?.lat && enterLatLng?.lng)) {
      return;
    }

    return (
      <SpotReportMapSmallMarker
        icon="enter"
        lat={enterLatLng.lat}
        lng={enterLatLng.lng}
        index={0}
        badgeText="入口場所"
      />
    );
  };

  const createBoxMarkers = () => {
    if (!(boxLatLng?.lat && boxLatLng?.lng)) {
      return;
    }
    return (
      <SpotReportMapSmallMarker
        icon="box"
        lat={boxLatLng.lat}
        lng={boxLatLng.lng}
        index={0}
        badgeText="配達box場所"
      />
    );
  };

  const createDestMarkers = () => {
    if (!(destLatLng?.lat && destLatLng?.lng)) {
      return;
    }
    return (
      destLatLng?.lat &&
      destLatLng?.lng && (
        <SpotReportMapSmallMarker
          icon="markerPin"
          lat={destLatLng.lat}
          lng={destLatLng.lng}
          index={0}
          badgeText="行き先"
        />
      )
    );
  };

  /**
   * Actionsのイベントハンドラ(追加・除去・全削除など)
   */
  const handleClickAddArea = async () => {
    setIsLoadSave(true);
    addArea(mapApi!.map.data)
      .then(() => {
        resetSelectedFeatures(mapApi!.map);
      })
      .finally(() => setIsLoadSave(false));
  };
  const handleClickRemoveArea = async () => {
    setIsLoadSave(true);
    subArea(mapApi!.map.data)
      .then(() => {
        resetSelectedFeatures(mapApi!.map);
      })
      .finally(() => setIsLoadSave(false));
  };
  const handleClickDestroyConfirm = () => {
    setOpenDestroyDialog(true);
  };
  const handleClickDestroyExecute = () => {
    if (!onDestroy) return;
    destroyAll()
      .then(() => {
        resetSelectedFeatures(mapApi!.map);
      })
      .finally(() => {
        setOpenDestroyDialog(false);
      });
  };
  const handleClickAddCircle = (
    latitude: string,
    longitude: string,
    radiusSize: number
  ) => {
    //
  };

  /**
   * circle関数で作成された円を渡し、geometryライブラリでそのpathを作成する関数
   */
  function getCirclePath(circle) {
    const numPts = 512;
    let path: google.maps.LatLng[] = [];
    for (let i = 0; i < numPts; i++) {
      path.push(
        google.maps.geometry.spherical.computeOffset(
          circle.getCenter(),
          circle.getRadius(),
          (i * 360) / numPts
        )
      );
    }
    return path;
  }

  /**
   * MAPに表示するUI
   */
  const toolbox = (
    <MAPToolboxContentForSpotRemark
      pickMode={pickMode}
      setPickMode={setPickMode}
      pathsToolbox={
        <MAPToolboxContentForPaths
          editMode={editMode}
          onChangeEditMode={setEditMode}
          map={mapApi?.map}
          onDeletePaths={() => resetSelectedFeatures(mapApi?.map)}
        />
      }
      hexToolbox={
        <MAPToolboxContentForHex
          hexSize={hexSize}
          onChangeHexSize={setHexSize}
        />
      }
      radiusToolbox={
        <MAPToolboxContentForRadius
          radiusSize={radiusSize}
          latitude={latitude}
          longitude={longitude}
          onChangeRadiusSize={setRadiusSize}
          onChangeLatitude={setLatitude}
          onChangeLongitude={setLongitude}
          onClickAddCircle={handleClickAddCircle}
          hideAddButton={true}
        />
      }
      spotToolBox={
        <MAPToolboxContentForSpotMarker
          selectType={selectType}
          onChangeSelectType={setSelectType}
        />
      }
      destToolBox={
        <MAPToolboxContentForDestLatLng
          selectType={selectType}
          onChangeSelectType={setSelectType}
        />
      }
    />
  );
  const indicator = (
    <MAPIndicatorContent
      content={
        (hoverTownBorder?.pref || "") +
        (hoverTownBorder?.city || "") +
        (hoverTownBorder?.town || "")
      }
    />
  );
  const actions = (
    <MAPActionsContentForSpotRemark
      loading={isLoadSave}
      onClickAdd={handleClickAddArea}
      onClickRemove={handleClickRemoveArea}
      onClickDestroy={handleClickDestroyConfirm}
      pickMode={pickMode}
      canDestroy={!notNull}
    />
  );
  const modal = (
    <MAPModalContent
      onCancel={() => {
        setOpenDestroyDialog(false);
      }}
      onDestroy={handleClickDestroyExecute}
    />
  );

  const drawer = (
    <MAPDrawerContent
      mapApi={mapApi}
      wkt={wkt}
      selectedFeature={selectedFeature}
      onClickTown={(town: TownBorder) => {
        handleClickTownBorder(town);
      }}
      changeCenter={changeCenter}
      onSubmitSearch={fetchTownBordersByKeyword}
    />
  );

  // 編集モード以外ではnullにして表示させない
  let uiControls;
  if (editable) {
    uiControls = {
      indicator,
      toolbox,
      actions,
      modal,
      drawer,
    };
  } else {
    uiControls = null;
  }

  const onChangeMap = (event: ChangeEventValue) => {
    $("#spot_remark_center_lat").val(event.center.lat);
    $("#spot_remark_center_lng").val(event.center.lng);
  };

  const renderHeatMap = (map: google.maps.Map, maps: typeof google.maps) => {
    if (locationsForHeatMap == null || locationsForHeatMap.length == 0) {
      return null;
    }

    if (heatMap) {
      // すでに設定されている場合には非表示
      heatMap.setMap(null);
    }

    const heatMapData = locationsForHeatMap.map((loc) => ({
      location: new maps.LatLng(+loc.lat, +loc.lng),
      weight: 1,
    }));

    const newHeatMap = new maps.visualization.HeatmapLayer({
      data: heatMapData,
      radius: 15,
    });

    newHeatMap.setMap(map);
    setHeatMap(newHeatMap);
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <GmapsUI
        ref={refMap}
        apiKey={gmapsApiKey}
        center={center}
        zoom={zoom}
        onClickMap={handleClickMap}
        onMapLoaded={handleMapLoad}
        onMousemove={setHoverIndexByMouseCursor}
        onMouseout={clearHoverHexIndex}
        controlsComponents={uiControls}
        modalOpen={openDestroyDialog}
        onModalClose={() => setOpenDestroyDialog(false)}
        onChangeMap={onChangeMap}
      >
        {mapApi && mapApi.map && (
          <>
            {/* 編集不可エリア */}
            {unEditableWkt && (
              <>
                <PolygonByWkt
                  wkt={unEditableWkt}
                  mapApi={mapApi}
                  style={MapPolygonStyles.UN_ACTIVE}
                  options={{ zIndex: -1 }}
                />
              </>
            )}

            {/* 保存済みのエリア */}
            {wkt && (
              <>
                <PolygonByWkt
                  wkt={wkt}
                  mapApi={mapApi}
                  style={MapPolygonStyles.DEFAULT_ACTIVE}
                  onMousemove={setHoverIndexByMouseCursor}
                  options={{ zIndex: -1 }}
                />
              </>
            )}
            {/* Hexカーソル位置を示すポリゴン */}
            {pickMode === "Hex" && (
              <>
                <PolygonByH3Index
                  hexIndex={hoverHexIndex}
                  mapApi={mapApi}
                  style={MapPolygonStyles.DEFAULT_HOVER}
                  onClick={handleClickHexArea}
                />
              </>
            )}
          </>
        )}
        {createBicycleMarkers()}
        {createParkingMarkers()}
        {createEnterMarkers()}
        {createBoxMarkers()}
        {createDestMarkers()}
      </GmapsUI>
    </div>
  );
}
