import { format, endOfDay, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import { axiosPost } from "../utils/AxiosClient";

Modal.setAppElement("#wrapper");

type Props = {
  id: number;
  carryStaffId: number;
  userId: number;
  avoidAutoAssignStartAt: string;
  avoidAutoAssignEndAt: string;
};

export default function CarryStaffAvoidAutoAssignTimeForm({
  id,
  carryStaffId,
  userId,
  avoidAutoAssignStartAt,
  avoidAutoAssignEndAt,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [startAt, setStartAt] = useState<Date>(() => parseISO(avoidAutoAssignStartAt));
  const [endAt, setEndAt] = useState<Date>(() => parseISO(avoidAutoAssignEndAt));

  // 新規作成時のinputの初期値の設定
  useEffect(() => {
    const date = new Date();
    if (!avoidAutoAssignStartAt) {
      setStartAt(date);
    }
    if (!avoidAutoAssignEndAt) {
      setEndAt(endOfDay(date));
    }
  }, []);

  const sleep = (waitTime) =>
    new Promise((resolve) => setTimeout(resolve, waitTime));

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    clearResults();
  };

  const clearResults = () => {
    if (errorMessage) setErrorMessage(null);
  };

  const onChangeStartAt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    setStartAt(date);
  };

  const onChangeEndAt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    setEndAt(date);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params = {
      id: id,
      carry_staff_id: carryStaffId,
      user_id: userId,
      avoid_auto_assign_start_at: startAt,
      avoid_auto_assign_end_at: endAt,
    };

    try {
      if (id) {
        await axiosPost.put("/api/carry_staff_avoid_auto_assign_times", {
          carry_staff_avoid_auto_assign_time: params,
        });
        toast.success("更新しました。");
      } else {
        await axiosPost.post("/api/carry_staff_avoid_auto_assign_times", {
          carry_staff_avoid_auto_assign_time: params,
        });
        toast.success("作成しました。");
      }
      setErrorMessage(null);
      await sleep(3000);
      window.location.reload();
    } catch (e) {
      console.log(e);
      const message = e.response?.data?.message || "エラーが発生しました。";
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  const toDatetime = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm");
  };

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={() => openModal()}
        disabled={isOpen}
      >
        {id && "編集"}
        {!id && "新規作成"}
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        style={modalStyles}
      >
        <h2 className="h5 mb-4">時間設定</h2>
        {errorMessage && (
          <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {errorMessage}
          </p>
        )}
        <form onSubmit={onSubmit}>
          <div className="border-left-info px-4">
            <div className="row align-items-center mb-3">
              <input
                type="datetime-local"
                defaultValue={toDatetime(startAt)}
                onChange={onChangeStartAt}
              />
              〜
              <input
                type="datetime-local"
                defaultValue={toDatetime(endAt)}
                onChange={onChangeEndAt}
              />
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              {id && "更新"}
              {!id && "作成"}
            </button>
          </div>
        </form>
      </Modal>
      <ToastContainer />
    </>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
