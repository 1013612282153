import React from "react";
import { observer } from "mobx-react";
import MapAttributes from "../constants/MapAttributes";
import { OtherArea } from "../interfaces/DeliveryArea";
import WktMultiPolygon from "./WktMultiPolygon";
import VendorCompanySmallMarker from "./VendorCompanySmallMarker";
import GoogleMap from '../components/Common/GoogleMap';


declare var gon: any;

interface Props {
  centerLat: number
  centerLng: number
  deliveryAreaWkt: string | null
  otherAreas?: OtherArea[]
  onClickOtherArea: (key: string, areaWkt: string) => Promise<void>
  onRightClickPolygon?: (key: string, areaWkt: string) => void
}

interface State {
  map: any
  mapApi: any
  mapLoaded: boolean
}

/**
 * デリバリーエリアを表示しつつ、他のエリア(デリバリーエリア周辺を想定)も表示するコンポーネント.
 * 
 * デリバリーエリア(=サービスエリア)編集での、市区町村エリア選択(オリジナルADMSには未実装)やヘックス選択での利用を想定
 */
class DeliveryAreaMapWithOtherAreas extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false
    };
    this.onClickOtherArea = this.onClickOtherArea.bind(this)
    this.onRightClickPolygon = this.onRightClickPolygon.bind(this)
  }

  componentDidMount() {
  }

  async onClickOtherArea(key: string, areaWkt: string) {
    await this.props.onClickOtherArea(key, areaWkt)
  }

  onRightClickPolygon(key: string, areaWkt: string) {
    this.props.onClickOtherArea(key, areaWkt)
  }

  render() {
    const { centerLat, centerLng, otherAreas } = this.props;
    return (
      <div className={'request-map-container'}>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={{ lat: centerLat, lng: centerLng }}
          defaultZoom={14}
          options={{ minZoom: 9, maxZoom: 18 }}
          center={{ lat: centerLat, lng: centerLng }}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => this.setState({
            map: map,
            mapApi: maps,
            mapLoaded: true
          })}
        >
          {(this.state.mapApi && this.props.deliveryAreaWkt) && (
            <WktMultiPolygon
              map={this.state.map}
              mapApi={this.state.mapApi}
              wktText={this.props.deliveryAreaWkt}
              strokeColor={"#7711FF"}
              fillColor={"#7711FF"}
            />
          )}
          {(this.state.mapApi && otherAreas && otherAreas.length > 0) && (
            otherAreas.map(oa => {
              return (
                <WktMultiPolygon
                  key={oa.key}
                  areaKey={oa.key}
                  map={this.state.map}
                  mapApi={this.state.mapApi}
                  wktText={oa.areaWkt}
                  // strokeColorを別の色にするとチラついてしまうので同じ色を設定
                  strokeColor={"#808080"}
                  fillColor={"#79baff"}
                  fillOpacity={0}
                  onClickPolygon={async () => await this.onClickOtherArea(oa.key, oa.areaWkt)}
                  onRightClickPolygon={() => this.onRightClickPolygon(oa.key, oa.areaWkt)}
                />)
            })
          )}
          <VendorCompanySmallMarker
            lat={centerLat}
            lng={centerLng}
            name={"店舗位置"}
            address={""}
          />
          {this.props.children}
        </GoogleMap>
      </div>
    );
  }
}

export default observer(DeliveryAreaMapWithOtherAreas);
