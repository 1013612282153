import React, { useEffect, useState } from "react";
import { Coords } from "google-map-react";
// @ts-ignore
import { parse } from "wkt";
import type { TownBorder } from "../../../interfaces/TownBorder";
import { axiosGet } from "../../../utils/AxiosClient";

interface Props {
  toggleFeature: (geometry: any, id: number | string) => void;
  defaultCenter: Coords;
}

export default function useTownBordersMode(props: Props) {
  const { toggleFeature, defaultCenter } = props;

  const [hoverTownBorder, setHoverTownBorder] = useState<
    TownBorder | undefined
  >();
  const [center, setCenter] = useState<Coords>(defaultCenter);
  const [townBorders, setTownBorders] = useState<TownBorder[]>([]);
  const [centerTown, setCenterTown] = useState<TownBorder>();

  useEffect(() => {
    axiosGet
      .get<TownBorder[]>("/api/town_borders", {
        params: {
          lat: center.lat,
          lng: center.lng,
        },
      })
      .then((res) => {
        if (res.data.length) {
          setCenterTown(res.data[0]);
        } else {
          setCenterTown(undefined);
        }
      });
  }, [center]);

  // 町域ポリゴンをクリック時に実行
  const handleClickTownBorder = (clickedTown: TownBorder) => {
    // ① DataLayer上の選択状態を切り替える
    toggleFeature(parse(clickedTown.border), clickedTown.id);
  };

  const fetchTownBordersByCenter = async () => {
    await axiosGet
      .get<TownBorder[]>("/api/town_borders", {
        params: {
          lat: center.lat,
          lng: center.lng,
          near: 0,
          far: 3,
          pref: centerTown?.pref || undefined,
        },
      })
      .then((res) => {
        // const newTownBorders = [...res.data]
        // const uniqueTownBorders = Array.from(
        //     new Map(newTownBorders.map((townBorder) => [townBorder.id, townBorder])).values()
        // );
        setTownBorders(res.data);
        return res.data;
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const fetchTownBordersByKeyword = (
    keyword: string,
    pref: string,
    page: number = 1
  ) => {
    return new Promise<TownBorder[]>((resolve, reject) => {
      axiosGet
        .get<TownBorder[]>("/api/town_borders", {
          params: {
            keyword,
            pref,
            page,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const changeCenter = (coords: Coords) => {
    setCenter(coords);
  };

  return {
    hoverTownBorder,
    setHoverTownBorder,
    handleClickTownBorder,
    fetchTownBordersByKeyword,
    fetchTownBordersByCenter,
    changeCenter,
    townBordersCenter: center,
    townBorders,
    centerTown,
  };
}
