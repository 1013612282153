import { axiosGet, axiosPost } from "../../../utils/AxiosClient";
import {
  RawCarryStaff,
  RawCarryStaffRequestSequence,
  RawCarryStaffSchedule,
  RawCarryStaffWithSchedules,
  RawRequest,
  RawRequestWithInfo,
  TargetTerm,
} from "../interfaces";

export async function loadCarryStaffsWithSchedules(
  searchText: string | null,
  territoryIds: number[],
  targetDate: string,
  pagination?: {
    page: number;
    perPage: number;
  }
) {
  let urlParams = [
    ["search_text", searchText || ""],
    ["territory_ids", `${territoryIds.join(",") ?? ""}`],
    ["target_date", targetDate],
  ];
  if (pagination) {
    urlParams.push(["pagination", "1"]);
    urlParams.push(["page", `${pagination.page}`]);
    urlParams.push(["per_page", `${pagination.perPage}`]);
  } else {
    urlParams.push(["pagination", "0"]);
  }

  const response = (await axiosGet.get(
    `/api/route_delivery_requests/carry_staffs?${new URLSearchParams(
      urlParams
    ).toString()}`
  )) as {
    data: {
      today_schedules: RawCarryStaffSchedule[];
      carry_staffs: RawCarryStaff[];
      total_carry_staffs_count: number;
      assigned_requests: RawRequest[];
    };
  };
  return {
    carryStaffs: response.data.carry_staffs,
    schedules: response.data.today_schedules,
    totalCarryStaffsCount: response.data.total_carry_staffs_count,
    assignedRequests: response.data.assigned_requests,
  };
}

export function mergeSchedulesIntoCarryStaffs(
  carryStaffs: RawCarryStaff[],
  schedules: RawCarryStaffSchedule[]
) {
  const _carryStaffs: RawCarryStaffWithSchedules[] = carryStaffs.map((cas) =>
    mergeSchedulesIntoCarryStaff(cas, schedules)
  );
  return _carryStaffs;
}

function mergeSchedulesIntoCarryStaff(
  carryStaff: RawCarryStaff,
  schedules: RawCarryStaffSchedule[]
) {
  const _carryStaff: RawCarryStaffWithSchedules = {
    id: carryStaff.id,
    name: carryStaff.name,
    staff_type: carryStaff.staff_type,
    can_route_delivery: carryStaff.can_route_delivery,
    territory_id: carryStaff.territory_id,
    vehicle_type_id: carryStaff.vehicle_type_id,
    schedules: schedules.filter(
      (schedule) => schedule.carry_staff_id == carryStaff.id
    ),
  };
  return _carryStaff;
}

export async function loadUnassignedRequests(targetTerm: TargetTerm) {
  const response = (await axiosGet.get(
    `/api/route_delivery_requests/unassigned_requests?${new URLSearchParams([
      [
        "target_term",
        targetTerm.type == "specified" ? targetTerm.date : targetTerm.type,
      ],
    ]).toString()}`
  )) as {
    data: {
      unassigned_requests: RawRequest[];
      total_unassigned_request_count: number;
    };
  };
  return response;
}

export function existsAssigningChanged(requests: RawRequestWithInfo[]) {
  return requests.some((req) => req.org_carry_staff_id != req.carry_staff_id);
}

export async function assign(
  params: {
    requestId: number;
    carryStaffId: number | null;
  }[]
) {
  const response = (await axiosPost.post(
    `/api/route_delivery_requests/assign`,
    {
      requests: params.map((param) => ({
        id: param.requestId,
        carry_staff_id: param.carryStaffId,
      })),
    }
  )) as { data: { message: string } };
  return response;
}

export async function updateSequences(params: {
  targetDate: string;
  carryStaffId: number;
  sequences: Pick<
    RawCarryStaffRequestSequence,
    | "request_id"
    | "destination_type"
    | "sequence"
    | "estimated_delivery_time"
    | "work_start_time"
  >[];
}) {
  const response = (await axiosPost.post(`/api/carry_staff_request_sequences`, {
    target_date: params.targetDate,
    carry_staff_id: params.carryStaffId,
    sequences: params.sequences,
  })) as {
    data: {
      message: string;
    };
  };
  return response;
}
