import {
  AcceptableTableKey,
  ConditionKey,
  tableAndColumnMap,
  typeConditionAllowMap,
} from "./Constants";
import {
  MasterTag,
  MasterTagRule,
  MasterTagRuleDetail,
  RootConditionUnit,
  NotRootConditionUnit,
  NodeConditionUnit,
  LeafConditionUnit,
  RelationType,
  ConditionUnit,
} from "./Interfaces";

export function convertPropsIntoTree(props: {
  masterTag: MasterTag;
  masterTagRule: MasterTagRule | null;
  masterTagRuleDetails: MasterTagRuleDetail[];
}) {
  const { masterTagRule, masterTagRuleDetails } = props;
  if (masterTagRule == null) {
    return createRootNode();
  }

  const rootNode = convertExpressionIntoTree(
    masterTagRule.expression,
    masterTagRuleDetails
  );
  remakeCodeLabels(rootNode);
  return rootNode;
}

function createRootNode(): RootConditionUnit {
  return {
    type: "root",
    code: "root",
    children: [],
  };
}

export function createDefaultTree(tableKey: AcceptableTableKey) {
  if (tableKey === "request") {
    return createDefaultTreeForRequest();
  } else if (tableKey === "delivery_offer") {
    return createDefaultTreeForDeliveryOffer();
  } else if (tableKey === "address") {
    return createDefaultTreeForAddress();
  }
  throw Error("Invalid table name");
}

/**
 * 「会社コードが...から始まり、かつ(配達先緯度経度が...に含まれるか、あるいは配達元緯度経度が...に含まれる)」という条件構造を作成するための関数.
 * 依頼用デフォルト条件構造
 * @returns
 */
function createDefaultTreeForRequest() {
  const rootNode = createRootNode();

  const rightLeaf: LeafConditionUnit = {
    type: "leaf",
    code: crypto.randomUUID(),
    codeLabel: "",
    condition: {
      columnKey: "company_cd",
      conditionKey: "starts_with",
      comparedValue: "",
      invalid: false,
    },
    relation_to_right: null,
    parent: rootNode,
  };
  rootNode.children.push(rightLeaf);

  const leftNode: NodeConditionUnit = {
    type: "node",
    code: crypto.randomUUID(),
    codeLabel: "",
    relation_to_right: "and",
    parent: rootNode,
    children: [],
  };
  rootNode.children.push(leftNode);
  const leftSenderLeaf: LeafConditionUnit = {
    type: "leaf",
    code: crypto.randomUUID(),
    codeLabel: "",
    relation_to_right: null,
    condition: {
      columnKey: "sender_latlng",
      conditionKey: "within",
      comparedValue: null,
      invalid: false,
    },
    parent: leftNode,
  };
  leftNode.children.push(leftSenderLeaf);
  const leftReceiverLeaf: LeafConditionUnit = {
    type: "leaf",
    code: crypto.randomUUID(),
    codeLabel: "",
    relation_to_right: "or",
    condition: {
      columnKey: "receiver_latlng",
      conditionKey: "within",
      comparedValue: null,
      invalid: false,
    },
    parent: leftNode,
  };
  leftNode.children.push(leftReceiverLeaf);

  remakeCodeLabels(rootNode);
  return rootNode;
}

/**
 * 「配達先緯度経度が...に含まれるか、あるいは配達元緯度経度が...に含まれる」という条件構造を作成するための関数.
 * オファー用デフォルト条件構造
 * @returns
 */
function createDefaultTreeForDeliveryOffer() {
  const rootNode = createRootNode();

  const senderLeaf: LeafConditionUnit = {
    type: "leaf",
    code: crypto.randomUUID(),
    codeLabel: "",
    relation_to_right: null,
    condition: {
      columnKey: "sender_latlng",
      conditionKey: "within",
      comparedValue: null,
      invalid: false,
    },
    parent: rootNode,
  };
  rootNode.children.push(senderLeaf);
  const receiverLeaf: LeafConditionUnit = {
    type: "leaf",
    code: crypto.randomUUID(),
    codeLabel: "",
    relation_to_right: "or",
    condition: {
      columnKey: "receiver_latlng",
      conditionKey: "within",
      comparedValue: null,
      invalid: false,
    },
    parent: rootNode,
  };
  rootNode.children.push(receiverLeaf);

  remakeCodeLabels(rootNode);
  return rootNode;
}

/**
 * 「都道府県が...と等しい」という条件構造を作成するための関数.
 * 住所用デフォルト条件構造
 * @returns
 */
function createDefaultTreeForAddress() {
  const rootNode = createRootNode();

  const leaf: LeafConditionUnit = {
    type: "leaf",
    code: crypto.randomUUID(),
    codeLabel: "",
    relation_to_right: null,
    condition: {
      columnKey: "state",
      conditionKey: "equal",
      comparedValue: null,
      invalid: false,
    },
    parent: rootNode,
  };
  rootNode.children.push(leaf);

  remakeCodeLabels(rootNode);
  return rootNode;
}

export function addNode({
  tableKey,
  node,
  relationType,
}: {
  tableKey: AcceptableTableKey;
  node: ConditionUnit;
  relationType: RelationType | null;
}) {
  const table = tableAndColumnMap.find((item) => item.table.key === tableKey);
  if (table == null) throw Error("Invalid table name");

  // そのテーブルのカラムのうちtableAndColumnMapで最初に定義されているもの
  const firstColumn = table.columns[0];
  // そのカラムの型定義に対して指定できる条件のうちtypeConditionAllowMapで最初に定義されているもの
  const firstColumnFirstConditionKey =
    typeConditionAllowMap[firstColumn.type][0];

  if (node.type === "leaf") {
    // 新しくノードを作成し、その子どもとして自身を登録、
    // さらに新しく作成した葉も子どもとして登録
    // 元の親の子どもから自身を削除
    // 元の親の子供として新しく作成したノードを登録
    const newParentNode: NodeConditionUnit = {
      type: "node",
      code: crypto.randomUUID(),
      codeLabel: "",
      relation_to_right: node.relation_to_right,
      parent: node.parent,
      children: [node],
    };
    const newLeafNode: LeafConditionUnit = {
      type: "leaf",
      code: crypto.randomUUID(),
      codeLabel: "",
      relation_to_right: relationType,
      condition: {
        columnKey: firstColumn.key,
        conditionKey: firstColumnFirstConditionKey,
        comparedValue: "",
        invalid: false,
      },
      parent: newParentNode,
    };
    newParentNode.children.push(newLeafNode);
    const targetNodeIndex = node.parent.children.findIndex(
      (n) => n.code === node.code
    );
    if (targetNodeIndex >= 0) {
      node.parent.children.splice(targetNodeIndex, 1, newParentNode);
    }
    // こいつが新しいノードの一番右側の(上の)葉になるので、relation_to_rightは消す
    node.relation_to_right = null;
    node.parent = newParentNode;
  } else {
    const newLeafNode: LeafConditionUnit = {
      type: "leaf",
      code: crypto.randomUUID(),
      codeLabel: "",
      // 削除された結果、子供を持たないルートノードがありうるので、もし子ノード数が0であればrelationはなし
      relation_to_right: node.children.length === 0 ? null : relationType,
      condition: {
        columnKey: firstColumn.key,
        conditionKey: firstColumnFirstConditionKey,
        comparedValue: "",
        invalid: false,
      },
      parent: node,
    };
    node.children.push(newLeafNode);
  }

  const rootNode = getRootNode(node);
  remakeCodeLabels(rootNode);

  return node;
}

export function deleteNode({ node }: { node: ConditionUnit }) {
  // rootノードは削除対象としない
  if (node.type === "root") {
    console.warn("Can't delete root node.");
    return;
  }

  // 親ノードの子どもからそいつ自身を削除
  const targetNodeIndex = node.parent.children.findIndex(
    (n) => n.code === node.code
  );
  if (targetNodeIndex >= 0) {
    node.parent.children.splice(targetNodeIndex, 1);
  }

  if (targetNodeIndex === 0 && node.parent.children.length > 0) {
    // そのノードにおける先頭の子どもが削除された場合、新しく先頭になったノードのリレーションを削除
    node.parent.children[0].relation_to_right = null;
  }

  const rootNode = getRootNode(node);

  // この内部でdeleteNodeが呼ばれているのでループしている
  removeNoLeafNodes(rootNode);
  remakeCodeLabels(rootNode);

  return node;
}

/**
 * 葉を持たないノードを削除するメソッド.
 *
 * あまりに非効率な気がするが、条件数はどれだけ多く見積もっても数十程度だろうので全走査
 * @param rootNode
 */
function removeNoLeafNodes(rootNode: RootConditionUnit) {
  const leafCountMap = countLeafNodesInDescendant(rootNode);
  for (const code in leafCountMap) {
    // ルートノードは消さない
    if (code === "root") {
      continue;
    }

    if (leafCountMap[code] === 0) {
      const node = searchNodeInDescendant(rootNode, code);
      if (node) {
        deleteNode({ node });
      }
    }
  }
}

function searchNodeInDescendant(
  fromNode: ConditionUnit,
  code: string
): ConditionUnit | null {
  if (fromNode.code === code) {
    return fromNode;
  }

  if (fromNode.type === "leaf") {
    return null;
  }

  for (const childNode of fromNode.children) {
    const result = searchNodeInDescendant(childNode, code);
    if (result) {
      return result;
    }
  }

  return null;
}

function remakeCodeLabels(node: ConditionUnit, codePrefix = "L#") {
  if (node.type === "leaf") {
    return;
  }

  let index = 1;
  for (const childNode of node.children) {
    const newCodePrefix = `${codePrefix}${index}`;
    childNode.codeLabel = newCodePrefix;
    remakeCodeLabels(childNode, newCodePrefix);
    index += 1;
  }
}

function getRootNode(node: ConditionUnit): RootConditionUnit {
  if (node.type === "root") {
    return node;
  }

  return getRootNode(node.parent);
}

function countLeafNodesInDescendant(node: ConditionUnit): {
  [key: string]: number;
} {
  if (node.type === "leaf") {
    return { [node.code]: 1 };
  }

  let leafNodesCound = 0;
  let countMap = {};
  for (const childNode of node.children) {
    const childCountMap = countLeafNodesInDescendant(childNode);
    leafNodesCound += childCountMap[childNode.code];
    countMap = Object.assign(countMap, childCountMap);
  }
  countMap[node.code] = leafNodesCound;

  return countMap;
}

type ValidationResult =
  | {
      valid: true;
      invalidType: null;
    }
  | {
      valid: false;
      invalidType: "no_conditions" | "no_contents";
    };

export function checkValid(node: ConditionUnit): ValidationResult {
  if (node.type == "root" && node.children.length == 0) {
    return { valid: false, invalidType: "no_conditions" };
  }

  if (node.type === "leaf") {
    // とりあえず文字列がそんざいするかどうかだけの確認とする
    // ただし、「存在する」の場合のみ、nullを許容する
    const isInvalid =
      node.condition.conditionKey !== "exists" &&
      (node.condition.comparedValue == null ||
        node.condition.comparedValue.length === 0);
    node.condition.invalid = isInvalid;

    if (isInvalid) {
      return { valid: false, invalidType: "no_contents" };
    } else {
      return { valid: true, invalidType: null };
    }
  }

  let validationResult: ValidationResult = {
    valid: true,
    invalidType: null,
  };
  for (const childNode of node.children) {
    const _validationResult = checkValid(childNode);
    if (!_validationResult.valid) {
      validationResult = _validationResult;
    }
  }

  return validationResult;
}

export function convertToPostBody(
  tableKey: AcceptableTableKey,
  node: RootConditionUnit
) {
  const leafNodes = getLeafNodes(node);
  return {
    table_key: tableKey,
    expression: convertToConditionExpression(node),
    rules: leafNodes.map((leaf) => ({
      code_label: leaf.codeLabel,
      column: leaf.condition.columnKey,
      condition: leaf.condition.conditionKey,
      value: leaf.condition.comparedValue,
    })),
  };
}

function convertToConditionExpression(node: ConditionUnit) {
  if (node.type === "leaf") {
    return (
      (node.relation_to_right ? " " + node.relation_to_right + " " : "") +
      node.codeLabel
    );
  }

  let expression = "";
  for (const childNode of node.children) {
    if (childNode.type === "node") {
      expression +=
        (childNode.relation_to_right
          ? " " + childNode.relation_to_right + " "
          : "") +
        "(" +
        convertToConditionExpression(childNode) +
        ")";
    } else {
      expression += convertToConditionExpression(childNode);
    }
  }

  return expression;
}

function getLeafNodes(node: ConditionUnit): LeafConditionUnit[] {
  if (node.type === "leaf") {
    return [node];
  }

  let leafNodes: LeafConditionUnit[] = [];
  for (const childNode of node.children) {
    const tempLeafNodes = getLeafNodes(childNode);
    leafNodes = leafNodes.concat(tempLeafNodes);
  }

  return leafNodes;
}

function convertExpressionIntoTree(
  expression: string,
  ruleDetails: MasterTagRuleDetail[]
) {
  const ruleDetailsMap = ruleDetails.reduce((accumulator, value, index) => {
    return { ...accumulator, [`ID#${value.id}`]: value };
  }, {}) as { [key: string]: MasterTagRuleDetail };
  const tokens = convertExpressionIntoTokens(expression);
  const rootNode = createRootNode();
  let currentNode = rootNode as RootConditionUnit | NodeConditionUnit;
  let prefixOperator: RelationType | null = null;
  for (const token of tokens) {
    switch (token.tokenType) {
      case "and":
        prefixOperator = "and";
        break;
      case "or":
        prefixOperator = "or";
        break;
      case "lparen":
        const node: NodeConditionUnit = {
          type: "node",
          code: crypto.randomUUID(),
          codeLabel: "",
          relation_to_right: prefixOperator,
          parent: currentNode,
          children: [],
        };
        currentNode.children.push(node);
        currentNode = node;
        prefixOperator = null;
        break;
      case "rparen":
        // ')'が来るということは、現在ノードがルートであるはずがないので
        currentNode = (currentNode as NotRootConditionUnit).parent;
        prefixOperator = null;
        break;
      case "id":
        const ruleDetail = ruleDetailsMap[token.token];
        if (!ruleDetail) {
          console.error("Exist no rule_detail", ruleDetailsMap, token);
          throw Error("Exist no matched rule_detail");
        }

        const leaf: LeafConditionUnit = {
          type: "leaf",
          code: crypto.randomUUID(),
          codeLabel: "",
          relation_to_right: prefixOperator,
          condition: {
            columnKey: ruleDetail.column,
            conditionKey: ruleDetail.condition as ConditionKey,
            comparedValue: ruleDetail.value,
            invalid: false,
          },
          parent: currentNode,
        };
        currentNode.children.push(leaf);
        prefixOperator = null;
        break;
    }
  }

  return rootNode;
}

type TokenType = "and" | "or" | "lparen" | "rparen" | "id";

function convertExpressionIntoTokens(expression: string) {
  const tokens = [
    { tokenType: "and", regex: /^and/ },
    { tokenType: "or", regex: /^or/ },
    { tokenType: "lparen", regex: /^\(/ },
    { tokenType: "rparen", regex: /^\)/ },
    { tokenType: "id", regex: /^ID#\d+/ },
  ] as { tokenType: TokenType; regex: RegExp }[];

  let index = 0;
  let parsedTokens: { tokenType: TokenType; token: string }[] = [];
  let tempExpression = expression.replace(/\s/g, "");
  while (tempExpression.length > 0) {
    for (const token of tokens) {
      const result = token.regex.exec(tempExpression);
      if (result) {
        const matchedToken = result[0];
        parsedTokens.push({
          tokenType: token.tokenType,
          token: matchedToken,
        });
        tempExpression = tempExpression.slice(matchedToken.length);
      }
    }

    // 無限ループ対応
    index += 1;
    if (index > 1000) {
      throw Error(`Failed to convert expression into tokens : ${expression}`);
    }
  }

  return parsedTokens;
}
