import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import { axiosPost } from "../utils/AxiosClient";

interface ImageInfo {
  url: string;
  key: string;
  id?: number;
}

interface Props {
  imageInfoList: ImageInfo[];
  editable: boolean;
  id?: number;
  type: string;
}

interface State {
  deleteImageModalIsOpen: boolean;
  modalIsOpen: boolean;
  targetImageInfo: ImageInfo | null;
  imageInfoList: ImageInfo[];
  keys: string[];
}

Modal.setAppElement("#wrapper");

class SpotRemarkImages extends React.Component<Props, State> {
  fileInputRef = React.createRef<HTMLInputElement>();
  constructor(props: Props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);

    // imageInfoListから画像のkeyを取り出す
    // formのkeyにセットするもの
    const keysArray = this.props.imageInfoList.map((info) => info.key);

    this.state = {
      deleteImageModalIsOpen: false,
      modalIsOpen: false,
      targetImageInfo: null,
      imageInfoList: this.props.imageInfoList,
      keys: keysArray,
    };
  }

  clickImage(imageInfo: ImageInfo): void {
    this.setState({ modalIsOpen: true, targetImageInfo: imageInfo });
  }

  clickDeleteButton(imageInfo: ImageInfo): void {
    this.setState({ deleteImageModalIsOpen: true, targetImageInfo: imageInfo });
  }

  // 画像削除処理
  async clickDeleteImage(imageInfo: ImageInfo): Promise<void> {
    const response = await axiosPost.post(
      `/api/spot_remarks/${this.props.id}/delete_image/`,
      {
        params: {
          key: imageInfo.key,
          type: this.props.type,
        },
      }
    );

    // 表示している画像リストの中から削除対象のkeyを持つものを消す
    const infoArray = this.state.imageInfoList.filter(
      (elem) => elem.key !== imageInfo.key
    );
    const keyArray = this.state.keys.filter((elem) => elem !== imageInfo.key);
    this.setState({
      imageInfoList: infoArray,
      keys: keyArray,
    });

    switch (this.props.type) {
      case "bicycle_key":
        $("#spot_remark_bicycle_key").val(JSON.stringify(this.state.keys));
        break;
      case "parking_key":
        $("#spot_remark_parking_key").val(JSON.stringify(this.state.keys));
        break;
      case "enter_key":
        $("#spot_remark_enter_key").val(JSON.stringify(this.state.keys));
        break;
      case "box_key":
        $("#spot_remark_box_key").val(JSON.stringify(this.state.keys));
        break;
    }

    this.closeDeleteModal();
  }

  async handleUpload() {
    const file =
      this.fileInputRef.current?.files && this.fileInputRef.current?.files[0];

    if (file) {
      const fileName = `spot_remarks/${Date.now()}/${file.name}`;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        let base64Image = reader.result as string;
        try {
          base64Image = base64Image.split(",")[1];

          const response = await axiosPost.post(
            `/api/spot_remarks/${this.props.id}/upload_image/`,
            {
              params: {
                key: fileName,
                type: this.props.type,
                file: base64Image,
                file_type: file.type,
              },
            }
          );

          // 表示する画像に追加する
          // keyは上で作成したfileName
          const addImage: ImageInfo = {
            url: response.data.result.presigned_url,
            key: fileName,
          };
          this.setState((prevState) => ({
            imageInfoList: [...prevState.imageInfoList, addImage],
            keys: [...prevState.keys, addImage.key],
          }));

          // type の種類により処理を分ける
          // 画像には駐輪、駐車、入口、宅配ボックスの四つが存在する
          switch (this.props.type) {
            case "bicycle_key":
              $("#spot_remark_bicycle_key").val(
                JSON.stringify(this.state.keys)
              );
              break;
            case "parking_key":
              $("#spot_remark_parking_key").val(
                JSON.stringify(this.state.keys)
              );
              break;
            case "enter_key":
              $("#spot_remark_enter_key").val(JSON.stringify(this.state.keys));
              break;
            case "box_key":
              $("#spot_remark_box_key").val(JSON.stringify(this.state.keys));
              break;
          }
        } catch (e) {
          console.log(e);
        }
      };
    }
  }

  closeModal(): void {
    this.setState({ modalIsOpen: false });
  }

  closeDeleteModal(): void {
    this.setState({ deleteImageModalIsOpen: false });
  }

  render() {
    if (!this.state.imageInfoList || this.state.imageInfoList.length <= 0) {
      return (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>なし</span>
          </div>
          <div>{this.uploadFunction()}</div>
        </>
      );
    }

    return (
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {this.renderImages()}
        </div>
        <div>{this.uploadFunction()}</div>
        {this.state.targetImageInfo && (
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={this.state.targetImageInfo.url}
                  style={{ maxHeight: 1500, maxWidth: 900 }}
                ></img>
              </div>
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  onClick={() => this.closeModal()}
                  className="btn btn-secondary"
                >
                  閉じる
                </button>
              </div>
            </div>
          </Modal>
        )}
        {this.state.targetImageInfo && (
          <Modal
            isOpen={this.state.deleteImageModalIsOpen}
            onRequestClose={() => this.closeDeleteModal()}
            style={deleteModalStyles}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>削除しますか？</div>
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  onClick={() =>
                    this.clickDeleteImage(this.state.targetImageInfo!)
                  }
                  className="btn btn-secondary"
                >
                  削除
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }

  private renderImages() {
    return _.map(this.state.imageInfoList, (imageInfo) => {
      return (
        <>
          {
            <div
              key={imageInfo.key}
              style={{
                height: 120,
                width: 120,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {this.props.editable && (
                <div onClick={() => this.clickDeleteButton(imageInfo)}>
                  <i className="fas fa-trash-alt"></i>
                </div>
              )}
              <div onClick={() => this.clickImage(imageInfo)}>
                <img
                  src={imageInfo.url}
                  style={{ maxHeight: 100, maxWidth: 100 }}
                ></img>
              </div>
            </div>
          }
        </>
      );
    });
  }

  private uploadFunction() {
    if (!this.props.editable) {
      return;
    }
    return (
      <div>
        <div>
          <input
            name="file"
            type="file"
            accept="image/*"
            ref={this.fileInputRef}
            onChange={this.handleUpload}
          />
        </div>
      </div>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
    height: "800px",
  },
};

const deleteModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "200px",
    width: "200px",
  },
};

export default observer(SpotRemarkImages);
