import { CSSProperties } from "react";
import { COLORS } from "./Colors";

const radiusSize = 6;

const mapCircleMarkerStyle: CSSProperties = {
  position: "absolute",
  width: radiusSize * 2,
  height: radiusSize * 2,
  left: -radiusSize,
  top: -radiusSize,
  borderWidth: 1,
  borderColor: COLORS.blue,
  backgroundColor: COLORS.darkBlue,
  borderRadius: radiusSize,
  textAlign: "left",
  fontSize: 12,
  cursor: "pointer",
};

// 2.7倍は良い感じの鋭角三角形になるよう(2倍=正三角形だと方向がわかりにくいので)
const mapTriangleMarkerStyle: CSSProperties = {
  position: "absolute",
  width: radiusSize * 2,
  height: radiusSize * 2,
  left: -radiusSize,
  top: -radiusSize * 1.35, // 位置調整: (borderBottomで指定した倍数 / 2)
  borderLeft: `${radiusSize}px solid transparent`,
  borderRight: `${radiusSize}px solid transparent`,
  borderBottom: `${radiusSize * 2.7}px solid ${COLORS.blue}`,
  cursor: "pointer",
};

export const STYLES: { [key: string]: CSSProperties } = {
  mapContainerStyle: {
    position: "relative",
    height: "calc(100vh - 6rem)",
    width: "100%",
  },
  mapCircleMarkerStyle,
  mapTriangleMarkerStyle,
  selectedMapMarkerStyle: {
    ...mapTriangleMarkerStyle,
    borderBottom: `${radiusSize * 2.7}px solid ${COLORS.orange}`,
  },
  transparentMapMarkerStyle: {
    ...mapTriangleMarkerStyle,
    borderBottom: `${radiusSize * 2.7}px solid ${COLORS.transparent}`,
  },
  earliestMapMarkerStyle: {
    ...mapCircleMarkerStyle,
    borderColor: COLORS.pink,
    backgroundColor: COLORS.pink,
    zIndex: 1,
  },
  latestMapMarkerStyle: {
    ...mapCircleMarkerStyle,
    borderColor: COLORS.green,
    backgroundColor: COLORS.darkGreen,
    zIndex: 1,
  },
  infoTextStyle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  infoWindowStyle: {
    position: "absolute",
    top: -8,
    left: 36,
    minWidth: 200,
    zIndex: 10, // マーカーより上
    boxShadow: `1px 1px 15px ${COLORS.shadowBlack}`,
    padding: 4,
  },
  mapAnnotationPosition: {
    position: "absolute",
    top: "0.5rem",
    left: "0.5rem",
  },
  mapAnnotationStyle: {
    zIndex: 12,
    padding: 8,
    boxShadow: `1px 1px 5px ${COLORS.shadowBlack}`,
    background: "#ffffff",
  },
} as const;

export const COLOR_SAMPLE = (color: string): CSSProperties => {
  return {
    display: "inline-block",
    width: 10,
    height: 10,
    backgroundColor: `${color}`,
    marginRight: 4,
  };
};
