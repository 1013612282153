import {
  DndContext,
  closestCenter,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
} from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useState, useEffect } from "react";
import { RawCarryStaff, RequestSequence } from "../interfaces";
import { SortableRequest } from "./SortableRequest";
import { ToastOptions } from "react-toastify/dist/types";
import "../../../packs/i18n/ja";
import { ExtendedRawRequest } from "../../../utils/routes-apis/common-utils";
import { searchConditionsStore } from "../stores";

interface Props {
  carryStaff: RawCarryStaff;
  sequences: RequestSequence[];
  canDrag: boolean;
  onDragStart: (
    event: DragStartEvent,
    draggingRequest: RequestSequence,
  ) => void;
  onDragOver: (event: DragOverEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  toastOptions: ToastOptions;
  handleChangeReassignTarget: (
    request: ExtendedRawRequest,
    checked: boolean,
  ) => void;
  reassignTargetRequests: ExtendedRawRequest[];
  isLoading: boolean;
}

export default function SortableRequestsContainer(props: Props) {
  const [draggingSequence, setDraggingSequence] =
    useState<RequestSequence | null>(null);
  const [sequences, setSequences] = useState(props.sequences);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const isReassignMode = searchConditionsStore.isReassignMode;

  useEffect(() => {
    setSequences(props.sequences);
  }, [props.sequences]);

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const _draggingSequence = sequences.find((seq) => seq.id == active.id);
    if (_draggingSequence) {
      setDraggingSequence(_draggingSequence);
      props.onDragStart(event, _draggingSequence);
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    console.log("[handleDragEnd]", active, over);
    setDraggingSequence(null);
    if (over && active.id !== over.id) {
      setSequences((_sequences) => {
        const oldIndex = _sequences.findIndex((seq) => seq.id == active.id);
        const newIndex = _sequences.findIndex((seq) => seq.id == over.id);
        _sequences.forEach((_seq, index, _) => {
          if (
            index < Math.min(oldIndex, newIndex) ||
            index > Math.max(oldIndex, newIndex)
          ) {
            return;
          }

          if (index == oldIndex) {
            _seq.sequence = newIndex + 1;
          } else {
            // 上から下に持って行った場合(oldIndex < newIndex)、その間に含まれていたアイテムのインデックスは -1
            // 下から上に持って行った場合(oldIndex > newIndex、その間に含まれていたアイテムのインデックスは ＋1
            _seq.sequence += oldIndex < newIndex ? -1 : 1;
          }
        });
        return arrayMove(_sequences, oldIndex, newIndex);
      });
    }
    props.onDragEnd(event);
  }

  function handleDragOver(event: DragOverEvent) {
    props.onDragOver(event);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      modifiers={
        isReassignMode
          ? [restrictToWindowEdges]
          : [restrictToWindowEdges, restrictToVerticalAxis]
      }
    >
      <div className={"d-flex flex-column position-relative"}>
        {props.isLoading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "calc(100vh - 300px)" }}
          >
            <div className="spinner-border spinner-border ml-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <SortableContext
            items={sequences}
            strategy={verticalListSortingStrategy}
            disabled={!props.canDrag}
          >
            <div
              className="col-12 d-flex overflow-auto align-items-stretch"
              style={{ maxHeight: "calc(100vh - 300px)" }}
            >
              <div
                className="col-12 d-flex flex-column"
                style={{ minHeight: 10 }}
              >
                {sequences.map((sequence, index) => (
                  <SortableRequest
                    key={sequence.id}
                    index={index}
                    isDragging={sequence.id == draggingSequence?.id}
                    canDrag={props.canDrag}
                    carryStaff={props.carryStaff}
                    sequence={sequence}
                    handleChangeReassignTarget={
                      props.handleChangeReassignTarget
                    }
                    reassignTargetRequests={props.reassignTargetRequests}
                    toastOptions={props.toastOptions}
                  />
                ))}
              </div>
            </div>
          </SortableContext>
        )}
      </div>

      <DragOverlay>
        {draggingSequence ? (
          <SortableRequest
            index={-1}
            isDragging={false}
            canDrag={props.canDrag}
            carryStaff={props.carryStaff}
            sequence={draggingSequence}
            handleChangeReassignTarget={props.handleChangeReassignTarget}
            toastOptions={props.toastOptions}
            reassignTargetRequests={props.reassignTargetRequests}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
