import React from "react";

import {
  CurrentLocationWithCarryStaffModel,
  WorkType,
} from "../models/CurrentLocationWithCarryStaffModel";
import Request from "../interfaces/Request";
import { MapMarkerPin } from "./MapMarkerPin";
import { SVGIcon } from "./MapMarkerPin/MarkerIcon";
import Colors from "../constants/BootstrapColors";
import { MapInfoWindow } from "./MapInfoWindow";
import { CarryStaffCard } from "./MapInfoWindow/CarryStaffCard";
import { carryStaffConst } from "../constants/CarryStaff";
import MarkerColors from "../constants/MarkerColors";
import { COLORS } from "../constants/Colors";
import ThirdPartyDeliveryTask from "../interfaces/ThirdPartyDeliveryTask";

interface Props {
  lat: number;
  lng: number;
  zoomLevel?: number;

  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;

  // keyを渡すとhoverがうまく判定できないので、key propsは任意にして値をセットしない
  key?: number;

  location: CurrentLocationWithCarryStaffModel;

  request: Request | ThirdPartyDeliveryTask;
}

interface State {
  showInfoWindow: boolean;
}

export default class CarryStaffMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false,
    };
  }

  render() {
    const location = this.props.location;
    const showInfoWindow = this.state.showInfoWindow || this.props.$hover;

    // 依頼対応件数が1以上
    const isProgressRequest = this.props.location.inProgressRequestCount > 0;
    // 動いていない可能性あり
    const mayStay = this.props.location.mayStayForAWhile;

    const pinSize = 28;
    const moveMethodIcon = this.moveMethodToIcon();
    const pinColor = this.getPinColor({ isProgressRequest, mayStay });

    let pinText: string | undefined = undefined;
    let pinTextColor: string | undefined = undefined;
    let pinBackgroundColor: string | undefined = pinColor;
    let imageUrl: string | null = location.imageUrl;

    // staffTypeがwoltの場合のマーカーアイコン対応
    const staffType = location.staffType;
    if (staffType == carryStaffConst.STAFF_TYPES.wolt) {
      pinText = "W";
      pinTextColor = "#fff";
      pinBackgroundColor = COLORS.brand.wolt;
      imageUrl = null;
    }

    return (
      <div>
        <MapMarkerPin
          svgIcon={moveMethodIcon}
          pinShape="drop"
          size={showInfoWindow ? pinSize * 1.5 : pinSize}
          pinText={pinText}
          pinTextColor={pinTextColor}
          pinBackgroundColor={pinBackgroundColor}
          svgIconColor={!isProgressRequest ? "#ffffff" : "#333"}
          topRightBadgeStyle={{
            iClass: !mayStay ? null : "fas fa-sm fa-exclamation",
            backgroundColor: Colors.DANGER_COLOR,
          }}
          pinImageUrl={imageUrl || undefined}
          onClick={this.onClickMarker.bind(this)}
          {...this.getTopLeftBadgeProps()}
          {...this.getMiddleLeftBadgeProps(
            moveMethodIcon,
            pinColor,
            imageUrl,
            staffType
          )}
        />
        {showInfoWindow && (
          <MapInfoWindow>
            <CarryStaffCard
              location={this.props.location}
              onCloseClick={() => {
                this.setState({
                  showInfoWindow: false,
                });
              }}
            />
          </MapInfoWindow>
        )}
      </div>
    );
  }

  private onClickMarker(event: Event) {
    this.setState({ showInfoWindow: !this.state.showInfoWindow });
    event.preventDefault();
    event.stopPropagation();
  }

  private progressText() {
    if (this.props.location.inProgressRequestCount == 0) {
      return "待機中";
    }
    return `依頼${this.props.location.inProgressRequestCount}件 対応中`;
  }

  private moveMethodToIcon() {
    switch (this.props.location.moveMethod) {
      case 0:
        return "bicycle";
      case 10:
        return "bike";
      case 20:
        return "car";
      case 30:
      default:
        return "walk";
    }
  }

  private getPinColor({ isProgressRequest, mayStay }) {
    if (isProgressRequest) return MarkerColors.PROGRESS_COLOR;
    if (mayStay) return "#666";
    return MarkerColors.DEFAULT_COLOR;
  }

  private getTopLeftBadgeProps() {
    switch (this.props.location.workType) {
      case WorkType.ANYCARRY:
        return {
          topLeftBadgeStyle: {
            backgroundColor: COLORS.brand.anycarry,
          },
          topLeftBadgeText: "A",
        };
      case WorkType.DEMAECAN:
        return {
          topLeftBadgeStyle: {
            backgroundColor: COLORS.brand.demaecan,
          },
          topLeftBadgeText: "D",
        };
      case WorkType.AMAZON:
        return {
          topLeftBadgeStyle: {
            iClass: "fab fa-amazon",
            backgroundColor: COLORS.brand.amazon,
          },
        };
      case WorkType.WOLT:
        return {
          topLeftBadgeStyle: {
            backgroundColor: COLORS.brand.wolt,
          },
          topLeftBadgeText: "W",
        };
      case WorkType.REST:
        return {
          topLeftBadgeStyle: {
            backgroundColor: COLORS.brand.rest,
          },
          topLeftBadgeText: "休",
        };
    }
  }

  /**
   * 配達スタッフに画像が設定されている場合はピンに画像が、
   * staffTypeがwoltの場合はピンに"W"の文字表示されるため、
   * 移動手段を示すsvgアイコンをバッジとして表示する
   * @param svgIcon
   * @param defaultPinColor
   * @param imageUrl
   * @param staffType
   * @returns
   */
  private getMiddleLeftBadgeProps(
    svgIcon: SVGIcon,
    defaultPinColor: string,
    imageUrl: string | null,
    staffType: number
  ) {
    if (imageUrl || staffType == carryStaffConst.STAFF_TYPES.wolt) {
      return {
        middleLeftBadgeIcon: svgIcon,
        middleLeftBadgeStyle: {
          backgroundColor: defaultPinColor,
        },
      };
    }
  }
}
