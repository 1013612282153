import { observer } from "mobx-react";
import React from "react";
import { Card } from "react-bootstrap";
import Modal from "react-modal";
import { Slide, ToastContainer, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @ts-ignore
import Wkt from "wicket";
import { axiosPost } from "../utils/AxiosClient";
import MapAreaPicker from "./MapAreaPicker/MapAreaPicker";

Modal.setAppElement("#wrapper");

declare var gon: any;

interface Props {
  vendorCompanyId: number;
  center: {
    sender: {
      lat: number;
      lng: number;
    };
    receiver: {
      lat: number;
      lng: number;
    };
  };
  otherDeliveryAreaId: number | null;
  senderAreaWkt: string | null;
  receiverAreaWkt: string | null;
  comment: string | null;
}

interface State {
  isEditSender: boolean;
  senderAreaWkt: string | null;
  receiverAreaWkt: string | null;
  comment: string;
  modalIsOpen: boolean;
  modalOpenType: "save" | "delete" | null;
}

class DeliveryAreaPairMapEditor extends React.Component<Props, State> {
  wkt: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isEditSender: true,
      senderAreaWkt: props.senderAreaWkt,
      receiverAreaWkt: props.receiverAreaWkt,
      comment: props.comment || "",
      modalIsOpen: false,
      modalOpenType: null,
    };
    this.wkt = new Wkt.Wkt();
  }

  validateWktText = (wktText: string) => {
    try {
      this.wkt.read(wktText);
    } catch (error) {
      return false;
    }
    return true;
  };

  save = async () => {
    let options: ToastOptions = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
    };

    try {
      const res = await axiosPost.post(
        `/api/vendor_companies/${this.props.vendorCompanyId}/update_other_delivery_area`,
        {
          id: this.props.otherDeliveryAreaId,
          sender_area_wkt: this.state.senderAreaWkt,
          receiver_area_wkt: this.state.receiverAreaWkt,
          comment: this.state.comment,
        }
      );
      this.setState({
        modalIsOpen: false,
        modalOpenType: null,
      });
      options["onClose"] = () => {
        location.href =
          "/vendor_companies/" +
          this.props.vendorCompanyId +
          "/vendor_company_other_delivery_areas";
      };
      toast.success("更新しました。", options);
    } catch (error) {
      this.setState({
        modalIsOpen: false,
        modalOpenType: null,
      });
      if (
        !(
          // 下記の場合はaxiosPostでまとめて対応しているのでここでは無視
          (
            error.response.status == 403 &&
            (error.response?.data?.result == "NOT_AUTHORIZED_BY_POLICY_GROUP" ||
              error.response?.data?.result == "NOT_AUTHORIZED")
          )
        )
      ) {
        options["type"] = toast.TYPE.ERROR;
        const errorMessage = (
          <div>
            更新に失敗しました。
            <br />
            {error.response.data.message}
          </div>
        );
        toast.error(errorMessage, options);
      }
    }
  };

  delete = async () => {
    let options: ToastOptions = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
    };
    try {
      const res = await axiosPost.post(
        `/api/vendor_companies/${this.props.vendorCompanyId}/delete_other_delivery_area`,
        {
          id: this.props.otherDeliveryAreaId,
        }
      );
      this.setState({
        modalIsOpen: false,
        modalOpenType: null,
      });
      options["onClose"] = () => {
        location.href =
          "/vendor_companies/" +
          this.props.vendorCompanyId +
          "/vendor_company_other_delivery_areas";
      };
      toast.success("削除しました。", options);
    } catch (error) {
      this.setState({
        modalIsOpen: false,
        modalOpenType: null,
      });
      if (
        !(
          // 下記の場合はaxiosPostでまとめて対応しているのでここでは無視
          (
            error.response.status == 403 &&
            (error.response?.data?.result == "NOT_AUTHORIZED_BY_POLICY_GROUP" ||
              error.response?.data?.result == "NOT_AUTHORIZED")
          )
        )
      ) {
        options["type"] = toast.TYPE.ERROR;
        const errorMessage = (
          <div>
            削除に失敗しました。
            <br />
            {error.response.data.message}
          </div>
        );
        toast.error(errorMessage, options);
      }
    }
  };

  updateByWkt = (
    targetAreaType: "sender" | "receiver",
    targetAreaWkt: string
  ) => {
    const isValid = this.validateWktText(targetAreaWkt);
    return new Promise<void>((resolve, reject) => {
      try {
        if (!isValid) throw new Error("wktの形式が不正です");

        if (targetAreaType === "sender") {
          this.setState({ senderAreaWkt: targetAreaWkt });
        } else {
          this.setState({ receiverAreaWkt: targetAreaWkt });
        }
        resolve();
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  resetArea = async (targetAreaType: "sender" | "receiver") => {
    if (targetAreaType === "sender") {
      this.setState({ senderAreaWkt: null });
    } else {
      this.setState({ receiverAreaWkt: null });
    }
  };

  confirm = async () => {
    if (this.state.modalOpenType === "save") {
      return await this.save();
    }
    return await this.delete();
  };

  render() {
    const { center } = this.props;
    const { senderAreaWkt, receiverAreaWkt, isEditSender } = this.state;

    return (
      <div
        className="w-100"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Card className="w-100" style={{ height: "700px" }}>
          <Card.Body>
            <Card.Title>配達元範囲</Card.Title>
            <div className="w-100" style={{ height: "95%" }}>
              <MapAreaPicker
                gmapsApiKey={gon.google_api_key}
                center={center.sender}
                unEditableWkts={[receiverAreaWkt]}
                wkt={senderAreaWkt!}
                showToast={true}
                onUpdateByWkt={async (areaWkt) => {
                  await this.updateByWkt("sender", areaWkt);
                }}
                onDestroy={async () => await this.resetArea("sender")}
              />
            </div>
          </Card.Body>
        </Card>

        <Card className="w-100 mt-2" style={{ height: "700px" }}>
          <Card.Body>
            <Card.Title>配達先範囲</Card.Title>
            <div className="w-100" style={{ height: "95%" }}>
              <MapAreaPicker
                gmapsApiKey={gon.google_api_key}
                center={center.receiver}
                unEditableWkts={[senderAreaWkt]}
                wkt={receiverAreaWkt!}
                showToast={true}
                onUpdateByWkt={async (areaWkt) => {
                  await this.updateByWkt("receiver", areaWkt);
                }}
                onDestroy={async () => await this.resetArea("receiver")}
              />
            </div>
          </Card.Body>
        </Card>

        <Card className="w-100 mt-2">
          <Card.Body>
            <Card.Title>メモ</Card.Title>
            <textarea
              className="form-control"
              value={this.state.comment}
              onChange={(event) =>
                this.setState({ comment: event.target.value })
              }
              rows={3}
            />
          </Card.Body>
        </Card>

        <div
          className="d-flex justify-content-end py-2 pr-4"
          style={{ width: "100%", height: "10%" }}
        >
          {this.props.otherDeliveryAreaId && (
            <button
              className="btn btn-danger mb-3 ml-4"
              onClick={() =>
                this.setState({
                  modalIsOpen: true,
                  modalOpenType: "delete",
                })
              }
              disabled={!this.props.otherDeliveryAreaId}
            >
              削除
            </button>
          )}
          <button
            className="btn btn-primary mb-3 ml-4"
            onClick={() =>
              this.setState({
                modalIsOpen: true,
                modalOpenType: "save",
              })
            }
            disabled={!this.state.senderAreaWkt || !this.state.receiverAreaWkt}
          >
            保存
          </button>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.setState({ modalIsOpen: false })}
          style={customStyles}
          contentLabel="Confirm save other delivery area"
        >
          <div className="w-100 d-flex flex-column">
            <div className="font-weight-bold mb-2" style={{ fontSize: 20 }}>
              確認
            </div>
            <div>
              {this.state.modalOpenType === "save"
                ? "保存しますか？"
                : "削除しますか？"}
            </div>
            <div className="w-100 d-flex justify-content-end mb-2">
              <button
                className="btn btn-outline-danger ml-4"
                onClick={() =>
                  this.setState({ modalIsOpen: false, modalOpenType: null })
                }
              >
                キャンセル
              </button>
              <button
                className="btn btn-primary ml-4"
                onClick={async () => await this.confirm()}
              >
                決定
              </button>
            </div>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    );
  }
}

const customStyles = {
  content: {
    width: "30%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
    height: "150px",
  },
};

export default observer(DeliveryAreaPairMapEditor);
