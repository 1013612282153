import React from "react";
import { observer } from "mobx-react";
import "react-toastify/dist/ReactToastify.css";
import DeliveryArea from "../interfaces/DeliveryArea";
import { axiosPost } from "../utils/AxiosClient";
import DeliveryAreaMapWithInputWkt from "./DeliveryAreaMapWithInputWkt";

interface Props {
  deaasTemplateId: number;
  deliveryArea: DeliveryArea;
}

class DeliveryAreaMapWithInputWktDeaasTemplate extends React.Component<Props> {
  render() {
    return (
      <DeliveryAreaMapWithInputWkt
        deliveryArea={this.props.deliveryArea}
        updateDeliveryArea={async (deliveryAreaWkt) => {
          return await axiosPost.post(
            `/api/deaas_templates/${this.props.deaasTemplateId}/update_delivery_area_wkt`,
            { delivery_area: deliveryAreaWkt }
          );
        }}
        deleteDeliveryArea={async () => {
          return await axiosPost.post(
            `/api/deaas_templates/${this.props.deaasTemplateId}/delete_delivery_area`
          );
        }}
      />
    );
  }
}

export default observer(DeliveryAreaMapWithInputWktDeaasTemplate);
