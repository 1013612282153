import React from "react";
import Modal from "react-modal";
import type { ArrangeErrorResponse, RawCarryStaff } from "../interfaces";

interface Props {
  isOpen: boolean;
  carryStaff: RawCarryStaff;
  contents: ArrangeErrorResponse | undefined;
  onCloseModal: () => void;
}

export default function AuroArrangeErrorConfirmModal(props: Props) {
  const contents = props.contents;
  if (contents == null) return null;

  if (contents.errorType == "custom") {
    return (
      <Modal
        isOpen={props.isOpen}
        onRequestClose={() => props.onCloseModal()}
        style={modalStyles}
      >
        <div className="h5 mb-4">並び替えエラー</div>
        <div className="d-flex flex-column">
          <div>{contents.errorMessage}</div>
          <div className="d-flex justify-content-end mt-3">
            <div className="mr-2">
              <a
                href={`/route_delivery_requests?name=${props.carryStaff.name}`}
              >
                <button className="btn btn-outline-primary">
                  アサイン画面へ
                </button>
              </a>
            </div>
            <button
              className="btn btn-primary mr-2"
              onClick={() => props.onCloseModal()}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  if (contents.errorType == "unsolved") {
    return (
      <Modal
        isOpen={props.isOpen}
        onRequestClose={() => props.onCloseModal()}
        style={modalStyles}
      >
        <div className="h5 mb-4">並び替えエラー</div>
        <div className="d-flex flex-column">
          <div>指定された制約下での最適化は行えませんでした。</div>
          {contents.errorMessage && (
            <div style={{ fontSize: 14, color: "red" }}>
              {contents.errorMessage}
            </div>
          )}
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary mr-2"
              onClick={() => props.onCloseModal()}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => props.onCloseModal()}
      style={modalStyles}
    >
      <div className="h5 mb-4">並び替えエラー</div>
      <div className="d-flex flex-column">
        <div>
          指定された依頼全てを時間内に配達し終えることができない可能性が高いため、最適化できません。
        </div>
        <div>
          下記の依頼のアサインを変更するか、手動による並び替えを行ってください。
        </div>
        <div
          className="px-4 d-flex flex-column overflow-auto"
          style={{ maxHeight: "calc(100vh - 250px)" }}
        >
          <div className="d-flex flex-column">
            {contents.droppedItems.map((item) => {
              return (
                <div
                  key={`${item.requestId}_${item.destinationType}`}
                  className="px-0 col-10 d-flex align-items-center"
                >
                  <div className="col-6">依頼 : ID#{item.requestId}</div>
                  <div className="col-6 d-flex align-items-center">
                    <span>地点 : </span>
                    {item.destinationType == "sender" ? (
                      <i className="fas fa-fw fa-store mr-2" />
                    ) : (
                      <i className="fas fa-fw fa-user mr-2" />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-end my-2">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={() => props.onCloseModal()}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
