import { format } from "date-fns";
import { RawCarryStaff, RawRequest } from "../interfaces/entities";
import RequestForMap from "../interfaces/Request";
import { convertSameJstTimeFromUtc } from "./TimeUtils";

export function convertRequestIntoForMap(
  request: RawRequest,
  carryStaff: Pick<RawCarryStaff, "id" | "name"> | undefined
) {
  const _req: RequestForMap = {
    id: request.id,
    carryStaffId: request.carry_staff_id,
    carryStaff: carryStaff || null,
    isReserved: null,
    readyTimeAt: new Date(request.ready_time_at),
    pickupStartTime: convertSameJstTimeFromUtc(
      request.pickup_start_time
    ),
    pickupEndTime: convertSameJstTimeFromUtc(
      request.pickup_end_time
    ),
    deliveryTimeAt: new Date(request.delivery_time_at),
    deliveryTimeSlotStartTime: convertSameJstTimeFromUtc(
      request.delivery_time_slot_start_time
    ),
    deliveryTimeSlotEndTime: convertSameJstTimeFromUtc(
      request.delivery_time_slot_end_time
    ),
    deliveryTimeSlotName: request.delivery_time_slot_name,
    isDeliveryTimeSpecified: request.is_delivery_time_specified,
    statusText: getStatusText(request.status),
    sender: {
      name: request.sender_name,
      address: request.sender_full_address,
      lat: +request.sender_lat,
      lng: +request.sender_lng,
    },
    receiver: {
      name: request.receiver_name,
      address: request.receiver_full_address,
      lat: +request.receiver_lat,
      lng: +request.receiver_lng,
    },
    // 以下3つは途中から追加した項目なため、なければデフォルト値
    deliveryType: request.delivery_type || null,
    createdById: null,
    otherSystemId: null,

    estimatedDeliveryTime: null,
    estimatedPickupTime: null,
  };
  // 型上ではstringだけど、実際には(テーブル定義上では)nullがありうる
  // ので、存在チェック
  if (request.delivery_time_slot_start_time != null) {
    _req["deliveryTimeSlotStartTime"] = new Date(
      request.delivery_time_slot_start_time
    );
  }
  return _req;
}

export const getStatusText = (status: RawRequest["status"]) => {
  switch (status) {
    case "wait_store":
      return "店舗処理待";
    case "uncheck":
      return "未確認";
    case "checked":
      return "確認済";
    case "pickup":
      return "店舗ピックアップ済";
    case "delivered":
      return "配達済";
    case "onhold":
      return "留め置き";
    case "return":
      return "持ち戻り";
    case "failed":
      return "配達失敗";
    case "cancel":
      return "キャンセル";
    case "store_cancel":
      return "（予備）";
  }
};

export const getStoreStatusText = (storeStatus: RawRequest["store_status"]) => {
  switch (storeStatus) {
    case "failed":
      return "注文失敗";
    case "waiting_check":
      return "確認待ち";
    case "waiting_settlement":
      return "決済処理待ち";
    case "waiting_approve":
      return "承認待ち";
    case "denied":
      return "注文却下";
    case "approved":
      return "承認済み";
    case "shipped":
      return "出荷済み";
    case "delivered":
      return "配達完了";
    case "cancel":
      return "キャンセル";
  }
};

export const getRangeDeliveryTimeSlotTimeLabel = (request: RawRequest) => {
  const deliveryTimeAt = new Date(request.delivery_time_at);

  if (!request.delivery_time_slot_start_time) {
    return format(deliveryTimeAt, "MM/dd HH:mm");
  }

  const deliveryTimeSlotStartTime = convertSameJstTimeFromUtc(
    request.delivery_time_slot_start_time
  );
  return `${format(deliveryTimeAt, "MM/dd")} ${format(
    deliveryTimeSlotStartTime!,
    "HH:mm"
  )} 〜 ${format(deliveryTimeAt, "HH:mm")}`;
};

/**
 * requestにdelivery_time_slot_start_timeとdelivery_time_slot_end_timeの両方が設定されているかどうかを判定する関数.
 *
 * @param request
 * @returns
 */
export const existsDtSlot = (request: RawRequest) => {
  if (
    request.delivery_time_slot_start_time == null ||
    request.delivery_time_slot_start_time.length == 0
  ) {
    return false;
  }
  if (
    request.delivery_time_slot_end_time == null ||
    request.delivery_time_slot_end_time.length == 0
  ) {
    return false;
  }

  return true;
};

export function checkCanChangeAssigning(request: RawRequest) {
  return request.status == "wait_store" || request.status == "uncheck";
}
