import { Coords } from "google-map-react";
import { observer } from "mobx-react";
import React from "react";
import Wkt from "wicket";

import { axiosPost } from "../utils/AxiosClient";
import { getCenterCoords } from "./MapAreaPicker/utils/WktPolygonUtils";
import MapAreaPicker from "./MapAreaPicker/MapAreaPicker";

declare var gon: any;

interface Props {
  targetId: number;
  lat: number;
  lng: number;
  wkt: string;
}

interface State {
  wkt: string;
  center: Coords;
}

class FacilityWithinAreaMapEditor extends React.Component<Props, State> {
  wkt: any = null;

  constructor(props: Props) {
    super(props);

    const { lat, lng, wkt } = props;
    const center = getCenterCoords(wkt);
    this.state = {
      wkt,
      center: center ? center : { lat, lng },
    };
    this.wkt = new Wkt.Wkt();
  }

  validateWktText = (wktText: string) => {
    try {
      this.wkt.read(wktText);
    } catch (error) {
      return false;
    }
    return true;
  };

  updateByWkt = async (targetArea) => {
    const isValid = this.validateWktText(targetArea);

    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!isValid) throw new Error("wktの形式が不正です");

        const res = await axiosPost.post(
          `/api/facilities/${this.props.targetId}/update_within_area_wkt`,
          { within_area_wkt: targetArea }
        );
        this.setState({
          wkt: res.data.within_area_wkt,
        });
        resolve();
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  destroy = async () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const res = await axiosPost.post(
          `/api/facilities/${this.props.targetId}/delete_within_area_wkt`,
        );
        this.setState({ wkt: "" });
        resolve();
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  render() {
    const { wkt, center } = this.state;

    return (
      <div style={{ width: "100%", height: 720 }}>
        <MapAreaPicker
          gmapsApiKey={gon.google_api_key}
          center={center}
          wkt={wkt}
          onUpdateByWkt={this.updateByWkt}
          onDestroy={this.destroy}
        />
      </div>
    );
  }
}

export default observer(FacilityWithinAreaMapEditor);
