import React from "react";
import Spinner from "./Spinner";

interface Props {
    durationMs?: number
    size?: number
    hexColor?: string
    opacity?: number 
    borderWidth?: number
    progress?: number
}
export class PinSpinner extends React.Component<Props> {
    render() {
        const {
            durationMs = 2000,
            size = 28,
            hexColor = "#4285F4",
            opacity = 0.2,
            borderWidth = 6,
            progress = 25,
        } = this.props

        const rgbColor = this.hex2rgb(hexColor)
        return (
            <div 
            className="map-marker-pin--spinner"
          
            >
                <Spinner
                    color={hexColor}
                    subColor={`rgba(${rgbColor[0]},${rgbColor[1]},${rgbColor[2]},${opacity})`}
                    size={size}
                    durationMs={durationMs}
                    borderWidth={borderWidth}
                    progress={progress}
                    
                />

            </div>
        )
    }
    private hex2rgb ( hex: string ) {
        if ( hex.slice(0, 1) === "#" ) hex = hex.slice(1) ;
        if ( hex.length === 3 ) hex = hex.slice(0,1) + hex.slice(0,1) + hex.slice(1,2) + hex.slice(1,2) + hex.slice(2,3) + hex.slice(2,3) ;

        return [ hex.slice( 0, 2 ), hex.slice( 2, 4 ), hex.slice( 4, 6 ) ].map( function ( str ) {
            return parseInt( str, 16 ) ;
        } ) ;
    }
}