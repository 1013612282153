import React from "react";
import Modal from "react-modal";
import { observer } from "mobx-react";
import { MapMarkerPin } from "../components/MapMarkerPin";
import WktMultiPolygon from "../components/WktMultiPolygon";
import MapAttributes from "../constants/MapAttributes";
import { calcCenter } from "../utils/GeographyUtils";
import GoogleMap from "../components/Common/GoogleMap";

Modal.setAppElement("#wrapper");

declare var gon: any;

interface Props {
  points?: { lat: number; lng: number }[];
  areaWkts?: string[];
  mapTitle?: string;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  isModalOpen: boolean;
}

class SimpleMapModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      isModalOpen: false,
    };
  }

  componentDidMount(): void {}

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  render() {
    const center = calcCenter({
      points: this.props.points,
      areaWkts: this.props.areaWkts,
    });
    const isModalOpen = this.state.isModalOpen;
    const mapTitle = this.props.mapTitle ?? "マップ情報";

    return (
      <>
        <div>
          <button
            className="btn btn-primary btn-sm ml-2"
            onClick={this.toggleModal}
          >
            MAP
          </button>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.toggleModal}
          style={modalStyles}
        >
          <div className="d-flex mb-3" style={{ position: "relative" }}>
            <h5>{mapTitle}</h5>
            <div
              style={{ position: "absolute", right: 0 }}
              className="fas fa-times"
              onClick={this.toggleModal}
            ></div>
          </div>

          <div className="container" style={{ width: "calc(100vw - 280px)" }}>
            <div className="row">
              <div className="col" style={{ height: "calc(100vh - 350px)" }}>
                <GoogleMap
                  bootstrapURLKeys={{
                    key: gon.google_api_key,
                  }}
                  defaultCenter={{ lng: center.lng, lat: center.lat }}
                  defaultZoom={14}
                  center={{ lng: center.lng, lat: center.lat }}
                  resetBoundsOnResize={true}
                  hoverDistance={MapAttributes.K_SIZE / 2}
                  onGoogleApiLoaded={({ map, maps }) => {
                    this.setState({
                      map: map,
                      mapApi: maps,
                      mapLoaded: true,
                    });
                  }}
                >
                  {this.createMarkers()}
                  {this.createPolygons()}
                </GoogleMap>
              </div>
            </div>
            <div>
              <button
                className="btn btn-primary btn-sm mt-2 float-right"
                onClick={this.toggleModal}
              >
                閉じる
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  createMarkers() {
    if (this.props.points == null || this.props.points.length == 0) {
      return null;
    }

    return this.props.points.map((point) => {
      return (
        <MapMarkerPin
          key={"marker_" + Math.random()}
          lat={point.lat}
          lng={point.lng}
          svgIcon="person"
          size={28}
          onClick={() => {}}
        />
      );
    });
  }

  createPolygons() {
    if (this.state.map == null) {
      return null;
    }

    if (this.props.areaWkts == null || this.props.areaWkts.length == 0) {
      return null;
    }

    return this.props.areaWkts.map((areaWkt) => {
      return (
        <WktMultiPolygon
          key={"area_" + Math.random()}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={areaWkt}
        />
      );
    });
  }
}

export default observer(SimpleMapModal);

const modalStyles: Modal.Styles = {
  content: {
    maxHeight: "calc(100vh - 200px)",
    maxWidth: "calc(100vw - 200px)",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 100,
  },
};
