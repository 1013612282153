import { format } from "date-fns";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Modal from "react-modal";
import DateSelection from "../../../../components/Common/DateSelection";
import CustomSelectionView from "../../../../components/CustomSelectionView";
import { RawTerritory } from "../../interfaces";
import { pageStore } from "../../stores";

interface Props {
  territories: RawTerritory[];
}

interface State {
  isModalOpen: boolean;
}

class CarryStaffsSearchComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  /**
   * mainProcessを実行する前に、もしアサイン内容に変更があれば、確認台やログを表示してから実行するためのメソッド.
   * @param mainProcess
   * @returns
   */
  private async executeAfterConfirm(mainProcess: () => Promise<void>) {
    if (pageStore.existsAssigningChanged()) {
      const confirmed = confirm(
        "検索条件を変更すると保存前のアサインが全てリセットされます。変更しますか？"
      );
      if (!confirmed) {
        return;
      }
    }

    await mainProcess();
  }

  render() {
    const isModalOpen = this.state.isModalOpen;

    return (
      <div className="col">
        <div className="col-12 d-flex flex-wrap">
          <div className="d-flex flex-column mr-2">
            <div style={styles.formLabel}>スタッフ名</div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: 180 }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="名前"
                value={pageStore.searchText || ""}
                onChange={(event) =>
                  pageStore.changeSearchText(event.target.value)
                }
                onKeyPress={async (e) => {
                  if (e.key == "Enter") {
                    await this.executeAfterConfirm(async () => {
                      pageStore.resetRequestAssignChange();
                      await pageStore.loadAndUpdateCarryStaffs();
                    });
                  }
                }}
              />
              <div
                style={{
                  position: "relative",
                  transform: "translate(-30px, 0)",
                  pointerEvents: "none",
                }}
              >
                <i className="fas fa-search" />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column mr-2" style={{ minWidth: 225 }}>
            <div style={styles.formLabel}>担当エリア</div>
            <CustomSelectionView
              selections={this.props.territories.map((tr) => ({
                id: `${tr.id}`,
                value1: tr.name,
              }))}
              queryKey="sample"
              sectionName="担当エリア"
              cookieKeyString="route_delivery_assign_territories_selection_state"
              isSelectingAllByDefault={true}
              acceptUnselectedState={false}
              onChange={async (selectionIds) => {
                await this.executeAfterConfirm(async () => {
                  pageStore.resetRequestAssignChange();
                  pageStore.selectTerritories(
                    selectionIds.map((_id) => +_id).filter((_id) => _id > 0)
                  );
                  await pageStore.loadAndUpdateCarryStaffs();
                });
              }}
            />
          </div>

          <div className="d-flex flex-column mr-2">
            <div style={styles.formLabel}>スケジュール対象日</div>
            <div className="d-flex justify-content-center align-items-center">
              <DateSelection
                date={
                  new Date(`${pageStore.schedulesTargetDate} 00:00:00+09:00`)
                }
                showTodayButton={false}
                onChangeDate={async (newDate) => {
                  await this.executeAfterConfirm(async () => {
                    pageStore.resetRequestAssignChange();
                    await pageStore.changeTargetDateAndLoadCarryStaffs(
                      format(newDate, "yyyy-MM-dd")
                    );
                  });
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center mt-3 mx-2">
            <button
              className="btn btn-secondary btn-sm"
              onClick={this.toggleModal}
            >
              表示条件
            </button>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.toggleModal}
          style={modalStyles}
        >
          <div className="modal-header">
            <h5 className="modal-title">強調表示する内容を選択してください。</h5>
            <button
              className="btn btn-outline-secondary"
              onClick={() => this.toggleModal()}
            >
              <i className="fa fa-times" />
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column mr-2">
              <div className="d-flex flex-row align-items-center">
                <input
                  type="radio"
                  id="all-time-form"
                  name="highlight-radio"
                  checked={pageStore.targetHighlighting === "all"}
                  onChange={() => {
                    pageStore.setTargetHighlighting("all");
                  }}
                />
                <label
                  htmlFor="all-time-form"
                  className="form-check-label auto"
                >
                  条件なし
                </label>
                <input
                  type="radio"
                  id="unspecified-time-form"
                  name="highlight-radio"
                  checked={pageStore.targetHighlighting === "unspecified"}
                  onChange={() => {
                    pageStore.setTargetHighlighting("unspecified")
                  }}
                />
                <label
                  htmlFor="unspecified-time-form"
                  className="form-check-label auto"
                >
                  配達時間指定なしのみ
                </label>
                <input
                  type="radio"
                  id="specified-time-form"
                  name="highlight-radio"
                  checked={pageStore.targetHighlighting === "specified"}
                  onChange={() => {
                    pageStore.setTargetHighlighting("specified")
                  }}
                />
                <label
                  htmlFor="specified-time-form"
                  className="form-check-label auto"
                >
                  配達時間指定あり
                </label>
                <div>
                  <input
                    type="time"
                    id="from-time-form"
                    className="form-control col"
                    disabled={pageStore.targetHighlighting !== "specified"}
                    defaultValue={pageStore.deliveryTimeRangeFrom}
                    onChange={(e) =>
                      pageStore.changeTargetDeliveryTimeRange(
                        "from",
                        e.target.value
                      )
                    }
                  />
                </div>
                <span className="mx-1">〜</span>
                <div>
                  <input
                    type="time"
                    id="to-time-form"
                    className="form-control col"
                    disabled={pageStore.targetHighlighting !== "specified"}
                    defaultValue={pageStore.deliveryTimeRangeTo}
                    onChange={(e) =>
                      pageStore.changeTargetDeliveryTimeRange(
                        "to",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default observer(CarryStaffsSearchComponent);

const styles: { [key in "formLabel"]: React.CSSProperties } = {
  formLabel: {
    fontSize: 12,
  },
};

const modalStyles: Modal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};