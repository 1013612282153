import React, {useEffect, useRef} from "react";
import type {MapApi} from "../index";

interface Props {
    mapApi: MapApi,
    children: React.ReactNode,
}

export function Actions(props: Props) {
    const componentRef = useRef(null)
    const {mapApi, children} = props

    useEffect(() => {
        if (componentRef.current) {
            mapApi.map.controls[mapApi.maps.ControlPosition.RIGHT_BOTTOM].push(componentRef.current);
        }
        return () => {
            mapApi.map.controls[mapApi.maps.ControlPosition.RIGHT_BOTTOM].clear()
        }
    }, [])
    return (
        <div style={{visibility: "hidden"}}>
            <div ref={componentRef} style={{
                background: "#ffffff",
                padding: 8,
                marginBottom: 54,
                marginRight: 8,
                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                display: "flex",
                gap: 8
            }}>
                {
                    children
                }
            </div>
        </div>
    )
}