import React, {useEffect, useRef} from "react";
import {MapApi} from "../index";

interface Props {
    mapApi: MapApi,
    children: React.ReactNode
    position: 'BOTTOM' | 'BOTTOM_LEFT' | 'BOTTOM_CENTER' | 'BOTTOM_RIGHT' | 'LEFT' | 'LEFT_BOTTOM' | 'LEFT_TOP' | 'RIGHT' | 'RIGHT_BOTTOM' | 'RIGHT_TOP' | 'TOP' | 'TOP_LEFT' | 'TOP_RIGHT'
    style?: React.CSSProperties
}

export default function MapControl(props: Props) {
    const componentRef = useRef(null)
    const {
        mapApi,
        children,
        position,
        style
    } = props

    useEffect(() => {
        if (componentRef.current) {
            mapApi.map.controls[mapApi.maps.ControlPosition[position]].push(componentRef.current);
        }
        return () => {
            mapApi.map.controls[mapApi.maps.ControlPosition[position]].clear()
        }
    }, [])

    return (
        <div style={{visibility: "hidden"}}>
            <div ref={componentRef} style={style}>
                {children}
            </div>
        </div>
    )
}