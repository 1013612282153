export default {
  K_SIZE: 36,
  SHIBUYA_STATION_LATITUDE: 35.658034,
  SHIBUYA_STATION_LONGITUDE: 139.701636,

  DEFAULT_ZOOM: 12,
  SPOTREMARK_DEFAULT_ZOOM: 14,
  MIN_SERVICE_AREA_HEX_ADDITIONAL_ZOOM: 9,

  LOCAL_STRAGE_KEY_OVERLOOKMAP_CENTER_LAT: "adms_overlookmap_centerlat-",
  LOCAL_STRAGE_KEY_OVERLOOKMAP_CENTER_LNG: "adms_overlookmap_centerlng-",
  LOCAL_STRAGE_KEY_OVERLOOKMAP_ZOOM: "adms_overlookmap_zoom-",

  LOCAL_STRAGE_KEY_CARRYSTAFFOVERLOOKMAP_CENTER_LAT:
    "adms_carrystaffoverlookmap_centerlat-",
  LOCAL_STRAGE_KEY_CARRYSTAFFOVERLOOKMAP_CENTER_LNG:
    "adms_carrystaffoverlookmap_centerlng-",
  LOCAL_STRAGE_KEY_CARRYSTAFFOVERLOOKMAP_ZOOM:
    "adms_carrystaffoverlookmap_zoom-",
  LOCAL_STRAGE_BASE_KEY_CARRYSTAFFOVERLOOKMAP: "adms_carrystaffoverlookmap",
  LOCAL_STRAGE_KEY_CARRYSTAFFOVERLOOKMAP_VISIBLE_SEQUENCE:
    "adms_carrystaffoverlookmap_visible_sequence-",

  LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_CENTER_LAT:
    "adms_deaasareaoverlookmap_centerlat-",
  LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_CENTER_LNG:
    "adms_deaasareaoverlookmap_centerlng-",
  LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_ZOOM: "adms_deaasareaoverlookmap_zoom-",

  LOCAL_STRAGE_KEY_SPOTREMARKMAP_CENTER_LAT: "adms_spotremarkmap_centerlat-",
  LOCAL_STRAGE_KEY_SPOTREMARKMAP_CENTER_LNG: "adms_spotremarkmap_centerlng-",
  LOCAL_STRAGE_KEY_SPOTREMARKMAP_ZOOM: "adms_spotremarkmap_zoom-",

  LOCAL_STRAGE_KEY_SPOTREMARKOVERLOOKMAP_CENTER_LAT:
    "adms_spotremarkoverlookmap_centerlat-",
  LOCAL_STRAGE_KEY_SPOTREMARKOVERLOOKMAP_CENTER_LNG:
    "adms_spotremarkoverlookmap_centerlng-",
  LOCAL_STRAGE_KEY_SPOTREMARKOVERLOOKMAP_ZOOM:
    "adms_spotremarkoverlookmap_zoom-",
  LOCAL_STRAGE_KEY_DELIVERD_TIME_BEFORE_MINUTES:
    "adms_deliverd_time_before_minutes-",

  LOCAL_STRAGE_KEY_ROUTEDELIVERYREQUESTS_CENTER_LAT:
    "adms_routedeliveryrequests_centerlat-",
  LOCAL_STRAGE_KEY_ROUTEDELIVERYREQUESTS_CENTER_LNG:
    "adms_routedeliveryrequests_centerlng-",
  LOCAL_STRAGE_KEY_ROUTEDELIVERYREQUESTS_ZOOM:
    "adms_routedeliveryrequests_zoom-",

  LOCAL_STRAGE_BASE_KEY_ROUTEDELIVERYASSIGN: "adms_routedeliveryassign",

  LOCAL_STRAGE_BASE_KEY_H3_MIN_PICKUP_MINUTES_INDEX_SETTINGS:
    "adms_h3_min_pickup_minutes_settings_",
};
