import React, {CSSProperties, MouseEvent, useEffect, useMemo, useRef, useState} from "react";
import {INotification} from "./interfaces";


interface Props {
  notifications: INotification[]
  isOpen: boolean
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  horizon: "left" | "right"
  vertical: "top" | "bottom"
}

export const NotificationAlertButton = (props: Props) => {

  const {notifications, onClick, isOpen} = props

  const [isHover, setIsHover] = useState<boolean>(false)


  const refPulse = useRef<HTMLDivElement>(null)
  const refBadgeText = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // notificationsが0の場合は実行しない
    if (notifications.length <= 0) return

    if (!refPulse.current) return

    refPulse.current.animate([
      {transform: "scale(1)"},
      {opacity: 0.05},
      {transform: "scale(5)", opacity: 0}
    ], {
      duration: 1000,
      easing: 'ease-in-out',
    })

    if (!refBadgeText.current) return
    refBadgeText.current.animate([
      {transform: "scale(0)"},
      {transform: "scale(1.5)"},
      {transform: "scale(1)"}
    ], {
      duration: 250,
      easing: 'ease-in-out',
    })

  }, [notifications, refPulse])

  // Stylesを作成
  const styles = createStyles(props)

  return (
    <>
      <style>{styles.animShake}</style>
      <div style={styles.wrapStyle}>
        <button
          style={
          isHover ? {
            ...styles.buttonHoverStyle
          }:{
            ...styles.buttonDefaultStyle
          }
          }
          onClick={onClick}
          onMouseOut={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {
            !isOpen &&
              <i className="fas fa-bell" style={{
                animation: notifications.length > 0 ? `shake 0.5s infinite` : '',
              }}/>
          }
          {
            isOpen &&
              <i className="far fa-bell-slash"/>
          }

        </button>
        {
          !isOpen &&
            <>
              <div style={{
                  ...styles.pulseStyle,
                }}
                     ref={refPulse}
              />

                <div style={styles.badgeStyle}>
                  <span ref={refBadgeText}>
                    {notifications.length}
                  </span>
              </div>
            </>
        }

      </div>

    </>

  )
}

/**
 * Styles
 * @param props
 */
const createStyles = (props: Props) => {
  const {notifications, isOpen, horizon, vertical} = props
  return useMemo(() => {
    const buttonSize = 42

    const wrapStyle: CSSProperties = {
      position: "relative",

    }


    const buttonStyle: CSSProperties = {
      width: buttonSize,
      height: buttonSize,
      borderRadius: buttonSize,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "background 0.5s",
      pointerEvents: "all",
      marginRight: horizon === "right" ? 16 : 0,
      marginLeft: horizon === "left" ? 16 : 0,
      marginBottom: vertical === "bottom" ? 16 : 0,
      marginTop: vertical === "top" ? 16 : 0,
      outline: "none",
      border: "none",
    }

    const buttonDefaultStyle: CSSProperties = {
      background: !isOpen ? "#f1f1f1": "#666",
      color: !isOpen ? "#666": "#fff",
      ...buttonStyle,
    }

    const buttonHoverStyle: CSSProperties = {
      background: !isOpen ? "#ccc": "#999",
      color: !isOpen ? "#666": "#fff",
      ...buttonStyle,
    }

    const badgePositionAndSize: CSSProperties = {
      position: "absolute",
      top: -8,
      left: horizon === "right" ? -8: "auto",
      right: horizon === "left" ? -8: "auto",
      width: 24,
      height: 24,
      borderRadius: 34,
    }

    const badgeStyle: CSSProperties = {
      ...badgePositionAndSize,
      background: "#dc3545",
      color: "#ffffff",
      textAlign: "center",
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "0.2s transform ease-out",
      transform: `scale(${notifications.length > 0 ? 1 : 0})`
    }

    const pulseStyle: CSSProperties = {
      ...badgePositionAndSize,
      background: "#dc3545",
      opacity: 0.5,
      transform: "scale(0)"
    }


    const animShake: string =
      `@keyframes shake {
            0% { transform: rotate(0deg); }
            25% { transform: rotate(25deg); }
            50% { transform: rotate(0eg); }
            75% { transform: rotate(-25deg); }
            100% { transform: rotate(0deg); }
      }`
    return {
      wrapStyle,
      buttonDefaultStyle,
      buttonHoverStyle,
      badgeStyle,
      pulseStyle,
      animShake,
    }
  }, [notifications, isOpen, horizon, vertical])
}
