import React, { ChangeEvent } from "react";


interface Props {
  defaultValue: number;
  disabled: boolean;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export default function DeliveredTimeBeforeMinutesSelect({ onChange, defaultValue, disabled = false }: Props) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <label style={{ flex: 1, margin: 0 }}>お届け希望時間</label>
      <select
        className="custom-select"
        style={{ flex: 1 }}
        placeholder="お届け希望時間を選択"
        defaultValue={defaultValue.toString()}
        disabled={disabled}
        onChange={onChange}
      >
        <option value={0}>-------</option>
        <option value={30}>30分以内</option>
        <option value={60}>1時間以内</option>
        <option value={120}>2時間以内</option>
        <option value={360}>6時間以内</option>
        <option value={1440}>1日以内</option>
        <option value={4320}>3日以内</option>
        <option value={10080}>7日以内</option>
      </select>
    </div>
  )
}
