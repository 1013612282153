import React, { ReactNode } from "react"
import { PinShape, Shape } from "./PinShape"
import { MarkerIcon } from "./MarkerIcon"
import { PinPulse } from "./PinPulse";
import { PinBadge } from "./PinBadge";
import { PinSpinner } from "./PinSpinner";

interface PulseStyle {
  hexColor?: string,
  durationMs?: number,
  size?: number,
}
interface BadgeStyle {
  color?: string
  iClass?: string | null
}
interface SpinnerStyle {
  size?: number
  hexColor?: string
  borderWidth?: number
  durationMs?: number
  opacity?: number
  progress?: number
}
interface Props {
  lat?: number
  lng?: number
  icon: string
  size: number
  shape?: Shape
  iconColor?: string
  pinColor?: string
  strokeWidth?: number
  reverse?: boolean
  pulse?: boolean
  spinner?: boolean
  spinnerStyle?: SpinnerStyle
  pulseStyle?: PulseStyle
  badgeText?: string | null
  badgeStyle?: BadgeStyle
  secondBadgeText?: string | ReactNode
  secondBadgeStyle?: BadgeStyle
  thirdBadgeText?: string | ReactNode
  thirdBadgeStyle?: BadgeStyle
  pinImageUrl?: string
}
export class SpotReportMapMarkerPin extends React.Component<Props> {
  render() {
    const {
      icon,
      shape = "drop",
      iconColor = "#fff",
      pinColor = "#666",
      strokeWidth = 2,
      size,
      pulse,
      pulseStyle,
      badgeText,
      badgeStyle,
      secondBadgeText,
      secondBadgeStyle,
      thirdBadgeText,
      thirdBadgeStyle,
      spinner,
      spinnerStyle,
      pinImageUrl
    } = this.props

    const fill = !this.props.reverse ? pinColor : iconColor
    const stroke = !this.props.reverse ? iconColor : pinColor
    const iconSize = size * 0.7

    return (
      <div
        className="map-marker-pin"
        style={{
          position: "relative",
          width: 0,
          height: 0,
        }}
      >
        {
          pulse &&
          <>
            <PinPulse
              {...pulseStyle}
            />
          </>
        }
        {
          spinner &&
          <PinSpinner
            {...spinnerStyle}
          />
        }
        <div
          className="map-marker-pin--inner"
          style={{
            width: size,
            height: size * 1.4, // ピン画像のアスペクト比率 1:1.4
            transform: `translate(-${size / 2}px, -${(size * 1.4)}px)`, // 座標中心に表示を合わせる
            flex: 1, justifyContent: 'center', alignItems: 'center'
          }}
        >
          {
            (badgeText || (badgeStyle && badgeStyle.iClass)) &&
            <PinBadge {...badgeStyle}>{badgeText}</PinBadge>
          }
          {
            (secondBadgeText || (secondBadgeStyle && secondBadgeStyle.iClass)) &&
            <PinBadge position="top-left" {...secondBadgeStyle}>{secondBadgeText}</PinBadge>
          }
          {
            (thirdBadgeText || (thirdBadgeStyle && thirdBadgeStyle.iClass)) &&
            <PinBadge position="number" {...thirdBadgeStyle}>{thirdBadgeText}</PinBadge>
          }
          {
            (pinImageUrl) &&
            <PinBadge position="middle-left" color={pinColor}>
            {/* moveMethodのiconをバッジ化した際はマウスオーバーで拡大させたくないためsizeを手動設定 */}
            <MarkerIcon icon={icon} color={stroke} size={11} />
            </PinBadge>
          }

          <PinShape
            size={size}
            // 画像が存在する場合には、その画像に対して枠をつけたく、その枠用にstroke用の色とfill用の色を合わせる
            fill={pinImageUrl ? stroke : fill}
            shape={shape}
            stroke={stroke}
            strokeWidth={strokeWidth}
            pinImageUrl={pinImageUrl}
          />
          <div className="map-marker-pin--icon-wrapper"
            style={
              {
                position: "absolute",
                bottom: size * 0.55, // アイコンが中心に合うように微調整している
                right: size * 0.17 // アイコンが中心に合うように微調整している
              }
            }
          >
            {
              (!pinImageUrl) &&
              <MarkerIcon icon={icon} size={iconSize} color={"white"} />
            }
          </div>

        </div>
      </div>

    )
  }
}
