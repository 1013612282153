import { format } from "date-fns";
import { action, observable, runInAction } from "mobx";
import type { GeoJSONLineString } from "wellknown";
import {
  createSequencesFromSummaries,
  loadRequestChangeSummaries,
  loadRequestsAndSchedules,
  loadSequences,
  mergeRequestsAndSequences,
} from "../../../utils/CarryStaffRequestSequenceUtils";
import {
  convIntoRoutingApiParams,
  getAndSaveLocationsRoutes,
  loadLocationsRoutes,
} from "../../../utils/LocationsRoutesUtils";
import {
  RoutesApiCode,
  RoutesApiParamSetting,
  SelectableApiType,
  strictApiParamSetting,
} from "../../../utils/routes-apis/api-code-utils";
import {
  ExtendedRawRequest,
  convertIntoExtendedRequests,
} from "../../../utils/routes-apis/common-utils";
import { LOCATIONS_ROUTES_API_CODE } from "../consts";
import type {
  DestinationType,
  RawCarryStaff,
  RawCarryStaffSchedule,
  RawCurrentLocation,
  RawTerritory,
  RawVehicleType,
  RequestSequence,
  RelatedCarryStaffWithSchedules,
  RequestForCount,
  TargetTerm,
  RawLocationsRoute,
  RawRoutesApiParamSetting,
} from "../interfaces";
import {
  checkInvalidSequenceAndUpdate,
  loadRelatedCarryStaffs,
  mergeSchedulesIntoCarryStaffs,
} from "../utils";

type ApiRoutesMap = Map<string, RoutesMap>;

type RoutesMap = Map<string, GeoJSONLineString>;

type RoutePath = {
  from: { lat: string; lng: string };
  to: { lat: string; lng: string };
};

export class PageStore {
  carryStaff: RawCarryStaff;

  @observable
  carryStaffSchedules: RawCarryStaffSchedule[] = [];

  @observable
  currentLocation: RawCurrentLocation | null;

  @observable.ref
  extendedRequests: ExtendedRawRequest[] = [];

  // ルート配達スタッフではない場合、存在しない
  territory: RawTerritory | null;

  vehicleType: RawVehicleType | null;

  @observable.ref
  sequences: RequestSequence[] = [];

  @observable.ref
  actualSequences: RequestSequence[] = [];

  // APIパラメーター設定から作られるハッシュをキーとするRoutesMapのマップ
  // キーに含めるAPIは、パラメーター設定で指定されるAPIではなく、
  // そのルート情報を取得するのに利用したAPI(現状Geoapify固定)としている。
  // なので、このMapから情報を取得する際には、パラメーター設定をそのまま指定すれば良いわけではないことに注意。
  @observable.ref
  apiRoutesMap: ApiRoutesMap = new Map();

  sequenceMap: Map<string, number> = new Map();

  @observable
  casLoading = false;

  @observable
  reqLoading = false;

  @observable.ref
  assignableCarryStaffs: RelatedCarryStaffWithSchedules[] = [];

  @observable.ref
  relatedCarryStaffs: RelatedCarryStaffWithSchedules[] = [];

  @observable
  relCasLoading = false;

  @observable.ref
  assignedRequests: RequestForCount[] = [];

  @action
  async loadAndSetRequestsAndSchedules(
    targetTerm: TargetTerm,
    callback?: () => void
  ) {
    this.reqLoading = true;

    const targetTermDate = new Date(targetTerm.date);
    try {
      const response = await loadRequestsAndSchedules({
        targetToDate: format(targetTermDate, "yyyy-MM-dd"),
        targetFromDate:
          targetTerm.type == "specified"
            ? format(targetTermDate, "yyyy-MM-dd")
            : null,
        carryStaffId: this.carryStaff.id,
      });

      runInAction(() => {
        this.carryStaffSchedules = response.data.carry_staff_schedules || [];
        this.extendedRequests = convertIntoExtendedRequests(
          targetTerm.date,
          response.data.requests,
          // 並び替え画面では前後時間調整用パラメーターは全て0のものを指定
          strictApiParamSetting
        );
      });
    } catch (err) {
      console.error("[loadAndSetSequences]", err);
    } finally {
      runInAction(() => {
        this.reqLoading = false;
        if (callback) {
          callback();
        }
      });
    }
  }

  @action
  async loadAndSetSequences(targetTerm: TargetTerm, callback?: () => void) {
    this.reqLoading = true;

    try {
      const response = await loadSequences({
        targetDate: format(new Date(targetTerm.date), "yyyy-MM-dd"),
        carryStaffId: this.carryStaff.id,
      });
      const mergedSequences = mergeRequestsAndSequences({
        requests: this.extendedRequests,
        sequences: response.data.sequences,
        isPast: targetTerm.isPast,
      });
      const { sequences, hasDisallowed } = checkInvalidSequenceAndUpdate(
        mergedSequences,
        this.carryStaffSchedules
      );

      runInAction(() => {
        this.sequences = sequences;
        this.setSequenceMap();
      });
    } catch (err) {
      console.error("[loadAndSetSequences]", err);
    } finally {
      runInAction(() => {
        this.reqLoading = false;

        if (callback) {
          callback();
        }
      });
    }
  }

  @action
  async loadAndSetActualSequences(
    targetTerm: TargetTerm,
    callback?: () => void
  ) {
    this.reqLoading = true;

    const targetTermDate = new Date(targetTerm.date);
    try {
      const requestIds = this.extendedRequests.map((req) => req.id);
      const response = await loadRequestChangeSummaries({
        targetDate: format(targetTermDate, "yyyy-MM-dd"),
        requestIds: requestIds,
      });
      const sequences = createSequencesFromSummaries(
        this.extendedRequests,
        response.data.request_change_summaries,
        targetTermDate
      );

      runInAction(() => {
        this.actualSequences = sequences;
      });
    } catch (err) {
      console.error("[loadAndSetRequestChangeSummaries]", err);
    } finally {
      runInAction(() => {
        this.reqLoading = false;
        if (callback) {
          callback();
        }
      });
    }
  }

  @action
  setSequence(newSequences: RequestSequence[]) {
    this.sequences = newSequences;
    this.setSequenceMap();
  }

  private setSequenceMap() {
    this.sequenceMap = new Map(
      this.sequences.map((seq, index) => [seq.id, seq.sequence])
    );
  }

  public getSequence(requestId: number, destinationType: DestinationType) {
    return this.sequenceMap.get(`${requestId}_${destinationType}`) || 0;
  }

  public getRequestSequenceByRequestId(
    requestId: number,
    destinationType: DestinationType,
    isActualSequenceDisplayed: boolean
  ) {
    const sequences = isActualSequenceDisplayed
      ? this.actualSequences
      : this.sequences;
    return sequences.find(
      (seq) =>
        seq.request_id === requestId && seq.destination_type === destinationType
    );
  }

  @action
  public async loadAssignableStaffs(
    targetDate: string,
    carryStaffId: number,
    requestIds: number[]
  ) {
    this.relCasLoading = true;
    const response = await loadRelatedCarryStaffs(
      targetDate,
      carryStaffId,
      requestIds
    );
    runInAction(() => {
      const { carry_staffs, schedules, assigned_requests } = response.data;
      this.assignableCarryStaffs = mergeSchedulesIntoCarryStaffs(
        carry_staffs,
        schedules
      ).filter((cas) => cas.id != carryStaffId);
      this.assignedRequests = assigned_requests;
      this.relCasLoading = false;
    });
  }

  @action
  public async loadRelatedCarryStaffs(
    targetDate: string,
    carryStaffId: number
  ) {
    const response = await loadRelatedCarryStaffs(targetDate, carryStaffId);
    runInAction(() => {
      const { carry_staffs, schedules, assigned_requests } = response.data;
      this.relatedCarryStaffs = mergeSchedulesIntoCarryStaffs(
        carry_staffs,
        schedules
      ).filter((cas) => cas.id != carryStaffId);
      this.assignedRequests = assigned_requests;
    });
  }

  @action
  public async loadAndUpdateRoutesMap(
    selectedApiType: SelectableApiType,
    paramSettings: RawRoutesApiParamSetting[]
  ) {
    const paths = this.getPathsAccordingToSequences(this.sequences);
    const actualPaths = this.getPathsAccordingToSequences(this.actualSequences);

    const params = convIntoRoutingApiParams(selectedApiType, paramSettings);
    // ApiRoutesMapに未登録のパスのみ取得しに行く
    const newPaths = this.exceptExistingRoutes(
      this.apiRoutesMap,
      params.apiCode,
      params.apiParamSetting,
      ([] as RoutePath[]).concat(paths).concat(actualPaths)
    );
    const response = await loadLocationsRoutes({
      targets: newPaths,
      apiCode: LOCATIONS_ROUTES_API_CODE,
      options: params.options,
    });
    runInAction(() => {
      const mergeApiRoutesMap = this.mergeToApiRoutesMap(
        this.apiRoutesMap,
        LOCATIONS_ROUTES_API_CODE,
        params.apiParamSetting,
        response.data.locations_routes
      );
      this.updateApiRoutesMapRef(mergeApiRoutesMap);
    });
  }

  public async registerLocationsRoutesIfLacked({
    selectedApiType,
    routesApiParamSettings,
    sequences,
  }: {
    selectedApiType: SelectableApiType;
    routesApiParamSettings: RawRoutesApiParamSetting[];
    sequences: RequestSequence[];
  }) {
    if (location.href.startsWith("http://localhost")) {
      // ローカルでの開発だといつまで経ってもDBにルート情報が登録されることはなく、
      // 毎回APIがよばれてしまうので、ローカルでは呼ばない。
      // ローカルでもテストしたい時だけここをコメントアウト
      console.debug("[registerLocationsRoutesIfLacked] Pass");
      return;
    }

    const { apiCode, apiParamSetting, options } = convIntoRoutingApiParams(
      selectedApiType,
      routesApiParamSettings
    );
    const paths = this.getPathsAccordingToSequences(sequences);
    const hasLacked = paths.some(
      (path) =>
        this.getRoutes({
          apiCode: apiCode,
          paramSetting: apiParamSetting,
          path,
        }) == null
    );
    if (!hasLacked) {
      // 全て揃っていたら何もしない
      console.debug(
        "[registerLocationsRoutesIfLacked] All routes are registered."
      );
      return;
    }

    await getAndSaveLocationsRoutes({
      targets: this.sequences.map((seq) => {
        if (seq.destination_type == "sender") {
          return {
            lat: seq.request.sender_lat,
            lng: seq.request.sender_lng,
          };
        }

        return {
          lat: seq.request.receiver_lat,
          lng: seq.request.receiver_lng,
        };
      }),
      apiCode: LOCATIONS_ROUTES_API_CODE,
      options: options,
    });
  }

  @action
  public setSequencesAfterAssign(requestIds: number[]) {
    this.sequences = this.sequences.filter(
      (seq) => !requestIds.some((id) => id === seq.request.id)
    );
  }

  private getPathsAccordingToSequences(sequences: RequestSequence[]) {
    if (sequences.length < 2) return [];

    let paths: RoutePath[] = [];
    for (let i = 0; i < sequences.length - 1; i++) {
      const fromIndex = i;
      const from =
        sequences[fromIndex].destination_type == "sender"
          ? {
              lat: sequences[fromIndex].request.sender_lat,
              lng: sequences[fromIndex].request.sender_lng,
            }
          : {
              lat: sequences[fromIndex].request.receiver_lat,
              lng: sequences[fromIndex].request.receiver_lng,
            };

      const toIndex = i + 1;
      const to =
        sequences[toIndex].destination_type == "sender"
          ? {
              lat: sequences[toIndex].request.sender_lat,
              lng: sequences[toIndex].request.sender_lng,
            }
          : {
              lat: sequences[toIndex].request.receiver_lat,
              lng: sequences[toIndex].request.receiver_lng,
            };

      paths.push({
        from,
        to,
      });
    }

    return paths;
  }

  private exceptExistingRoutes(
    apiRoutesMap: ApiRoutesMap,
    apiCode: RoutesApiCode,
    paramSetting: RoutesApiParamSetting,
    targetRoutePaths: RoutePath[]
  ) {
    const apiRoutesMapKey = this.createApiRoutesMapKey(apiCode, paramSetting);
    const existingPathKeys = Array.from(
      (apiRoutesMap.get(apiRoutesMapKey) || new Map<string, RoutesMap>()).keys()
    );
    const targetPathKeys = targetRoutePaths.map((path) =>
      this.createRoutesMapKey(path)
    );
    const newPathKeys = targetPathKeys.filter(
      (targetPathkey) => !existingPathKeys.includes(targetPathkey)
    );
    const uniquedNewPathKeys = Array.from(new Set(newPathKeys));
    return uniquedNewPathKeys.map((pathKey) => this.parseRoutesMapKey(pathKey));
  }

  /**
   * 参照を更新するだけのメソッド.
   */
  @action
  private updateApiRoutesMapRef(apiRoutesMap: ApiRoutesMap) {
    const newApiRoutesMap = new Map();
    for (const key of Array.from(apiRoutesMap.keys())) {
      newApiRoutesMap.set(key, apiRoutesMap.get(key));
    }
    this.apiRoutesMap = newApiRoutesMap;
  }

  /**
   * 引数で指定したapiRoutesMap自体の内容を書き換える。
   * @param apiRoutesMap
   * @param apiCode
   * @param paramSetting
   * @param locationsRoutes
   * @returns
   */
  private mergeToApiRoutesMap(
    apiRoutesMap: ApiRoutesMap,
    apiCode: RoutesApiCode,
    paramSetting: RoutesApiParamSetting,
    locationsRoutes: RawLocationsRoute[]
  ) {
    const apiRoutesMapKey = this.createApiRoutesMapKey(apiCode, paramSetting);
    const routesMap =
      apiRoutesMap.get(apiRoutesMapKey) || new Map<string, GeoJSONLineString>();

    locationsRoutes
      .filter((row) => row.routes != null)
      .forEach((row) => {
        const key = this.createRoutesMapKey({
          from: { lat: row.from_lat, lng: row.from_lng },
          to: { lat: row.to_lat, lng: row.to_lng },
        });
        routesMap.set(key, row.routes!);
      });
    apiRoutesMap.set(apiRoutesMapKey, routesMap);

    return apiRoutesMap;
  }

  private createApiRoutesMapKey(
    apiCode: RoutesApiCode,
    paramSetting: RoutesApiParamSetting
  ) {
    if (apiCode == "ec-and-nm" || apiCode == "geoapify") {
      return JSON.stringify({
        apiCode: apiCode,
        options: {
          geoapify: {
            optimizationType: paramSetting.geoapify_optimization_type,
            travelMode: paramSetting.geoapify_travel_mode,
            maxSpeed: paramSetting.geoapify_max_speed,
          },
        },
      });
    }

    throw Error(`[createApiRoutesMapKey] Invalid apiCode: ${apiCode}`);
  }

  private createRoutesMapKey({ from, to }: RoutePath): string {
    return `${from.lat}-${from.lng}-${to.lat}-${to.lng}`;
  }

  private parseRoutesMapKey(routesMapKey: string): RoutePath {
    const latLngs = routesMapKey.split("-");
    return {
      from: {
        lat: latLngs[0],
        lng: latLngs[1],
      },
      to: {
        lat: latLngs[2],
        lng: latLngs[3],
      },
    };
  }

  public getRoutes({
    apiCode,
    paramSetting,
    path,
  }: {
    apiCode: RoutesApiCode;
    paramSetting: RoutesApiParamSetting;
    path: RoutePath;
  }): GeoJSONLineString | null {
    // 実質Geoapify固定だけど、他のAPIを利用するようになったときを想定して、一旦以下の形
    const correctedApiCode =
      apiCode != "geoapify" ? LOCATIONS_ROUTES_API_CODE : apiCode;
    const apiRoutesMapKey = this.createApiRoutesMapKey(
      correctedApiCode,
      paramSetting
    );
    const routesMap = this.apiRoutesMap.get(apiRoutesMapKey);
    if (routesMap == null) return null;

    const { from, to } = path;
    if (from.lat == to.lat && from.lng == to.lng) {
      // 同じ地点の場合はここでGeoJsonを作成してしまう
      return {
        type: "LineString",
        coordinates: [
          [+from.lng, +from.lat],
          [+to.lng, +to.lat],
        ],
      };
    }

    const key = this.createRoutesMapKey({ from, to });
    const routes = routesMap.get(key);
    return routes || null;
  }
}
