import { format } from "date-fns";
import React from "react";

interface Props {
  date: Date;
  minDate?: Date;
  showTodayButton?: boolean;
  onChangeDate: (newDate: Date) => void;
}

interface State {}

export default class DateSelection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const changeDate = (date: Date) => {
      this.props.onChangeDate(date);
    };

    let options = {
      format: "Y/m/d",
      timepicker: false,
      scrollInput: false, // マウスホイールの動きで月が変わらないよう
      onChangeDateTime: function (_dp: any, _input: any) {
        const seldate = new Date(_input.val());
        if (typeof seldate == "string") {
          console.log("typeof seldate is string", seldate);
          return;
        }

        changeDate(seldate);
      }
    }

    if (this.props.minDate) {
      options = Object.assign(options, { minDate: this.props.minDate })
    }

    $.datetimepicker.setLocale("ja");
    $(".datepicker").datetimepicker(options);

    $(".datepicker").val(format(this.props.date, "yyyy/MM/dd"));
  }

  updateDateTimePickerAndSelectedDate(
    params: { action: "add"; addDays: number } | { action: "reset" }
  ) {
    let newDate: Date;
    if (params.action == "add") {
      newDate = new Date(
        this.props.date.getTime() + 1000 * 60 * 60 * 24 * params.addDays
      );
    } else {
      newDate = new Date();
    }

    $(".datepicker").datetimepicker("setDate", newDate);
    $(".datepicker").val(format(newDate, "yyyy/MM/dd"));
    this.props.onChangeDate(newDate);
  }

  render() {
    const { showTodayButton = true } = this.props;

    return (
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="d-flex justify-content-center align-items-center mr-3"
          style={{ width: 200 }}
        >
          <div
            className="mr-3"
            onClick={() => {
              if (this.props.minDate && ( format(this.props.date, "yyyy/MM/dd") <= format(this.props.minDate, "yyyy/MM/dd"))) {
                return
              }
              this.updateDateTimePickerAndSelectedDate({
                action: "add",
                addDays: -1
              });
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <input
              type="text"
              placeholder="年 / 月 / 日"
              className="form-control datepicker"
            />
            <div
              style={{
                position: "relative",
                transform: "translate(-30px, 0)",
                pointerEvents: "none"
              }}
            >
              <i className="far fa-calendar-alt" />
            </div>
          </div>

          <div
            onClick={() => {
              this.updateDateTimePickerAndSelectedDate({
                action: "add",
                addDays: 1
              });
            }}
          >
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
        {showTodayButton && (
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                this.updateDateTimePickerAndSelectedDate({ action: "reset" });
              }}
            >
              今日
            </button>
          </div>
        )}
      </div>
    );
  }
}
