import { action, observable, set } from "mobx";
import { VendorCompanyModel } from "../models/VendorCompanyModel";
import VendorCompanyResponse from "../interfaces/VendorCompanyResponse";

export class VisibleVendorCompanyModel{
  @observable.ref
  id: number;

  @observable.ref
  key: number;
  
  model: VendorCompanyModel;
  
  visible: boolean = true;
  
  constructor(response: VendorCompanyResponse) {
    this.id = response.id;
    this.key = response.id;
    
    this.model = new VendorCompanyModel(response);
  }
  
  @action
  public toggleVisible() {
    this.visible = !this.visible;
  }
}
