import React from "react";
import { ConditionUnit } from "./Interfaces";

interface Props {
  node: ConditionUnit;
  onChangeRelation: (node: ConditionUnit) => void;
}

export default class AndOrRelation extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { node } = this.props;
    if (node.type === "root" || node.relation_to_right == null) {
      return null;
    }

    return (
      <div className="d-flex justify-content-start my-2">
        <button
          className="btn btn-outline-dark btn-sm mr-2 small text-dark"
          style={{ lineHeight: 0.5 }}
          disabled
        >
          {node.relation_to_right === "and" ? "かつ" : "または"}
        </button>
        {/* ややこしい(画面がうるさい)だけなので一旦なし */}
        {/* <button
          className="btn btn-primary btn-sm mr-2 small"
          style={{ lineHeight: 0.5 }}
          onClick={() => this.props.onChangeRelation(node)}
        >
          <i className="fas fa-exchange-alt mr-1" style={{ color: 'white' }}></i>
        </button> */}
      </div>
    );
  }
}
