import { observable } from "mobx";
import { RawH3MinPickupMinutesSetting } from "../interfaces/entities/RawH3MinPickupMinutesSetting";
import { H3Index } from "../interfaces/H3Index";

export class H3IndexModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  // h3_indicesテーブルのデータ
  h3Index: string;
  h3AreaWkt: string;
  nickName: string | null;
  // h3_indices.h3_indexから算出されるデータ(取得時にRails側で計算)
  centerLat: number;
  centerLng: number;
  // h3_min_pickup_minutes_settingsテーブルのデータ
  minPickupMinutesSettings: {
    fromTime: Date;
    toTime: Date;
    minPickupMinutes: number;
  }[];
  // h3_capacity_timelinesテーブルのデータ
  capacity: number | null;
  requiredMinute: number | null;
  manualRequiredMinute: number | null;
  // staff_count_predictionsテーブルのデータ
  prediction: number | null;
  predictionUpdatedAt: Date | null;

  constructor(
    h3Index: H3Index,
    minPickupMinutesSettings: Pick<
      RawH3MinPickupMinutesSetting,
      "h3_index" | "from_time" | "to_time" | "min_pickup_minutes"
    >[]
  ) {
    this.id = h3Index.id;
    this.key = h3Index.id;
    this.h3Index = h3Index.h3_index;
    this.h3AreaWkt = h3Index.h3_area_wkt;
    this.nickName = h3Index.nick_name ?? null;
    this.centerLat = h3Index.center_lat;
    this.centerLng = h3Index.center_lng;
    this.minPickupMinutesSettings = (minPickupMinutesSettings ?? [])
      .filter((setting) => setting.h3_index == h3Index.h3_index)
      .map((setting) => ({
        fromTime: new Date(setting.from_time),
        toTime: new Date(setting.to_time),
        minPickupMinutes: setting.min_pickup_minutes,
      }));
    this.capacity = h3Index.capacity ?? null;
    this.requiredMinute = h3Index.required_minute ?? null;
    this.manualRequiredMinute = h3Index.manual_required_minute ?? null;
    this.prediction = h3Index.prediction ?? null;
    this.predictionUpdatedAt =
      h3Index.prediction_updated_at != null
        ? new Date(h3Index.prediction_updated_at)
        : null;
  }

  public getH3IndexColor() {
    // 一旦色を変える閾値は固定
    // 一つのヘックスが複数のキャパシティと交差している可能性があり、その場合の閾値を計算するのが面倒なため
    // 手動だと時間で見るので、時間で判定

    const minute = this.manualRequiredMinute ?? this.requiredMinute;
    // @ts-ignore TODO: nullの場合の考慮
    if (minute >= 45) {
      return "#ff2600";
    }

    // @ts-ignore
    if (minute >= 30) {
      return "#ff982a";
    }

    // @ts-ignore
    if (minute >= 15) {
      return "#00ffd0";
    }

    return "#79baff";
  }
}
