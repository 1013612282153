import React, { useState } from "react";
import { Slide, toast } from "react-toastify";
import type {
  RoutesApiBulkAssignResponse,
  SequenceItem,
} from "../../../../utils/routes-apis/apis/common";
import type { RawCarryStaff } from "../../interfaces";
import { pageStore } from "../../stores";
import { assign, updateSequences } from "../../utils";
import ModalBodyWrapper from "./ModalBodyWrapper";

interface Props {
  carryStaffs: RawCarryStaff[];
  response: RoutesApiBulkAssignResponse | null;
  onClickNext: () => void;
  onClickClose: () => void;
}

export default function ConfirmAssigningStageBody(props: Props) {
  const { carryStaffs, response, onClickNext, onClickClose } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  if (response == null || !response.isSuccess) {
    return (
      <ModalBodyWrapper
        title="計算結果確認"
        onClickClose={onClickClose}
        showBackButton={false}
        showNextButton={false}
      >
        <div className="d-flex flex-column">
          <div className="font-weight-bold">アサイン不可</div>
          <div className="d-flex flex-column">
            条件を満たしたアサインを見つけられませんでした
          </div>
        </div>
      </ModalBodyWrapper>
    );
  }

  const {
    carryStaffs: carryStaffResponseItems,
    droppedReqIds,
    inputParams,
  } = response;
  const casRequestsMap: { [key: string]: number[] } =
    carryStaffResponseItems.reduce((accum, cas) => {
      accum[`${cas.id}`] = cas.sequences
        .filter((seq) => !seq.wasAssigned)
        .map((seq) => seq.requestId);
      return accum;
    }, {});

  const handleClickNext = async () => {
    try {
      setLoading(true);
      setErrorMessage(null);
      await assign(
        ([] as (SequenceItem & { wasAssigned: boolean })[])
          .concat(
            ...carryStaffResponseItems.map((casItem) => casItem.sequences)
          )
          .filter((seq) => !seq.wasAssigned)
          .filter((seq) => seq.destinationType == "receiver")
          .map((seq) => ({
            requestId: seq.requestId,
            carryStaffId: seq.carryStaffId,
          }))
      );
      const responses = await Promise.all(
        carryStaffResponseItems.map((casItem) =>
          updateSequences({
            targetDate: inputParams.target_date,
            carryStaffId: casItem.id,
            sequences: casItem.sequences.map((seq, index) => ({
              request_id: seq.requestId,
              destination_type: seq.destinationType,
              sequence: index + 1,
              estimated_delivery_time: seq.estimatedDeliveryTime,
              work_start_time: seq.workStartTime,
            })),
          })
        )
      );

      await pageStore.resetUnassignedRequestsAndCarryStaffs();
      setLoading(false);
      toast.info("アサインしました。", {
        autoClose: 1000,
        closeButton: false,
        hideProgressBar: true,
        position: toast.POSITION.TOP_RIGHT,
        transition: Slide,
      });
      onClickNext();
    } catch (e) {
      console.error(e);
      setLoading(false);
      setErrorMessage(e.response?.data?.message || "アサインに失敗しました。");
    }
  };

  return (
    <ModalBodyWrapper
      title="計算結果確認"
      errorMessage={errorMessage}
      showLoading={loading}
      closeButtonDisabled={loading}
      onClickClose={onClickClose}
      showBackButton={false}
      nextButtonLabel="適用"
      onClickNext={handleClickNext}
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column">
          <div className="font-weight-bold">アサイン可能</div>
          <div className="d-flex flex-column">
            {Object.keys(casRequestsMap).map((casId) => {
              const requestIds = Array.from(new Set(casRequestsMap[casId]));
              requestIds.sort();
              const carryStaff = carryStaffs.find((cas) => cas.id == +casId);
              return (
                <div key={casId} className="ml-2 d-flex flex-column">
                  <div>
                    配達スタッフ: {carryStaff?.name ?? "-"}(ID: {casId})
                  </div>
                  {requestIds.map((reqId) => {
                    return (
                      <div key={reqId} className="ml-1">
                        <div>依頼ID: {reqId}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="font-weight-bold">アサイン不可</div>
          <div className="d-flex flex-column">
            {droppedReqIds.length == 0 && <div>なし</div>}
            {droppedReqIds.map((droppedReqId) => {
              return (
                <div key={droppedReqId} className="ml-2 d-flex flex-column">
                  <div>依頼ID: {droppedReqId}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ModalBodyWrapper>
  );
}
