import { DragEndEvent, DragStartEvent, DragOverEvent } from "@dnd-kit/core";
import { format, startOfDay, endOfToday } from "date-fns";
import { Coords } from "google-map-react";
import { IReactionDisposer } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import { ToastContainer, toast, Slide } from "react-toastify";
import GoogleMap from "../../components/Common/GoogleMap";
import Polyline from "../../components/Polyline";
import WktMultiPolygon from "../../components/WktMultiPolygon";
import { COLORS } from "../../constants/Colors";
import MapAttributes from "../../constants/MapAttributes";
import { axiosPost } from "../../utils/AxiosClient";
import { createEdgePointPairs } from "../../utils/CarryStaffRequestSequenceUtils";
import { calcCenter } from "../../utils/GeographyUtils";
import {
  convIntoRoutingApiParams,
  getAndSaveLocationsRoutes,
} from "../../utils/LocationsRoutesUtils";
import { convertRequestIntoForMap } from "../../utils/RequestEntityUtils";
import type { SelectableApiType } from "../../utils/routes-apis/api-code-utils";
import { getSelectableApiTypes } from "../../utils/routes-apis/api-code-utils";
import { ExtendedRawRequest } from "../../utils/routes-apis/common-utils";
import { callCalculateEatApi } from "../../utils/routes-apis/apis";
import DateSelection from "../Common/DateSelection";
import RoutesApiSelection from "../Common/RoutesApiSelection";
import ReceiverSequenceMarker from "../ReceiverSequenceMarker";
import SenderSequenceMarker from "../SenderSequenceMarker";
import { RelatedCarryStaffsAccordion } from "./components/RelatedCarryStaffAccordion";
import SortableRequestsContainer from "./components/SortableRequestsContainer";
import { LOCATIONS_ROUTES_API_CODE } from "./consts";
import type {
  RawCarryStaff,
  RawCurrentLocation,
  RawRoutesApiParamSetting,
  RawTerritory,
  RawVehicleType,
  ArrangeErrorResponse,
  RequestSequence,
} from "./interfaces";
import ArrangeResultConfirmModal from "./modals/ArrangeResultConfirmModal";
import AssignOtherStaffModal from "./modals/AssignOtherStaffModal";
import AuroArrangeErrorConfirmModal from "./modals/AuroArrangeErrorConfirmModal";
import AutoArrangeExecuteModal from "./modals/AutoArrangeExecuteModal";
import CalculateEatModal from "./modals/CalculateEatModal";
import EatResultConfirmModal from "./modals/EatResultConfirmModal";
import { pageStore, searchConditionsStore } from "./stores";
import {
  updateSequences,
  moveItem,
  checkInvalidSequenceAndUpdate,
} from "./utils";

Modal.setAppElement("#wrapper");

declare var gon: any;

interface Props {
  carryStaff: RawCarryStaff;
  currentLocation: RawCurrentLocation | null;
  // ルート配達スタッフではない場合、存在しない
  territory: RawTerritory | null;
  vehicleType: RawVehicleType | null;
  routesApiParamSettings: RawRoutesApiParamSetting[];
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  center: Coords;
  selectedApiType: SelectableApiType;
  draggingSequence: RequestSequence | undefined;
  latestClickedMapAt: Date;
  openModalType:
    | "auto-arrange-execute"
    | "auto-arrange-error-confirm"
    | "arrange-result-confirm"
    | "calculate-eat-execute"
    | "eat-result-confirm"
    | "assign-to-other-staff"
    | undefined;
  executeCalcEat: boolean;
  isLoading: boolean;
  isDataLoading: boolean;
  routesApiErrorInfo: ArrangeErrorResponse | undefined;
  reassignTargetRequests: ExtendedRawRequest[];
}

class CarryStaffRequestSequence extends React.Component<Props, State> {
  disposers: IReactionDisposer[] = [];

  private defaultToastOptios = {
    autoClose: 1000,
    closeButton: false,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    transition: Slide,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      center: {
        lat: MapAttributes.SHIBUYA_STATION_LATITUDE,
        lng: MapAttributes.SHIBUYA_STATION_LONGITUDE,
      },
      selectedApiType: "ec-and-nm",
      draggingSequence: undefined,
      openModalType: undefined,
      executeCalcEat: false,
      isLoading: false,
      isDataLoading: false,
      routesApiErrorInfo: undefined,
      latestClickedMapAt: new Date(),
      reassignTargetRequests: [],
    };

    this.onDragStart = this.onDragStart.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);

    pageStore.currentLocation = this.props.currentLocation;
    pageStore.territory = this.props.territory;
    pageStore.vehicleType = this.props.vehicleType;
    pageStore.carryStaff = this.props.carryStaff;
  }

  async componentDidMount() {
    await this.loadRequestsAndSequences();
    // componentDidUpdateでは初回ロード時に更新されないので、
    // こちらでも対応
    this.calcCenterAndUpdate();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (prevState.isDataLoading && !this.state.isDataLoading) {
      this.calcCenterAndUpdate();
    }
  }

  private calcCenterAndUpdate() {
    const targetRequests = searchConditionsStore.isActualSequenceDisplayed
      ? pageStore.actualSequences.map((seq) => seq.request)
      : pageStore.sequences.map((seq) => seq.request);

    if (targetRequests.length === 0) return;

    const center = calcCenter({
      points: targetRequests
        .map((req) => [
          { lat: +req.sender_lat, lng: +req.sender_lng },
          { lat: +req.receiver_lat, lng: +req.receiver_lng },
        ])
        .reduce((a, b) => a.concat(b), []),
    });
    this.setState({ center: center });
  }

  private async loadRequestsAndSequences() {
    this.setState({ isDataLoading: true });
    const targetTerm = searchConditionsStore.targetTerm;
    try {
      await pageStore.loadAndSetRequestsAndSchedules(targetTerm);
      await pageStore.loadAndSetSequences(targetTerm);
      if (targetTerm.isPast) {
        await pageStore.loadAndSetActualSequences(targetTerm);
      }

      await pageStore.loadAndUpdateRoutesMap(
        this.state.selectedApiType,
        this.props.routesApiParamSettings
      );

      await this.registerLocationsRoutesIfLacked();
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  // ローカルで開発している場合、
  // pageStore.registerLocationsRoutesIfLackedの中身の処理はパスされるので注意
  private async registerLocationsRoutesIfLacked() {
    await Promise.all([
      pageStore.registerLocationsRoutesIfLacked({
        selectedApiType: this.state.selectedApiType,
        routesApiParamSettings: this.props.routesApiParamSettings,
        sequences: pageStore.sequences,
      }),
      searchConditionsStore.targetTerm.isPast
        ? pageStore.registerLocationsRoutesIfLacked({
            selectedApiType: this.state.selectedApiType,
            routesApiParamSettings: this.props.routesApiParamSettings,
            sequences: pageStore.actualSequences,
          })
        : Promise.resolve(true),
    ]);
  }

  private onDragStart(
    event: DragStartEvent,
    draggingSequence: RequestSequence
  ) {
    this.setState({ draggingSequence: draggingSequence });
  }

  private onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      let { movedSequences, raisedErrorType } = moveItem(
        pageStore.sequences,
        active.id,
        over.id
      );

      if (raisedErrorType) {
        if (raisedErrorType == "sender_over_receiver") {
          toast.warn(
            "同じ依頼のものに関して、配達先は配達元より前に設定できません。",
            this.defaultToastOptios
          );
        } else if (raisedErrorType == "receiver_over_sender") {
          toast.warn(
            "同じ依頼のものに関して、配達元は配達先より後に設定できません。",
            this.defaultToastOptios
          );
        }
      }
      pageStore.setSequence([...movedSequences]);
    }
  }

  private onDragEnd(event: DragEndEvent) {
    // const { active, over } = event;
    const { sequences: checkedSequences, hasDisallowed } =
      checkInvalidSequenceAndUpdate(
        pageStore.sequences,
        pageStore.carryStaffSchedules
      );
    pageStore.setSequence(checkedSequences);
    this.setState({
      draggingSequence: undefined,
    });
  }

  private async notifyCarryStaff() {
    try {
      const response = await axiosPost.post(
        `/api/carry_staff_request_sequences/notify_carry_staff`,
        { carry_staff_id: this.props.carryStaff.id }
      );
    } catch (error) {
      console.error(error);
      toast.error("配達員への通知に失敗しました。", this.defaultToastOptios);
    }
  }

  private validateAndShowToast() {
    const { sequences, hasDisallowed } = checkInvalidSequenceAndUpdate(
      pageStore.sequences,
      pageStore.carryStaffSchedules
    );
    if (hasDisallowed) {
      toast.warning(
        "同じ依頼のものに関して、配達先が配達元より先に設定されているものがあります。",
        { ...this.defaultToastOptios, autoClose: 2000 }
      );
    }

    return !hasDisallowed;
  }

  private async updateSequences() {
    const validationResult = this.validateAndShowToast();
    if (!validationResult) return;

    this.setState({
      isLoading: true,
    });

    try {
      const targetDate = format(
        searchConditionsStore.getTargetTermDate(),
        "yyyy-MM-dd"
      );
      let newSequences = pageStore.sequences.map((seq) => ({
        request_id: seq.request_id,
        destination_type: seq.destination_type,
        sequence: seq.sequence,
        estimated_delivery_time: seq.estimated_delivery_time,
        work_start_time: seq.work_start_time,
      }));

      await updateSequences({
        targetDate: targetDate,
        carryStaffId: pageStore.carryStaff.id,
        sequences: newSequences,
      });

      // ロードし直す前にチェック
      const existsChangedTodaySequences = pageStore.sequences
        .filter((seq) => seq.org_sequence != seq.sequence)
        .some((seq) => new Date(seq.request.delivery_time_at) <= endOfToday());

      if (existsChangedTodaySequences) {
        await this.notifyCarryStaff();
      }

      toast.success("更新しました。", this.defaultToastOptios);
    } catch (error) {
      console.error("[updateSequences]", error);
      toast.error("更新に失敗しました。", this.defaultToastOptios);
    }

    await Promise.all([
      pageStore.loadAndSetSequences(searchConditionsStore.targetTerm),
      this.saveLocationsRoutes(),
    ]);

    this.setState({
      openModalType: undefined,
      isLoading: false,
      executeCalcEat: false,
    });
  }

  private async saveLocationsRoutes() {
    try {
      const params = convIntoRoutingApiParams(
        this.state.selectedApiType,
        this.props.routesApiParamSettings
      );
      await getAndSaveLocationsRoutes({
        targets: pageStore.sequences.map((seq) => {
          if (seq.destination_type == "sender") {
            return {
              lat: seq.request.sender_lat,
              lng: seq.request.sender_lng,
            };
          }

          return {
            lat: seq.request.receiver_lat,
            lng: seq.request.receiver_lng,
          };
        }),
        apiCode: LOCATIONS_ROUTES_API_CODE,
        options: params.options,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async calculateEatAndUpdateSequences({
    selectedApiType,
    onlyCalcEat,
  }: {
    selectedApiType: SelectableApiType;
    onlyCalcEat: boolean;
  }) {
    const validationResult = this.validateAndShowToast();
    if (!validationResult) return;

    this.setState({ isLoading: true });

    const targetDate = format(
      searchConditionsStore.getTargetTermDate(),
      "yyyy-MM-dd"
    );
    let isSuccessCalcEat = true;
    let newSequences = pageStore.sequences;

    try {
      const result = await callCalculateEatApi({
        // タイムアウトするようならfalseに切り替える
        sync: true,
        apiType: selectedApiType,
        targetDate: targetDate,
        carryStaffId: this.props.carryStaff.id,
        sequences: newSequences.map((seq) => ({
          requestId: seq.request_id,
          destinationType: seq.destination_type,
          sequence: seq.sequence,
        })),
        routesApiParamSettings: this.props.routesApiParamSettings,
        timeout: 30,
      });
      if (result.isSuccess) {
        newSequences = newSequences.map((seq) => {
          const calcedEatSeq = result.sequences.find(
            (eatSeq) =>
              seq.request_id == eatSeq.requestId &&
              seq.destination_type == eatSeq.destinationType
          );
          // ないことはないはずだけど、なかったらエラー
          if (calcedEatSeq == null) {
            console.log(
              "[calculateEatAndUpdateSequences] Error seq=",
              seq,
              "result.sequences=",
              result.sequences
            );
            throw Error("Exists no sequece in calculate EAT result");
          }

          return {
            ...seq,
            estimated_delivery_time: calcedEatSeq.estimatedDeliveryTime,
            work_start_time: calcedEatSeq.workStartTime,
          };
        });
      } else {
        isSuccessCalcEat = false;
        console.log("[calculateEatAndUpdateSequences]", result);
        toast.error(
          "到着予想時刻の計算に失敗しました。",
          this.defaultToastOptios
        );
      }
    } catch (error) {
      console.error("[calculateEatAndUpdateSequences]", error);
      isSuccessCalcEat = false;
      toast.error(
        "到着予想時刻の計算に失敗しました。",
        this.defaultToastOptios
      );
    }

    if (isSuccessCalcEat) {
      pageStore.setSequence(newSequences);
      this.setState({
        openModalType: "eat-result-confirm",
        isLoading: false,
        executeCalcEat: false,
      });
      return;
    }

    // EAT計算のみでかつEATの計算に失敗した場合、ここでおしまい
    if (onlyCalcEat) {
      this.setState({
        openModalType: undefined,
        isLoading: false,
        executeCalcEat: false,
      });
      return;
    }

    // 手動並び替えかつEAT計算が失敗した場合
    try {
      await updateSequences({
        targetDate: targetDate,
        carryStaffId: pageStore.carryStaff.id,
        sequences: newSequences.map((seq) => ({
          request_id: seq.request_id,
          destination_type: seq.destination_type,
          sequence: seq.sequence,
          estimated_delivery_time: seq.estimated_delivery_time,
          work_start_time: seq.work_start_time,
        })),
      });

      // なぜか「順序が変更された依頼がありません。」の表示がされてしまう瞬間があるので、
      // モーダルはここで消してしまう。
      this.setState({
        openModalType: undefined,
      });

      const existsChangedTodaySequences = pageStore.sequences
        .filter((seq) => seq.org_sequence != seq.sequence)
        .some((seq) => new Date(seq.request.delivery_time_at) <= endOfToday());

      const responses = await Promise.all([
        existsChangedTodaySequences
          ? this.notifyCarryStaff()
          : Promise.resolve(true),
        pageStore.loadAndSetSequences(searchConditionsStore.targetTerm),
        this.saveLocationsRoutes(),
      ]);

      toast.success("配達順を更新しました。", this.defaultToastOptios);
    } catch (error) {
      console.error("[calculateEatAndUpdateSequences]", error);
      toast.error("配達順の更新に失敗しました。", this.defaultToastOptios);
    }

    this.setState({
      openModalType: undefined,
      isLoading: false,
      executeCalcEat: false,
    });
  }

  private updateLatestClickedMapAt() {
    this.setState({ latestClickedMapAt: new Date() });
  }

  private async handleDateChange(newDate: Date) {
    searchConditionsStore.setTargetTermDate(newDate);
    await this.loadRequestsAndSequences();
  }

  private createTerritory() {
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      pageStore.territory == null
    ) {
      return null;
    }

    return (
      <WktMultiPolygon
        map={this.state.map}
        mapApi={this.state.mapApi}
        wktText={pageStore.territory!.area_wkt}
      />
    );
  }

  private createMarkers(destinationType: "sender" | "receiver") {
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      pageStore.extendedRequests.length == 0
    ) {
      return null;
    }

    const { draggingSequence, latestClickedMapAt } = this.state;

    const sequences = searchConditionsStore.isActualSequenceDisplayed
      ? pageStore.actualSequences
      : pageStore.sequences;
    const targetRequests = sequences
      .filter((seq) => seq.destination_type == destinationType)
      .map((seq) => seq.request);

    return targetRequests.map((req) => {
      const isDragging =
        draggingSequence?.request_id == req.id &&
        draggingSequence?.destination_type == destinationType;
      const _req = convertRequestIntoForMap(req, pageStore.carryStaff);

      let pinText = "-";

      const sequence = pageStore.getRequestSequenceByRequestId(
        req.id,
        destinationType,
        searchConditionsStore.isActualSequenceDisplayed
      );

      if (searchConditionsStore.targetTerm.isPast) {
        // 対象日が過去の場合は配達済依頼のため予想時間は不要なので空にしておく
        _req.estimatedPickupTime = null;
        pinText = sequence ? sequence.sequence.toString() : "-";
      } else {
        // RequestSequencesから予想時間を取得して_reqに追加する
        if (sequence) {
          if (destinationType == "sender") {
            _req.estimatedPickupTime = sequence.work_start_time;
          } else {
            _req.estimatedDeliveryTime = sequence.work_start_time;
          }
        }
        pinText = pageStore.getSequence(req.id, destinationType).toString();
      }

      if (destinationType === "sender") {
        return (
          <SenderSequenceMarker
            key={`sender_marker_${req.id}`}
            lat={+req.sender_lat}
            lng={+req.sender_lng}
            request={_req}
            iconColor={isDragging ? "black" : COLORS.startMarker}
            zIndex={isDragging ? 100 : 1}
            infoWindoZIndex={200}
            latestClickedMapAt={latestClickedMapAt}
            pinText={pinText}
          />
        );
      } else if (destinationType === "receiver") {
        return (
          <ReceiverSequenceMarker
            key={`receiver_marker_${req.id}`}
            lat={+req.receiver_lat}
            lng={+req.receiver_lng}
            request={_req}
            iconColor={isDragging ? "black" : COLORS.goalMarker}
            zIndex={isDragging ? 100 : 1}
            infoWindoZIndex={200}
            latestClickedMapAt={latestClickedMapAt}
            pinText={pinText}
          />
        );
      } else {
        return null;
      }
    });
  }

  private createLines() {
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      pageStore.sequences.length == 0
    ) {
      return null;
    }

    const targetSequences = searchConditionsStore.isActualSequenceDisplayed
      ? pageStore.actualSequences
      : pageStore.sequences;

    const params = convIntoRoutingApiParams(
      this.state.selectedApiType,
      this.props.routesApiParamSettings
    );

    const lines = createEdgePointPairs({
      sequences: targetSequences,
    });
    return lines.map((line) => {
      const fromPoint =
        line.from.destination_type == "sender"
          ? {
              lat: line.from.request.sender_lat,
              lng: line.from.request.sender_lng,
            }
          : {
              lat: line.from.request.receiver_lat,
              lng: line.from.request.receiver_lng,
            };
      const toPoint =
        line.to.destination_type == "sender"
          ? { lat: line.to.request.sender_lat, lng: line.to.request.sender_lng }
          : {
              lat: line.to.request.receiver_lat,
              lng: line.to.request.receiver_lng,
            };
      const isDragging =
        (this.state.draggingSequence?.request_id == line.from.request.id &&
          this.state.draggingSequence?.destination_type ==
            line.from.destination_type) ||
        (this.state.draggingSequence?.request_id == line.to.request.id &&
          this.state.draggingSequence?.destination_type ==
            line.to.destination_type);

      const routes = pageStore.getRoutes({
        apiCode: params.apiCode,
        paramSetting: params.apiParamSetting,
        path: { from: fromPoint, to: toPoint },
      });
      const locations = routes
        ? routes.coordinates.map((coords) => ({
            lat: coords[1],
            lng: coords[0],
          }))
        : [
            { lat: +fromPoint.lat, lng: +fromPoint.lng },
            { lat: +toPoint.lat, lng: +toPoint.lng },
          ];
      return (
        <Polyline
          key={`line_from_${line.from.request.id}_${line.from.destination_type}`}
          map={this.state.map}
          mapApi={this.state.mapApi}
          locations={locations}
          addArrow={true}
          arrowIconScale={3}
          arrowOffset={30}
          strokeColor={
            isDragging ? COLORS.brand.anycarry : "rgba(51, 163, 247, 0.7)"
          }
          strokeWeight={2}
          zIndex={isDragging ? 100 : 1}
        />
      );
    });
  }

  private updateReassignTargetRequests = (
    request: ExtendedRawRequest,
    checked: boolean
  ) => {
    if (
      checked &&
      !this.state.reassignTargetRequests.some((req) => req.id === request.id)
    ) {
      this.setState((prevState) => ({
        reassignTargetRequests: [...prevState.reassignTargetRequests, request],
      }));
    }
    if (!checked) {
      this.setState((prevState) => ({
        reassignTargetRequests: prevState.reassignTargetRequests.filter(
          (req) => req.id !== request.id
        ),
      }));
    }
  };

  render() {
    const now = new Date();
    const startOfToday = startOfDay(now);
    const targetDate = searchConditionsStore.getTargetTermDate();
    const isPast = targetDate < startOfToday;
    const targetSequences = searchConditionsStore.isActualSequenceDisplayed
      ? pageStore.actualSequences
      : pageStore.sequences;

    return (
      <>
        <div className="d-flex flex-wrap">
          <div
            className="col-12 col-lg-6 d-flex mb-3"
            style={{ height: "calc(100vh - 100px)" }}
          >
            <GoogleMap
              bootstrapURLKeys={{
                key: gon.google_api_key,
              }}
              defaultZoom={14}
              center={this.state.center}
              resetBoundsOnResize={true}
              hoverDistance={MapAttributes.K_SIZE / 2}
              onGoogleApiLoaded={({ map, maps }) => {
                this.setState({
                  map: map,
                  mapApi: maps,
                  mapLoaded: true,
                });
              }}
              onClick={() => this.updateLatestClickedMapAt()}
            >
              {this.createTerritory()}
              {this.createMarkers("sender")}
              {this.createMarkers("receiver")}
              {this.createLines()}
            </GoogleMap>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column mb-3">
            <div className="d-flex flex-column justify-content-center mr-4 mb-2">
              <div className="d-flex justify-content-between align-items-end">
                <div className="d-flex flex-column">
                  <div style={{ fontSize: 12 }}>利用API</div>
                  <div className="d-flex overflow-auto">
                    <RoutesApiSelection
                      showLabel={false}
                      defaultValue={this.state.selectedApiType}
                      selectableApiTypes={getSelectableApiTypes(
                        "sort",
                        this.props.routesApiParamSettings
                      )}
                      routesApiParamSettings={this.props.routesApiParamSettings}
                      disabled={isPast}
                      onSelect={async (apiType) => {
                        this.setState({
                          selectedApiType: apiType,
                        });
                        await pageStore.loadAndUpdateRoutesMap(
                          apiType,
                          this.props.routesApiParamSettings
                        );
                        await this.registerLocationsRoutesIfLacked();
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end gap-1">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-primary"
                      data-toggle="tooltip"
                      data-original-title={
                        isPast ? `過去のデータは変更できません。` : undefined
                      }
                      disabled={isPast}
                      onClick={() =>
                        this.setState({
                          openModalType: "arrange-result-confirm",
                          executeCalcEat: true,
                        })
                      }
                    >
                      配達順更新
                    </button>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-primary"
                      data-toggle="tooltip"
                      data-original-title={
                        isPast ? `過去のデータは変更できません。` : undefined
                      }
                      disabled={isPast}
                      onClick={() =>
                        this.setState({
                          openModalType: "calculate-eat-execute",
                        })
                      }
                    >
                      到着予想時刻更新
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div className="d-flex flex-column">
                  {isPast ? (
                    <div className="my-2">
                      <div className="d-flex justify-content-start align-items-center">
                        <input
                          type="radio"
                          id="registered_sequences"
                          name="sequenceType"
                          value="registered"
                          checked={
                            !searchConditionsStore.isActualSequenceDisplayed
                          }
                          onChange={(e) => {
                            searchConditionsStore.setIsActualSequenceDisplayed(
                              !e.target.checked
                            );
                          }}
                        />
                        <label
                          className="mx-2 my-0"
                          htmlFor="registered_sequences"
                        >
                          登録されていた配達順
                        </label>
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <input
                          type="radio"
                          id="actual_sequences"
                          name="sequenceType"
                          value="actual"
                          checked={
                            searchConditionsStore.isActualSequenceDisplayed
                          }
                          onChange={(e) => {
                            searchConditionsStore.setIsActualSequenceDisplayed(
                              e.target.checked
                            );
                          }}
                        />
                        <label className="mx-2 my-0" htmlFor="actual_sequences">
                          実際の配達時刻による配達順
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="">作業日</div>
                        <div className="d-flex justify-content-start align-items-center ml-3">
                          <input
                            type="radio"
                            id="work_date_specified"
                            name="work_date"
                            value="specified"
                            checked={
                              searchConditionsStore.targetTerm.type ===
                              "specified"
                            }
                            onChange={() => {
                              searchConditionsStore.setTargetTermType(
                                "specified"
                              );
                              this.loadRequestsAndSequences();
                            }}
                          />
                          <label
                            className="ml-2 mt-2"
                            htmlFor="work_date_specified"
                          >
                            当日のみ
                          </label>
                        </div>
                        <div className="d-flex justify-content-start align-items-center ml-3">
                          <input
                            type="radio"
                            id="work_date_all"
                            name="work_date"
                            value="all"
                            checked={
                              searchConditionsStore.targetTerm.type === "all"
                            }
                            onChange={() => {
                              searchConditionsStore.setTargetTermType("all");
                              this.loadRequestsAndSequences();
                            }}
                          />
                          <label className="ml-2 mt-2" htmlFor="work_date_all">
                            過去未配達分も表示
                          </label>
                        </div>
                      </div>
                      <div className="custom-control custom-switch mb-2">
                        <input
                          id="assign-mode-toggle-switch"
                          type="checkbox"
                          className="custom-control-input"
                          checked={searchConditionsStore.isReassignMode}
                          onChange={(e) => {
                            searchConditionsStore.setIsReassignMode(
                              e.target.checked
                            );
                            this.setState({
                              reassignTargetRequests: [],
                            });
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="assign-mode-toggle-switch"
                        >
                          他の配達スタッフへのアサイン変更
                        </label>
                      </div>
                    </>
                  )}
                  <div className="d-flex justify-content-center align-items-center">
                    <DateSelection
                      date={startOfDay(
                        searchConditionsStore.getTargetTermDate()
                      )}
                      showTodayButton={false}
                      onChangeDate={async (newDate) => {
                        this.handleDateChange(newDate);
                      }}
                    />
                    <div>
                      <span>
                        {`※ スケジュール登録数 ${pageStore.carryStaffSchedules.length} 件`}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center my-1 ml-auto">
                  <button
                    className="btn btn-outline-primary"
                    data-toggle="tooltip"
                    data-original-title={
                      isPast ? `過去のデータは変更できません。` : undefined
                    }
                    disabled={isPast || pageStore.extendedRequests.length <= 1}
                    onClick={() =>
                      this.setState({ openModalType: "auto-arrange-execute" })
                    }
                  >
                    自動整列
                  </button>
                </div>
              </div>
            </div>
            {searchConditionsStore.isReassignMode && (
              <div className="d-flex justify-content-flex-end mb-3">
                <button
                  className={"btn btn-primary"}
                  onClick={() =>
                    this.setState({
                      openModalType: "assign-to-other-staff",
                    })
                  }
                  disabled={this.state.reassignTargetRequests.length < 1}
                >
                  選択した依頼を他の配達スタッフにアサイン
                </button>
              </div>
            )}
            <SortableRequestsContainer
              carryStaff={this.props.carryStaff}
              sequences={targetSequences}
              canDrag={!isPast}
              onDragStart={this.onDragStart}
              onDragOver={this.onDragOver}
              onDragEnd={this.onDragEnd}
              toastOptions={this.defaultToastOptios}
              isLoading={this.state.isDataLoading}
              reassignTargetRequests={this.state.reassignTargetRequests}
              handleChangeReassignTarget={this.updateReassignTargetRequests}
            />
          </div>
        </div>
        <RelatedCarryStaffsAccordion />

        <AutoArrangeExecuteModal
          isOpen={this.state.openModalType == "auto-arrange-execute"}
          carryStaff={pageStore.carryStaff}
          schedules={pageStore.carryStaffSchedules}
          extendedRequests={pageStore.extendedRequests}
          routesApiParamSettings={this.props.routesApiParamSettings}
          sequences={pageStore.sequences}
          selectedApiType={this.state.selectedApiType}
          onCompleteRearrenge={(response) => {
            if (response.success) {
              pageStore.setSequence(response.sequences);
              this.setState({
                openModalType: "arrange-result-confirm",
                executeCalcEat: false,
                routesApiErrorInfo: undefined,
              });
            } else {
              this.setState({
                openModalType: "auto-arrange-error-confirm",
                routesApiErrorInfo: response,
              });
            }
          }}
          onCloseModal={() =>
            this.setState({
              openModalType: undefined,
              routesApiErrorInfo: undefined,
            })
          }
        />

        <AuroArrangeErrorConfirmModal
          isOpen={this.state.openModalType == "auto-arrange-error-confirm"}
          carryStaff={this.props.carryStaff}
          contents={this.state.routesApiErrorInfo}
          onCloseModal={() =>
            this.setState({
              openModalType: undefined,
              routesApiErrorInfo: undefined,
            })
          }
        />

        <ArrangeResultConfirmModal
          isOpen={this.state.openModalType == "arrange-result-confirm"}
          sequences={pageStore.sequences}
          schedules={pageStore.carryStaffSchedules}
          routesApiParamSettings={this.props.routesApiParamSettings}
          selectedApiType={this.state.selectedApiType}
          isLoading={this.state.isLoading}
          executeCalcEat={this.state.executeCalcEat}
          onSubmit={async () => {
            if (this.state.executeCalcEat) {
              await this.calculateEatAndUpdateSequences({
                selectedApiType: this.state.selectedApiType,
                onlyCalcEat: false,
              });
            } else {
              await this.updateSequences();
            }
          }}
          onCloseModal={() =>
            this.setState({
              openModalType: undefined,
              executeCalcEat: false,
            })
          }
        />

        <CalculateEatModal
          isOpen={this.state.openModalType == "calculate-eat-execute"}
          isLoading={this.state.isLoading}
          routesApiParamSettings={this.props.routesApiParamSettings}
          selectedApiType={this.state.selectedApiType}
          onSubmit={async () => {
            await this.calculateEatAndUpdateSequences({
              selectedApiType: this.state.selectedApiType,
              onlyCalcEat: true,
            });
          }}
          onCloseModal={() =>
            this.setState({
              openModalType: undefined,
            })
          }
        />

        <EatResultConfirmModal
          isOpen={this.state.openModalType == "eat-result-confirm"}
          isLoading={this.state.isLoading}
          sequences={pageStore.sequences}
          onCloseModal={async () => {
            await this.updateSequences();
          }}
        />

        <AssignOtherStaffModal
          isModalOpen={this.state.openModalType === "assign-to-other-staff"}
          onRequestClose={() =>
            this.setState({
              openModalType: undefined,
            })
          }
          requests={this.state.reassignTargetRequests}
          carryStaff={this.props.carryStaff}
          onAfterAssignSucceed={() => {
            this.setState({
              reassignTargetRequests: [],
            });
          }}
        />

        <ToastContainer />
      </>
    );
  }
}

export default observer(CarryStaffRequestSequence);
