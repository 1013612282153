import { startOfDay } from "date-fns";
import format from "date-fns/format";
import _ from "lodash";
import { action, observable } from "mobx";
import { RawRequest } from "../../../interfaces/entities";
import { CarryStaffRequestSequenceModel } from "../../../models/CarryStaffRequestSequenceModel";
import {
  loadSequences,
  mergeRequestsAndSequences,
} from "../../../utils/CarryStaffRequestSequenceUtils";
import { axiosGet } from "../../../utils/AxiosClient";

export class CarryStaffRequestSequencesStore {
  sequences = observable<CarryStaffRequestSequenceModel>([]);

  @observable
  targetCarryStaffId: number | undefined = undefined;

  @action
  public async loadTodaySequences(targetCasId: number) {
    try {
      const [sequencesResponse, assignedRequests] = await Promise.all([
        loadSequences({
          targetDate: format(new Date(), "yyyy-MM-dd"),
          carryStaffId: targetCasId,
        }),
        this.loadAssignedRequests(targetCasId),
      ]);

      const todayAssignedRequests = _.filter(
        assignedRequests,
        (assignedRequest) => {
          return this.checkSequencesToday(assignedRequest.delivery_time_at);
        }
      );

      const mergedSequences = mergeRequestsAndSequences({
        requests: todayAssignedRequests,
        sequences: sequencesResponse.data.sequences,
      });

      const sequences = mergedSequences.map(
        (item) => new CarryStaffRequestSequenceModel(item)
      );
      this.replaceSequences(sequences);
      this.setTargetCarryStaffId(targetCasId);
    } catch (err) {
      console.log("Error", err);
      this.resetSequences();
    }
  }

  private async loadAssignedRequests(carryStaffId: number) {
    try {
      const response = await axiosGet.get<{ items: RawRequest[] }>(
        `/api/requests/carry_staffs/${carryStaffId}`
      );
      return response.data.items;
    } catch (err) {
      console.log("Error", err);
      return [];
    }
  }

  @action
  private setTargetCarryStaffId(carryStaffId: number) {
    this.targetCarryStaffId = carryStaffId;
  }

  @action
  private replaceSequences(sequences: CarryStaffRequestSequenceModel[]) {
    this.sequences.replace(sequences);
  }

  @action
  public resetSequences() {
    this.sequences.replace([]);
    this.targetCarryStaffId = undefined;
  }

  private checkSequencesToday(delivery_time_at: string) {
    const requestDeliveryTime = new Date(delivery_time_at);
    const tomorrow = startOfDay(new Date());
    tomorrow.setDate(tomorrow.getDate() + 1);

    return requestDeliveryTime < tomorrow;
  }
}

const singleton = new CarryStaffRequestSequencesStore();
export default singleton;
