import { action, observable, set } from "mobx";
import ServiceAreasResponse from "../interfaces/ServiceAreasResponse";

export class ServiceAreaModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  name: string;

  lat: number;

  lng: number;

  withinMeter: number;

  withinType: 'use_point' | 'use_area';

  withinAreaWkt: string;

  constructor(response: ServiceAreasResponse) {
    this.id = response.id;
    this.key = response.id;

    this.name = response.name;

    this.lat = Number(response.lat);
    this.lng = Number(response.lng);

    this.withinMeter = response.within_meter
    this.withinType = response.within_type
    this.withinAreaWkt = response.within_area_wkt
  }
  
  public usePoint() {
    return this.withinType === 'use_point'
  }
  
  public useArea() {
    return this.withinType === 'use_area'
  }
}
