import { isProcessingOrUndelivered } from "../../../constants/Request";
import { thirdPartyDeliveryTaskConst } from "../../../constants/ThirdPartyDeliveryTask";
import carryStaffLocationsStore from "../../../stores/CarryStaffLocationsStore";
import requestsStore from "../../../stores/RequestsStore";
import thirdPartyDeliveryTasksStore from "../../../stores/ThirdPartyDeliveryTasksStore";
import searchConditionsStore from "../stores/SearchConditionsStore";

export function filterCarryStaffLocations() {
  const displayPage = searchConditionsStore.displayPage;
  const items = carryStaffLocationsStore.items;

  if (displayPage == "carry_staff_requests_or_tpdts") {
    // carry_staff_requests_or_tpdtsにいるなら、carryStaffは存在するという前提
    const selectedCarryStaff =
      searchConditionsStore.carryStaffRequestsConditions.carryStaff!;
    const filteredItems = items.filter(
      (item) => item.carryStaffId == selectedCarryStaff.id
    );
    return filteredItems;
  }

  const {
    text: searchText,
    staffType: selectedStaffType,
    tagId: selectedTagId,
  } = searchConditionsStore.carryStaffsConditions;

  const filteredItems = items.filter((item) => {
    const isMatchSearchText =
      searchText == "" || item.name.includes(searchText);
    const isMatchStaffType =
      selectedStaffType == "all" || item.staffType === selectedStaffType;
    const hasMatchTag = carryStaffLocationsStore.masterTagRelations.some(
      (masterTagRelation) =>
        masterTagRelation.master_tag_id === selectedTagId &&
        masterTagRelation.target_id === item.carryStaffId
    );
    const isMatchTag = selectedTagId == "all" || hasMatchTag;

    return isMatchSearchText && isMatchStaffType && isMatchTag;
  });

  return filteredItems;
}

export function filterRequests() {
  const displayPage = searchConditionsStore.displayPage;
  const items = requestsStore.items;

  if (displayPage == "carry_staffs") {
    const {
      text: searchText,
      staffType: selectedStaffType,
      tagId: selectedTagId,
    } = searchConditionsStore.carryStaffsConditions;
    const specifiedCond =
      searchText != "" || selectedStaffType != "all" || selectedTagId != "all";
    if (!specifiedCond) {
      // 条件指定がない場合、地図範囲に存在するCASに紐付く依頼を表示
      const targetItems = items.filter((item) =>
        carryStaffLocationsStore.items.some(
          (loc) => loc.carryStaffId == item.carryStaffId
        )
      );
      return targetItems;
    }

    const filteredCasLocations = filterCarryStaffLocations();
    const filteredCasIds = filteredCasLocations.map((loc) => loc.carryStaffId);
    const filteredItems = items.filter((item) =>
      filteredCasIds.some((casId) => casId == item.carryStaffId)
    );
    return filteredItems;
  }

  if (displayPage == "carry_staff_requests_or_tpdts") {
    // CAS一覧からCAS詳細をクリックした時の画面を表示中
    const { carryStaff: selectedCarryStaff, status: selectedStatus } =
      searchConditionsStore.carryStaffRequestsConditions;

    let filteredItems = items.filter((req) => {
      const isMatchCasId =
        selectedCarryStaff &&
        ((selectedCarryStaff.id === "unassigned" && req.carryStaffId == null) ||
          req.carryStaffId === selectedCarryStaff.id);
      const isMatchStatus =
        selectedStatus === "all" ||
        (selectedStatus === "undelivered"
          ? isProcessingOrUndelivered(req.status, "undelivered")
          : req.status == selectedStatus);

      return isMatchCasId && isMatchStatus;
    });

    const visibleSequenceNumber =
      searchConditionsStore.carryStaffRequestsConditions.visibleSequenceNumber;
    if (visibleSequenceNumber) {
      // 依頼順表示中の場合はsequence昇順に依頼を並び替える
      // sequenceがない依頼は最後に配置
      filteredItems = filteredItems.sort((a, b) => {
        const aSequences = a.carryStaffRequestSequences;
        const bSequences = b.carryStaffRequestSequences;

        if (!aSequences && !bSequences) return 0;
        if (!aSequences) return 1;
        if (!bSequences) return -1;

        // sequenceの小さいほうの値で比較する。
        // Math.minの仕様として、
        // > Infinity if there are no parameters.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/min
        // となっており、それを利用している。
        // (carryStaffRequestSequencesが空配列の場合、minSequenceがInfinityとなり、最後尾に持ってこられる)
        const aMinSequence = Math.min(
          ...aSequences.map((item) => item.sequence)
        );
        const bMinSequence = Math.min(
          ...bSequences.map((item) => item.sequence)
        );

        return aMinSequence - bMinSequence;
      });
    }

    return filteredItems;
  }

  if (displayPage == "requests") {
    // 依頼一覧を表示を表示中

    // isUnassignedに関してはトグルで発生するのが元データの絞り込みではなく
    // 元データそのものの取り直しなのでここでは使用しない
    const {
      text: searchText,
      status: selectedStatus,
      tagId: selectedTagId,
    } = searchConditionsStore.requestsConditions;

    const filteredItems = items.filter((req) => {
      const isMatchSearchText =
        searchText === "" ||
        String(req.id).includes(searchText) ||
        (req.createdById && req.createdById.includes(searchText)) ||
        (req.otherSystemId && req.otherSystemId.includes(searchText)) ||
        req.sender.name.includes(searchText) ||
        req.sender.address.includes(searchText) ||
        req.receiver.name.includes(searchText) ||
        req.receiver.address.includes(searchText);
      const isMatchStatus =
        selectedStatus === "processing"
          ? isProcessingOrUndelivered(req.status)
          : req.status == selectedStatus;
      const hasMatchTag = requestsStore.masterTagRelations.some(
        (masterTagRelation) =>
          masterTagRelation.master_tag_id === selectedTagId &&
          masterTagRelation.target_id === req.id
      );
      const isMatchTag = selectedTagId === "all" || hasMatchTag;

      return isMatchSearchText && isMatchTag && isMatchStatus;
    });

    return filteredItems;
  }

  return [];
}

export function filterThirdPartyDeliveryTasks() {
  const displayPage = searchConditionsStore.displayPage;
  const items = thirdPartyDeliveryTasksStore.items;

  if (displayPage == "carry_staffs") {
    const {
      text: searchText,
      staffType: selectedStaffType,
      tagId: selectedTagId,
    } = searchConditionsStore.carryStaffsConditions;
    const specifiedCond =
      searchText != "" || selectedStaffType != "all" || selectedTagId != "all";
    if (!specifiedCond) {
      // 条件指定がない場合、地図範囲に存在するCASに紐付く外部配達依頼を表示
      const targetItems = items.filter((item) =>
        carryStaffLocationsStore.items.some(
          (loc) => loc.carryStaffId == item.carryStaffId
        )
      );
      return targetItems;
    }

    // 何らかの条件が指定されている場合、その指定によって限定されるCASに紐づいている外部配達依頼のみを表示
    const filteredCasLocations = filterCarryStaffLocations();
    const filteredCasIds = filteredCasLocations.map((loc) => loc.carryStaffId);
    const filteredItems = items.filter((item) =>
      filteredCasIds.some((casId) => casId == item.carryStaffId)
    );
    return filteredItems;
  }

  if (displayPage == "carry_staff_requests_or_tpdts") {
    // CAS一覧からCAS詳細をクリックした時の画面を表示中
    const { carryStaff: selectedCarryStaff, status: selectedStatus } =
      searchConditionsStore.carryStaffRequestsConditions;

    const filteredItems = items.filter((tpdt) => {
      const isMatchCasId =
        selectedCarryStaff &&
        ((selectedCarryStaff.id === "unassigned" &&
          tpdt.carryStaffId == null) ||
          tpdt.carryStaffId === selectedCarryStaff.id);
      const isMatchStatus =
        selectedStatus === "all" || tpdt.status == selectedStatus;
      const isMatchProgressStatus =
        (
          thirdPartyDeliveryTaskConst.CUSTOM_STATUSES
            .in_progress as readonly number[]
        ).indexOf(tpdt.status) >= 0;

      return (
        isMatchCasId &&
        (selectedStatus === "undelivered"
          ? isMatchProgressStatus
          : isMatchStatus)
      );
    });

    return filteredItems;
  }

  if (displayPage == "third_party_delivery_tasks") {
    // 外部配達依頼一覧を表示を表示中
    const {
      text: searchText,
      status: selectedStatus,
      workType: selectedWorkType,
    } = searchConditionsStore.thirdPartyDeliveryTasksConditions;

    const filteredItems = items.filter((tpdt) => {
      const isMatchSearchText =
        searchText === "" ||
        String(tpdt.id).includes(searchText) ||
        tpdt.carryStaff.name.includes(searchText);
      const isMatchStatus =
        selectedStatus === "all" || tpdt.status === selectedStatus;
      const isMatchWorkType =
        selectedWorkType === "all" || tpdt.workType === selectedWorkType;

      return isMatchSearchText && isMatchStatus && isMatchWorkType;
    });

    return filteredItems;
  }

  return [];
}
