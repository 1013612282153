import { centerOfMass, difference, multiPolygon, union } from "@turf/turf";
import { Coords } from "google-map-react";
// @ts-ignore
import { stringify, parse } from "wkt";

// 全てのマルチポリゴン及びポリゴンを結合する
export function multiPolygonsUnion(polygonStrings: string[]) {
  // ポリゴンが一個しかない場合、結合する必要がないのでそのまま返す
  if (!polygonStrings[1]) return polygonStrings[0];

  const polygonObjects = polygonStrings.reduce((prev: any[], current) => {
    try {
      const obj = parse(current);
      const polygon: any = multiPolygon(obj.coordinates);
      return [...prev, polygon];
    } catch (e) {
      console.error(e);
      return prev;
    }
  }, []);

  const unionedPolygon = polygonObjects.reduce(
    (accUnion: any, feature: any) => {
      if (!accUnion) return feature;
      try {
        return union(feature, accUnion);
      } catch (e) {
        console.error(e);
        return accUnion;
      }
    },
    null
  );
  if (!unionedPolygon || !unionedPolygon.geometry) return "";
  return stringify(unionedPolygon.geometry) as string;
}

// ベースとなるポリゴンを選択したポリゴンで型抜きする
export function multiPolygonsDifference(
  basePolygonWkt: string,
  removeAreaPolygonWkts: string[]
) {
  const basePolygonObject = multiPolygon(parse(basePolygonWkt).coordinates);

  const polygonObjects = removeAreaPolygonWkts.reduce(
    (prev: any[], current) => {
      try {
        const obj = parse(current);
        const polygon: any = multiPolygon(obj.coordinates);
        return [...prev, polygon];
      } catch (e) {
        console.error(e);
        return prev;
      }
    },
    []
  );
  const differencedPolygon = polygonObjects.reduce(
    (accUnion: any, feature: any) => {
      try {
        return difference(accUnion, feature);
      } catch (e) {
        console.error(e);
        return accUnion;
      }
    },
    basePolygonObject
  );
  if (!differencedPolygon || !differencedPolygon.geometry) return "";

  return stringify(differencedPolygon.geometry) as string;
}

// ポリゴン全体の中心点を取得する
export function getCenterCoords(wkt: string): Coords | undefined {
  try {
    const geoJSON = parse(wkt);
    const centerPoint = centerOfMass(geoJSON);
    return {
      lat: centerPoint.geometry.coordinates[1],
      lng: centerPoint.geometry.coordinates[0],
    };
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
