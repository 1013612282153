import { EventEmitter } from "events";
import React, { useEffect } from "react";

const eventEmitter = new EventEmitter();

interface SelectionItem {
  key: string;
  label: string;
  areaWkt: string;
}

interface Props {
  areas: SelectionItem[];
  onHover: (area: SelectionItem) => void;
  onSelect: (area: SelectionItem) => void;
}

export default function MAPToolboxContentForSelectableAreas(props: Props) {
  const { areas, onHover, onSelect } = props;

  const handleWrapper = (
    key: string | undefined,
    handler: (area: SelectionItem) => void
  ) => {
    if (!key) return;

    const area = areas.find((_area) => _area.key == key);
    if (!area) return;

    handler(area);
  };

  const handleOptionHover = (key: string | undefined) => {
    handleWrapper(key, onHover);
  };

  useEffect(() => {
    eventEmitter.on("onSelect", (key: string) => {
      handleWrapper(key, onSelect);
    });
    return () => {
      eventEmitter.removeAllListeners("onSelect");
    };
  }, [onSelect]);

  useEffect(() => {
    $(".selectize").selectize({
      onChange: (areaKey: string) => {
        // ここでpropsから渡ってきたonSelectを直接実行するように記述すると、
        // selectizeの設定の関係でマウント時にしかここ(onChangeに対する関数設定)が実行されず(実行されないようにする必要があり)、
        // そうするとmapApiがloadされる前の状態のonSelectが渡ってきてしまい、上手くいかない。
        // なので、一旦eventEmitterを経由させ、
        // eventEmitterをサブスクライブしている側では最新の(mapApiがloadされた状態の)onSelectが実行されるようにしている
        eventEmitter.emit("onSelect", areaKey);
      }
    });

    // selectizeのonOptionHoverが効いてほしいのだけど、なんか上手くいかないので
    // 参考: https://stackoverflow.com/questions/36222947/how-to-capture-hover-event-on-selectize-select-box
    $(".selectize-control").on(
      "mouseenter",
      ".selectize-dropdown-content div",
      (e: any) => handleOptionHover(e.target?.dataset?.value)
    );
  }, []);

  return (
    <div className="row">
      <div className="d-flex align-items-center" style={{ width: "90%" }}>
        <select
          className="form-control form-control-sm selectize ml-2"
          defaultValue="none"
        >
          <option key="none" value="none" disabled>
            選択してください
          </option>
          {areas.map((area) => {
            return (
              <option key={area.key} value={area.key}>
                {area.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
