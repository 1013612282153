export const conditionKeys = {
  exists: "exists",
  equal: "equal",
  not_equal: "not_equal",
  more_than: "more_than",
  more_than_or_equal: "more_than_or_equal",
  less_than: "less_than",
  less_than_or_equal: "less_than_or_equal",
  starts_with: "starts_with",
  ends_with: "ends_with",
  contains: "contains",
  within: "within",
} as const;

export type ConditionKey = keyof typeof conditionKeys;
export type ConditionCodeItem = { key: ConditionKey; label: string };

export const conditionCodeLavels = [
  { key: conditionKeys.exists, label: "存在する" },
  { key: conditionKeys.equal, label: "と等しい" },
  { key: conditionKeys.not_equal, label: "と等しくない" },
  { key: conditionKeys.more_than, label: "より大きい" },
  { key: conditionKeys.more_than_or_equal, label: "より大きいもしくは等しい" },
  { key: conditionKeys.less_than, label: "より小さい" },
  { key: conditionKeys.less_than_or_equal, label: "より小さいもしくは等しい" },
  { key: conditionKeys.starts_with, label: "から始まる" },
  { key: conditionKeys.ends_with, label: "で終わる" },
  { key: conditionKeys.contains, label: "を含む" },
  { key: conditionKeys.within, label: "に含まれる" },
] as ConditionCodeItem[];

type ColumnType = "id" | "string" | "number" | "geometry";

export const typeConditionAllowMap = {
  id: [conditionKeys.exists],
  string: [
    conditionKeys.equal,
    conditionKeys.not_equal,
    conditionKeys.starts_with,
    conditionKeys.ends_with,
    conditionKeys.contains,
  ],
  number: [
    conditionKeys.equal,
    conditionKeys.not_equal,
    conditionKeys.more_than,
    conditionKeys.more_than_or_equal,
    conditionKeys.less_than,
    conditionKeys.less_than_or_equal,
  ],
  geometry: [conditionKeys.within],
} as { [key in ColumnType]: (keyof typeof conditionKeys)[] };

export type AcceptableTableKey = "request" | "delivery_offer" | "address";
export type TableItem = { key: AcceptableTableKey; label: string };
export type Column = { key: string; type: ColumnType; label: string };

export const tableAndColumnMap = [
  {
    table: { key: "request", label: "依頼" },
    columns: [
      { key: "id", type: "id", label: "ID" },
      { key: "company_cd", type: "string", label: "店舗会社コード" },
      { key: "sender_name", type: "string", label: "配達元名称" },
      { key: "sender_full_address", type: "string", label: "配達元住所" },
      { key: "sender_state", type: "string", label: "配達元都道府県" },
      { key: "sender_city", type: "string", label: "配達元市区町村" },
      { key: "sender_latlng", type: "geometry", label: "配達元緯度経度" },
      { key: "receiver_name", type: "string", label: "配達先名称" },
      { key: "receiver_full_address", type: "string", label: "配達先住所" },
      { key: "receiver_state", type: "string", label: "配達先都道府県" },
      { key: "receiver_city", type: "string", label: "配達先市区町村" },
      { key: "receiver_latlng", type: "geometry", label: "配達先緯度経度" },
    ],
  },
  {
    table: { key: "delivery_offer", label: "オファー" },
    columns: [
      { key: "id", type: "id", label: "ID" },
      { key: "sender_name", type: "string", label: "配達元名称" },
      { key: "sender_full_address", type: "string", label: "配達元住所" },
      { key: "sender_state", type: "string", label: "配達元都道府県" },
      { key: "sender_city", type: "string", label: "配達元市区町村" },
      { key: "sender_latlng", type: "geometry", label: "配達元緯度経度" },
      { key: "sender_geo_hash", type: "string", label: "配達元ジオハッシュ" },
      { key: "receiver_name", type: "string", label: "配達先名称" },
      { key: "receiver_full_address", type: "string", label: "配達先住所" },
      { key: "receiver_state", type: "string", label: "配達先都道府県" },
      { key: "receiver_city", type: "string", label: "配達先市区町村" },
      { key: "receiver_latlng", type: "geometry", label: "配達先緯度経度" },
      { key: "receiver_geo_hash", type: "string", label: "配達先ジオハッシュ" },
    ],
  },
  {
    table: { key: "address", label: "住所" },
    columns: [
      { key: "id", type: "id", label: "ID" },
      { key: "full_address", type: "string", label: "住所" },
      { key: "zipcode", type: "string", label: "郵便番号" },
      { key: "state", type: "string", label: "都道府県" },
      { key: "city", type: "string", label: "市区町村" },
      { key: "address1", type: "string", label: "丁目番地号" },
      { key: "address2", type: "string", label: "マンション名・ビル名等" },
      { key: "temporary_lat", type: "number", label: "一時緯度" },
      { key: "temporary_lng", type: "number", label: "一時経度" },
      { key: "geo_hash", type: "string", label: "ジオハッシュ" },
    ],
  },
] as {
  table: TableItem;
  columns: Column[];
}[];
