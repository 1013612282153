import _ from 'lodash';
import { observer } from "mobx-react";
import React from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import MapAttributes from "../constants/MapAttributes";
import CarryStaff from '../interfaces/CarryStaff';
import Request from "../interfaces/Request";
import RequestAssignChangeLog from "../interfaces/RequestAssignChangeLog";
import OfficeCarryStaffMarker from "../components/OfficeCarryStaffMarker";
import Polyline from "../components/Polyline";
import ReceiverMarker from '../components/ReceiverMarker'
import SenderMarker from '../components/SenderMarker'
import { CurrentLocationWithCarryStaffModel, WorkType } from "../models/CurrentLocationWithCarryStaffModel";
import GoogleMap from '../components/Common/GoogleMap';


declare var gon: any;

interface Props {
  request: Request
  requestAssignChangeLog: RequestAssignChangeLog
  fromCas: CarryStaff | null
  toCas: CarryStaff | null
  nearCarryStaffsNameMap: { [key: number]: string }
}

interface State {
  map: any
  mapApi: any
  mapLoaded: boolean
  circle: any
  circleRadiusMeter: number
}

class RequestAssignChangeLogMap extends React.Component<Props, State> {

  circle: any

  constructor(props: Props) {
    super(props);
    this.circle = null;
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      circle: null,
      circleRadiusMeter: 3000
    };
  }

  componentDidMount(): void {
  }

  onChangeCircleRadius = (radius: number) => {
    this.setState({ circleRadiusMeter: radius })
  }

  render() {
    const {
      request,
      requestAssignChangeLog,
      fromCas,
      toCas
    } = this.props;

    const carryStaffsJsonObj = JSON.parse(requestAssignChangeLog.carry_staffs_json || '{}') as {
      carry_staffs?: {
        carry_staff_id: number
        // 以下4つは途中から追加したので「?」
        move_method?: number
        work_type?: number
        staff_type?: number
        may_stay_for_a_while?: boolean
        avoid_assign?: boolean
        lat: string
        lng: string
        sent_at: string
        requests: {
          request_id: number
          status: number
        }[]
      }[]
    };
    const nearCarryStaffs = carryStaffsJsonObj.carry_staffs || [];
    const fromCasPosition = fromCas ? nearCarryStaffs.find(ncas => ncas.carry_staff_id == fromCas.id) : null;
    const toCasPosition = toCas ? nearCarryStaffs.find(ncas => ncas.carry_staff_id == toCas.id) : null;

    return (
      <div className={'request-map-container'}>
        <RangeSlider
          min={500}
          max={10000}
          step={100}
          value={this.state.circleRadiusMeter}
          onChange={e => {
            this.onChangeCircleRadius(+e.target.value)
          }}
        />
        {/* calc(100% - 40px)の40pxは上のSliderの分 */}
        <div style={{ width: "100%", height: "calc(100% - 40px)", margin: "0px", padding: "0px", position: "relative" }}>
          <GoogleMap
            bootstrapURLKeys={{
              key: gon.google_api_key
            }}
            defaultCenter={this.senderCoord(request)}
            defaultZoom={16}
            center={this.senderCoord(request)}
            resetBoundsOnResize={true}
            hoverDistance={MapAttributes.K_SIZE / 2}
            onGoogleApiLoaded={({ map, maps }) => this.setState({
              map: map,
              mapApi: maps,
              mapLoaded: true
            })}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            <Polyline
              map={this.state.map}
              mapApi={this.state.mapApi}
              locations={
                [
                  this.senderCoord(request),
                  this.receiverCoord(request)
                ]
              }
            />
            <SenderMarker
              lat={request.sender.lat}
              lng={request.sender.lng}
              request={request}
            />
            <ReceiverMarker
              lat={request.receiver.lat}
              lng={request.receiver.lng}
              request={request}
            />
            {this.renderCircle(request)}
            {nearCarryStaffs.map(nearCarryStaff => {
              return (
                <OfficeCarryStaffMarker
                  key={nearCarryStaff.carry_staff_id}
                  lat={+nearCarryStaff.lat}
                  lng={+nearCarryStaff.lng}
                  location={new CurrentLocationWithCarryStaffModel({
                    id: 0,
                    lat: nearCarryStaff.lat,
                    lng: nearCarryStaff.lng,
                    carry_staff_id: nearCarryStaff.carry_staff_id,
                    carry_staff_name: this.props.nearCarryStaffsNameMap[nearCarryStaff.carry_staff_id] || '[名前:取得エラー]',
                    carry_staff_sub: '',
                    in_progress_request_count: nearCarryStaff.requests.length,
                    // 以下9種類(10つ)は途中から追加した項目なため、なければデフォルト値
                    move_method: nearCarryStaff.move_method || 0,
                    carry_staff_move_method: nearCarryStaff.move_method || 0,
                    carry_staff_work_type: nearCarryStaff.work_type || WorkType.ANYCARRY,
                    carry_staff_may_stay_for_a_while: nearCarryStaff.may_stay_for_a_while || false,
                    carry_staff_avoid_assign: nearCarryStaff.avoid_assign || false,
                    carry_staff_image_url: null,
                    carry_staff_image_url_expired_at: null,
                    carry_staff_staff_type: nearCarryStaff.staff_type || 1,
                    todays_request_count: 0,
                    todays_third_party_delivery_task_count: 0,
                    todays_return_request_count: 0,
                    todays_onhold_request_count: 0,
                    todays_delivered_request_count: 0,
                  })}
                />
              )
            })}
            {fromCasPosition && toCasPosition ? (
              <Polyline
                map={this.state.map}
                mapApi={this.state.mapApi}
                locations={
                  [
                    { lat: +fromCasPosition.lat, lng: +fromCasPosition.lng },
                    { lat: +toCasPosition.lat, lng: +toCasPosition.lng }
                  ]
                }
                addArrow={true}
                zIndex={-1}
                strokeColor="#ff4507"
                strokeWeight={2}
              />
            ) : null
            }
          </GoogleMap>
        </div>
        <div className="mb-4"></div>
      </div>
    );
  }

  /**
   * requestの配達先位置を中心とする半径radiusの円を描画するメソッド.
   * @param request 
   * @param radius 
   * @returns 
   */
  private renderCircle(request: Request) {
    if (!this.state.mapLoaded) return;

    this.circle?.setMap(null);
    this.circle = new this.state.mapApi.Circle({
      center: this.senderCoord(request),
      radius: this.state.circleRadiusMeter,
      strokeColor: '#79baff',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#79baff',
      fillOpacity: 0.35
    });
    this.circle.setMap(this.state.map);
  }

  private senderCoord(request: Request) {
    return {
      lat: Number(request.sender.lat),
      lng: Number(request.sender.lng)
    }
  }

  private receiverCoord(request: Request) {
    return {
      lat: Number(request.receiver.lat),
      lng: Number(request.receiver.lng)
    }
  }
}

export default observer(RequestAssignChangeLogMap);
