import React from "react";
import Modal from "react-modal";
import { COLORS } from "../../../constants/Colors";
import type { SelectableApiType } from "../../../utils/routes-apis/api-code-utils";
import { getApiTypeName } from "../../../utils/routes-apis/api-code-utils";
import { formatIso8601Time } from "../../../utils/TimeUtils";
import type {
  RawCarryStaffSchedule,
  RawRoutesApiParamSetting,
  RequestSequence,
} from "../interfaces";
import { checkInvalidSequenceAndUpdate, getTimeLabel } from "../utils";

interface Props {
  sequences: RequestSequence[];
  schedules: RawCarryStaffSchedule[];
  routesApiParamSettings: RawRoutesApiParamSetting[];
  selectedApiType: SelectableApiType;
  isOpen: boolean;
  isLoading: boolean;
  executeCalcEat: boolean;
  onSubmit: () => Promise<void>;
  onCloseModal: () => void;
}

export default function ArrangeResultConfirmModal(props: Props) {
  const {
    sequences,
    schedules,
    routesApiParamSettings,
    selectedApiType,
    isOpen,
    isLoading,
    executeCalcEat,
    onSubmit,
    onCloseModal,
  } = props;

  const existsChanged = sequences.some(
    (seq) => seq.org_sequence !== seq.sequence
  );
  if (!existsChanged) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => onCloseModal()}
        style={modalStyles}
      >
        <div className="h5 mb-4">順序変更内容確認</div>
        <div className="d-flex flex-column">
          <div>順序が変更された依頼がありません。</div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary mr-2"
              onClick={() => onCloseModal()}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  const renderRequestItem = (sequence: RequestSequence, showEdt = true) => {
    return (
      <div className="d-flex my-2">
        <div
          className="d-flex flex-column justify-content-start"
          style={{ fontSize: 14 }}
        >
          <div className="d-flex align-items-center">
            {sequence.destination_type == "sender" ? (
              <i className="fas fa-fw fa-store mr-2" />
            ) : (
              <i className="fas fa-fw fa-user mr-2" />
            )}
            <span>依頼No.{sequence.request_id}</span>
          </div>
          <div className="d-flex align-items-center">
            <i className="fas fa-clock mr-2" />
            <div>
              {sequence.destination_type == "sender" ? (
                <span>集荷時間：{getTimeLabel(sequence)}</span>
              ) : (
                <span>配達希望時間：{getTimeLabel(sequence)}</span>
              )}
              <br />
              {showEdt && sequence.work_start_time ? (
                <span>
                  到着予想時間：
                  {formatIso8601Time(sequence.work_start_time)}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const { sequences: validatedSequences, hasDisallowed } =
    checkInvalidSequenceAndUpdate(sequences, schedules);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onCloseModal()}
      onAfterOpen={() => {
        $('[data-toggle="tooltip"]').tooltip();
      }}
      style={modalStyles}
    >
      <div className="h5 mb-4 d-flex align-items-center">
        順序変更内容確認
        {isLoading && (
          <div className="d-flex align-items-center ml-3">
            <div className="mr-2">
              <span style={{ fontSize: 16 }}>
                {executeCalcEat ? "到着予想時刻計算中..." : "更新中..."}
              </span>
            </div>
            <div
              className="spinner-border spinner-border-sm ml-3"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div>
        利用API:
        <span className="ml-2 font-weight-bold">
          {getApiTypeName(selectedApiType, routesApiParamSettings)}
        </span>
      </div>
      <div className="d-flex flex-column">
        <div className="my-2">
          {hasDisallowed
            ? "不正な順序となっている依頼が存在するため、更新できません。"
            : "下記の内容で順序を変更しますか？"}
        </div>
        <div
          className="px-4 d-flex flex-column overflow-auto"
          style={{
            maxHeight: executeCalcEat
              ? "calc(100vh - 350px)"
              : "calc(100vh - 250px)",
          }}
        >
          <div className="d-flex flex-column">
            {validatedSequences.map((seq, index) => {
              const orgSeq = sequences.find(
                (_seq) => _seq.org_sequence == seq.sequence
              );
              const isChanged =
                orgSeq == null ||
                orgSeq.request_id != seq.request_id ||
                orgSeq.destination_type != seq.destination_type;
              const colorStyle: React.CSSProperties | undefined =
                seq.is_disallowed
                  ? {
                      color: "red",
                    }
                  : isChanged
                  ? {
                      color: COLORS.brand.anycarry,
                    }
                  : undefined;
              return (
                <div
                  key={seq.id}
                  className="d-flex justify-content-start align-items-center"
                >
                  <div className="col-1">{index + 1}</div>
                  <div className="col-5">
                    {/* ここでEDTを表示すると、元の並びで時間順が変なように見えてしまうので、
                    元の並びとして表示する場合にはEDTは非表示 */}
                    {orgSeq ? renderRequestItem(orgSeq, false) : null}
                  </div>
                  <div className="mx-2">
                    <i className="fa fa-lg fa-arrow-right" />
                  </div>
                  <div
                    className="col-5"
                    style={colorStyle}
                    data-toggle="tooltip"
                    data-original-title={
                      seq.is_disallowed
                        ? `配達元より配達先が先に設定されています。`
                        : undefined
                    }
                  >
                    {/* 手動による変更の場合(EATを計算する必要のある場合)には、
                    EDT(というかEAT: 到着予想時刻)は表示しない */}
                    {renderRequestItem(seq, !executeCalcEat)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {hasDisallowed ? (
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary mr-2"
              onClick={() => onCloseModal()}
            >
              OK
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-end my-2">
            <button
              className="btn btn-secondary mr-2"
              onClick={() => onCloseModal()}
              disabled={isLoading}
            >
              キャンセル
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading}
              onClick={async () => {
                await onSubmit();
              }}
            >
              OK
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 900,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
