import React, { useState } from "react";
import Modal from "react-modal";
import { axiosPost } from "../utils/AxiosClient";

Modal.setAppElement("#wrapper");

type Props = {
  carryStaffName: string;
  requestId: number;
};

export default function RequestCallNotificationModal({
  requestId,
  carryStaffName,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    clearResults();
  };

  const clearResults = () => {
    if (isSuccess) setIsSuccess(false);
    if (errorMessage) setErrorMessage(null);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    try {
      await axiosPost.post("/api/request_call_notifies", {
        requestId: requestId,
      });
      setIsSuccess(true);
      setErrorMessage(null);
    } catch (e) {
      console.log(e);
      const message = e.response?.data?.message || "エラーが発生しました。";
      setErrorMessage(message);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={() => openModal()}
        disabled={isOpen}
      >
        音声通知
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        style={modalStyles}
      >
        <h2 className="h5 mb-4">音声通知確認</h2>
        {isLoading && <p>発信中...</p>}
        {isSuccess && <p>発信しました。</p>}
        {errorMessage && (
          <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {errorMessage}
          </p>
        )}
        <form onSubmit={onSubmit}>
          <div className="border-left-info px-4">
            <div className="row align-items-center mb-3">
              <label className="col-4 mb-0">対象キャリースタッフ:</label>
              <input
                className="form-control col"
                value={carryStaffName}
                disabled
              />
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              発信する
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
