import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#wrapper");

interface MasterTag {
  id: number;
  name: string;
  selected: boolean;
}

interface Props {
  masterTags: MasterTag[];
  formInputName: string;
  formInputInnerName?: string;
  confirmMessage?: string;
}

interface State {
  masterTags: MasterTag[];
  searchTagCond: string;
  showOnlyChecked: boolean;
  selectAllTags: boolean;
  isModalOpen: boolean;
}

export default class MasterTagRelationsInForm extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      masterTags: this.props.masterTags,
      searchTagCond: "",
      showOnlyChecked: false,
      selectAllTags: false,
      isModalOpen: false,
    };
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    // タグ未設定の場合は確認モーダルを表示、設定されていたらそのまま処理実行
    const formButtonId = document.getElementById("form-submit-button");
    if (formButtonId) {
      formButtonId.addEventListener("click", this.handleFormSubmit);
    }
  }

  componentWillUnmount() {
    const formButtonId = document.getElementById("form-submit-button");
    if (formButtonId) {
      formButtonId.removeEventListener("click", this.handleFormSubmit);
    }
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    const hasChecked = this.state.masterTags.some((mt) => mt.selected);
    if (hasChecked) {
      if (this.props.confirmMessage) {
        const result = confirm(this.props.confirmMessage);
        result ? this.doSubmit() : false;
        return;
      }
      this.doSubmit();
    } else {
      this.setState({ isModalOpen: true });
    }
  };

  doSubmit = () => {
    $("#target-form").submit();
  };

  closeModalWindow = () => {
    this.setState({ isModalOpen: false });
  };

  onSelect(tagSettingId: number) {
    this.setState({
      masterTags: this.state.masterTags.map((tag) => {
        if (tag.id === tagSettingId) {
          tag.selected = !tag.selected;
          return tag;
        }
        return tag;
      }),
    });
  }

  render() {
    const isModalOpen = this.state.isModalOpen;
    const selectAllTagStyles = Object.assign(
      {},
      styles.tag,
      styles.selectAllTag,
      this.state.selectAllTags
        ? styles.selectAllTagRemoveVer
        : styles.selectAllTagAddVer
    );
    const inputFormName = `${this.props.formInputName}[${
      this.props.formInputInnerName ?? "tag_ids"
    }][]`;
    return (
      <div className="container px-0">
        <div className="row col-12 py-2 mb-3 mx-0" style={styles.container}>
          {/* 全てのタグ設定を外した場合でもname=tag_idsがフォームの要素として渡るように設定 */}
          <input
            type="hidden"
            name={inputFormName}
            style={{ display: "none" }}
            onChange={() => {}}
          ></input>
          {this.state.masterTags
            .filter((tag) => !this.state.showOnlyChecked || tag.selected)
            .map((tag) => {
              const tagStyles = Object.assign(
                {},
                tag.selected ? styles.selectedTag : styles.unselectedTag,
                styles.tagWrapper
              );
              return (
                <div
                  key={`tag_${tag.id}`}
                  style={tagStyles}
                  onClick={() => this.onSelect(tag.id)}
                >
                  <div style={{ ...styles.tag, ...styles.normalTag }}>
                    <input
                      type="checkbox"
                      name={inputFormName}
                      autoComplete="off"
                      value={tag.id}
                      style={{ display: "none" }}
                      checked={tag.selected}
                      onChange={() => {}}
                    ></input>
                    #{tag.name}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row align-items-center justify-content-end col-12 mx-0 px-0">
          <div
            className="row align-items-center col-12 col-sm-2 px-0"
            style={{ minWidth: 200 }}
          >
            <div
              style={styles.tagWrapper}
              onClick={() => {
                this.setState({
                  masterTags: this.state.masterTags.map((tag) => {
                    tag.selected = !this.state.selectAllTags;
                    return tag;
                  }),
                  selectAllTags: !this.state.selectAllTags,
                });
              }}
            >
              {this.state.selectAllTags ? (
                <i
                  className="fas fa-minus-circle"
                  style={{
                    ...styles.iconCommon,
                    ...styles.deleteIcon,
                  }}
                ></i>
              ) : (
                <i
                  className="fas fa-plus"
                  style={{
                    ...styles.iconCommon,
                    ...styles.addIcon,
                  }}
                ></i>
              )}
              <div style={selectAllTagStyles}>全てのタグ</div>
            </div>
          </div>
          <div
            className="row align-items-center col-12 col-sm-4 px-0"
            style={{ minWidth: 200 }}
          >
            <label className="form-check-label">
              <input
                type="checkbox"
                className="mr-2"
                checked={this.state.showOnlyChecked}
                onChange={() => {
                  this.setState({
                    showOnlyChecked: !this.state.showOnlyChecked,
                  });
                }}
              />
              チェック済みタグのみ表示
            </label>
          </div>
          <div className="col-12 col-sm-4 px-0" style={{ minWidth: 200 }}>
            <input
              type="text"
              className="form-control"
              value={this.state.searchTagCond}
              placeholder={"タグ検索"}
              onChange={(event) => {
                const serachStr = event.target.value;
                const filteredTags = this.props.masterTags.filter((tag) =>
                  tag.name.includes(serachStr)
                );
                this.setState({
                  searchTagCond: serachStr,
                  masterTags: filteredTags,
                });
              }}
            />
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.closeModalWindow}
          style={modalStyles}
        >
          <div>
            <h5 className="fas fa-exclamation-circle">
              {" "}
              タグが設定されていません
            </h5>
            <div className="my-2">
              タグ未設定のデータはタグ機能により非表示になります。
              <br />
              タグを設定してください。
            </div>
            <div className="text-right">
              <button
                className="btn btn-secondary mr-2"
                type="button"
                onClick={this.closeModalWindow}
              >
                戻る
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={this.doSubmit}
              >
                タグ未設定で続行
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const modalStyles: Modal.Styles = {
  content: {
    width: 500,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
    backgroundColor: "rgba(0,0,0,0.4)",
  },
};

const styles = {
  container: {
    display: "block",
    width: "100%",
    border: "1px solid #d1d3e2",
    borderRadius: "0.35rem",
    minHeight: 40,
    maxHeight: 200,
    overflow: "scroll",
  },
  tagWrapper: {
    display: "inline-block",
    marginRight: ".3em",
    cursor: "pointer",
  },
  tag: {
    display: "inline-block",
    padding: ".6em",
    lineHeight: 1,
    textDecoration: "none",
    border: "1px solid",
    borderRadius: "2em",
    fontSize: ".7em",
  },
  normalTag: {
    margin: "0 .1em .6em 0",
    borderColor: "#4e73df",
  },
  selectAllTag: {
    margin: "auto",
  },
  selectAllTagAddVer: {
    borderColor: "#4e73df",
  },
  selectAllTagRemoveVer: {
    borderColor: "red",
  },
  selectedTag: {
    color: "#4e73df",
    backgroundColor: "#fff",
  },
  unselectedTag: {
    color: "#4e73df",
    backgroundColor: "#fff",
    opacity: "0.4",
  },
  iconCommon: {
    margin: "0px 0.3em 0.6em 0px",
    lineHeight: 1,
    fontSize: ".7em",
  },
  addIcon: {
    color: "#4e73df",
  },
  deleteIcon: {
    color: "red",
  },
  searchInputWrapper: {
    minWidth: 400,
  },
};
