import format from "date-fns/format";
import { Bounds, Coords } from "google-map-react";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import RequestPolyline from "../components/RequestPolyline";
import MapAttributes from "../constants/MapAttributes";
import { AddressPoint } from "../interfaces/AddressPoint";
import Request from "../interfaces/Request";
import carryStaffLocationsStore from "../stores/CarryStaffLocationsStore";
import requestStore from "../stores/RequestStore";
import CarryStaffMarker from "./CarryStaffMarker";
import ReceiverMarker from "./ReceiverMarker";
import SenderMarker from "./SenderMarker";
import GoogleMap from "../components/Common/GoogleMap";

declare var gon: any;
const DEFAULT_ZOOM = 16;

interface Props {
  request: Request;
  carryStaffName: string;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  center: Coords;
  bounds: Bounds | null;
}

class RequestMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      center: this.props.request.sender,
      bounds: null,
    };
  }

  componentDidMount(): void {
    requestStore.subscribeRequest(this.props.request.id);
  }

  subscribe() {
    carryStaffLocationsStore.subscribeIn(this.state.center, this.state.bounds);
  }

  // 配送元・配送先が地図表示範囲に収まるようにビューポートを設定する
  private executeFitBounds(sender: AddressPoint, receiver: AddressPoint) {
    const bounds = new google.maps.LatLngBounds(
      // 南西隅, 北東隅の順に指定しないと正しく機能しないため計算して指定
      {
        lat: Math.min(sender.lat, receiver.lat),
        lng: Math.min(sender.lng, receiver.lng),
      },
      {
        lat: Math.max(sender.lat, receiver.lat),
        lng: Math.max(sender.lng, receiver.lng),
      }
    );

    if (this.state.map) {
      this.state.map.fitBounds(bounds);
      // 地点間の距離が近すぎる状態でズームすると周辺情報が全くわからないので、
      // fitBoundsを実行した結果のzoom値がDEFAULT_ZOOMより大きい場合はDEFAULT_ZOOMに戻す
      const zoomLevel = this.state.map.getZoom();
      if (zoomLevel > DEFAULT_ZOOM) {
        this.state.map.setZoom(DEFAULT_ZOOM);
      }
    }
  }

  private renderCarryStaffMarkers() {
    return _.map(carryStaffLocationsStore.items.slice(), (location) => {
      return (
        <CarryStaffMarker
          lat={location.lat}
          lng={location.lng}
          location={location}
          request={this.props.request}
          key={location.id}
        />
      );
    });
  }

  private renderCarryStaffPolyline() {
    if (!this.state.mapLoaded) {
      return;
    }
    if (!requestStore.request) {
      return;
    }

    return (
      <RequestPolyline
        map={this.state.map}
        mapApi={this.state.mapApi}
        deliveryItem={requestStore.request}
      />
    );
  }

  render() {
    const { request, carryStaffName } = this.props;
    const latestLoadedAt =
      carryStaffLocationsStore.loadingInfo.latestLoadedAt ?? new Date();
    return (
      <div className={"request-map-container"}>
        <span
          id="request-map-postion-read-at"
          style={{ position: "absolute", zIndex: 100, top: 0, left: 4 }}
        >
          {`位置情報 読込日時:  ${format(latestLoadedAt, "HH:mm:ss")}`}
        </span>

        {
          //carryStaff が指定されている場合は依頼俯瞰へのクエリパラメータ付きリンクを表示する
          request.carryStaffId && carryStaffName ?
            <span
              id="request-map-overlook-link"
              style={{ position: "absolute", zIndex: 100, top: 15, right: 60, padding: 5, backgroundColor: "#FFF" }}
            >
              <a href={"/carry_staff_overlook?carryStaffId=" + request.carryStaffId}>{`依頼俯瞰（` + carryStaffName + `）`}</a>
            </span>
            : ""
        }
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          defaultCenter={request.sender}
          defaultZoom={DEFAULT_ZOOM}
          center={request.sender}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) =>
            this.setState(
              {
                map: map,
                mapApi: maps,
                mapLoaded: true,
              },
              () => {
                this.executeFitBounds(request.sender, request.receiver);
              }
            )
          }
          onChange={(value) => {
            this.setState(
              {
                center: { ...value.center },
                bounds: { ...value.bounds },
              },
              () => {
                this.subscribe();
              }
            );
          }}
        >
          <SenderMarker
            lat={request.sender.lat}
            lng={request.sender.lng}
            request={request}
          />
          <ReceiverMarker
            lat={request.receiver.lat}
            lng={request.receiver.lng}
            request={request}
          />
          {this.renderCarryStaffMarkers()}
          {this.renderCarryStaffPolyline()}
        </GoogleMap>
      </div>
    );
  }
}

export default observer(RequestMap);
