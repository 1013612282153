import format from "date-fns/format";
import { Bounds } from "google-map-react";
import _ from "lodash";
import React from "react";
import { observer } from "mobx-react";
import OfficeMarker from "../components/OfficeMarker";
import RequestPolyline from "../components/RequestPolyline";
import MapAttributes from "../constants/MapAttributes";
import Office from "../interfaces/Office";
import carryStaffLocationsStore from "../stores/CarryStaffLocationsStore";
import requestsStore from "../stores/RequestsStore";
import OfficeCarryStaffMarker from "./OfficeCarryStaffMarker";
import SenderMarker from "./SenderMarker";
import ReceiverMarker from "./ReceiverMarker";
import GoogleMap from "../components/Common/GoogleMap";

declare var gon: any;

interface Props {
  office: Office;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  bounds: Bounds | null;
}

class OfficeMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      bounds: null,
    };
  }

  componentDidMount(): void {}

  subscribe() {
    carryStaffLocationsStore.subscribeIn(this.props.office, this.state.bounds);
    requestsStore.subscribeIn(this.props.office, this.state.bounds, false);
  }

  private renderOfficeMarker() {
    const { office } = this.props;
    return <OfficeMarker lat={office.lat} lng={office.lng} />;
  }

  private createCarryStaffMarkers() {
    return _.map(carryStaffLocationsStore.items.slice(), (location) => {
      return (
        <OfficeCarryStaffMarker
          key={location.id}
          lat={location.lat}
          lng={location.lng}
          location={location}
        />
      );
    });
  }

  private renderRequestSenderMarkers() {
    return _.map(requestsStore.items.slice(), (request) => {
      return (
        <SenderMarker
          key={request.id}
          lat={request.sender.lat}
          lng={request.sender.lng}
          request={request}
        />
      );
    });
  }

  private renderRequestReceiverMarkers() {
    return _.map(requestsStore.items.slice(), (request) => {
      return (
        <ReceiverMarker
          key={request.id}
          lat={request.receiver.lat}
          lng={request.receiver.lng}
          request={request}
        />
      );
    });
  }

  private renderRequestPolyline() {
    if (!this.state.mapLoaded) {
      return;
    }

    return _.map(requestsStore.items.slice(), (request) => {
      return (
        <RequestPolyline
          key={request.id}
          map={this.state.map}
          mapApi={this.state.mapApi}
          deliveryItem={request}
        />
      );
    });
  }

  render() {
    const { office } = this.props;
    const latestLoadedAt =
      carryStaffLocationsStore.loadingInfo.latestLoadedAt ?? new Date();
    return (
      <div className={"request-map-container"}>
        <span style={{ position: "absolute", zIndex: 100, top: 0, left: 4 }}>
          {`位置情報 読込日時:  ${format(latestLoadedAt, "HH:mm:ss")}`}
        </span>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          defaultCenter={office}
          defaultZoom={16}
          center={office}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) =>
            this.setState({
              map: map,
              mapApi: maps,
              mapLoaded: true,
            })
          }
          onChange={(value) => {
            this.setState({ bounds: { ...value.bounds } }, () =>
              this.subscribe()
            );
          }}
        >
          {this.renderOfficeMarker()}
          {this.createCarryStaffMarkers()}
          {this.renderRequestSenderMarkers()}
          {this.renderRequestReceiverMarkers()}
          {this.renderRequestPolyline()}
        </GoogleMap>
      </div>
    );
  }
}

export default observer(OfficeMap);
