import React from "react";
import requestAlertStore from "../stores/RequestAlertStore";
import { axiosPost } from "../utils/AxiosClient";

interface Props {
  label: string;
  request_alert: any;
  confirmed: boolean;
}

interface State {
  confirmed: boolean;
}

// 使われていなさそう
class RequestAlert extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmed: props.confirmed,
    };
  }

  render() {
    const confirmed = this.state.confirmed;
    var badgeStyle;
    if (confirmed) {
      badgeStyle = "request-confirmed-alert";
    } else if (this.props.label == "1分間未確認") {
      badgeStyle = "request-alert-unconfirmed";
    } else if (this.props.label == "一定時間未割当") {
      badgeStyle = "request-alert-no-assign";
    } else {
      badgeStyle = "request-address-inaccurate-label";
    }
    return (
      <div className={"dropdown"}>
        <span
          className={`${badgeStyle} badge dropdown-toggle`}
          data-toggle={"dropdown"}
        >
          {this.props.label}
        </span>
        <ul className={"dropdown-menu"} role={"menu"}>
          {!confirmed && (
            <li role="presentation">
              <a
                role={"menuitem"}
                href={"#"}
                style={{ marginLeft: 4 }}
                onClick={this.confirm}
              >
                確認済みにする
              </a>
            </li>
          )}
          {confirmed && (
            <li>
              <a
                role={"menuitem"}
                href={"#"}
                style={{ marginLeft: 4 }}
                onClick={this.unconfirm}
              >
                未確認にする
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }

  confirm = async () => {
    try {
      await axiosPost
        .post(`/api/user_request_alerts/${this.props.request_alert.id}/confirm`)
        .then(() => {
          this.setState({ confirmed: true });
          requestAlertStore.getAlertNum();
        });
    } catch (e) {}
  };

  unconfirm = async () => {
    try {
      await axiosPost
        .post(
          `/api/user_request_alerts/${this.props.request_alert.id}/unconfirm`
        )
        .then(() => {
          this.setState({ confirmed: false });
          requestAlertStore.getAlertNum();
        });
    } catch (e) {}
  };
}

export default RequestAlert;
