import { useDroppable } from "@dnd-kit/core";
import { format, startOfDay, endOfDay } from "date-fns";
import { observer } from "mobx-react";
import React, { FC, useEffect } from "react";
import { COLORS } from "../../../../constants/Colors";
import { requestConst } from "../../../../constants/Request";
import { CARRY_STAFF_DROPPABLE_TYPE } from "../../consts";
import {
  RawCarryStaffWithSchedules,
  RawRequestWithInfo,
  RawTerritory,
  RawVehicleType,
} from "../../interfaces";
import DraggableRequestCard from "./DraggableRequestCard";
import { pageStore } from "../../stores";

interface Props {
  isSelected: boolean;
  carryStaff: RawCarryStaffWithSchedules;
  territories: RawTerritory[];
  vehicleTypes: RawVehicleType[];
  draggingRequest: RawRequestWithInfo | null;
  requests: RawRequestWithInfo[];
  onSelect: () => void;
}

const CarryStaffDroppable: FC<Props> = (props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: `carry_staff_${props.carryStaff.id}`,
    data: {
      type: CARRY_STAFF_DROPPABLE_TYPE,
      carryStaffId: props.carryStaff.id,
    },
  });

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const territory = props.territories.find(
    (tr) => tr.id == props.carryStaff.territory_id
  );
  const vehicleType = props.vehicleTypes.find(
    (vt) => vt.id == props.carryStaff.vehicle_type_id
  );

  const assignedRequests = props.requests.filter((req) => {
    const targetDate = new Date(pageStore.schedulesTargetDate);
    const startOfTargetDay = startOfDay(targetDate);
    const endOfTargetDay = endOfDay(targetDate);

    const now = new Date();
    const startOfToday = startOfDay(now);
    const endOfToday = endOfDay(now);

    // 対象日が過去日付の場合
    // 対象日に配達済みになった依頼
    if (targetDate < startOfToday) {
      return (
        new Date(req.delivery_time_at) >= startOfTargetDay &&
        new Date(req.delivery_time_at) <= endOfTargetDay &&
        req.status == requestConst.STATUS_CODES.delivered &&
        req.carry_staff_id == props.carryStaff.id
      );
    }
    // 対象日が未来日付の場合
    // delivery_time_atが対象日かつ未配達の依頼
    else if (targetDate > endOfToday) {
      return (
        new Date(req.delivery_time_at) >= startOfTargetDay &&
        new Date(req.delivery_time_at) <= endOfTargetDay &&
        (req.status == requestConst.STATUS_CODES.wait_store ||
          req.status == requestConst.STATUS_CODES.uncheck ||
          req.status == requestConst.STATUS_CODES.checked ||
          req.status == requestConst.STATUS_CODES.pickup ||
          req.status == requestConst.STATUS_CODES.onhold ||
          req.status == requestConst.STATUS_CODES.return) &&
        req.carry_staff_id == props.carryStaff.id
      );
    } else {
      // 対象日が本日の場合
      // delivery_time_atが本日までの日時かつ未配達の依頼
      return (
        new Date(req.delivery_time_at) <= endOfToday &&
        (req.status == requestConst.STATUS_CODES.wait_store ||
          req.status == requestConst.STATUS_CODES.uncheck ||
          req.status == requestConst.STATUS_CODES.checked ||
          req.status == requestConst.STATUS_CODES.pickup ||
          req.status == requestConst.STATUS_CODES.onhold ||
          req.status == requestConst.STATUS_CODES.return) &&
        req.carry_staff_id == props.carryStaff.id
      );
    }
  });

  const isOverAndOtherArea =
    isOver && props.draggingRequest?.carry_staff_id !== props.carryStaff.id;

  return (
    <div
      ref={setNodeRef}
      className="card shadow d-flex flex-column p-2 border border-dark"
      style={{
        height: "100%",
        backgroundColor: isOverAndOtherArea
          ? "rgba(51, 163, 247, 0.2)"
          : undefined,
      }}
    >
      <div className="d-flex justify-content-start">
        <div
          className="d-flex flex-column justify-content-start"
          style={{ minWidth: 200 }}
        >
          <div className="d-flex justify-content-between align-items-center font-weight-bold">
            <div className="d-flex align-items-center">
              {props.isSelected ? (
                <i
                  className="far fa-eye mr-2"
                  style={{ cursor: "pointer", color: COLORS.brand.anycarry }}
                  onClick={props.onSelect}
                />
              ) : (
                <i
                  className="far fa-eye-slash mr-2"
                  style={{ cursor: "pointer" }}
                  onClick={props.onSelect}
                />
              )}
              <a
                href={`/carry_staffs/${props.carryStaff.id}/carry_staff_request_sequence/edit`}
                style={{ fontSize: "0.8rem" }}
              >
                {props.carryStaff.name}
              </a>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-start">
            <div className="d-flex" style={{ fontSize: 14 }}>
              <div className="d-flex align-items-center mr-2">
                <i className="fas fa-fw fa-street-view" />
                {territory ? <span>{territory.name}</span> : null}
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-fw fa-truck" />
                {vehicleType ? <span>{vehicleType.name}</span> : null}
              </div>
            </div>
            <div className="d-flex align-items-center" style={{ fontSize: 14 }}>
              <div className="d-flex justify-content-start align-items-center">
                <span className="mr-1">依頼数:</span>
                <span
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: COLORS.brand.anycarry,
                  }}
                >
                  {assignedRequests.length}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center" style={{ fontSize: 14 }}>
              <div className="d-flex flex-column align-items-start">
                <i className="fas fa-clock" />
              </div>
              <div className="px-1 d-flex flex-column justify-content-center">
                {props.carryStaff.schedules.length > 0 ? (
                  props.carryStaff.schedules.map((schedule, index) => {
                    return (
                      <div key={`schedule_${schedule.id}`}>
                        <span>
                          {format(new Date(`${schedule.from_time}`), "HH:mm")}
                        </span>
                        〜
                        <span>
                          {format(new Date(`${schedule.to_time}`), "HH:mm")}
                        </span>
                        {index < props.carryStaff.schedules.length - 1 ? (
                          <span className="mr-1">,</span>
                        ) : null}
                      </div>
                    );
                  })
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start flex-grow-1">
          <div
            className="mx-3"
            style={{
              paddingBottom: isOverAndOtherArea ? "40px" : undefined,
            }}
          >
            <div className="d-flex flex-wrap" style={{ minHeight: 10 }}>
              {assignedRequests
                .filter((request) => request.id != props.draggingRequest?.id)
                .map((request) => {
                  return (
                    <DraggableRequestCard
                      key={`request_${request.id}`}
                      isMini={true}
                      request={request}
                    />
                  );
                })}
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CarryStaffDroppable);
