import React from "react";
import { observer } from "mobx-react";
import deaasOverlookStore from '../stores/DeaasAreaOverlookStore';
import _ from 'lodash'

interface Props {
}

interface State {
  address: string
}

class DeaasAreaOverlookAddMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      address: ""
    }
  }

  componentDidMount(): void {
  }

  render() {
    return (
      <div>
        {this.renderSearchBox()}
        {this.renderAddMarker()}
        {this.renderNewMarkerList()}
      </div>
    );
  }

  private renderNewMarkerList() {
    return _.map(deaasOverlookStore.visibleNewMarkers, (newMarker) => {
      return (
        <div className={"form-check mb-2"}>
          <input type="checkbox" className={"form-check-input"} id={`newmarker-${newMarker.id}`}
                 checked={newMarker.visible} onClick={() => {
            deaasOverlookStore.toggleNewMarker(newMarker);
          }} />
          <label className={"form-check-label"} htmlFor={`newmarker-${newMarker.id}`}>
            {newMarker.id} - 緯度:{_.floor(newMarker.lat, 6)} 経度:{_.floor(newMarker.lng, 6)}
          </label>
        </div>
      )
    })
  }

  private renderSearchBox() {
    return (
      <React.Fragment>
        <div className="form-group form-inline mb-2">
          <input type="number" className="form-control input-overlook-range" value={deaasOverlookStore.newMarkerRange}
                 placeholder={""}
                 onChange={(event) => {
                   deaasOverlookStore.setNewMarkerRange(Number(event.target.value))
                 }}
          />
          <span>㍍</span>
          <button className="btn btn-primary ml-2" onClick={() => {
            deaasOverlookStore.viewAllNewMarkers()
          }}>
            <i className={"fas fa-eye"}/>
          </button>
          <button className="btn btn-primary ml-2" onClick={() => {
            deaasOverlookStore.hiddenAllNewMarkers()
          }}>
            <i className={"fas fa-eye-slash"}/>
          </button>
        </div>
      </React.Fragment>
    )
  }

  private renderAddMarker() {
    return (
      <div className="form-group form-inline mb-2">
        <input
          type="text" className="form-control input-overlook-address"
          value={this.state.address}
          placeholder={"住所（一部分でも可）"}
          onChange={(event) => {
            this.setState({
              address: event.target.value
            })
          }}
        />
        <button className="btn btn-info ml-2" onClick={() => {
          deaasOverlookStore.moveCenter(this.state.address)
        }}>住所検索
        </button>

        <button className="btn btn-primary ml-30" onClick={() => {
          deaasOverlookStore.addNewMarker(this.state.address)
        }}>地図中央にマーカー追加
        </button>
      </div>
    )
  }
}

export default observer(DeaasAreaOverlookAddMarker);
