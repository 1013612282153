import { useDraggable } from "@dnd-kit/core";
import { observer } from "mobx-react";
import React, { CSSProperties, FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Colors from "../../../../constants/BootstrapColors";
import I18n from "../../../../packs/i18n/i18n";
import "../../../../packs/i18n/ja";
import { checkCanChangeAssigning, getStatusText } from "../../../../utils/RequestEntityUtils";
import {
  getRangeDeliveryTimeSlotTimeLabel,
  existsDtSlot,
} from "../../../../utils/RequestEntityUtils";
import {
  getHhMmFromJstTimeUtcDateStr,
  getHhMmFromJstDateStr,
  isOverlapStrTimes,
} from "../../../../utils/TimeUtils";
import { RawRequestWithInfo } from "../../interfaces";
import { pageStore } from "../../stores";

interface Props {
  isMini: boolean;
  request: RawRequestWithInfo;
}

const DraggableRequestCard: FC<Props> = (props) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `request_${props.request.id}`,
    data: {
      requestId: props.request.id,
    },
  });

  const isSelected = props.request.id == pageStore.selectedReqId;

  // すでに確認済み以上のステータスの依頼はドラッグさせない
  const _props =
    ["wait_store", "uncheck"].indexOf(props.request.status) < 0
      ? undefined
      : {
          ...listeners,
          ...attributes,
        };

  // ドラッグ対象のアイテムは非表示にする
  const style: React.CSSProperties = isDragging
    ? {
        fontSize: 12,
        visibility: "hidden",
      }
    : {
        fontSize: 12,
      };

  const indexWrapperStyle: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${
      isSelected ? Colors.WARNING_COLOR : Colors.INFO_COLOR
    }`,
    color: isSelected ? Colors.WARNING_COLOR : Colors.INFO_COLOR,
    fontSize: "0.8rem",
    fontWeight: "bold",
    width: "36px",
    height: "24px",
  };

  /**
   * 配達時間の指定条件をチェックするメソッド
   * @param request
   */
  const checkDeliveryTimeCondition = (request: RawRequestWithInfo): boolean => {
    let selectedDtRangeFrom = pageStore.deliveryTimeRangeFrom;
    let selectedDtRangeTo = pageStore.deliveryTimeRangeTo;

    //強調表示選択によってハイライト表示条件の変更
    switch (pageStore.targetHighlighting) {
      case "all":
        return true;
      case "specified":
        if (!request.is_delivery_time_specified) return false;
        break;
      case "unspecified":
        return !request.is_delivery_time_specified;
    }

    // 条件指定されていない場合はスルー
    if (selectedDtRangeFrom == "" && selectedDtRangeTo == "") return true;

    selectedDtRangeFrom =
      selectedDtRangeFrom == "" ? "00:00" : selectedDtRangeFrom;
    selectedDtRangeTo = selectedDtRangeTo == "" ? "23:59" : selectedDtRangeTo;

    // delivery_time_slotが設定されている場合はその時間と比較
    const hasSlot = existsDtSlot(request);
    if (hasSlot) {
      const isOverlap = isOverlapStrTimes(
        {
          from: selectedDtRangeFrom,
          to: selectedDtRangeTo,
        },
        {
          from: getHhMmFromJstTimeUtcDateStr(
            request.delivery_time_slot_start_time
          ),
          to: getHhMmFromJstTimeUtcDateStr(request.delivery_time_slot_end_time),
        }
      );
      return isOverlap;
    } else if (request.delivery_time_at) {
      // delivery_time_slotが存在せず、delivery_time_atがあればその時間と比較
      const deliveryTimeAtHhMm = getHhMmFromJstDateStr(
        request.delivery_time_at
      );
      const isInRange = isOverlapStrTimes(
        { from: deliveryTimeAtHhMm, to: deliveryTimeAtHhMm },
        { from: selectedDtRangeFrom, to: selectedDtRangeTo }
      );
      return isInRange;
    }
    // それ以外のデータは除外しておく
    return false;
  };

  /**
   * 配達時間帯の設定によって、表示する内容を変更
   * @param request
   */
  const getDeliveryTimeLabel = (request: RawRequestWithInfo) => {
    return (request.is_delivery_time_specified && request.delivery_time_at)?
      getRangeDeliveryTimeSlotTimeLabel(request) : "時間指定なし";
  };

  if (props.isMini) {
    const isMatchDtCond = checkDeliveryTimeCondition(props.request);
    return (
      <div
        className="card shadow d-flex flex-column p-2 m-1"
        style={{
          ...style,
          minWidth: 160,
          // 詳細条件に合致しない依頼カードを薄くする
          ...(isMatchDtCond ? {} : { opacity: 0.2 }),
        }}
        ref={setNodeRef}
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ fontSize: 15 }}
        >
          <div className="d-flex align-items-center">
            <div
              className="d-flex align-items-center mr-1"
              onClick={() => pageStore.selectRequest(props.request.id)}
            >
              <div style={indexWrapperStyle}>
                {props.request.shortCode ?? "-"}
              </div>
            </div>
            <a href={`/requests/${props.request.id}/edit`}>
              依頼No: {props.request.id}
            </a>
          </div>
          <div className="p-1" style={{ cursor: "grab" }} {..._props}>
            {checkCanChangeAssigning(props.request) ? (
              <i className="fas fa-lg fa-hand-paper"></i>
            ) : (
              <OverlayTrigger
                key={props.request.id}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    ステータスが"
                    {I18n.t(`enums.request.status.${props.request.status}`)}
                    "のため変更することが出来ません。
                  </Tooltip>
                }
              >
                <i className="fas fa-lg fa-ban"></i>
              </OverlayTrigger>
            )}
          </div>
        </div>
        <div className="d-flex my-1">
          <div>
            <i className="fas fa-clock mr-1" />
          </div>
          <span>
            {getDeliveryTimeLabel(props.request)}
          </span>
          <span
            className="request-status-label badge ml-1"
            title={props.request.status}
            style={{ fontSize: 12 }}
          >
            {getStatusText(props.request.status)}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="card shadow d-flex flex-column p-2"
      style={style}
      ref={setNodeRef}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ fontSize: 15 }}
      >
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center mr-1"
            onClick={() => pageStore.selectRequest(props.request.id)}
          >
            <div style={indexWrapperStyle}>
              {props.request.shortCode ?? "-"}
            </div>
          </div>
          <a href={`/requests/${props.request.id}/edit`}>
            依頼No: {props.request.id}
          </a>
        </div>
        <div className="p-1" style={{ cursor: "grab" }} {..._props}>
          {checkCanChangeAssigning(props.request) ? (
            <i className="fas fa-lg fa-hand-paper"></i>
          ) : (
            <OverlayTrigger
              key={props.request.id}
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-request`}>
                  ステータスが"
                  {I18n.t(`enums.request.status.${props.request.status}`)}
                  "のため変更することが出来ません。
                </Tooltip>
              }
            >
              <i className="fas fa-lg fa-ban"></i>
            </OverlayTrigger>
          )}
        </div>
      </div>
      <div className="d-flex flex-column">
        <span style={{ fontWeight: "bold" }}>配達時間</span>
        <span>
          {getDeliveryTimeLabel(props.request)}
        </span>
      </div>
      <div className="d-flex flex-column">
        <span style={{ fontWeight: "bold" }}>配達先</span>
        <span>{props.request.receiver_full_address}</span>
      </div>
    </div>
  );
};

export default observer(DraggableRequestCard);
