import { ScheduleConst } from "./Constants"
import { ExpandedRawRequest } from "./Interfaces";


// baseの幅に対するtargetの開始地点までの長さの割合
function calcStartRatio(
  base: { start: Date, end: Date },
  target: { start: Date, end: Date }
) {
  const _result = Math.floor(
    (target.start.getTime() - base.start.getTime()) * 10000 /
    (base.end.getTime() - base.start.getTime())
  ) / 100;
  return _result;
}

// baseの幅に対するtargetの幅の割合
function calcWidthRatio(
  base: { start: Date, end: Date },
  target: { start: Date, end: Date }
) {
  const _result = Math.floor(
    (target.end.getTime() - target.start.getTime()) * 10000 /
    (base.end.getTime() - base.start.getTime())
  ) / 100;
  return _result;
}

// 表示するスケジュールデータの始点(left)とその幅(width)を計算する
export function calcScheduleDataPosition(
  base: { start: Date, end: Date },
  target: { start: Date, end: Date }
) {
  const leftRatio = calcStartRatio(base, target);
  const widthRatio = calcWidthRatio(base, target);

  return {
    leftRatio,
    widthRatio
  }
}

// 表示する依頼データの始点(left)と地点間の幅(width)を計算する
function calcRequestDataPosition(
  base: { start: Date, end: Date },
  pickup: { start: Date, end: Date },
  delivery: { start: Date, end: Date }
) {
  /** 
   *        |---|-------|---|
   *  |------------------------------|
   * B.S 　 A   B       C   D        B.E
   * 
   *  B.S: base.start,   B.E: base.end
   *  A: pickup.start,   B: pickup.end
   *  C: delivery.start, D: delivery.end
   **/

  // 依頼全体はpickup.start ~ delivery.end(A~D)
  const requestRange = { start: pickup.start, end: delivery.end };

  // (B.S~A)baseの幅に対する依頼全体の開始地点までの長さの割合
  const startRatioOfRequestToBase = calcStartRatio(base, pickup);

  // (A~D)baseの幅に対する依頼全体の幅の割合
  const widthRatioOfRequestToBase = calcWidthRatio(base, requestRange);

  // (A~B)依頼全体の幅に対するpickupの幅の割合
  const widthRatioOfPickupToRequest = calcWidthRatio(requestRange, pickup);

  // (C~D)依頼全体の幅に対するdeliveryの幅の割合
  const widthRatioOfDeliveryToRequest = calcWidthRatio(requestRange, delivery);

  return {
    startRatioOfRequestToBase,
    widthRatioOfRequestToBase,
    widthRatioOfPickupToRequest,
    widthRatioOfDeliveryToRequest
  }
}

interface RangeItem {
  startPosition: number
  endPosition: number
}
type RequestRangeItem = { requestId: number } & RangeItem;

export function createIndependentRequestsArrangementMap(
  targetDate: string,
  independentRequests: ExpandedRawRequest[]
) {
  // 各依頼が何行目(インデックスなので0から)に配置されているのかの連想配列
  let indexMap: { [key: string]: number } = {};
  // 各依頼がその行において、どの位置(左からの割合)にどのくらいの長さ(幅の割合)で存在するかの連想配列
  let positionMap: {
    [key: string]: {
      startRatioOfRequestToBase: number,
      widthRatioOfRequestToBase: number,
      widthRatioOfPickupToRequest: number,
      widthRatioOfDeliveryToRequest: number
    }
  } = {};
  let rows: RangeItem[][] = [];

  const baseStartDate = new Date(`${targetDate} ${('00' + ScheduleConst.FROM_TIME).slice(-2)}:00:00+09:00`);
  const baseEndDate = new Date(`${targetDate} ${('00' + ScheduleConst.TO_TIME).slice(-2)}:00:00+09:00`);
  for (const req of independentRequests) {
    const {
      startRatioOfRequestToBase,
      widthRatioOfRequestToBase,
      widthRatioOfPickupToRequest,
      widthRatioOfDeliveryToRequest
    } = calcRequestDataPosition(
      { start: baseStartDate, end: baseEndDate },
      { start: new Date(req.from_pickup_at), end: new Date(req.to_pickup_at) },
      { start: new Date(req.from_delivery_time_at), end: new Date(req.to_delivery_time_at) }
    );

    const range = {
      startPosition: startRatioOfRequestToBase,
      endPosition: startRatioOfRequestToBase + widthRatioOfRequestToBase
    };

    const res = getInsertPositionInArrangementRequestRows(rows, range);
    if (res.canInsert) {
      rows[res.index].push(range)
    } else {
      rows.push([range]);
    }

    indexMap[`${req.id}`] = res.canInsert ? res.index : rows.length - 1;
    positionMap[`${req.id}`] = {
      startRatioOfRequestToBase: startRatioOfRequestToBase,
      widthRatioOfRequestToBase: widthRatioOfRequestToBase,
      widthRatioOfPickupToRequest: widthRatioOfPickupToRequest,
      widthRatioOfDeliveryToRequest: widthRatioOfDeliveryToRequest
    };
  }

  return {
    indexMap,
    positionMap,
    rowLength: rows.length
  }
}

/**
 * 同じ行内で範囲重複が生じないように格納し、与えられた範囲が属するindexを返す関数
 * @param rows すでにグループ化されている範囲データの配列(の配列)
 * @param target 挿入対象となる範囲データ
 * @returns 
 */
function getInsertPositionInArrangementRequestRows(
  rows: RangeItem[][],
  target: RangeItem
): { canInsert: true, index: number } | { canInsert: false, index: null } {
  for (let i = 0; i < rows.length; i++) {
    let isOverlap = false;

    // 一行に表示するバーアイテム({start, end})の配列
    const row = rows[i];
    for (const { startPosition: itemStart, endPosition: itemEnd } of row) {
      // 重複していない場合の否定条件
      if (!(target.startPosition > itemEnd || target.endPosition < itemStart)) {
        isOverlap = true;
        break;
      }
    }

    if (!isOverlap) {
      return {
        canInsert: true,
        index: i
      }
    }
  }

  return {
    canInsert: false,
    index: null
  }
}

/**
 * 独立表示の依頼リストとグループ化した依頼リストに分けて返す関数
 * @param targetDate YYYY-MM-DD形式の文字列
 * @param casRequests ある配達スタッフにアサインされている依頼リスト
 * @param maxRequestRowCount 
 * @returns 
 */
export function classifyRequests(targetDate: string, casRequests: ExpandedRawRequest[], maxRequestRowCount: number) {
  const ranges = convertRequestToCalculableRangeValue(targetDate, casRequests);
  const overlapGroups = createOverlapGroups(ranges);

  // グループ化する必要のある（表示行数条件を越える重複範囲を持つ）依頼グループと、単独表示依頼に分類する
  let groupRanges: RequestRangeItem[][] = [];
  let independentRanges: RequestRangeItem[] = [];
  overlapGroups.forEach(group => {
    const uniqueGroups = createUniqueGroups(group);
    // 独立表示した場合の行数（配列のグループ数）が設定値を上回るときにグループ化する
    if (uniqueGroups.length > maxRequestRowCount) {
      groupRanges.push(group);
    } else {
      independentRanges.push(...group);
    }
  });

  // 分類したrangesを依頼データに戻す
  let groupRequests: { requests: ExpandedRawRequest[], range: RangeItem }[] = [];

  for (const group of groupRanges) {
    const ids = group.map(item => item.requestId);
    const _groupRequests = casRequests.filter(req => ids.indexOf(req.id) >= 0);
    const startPositions = group.map(item => item.startPosition);
    const endPositions = group.map(item => item.endPosition);
    // グループ化されたバーのポジション情報はこの時点で決まっているので一緒に返す
    const range = {
      startPosition: Math.min(...startPositions),
      endPosition: Math.max(...endPositions)
    }
    groupRequests.push({ requests: _groupRequests, range: range })
  }

  const independentRequestIds = independentRanges.map(range => range.requestId);
  const independentRequests = casRequests.filter(req => independentRequestIds.indexOf(req.id) >= 0);

  return {
    independentRequests: independentRequests,
    groupRequests: groupRequests
  }
}

/**
 * 範囲の「重複する」組にグループ化した配列を返す関数.
 * 
 * 返り値であるoverlapGroupsについて言えるのは、  
 * そのoverlapGroupの各要素はそのoverlapGroupに含まれる他の少なくとも1つの要素と重複を持つということ。  
 * 下記の例ではプロパティ名を省略
 * ```ts
 * ranges = [
 *   {1, 10}, {2, 11}, {3, 12}, {21, 30}, {22, 31}, {41, 50}
 * ]
 * ```
 * => 
 * ```
 * overlapGroups = [
 *   [{1, 10}, {2, 11}, {3, 12}]
 *   [{21, 30}, {22, 31}]
 *   [{41, 50}]
 * ]
 * ```
 * @param ranges 
 * @returns 
 */
function createOverlapGroups(ranges: RequestRangeItem[]) {
  let overlapGroups: RequestRangeItem[][] = [];
  ranges.forEach(range => {
    let overlap = false;
    for (const overlapGroup of overlapGroups) {
      // グループ内に範囲の重複する組が存在したらその配列に追加する
      if (overlapGroup.some(item =>
        !(item.startPosition > range.endPosition || item.endPosition < range.startPosition))) {
        overlapGroup.push(range);
        overlap = true;
        break;
      }
    }
    if (!overlap) {
      // 範囲の重複する組が存在しなければ新しい配列として追加する
      overlapGroups.push([range]);
    }
  });

  return overlapGroups;
}

/**
 * 範囲の「重複しない」組にグループ化した配列を返す関数.
 * 
 * 返り値であるuniqueGroupsについて言えるのは、  
 * そのuniqueGroupの各要素はそのuniqueGroupの他のどの要素とも重複を持たないということ。  
 * 下記の例ではプロパティ名を省略
 * ```ts
 * ranges = [
 *   {1, 10}, {2, 11}, {3, 12}, {21, 30}, {22, 31}, {41, 50}
 * ]
 * ```
 * => 
 * ```
 * uniqueGroups = [
 *   [{1, 10}, {21, 30}, {41, 50}]
 *   [{2, 11}, {22, 31}]
 *   [{3, 12}]
 * ]
 * ```
 * @param ranges 
 * @returns 
 */
function createUniqueGroups(ranges: RequestRangeItem[]) {
  let uniqueGroups: RequestRangeItem[][] = [];
  ranges.forEach(range => {
    let unique = false;
    for (const uniqueGroup of uniqueGroups) {
      // グループ内のデータと範囲重複が存在しなければそのグループに追加する
      if (uniqueGroup.some(item =>
        (item.startPosition > range.endPosition || item.endPosition < range.startPosition))) {
        uniqueGroup.push(range);
        unique = true;
        break;
      }
    }
    if (!unique) {
      // 既存グループと範囲が重複していたら新しいグループとして追加する
      uniqueGroups.push([range]);
    }
  });

  return uniqueGroups;
}

/**
 * 依頼データを範囲計算しやすいよう加工した配列に変換する関数
 * @param targetDate YYYY-MM-DD形式の文字列
 * @param casRequests 
 * @returns 
 */
function convertRequestToCalculableRangeValue(
  targetDate: string,
  casRequests: ExpandedRawRequest[]
) {
  let ranges: RequestRangeItem[] = [];
  const baseStartDate = new Date(`${targetDate} ${('00' + ScheduleConst.FROM_TIME).slice(-2)}:00:00+09:00`);
  const baseEndDate = new Date(`${targetDate} ${('00' + ScheduleConst.TO_TIME).slice(-2)}:00:00+09:00`);
  for (const req of casRequests) {
    const {
      startRatioOfRequestToBase,
      widthRatioOfRequestToBase
    } = calcRequestDataPosition(
      { start: baseStartDate, end: baseEndDate },
      { start: new Date(req.from_pickup_at), end: new Date(req.to_pickup_at) },
      { start: new Date(req.from_delivery_time_at), end: new Date(req.to_delivery_time_at) }
    );

    const range = {
      requestId: req.id,
      startPosition: startRatioOfRequestToBase,
      endPosition: startRatioOfRequestToBase + widthRatioOfRequestToBase
    };

    ranges.push(range);
  }
  return ranges
}
