import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";

interface ActionDetail {
  action: string;
  label: string;
  allowed: boolean;
}

interface ControllerDetail {
  controller: string;
  label: string;
  actions: ActionDetail[];
}

interface Props {
  policySettings: ControllerDetail[];
  readonly?: boolean;
}

interface State {
  policySettings: ControllerDetail[];
}

export default class PolicyGroupDetailForm extends React.Component<
  Props,
  State
> {
  // 画面に表示するコントローラーに対して階層構造を設定したい場合用の設定
  MENU_HIERARCHIES = [
    {
      label: "依頼",
      code: "request",
      submenus: [
        "RequestsController",
        "DeliveryTimeChangesController",
        "SevenRequestsController",
        "HoldingSidewaysRequestsController",
        "ThirdPartyDeliveryTasksController",
        "CarryStaffOverlooksController",
        "DeliveryOffersController",
        "RouteDeliveryRequestsController",
        "RoutesApiParamSettingsController",
        "PreDeliveryNotificationMailsController",
      ],
    },
    {
      label: "店舗会社",
      code: "vendor_company",
      submenus: [
        "VendorCompaniesController",
        "VendorUsersController",
        "VendorDevicesController",
        "SimpleDeaasUsersController",
        "DeaasTemplatesController",
        "VendorCallHistoriesController",
        "VendorCompanyConfigsController",
        "WoltMerchantsController",
        "UberAccountsController",
        "MenuAccountsController",
      ],
    },
    {
      label: "配達スタッフ",
      code: "carry_staff",
      submenus: [
        "CarryStaffsController",
        "CarryStaffActivityTimesController",
        "CarryStaffAppLinksController",
        "CarryStaffAppFormSettingsController",
        "CasCallHistoriesController",
        "CognitoUserSettingsController",
        "CarryStaffSchedulesController",
        "CarryStaffCompaniesController",
        "CarryStaffCompanyStaffAssignmentsController",
        "CarryStaffCompanyVehicleAssignmentsController",
        "VehiclesController",
        "VehicleTypesController",
        "TerritoriesController",
        "BulkAssignAreasController",
        "AttendancesController",
        "CorrectStampLogsController",
        "CarryStaffDrivingDailyReportsController"
      ],
    },
    {
      label: "顧客",
      code: "customer",
      submenus: ["CustomersController"],
    },
    {
      label: "拠点",
      code: "office",
      submenus: ["OfficesController", "OverlooksController"],
    },
    {
      label: "サービスエリア",
      code: "service_area",
      submenus: [
        "ServiceAreasController",
        "DeaasAreaOverlooksController",
        "EntireServiceAreaSuspensionsController",
        "AnycarryNoDeliveryAreasController",
      ],
    },
    {
      label: "ヘックス",
      code: "h3",
      submenus: [
        "H3CapacityTimelinesController",
        "H3MinPickupMinutesSettingsController",
      ],
    },
    {
      label: "住所一覧",
      code: "address",
      submenus: ["AddressesController"],
    },
    {
      label: "スポット情報",
      code: "spot_remark",
      submenus: [
        "SpotRemarksController",
        "SpotRemarkNegativeReviewsController",
      ],
    },
    {
      label: "輸送経路（拠点）",
      code: "facility",
      submenus: [
        "FacilitiesController",
      ],
    },
    {
      label: "データダウンロード",
      code: "data_download",
      submenus: ["DataDownloadsController"],
    },
    {
      label: "管理ユーザー",
      code: "user",
      submenus: ["UsersController"],
    },
    {
      label: "エクスポート結果",
      code: "file_export",
      submenus: ["FileExportsController"],
    },
    {
      label: "権限グループ",
      code: "policy_group",
      submenus: ["PolicyGroupsController"],
    },
    {
      // 上記で記載されたコントローラー以外のコントローラーが全て入る
      label: "その他",
      code: "others" as const,
      submenus: [],
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      policySettings: this.props.policySettings,
    };
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(controllerName: string, action: string) {
    const controller = this.state.policySettings.find(
      (cntr) => cntr.controller == controllerName
    );
    if (!controller) {
      return;
    }

    const targetAction = controller.actions.find((act) => act.action == action);
    if (targetAction) {
      if (action == "read" && targetAction.allowed) {
        // チェック対象が参照、かつ参照を許可する場合(元々チェックが入っていた場合)
        // 他のアクションを全て禁止とする
        controller.actions.forEach((detail) => {
          if (detail.action != "read") {
            detail.allowed = false;
          }
        });
      } else if (action != "read" && !targetAction.allowed) {
        // チェック対象が参照以外で、対象アクションを許可する場合、
        // 参照権限も許可する
        controller.actions.forEach((detail) => {
          if (detail.action == "read") {
            detail.allowed = true;
          }
        });
      }
    }

    // チェックを入れ替える
    controller.actions.forEach((detail) => {
      if (detail.action === action) {
        detail.allowed = !detail.allowed;
      }
    });

    this.setState({
      policySettings: [...this.state.policySettings],
    });
  }

  changeAllCheckboxesSelectState(checkAll: boolean) {
    const updatedPolicySettings = this.state.policySettings.map(
      (controller) => {
        const updatedActions = controller.actions.map((detail) => {
          return { ...detail, allowed: checkAll };
        });

        return { ...controller, actions: updatedActions };
      }
    );

    this.setState({
      policySettings: updatedPolicySettings,
    });
  }

  render() {
    // 表示順
    const actionOrder = { read: 1, create: 2, update: 3, destroy: 4 };
    const readonly = this.props.readonly;
    const heading = readonly ? "権限一覧" : "権限設定";

    return (
      <div className="mb-4">
        <Row>
          <div className="col-4 mb-4">
            <h4>{heading}</h4>
          </div>
          {!readonly && (
            <div className="col-6 text-right">
              <div className="mb-4">
                <Button
                  variant="primary"
                  size="sm"
                  className="mr-2"
                  onClick={() => this.changeAllCheckboxesSelectState(true)}
                >
                  全て選択
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => this.changeAllCheckboxesSelectState(false)}
                >
                  全て解除
                </Button>
              </div>
            </div>
          )}
        </Row>
        <div className="d-flex flex-column border-left-info pl-4 mb-3">
          {this.MENU_HIERARCHIES.map((menu) => {
            let controllers: ControllerDetail[] = [];
            // 権限管理対象だが、MENU_HIERARCHIESに記述されていないコントローラーを取得
            if (menu.code == "others") {
              const submenuControllers = [].concat.apply(
                [],
                this.MENU_HIERARCHIES.filter(
                  (menu) => menu.code != "others"
                ).map((menu) => menu.submenus)
              ) as string[];

              controllers = this.state.policySettings.filter(
                (cntr) => submenuControllers.indexOf(cntr.controller) === -1
              );
            } else {
              // MENU_HIERARCHIESで指定した順番通りに表示したいのでsubmenusベースで取得
              controllers = menu.submenus
                .filter((submenu) =>
                  this.state.policySettings.some(
                    (setting) => setting.controller === submenu
                  )
                )
                .map(
                  (submenu) =>
                    this.state.policySettings.find(
                      (setting) => setting.controller === submenu
                    )!
                );
            }

            // 該当するコントローラーが存在しない場合は何も表示しない
            if (controllers.length === 0) return null;

            return (
              <div key={menu.code} className="d-flex">
                <div className="col-2 p-0">{menu.label}</div>
                <div className="col-10">
                  <div className="form-group">
                    {controllers.map((controllerDetail) => {
                      return (
                        <div
                          className="d-flex"
                          key={controllerDetail.controller}
                        >
                          <div className="border-left-info pl-4 col-4 text-break">
                            {controllerDetail.label}
                          </div>
                          <Row className="col-6">
                            {Object.keys(actionOrder).map((_action) => {
                              const actionDetail =
                                controllerDetail.actions.find(
                                  (action) => action.action == _action
                                );
                              if (actionDetail) {
                                return (
                                  <Col
                                    key={`detial_${_action}`}
                                    md={3}
                                    className="mb-2"
                                    onClick={() => {
                                      if (!readonly) {
                                        this.onSelect(
                                          controllerDetail.controller,
                                          actionDetail.action
                                        );
                                      }
                                    }}
                                  >
                                    <Form.Check
                                      inline
                                      custom
                                      type="checkbox"
                                      name={`policies[${controllerDetail.controller}][${actionDetail.action}]`}
                                      autoComplete="off"
                                      checked={actionDetail.allowed}
                                      label={actionDetail.label}
                                      disabled={readonly}
                                      onChange={() => {}}
                                    />
                                  </Col>
                                );
                              }

                              return (
                                <Col
                                  key={`detial_${_action}`}
                                  md={3}
                                  className="mb-2"
                                ></Col>
                              );
                            })}
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
