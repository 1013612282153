import React from 'react'
export type SVGIcon = "bicycle" | "bike" | "car" | "walk" | "person" | "restaurant" | "shop" | "building" | "circle" | "device"
interface Props {
  size?: number
  color?: string
  icon: SVGIcon
}
export class MarkerIcon extends React.Component<Props> {

  render() {
    const { size, color, icon } = this.props
    return (
      <svg
        id="icons"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width={size || 64}
        height={size || 64}
        fill={color}
      >
        {(() => {
          switch (icon) {
            case "bicycle":
              return <BicycleSVG />

            case "bike":
              return <BikeSVG />

            case "car":
              return <CarSVG />

            case "walk":
              return <WalkSVG />

            case "person":
              return <PersonSVG />

            case "building":
              return <BuildingSVG />

            case "restaurant":
              return <RestaurantSVG />

            case "shop":
              return <ShopSVG />

            case "circle":
              return <CricleSVG size={size || 64} />

            case "device":
              return <DeviceSVG />

            default:
              return <PersonSVG />

          }
        })()}
      </svg>
    )

  }
}

class BicycleSVG extends React.Component {
  render() {
    return (
      <>
        <path d="M.07,37.31a7.36,7.36,0,0,0,.31-.9,12.48,12.48,0,0,1,12.17-9.8c.36,0,.72.06,1.21.11a31.26,31.26,0,0,1-1,3.1c-.15.31-.76.5-1.19.58a8.75,8.75,0,1,0,9,12.39,8.56,8.56,0,0,0-2-10,1.45,1.45,0,0,1-.45-1.9c.32-.8.56-1.62.84-2.44,4,1.94,6.71,7.32,6.12,11.83-.86,6.51-5.46,11-12,11.32A12.37,12.37,0,0,1,.38,41.71a8.29,8.29,0,0,0-.31-.92Z" />
        <path d="M12.87,14.81V12.6a37.7,37.7,0,0,1,4.26,0,4.06,4.06,0,0,1,3.54,5.22c-.09.43-.22.86-.36,1.4,2.62.64,5.13,1.27,7.66,1.88,2.21.53,4.44,1,6.63,1.6,1,.3,1.55.11,1.77-.94a9.83,9.83,0,0,1,.47-1.34l2.19.27a4.57,4.57,0,0,1-.27,1c-1,1.75-.52,3,.94,4.34,3.31,3.11,6.42,6.44,9.57,9.64L47.63,37,37.14,26.37l-4,11.71H47.42v2.19H38.63c-2.16,0-4.32,0-6.47,0a2.09,2.09,0,0,1-2-1.08C26.8,34.07,23.42,29,20.05,23.83c-.22-.34-.46-.66-.88-1.25L14.81,35.93l-2.1-.7c.83-2.57,1.63-5.08,2.44-7.58q1.59-4.91,3.17-9.8c.64-2-.12-3-2.23-3Zm9,7.14-.16.29,9.5,14.45c1.38-4,2.66-7.73,4-11.54Z" />
        <path d="M44.47,28.68c4.16-3.06,10.66-2.64,14.75.8a12.5,12.5,0,0,1-10.75,21.8c-4.77-1.11-9.18-5.52-9.21-9.37a30.64,30.64,0,0,1,3.18,0c.32,0,.67.52.86.88a8.52,8.52,0,0,0,7.1,5,8.39,8.39,0,0,0,8.15-4,8.6,8.6,0,0,0-.41-10,8.5,8.5,0,0,0-9.64-3,1.73,1.73,0,0,1-2.31-.54A16,16,0,0,0,44.47,28.68Z" />
        <path d="M38.42,15.79H42c.84,0,1.22.4,1.09,1.25-.07.45-.16.89-.21,1.34-.1.79-.57,1.05-1.3,1l-7.16-.74c-1-.1-1-.79-1-1.53s.28-1.28,1.12-1.28Z" /><path d="M12.58,41.76a2.5,2.5,0,0,1-2.53-2.64,2.58,2.58,0,0,1,5.16.08A2.56,2.56,0,0,1,12.58,41.76Z" />
        <path d="M53.77,39.24a2.5,2.5,0,0,1-2.65,2.52,2.57,2.57,0,1,1,2.65-2.52Z" />
        <path d="M40.57,32.25c1,1,1.66,1.64,2.23,2.38A1.53,1.53,0,0,1,43,35.87a1.3,1.3,0,0,1-1,.61c-1,.09-2,0-3.22,0Z" />
      </>
    )
  }

}

class BikeSVG extends React.Component {
  render() {
    return (
      <>
        <path d="M64,42.61c-.35-1.63-.62-3.29-1-4.9a16,16,0,0,0-3.86-7.22,2,2,0,0,0-1.22-.67c-6-.05-12,0-18.11,0A39.36,39.36,0,0,1,39,33.68,17.23,17.23,0,0,1,37.4,37.4a5.93,5.93,0,0,1-5.38,3c-1.94,0-3.88,0-5.82,0a1.14,1.14,0,0,1-1.32-.93,14.07,14.07,0,0,0-8.2-9c-.73-.33-.89-.68-.72-1.4q1.84-7.87,3.63-15.76c.16-.7.44-1,1.15-.93a18.73,18.73,0,0,0,3.09,0c.41-.06.75-.57,1.13-.88-.39-.32-.76-.89-1.17-.91-1.72-.11-3.47,0-5.19-.08a2.5,2.5,0,0,0-2.77,1.9c-1.57,4.16-3.2,8.3-4.81,12.44-.49,1.27-.92,2.57-1.5,3.79a2.08,2.08,0,0,1-1.18,1c-3.31.89-5.52,2.08-6.94,3.9L2.33,35c4.37-2.59,8.83-3.52,13.47-1.18s6.56,6.5,6.82,11.68h39.9c.49,0,1,0,1.48.05Z" />
        <path d="M10.54,54.45a9.65,9.65,0,1,1-.38-19.29,9.65,9.65,0,0,1,.38,19.29Zm-.22-4.79a5,5,0,0,0,4.89-4.9,5.07,5.07,0,0,0-5-4.89,5,5,0,0,0-4.83,5A4.84,4.84,0,0,0,10.32,49.66Z" />
        <path d="M60.68,47.13H42.29c.25,3.72,4.65,7.29,8.94,7.32C55.9,54.49,60.33,51.09,60.68,47.13Z" />
        <path d="M59.29,28.18c0-.78.06-1.6,0-2.41a1.55,1.55,0,0,0-1.72-1.44c-4.91,0-9.82-.06-14.73,0a3.34,3.34,0,0,0-3.47,3.07c-.05.59.08.95.78.95l18.7,0A1.79,1.79,0,0,0,59.29,28.18Z" />
        <path d="M8,21.25c1.57.14,3.06.34,4-1.15a3,3,0,0,0,0-3.28c-.94-1.48-2.43-1.3-4-1.15Z" />
        <path d="M6.36,15.5c-.23.28-.66.54-.68.83-.07,1.44-.06,2.89,0,4.33,0,.27.42.52.65.78l.39-.21V15.72Z" />
      </>
    )
  }
}

class CarSVG extends React.Component {

  render() {
    return (
      <path d="M0,32.5c.8-2.56,2.66-3.71,5.21-4.21,3.75-.74,7.46-1.65,11.18-2.55a3.62,3.62,0,0,0,1.56-.9c1.7-1.65,3.34-3.37,5-5.05a12.21,12.21,0,0,1,9-3.74h19.5a7.72,7.72,0,0,1,6.9,3.77c1.39,2.17,2.81,4.33,4.1,6.56A34.37,34.37,0,0,1,64,30v9.5a3,3,0,0,1-3,1.27c-.2,0-.41,0-.62,0-.88,0-2-.27-2.56.13s-.55,1.49-.78,2.29a6.68,6.68,0,0,1-13-1.07c-.06-.41-.14-.81-.23-1.27H22.82c-.1.55-.18,1.07-.29,1.59a6.66,6.66,0,0,1-7,5.51,6.59,6.59,0,0,1-6.24-6.18c-.07-.89-.44-1-1.17-1-1.71,0-3.42,0-5.12,0A3.19,3.19,0,0,1,0,39.5ZM46.86,27c0-2.29,0-4.45,0-6.61a1.13,1.13,0,0,0-.78-.78q-4.55-.07-9.11,0c-.26,0-.74.36-.74.56-.06,2.27,0,4.54,0,6.92h9.42C45.93,27,46.3,27,46.86,27ZM33,19.68c-4-.68-9.61,2.81-11.78,7.24H33ZM58.67,27c-1.21-1.92-2.24-3.61-3.32-5.28A4.55,4.55,0,0,0,50,19.73V27ZM16,38.49a2.85,2.85,0,0,0-2.78,2.66A2.8,2.8,0,0,0,15.83,44a2.86,2.86,0,0,0,2.87-2.7A2.91,2.91,0,0,0,16,38.49ZM53.3,41.21a2.78,2.78,0,0,0-2.69-2.72,2.82,2.82,0,0,0-2.71,2.84A2.78,2.78,0,0,0,50.67,44,2.71,2.71,0,0,0,53.3,41.21Z" />
    )
  }
}

class WalkSVG extends React.Component {
  render() {
    return (
      <>
        <path d="M30.86,12a5.68,5.68,0,0,0,5.81-5.8A5.84,5.84,0,1,0,25,6.2,5.71,5.71,0,0,0,30.86,12Z" />
        <path d="M22,63.93a4.19,4.19,0,0,0,2.21-2.37c.61-1.45,1.47-2.81,2-4.29,1.79-5.24,5.66-9,9.27-12.89a2.75,2.75,0,0,1,.41-.26c.21.41.41.8.62,1.19,3.08,5.58,6.14,11.17,9.24,16.74a2.86,2.86,0,0,0,4,1.34c1.4-.7,1.88-2.13,1.22-3.82C49.92,57,49,54.46,47.84,52c-2.49-5.39-3.37-11.22-4.72-16.93-.93-3.9-1.76-7.83-2.63-11.74a10.06,10.06,0,0,1-.13-1c1.78.57,3.43,1.05,5,1.64.36.13.64.62.82,1,1,2.09,2,4.21,2.94,6.3.69,1.48,1.91,2,3.2,1.45s1.63-1.8,1-3.27c-1.18-2.83-2.31-5.68-3.64-8.43a5.29,5.29,0,0,0-2.16-2.26,89.46,89.46,0,0,0-8.64-4.07,12.08,12.08,0,0,0-4.22-.49,14.17,14.17,0,0,0-2.93.51,10.12,10.12,0,0,0-7.4,5.68c-.7,1.37-1.43,2.72-2,4.14a4.64,4.64,0,0,1-2.51,2.56c-1.64.79-3.24,1.67-4.84,2.53a2.25,2.25,0,0,0-1.2,3,2.2,2.2,0,0,0,3.06,1c2.51-1.09,5.05-2.12,7.45-3.41a10.88,10.88,0,0,0,2.5-2.52c.52-.57,1-1.2,1.65-2,.44,2.18.82,4.06,1.19,5.95.29,1.51.49,3,.87,4.51a2.18,2.18,0,0,1-.5,2c-1.73,2.23-3.51,4.42-5.08,6.76a29,29,0,0,0-2.84,5.33c-1.17,3-2.11,6-3.13,9-.86,2.54-.64,3.22,1.47,4.68Z" />
      </>
    )
  }
}

class PersonSVG extends React.Component {
  render() {
    return (
      <>
        <path d="M15.42,58c-3.55-1.61-4.15-6.43-2.63-9.48a19.15,19.15,0,0,1,9.11-9.09,1.13,1.13,0,0,1,1.21.08q8.88,5.33,17.77,0a1.15,1.15,0,0,1,1.21-.07,19.21,19.21,0,0,1,9.12,9.09,7.85,7.85,0,0,1,.5,5.54A5.7,5.7,0,0,1,48.57,58Z" />
        <path d="M44.65,24.47a13.81,13.81,0,0,1-2.43,8.75,12.6,12.6,0,0,1-22.75-6.13,20.45,20.45,0,0,1,.6-8.38,12.6,12.6,0,0,1,24.47,2.92A23.34,23.34,0,0,1,44.65,24.47Z" />
      </>
    )
  }
}

class RestaurantSVG extends React.Component {
  render() {
    return (
      <>
        <path d="M45,58l-11-12.68c-2-2.34-4.14-4.63-6.09-7a9.36,9.36,0,0,0-4.82-3c-1.09-.34-2.22-.54-3.32-.8a10.75,10.75,0,0,1-6.64-4.6A71,71,0,0,1,6.73,17c-.39-1-.82-1.9-1.23-2.85V11.62c1.64-2.74,3.73-2.85,5.84-.42Q20.6,21.93,29.91,32.58t18.47,21.2c.62.71.58,1.15-.16,1.74A19.71,19.71,0,0,0,45.46,58Z" />
        <path d="M58.5,16.8c-2.62,3.24-5.34,6.4-8.22,9.43a7.77,7.77,0,0,1-7.08,2.32,4.82,4.82,0,0,0-4.48,1.55c-1.17,1.27-2.32,2.56-3.41,3.9-.53.64-.89.57-1.36,0-.72-.89-1.46-1.75-2.23-2.6a.8.8,0,0,1,0-1.25c.9-1,1.75-2,2.65-3a5.11,5.11,0,0,0,1.35-4.76,10.85,10.85,0,0,1-.4-4.09,9.25,9.25,0,0,1,2.28-4.46c2.22-2.64,4.5-5.23,6.76-7.82a12.45,12.45,0,0,1,1.08-1h1.45c1.83,1.13,2,2.32.66,4l-.48.55L41.6,15.84a1,1,0,0,0,.06,1.61,1.06,1.06,0,0,0,1.64-.08c.19-.2.37-.41.55-.61,1.9-2.19,3.79-4.38,5.7-6.55a2.19,2.19,0,0,1,3.67.18c.66.92.54,1.83-.37,2.89Q49.93,16.63,47,20c-.35.41-.81.8-.58,1.45.34,1,1.34,1.14,2.1.29,2-2.25,3.93-4.53,5.92-6.78,1.45-1.64,2.74-1.56,4,.2Z" /><path d="M14.2,58c-1-.91-2.06-1.84-3.12-2.72-.49-.41-.58-.73-.11-1.26q6.6-7.55,13.15-15.15c.52-.6,1-.56,1.46,0,.74.82,1.43,1.69,2.18,2.49.5.52.41.91,0,1.41-2.37,2.69-4.71,5.4-7.07,8.11Q17.54,54.43,14.4,58Z" />
      </>
    )
  }
}
class BuildingSVG extends React.Component {
  render() {
    return (
      <>
        <path d="M27.75,59.22H15.33c0-.58-.05-1.16-.05-1.74q0-14.82,0-29.63,0-8.87,0-17.75c0-1.72,0-1.72,1.76-1.72,1.93,0,3.85,0,5.78,0,.9,0,1.24-.35,1.2-1.23s0-1.88,0-2.83c0-.68.31-1,1-1H39.62c.69,0,1,.28,1,1,0,1,0,1.89,0,2.83s.3,1.25,1.21,1.23c1.85-.05,3.7,0,5.55,0s2,0,2,1.94V57.8c0,.48,0,1-.06,1.42H36.9c0-2.65-.07-5.29-.06-7.93,0-.84-.29-1.18-1.14-1.16-2.18,0-4.35,0-6.53,0-1.05,0-1.38.34-1.37,1.38C27.84,54.07,27.77,56.65,27.75,59.22ZM23.12,17.4h0c0-.61,0-1.23,0-1.85s-.24-1-.89-1H20.59a.72.72,0,0,0-.83.79c0,1.34,0,2.68,0,4,0,.49.3.67.76.74,2.3.33,2.59.09,2.59-2.2Zm3.83,0c0,3,0,3,2.45,2.78.63-.05.93-.31.92-.94v-3.7a.81.81,0,0,0-.71-.92c-2.15-.43-2.66,0-2.66,2.13Zm10.75,0c0-3-.06-3-2.46-2.77h-.11a.74.74,0,0,0-.79.83c0,1.31,0,2.62,0,3.92a.75.75,0,0,0,.72.8c2.2.39,2.64.05,2.64-2.14Zm7.21,0c0-3,0-3-2.43-2.8h-.11a.73.73,0,0,0-.82.8c0,1.3,0,2.61,0,3.91,0,.53.25.77.78.84,2.31.32,2.58.1,2.58-2.21Zm-21.79,12v-.65c0-2.29-.14-2.4-2.4-2.21-.68.06-1,.35-1,1,0,.95,0,1.89,0,2.83,0,1.75,0,1.75,1.74,1.75s1.62,0,1.63-1.65Zm7.21,0c0-3-.1-3.13-2.46-2.89-.62.07-.92.33-.92.95,0,1.23,0,2.46,0,3.69,0,.69.31.93,1,1,2.39.12,2.39.14,2.39-2.29Zm7.37-.07c0-3-.05-3-2.4-2.82-.68.06-1,.35-1,1,0,1,0,1.95,0,2.92,0,1.65,0,1.65,1.61,1.66,1.77,0,1.77,0,1.77-1.81Zm7.21,0c0-3-.08-3.07-2.43-2.82h-.11c-.52,0-.82.28-.82.81q0,2,0,3.93c0,.52.25.77.78.84,2.33.3,2.58.1,2.58-2.22ZM23.12,41.15c-.06-3,.54-2.79-2.45-2.78-.64,0-.93.31-.92.94,0,1.23,0,2.47,0,3.7a.81.81,0,0,0,.6.91c1.89.59,2.77-.05,2.77-2Zm3.83,0v.65c0,2.25.27,2.47,2.5,2.18.56-.07.87-.32.87-.89,0-1.3,0-2.6,0-3.9,0-.52-.3-.74-.81-.8-2.36-.28-2.55-.12-2.55,2.22Zm10.75.08c-.06-3.05.58-2.85-2.46-2.84-.64,0-.91.32-.91.94,0,1,0,2.09,0,3.14,0,1.43.1,1.51,1.56,1.52,1.8,0,1.82,0,1.82-1.78Zm7.21,0c-.07-3.08.56-2.88-2.47-2.87-.65,0-.91.33-.9,1,0,1,0,2,0,2.94,0,1.67,0,1.71,1.7,1.71s1.66,0,1.67-1.64Z" />
      </>
    )
  }
}
class ShopSVG extends React.Component {
  render() {
    return (
      <>
        <path d="M58.5,26.61c-2.55,0-5.1,0-7.65,0-.82,0-1.24-.23-1.37-1.13-.62-4.22-1.33-8.44-2-12.66-.2-1.28-.13-1.33,1.21-1.33,1.72,0,3.45,0,5.17,0a1.33,1.33,0,0,1,1.56,1.22c.87,3.91,1.8,7.8,2.71,11.69.1.4.24.79.35,1.19Z" />
        <path d="M32.07,29.05H53.81c1.37,0,1.4,0,1.4,1.39q0,10.93,0,21.85c0,1.35-.08,1.44-1.38,1.45H47.31c-1.36,0-1.43-.08-1.43-1.5,0-3.86,0-7.73,0-11.59,0-1.36,0-1.38-1.4-1.38-3,0-6.08,0-9.11,0-.93,0-1.27.25-1.26,1.23,0,4,0,8,0,12,0,.95-.31,1.26-1.25,1.26-7.59,0-15.18,0-22.78,0-1.24,0-1.33-.1-1.33-1.38q0-11,0-22c0-1.29.05-1.34,1.33-1.35ZM21.64,46.23c.86,0,1.72,0,2.58,0,.68,0,.91-.27.9-.93,0-1.72,0-3.44,0-5.17,0-.6-.23-.88-.85-.87H19c-.62,0-.85.28-.85.88,0,1.76,0,3.51,0,5.27,0,.56.23.82.81.81C19.84,46.21,20.74,46.23,21.64,46.23Z" /><path d="M9.82,26.6H6.31c-.66,0-.94-.25-.77-1Q7.12,19,8.65,12.37a1,1,0,0,1,1.08-.87h6c.66,0,.92.26.81.95-.72,4.45-1.41,8.9-2.1,13.36-.09.62-.46.79-1,.79Z" /><path d="M29.11,15.75c0,3.17,0,6.34,0,9.51,0,1-.37,1.4-1.4,1.36-2-.06-4.07,0-6.1,0-1.22,0-1.36-.11-1.24-1.29.35-3.6.73-7.19,1.11-10.79.07-.72.15-1.43.22-2.15a.84.84,0,0,1,.93-.87h6c.56,0,.78.23.75.83-.05,1.13,0,2.27,0,3.41Z" /><path d="M39.34,26.6c-1.14,0-2.27,0-3.41,0-.67,0-1-.26-1-1q-.14-6.72-.29-13.43c0-.52.23-.69.67-.7,2.07,0,4.14,0,6.21,0,.93,0,.8.82.87,1.39.24,2,.42,4,.62,5.95s.45,4.25.64,6.37c.11,1.26,0,1.36-1.27,1.37h-3Z" /><path d="M38.85,47.16c0,.65,0,1.31,0,2a1.16,1.16,0,0,1-2.32,0q-.06-1.86,0-3.72a1.07,1.07,0,0,1,1.18-1.15,1.1,1.1,0,0,1,1.15,1.19c0,.59,0,1.17,0,1.76Z" />
      </>
    )
  }
}

class DeviceSVG extends React.Component {
  render() {
    return (
      <>
        {/* viewboxのスケールの違いでfontawesomeのd属性の値がそのまま使用できなかったためtransformで調整 */}
        <g transform="translate(7, 2) scale(0.125)">
          <path d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM224 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM304 64H80V384H304V64z" />
        </g>
      </>
    )
  }
}

class CricleSVG extends React.Component<{ size: number }> {
  render() {
    return (
      <circle cx="32" cy="32" r="20" />
    )
  }
}
