import { observer } from "mobx-react";
import React from "react";
import Wkt from "wicket";
import Location from "../interfaces/Location";
import SpotRemark from "../interfaces/SpotRemark";
import { getCenterFrom } from "../utils/GeographyUtils";
import SpotRemarkMapAreaPicker from "./MapAreaPicker/SpotRemarkMapAreaPicker";

declare var gon: any;

interface Props {
  spotRemark: SpotRemark;
  locations?: Location[];
  editable?: boolean;
}

interface State {
  areaWkt: string;
}

class SpotRemarkAreaMapEditor extends React.Component<Props, State> {
  wkt: any;

  constructor(props: Props) {
    super(props);

    const { spotRemark } = props;
    this.state = {
      areaWkt: spotRemark.within_area_wkt,
    };
    this.wkt = new Wkt.Wkt();
  }

  // ポリゴン追加を確定させた時
  // formのvalueに追加する
  updateByWkt = async (targetArea: string) => {
    const isValid = this.validateWKT(targetArea);
    if (!isValid) {
      console.warn("[updateByWkt] Warn: Invalid area:", targetArea);
      return;
    }

    $("#spot_remark_within_area_wkt").val(targetArea);
    $("#spot_remark_within_area").val(targetArea + ",4326");

    this.setState({
      areaWkt: targetArea,
    });
  };

  validateWKT = (areaWkt: string) => {
    try {
      this.wkt.read(areaWkt);
    } catch (error) {
      return false;
    }

    return true;
  };

  render() {
    const { spotRemark, locations, editable = false } = this.props;
    const { areaWkt } = this.state;

    const areaCenter = getCenterFrom(spotRemark.within_area_wkt);
    const center = areaCenter ?? {
      lat: +spotRemark.center_lat,
      lng: +spotRemark.center_lng,
    };

    return (
      <div style={{ width: "100%", height: 720 }}>
        <SpotRemarkMapAreaPicker
          gmapsApiKey={gon.google_api_key}
          center={center}
          wkt={areaWkt}
          notNull={true}
          editable={editable}
          spot={spotRemark}
          locationsForHeatMap={locations}
          onUpdateByWkt={this.updateByWkt}
        />
      </div>
    );
  }
}

export default observer(SpotRemarkAreaMapEditor);
