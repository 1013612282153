import React from "react";
import type { AxiosResponse } from "axios";
import { observer } from "mobx-react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @ts-ignore
import Wkt from "wicket";
import DeliveryArea from "../interfaces/DeliveryArea";
import DeliveryAreaMapWithOtherAreas from "./DeliveryAreaMapWithOtherAreas";

interface Props {
  deliveryArea: DeliveryArea;
  updateDeliveryArea: (deliveryAreaWkt: string) => Promise<AxiosResponse<any>>;
  deleteDeliveryArea: () => Promise<AxiosResponse<any>>;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  deliveryAreaWkt: string;
  isInvalid: boolean;
}

class DeliveryAreaMapWithInputWkt extends React.Component<Props, State> {
  wkt: any = null;

  alertMessage: {
    type: "error" | "alert" | "notice" | "success";
    text: string;
  } | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      deliveryAreaWkt: props.deliveryArea.area_wkt,
      isInvalid: false,
    };
    this.wkt = new Wkt.Wkt();
  }

  componentDidMount() {}

  validateWktText = (wktText: string) => {
    try {
      this.wkt.read(wktText);
    } catch (error) {
      return false;
    }
    return true;
  };

  onChange = (event: any) => {
    const isValid = this.validateWktText(event.target.value);
    this.setState({
      deliveryAreaWkt: event.target.value,
      isInvalid: !isValid,
    });
  };

  update = async () => {
    let options = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
    };
    try {
      const res = await this.props.updateDeliveryArea(
        this.state.deliveryAreaWkt
      );
      toast.success("更新しました。", options);
      return res;
    } catch (e) {
      toast.error("更新に失敗しました。", options);
    }
  };

  destroy = async () => {
    let options = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
    };
    try {
      const res = await this.props.deleteDeliveryArea();
      this.setState({ deliveryAreaWkt: "" });
      toast.success("削除しました。", options);
      return res;
    } catch (e) {
      toast.error("削除に失敗しました。", options);
    }
  };

  render() {
    const { deliveryArea } = this.props;
    return (
      <div>
        <div className="border-left-info pl-4">
          <div className="form-group flex">
            <label>範囲指定（wkt形式）</label>
            <div className="flex flex-column" style={{ width: "100%" }}>
              <textarea
                className="form-control"
                value={this.state.deliveryAreaWkt}
                onChange={this.onChange}
                rows={3}
              />
              {this.state.isInvalid && (
                <p className="mt-2 mb-0" style={{ color: "red", fontSize: 13 }}>
                  不正な形式です。
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-primary mb-3"
            onClick={async () => await this.update()}
            disabled={this.state.isInvalid}
          >
            更新
          </button>
          <button
            className="btn btn-danger mb-3 ml-4"
            onClick={async () => await this.destroy()}
            disabled={this.state.isInvalid}
          >
            削除
          </button>
        </div>
        <DeliveryAreaMapWithOtherAreas
          centerLat={deliveryArea.lat}
          centerLng={deliveryArea.lng}
          deliveryAreaWkt={this.state.deliveryAreaWkt}
          onClickOtherArea={async () => {}}
        ></DeliveryAreaMapWithOtherAreas>
        <ToastContainer />
      </div>
    );
  }
}

export default observer(DeliveryAreaMapWithInputWkt);
