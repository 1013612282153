import I18n from './i18n';
I18n.translations || (I18n.translations = {});
I18n.translations["af"] = I18n.extend((I18n.translations["af"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ar"] = I18n.extend((I18n.translations["ar"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["az"] = I18n.extend((I18n.translations["az"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["be"] = I18n.extend((I18n.translations["be"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["bg"] = I18n.extend((I18n.translations["bg"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["bn"] = I18n.extend((I18n.translations["bn"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["bs"] = I18n.extend((I18n.translations["bs"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ca"] = I18n.extend((I18n.translations["ca"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["cs"] = I18n.extend((I18n.translations["cs"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["da"] = I18n.extend((I18n.translations["da"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["de"] = I18n.extend((I18n.translations["de"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["de-CH"] = I18n.extend((I18n.translations["de-CH"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["el"] = I18n.extend((I18n.translations["el"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["en-GB"] = I18n.extend((I18n.translations["en-GB"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["es"] = I18n.extend((I18n.translations["es"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["es-CO"] = I18n.extend((I18n.translations["es-CO"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["es-MX"] = I18n.extend((I18n.translations["es-MX"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["et"] = I18n.extend((I18n.translations["et"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["fa"] = I18n.extend((I18n.translations["fa"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["fi"] = I18n.extend((I18n.translations["fi"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["fr"] = I18n.extend((I18n.translations["fr"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["fr-CA"] = I18n.extend((I18n.translations["fr-CA"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ha"] = I18n.extend((I18n.translations["ha"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["he"] = I18n.extend((I18n.translations["he"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["hi"] = I18n.extend((I18n.translations["hi"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["hr"] = I18n.extend((I18n.translations["hr"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["hu"] = I18n.extend((I18n.translations["hu"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["id"] = I18n.extend((I18n.translations["id"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ig"] = I18n.extend((I18n.translations["ig"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["is"] = I18n.extend((I18n.translations["is"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["it"] = I18n.extend((I18n.translations["it"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ja"] = I18n.extend((I18n.translations["ja"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ka"] = I18n.extend((I18n.translations["ka"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["km"] = I18n.extend((I18n.translations["km"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ko"] = I18n.extend((I18n.translations["ko"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["lo-LA"] = I18n.extend((I18n.translations["lo-LA"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["lt"] = I18n.extend((I18n.translations["lt"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["lv"] = I18n.extend((I18n.translations["lv"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["mk"] = I18n.extend((I18n.translations["mk"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ms"] = I18n.extend((I18n.translations["ms"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["nb"] = I18n.extend((I18n.translations["nb"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["nl"] = I18n.extend((I18n.translations["nl"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["nn-NO"] = I18n.extend((I18n.translations["nn-NO"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["no"] = I18n.extend((I18n.translations["no"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["pap-AW"] = I18n.extend((I18n.translations["pap-AW"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["pap-CW"] = I18n.extend((I18n.translations["pap-CW"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["pl"] = I18n.extend((I18n.translations["pl"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["pt"] = I18n.extend((I18n.translations["pt"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "changed_delivery_time": "配達希望日時変更有り",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["pt-BR"] = I18n.extend((I18n.translations["pt-BR"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ro"] = I18n.extend((I18n.translations["ro"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ru"] = I18n.extend((I18n.translations["ru"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["si"] = I18n.extend((I18n.translations["si"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["sk"] = I18n.extend((I18n.translations["sk"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["sl"] = I18n.extend((I18n.translations["sl"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["sq"] = I18n.extend((I18n.translations["sq"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["sr"] = I18n.extend((I18n.translations["sr"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["sr-RS"] = I18n.extend((I18n.translations["sr-RS"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["sv"] = I18n.extend((I18n.translations["sv"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["th"] = I18n.extend((I18n.translations["th"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["tl"] = I18n.extend((I18n.translations["tl"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["tr"] = I18n.extend((I18n.translations["tr"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["uk"] = I18n.extend((I18n.translations["uk"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["ur"] = I18n.extend((I18n.translations["ur"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["vi"] = I18n.extend((I18n.translations["vi"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["yo"] = I18n.extend((I18n.translations["yo"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["zh-CN"] = I18n.extend((I18n.translations["zh-CN"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["zh-HK"] = I18n.extend((I18n.translations["zh-HK"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["zh-TW"] = I18n.extend((I18n.translations["zh-TW"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
I18n.translations["zh-YUE"] = I18n.extend((I18n.translations["zh-YUE"] || {}), {
  "activemodel": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    }
  },
  "activerecord": {
    "attributes": {
      "address": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "assign_config": {
        "angle_condition_01": "配達先判定角度1",
        "angle_condition_02": "配達先判定角度2",
        "created_at": "作成日時",
        "id": "ID",
        "updated_at": "更新日時"
      },
      "carry_staff_avoid_auto_assign_time": {
        "avoid_auto_assign_end_at": "終了時刻",
        "avoid_auto_assign_start_at": "開始時刻"
      },
      "deaas_template": {
        "delivery_area": "範囲指定",
        "identification_name": "プロジェクト識別子",
        "lat": "緯度",
        "lng": "経度",
        "project_name": "プロジェクト名"
      },
      "form_cognito_password_update": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード"
      },
      "form_cognito_sign_up": {
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "phone_number": "電話番号",
        "token": "配布フォーム",
        "username": "ユーザーID"
      },
      "h3_min_pickup_minutes_setting": {
        "from_time": "開始時刻",
        "min_pickup_minutes": "最低到着分数",
        "to_tima": "終了時刻"
      },
      "ip_whitelist": {
        "ip_address": "IPアドレス",
        "organization": "組織"
      },
      "master_tag": {
        "name": "タグ名"
      },
      "office": {
        "address1": "番地",
        "city": "市区町村",
        "lat": "緯度",
        "lng": "経度",
        "name": "名称",
        "state": "都道府県",
        "zipcode": "郵便番号"
      },
      "request": {
        "cancel_comment": "キャンセル時記入欄",
        "redelivery_comment": "再配達時記入欄",
        "refund_comment": "返金時時記入欄",
        "return_reason": "持ち戻りの理由"
      },
      "request_cassapp_spot_report": {
        "spot_type": "スポットタイプ"
      },
      "service_area": {
        "lat": "緯度",
        "lng": "経度",
        "name": "名称"
      },
      "service_area_suspension": {
        "end_time": "終了時間",
        "start_time": "開始時間",
        "suspension_date": "停止日"
      },
      "simple_deaas_user": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "spot_remark_additional_memo": {
        "keyword": "キーワード",
        "memo": "メモ"
      },
      "user": {
        "confirmation_sent_at": "パスワード確認送信時刻",
        "confirmation_token": "パスワード確認用トークン",
        "confirmed_at": "パスワード確認時刻",
        "created_at": "作成日",
        "current_password": "現在のパスワード",
        "current_sign_in_at": "現在のログイン時刻",
        "current_sign_in_ip": "現在のログインIPアドレス",
        "email": "メールアドレス",
        "encrypted_password": "暗号化パスワード",
        "failed_attempts": "失敗したログイン試行回数",
        "last_sign_in_at": "最終ログイン時刻",
        "last_sign_in_ip": "最終ログインIPアドレス",
        "locked_at": "ロック時刻",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "確認用パスワード",
        "remember_created_at": "ログイン記憶時刻",
        "remember_me": "ログインを記憶",
        "reset_password_sent_at": "パスワードリセット送信時刻",
        "reset_password_token": "パスワードリセット用トークン",
        "sign_in_count": "ログイン回数",
        "unconfirmed_email": "未確認Eメール",
        "unlock_token": "ロック解除用トークン",
        "updated_at": "更新日",
        "user_offices": "担当拠点",
        "user_subscribed_offices": "購読拠点",
        "user_vendor_companies": "担当店舗会社"
      },
      "user_subscribed_offices": {
        "office": "購読拠点",
        "user": "購読拠点"
      },
      "vendor_company": {
        "company_cd": "会社CD",
        "name": "名前",
        "sender_radius": "半径",
        "simple_deaas_users": "SimpleDeaasユーザー"
      },
      "vendor_company/simple_deaas_users": {
        "email": "メールアドレス",
        "password": "パスワード",
        "password_confirmation": "パスワード確認",
        "uid_name": "登録名"
      },
      "vendor_company_config": {
        "auto_call_from": "自動発信の発信元電話番号"
      },
      "vendor_user": {
        "created_at": "作成日時",
        "email": "メールアドレス",
        "id": "ID",
        "name": "ユーザー名",
        "password": "パスワード",
        "password_confirmation": "パスワード（確認）",
        "updated_at": "更新日時",
        "vendor_user_companies": "担当店舗"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "バリデーションに失敗しました: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "%{record}が存在しているので削除できません",
          "has_one": "%{record}が存在しているので削除できません"
        }
      },
      "models": {
        "request": {
          "status_return_and_return_reason_blank": "ステータスが「持ち戻り」の場合、持ち戻りの理由は必須です"
        }
      }
    },
    "models": {
      "assign_config": "配達アサイン設定",
      "user": "ユーザ"
    }
  },
  "common": {
    "created_at": "作成日時",
    "id": "ID",
    "name": "名前",
    "rule": "ルール",
    "updated_at": "更新日時"
  },
  "date": {
    "abbr_day_names": [
      "日",
      "月",
      "火",
      "水",
      "木",
      "金",
      "土"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "day_names": [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    "formats": {
      "default": "%Y/%m/%d",
      "long": "%Y年%m月%d日(%a)",
      "md_jp": "%m月%d日(%a)",
      "short": "%m/%d"
    },
    "month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "約1時間",
        "other": "約%{count}時間"
      },
      "about_x_months": {
        "one": "約1ヶ月",
        "other": "約%{count}ヶ月"
      },
      "about_x_years": {
        "one": "約1年",
        "other": "約%{count}年"
      },
      "almost_x_years": {
        "one": "1年弱",
        "other": "%{count}年弱"
      },
      "half_a_minute": "30秒前後",
      "less_than_x_minutes": {
        "one": "1分以内",
        "other": "%{count}分未満"
      },
      "less_than_x_seconds": {
        "one": "1秒以内",
        "other": "%{count}秒未満"
      },
      "over_x_years": {
        "one": "1年以上",
        "other": "%{count}年以上"
      },
      "x_days": {
        "one": "1日",
        "other": "%{count}日"
      },
      "x_minutes": {
        "one": "1分",
        "other": "%{count}分"
      },
      "x_months": {
        "one": "1ヶ月",
        "other": "%{count}ヶ月"
      },
      "x_seconds": {
        "one": "1秒",
        "other": "%{count}秒"
      },
      "x_years": {
        "one": "1年",
        "other": "%{count}年"
      }
    },
    "prompts": {
      "day": "日",
      "hour": "時",
      "minute": "分",
      "month": "月",
      "second": "秒",
      "year": "年"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "メールアドレスが確認できました。",
      "new": {
        "resend_confirmation_instructions": "アカウント確認メール再送"
      },
      "send_instructions": "アカウントの有効化について数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、本人確認用のメールが数分以内に送信されます。"
    },
    "failure": {
      "already_authenticated": "すでにログインしています。",
      "inactive": "アカウントが有効化されていません。メールに記載された手順にしたがって、アカウントを有効化してください。",
      "invalid": "%{authentication_keys}またはパスワードが違います。",
      "last_attempt": "もう一回誤るとアカウントがロックされます。",
      "locked": "アカウントはロックされています。",
      "not_found_in_database": "%{authentication_keys}またはパスワードが違います。",
      "timeout": "セッションがタイムアウトしました。もう一度ログインしてください。",
      "unauthenticated": "ログインもしくはアカウント登録してください。",
      "unconfirmed": "メールアドレスの本人確認が必要です。"
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "アカウント確認",
        "greeting": "ようこそ、%{recipient}さん!",
        "instruction": "次のリンクでメールアドレスの確認が完了します:",
        "subject": "メールアドレス確認メール"
      },
      "email_changed": {
        "greeting": "こんにちは、%{recipient}様。",
        "message": "メールアドレスの（%{email}）変更が完了したため、メールを送信しています。",
        "message_unconfirmed": "メールアドレスが（%{email}）変更されたため、メールを送信しています。",
        "subject": "メール変更完了"
      },
      "password_change": {
        "greeting": "%{recipient}様",
        "message": "パスワードが再設定されました。",
        "subject": "パスワードの変更について"
      },
      "reset_password_instructions": {
        "action": "パスワード変更",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "誰かがパスワードの再設定を希望しました。次のリンクでパスワードの再設定が出来ます。",
        "instruction_2": "あなたが希望したのではないのなら、このメールは無視してください。",
        "instruction_3": "上のリンクにアクセスして新しいパスワードを設定するまで、パスワードは変更されません。",
        "subject": "パスワードの再設定について"
      },
      "unlock_instructions": {
        "action": "アカウントのロック解除",
        "greeting": "こんにちは、%{recipient}さん!",
        "instruction": "アカウントのロックを解除するには下のリンクをクリックしてください。",
        "message": "ログイン失敗が繰り返されたため、アカウントはロックされています。",
        "subject": "アカウントのロック解除について"
      }
    },
    "omniauth_callbacks": {
      "failure": "%{kind} アカウントによる認証に失敗しました。理由：（%{reason}）",
      "success": "%{kind} アカウントによる認証に成功しました。"
    },
    "passwords": {
      "edit": {
        "change_my_password": "パスワードを変更する",
        "change_your_password": "パスワードを変更",
        "confirm_new_password": "確認用新しいパスワード",
        "new_password": "新しいパスワード"
      },
      "new": {
        "forgot_your_password": "パスワードを忘れましたか?",
        "send_me_reset_password_instructions": "パスワードの再設定方法を送信する"
      },
      "no_token": "このページにはアクセスできません。パスワード再設定メールのリンクからアクセスされた場合には、URL をご確認ください。",
      "send_instructions": "パスワードの再設定について数分以内にメールでご連絡いたします。",
      "send_paranoid_instructions": "メールアドレスが登録済みの場合、パスワード再設定用のメールが数分以内に送信されます。",
      "updated": "パスワードが正しく変更されました。",
      "updated_not_active": "パスワードが正しく変更されました。"
    },
    "registrations": {
      "destroyed": "アカウントを削除しました。またのご利用をお待ちしております。",
      "edit": {
        "are_you_sure": "本当に良いですか?",
        "cancel_my_account": "アカウント削除",
        "currently_waiting_confirmation_for_email": "%{email} の確認待ち",
        "leave_blank_if_you_don_t_want_to_change_it": "空欄のままなら変更しません",
        "title": "%{resource}編集",
        "unhappy": "気に入りません",
        "update": "更新",
        "we_need_your_current_password_to_confirm_your_changes": "変更を反映するには現在のパスワードを入力してください"
      },
      "new": {
        "sign_up": "アカウント登録"
      },
      "signed_up": "アカウント登録が完了しました。",
      "signed_up_but_inactive": "ログインするためには、アカウントを有効化してください。",
      "signed_up_but_locked": "アカウントがロックされているためログインできません。",
      "signed_up_but_unconfirmed": "本人確認用のメールを送信しました。メール内のリンクからアカウントを有効化させてください。",
      "update_needs_confirmation": "アカウント情報を変更しました。変更されたメールアドレスの本人確認のため、本人確認用メールより確認処理をおこなってください。",
      "updated": "アカウント情報を変更しました。",
      "updated_but_not_signed_in": "あなたのアカウントは正常に更新されましたが、パスワードが変更されたため、再度ログインしてください。"
    },
    "sessions": {
      "already_signed_out": "既にログアウト済みです。",
      "new": {
        "sign_in": "ログイン"
      },
      "signed_in": "ログインしました。",
      "signed_out": "ログアウトしました。"
    },
    "shared": {
      "links": {
        "back": "戻る",
        "didn_t_receive_confirmation_instructions": "アカウント確認のメールを受け取っていませんか?",
        "didn_t_receive_unlock_instructions": "アカウントの凍結解除方法のメールを受け取っていませんか?",
        "forgot_your_password": "パスワードを忘れましたか?",
        "sign_in": "ログイン",
        "sign_in_with_provider": "%{provider}でログイン",
        "sign_up": "アカウント登録"
      },
      "minimum_password_length": "（%{count}字以上）"
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "アカウントの凍結解除方法を再送する"
      },
      "send_instructions": "アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "send_paranoid_instructions": "アカウントが見つかった場合、アカウントのロック解除方法を数分以内にメールでご連絡します。",
      "unlocked": "アカウントをロック解除しました。"
    }
  },
  "enums": {
    "anycarry_no_delivery_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "carry_staff": {
      "staff_type": {
        "anycarry": "通常",
        "ato": "ato",
        "disabled": "無効",
        "genie": "GENie",
        "gigworker": "ギグワーカー",
        "invalid_state": "未検証",
        "route": "ルート配達",
        "uber_direct": "UberDirect",
        "vendor": "ベンダー",
        "wolt": "Wolt",
        "menu": "Menu"
      },
      "work_type": {
        "amazon": "Amazon",
        "anycarry": "エニキャリ",
        "demaecan": "出前館",
        "rest": "休憩中",
        "wolt": "Wolt"
      }
    },
    "cas_call_history": {
      "call_type": {
        "from_receiver": "購入者から配達員へ",
        "from_sender": "店舗から配達員へ",
        "others": "その他",
        "test": "確認用発信",
        "to_receiver": "配達員から購入者へ",
        "to_sender": "配達員から店舗へ"
      }
    },
    "deaas_request": {
      "status": {
        "assigned": "アサイン済",
        "assigning": "アサイン処理中",
        "cancelled": "キャンセル済",
        "cancelling": "キャンセル処理中",
        "delivered": "配達完了",
        "pickedup": "店舗受取完了",
        "waiting": "待機中"
      }
    },
    "deaas_request_webhook": {
      "callback_type": {
        "assigned": "アサイン完了",
        "cancelled": "キャンセル完了",
        "delivered": "配達完了",
        "get_closer_to_200": "配達先の200mへ接近",
        "pickedup": "店舗受取完了"
      },
      "status": {
        "retry": "リトライ",
        "sending": "送信中",
        "sent": "完了",
        "stop": "送信ストップ",
        "url_none": "URL指定無"
      }
    },
    "deaas_template": {
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      }
    },
    "delivery_offer": {
      "offer_status": {
        "cancel": "キャンセル",
        "failed": "失敗",
        "offering": "募集中",
        "success": "成功"
      }
    },
    "delivery_partner_request": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      },
      "status": {
        "assigned": "アサイン成功",
        "cannot_create_offer": "オファー処理が実行できなかった",
        "cannot_create_quote": "見積もりを作成できなかった",
        "cannot_delivery": "配達失敗",
        "delivered": "配達完了",
        "delivery_anycarry_cancelled": "アサイン後にエニキャリがキャンセル",
        "delivery_partner_cancelled": "アサイン後にパートナー側かがキャンセル",
        "initialized": "初期状態",
        "no_use_quote": "この配達はパートナーリクエストをする条件を満たしていたが、他の条件で見積もりをしなかった",
        "not_allow": "この配達はパートナーリクエストをする条件を満たしていない",
        "offer_waiting": "オファー処理待ち",
        "offering": "オファー中",
        "offering_anycarry_cancelled": "アサイン中にエニキャリがキャンセル",
        "offering_partner_cancelled": "アサイン中にパートナーがキャンセル",
        "request_cancel": "キャンセル"
      }
    },
    "delivery_partner_request_history": {
      "delivery_partner_status": {
        "wolt_customer_no_show": "顧客不在",
        "wolt_delivered": "配達完了",
        "wolt_dropoff_eta_updated": "配達完了予想時間更新",
        "wolt_location_updated": "位置情報更新",
        "wolt_picked_up": "店舗受取済",
        "wolt_pickup_eta_updated": "店舗到着予想時間更新",
        "wolt_pickup_started": "アサイン完了",
        "wolt_received": "依頼済",
        "wolt_rejected": "依頼却下"
      }
    },
    "entire_service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "file_export": {
      "data_type": {
        "bundle_info": "バンドル配達一覧",
        "deliver_info": "配達距離と配達時間の一覧",
        "request_info": "配達一覧"
      },
      "status": {
        "enqueued": "待機中",
        "failed": "異常終了",
        "finished": "完了",
        "processing": "ファイル作成中"
      }
    },
    "h3_capacity_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "office": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    },
    "request": {
      "created_by_type": {
        "cscart": "cscart",
        "deaas": "DeaaS",
        "epw": "GEMS 広尾",
        "iwaki": "いわき",
        "subsc": "サブスク"
      },
      "delivery_type": {
        "delivery": "通常デリバリー",
        "exclud_takeout": "全配送",
        "robo": "ロボットデリバリー",
        "route": "ルート配送",
        "takeout": "テイクアウト"
      },
      "return_reason": {
        "access_issue": "アクセスの問題",
        "address_unknown": "住所不明",
        "after_hours": "営業時間外",
        "not_home": "ご不在",
        "relocation": "転居",
        "unsafe_location": "指定場所が安全ではない",
        "weather_delay": "悪天候のため配達が遅延"
      },
      "risk_level": {
        "high": "高",
        "low": "低",
        "middle": "中",
        "very_high": "最高",
        "very_low": ""
      },
      "status": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "onhold": "留め置き",
        "pickup": "店舗ピックアップ済",
        "return": "持ち戻り",
        "store_cancel": "（予備）",
        "unapproved": "店舗未承認",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "store_status": {
        "approved": "承認済み",
        "cancel": "キャンセル",
        "delivered": "配達完了",
        "denied": "注文却下",
        "failed": "注文失敗",
        "shipped": "出荷済み",
        "waiting_approve": "承認待ち",
        "waiting_check": "確認待ち",
        "waiting_settlement": "決済処理待ち"
      }
    },
    "request_alert": {
      "alert_type": {
        "address_inaccurate": "位置ズレ",
        "delay_delivery": "遅配",
        "early_delivery": "早配",
        "no_assign": "未アサイン",
        "no_assign_for_a_while": "一定時間未割当",
        "no_deliver": "未配達",
        "no_pick_up": "未ピック",
        "onhold": "留め置き",
        "return": "持ち戻り",
        "unconfirmed_for_a_while": "1分間未確認",
        "webhook_notify_failed": "通知失敗",
        "wolt_cancel_error": "Woltキャンセル失敗",
        "wolt_create_offer_anycarry_no_delivery_area_error": "Wolt配達範囲外",
        "uber_direct_cancel_error": "Uberキャンセル失敗",
        "uber_direct_create_offer_anycarry_no_delivery_area_error": "Uber配達範囲外",
        "menu_cancel_error": "Menuキャンセル失敗",
        "menu_create_offer_anycarry_no_delivery_area_error": "Menu配達範囲外"       
      }
    },
    "request_assign_change_log": {
      "change_from": {
        "adms": "ADMS",
        "deaas": "Deaas",
        "mobile": "Mobile",
        "system": "System"
      },
      "change_reason": {
        "about_to_end_staff": "近くにもうすぐ配達が終わりそうなスタッフがいる",
        "be_late_probably": "配達に遅れそうなので、別スタッフに切り替えた",
        "can_multi_pick": "別のスタッフにマルチピックしてもらうため",
        "can_not_deliver_by_accident": "体調不良や事故等によりアサインされていたスタッフが配達できなくなったため",
        "can_not_multi_pick": "マルチピックできるほどの距離にはないため",
        "disperse_tasks": "他スタッフとアサイン業務負荷を分散させるため",
        "is_free": "他に空いているスタッフがいる",
        "left_or_rest": "退勤・休憩",
        "more_faster_staff": "より効率的に運べるスタッフが配達時間に間に合いそうなため",
        "others": "その他",
        "out_of_delivery_range": "スタッフの担当範囲外のため",
        "over_delivery_distance": "スタッフの配達可能な距離を超えているため",
        "under_amazon": "Amazonの業務をおこなっているため",
        "under_demaekan": "出前館の業務をおこなっているため",
        "under_other_task": "その他別業務をおこなっていたため",
        "under_wolt": "Woltの業務をおこなっているため"
      }
    },
    "request_history": {
      "status_txt": {
        "cancel": "キャンセル",
        "checked": "確認済",
        "delivered": "配達済",
        "failed": "配達失敗",
        "pickup": "店舗ピックアップ済",
        "store_cancel": "店舗キャンセル",
        "uncheck": "未確認",
        "wait_store": "店舗処理待"
      },
      "update_from": {
        "adms": "アダムス",
        "casapp": "CASアプリ",
        "serverless": "サーバーレス"
      }
    },
    "service_area": {
      "area_type": {
        "anycarry": "エニキャリ",
        "wolt": "WOLT"
      },
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      },
      "status": {
        "accepting": "受付中",
        "stop": "一時停止中"
      },
      "within_type": {
        "use_area": "範囲指定",
        "use_point": "座標指定"
      }
    },
    "service_area_suspension": {
      "status": {
        "resumed": "再開済",
        "suspended": "停止中"
      },
      "suspension_date": "停止日"
    },
    "service_area_timeline": {
      "required_minutes_status": {
        "auto": "自動計算",
        "fixed": "手動更新"
      }
    },
    "third_party_delivery_task": {
      "status": {
        "checked": "確認済",
        "delivered": "配達済",
        "pickup": "店舗ピックアップ済"
      }
    },
    "vendor_company": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する",
        "unset": "設定しない"
      },
      "determine_type": {
        "use_area": "範囲",
        "use_distance": "距離"
      },
      "run_auto_assign": {
        "do": "する",
        "stop": "しない"
      },
      "shop_call_enabled": {
        "false": "利用しない",
        "true": "利用する"
      },
      "vendor_type": {
        "cscart": "cscart",
        "subsc": "サブスク"
      }
    },
    "vendor_company_config": {
      "cas_return_call_type": {
        "message": "自動音声を再生する",
        "to_cas": "配達員に転送する"
      }
    },
    "vendor_user": {
      "use_check": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_lock_mode": {
        "false": "利用しない",
        "true": "利用する"
      },
      "use_order_stop": {
        "false": "利用しない",
        "true": "利用する"
      }
    },
    "wolt_merchant_offer_area": {
      "enabled_status": {
        "disabled": "無効",
        "enabled": "有効"
      }
    }
  },
  "errors": {
    "format": "%{attribute}%{message}",
    "messages": {
      "accepted": "を受諾してください",
      "already_confirmed": "は既に登録済みです。ログインしてください。",
      "blank": "を入力してください",
      "confirmation": "と%{attribute}の入力が一致しません",
      "confirmation_period_expired": "の期限が切れました。%{period} までに確認する必要があります。 新しくリクエストしてください。",
      "empty": "を入力してください",
      "equal_to": "は%{count}にしてください",
      "even": "は偶数にしてください",
      "exclusion": "は予約されています",
      "expired": "の有効期限が切れました。新しくリクエストしてください。",
      "greater_than": "は%{count}より大きい値にしてください",
      "greater_than_or_equal_to": "は%{count}以上の値にしてください",
      "inclusion": "は一覧にありません",
      "invalid": "は不正な値です",
      "less_than": "は%{count}より小さい値にしてください",
      "less_than_or_equal_to": "は%{count}以下の値にしてください",
      "model_invalid": "バリデーションに失敗しました: %{errors}",
      "not_a_number": "は数値で入力してください",
      "not_an_integer": "は整数で入力してください",
      "not_found": "は見つかりませんでした。",
      "not_locked": "はロックされていません。",
      "not_saved": {
        "one": "エラーが発生したため %{resource} は保存されませんでした。",
        "other": "%{count} 件のエラーが発生したため %{resource} は保存されませんでした。"
      },
      "odd": "は奇数にしてください",
      "other_than": "は%{count}以外の値にしてください",
      "present": "は入力しないでください",
      "required": "を入力してください",
      "taken": "はすでに存在します",
      "too_long": "は%{count}文字以内で入力してください",
      "too_short": "は%{count}文字以上で入力してください",
      "wrong_length": "は%{count}文字で入力してください"
    },
    "template": {
      "body": "次の項目を確認してください",
      "header": {
        "one": "%{model}にエラーが発生しました",
        "other": "%{model}に%{count}個のエラーが発生しました"
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "選択してください"
    },
    "submit": {
      "create": "登録する",
      "submit": "保存する",
      "update": "更新する"
    }
  },
  "models": {
    "address": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "assign_config": {
      "angle_condition_01": "配達先判定角度1",
      "angle_condition_02": "配達先判定角度2",
      "created_at": "作成日時",
      "id": "ID",
      "updated_at": "更新日時"
    },
    "carry_staff_avoid_auto_assign_time": {
      "avoid_auto_assign_end_at": "終了時刻",
      "avoid_auto_assign_start_at": "開始時刻"
    },
    "deaas_template": {
      "delivery_area": "範囲指定",
      "identification_name": "プロジェクト識別子",
      "lat": "緯度",
      "lng": "経度",
      "project_name": "プロジェクト名"
    },
    "form_cognito_password_update": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード"
    },
    "form_cognito_sign_up": {
      "password": "パスワード",
      "password_confirmation": "確認用パスワード",
      "phone_number": "電話番号",
      "token": "配布フォーム",
      "username": "ユーザーID"
    },
    "h3_min_pickup_minutes_setting": {
      "from_time": "開始時刻",
      "min_pickup_minutes": "最低到着分数",
      "to_tima": "終了時刻"
    },
    "ip_whitelist": {
      "ip_address": "IPアドレス",
      "organization": "組織"
    },
    "master_tag": {
      "name": "タグ名"
    },
    "office": {
      "address1": "番地",
      "city": "市区町村",
      "lat": "緯度",
      "lng": "経度",
      "name": "名称",
      "state": "都道府県",
      "zipcode": "郵便番号"
    },
    "request": {
      "cancel_comment": "キャンセル時記入欄",
      "redelivery_comment": "再配達時記入欄",
      "refund_comment": "返金時時記入欄",
      "return_reason": "持ち戻りの理由"
    },
    "request_cassapp_spot_report": {
      "spot_type": "スポットタイプ"
    },
    "service_area": {
      "lat": "緯度",
      "lng": "経度",
      "name": "名称"
    },
    "simple_deaas_user": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "spot_remark_additional_memo": {
      "keyword": "キーワード",
      "memo": "メモ"
    },
    "user": {
      "user_offices": "担当拠点",
      "user_subscribed_offices": "購読拠点",
      "user_vendor_companies": "担当店舗会社"
    },
    "vendor_company": {
      "company_cd": "会社CD",
      "name": "名前",
      "sender_radius": "半径",
      "simple_deaas_users": "SimpleDeaasユーザー"
    },
    "vendor_company/simple_deaas_users": {
      "email": "メールアドレス",
      "password": "パスワード",
      "password_confirmation": "パスワード確認",
      "uid_name": "登録名"
    },
    "vendor_company_config": {
      "auto_call_from": "自動発信の発信元電話番号"
    },
    "vendor_user": {
      "created_at": "作成日時",
      "email": "メールアドレス",
      "id": "ID",
      "name": "ユーザー名",
      "password": "パスワード",
      "password_confirmation": "パスワード（確認）",
      "updated_at": "更新日時",
      "vendor_user_companies": "担当店舗"
    }
  },
  "navigation": {
    "action": {
      "assign": "アサイン"
    },
    "cognito": {
      "errors": {
        "User does not exist": "ユーザーが存在しません"
      },
      "status": {
        "UNCONFIRMED": "未認証"
      }
    },
    "interaction": {
      "add": "追加",
      "bulk_edit": "一括編集",
      "create": "作成",
      "delete": "削除",
      "detail": "詳細",
      "download": "ダウンロード",
      "edit": "編集",
      "list": "一覧",
      "management": "管理",
      "overlook": "俯瞰",
      "search": "検索",
      "settings": "設定",
      "update": "更新"
    },
    "model": {
      "account_management": "アカウント管理",
      "activity": "アクティビティ",
      "address": "住所",
      "admin_user": "管理ユーザー",
      "anycarry_no_delivery_area": "エニキャリ配達範囲外",
      "app_form_setting": "アプリ配布フォーム",
      "app_link": "アプリ引換コード",
      "assign_config": "配達アサイン設定",
      "bulk_assign_area": "一括アサインエリア",
      "carry_staff": "配達スタッフ",
      "carry_staff_allowlist": "配達許可スタッフ",
      "carry_staff_avoid_auto_assign_time": "自動アサイン拒否時間",
      "carry_staff_delivery_sequnce": "配達順",
      "carry_staff_denylist": "配達禁止スタッフ",
      "carry_staff_schedule": "配達スタッフスケジュール",
      "cas_call_history": "配達員通話履歴",
      "cognito_user": "未認証アプリユーザー",
      "data_download": "データダウンロード",
      "deaas_config": "DEAAS API受付設定",
      "deaas_template": "DEAASテンプレート",
      "delivery_offer": "オファー",
      "entire_service_area_suspensions": "全エリア停止",
      "file_export": "エクスポート結果",
      "h3_capacity_timeline": "キャパシティ",
      "h3_index": "ヘックス",
      "h3_min_pickup_minutes_setting": "最低到着分数",
      "ip_whitelist": "IPホワイトリスト",
      "master_tag": "タグ",
      "master_tag_relation": "タグ設定",
      "master_tag_rule": "タグルール",
      "office": "拠点",
      "request": "依頼",
      "request_assign_change_log": "アサイン変更ログ",
      "request_assign_log": "アサインログ",
      "request_casapp_spot_report": "拠点報告",
      "route_delivery_request": "ルート配達",
      "schedule": "スケジュール",
      "service_area": "サービスエリア",
      "simple_deaas_user": "SimpleDeaasユーザー",
      "spot_remark_additional_memo": "スポット追加メモ",
      "spot_remarks": "スポット情報",
      "summary": "サマリー",
      "territory": "担当エリア",
      "third_party_delivery_task": "外部配達タスク",
      "twiml": "自動音声内容",
      "user_onesignal_setting": "通知購読管理",
      "vehicle_type": "車両種別",
      "vendor_app_access": "店舗アプリアクセス",
      "vendor_app_user": "店舗アプリユーザー",
      "vendor_call_history": "店舗発信履歴",
      "vendor_company": "店舗会社",
      "vendor_company_config": "店舗会社共通設定",
      "vendor_company_other_delivery_area": "その他の配達範囲",
      "wolt_merchant": "Wolt顧客設定",
      "wolt_merchant_offer_area": "エリア別オファー設定"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 0,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "円"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "十億",
          "million": "百万",
          "quadrillion": "千兆",
          "thousand": "千",
          "trillion": "兆",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n%u",
        "units": {
          "byte": "バイト",
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "ransack": {
    "all": "全て",
    "and": "と",
    "any": "いずれか",
    "asc": "昇順",
    "attribute": "属性",
    "combinator": "組み合わせ",
    "condition": "状態",
    "desc": "降順",
    "or": "あるいは",
    "predicate": "は以下である",
    "predicates": {
      "blank": "は空である",
      "cont": "は以下を含む",
      "cont_all": "は以下の全てを含む",
      "cont_any": "はいずれかを含む",
      "does_not_match": "は以下と合致していない",
      "does_not_match_all": "は以下の全てに合致していない",
      "does_not_match_any": "は以下のいずれかに合致していない",
      "end": "は以下で終わる",
      "end_all": "は以下の全てで終わる",
      "end_any": "は以下のいずれかで終わる",
      "eq": "は以下と等しい",
      "eq_all": "は以下の全てに等しい",
      "eq_any": "は以下のいずれかに等しい",
      "false": "偽",
      "gt": "は以下より大きい",
      "gt_all": "は以下の全てより大きい",
      "gt_any": "は以下のいずれかより大きい",
      "gteq": "は以下より大きいか等しい",
      "gteq_all": "は以下の全てより大きいか等しい",
      "gteq_any": "は以下のいずれかより大きいか等しい",
      "in": "は以下の範囲内である",
      "in_all": "は以下の全ての範囲内である",
      "in_any": "は以下のいずれかの範囲内である",
      "lt": "は以下よりも小さい",
      "lt_all": "は以下の全てよりも小さい",
      "lt_any": "は以下のいずれかより小さい",
      "lteq": "は以下より小さいか等しい",
      "lteq_all": "は以下の全てより小さいか等しい",
      "lteq_any": "は以下のいずれかより小さいか等しい",
      "matches": "は以下と合致している",
      "matches_all": "は以下の全てと合致している",
      "matches_any": "は以下のいずれかと合致している",
      "not_cont": "は含まない",
      "not_cont_all": "は以下の全てを含まない",
      "not_cont_any": "は以下のいずれかを含まない",
      "not_end": "は以下のどれでも終わらない",
      "not_end_all": "は以下の全てで終わらない",
      "not_end_any": "は以下のいずれかで終わらない",
      "not_eq": "は以下と等しくない",
      "not_eq_all": "は以下の全てと等しくない",
      "not_eq_any": "は以下のいずれかに等しくない",
      "not_in": "は以下の範囲内でない",
      "not_in_all": "は以下の全ての範囲内",
      "not_in_any": "は以下のいずれかの範囲内でない",
      "not_null": "は無効ではない",
      "not_start": "は以下で始まらない",
      "not_start_all": "は以下の全てで始まらない",
      "not_start_any": "は以下のいずれかで始まらない",
      "null": "無効",
      "present": "は存在する",
      "start": "は以下で始まる",
      "start_all": "は以下の全てで始まる",
      "start_any": "は以下のどれかで始まる",
      "true": "真"
    },
    "search": "検索",
    "sort": "分類",
    "value": "値"
  },
  "support": {
    "array": {
      "last_word_connector": "、",
      "two_words_connector": "、",
      "words_connector": "、"
    }
  },
  "time": {
    "am": "午前",
    "formats": {
      "date_only": "%Y/%m/%d",
      "default": "%Y年%m月%d日(%a) %H時%M分%S秒 %z",
      "long": "%Y/%m/%d %H:%M",
      "short": "%m/%d %H:%M",
      "time_only": "%H:%M"
    },
    "pm": "午後"
  },
  "views": {
    "pagination": {
      "truncate": "..."
    }
  }
});
