import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';


export interface TimeRange {
  name: string
  start: { hour: number, minute: number }
  end: { hour: number, minute: number }
}

interface Props {
  onChange: (range: TimeRange) => void
  timeRanges: TimeRange[]
  disabled?: boolean
}

export default function TimeRangeSlider(props: Props) {
  const {
    onChange,
    timeRanges,
    disabled,
  } = props;

  const [selectedRangeIndex, setSelectedRangeIndex] = useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(event.target.value);
    setSelectedRangeIndex(index);
    onChange(timeRanges[index]);
  }

  const handleClickMinus = () => {
    const newIndex = selectedRangeIndex - 1 >= 0 ? selectedRangeIndex - 1 : 0;
    setSelectedRangeIndex(newIndex);
    onChange(timeRanges[newIndex]);
  };

  const handleClickPlus = () => {
    const newIndex = selectedRangeIndex + 1 < timeRanges.length ?
      selectedRangeIndex + 1 : timeRanges.length - 1;
    setSelectedRangeIndex(newIndex);
    onChange(timeRanges[newIndex]);
  };


  return (
    <div>
      <RangeSlider
        disabled={disabled}
        min={0}
        max={timeRanges.length - 1}
        tooltip='off'
        value={selectedRangeIndex}
        onChange={handleChange}
      />
      <div className='d-flex'>
        <div>
          {timeRanges[selectedRangeIndex].name}
        </div>

        <div className='d-flex' style={{ marginLeft: 'auto' }}>
          <button className="btn btn-sm" onClick={handleClickMinus} disabled={disabled}>
            <i className='fas fa-minus'></i>
          </button>
          <button className="btn btn-sm" onClick={handleClickPlus} disabled={disabled}>
            <i className='fas fa-plus'></i>
          </button>
        </div>
      </div>
    </div>
  );
};
