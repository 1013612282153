import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import Colors from "../../constants/BootstrapColors";
import MapAttributes from "../../constants/MapAttributes";
import GoogleMap from "../../components/Common/GoogleMap";
import Polyline from "../../components/Polyline";
import ReceiverMarker from "../../components/ReceiverMarker";
import SenderMarker from "../../components/SenderMarker";
import WktMultiPolygon from "../../components/WktMultiPolygon";
import {
  createEdgePointPairs,
  loadSequences,
  mergeRequestsAndSequences,
} from "../../utils/CarryStaffRequestSequenceUtils";
import { calcCenter } from "../../utils/GeographyUtils";
import { convertRequestIntoForMap } from "../../utils/RequestEntityUtils";
import type {
  ExpandedRawRequest,
  RawCarryStaffWithSchedules,
  RawTerritory,
  RequestSequence,
} from "./Interfaces";
import TimeRangeSlider, { TimeRange } from "./TimeRangeSlider";

declare var gon: any;

interface Props {
  requests?: ExpandedRawRequest[];
  carryStaff?: RawCarryStaffWithSchedules;
  selectedDate: string;
  territories: RawTerritory[];
  isOpen: boolean;
  onRequestClose: () => void;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  center: { lat: number; lng: number };
  isSliderDisabled: boolean;
  selectedRequestId: number | null;
  targetTimeRange: TimeRange | undefined;
  targetSenderRequests: ExpandedRawRequest[];
  targetReceiverRequests: ExpandedRawRequest[];
  sequences: RequestSequence[];
  isLoading: boolean;
}

const timeRanges: TimeRange[] = [
  {
    name: "午前中",
    start: { hour: 8, minute: 0 },
    end: { hour: 12, minute: 0 },
  },
  {
    name: "12:00~14:00",
    start: { hour: 12, minute: 0 },
    end: { hour: 14, minute: 0 },
  },
  {
    name: "14:00~16:00",
    start: { hour: 14, minute: 0 },
    end: { hour: 16, minute: 0 },
  },
  {
    name: "16:00~18:00",
    start: { hour: 16, minute: 0 },
    end: { hour: 18, minute: 0 },
  },
  {
    name: "18:00~20:00",
    start: { hour: 18, minute: 0 },
    end: { hour: 20, minute: 0 },
  },
];

class RequestMapModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      center: calcCenter({
        areaWkts: props.territories.map((territory) => territory.area_wkt),
      }),
      isSliderDisabled: false,
      selectedRequestId: null,
      targetTimeRange: undefined,
      targetSenderRequests: [],
      targetReceiverRequests: [],
      sequences: [],
      isLoading: false,
    };
    this.setDefaultValue = this.setDefaultValue.bind(this);
    this.setCenterAndRequestId = this.setCenterAndRequestId.bind(this);
  }

  async loadAndSetSequences(
    params: { targetDate: string; carryStaffId: number },
    needsLoding = false
  ) {
    if (this.props.requests == null) {
      return;
    }

    if (needsLoding) {
      this.setState({ isLoading: true });
    }

    try {
      const response = await loadSequences(params);

      const mergedSequences = mergeRequestsAndSequences({
        requests: this.props.requests,
        sequences: response.data.sequences,
      });

      this.setState({ sequences: mergedSequences });
    } catch (err) {
      console.error("[loadAndSetSequences]", err);
    } finally {
      if (needsLoding) {
        this.setState({ isLoading: false });
      }
    }
  }

  async setDefaultValue() {
    if (this.props.requests == null || this.props.carryStaff == null) {
      return;
    }

    // 配達スタッフの担当エリアで地図をセンタリング
    const territoriyIds = (this.props.carryStaff?.schedules || [])
      .filter((sch) => sch.territory_id != null)
      .map((sch) => sch.territory_id) as number[];
    const casCenter = calcCenter({
      areaWkts: this.props.territories
        .filter((tr) => territoriyIds.indexOf(tr.id) >= 0)
        .map((tr) => tr.area_wkt),
    });

    // 現状スライダーの初期値は最初の配列になるので開いた際に依頼が取れるよう設定しておく
    const setTimeRange = timeRanges[0];
    const { targetSenderRequests, targetReceiverRequests } =
      this.createTargetRequestsArray(setTimeRange, this.props.requests);

    this.setState({
      center: casCenter,
      isSliderDisabled: false,
      selectedRequestId: null,
      targetSenderRequests: targetSenderRequests,
      targetReceiverRequests: targetReceiverRequests,
      sequences: [],
    });

    await this.loadAndSetSequences(
      {
        targetDate: this.props.selectedDate,
        carryStaffId: this.props.carryStaff.id,
      },
      true
    );
  }

  /**
   * 時刻文字列の時間部分を分数に変換するメソッド.
   * ex) 2023-10-01 07:20:00 → 7*60 + 20 = 440
   * @param dateStr
   * @returns
   */
  convertDateStrToMinutes(dateStr: string) {
    const _date = new Date(dateStr);
    const minutes = _date.getHours() * 60 + _date.getMinutes();
    return minutes;
  }

  /**
   * 指定されたrequestsのうち、rangeで指定される時間範囲と重複を持つものを、配達元(受取)と配達先(配達)で分けて抽出するメソッド.
   * @param range
   * @param requests
   * @returns
   */
  createTargetRequestsArray(range: TimeRange, requests: ExpandedRawRequest[]) {
    let targetSenderRequests: ExpandedRawRequest[] = [];
    let targetReceiverRequests: ExpandedRawRequest[] = [];

    const targetStart = range.start.hour * 60 + range.start.minute;
    const targetEnd = range.end.hour * 60 + range.end.minute;

    requests.forEach((req) => {
      // TimeRangeのstart-endと比較可能な値に変換して該当する依頼を格納する
      const pickupStart = this.convertDateStrToMinutes(req.from_pickup_at);
      const pickupEnd = this.convertDateStrToMinutes(req.to_pickup_at);
      const deliveryStart = this.convertDateStrToMinutes(
        req.from_delivery_time_at
      );
      const deliveryEnd = this.convertDateStrToMinutes(req.to_delivery_time_at);

      if (!(targetStart > pickupEnd || targetEnd < pickupStart)) {
        targetSenderRequests.push(req);
      }

      if (!(targetStart > deliveryEnd || targetEnd < deliveryStart)) {
        targetReceiverRequests.push(req);
      }
    });
    return { targetSenderRequests, targetReceiverRequests };
  }

  setCenterAndRequestId(
    request: ExpandedRawRequest,
    destinationType: "sender" | "receiver"
  ) {
    const center =
      destinationType == "sender"
        ? {
            lat: +request.sender_lat,
            lng: +request.sender_lng,
          }
        : {
            lat: +request.receiver_lat,
            lng: +request.receiver_lng,
          };
    this.setState(
      {
        center: center,
        selectedRequestId: request.id,
      },
      () => {
        // 再描画がスキップされセンタリングされないケースがあるので明示的にセンタリングする
        if (this.state.mapLoaded) {
          this.state.map.panTo(center);
        }
      }
    );
  }

  renderTimeRangeSlider(requests: ExpandedRawRequest[]) {
    const handleChangeTimeRange = (selectedRange: TimeRange) => {
      this.setState({
        targetTimeRange: selectedRange,
      });

      const { targetSenderRequests, targetReceiverRequests } =
        this.createTargetRequestsArray(selectedRange, requests);

      this.setState({
        targetSenderRequests: targetSenderRequests,
        targetReceiverRequests: targetReceiverRequests,
      });
    };

    const handleCheckboxChange = (event) => {
      this.setState({
        isSliderDisabled: event.target.checked,
      });
    };

    return (
      <div
        style={{
          position: "absolute",
          maxWidth: 400,
          top: "87%",
          left: "22%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            zIndex: 12,
            width: "30vh",
            padding: 8,
            boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            background: "#ffffff",
          }}
        >
          <TimeRangeSlider
            onChange={handleChangeTimeRange}
            timeRanges={timeRanges}
            disabled={this.state.isSliderDisabled}
          />

          <div className="form-group text-right mb-2">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="allRequestsChecked"
                checked={this.state.isSliderDisabled}
                onChange={handleCheckboxChange}
              />
              <label
                className="custom-control-label w-auto"
                htmlFor="allRequestsChecked"
              >
                全ての依頼を表示
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRequestCards(
    requests: ExpandedRawRequest[],
    destinationType: "sender" | "receiver"
  ) {
    return requests.map((req) => {
      return (
        <div key={`request_${req.id}`} className="card d-flex flex-column p-2">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: 15 }}
          >
            <a href={`/requests/${req.id}/edit`}>依頼No: {req.id}</a>
            <div
              className="p-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setCenterAndRequestId(req, destinationType);
              }}
            >
              <i className="fas fa-lg fa-map-marker-alt"></i>
            </div>
          </div>
          <div style={{ fontSize: "0.75em" }}>
            {destinationType == "sender" && (
              <div>
                <i className="fas fa-cube mr-1"></i>
                {req.sender_full_address}
              </div>
            )}
            {destinationType == "receiver" && (
              <div>
                <i className="fas fa-running mr-1"></i>
                {req.receiver_full_address}
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  renderRequestNavigation(requests: ExpandedRawRequest[]) {
    const { isSliderDisabled, targetSenderRequests, targetReceiverRequests } =
      this.state;
    const requestCounts = requests.length;
    const senderCounts = targetSenderRequests.length;
    const receiverCounts = targetReceiverRequests.length;

    const displayCounts = (targetCounts: number) => {
      if (isSliderDisabled) {
        return requestCounts;
      } else if (targetCounts > 0) {
        return targetCounts >= 99 ? "99+" : targetCounts;
      }
      return null;
    };

    return (
      <div className="col-3">
        <div className="card text-center">
          <div className="card-header">
            <ul
              className="nav nav-tabs card-header-tabs"
              id="nav-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pickup-tab"
                  data-toggle="tab"
                  href="#pickup"
                  role="tab"
                  aria-controls="pickup"
                  aria-selected="true"
                >
                  集荷
                  <span className="badge rounded-pill bg-white text-dark border">
                    {displayCounts(senderCounts)}
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="delivery-tab"
                  data-toggle="tab"
                  href="#delivery"
                  role="tab"
                  aria-controls="delivery"
                  aria-selected="false"
                >
                  配達
                  <span className="badge rounded-pill bg-white text-dark border">
                    {displayCounts(receiverCounts)}
                  </span>
                </a>
              </li>
            </ul>
          </div>

          <div
            className="card-body tab-content p-3"
            id="tab-content"
            style={{
              height: "calc(100vh - 300px)",
              overflowY: "auto",
            }}
          >
            <div
              className="tab-pane fade show active"
              id="pickup"
              role="tabpanel"
              aria-labelledby="pickup-tab"
            >
              {isSliderDisabled
                ? this.renderRequestCards(requests, "sender")
                : targetSenderRequests &&
                  this.renderRequestCards(targetSenderRequests, "sender")}
            </div>
            <div
              className="tab-pane fade"
              id="delivery"
              role="tabpanel"
              aria-labelledby="delivery-tab"
            >
              {isSliderDisabled
                ? this.renderRequestCards(requests, "receiver")
                : targetReceiverRequests &&
                  this.renderRequestCards(targetReceiverRequests, "receiver")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // 依頼に対応するシーケンスとその前後のシーケンスを抽出する関数
  extractSequencesByRequests(
    sequences: RequestSequence[],
    senderRequests: ExpandedRawRequest[],
    receiverRequests: ExpandedRawRequest[]
  ) {
    const senders = senderRequests.map((req) => {
      return { request_id: req.id, type: "sender" };
    });
    const receivers = receiverRequests.map((req) => {
      return { request_id: req.id, type: "receiver" };
    });
    const targets = [...senders, ...receivers];

    // 引数で与えられた依頼に対応するシーケンスを抽出
    const targetSequences = sequences.filter((seq) =>
      targets.some(
        (tar) =>
          tar.request_id === seq.request_id && tar.type === seq.destination_type
      )
    );

    let beforeSequences: RequestSequence[] = [];
    let afterSequences: RequestSequence[] = [];
    if (targetSequences.length > 0) {
      // 抽出したデータの前後にシーケンスが存在する場合はデータを返す
      const targetFirstSequence = targetSequences[0].sequence;
      const targetLastSequence =
        targetSequences[targetSequences.length - 1].sequence;
      const existBeforeSequence = sequences.some(
        (seq) => seq.sequence === targetFirstSequence - 1
      );
      const existAfterSequence = sequences.some(
        (seq) => seq.sequence === targetLastSequence + 1
      );

      beforeSequences = existBeforeSequence
        ? sequences
            .filter(
              (seq) =>
                seq.sequence === targetFirstSequence ||
                seq.sequence === targetFirstSequence - 1
            )
            .map((item) => ({ ...item }))
        : [];
      afterSequences = existAfterSequence
        ? sequences
            .filter(
              (seq) =>
                seq.sequence === targetLastSequence ||
                seq.sequence === targetLastSequence + 1
            )
            .map((item) => ({ ...item }))
        : [];
    }

    return {
      targets: targetSequences,
      before: beforeSequences,
      after: afterSequences,
    };
  }

  render() {
    const requests = this.props.requests;
    const carryStaff = this.props.carryStaff;
    if (requests == null || carryStaff == null) {
      return null;
    }

    const {
      center,
      isSliderDisabled,
      targetSenderRequests,
      targetReceiverRequests,
      sequences,
    } = this.state;

    let targetSequences: RequestSequence[] = [];
    let beforeSequences: RequestSequence[] = [];
    let afterSequences: RequestSequence[] = [];
    if (targetSenderRequests || targetReceiverRequests) {
      const { targets, before, after } = this.extractSequencesByRequests(
        sequences,
        targetSenderRequests,
        targetReceiverRequests
      );
      targetSequences = targets;
      beforeSequences = before;
      afterSequences = after;
    }

    return (
      <Modal
        isOpen={this.props.isOpen}
        onAfterOpen={this.setDefaultValue}
        onRequestClose={this.props.onRequestClose}
        style={modalStyles}
      >
        <div className="d-flex mb-3" style={{ position: "relative" }}>
          <h5 className="fas fa-map"> 依頼状況</h5>
          <div style={{ position: "absolute", right: 0 }}>
            配達スタッフ :&nbsp;
            <a href={`/carry_staffs/${carryStaff.id}/edit`}>
              {carryStaff.name}
            </a>
          </div>
        </div>

        <div className="container" style={{ width: "calc(100vw - 180px)" }}>
          <div className="row">
            <div className="col" style={{ height: "calc(100vh - 250px)" }}>
              <GoogleMap
                yesIWantToUseGoogleMapApiInternals={true}
                bootstrapURLKeys={{
                  key: gon.google_api_key,
                }}
                defaultZoom={14}
                center={center}
                resetBoundsOnResize={true}
                hoverDistance={MapAttributes.K_SIZE / 2}
                onGoogleApiLoaded={({ map, maps }) => {
                  this.setState({
                    map: map,
                    mapApi: maps,
                    mapLoaded: true,
                  });
                }}
              >
                {this.createTerritories()}
                {this.createSenders(
                  isSliderDisabled ? requests : targetSenderRequests
                )}
                {this.createReceivers(
                  isSliderDisabled ? requests : targetReceiverRequests
                )}
                {this.createLines(
                  isSliderDisabled ? sequences : targetSequences
                )}
                {/* 表示するシーケンスの前後に接続するシーケンスがあれば破線で表示する
                破線の場合矢印アイコンが小さくなり見えにくいのでスケールを少し調整する */}
                {!isSliderDisabled &&
                  this.createLines(beforeSequences, {
                    isDashed: true,
                    arrowIconScale: 4,
                    strokeColor: "rgba(108, 117, 125, 0.7)",
                  })}
                {!isSliderDisabled &&
                  this.createLines(afterSequences, {
                    isDashed: true,
                    arrowIconScale: 4,
                  })}
              </GoogleMap>
              {this.renderTimeRangeSlider(requests)}
            </div>
            {this.renderRequestNavigation(requests)}
          </div>
        </div>

        <div className="text-right mt-2">
          <button
            className="btn btn-secondary mr-2"
            type="button"
            onClick={this.props.onRequestClose}
          >
            戻る
          </button>
        </div>
      </Modal>
    );
  }

  createTerritories() {
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      this.props.territories.length == 0
    ) {
      return null;
    }

    const carryStaff = this.props.carryStaff;
    const territoriyIds = (carryStaff?.schedules || [])
      .filter((sch) => sch.territory_id != null)
      .map((sch) => sch.territory_id) as number[];
    return this.props.territories.map((territory) => {
      const isSelected = territoriyIds.indexOf(territory.id) >= 0;
      return (
        <WktMultiPolygon
          key={`area_${territory.area_wkt}`}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={territory.area_wkt}
          fillColor={isSelected ? "red" : undefined}
          strokeColor={isSelected ? "red" : undefined}
        />
      );
    });
  }

  createSenders(requests: ExpandedRawRequest[]) {
    const carryStaff = this.props.carryStaff;
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      requests.length == 0
    ) {
      return null;
    }

    return requests.map((req) => {
      const _req = convertRequestIntoForMap(req, carryStaff);
      const isSelected = this.state.selectedRequestId == req.id;
      return (
        <SenderMarker
          key={`sender_marker_${req.id}`}
          lat={+req.sender_lat}
          lng={+req.sender_lng}
          request={_req}
          iconColor={isSelected ? Colors.WARNING_COLOR : Colors.PRIMARY_COLOR}
          zIndex={isSelected ? 100 : undefined}
        />
      );
    });
  }

  createReceivers(requests: ExpandedRawRequest[]) {
    const carryStaff = this.props.carryStaff;
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      requests.length == 0
    ) {
      return null;
    }

    return requests.map((req) => {
      const _req = convertRequestIntoForMap(req, carryStaff);
      const isSelected = this.state.selectedRequestId == req.id;
      return (
        <ReceiverMarker
          key={`receiver_marker_${req.id}`}
          lat={+req.receiver_lat}
          lng={+req.receiver_lng}
          request={_req}
          iconColor={isSelected ? Colors.WARNING_COLOR : Colors.INFO_COLOR}
          zIndex={isSelected ? 100 : undefined}
        />
      );
    });
  }

  createLines(
    sequences: RequestSequence[],
    options?: {
      isDashed?: boolean;
      arrowIconScale?: number;
      strokeColor?: string;
    }
  ) {
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      sequences.length == 0
    ) {
      return null;
    }

    const lines = createEdgePointPairs({ sequences });

    return lines.map((line) => {
      const fromPoint =
        line.from.destination_type == "sender"
          ? {
              lat: line.from.request.sender_lat,
              lng: line.from.request.sender_lng,
            }
          : {
              lat: line.from.request.receiver_lat,
              lng: line.from.request.receiver_lng,
            };
      const toPoint =
        line.to.destination_type == "sender"
          ? { lat: line.to.request.sender_lat, lng: line.to.request.sender_lng }
          : {
              lat: line.to.request.receiver_lat,
              lng: line.to.request.receiver_lng,
            };

      return (
        <Polyline
          key={`line_from_${line.from.request.id}_${line.from.destination_type}`}
          map={this.state.map}
          mapApi={this.state.mapApi}
          locations={[
            { lat: +fromPoint.lat, lng: +fromPoint.lng },
            { lat: +toPoint.lat, lng: +toPoint.lng },
          ]}
          addArrow={true}
          arrowIconScale={options?.arrowIconScale ?? 3}
          arrowOffset={30}
          strokeColor={options?.strokeColor ?? "rgba(51, 163, 247, 0.7)"}
          strokeWeight={2}
          zIndex={-1}
          isDashed={options?.isDashed}
        />
      );
    });
  }
}

export default observer(RequestMapModal);

const modalStyles: Modal.Styles = {
  content: {
    maxHeight: "calc(100vh - 100px)",
    maxWidth: "calc(100vw - 100px)",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
