import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import GoogleMap from "../../../components/Common/GoogleMap";
import MapAttributes from "../../../constants/MapAttributes";
import SpotRemark from "../../../interfaces/SpotRemark";
import {
  getBboxFrom,
  getCenterFrom,
  getEnclosingCircleRadiusFrom,
} from "../../../utils/GeographyUtils";
import { calcWeights } from "../../../utils/HeatMapUtils";
import SpotReportMapSmallMarker from "../../SpotReportMapSmallMarker";
import WktMultiPolygon from "../../WktMultiPolygon";
import locationsStore from "./stores/LocationsStore";
import HeatMapRangeMeterSlider from "./components/HeatMapRangeMeterSlider";

declare var gon: any;
const DEFAULT_ZOOM = 16;
const MINIMUM_DEFAULT_ZOOM = 15;
const HEATMAP_RANGEMETER_STEP = 10;
const DEFAULT_HEATMAP_RADIUS = 200;

interface Props {
  spotRemark: SpotRemark;
}

interface State {
  map: google.maps.Map | null;
  mapApi: typeof google.maps | null;
  mapLoaded: boolean;
  heatMap: google.maps.visualization.HeatmapLayer | null;
  defaultHeatMapRadius: number;
}

class SpotRemarkMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      heatMap: null,
      defaultHeatMapRadius: DEFAULT_HEATMAP_RADIUS,
    };
  }

  private async loadLocations(meter: number) {
    await locationsStore.loadLocations(
      this.props.spotRemark.within_area_wkt,
      meter
    );
    this.resetHeatMap();
  }

  // サービスエリアの範囲に合わせてズームレベルを調整するための関数
  private executeFitBounds(targetAreaWkt: string) {
    const bbox = getBboxFrom(targetAreaWkt);
    if (!bbox) return;
    const bounds = new google.maps.LatLngBounds(
      {
        lat: bbox.minLat,
        lng: bbox.minLng,
      },
      {
        lat: bbox.maxLat,
        lng: bbox.maxLng,
      }
    );

    if (this.state.map) {
      this.state.map.fitBounds(bounds);
      // fitBounds実行後の値がMINIMUM_DEFAULT_ZOOMより小さい(広範囲な)場合はDEFAULT_ZOOMに戻す
      const zoomLevel = this.state.map.getZoom();
      if (zoomLevel && zoomLevel < MINIMUM_DEFAULT_ZOOM) {
        this.state.map.setZoom(DEFAULT_ZOOM);
      }
    }
  }

  private resetHeatMap() {
    const locations = locationsStore.locations || [];
    const { map, mapApi } = this.state;
    if (map && mapApi) {
      if (this.state.heatMap) {
        // すでに設定されている場合には非表示
        this.state.heatMap.setMap(null);
      }
      const weights = calcWeights(locations);
      const heatMapData = locations.map((loc, index) => ({
        location: new mapApi.LatLng(+loc.lat, +loc.lng),
        weight: weights[index],
      }));

      const heatMap = new mapApi.visualization.HeatmapLayer({
        data: heatMapData,
        radius: 15,
      });

      heatMap.setMap(map);
      this.setState({ heatMap: heatMap });
    }
  }

  private renderMarkers() {
    const targets = ["bicycle", "parking", "enter", "box"] as const;
    return targets.map((target) => this.renderMarker(target));
  }

  private renderMarker(target: "bicycle" | "parking" | "enter" | "box") {
    const { spotRemark } = this.props;

    const labelMap = {
      bicycle: "駐輪場所",
      parking: "駐車場所",
      enter: "入り口",
      box: "配達ボックス",
    };

    const targetLat = spotRemark[`${target}_lat`];
    const targetLng = spotRemark[`${target}_lng`];
    if (targetLat && targetLng) {
      return (
        <SpotReportMapSmallMarker
          key={target}
          index={0}
          icon={target}
          badgeText={labelMap[target]}
          lat={targetLat}
          lng={targetLng}
        />
      );
    }

    return null;
  }

  private renderHeatMapRangeMeterSlider() {
    const { defaultHeatMapRadius } = this.state;
    return (
      <HeatMapRangeMeterSlider
        step={HEATMAP_RANGEMETER_STEP}
        min={0}
        max={(defaultHeatMapRadius || DEFAULT_HEATMAP_RADIUS) * 2}
        defaultValue={defaultHeatMapRadius}
        onChange={(value) => this.loadLocations(value)}
      />
    );
  }

  render() {
    const { spotRemark } = this.props;
    const { map, mapApi } = this.state;

    const areaCenter = getCenterFrom(spotRemark.within_area_wkt);
    const center = areaCenter ?? {
      lat: spotRemark.center_lat,
      lng: spotRemark.center_lng,
    };

    return (
      <div style={{ width: "100%" }}>
        <div className="col-12">
          <div className="card shadow">
            <div style={styles.mapContainerStyle}>
              <GoogleMap
                bootstrapURLKeys={{
                  key: gon.google_api_key,
                }}
                defaultZoom={DEFAULT_ZOOM}
                center={{ lng: center.lng, lat: center.lat }}
                resetBoundsOnResize={true}
                hoverDistance={MapAttributes.K_SIZE / 2}
                onGoogleApiLoaded={({ map, maps }) => {
                  this.setState(
                    {
                      map: map,
                      mapApi: maps,
                      mapLoaded: true,
                    },
                    () => {
                      this.executeFitBounds(spotRemark.within_area_wkt);
                      const includeAreaRadius = getEnclosingCircleRadiusFrom(
                        spotRemark.within_area_wkt
                      );
                      // サービスエリアの範囲に合わせてヒートマップのデフォルト取得範囲を設定
                      // HEATMAP_RANGEMETER_STEPの単位に合わせて、
                      // 取得範囲半径が0になってしまうようなケースでは切り上げ、それ以外では切り捨てを行う
                      const radius =
                        includeAreaRadius ?? this.state.defaultHeatMapRadius;
                      const stepRadius =
                        Math.floor(radius / HEATMAP_RANGEMETER_STEP) === 0
                          ? Math.ceil(radius / HEATMAP_RANGEMETER_STEP) *
                            HEATMAP_RANGEMETER_STEP
                          : Math.floor(radius / HEATMAP_RANGEMETER_STEP) *
                            HEATMAP_RANGEMETER_STEP;
                      this.setState(
                        { defaultHeatMapRadius: stepRadius },
                        () => {
                          this.loadLocations(stepRadius);
                        }
                      );
                    }
                  );
                }}
                yesIWantToUseGoogleMapApiInternals={true}
              >
                {this.state.mapApi && (
                  <WktMultiPolygon
                    map={map}
                    mapApi={mapApi}
                    wktText={spotRemark.within_area_wkt}
                  />
                )}
                {this.renderMarkers()}
              </GoogleMap>
              {this.renderHeatMapRangeMeterSlider()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(SpotRemarkMap);

const styles: { [key: string]: CSSProperties } = {
  mapContainerStyle: {
    position: "relative",
    height: "calc(100vh - 6rem)",
    width: "100%",
  },
};
