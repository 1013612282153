import React, { useEffect, useState, CSSProperties } from "react";
import RangeSlider from "react-bootstrap-range-slider";

interface Props {
  onChange: (val: number) => void | Promise<void>;
  step?: number;
  min?: number;
  max?: number;
  defaultValue?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export default function HeatMapRangeMeterSlider(props: Props) {
  const {
    onChange,
    step = 10,
    min = 0,
    max = 1000,
    defaultValue = 200,
    style,
    disabled = false,
  } = props;

  const [value, setValue] = useState<number>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setValue(value);
    onChange(value);
  };

  const handleClickSub = () => {
    const resultValue = value - step;
    if (resultValue >= min) {
      setValue(resultValue);
      onChange(resultValue);
    }
  };

  const handleClickAdd = () => {
    const resultValue = value + step;
    if (resultValue <= max) {
      setValue(resultValue);
      onChange(resultValue);
    }
  };

  return (
    <div style={styles.mapMeterSettingPosition}>
      <div style={styles.mapMeterSettingStyle}>
        <div className="d-flex">
          <div>ヒートマップ表示範囲</div>
        </div>
        <div className="d-flex">
          <div style={{ fontSize: 12 }}>エリア中心からの半径距離</div>
        </div>
        <RangeSlider
          value={value}
          step={step}
          min={min}
          max={max}
          tooltip="off"
          onChange={handleChange}
          style={{ ...style, width: "100%" }}
          disabled={disabled}
        />
        <div className="d-flex">
          <div>{value + "m"}</div>

          <div className="d-flex" style={{ marginLeft: "auto" }}>
            <button
              className="btn btn-sm"
              onClick={handleClickSub}
              disabled={disabled}
            >
              <i className="fas fa-minus"></i>
            </button>
            <button
              className="btn btn-sm"
              onClick={handleClickAdd}
              disabled={disabled}
            >
              <i className="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles: { [key: string]: CSSProperties } = {
  mapMeterSettingPosition: {
    position: "absolute",
    bottom: "0.5rem",
    left: "0.5rem",
  },
  mapMeterSettingStyle: {
    zIndex: 12,
    padding: "0.5rem 1rem",
    boxShadow: `1px 1px 5px rgba(0, 0, 0, 0.25)`,
    background: "#ffffff",
  },
};
