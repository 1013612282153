import { Bounds } from "google-map-react";
import { action, observable } from "mobx";
import ServiceAreasResponse from "../interfaces/ServiceAreasResponse";
import { ServiceAreaModel } from "../models/ServiceAreaModel";
import { axiosGet } from "../utils/AxiosClient";

export class ServiceAreasStore {
  serviceAreas = observable<ServiceAreaModel>([]);
  // 表示IDリスト
  visibleIds = observable<number>([]);

  /**
   * > TODO 依頼俯瞰で、2023-07-12から急にzoomレベルを高域にするとブラウザ consoleで
   * > > The second argument to new LatLng() was ignored and can be removed.
   * > とアラートメッセージが表示され続けて、画面操作ができなくなった
   * > しかし、zoomレベルをアップにするとメッセージが出なくなる
   * という対応のために、一旦コメントアウトされているものなので、今のところ特に必要なし
   */
  notExistStaffServiceAreas = observable<ServiceAreaModel>([]);

  @action
  public async loadInterSectServiceAreas(
    bounds: Bounds | null,
    center: { lat: number; lng: number }
  ) {
    if (bounds == null) return;
    // 不正な値が入っている場合は何もしない
    const coords = [bounds.ne, bounds.se, bounds.sw, bounds.nw];
    for (const coord of coords) {
      if (!coord.lat || !coord.lng) return;
    }

    try {
      const response = await axiosGet.get<ServiceAreasResponse[]>(
        "/api/intersect_service_areas",
        {
          params: {
            centerLat: String(center.lat),
            centerLng: String(center.lng),
            neLng: String(bounds.ne.lng),
            neLat: String(bounds.ne.lat),
            seLng: String(bounds.se.lng),
            seLat: String(bounds.se.lat),
            swLng: String(bounds.sw.lng),
            swLat: String(bounds.sw.lat),
            nwLng: String(bounds.nw.lng),
            nwLat: String(bounds.nw.lat),
          },
        }
      );
      const items = response.data.map((item) => new ServiceAreaModel(item));

      this.replaceServiceAreas(items);
      this.showAllServiceAreas();
    } catch (err) {
      console.log("Error", err);
      this.replaceServiceAreas([]);
    }
  }

  @action
  private replaceServiceAreas(serviceAreas: ServiceAreaModel[]) {
    this.serviceAreas.replace(serviceAreas);
  }

  // @action
  // public async loadNotExistStaffServiceAreas() {
  //   try {
  //     const response = await axios.get<ServiceAreasResponse[]>(
  //       '/api/not_exist_staff_service_areas',
  //       { params: {} }
  //     );
  //     const items = response.data.map(item => new ServiceAreaModel(item));
  //     this.replaceNotExistStaffServiceAreas(items);
  //   } catch (err) {
  //     console.log("Error", err);
  //     this.replaceNotExistStaffServiceAreas([]);
  //   }
  // }

  // @action
  // private replaceNotExistStaffServiceAreas(serviceAreas: ServiceAreaModel[]) {
  //   this.notExistStaffServiceAreas.replace(serviceAreas)
  // }

  public getVisibleServiceAreas() {
    return this.serviceAreas.filter(
      (item) => this.visibleIds.indexOf(item.id) >= 0
    );
  }

  public isVisible(targetServiceArea: ServiceAreaModel) {
    return this.visibleIds.indexOf(targetServiceArea.id) >= 0;
  }

  @action
  public toggleVisible(targetServiceArea: ServiceAreaModel) {
    const indexInVisibleList = this.visibleIds.findIndex(
      (visibleId) => visibleId == targetServiceArea.id
    );
    if (indexInVisibleList >= 0) {
      const tmpVisibleIds = [...this.visibleIds];
      tmpVisibleIds.splice(indexInVisibleList, 1);
      this.visibleIds.replace(tmpVisibleIds);
    } else {
      this.visibleIds.replace(this.visibleIds.concat(targetServiceArea.id));
    }
  }

  @action
  public showAllServiceAreas() {
    this.visibleIds.replace(this.serviceAreas.map((item) => item.id));
  }

  @action
  public hideAllServiceAreas() {
    this.visibleIds.replace([]);
  }
}

const singleton = new ServiceAreasStore();
export default singleton;
