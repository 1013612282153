import _ from "lodash";
import { action, observable } from "mobx";
import { H3CapacityTimeline } from "../interfaces/H3CapacityTimeline";
import { H3CapacityTimelineModel } from "../models/H3CapacityTimelineModel";
import { axiosGet } from "../utils/AxiosClient";

interface H3CapatityTimelinesInfo {
  timelines: H3CapacityTimelineModel[];
  loading: boolean;
  loadStartAt: Date | null;
  loadEndAt: Date | null;
}

export class H3CapatityTimelinesStore {
  @observable updatedHexKey: string | null = null;

  capacityTimelinesMap = observable<{ [key: string]: H3CapatityTimelinesInfo }>(
    {}
  );

  constructor() {}

  public isLoading(h3Index: string | null | undefined) {
    if (!h3Index) return false;

    const timelinesInfo = this.capacityTimelinesMap[h3Index];
    if (!timelinesInfo) return false;

    return timelinesInfo.loading;
  }

  public getTimelinesInfoWithoutLoad(
    h3Index: string | null | undefined
  ): H3CapatityTimelinesInfo {
    if (h3Index == null) return this.getNoneTimelinesInfo();

    return this.capacityTimelinesMap[h3Index] || this.getNoneTimelinesInfo();
  }

  public convertTimelinesToRaw(
    models: H3CapacityTimelineModel[]
  ): H3CapacityTimeline[] {
    return models.map((model) => ({
      id: model.id,
      h3_index: model.h3Index,
      sdate: model.sdate,
      edate: model.sdate,
      org_staff_count: model.orgStaffCount,
      capacity: model.capacity,
      required_minute: model.requiredMinute,
      manual_required_minute: model.manualRequiredMinute,
      created_at: model.createdAt,
      updated_at: model.updatedAt,
      staff_count_prediction: model.staffCountPrediction ?? undefined,
    }));
  }

  @action
  public async resetH3Indices(h3Index: string[]) {
    h3Index.forEach((h3Index) => {
      const timelinesInfo = this.capacityTimelinesMap[h3Index];
      if (!timelinesInfo) return;

      // リセット
      this.capacityTimelinesMap[h3Index] = {
        timelines: [],
        loading: false,
        loadStartAt: null,
        loadEndAt: null,
      };
    });
  }

  // もし対象のh3Indexのデータがロードされていなければ、ロードするためのメソッド
  // changeIndexを利用しているところはそのままgetTimelinesで置き換えられるが、意味がわかりやすいように
  // エイリアス的な
  @action
  public async changeIndex(h3Index: string | null | undefined) {
    return await this.getTimelinesInfo(h3Index);
  }

  @action
  public async getTimelinesInfo(
    h3Index: string | null | undefined
  ): Promise<H3CapatityTimelinesInfo> {
    if (h3Index == null) return this.getNoneTimelinesInfo();

    const timelinesInfo = this.capacityTimelinesMap[h3Index];
    const needLoad = this.checkNeedLoad(timelinesInfo);
    if (needLoad) {
      return await this.loadTimelines(h3Index);
    }

    return timelinesInfo;
  }

  @action
  public async loadTimelines(h3Index: string | null | undefined) {
    if (h3Index == null) return this.getNoneTimelinesInfo();

    let timelinesInfo = this.capacityTimelinesMap[h3Index];
    if (timelinesInfo == null) {
      timelinesInfo = {
        timelines: [],
        loading: true,
        loadStartAt: null,
        loadEndAt: null,
      };
      this.capacityTimelinesMap[h3Index] = timelinesInfo;
    }

    timelinesInfo.loadStartAt = new Date();
    timelinesInfo.loading = true;

    const response = await axiosGet.get("/api/h3_capacity_timelines", {
      params: {
        h3Index: h3Index,
      },
    });
    const results = response.data;
    const timelines = _.map(results, (result: H3CapacityTimeline) => {
      return new H3CapacityTimelineModel(result);
    });

    timelinesInfo.timelines = timelines;
    timelinesInfo.loadEndAt = new Date();
    timelinesInfo.loading = false;

    this.capacityTimelinesMap[h3Index] = timelinesInfo;
    this.updateUpdatedHexKey(h3Index);

    return timelinesInfo;
  }

  @action
  private updateUpdatedHexKey(h3Index: string) {
    this.updatedHexKey = `${h3Index}_${new Date().getTime()}`;
  }

  private checkNeedLoad(timelinesInfo: H3CapatityTimelinesInfo | null) {
    // データが存在しない場合、もしくはロードされておらずかつローディング中でもない取得
    if (!timelinesInfo || !timelinesInfo.loadStartAt) return true;

    const nowTime = new Date().getTime();
    // ロード時刻3分以上前のものである場合、再取得
    if ((nowTime - timelinesInfo.loadStartAt.getTime()) / 1000 > 3 * 60) {
      return true;
    }

    // ロード時刻が10秒以上前かつ3分未満であるが、ロード中である場合
    // つまり10秒以上ローディングのままであると考えられる場合、再取得
    if (
      timelinesInfo.loading &&
      (nowTime - timelinesInfo.loadStartAt.getTime()) / 1000 > 10
    ) {
      return true;
    }

    return false;
  }

  private getNoneTimelinesInfo(): H3CapatityTimelinesInfo {
    return {
      timelines: [],
      loading: true,
      loadStartAt: null,
      loadEndAt: null,
    };
  }
}

const singleton = new H3CapatityTimelinesStore();
export default singleton;
