import React from "react";

interface Props {
  onCancel: () => void;
  onDestroy: () => void;
}

export default function MAPModalContent(props: Props) {
  const { onCancel, onDestroy } = props;
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalCenterTitle">
          全ての選択エリアを初期状態に戻しますか？
        </h5>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={onCancel}>
          キャンセル
        </button>
        <button type="button" className="btn btn-danger" onClick={onDestroy}>
          全ての選択エリアを初期化
        </button>
      </div>
    </div>
  );
}
