import { UniqueIdentifier } from "@dnd-kit/core";
import { format } from "date-fns";
import { axiosGet, axiosPost } from "../../../utils/AxiosClient";
import { getRawRequestShortDeliveryTimeLabel } from "../../../utils/DeliveryTimeLabelUtils";
import type { ExtendedRawRequest } from "../../../utils/routes-apis/common-utils";
import { isSameDate } from "../../../utils/time-utils";
import type {
  RawCarryStaff,
  RawCarryStaffRequestSequence,
  RawCarryStaffSchedule,
  RelatedCarryStaff,
  RelatedCarryStaffWithSchedules,
  RequestForCount,
  RequestSequence,
} from "../interfaces";

export function canEditSequence(sequence: RequestSequence) {
  const request = sequence.request;

  // 下記ステータスの場合は順序を入れ替えれない
  // 際配達とかのステータスに関してはそれが実装されてから考える
  if (
    ["delivered", "failed", "cancel", "store_cancel"].indexOf(request.status) >=
    0
  ) {
    return false;
  }

  // 受取済みの場合は、荷受けタスクの順番は入れ替えれない
  if (request.status == "pickup" && sequence.destination_type == "receiver") {
    return false;
  }

  return true;
}

export async function updateSequences(params: {
  targetDate: string;
  carryStaffId: number;
  sequences: Pick<
    RawCarryStaffRequestSequence,
    | "request_id"
    | "destination_type"
    | "sequence"
    | "estimated_delivery_time"
    | "work_start_time"
  >[];
}) {
  const response = (await axiosPost.post(`/api/carry_staff_request_sequences`, {
    target_date: params.targetDate,
    carry_staff_id: params.carryStaffId,
    sequences: params.sequences,
  })) as {
    data: {
      message: string;
    };
  };
  return response;
}

export function moveItem(
  sequences: RequestSequence[],
  draggingId: UniqueIdentifier,
  droppedId: UniqueIdentifier
) {
  const oldIndex = sequences.findIndex((seq) => seq.id == draggingId);
  const newIndex = sequences.findIndex((seq) => seq.id == droppedId);
  if (oldIndex < 0 || newIndex < 0) {
    console.warn(
      "[moveItem] Exist no target sequence",
      draggingId,
      "=>",
      droppedId
    );
    return { movedSequences: sequences, raisedErrorType: null };
  }

  const movedSequences = _moveItem(sequences, oldIndex, newIndex);
  return movedSequences;
}

function _moveItem(
  sequences: RequestSequence[],
  oldIndex: number,
  newIndex: number
) {
  const draggingSeq = sequences[oldIndex];
  let raisedErrorType: string | null = null;
  const _sequences = sequences.map((_seq, index, _) => {
    if (
      index < Math.min(oldIndex, newIndex) ||
      index > Math.max(oldIndex, newIndex)
    ) {
      return _seq;
    }

    if (
      oldIndex < newIndex &&
      draggingSeq.request_id == _seq.request_id &&
      draggingSeq.destination_type == "sender" &&
      _seq.destination_type == "receiver"
    ) {
      // 上から下に持って行き、
      // かつドラッグ中のアイテムと重なっているアイテムの依頼が同じもので、
      // かつドラッグ中のアイテムが配達元で、
      // かつ重なっているアイテムが配達先の場合
      // つまり、配達元を配達先より後に設定しようとしている場合、エラー
      raisedErrorType = "sender_over_receiver";
    } else if (
      oldIndex > newIndex &&
      draggingSeq.request_id == _seq.request_id &&
      draggingSeq.destination_type == "receiver" &&
      _seq.destination_type == "sender"
    ) {
      // 下から上に持って行き、
      // かつドラッグ中のアイテムと重なっているアイテムの依頼が同じもので、
      // かつドラッグ中のアイテムが配達先で、
      // かつ重なっているアイテムが配達元の場合
      // つまり、配達先を配達元より前に設定しようとしている場合、エラー
      raisedErrorType = "receiver_over_sender";
    }

    if (index == oldIndex) {
      return {
        ..._seq,
        sequence: newIndex + 1,
      };
    } else {
      // 上から下に持って行った場合(oldIndex < newIndex)、その間に含まれていたアイテムのインデックスは -1
      // 下から上に持って行った場合(oldIndex > newIndex、その間に含まれていたアイテムのインデックスは ＋1
      return {
        ..._seq,
        sequence: _seq.sequence + (oldIndex < newIndex ? -1 : 1),
      };
    }
  });

  const movedSequences = sortSequencesBySequence(_sequences);

  return {
    movedSequences,
    raisedErrorType,
  };
}

/**
 * 配達先への順番がその依頼の配達元への順番より前に来ているものがないか確認し、
 * もしあれば、そのアイテムのis_disallowedフラグを立てるメソッド.
 *
 * @param sequences
 * @returns
 */
export function checkInvalidSequenceAndUpdate(
  sequences: RequestSequence[],
  carryStaffSchedules: RawCarryStaffSchedule[]
) {
  // すでに受取を完了している配達の場合には、senderとreceiverの順番を機にする必要はない
  // (sequencesにその依頼のsenderが含まれることはない)ので、
  // senderが存在する依頼に関してのみ、receiverの位置を確認するようにする
  const reqIdsHasSender = sequences
    .filter((seq) => seq.destination_type == "sender")
    .map((seq) => seq.request_id);
  let _sequences: RequestSequence[] = [];
  let passedIds: string[] = [];
  let hasDisallowed = false;
  for (const seq of sequences) {
    // 配達先が配達元より先に来ているかどうか
    let isDisallowed = false;
    if (
      reqIdsHasSender.indexOf(seq.request_id) >= 0 &&
      seq.destination_type == "receiver" &&
      passedIds.indexOf(`${seq.request_id}_sender`) < 0
    ) {
      isDisallowed = true;
      hasDisallowed = true;
    }

    // 対象の地点への時刻がスケジュール外になっているかどうか
    let isOutsideSchedule = false;
    if (carryStaffSchedules) {
      const pickupTimeWindow = {
        earliest: new Date(seq.request.pickup.earliest),
        latest: new Date(seq.request.pickup.latest),
      };
      const dropoffTimeWindow = {
        earliest: new Date(seq.request.dropoff.earliest),
        latest: new Date(seq.request.dropoff.latest),
      };
      const targetTimeWindow =
        seq.destination_type == "sender" ? pickupTimeWindow : dropoffTimeWindow;
      const isIncludedInSchedule = carryStaffSchedules.some((sch) => {
        return !(
          new Date(sch.from_time) > targetTimeWindow.latest ||
          new Date(sch.to_time) < targetTimeWindow.earliest
        );
      });
      isOutsideSchedule = !isIncludedInSchedule;
    }

    _sequences.push({
      ...seq,
      is_disallowed: isDisallowed,
      is_outside_schedule: isOutsideSchedule,
    });
    passedIds.push(`${seq.request_id}_${seq.destination_type}`);
  }
  return { sequences: _sequences, hasDisallowed };
}

export function sortSequencesBySequence(sequences: RequestSequence[]) {
  const sortedSequences = sequences.sort((a, b) => {
    // 正なら b <- a
    // 負なら a <- b
    // 0なら そのまま
    if (a.sequence == b.sequence) {
      return 0;
    }

    return a.sequence < b.sequence ? -1 : 1;
  });

  return sortedSequences;
}

export function isRouteAssignable(carryStaff: RawCarryStaff) {
  const routeAssignmentConditions = {
    route: true,
    anycarry: carryStaff.can_route_delivery,
    vendor: carryStaff.can_route_delivery
  };

  return routeAssignmentConditions[carryStaff.staff_type] || false;
}

export function getTimeLabel(
  sequence: RequestSequence,
  useOrgTimeAt?: boolean
) {
  const request = sequence.request;
  if (sequence.destination_type == "sender") {
    return getSenderTimeLabel(request, useOrgTimeAt);
  } else {
    return getReceiverTimeLabel(request, useOrgTimeAt);
  }
}

function getSenderTimeLabel(
  request: ExtendedRawRequest,
  // ready_time_atそのままの日時で表示するためのオプション
  useOrgReadyTimeAt: boolean = false
) {
  if (useOrgReadyTimeAt) {
    // 過去表示の場合、createSequencesFromSummaries経由でrequest.ready_time_atが
    // request_change_summariesのrecieved_atで書き換えられているため、
    // DBに保存されているrequests.ready_time_atの値が表示されるわけではないことに注意。
    // ただし、request_change_summaries.recieved_atがNULLの場合には、
    // requests.ready_time_atの値が表示されることになる。
    return format(new Date(request.ready_time_at), "MM月dd日 HH:mm");
  }

  const pickup = request.pickup;
  let timeAtLabel = "-";
  if (request.pickup.earliest == request.pickup.latest) {
    timeAtLabel = `${format(new Date(pickup.earliest), "MM月dd日 HH:mm")}`;
  } else {
    timeAtLabel =
      `${format(new Date(pickup.earliest), "MM月dd日 HH:mm")} 〜 ` +
      `${format(new Date(pickup.latest), "HH:mm")}`;
  }

  if (!isSameDate(request.ready_time_at, pickup.earliest)) {
    // 補正されている場合、補正前の日付も表示
    timeAtLabel += ` (${format(new Date(request.ready_time_at), "MM月dd日")})`;
  }

  return timeAtLabel;
}

function getReceiverTimeLabel(
  request: ExtendedRawRequest,
  // delivery_time_atそのままの日時で表示するためのオプション
  useOrgDeliveryTimeAt: boolean = false
) {
  if (useOrgDeliveryTimeAt) {
    // 過去表示の場合、createSequencesFromSummaries経由でrequest.delivery_time_atが
    // request_change_summariesのdelivered_atで書き換えられているため、
    // DBに保存されているrequests.delivery_time_atの値が表示されるわけではないことに注意。
    // ただし、request_change_summaries.delivered_atがNULLの場合には、
    // requests.delivery_time_atの値が表示されることになる。
    return format(new Date(request.delivery_time_at), "MM月dd日 HH:mm");
  }

  const dropoff = request.dropoff;
  let timeAtLabel =
    `${format(new Date(dropoff.earliest), "MM月dd日")} ` +
    `${getRawRequestShortDeliveryTimeLabel(request)}`;
  if (!isSameDate(request.delivery_time_at, dropoff.earliest)) {
    // 補正されている場合、補正前の日付も表示
    timeAtLabel += ` (${format(
      new Date(request.delivery_time_at),
      "MM月dd日"
    )})`;
  }
  return timeAtLabel;
}

/**
 * ISO8601形式の日付文字列をHH:MMにフォーマットして返す
 * @param isoString
 */
export function formatIso8601Time(isoString: string | null): string {
  if (!isoString || isoString === "") {
    return "";
  }

  // Dateオブジェクトを作成
  const date = new Date(isoString);
  // Intl.DateTimeFormatを使用してロケールに基づいた時間の文字列を取得
  const timeString = new Intl.DateTimeFormat("ja-JP", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 24時間表記
  }).format(date);

  return timeString;
}

export async function loadRelatedCarryStaffs(
  targetDate: string,
  carryStaffId: number,
  requestIds?: number[]
) {
  const params = [
    ["target_date", targetDate],
    ["carry_staff_id", String(carryStaffId)],
  ];
  requestIds?.forEach((id) => {
    params.push(["request_ids[]", String(id)]);
  });
  return (await axiosGet.get(
    `/api/carry_staff_request_sequences/load_related_carry_staffs?${new URLSearchParams(
      params
    ).toString()}`
  )) as {
    data: {
      carry_staffs: RelatedCarryStaff[];
      schedules: RawCarryStaffSchedule[];
      assigned_requests: RequestForCount[];
    };
  };
}

export function mergeSchedulesIntoCarryStaffs(
  carryStaffs: RelatedCarryStaff[],
  schedules: RawCarryStaffSchedule[]
) {
  const _carryStaffs: RelatedCarryStaffWithSchedules[] = carryStaffs.map(
    (cas) => mergeSchedulesIntoCarryStaff(cas, schedules)
  );
  return _carryStaffs;
}

function mergeSchedulesIntoCarryStaff(
  carryStaff: RelatedCarryStaff,
  schedules: RawCarryStaffSchedule[]
) {
  const _carryStaff: RelatedCarryStaffWithSchedules = {
    id: carryStaff.id,
    name: carryStaff.name,
    staff_type: carryStaff.staff_type,
    can_route_delivery: carryStaff.can_route_delivery,
    territory_id: carryStaff.territory_id,
    vehicle_type_id: carryStaff.vehicle_type_id,
    vehicle_type_name: carryStaff.vehicle_type_name,
    territory_name: carryStaff.territory_name,
    schedules: schedules.filter(
      (schedule) => schedule.carry_staff_id == carryStaff.id
    ),
  };
  return _carryStaff;
}

export async function reassignRequestToAnotherCarryStaff(params: {
  reqIds: number[];
  casId: number;
}) {
  const response = (await axiosPost.post(
    `/api/carry_staff_request_sequences/reassign_request`,
    {
      request_ids: params.reqIds,
      carry_staff_id: params.casId,
    }
  )) as {
    data: {
      message: string;
    };
  };
  return response;
}
