import { useState } from "react";
//@ts-ignore
import { parse } from "wkt";

interface Props {
  toggleFeature: (
    geometry: any,
    id: number | string,
    resetIfExists?: boolean
  ) => void;
}

export default function useSelectableAreaMode(props: Props) {
  const { toggleFeature } = props;
  const [hoverSelectableArea, setHoverSelectableArea] = useState<{
    key: string;
    label: string;
    areaWkt: string;
  } | null>(null);

  const handleClickSelection = (area: {
    key: string;
    label: string;
    areaWkt: string;
  }) => {
    // つねに一つしか選択させたくないので、idはエリアによらず共通
    toggleFeature(parse(area.areaWkt), "selectable_area", true);
  };

  return {
    hoverSelectableArea,
    setHoverSelectableArea,
    handleClickSelection
  };
}
