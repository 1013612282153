import MapAttributes from "../constants/MapAttributes";

const K_SIZE = MapAttributes.K_SIZE;

const mapMarkerPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute' as 'absolute',
  width: K_SIZE,
  height: K_SIZE,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,

  border: '5px solid #29b50e',
  borderRadius: K_SIZE,
  backgroundColor: 'white',
  fontSize: 16,
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center' as 'center',
  justifyContent: 'center' as 'center',
};

const mapSmallMarkerPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute' as 'absolute',
  width: K_SIZE - 12,
  height: K_SIZE - 12,
  left: -((K_SIZE - 12) / 2),
  top: -((K_SIZE - 12) / 2),

  border: '2px solid #29b50e',
  borderRadius: K_SIZE - 12,
  backgroundColor: 'white',
  fontSize: 9,
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center' as 'center',
  justifyContent: 'center' as 'center',
};

const mapMarkerPlaceStyleHover = {
  ...mapMarkerPlaceStyle,
  border: '5px solid #3f51b5',
  color: '#f44336'
};

const infoWindowStyle = {
  position: 'absolute' as 'absolute',
  top: -8,
  left: K_SIZE,

  width: 200,
  height: 'auto',

  textAlign: 'left' as 'left',
  fontSize: 12,
  fontWeight: 'normal' as 'normal',
};

export {
  mapMarkerPlaceStyle, mapMarkerPlaceStyleHover, mapSmallMarkerPlaceStyle,
  K_SIZE, infoWindowStyle
};
