import React from "react";
import Colors from "../constants/BootstrapColors";
import { MapMarkerPin } from "./MapMarkerPin";

interface Props {
  lat: number;
  lng: number;
  iconColor?: string;
  zoomLevel?: number;
  zIndex?: number;
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;
}

export default class DeviceMarker extends React.Component<Props> {
  render() {
    const pinSize = 31;

    return (
      <div>
        <MapMarkerPin
          svgIcon="device"
          pinShape="circle"
          size={pinSize}
          pinBackgroundColor="#fff"
          svgIconColor={this.props.iconColor ?? Colors.PRIMARY_COLOR}
          onClick={() => {}}
          zIndex={this.props.zIndex ?? undefined}
        />
      </div>
    );
  }
}
