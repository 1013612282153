import GoogleMapReact, { Coords } from "google-map-react";
import { IReactionDisposer, reaction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import SenderMarker from "../../../components/SenderMarker";
import WktMultiPolygon from "../../../components/WktMultiPolygon";
import MapAttributes from "../../../constants/MapAttributes";
import { getMapDefaultValueFunctions } from "../../../utils/MapSettingStorageUtils";
import { convertRequestIntoForMap } from "../../../utils/RequestEntityUtils";
import { MapMarkerPin } from "../../MapMarkerPin";
import Colors from "../../../constants/BootstrapColors";
import { COLORS } from "../../../constants/Colors";
import {
  RawRequestWithInfo,
  RawTerritory,
  RawVehicleType,
} from "../interfaces";
import { pageStore } from "../stores";

declare var gon: any;

interface Props {
  territories: RawTerritory[];
  vehicleTypes: RawVehicleType[];
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  zoom: number;
  center: Coords;
  latestClickedMapAt: Date;
}

class CustomGoogleMap extends React.Component<Props, State> {
  mapFuncs: ReturnType<typeof getMapDefaultValueFunctions>;
  disposers: IReactionDisposer[] = [];

  constructor(props: Props) {
    super(props);
    this.mapFuncs = getMapDefaultValueFunctions(
      MapAttributes.LOCAL_STRAGE_BASE_KEY_ROUTEDELIVERYASSIGN,
      false
    );
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      zoom: this.mapFuncs.getDefaultZoom(),
      center: {
        lat: this.mapFuncs.getDefaultCenterLat(),
        lng: this.mapFuncs.getDefaultCenterLng(),
      },
      latestClickedMapAt: new Date(),
    };
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => pageStore.draggingRequest,
        (v, r) => {
          if (v) {
            this.setState({
              center: {
                lat: +v.receiver_lat,
                lng: +v.receiver_lng,
              },
            });
          }
        }
      )
    );

    this.disposers.push(
      reaction(
        () => pageStore.selectedReqId,
        (v, r) => {
          const req = pageStore.getSelectedReq();
          if (req) {
            this.setState({
              center: {
                lat: +req.receiver_lat,
                lng: +req.receiver_lng,
              },
            });
          }
        }
      )
    );
  }
  componentWillUnmount(): void {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  private createTerritories() {
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      this.props.territories.length == 0
    ) {
      return null;
    }

    const selectedCarryStaff = pageStore.getSelectedCas();
    return this.props.territories.map((territory) => {
      const isSelected =
        selectedCarryStaff && territory.id == selectedCarryStaff.territory_id;
      return (
        <WktMultiPolygon
          key={`area_${territory.area_wkt}`}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={territory.area_wkt}
          fillColor={isSelected ? COLORS.brand.anycarry : undefined}
          fillOpacity={isSelected ? 0.08 : undefined}
          strokeColor={isSelected ? COLORS.brand.anycarry : undefined}
          // areaKeyが設定されているとzIndexが10になるのを間接的に利用
          areaKey={isSelected ? "selected" : undefined}
        />
      );
    });
  }

  private createSenders() {
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      pageStore.requests.length == 0
    ) {
      return null;
    }

    return pageStore.requests.map((req) => {
      const _req = convertRequestIntoForMap(
        req,
        pageStore.carryStaffs.find((cas) => cas.id == req.carry_staff_id)
      );
      const colorAndZIndex = this.getPinColorAndZIndex(req);

      return (
        <SenderMarker
          key={`sender_marker_${req.id}`}
          lat={+req.sender_lat}
          lng={+req.sender_lng}
          request={_req}
          iconColor={colorAndZIndex.iconColor}
          zIndex={colorAndZIndex.zIndex}
          infoWindoZIndex={101}
          latestClickedMapAt={this.state.latestClickedMapAt}
        />
      );
    });
  }

  private createReceivers() {
    if (
      this.state.map == null ||
      this.state.mapApi == null ||
      pageStore.requests.length == 0
    ) {
      return null;
    }

    return pageStore.requests.map((req) => {
      const colorAndZIndex = this.getPinColorAndZIndex(req);

      return (
        <MapMarkerPin
          key={`receiver_marker_${req.id}`}
          lat={+req.receiver_lat}
          lng={+req.receiver_lng}
          pinShape={"rectangle"}
          size={27}
          pinBackgroundColor="#fff"
          pinText={req.shortCode}
          pinTextColor={colorAndZIndex.iconColor}
          zIndex={colorAndZIndex.zIndex}
          onClick={() => pageStore.selectRequest(req.id)}
        />
      );
    });
  }

  private getPinColorAndZIndex(targetRequest: RawRequestWithInfo) {
    const isDragging = pageStore.draggingRequest?.id == targetRequest.id;
    if (isDragging) {
      // ドラッグ中の依頼が最前
      return {
        iconColor: Colors.WARNING_COLOR,
        zIndex: 101,
      };
    }

    if (
      pageStore.selectedReqId &&
      pageStore.selectedReqId == targetRequest.id
    ) {
      // 選択されているCASに紐づく依頼より、直接選択されている依頼が前
      // (CASを選択している状態で、そのCASに紐づく依頼を直接選択した場合にその選択した依頼が前に来るように)
      return {
        iconColor: Colors.WARNING_COLOR,
        zIndex: 100,
      };
    }

    if (
      pageStore.selectedCasId &&
      pageStore.selectedCasId == targetRequest.carry_staff_id
    ) {
      return {
        iconColor: COLORS.brand.anycarry,
        zIndex: 99,
      };
    }

    if (targetRequest.org_carry_staff_id != null) {
      // アサイン済みの場合には、灰色
      return {
        iconColor: Colors.SECONDARY_COLOR,
        zIndex: undefined,
      };
    }

    return {
      iconColor: Colors.INFO_COLOR,
      zIndex: undefined,
    };
  }

  private updateLatestClickedMapAt() {
    this.setState({ latestClickedMapAt: new Date() });
  }

  render() {
    const center = this.state.center;
    return (
      <div className="col-12 d-flex" style={{ height: "50vh" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          // こいつ(defaultZoom)がないと「Error: Invalid LatLng object: (NaN, NaN)」というエラーになる
          defaultZoom={this.state.zoom}
          center={{ lng: center.lng, lat: center.lat }}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => {
            this.setState({
              map: map,
              mapApi: maps,
              mapLoaded: true,
            });
          }}
          onChange={(value) => {
            this.mapFuncs.setCurrentLatLngAndZoomToLocalStrage(
              value.center.lat,
              value.center.lng,
              value.zoom
            );
          }}
          onClick={() => this.updateLatestClickedMapAt()}
        >
          {this.createTerritories()}
          {this.createSenders()}
          {this.createReceivers()}
        </GoogleMapReact>
      </div>
    );
  }
}

export default observer(CustomGoogleMap);
