import { RoutesApiCode, parseApiType } from "./api-code-utils";
import type {
  ExcludedRequestReasonsParams,
  ExcludedRequestReasonsResponse,
} from "./common-utils";
import { getCommonExcludedRequestReasons } from "./common-utils";

export function getExcludedRequestReasons(
  params: ExcludedRequestReasonsParams
): ExcludedRequestReasonsResponse {
  // APIの特徴ごとに依頼を除くロジックを簡単に分岐できるような作りにはしておく
  const funcs: {
    [code in RoutesApiCode]: (
      params: ExcludedRequestReasonsParams
    ) => ExcludedRequestReasonsResponse;
  } = {
    "ec-and-nm": getCommonExcludedRequestReasons,
    geoapify: getCommonExcludedRequestReasons,
  };
  const { apiCode } = parseApiType(params.apiType);
  return funcs[apiCode](params);
}
