import React from "react";
import { MapInfoWindow } from "../components/MapInfoWindow";
import { CarryStaffCard } from "../components/MapInfoWindow/CarryStaffCard";
import { MapMarkerPin } from "../components/MapMarkerPin";
import { SVGIcon } from "../components/MapMarkerPin/MarkerIcon";
import Colors from "../constants/BootstrapColors";
import { carryStaffConst } from "../constants/CarryStaff";
import { COLORS } from "../constants/Colors";
import { MapItemZindexConst } from "../constants/MapItemZIndices";
import MarkerColors from "../constants/MarkerColors";
import {
  CurrentLocationWithCarryStaffModel,
  WorkType,
} from "../models/CurrentLocationWithCarryStaffModel";

interface Props {
  lat: number;
  lng: number;
  zoomLevel?: number;
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;
  // keyを渡すとhoverがうまく判定できないので、key propsは任意にして値をセットしない
  key?: number;
  location: CurrentLocationWithCarryStaffModel;
  latestClickedMapAt?: Date;
  zIndex?: number;
}

interface State {
  showInfoWindow: boolean;
}

export default class OfficeCarryStaffMarker extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    // 地図がクリックされたときに開いている詳細ウィンドウを閉じるため
    if (this.props.latestClickedMapAt !== prevProps.latestClickedMapAt) {
      this.setState({ showInfoWindow: false });
    }
  }

  // 地図がクリックされたときのクリック対象が詳細ウィンドウ内の場合は閉じないよう
  private onClickInfoWindow(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const location = this.props.location;
    const showInfoWindow = this.state.showInfoWindow || this.props.$hover;
    // 依頼対応件数が1以上
    const isProgressRequest = location.inProgressRequestCount > 0;
    // 動いていない可能性あり
    const mayStay = location.mayStayForAWhile;
    const carryStaffAvoidAssign = location.carryStaffAvoidAssign;
    const staffType = location.staffType;
    const moveMethodIcon = this.moveMethodToIcon();
    const pinSize = 28;
    const pinColor = this.getPinColor({ isProgressRequest, mayStay });
    const zIndex = showInfoWindow
      ? MapItemZindexConst.SELECTED_MARKER
      : this.props.zIndex;

    let pinText: string | undefined = undefined;
    let pinTextColor: string | undefined = undefined;
    let pinBackgroundColor: string | undefined = pinColor;
    let imageUrl: string | null = location.imageUrl;

    // staffTypeがwoltの場合のマーカーアイコン対応
    if (staffType == carryStaffConst.STAFF_TYPES.wolt) {
      pinText = "W";
      pinTextColor = "#fff";
      pinBackgroundColor = COLORS.brand.wolt;
      imageUrl = null;
    }

    return (
      <div>
        <MapMarkerPin
          svgIcon={moveMethodIcon}
          pinShape="drop"
          size={showInfoWindow ? pinSize * 1.5 : pinSize}
          pinText={pinText}
          pinTextColor={pinTextColor}
          pinBackgroundColor={pinBackgroundColor}
          svgIconColor={!isProgressRequest ? "#ffffff" : "#333"}
          pinImageUrl={imageUrl || undefined}
          onClick={this.onClickMarker.bind(this)}
          {...this.getTopRightBadgeProps(mayStay, carryStaffAvoidAssign)}
          {...this.getTopLeftBadgeProps(location.workType)}
          {...this.getMiddleLeftBadgeProps(
            moveMethodIcon,
            pinColor,
            imageUrl,
            staffType
          )}
          zIndex={zIndex}
        />
        {showInfoWindow && (
          <div onClick={(e) => this.onClickInfoWindow(e)}>
            <MapInfoWindow>
              <CarryStaffCard
                location={this.props.location}
                onCloseClick={() => {
                  this.setState({
                    showInfoWindow: false,
                  });
                }}
              />
            </MapInfoWindow>
          </div>
        )}
      </div>
    );
  }

  private onClickMarker(event: Event) {
    this.setState({ showInfoWindow: !this.state.showInfoWindow });
    event.preventDefault();
    event.stopPropagation();
  }

  private moveMethodToIcon(): SVGIcon {
    switch (this.props.location.moveMethod) {
      case 0:
        return "bicycle";
      case 10:
        return "bike";
      case 20:
        return "car";
      case 30:
      default:
        return "walk";
    }
  }

  private getPinColor({ isProgressRequest, mayStay }) {
    if (isProgressRequest) return MarkerColors.PROGRESS_COLOR;
    if (mayStay) return "#666";
    return MarkerColors.DEFAULT_COLOR;
  }

  private getIclass({ mayStay, carryStaffAvoidAssign }) {
    if (carryStaffAvoidAssign) return "far fa-hand-paper";
    if (mayStay) return "fas fa-sm fa-exclamation";
    return null;
  }

  private getTopRightBadgeProps(
    mayStay: boolean,
    carryStaffAvoidAssign: boolean
  ) {
    return {
      topRightBadgeStyle: {
        iClass: this.getIclass({ mayStay, carryStaffAvoidAssign }),
        backgroundColor: Colors.DANGER_COLOR,
      },
    };
  }

  private getTopLeftBadgeProps(workType: WorkType) {
    switch (workType) {
      case WorkType.ANYCARRY:
        return {
          topLeftBadgeStyle: {
            backgroundColor: COLORS.brand.anycarry,
          },
          topLeftBadgeText: "A",
        };
      case WorkType.DEMAECAN:
        return {
          topLeftBadgeStyle: {
            backgroundColor: COLORS.brand.demaecan,
          },
          topLeftBadgeText: "D",
        };
      case WorkType.AMAZON:
        return {
          topLeftBadgeStyle: {
            iClass: "fab fa-amazon",
            backgroundColor: COLORS.brand.amazon,
          },
        };
      case WorkType.WOLT:
        return {
          topLeftBadgeStyle: {
            backgroundColor: COLORS.brand.wolt,
          },
          topLeftBadgeText: "W",
        };
      case WorkType.REST:
        return {
          topLeftBadgeStyle: {
            backgroundColor: COLORS.brand.rest,
          },
          topLeftBadgeText: "休",
        };
    }
  }

  /**
   * 配達スタッフに画像が設定されている場合はピンに画像が、
   * staffTypeがwoltの場合はピンに"W"の文字表示されるため、
   * 移動手段を示すsvgアイコンをバッジとして表示する
   * @param svgIcon
   * @param defaultPinColor
   * @param imageUrl
   * @param staffType
   * @returns
   */
  private getMiddleLeftBadgeProps(
    svgIcon: SVGIcon,
    defaultPinColor: string,
    imageUrl: string | null,
    staffType: number
  ) {
    if (imageUrl || staffType == carryStaffConst.STAFF_TYPES.wolt) {
      return {
        middleLeftBadgeIcon: svgIcon,
        middleLeftBadgeStyle: {
          backgroundColor: defaultPinColor,
        },
      };
    }
  }
}
