import _ from "lodash";
import { action, observable } from "mobx";
import SpotRemarksResponse from "../interfaces/SpotRemarksResponse";
import { VisibleSpotRemarkModel } from "../models/VisibleSpotRemarkModel";
import { SpotRemarkModel } from "../models/SpotRemarkModel";
import { axiosGet } from "../utils/AxiosClient";

interface Marker {
  visible: boolean;
  id: number;
  model: SpotRemarkModel;
}

declare var google: any;

declare var gon: any;

export class SpotRemarkOverlookStore {
  visibleMarkers = observable<Marker>([]);

  @observable
  markerRange: number = 5000;

  listFilterFunc: Function | null = null;

  markerCount: number = 0;

  map: any;

  constructor() {}

  @action
  public setMap(map) {
    this.map = map;
  }

  @action
  public async loadMarkers(
    centerLat: number,
    centerLng: number,
    rangeMeter: number
  ) {
    const response = await axiosGet.get("/api/spot_remarks", {
      params: {
        lat: String(centerLat),
        lng: String(centerLng),
        range: rangeMeter,
      },
    });
    const results = response.data;

    let markers = _.map(results, (result: SpotRemarksResponse) => {
      return new VisibleSpotRemarkModel(result);
    });
    // listFilterFuncがない場合は全て通す
    markers = this.listFilterFunc ? this.listFilterFunc(markers) : markers;
    this.replaceMarkers(markers);
  }

  @action
  private replaceMarkers(markers) {
    this.visibleMarkers.replace(markers);
  }

  @action
  public toggleMarker(marker) {
    const findMarker = _.find(this.visibleMarkers, (object) => {
      return marker.id == object.id;
    });
    if (findMarker == null) {
      return;
    }

    findMarker.visible = !findMarker.visible;
    this.replaceMarkers(this.visibleMarkers);
  }

  // 外からも呼べるようにする
  @action
  public updateMarkers(markers) {
    this.replaceMarkers(markers);
  }

  @action
  public viewAllMarkers() {
    _.each(this.visibleMarkers, (object) => {
      object.visible = true;
    });
    this.replaceMarkers(this.visibleMarkers);
  }

  @action
  public hiddenAllMarkers() {
    _.each(this.visibleMarkers, (object) => {
      object.visible = false;
    });
    this.replaceMarkers(this.visibleMarkers);
  }

  @action
  public setMarkerRange(meter: number) {
    this.markerRange = meter;
  }

  public moveCenter(address: string) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: address,
        region: "jp",
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          this.map.setCenter({ lat, lng });
          this.loadMarkers(lat, lng, this.markerRange);
        }
      }
    );
  }
}

const singleton = new SpotRemarkOverlookStore();
export default singleton;
