import React from "react";
import Colors from "../constants/BootstrapColors";
import Request from "../interfaces/Request";
import { formatIso8601Time } from "../utils/TimeUtils";
import { MapInfoWindow } from "./MapInfoWindow";
import { SenderCard } from "./MapInfoWindow/SenderCard";
import { MapMarkerPin } from "./MapMarkerPin";

interface Props {
  lat: number;
  lng: number;
  request: Request;
  iconColor?: string;
  zoomLevel?: number;
  zIndex?: number;
  infoWindoZIndex?: number;
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;
  latestClickedMapAt?: Date;
  pinText: string;
}

interface State {
  showInfoWindow: boolean;
}

export default class SenderSequenceMarker extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    // 地図がクリックされたときに開いている詳細ウィンドウを閉じるため
    if (this.props.latestClickedMapAt !== prevProps.latestClickedMapAt) {
      this.setState({ showInfoWindow: false });
    }
  }

  // 地図がクリックされたときのクリック対象が詳細ウィンドウ内の場合は閉じないよう
  private onClickInfoWindow(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow;
    const pinSize = 31;
    const badgeText = () => {
      if (
        this.props.request.pickupStartTime &&
        this.props.request.pickupEndTime
      ) {
        let pickupStartTime = formatIso8601Time(
          this.props.request.pickupStartTime.toISOString()
        );
        let pickupEndTime = formatIso8601Time(
          this.props.request.pickupEndTime.toISOString()
        );
        return `${pickupStartTime}-${pickupEndTime}`;
      } else if (this.props.request.estimatedPickupTime) {
        return `${formatIso8601Time(this.props.request.estimatedPickupTime)}`;
      }
      return null;
    };

    return (
      <div>
        <MapMarkerPin
          pinShape="circle"
          size={showInfoWindow ? pinSize * 1.5 : pinSize}
          pinBackgroundColor="#fff"
          pinText={this.props.pinText}
          pinTextColor={this.props.iconColor ?? Colors.PRIMARY_COLOR}
          onClick={this.onClickMarker.bind(this)}
          topRightBadgeText={badgeText()}
          zIndex={this.props.zIndex ?? undefined}
        />
        {showInfoWindow && (
          <div onClick={this.onClickInfoWindow}>
            <MapInfoWindow zIndex={this.props.infoWindoZIndex}>
              <SenderCard
                deliveryRequest={this.props.request}
                onCloseClick={() => {
                  this.setState({
                    showInfoWindow: false,
                  });
                }}
              />
            </MapInfoWindow>
          </div>
        )}
      </div>
    );
  }

  private onClickMarker(event: Event) {
    this.setState({ showInfoWindow: !this.state.showInfoWindow });
    event.preventDefault();
    event.stopPropagation();
  }
}
