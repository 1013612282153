export const COLORS = {
  blue: "rgba(47,149,220, 0.9)",
  darkBlue: "rgba(47,149,220, 0.5)",
  orange: "rgba(255, 193, 7, 0.8)",
  darkOrange: "rgba(255, 193, 7, 0.5)",
  green: "rgba(64,220,113, 0.9)",
  darkGreen: "rgba(64,220,113, 0.5)",
  pink: "rgba(220,64,171, 0.8)",
  darkPink: "rgba(220,64,171, 0.5)",
  shadowBlack: "rgba(0, 0, 0, 0.25)",
  transparent: "rgba(255, 255, 255, 0)",
} as const;
