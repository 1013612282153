import "jquery-slimscroll";
import { differenceInSeconds, format } from "date-fns";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Pagination from "../../../../components/Common/Pagination";
import { staffLocationStatesStore } from "../stores";

interface Props {
  carryStaffId: number;
}

function OnOffList(props: Props) {
  const { carryStaffId } = props;

  const [page, setPage] = useState(1);

  const states = staffLocationStatesStore.states;

  const convSentAtIntoStr = (sentAt: string | null) => {
    if (sentAt == null || sentAt.length == 0) return "";

    const _sentAt = new Date(sentAt);
    const diffSec = differenceInSeconds(new Date(), _sentAt);
    if (diffSec <= 60) return "1分前";
    if (diffSec <= 180) return "3分前";
    if (diffSec <= 300) return "5分前";
    if (diffSec <= 600) return "10分前";
    if (diffSec <= 900) return "15分前";
    return format(_sentAt, "yyyy/MM/dd HH:mm");
  };

  const onChangePage = async (e: React.MouseEvent, newPage: number) => {
    e.preventDefault();
    if (newPage == page || newPage < 1) {
      return;
    }

    setPage(newPage);
    await staffLocationStatesStore.loadStaffLocationStates(
      carryStaffId,
      newPage
    );
  };

  return (
    <React.Fragment>
      <div
        className="d-flex flex-column"
        style={{
          height: "calc(100vh - 4.375rem - 10rem)",
          overflow: "auto",
        }}
      >
        <table className="border" style={{ fontSize: 14, width: "100%" }}>
          <thead>
            <tr>
              <th className="border font-weight-bold">ON/OFF</th>
              <th className="border font-weight-bold">更新日時</th>
            </tr>
          </thead>
          <tbody>
            {states.map((state) => {
              return (
                <tr key={state.id}>
                  <td className="border font-weight-normal">
                    {state.on_off == "on" ? "ON" : "OFF"}
                  </td>
                  <td className="border font-weight-normal">
                    {convSentAtIntoStr(state.sent_at)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="mt-auto">
          <Pagination
            displayPageCount={staffLocationStatesStore.perPage}
            currentPage={page}
            lastPage={staffLocationStatesStore.lastPage}
            onChangePage={(e, newPage) => {
              onChangePage(e, newPage);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default observer(OnOffList);
