import React from "react";

interface Props {
  mapApi: any;
  map: any;
  locations: any;
  addArrow?: boolean;
  arrowOffset?: number;
  arrowIconScale?: number;
  strokeColor?: string;
  strokeWeight?: number;
  zIndex?: number;
  isDashed?: boolean;
}

class Polyline extends React.Component<Props> {
  // mapapiを使って描画するのでstate相当のlineを自分で管理する
  line: any;

  constructor(props: Props) {
    super(props);
  }

  componentWillUpdate() {
    if (this.line) {
      // propsが更新されるタイミングで一旦lineをnullにする
      this.line.setMap(null);
    }
  }

  componentWillUnmount() {
    if (this.line) {
      this.line.setMap(null);
    }
  }

  render() {
    if (!this.props.mapApi) {
      return null;
    }
    // render関数で直接map apiにpolylineを追加
    const Polyline = this.props.mapApi.Polyline;

    const renderedPolyline = this.renderPolyline();
    let paths = { path: this.props.locations };

    let lineStyles: any[] = [];
    if (this.props.isDashed) {
      lineStyles.push({
        icon: {
          path: "M 0,-1 0,1",
          strokeOpacity: 1,
          scale: 4,
        },
        offset: "0",
        repeat: "20px",
      });
      renderedPolyline["strokeOpacity"] = 0;
    }

    if (this.props.addArrow) {
      lineStyles.push({
        icon: {
          anchor: this.props.mapApi.Point(30, 0),
          path: this.props.mapApi.SymbolPath.FORWARD_CLOSED_ARROW,
          fillOpacity: 1,
          scale: this.props.arrowIconScale ?? 4,
        },
        offset: this.props.arrowOffset ? `${this.props.arrowOffset}%` : "100%",
      });
    }

    paths["icons"] = lineStyles;
    this.line = new Polyline(Object.assign({}, renderedPolyline, paths));

    this.line.setMap(this.props.map);

    return null;
  }

  renderPolyline() {
    let res = {
      geodesic: true,
      strokeColor: "rgba(47, 149, 220, 0.9)",
      strokeOpacity: 1,
      strokeWeight: 4,
    };
    if (this.props.strokeColor) {
      res["strokeColor"] = this.props.strokeColor;
    }
    if (this.props.strokeWeight) {
      res["strokeWeight"] = this.props.strokeWeight;
    }
    if (this.props.zIndex) {
      res["zIndex"] = this.props.zIndex;
    }

    return res;
  }
}

export default Polyline;
