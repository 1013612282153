import { EventEmitter } from "events";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  getApiTypeName,
  type SelectableApiType,
} from "../../../utils/routes-apis/api-code-utils";
import { callArrangementApi } from "../../../utils/routes-apis/apis/arrange";
import type { RoutesApiErrorResponse } from "../../../utils/routes-apis/apis/common";
import type { ExtendedRawRequest } from "../../../utils/routes-apis/common-utils";
import { existsTasksNotOnSchedules } from "../../../utils/routes-apis/common-utils";
import type {
  ArrangeResponse,
  RawCarryStaff,
  RawCarryStaffSchedule,
  RawRoutesApiParamSetting,
  RequestSequence,
} from "../interfaces";
import { searchConditionsStore } from "../stores";
import { sortSequencesBySequence } from "../utils";

const stopLoopEventEmitter = new EventEmitter();

interface Props {
  isOpen: boolean;
  carryStaff: RawCarryStaff;
  schedules: RawCarryStaffSchedule[];
  extendedRequests: ExtendedRawRequest[];
  routesApiParamSettings: RawRoutesApiParamSetting[];
  sequences: RequestSequence[];
  selectedApiType: SelectableApiType;
  onCompleteRearrenge: (response: ArrangeResponse) => void;
  onCloseModal: () => void;
}

export default function AutoArrangeExecuteModal(props: Props) {
  const {
    isOpen,
    carryStaff,
    schedules,
    extendedRequests,
    routesApiParamSettings,
    sequences,
    selectedApiType,
    onCompleteRearrenge,
    onCloseModal,
  } = props;

  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");

  useEffect(() => {
    return () => {
      stopLoopEventEmitter.removeAllListeners("stop");
    };
  }, []);

  const createErrorMessage = (response: RoutesApiErrorResponse) => {
    let errorMessage = "";
    switch (response.code) {
      case "custom_timeout":
        errorMessage = `詳細：時間内に処理を完了することができませんでした。`;
        break;
      case "interrupted":
        errorMessage = `詳細：処理が中断されました。`;
        break;
      case "invalid_request":
        errorMessage = `詳細：正しい形式でのリクエストが実行できませんした。`;
        break;
      case "no_agents":
        errorMessage = `詳細：並び替え対象の配達スタッフが取得できませんでした。`;
        break;
      case "no_jobs_or_no_shipments":
        errorMessage = `詳細：並び替え対象依頼がありませんでした。`;
        break;
      case "no_solution":
        errorMessage = `詳細：制約を満たしながら並び替えることができませんでした。[no_solution]`;
        break;
      case "no_vehicles":
        errorMessage = `詳細：制約を満たす配送手段が見つかりませんでした。`;
        break;
      case "unsolvable":
        errorMessage = `詳細：制約を満たしながら並び替えることができませんでした。[unsolvable]`;
        break;
      case "unexpected_error":
        errorMessage = `詳細：計算時に予期せぬエラーが発生しました。`;
        break;
      case "validation_error":
        errorMessage = `詳細：チェック処理に失敗しました。`;
      default:
        errorMessage = `詳細：コード[${response.code}]`;
    }

    return `並び替え処理に失敗しました。 ${errorMessage}`;
  };

  const callRoutesApiAndSetSequences = async () => {
    if (extendedRequests.length <= 1) {
      onCompleteRearrenge({
        success: false,
        errorType: "custom",
        errorMessage: "依頼数が少ないため、手動で並び替えてください。",
        droppedItems: [],
      });
      return;
    }

    const _schedules = schedules.map((sch) => ({
      carry_staff_id: sch.carry_staff_id,
      territory_id: sch.territory_id,
      from_time: new Date(sch.from_time),
      to_time: new Date(sch.to_time),
    }));

    if (existsTasksNotOnSchedules(carryStaff, _schedules, extendedRequests)) {
      onCompleteRearrenge({
        success: false,
        errorType: "custom",
        errorMessage:
          "スケジュール外に受け取りもしくは配達を行わなければならない依頼が存在するため、" +
          "自動では並び替えることができません。手動で並び替えてください。",
        droppedItems: [],
      });
      return;
    }

    try {
      setLoading(true);
      setLoadingLabel("計算中...");
      const response = await callArrangementApi({
        apiType: selectedApiType,
        targetDate: searchConditionsStore.targetTerm.date,
        includePast: searchConditionsStore.targetTerm.type == "all",
        carryStaffId: carryStaff.id,
        routesApiParamSettings: routesApiParamSettings,
        timeout: 300,
        eventEmitter: stopLoopEventEmitter,
      });

      if (!response.isSuccess) {
        onCompleteRearrenge({
          success: false,
          errorType: "custom",
          errorMessage: createErrorMessage(response),
          droppedItems: [],
        });
        return;
      }

      if (response.dropped.length > 0) {
        // ドロップされてしまったアイテムがあった場合、並び替えは少々厳しいので諦め
        onCompleteRearrenge({
          success: false,
          errorType: "exists_dropped",
          errorMessage: "",
          droppedItems: response.dropped,
        });
        return;
      }

      if (response.sequences.length <= 0) {
        onCompleteRearrenge({
          success: false,
          errorType: "unsolved",
          errorMessage: "",
          droppedItems: [],
        });
        return;
      }

      let _sequences: RequestSequence[] = [];
      for (const seq of sequences) {
        const newIndex = response.sequences.findIndex(
          (_seq) =>
            _seq.requestId == seq.request_id &&
            _seq.destinationType == seq.destination_type
        );
        const targetSeq = response.sequences[newIndex];
        if (targetSeq == null) {
          console.log("seq", seq, "response.sequences", response.sequences);
          throw Error("Exists no sequence in response");
        }

        _sequences.push({
          ...seq,
          sequence: newIndex + 1,
          estimated_delivery_time: targetSeq.estimatedDeliveryTime,
          work_start_time: targetSeq.workStartTime,
        });
      }

      const sortedSequences = sortSequencesBySequence(_sequences);
      const reNumberedSequences = sortedSequences.map((_seq, index) => ({
        ..._seq,
        // responseに含まれているものの、sequencesには不要な要素がある可能性があり、
        // その場合newIndexが1からの順番にならない(どこかで飛ぶ)ため、
        // その場合用に改めてsequenceを振り直す
        sequence: index + 1,
      }));

      onCompleteRearrenge({
        success: true,
        sequences: reNumberedSequences,
      });
    } catch (e) {
      console.error(e);
      onCompleteRearrenge({
        success: false,
        errorType: "unsolved",
        errorMessage: e.response?.data?.message,
        droppedItems: [],
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClickClose = () => {
    if (loading) {
      const confirmed = confirm("まだ実行中ですが、終了しますか？");
      if (confirmed) {
        setLoadingLabel("終了中です...");
        stopLoopEventEmitter.emit("stop");
      }
    } else {
      onCloseModal();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        if (!loading) {
          onCloseModal();
        }
      }}
      style={modalStyles}
    >
      <div className="h5 mb-4 d-flex align-items-center">
        自動整列
        {loading && (
          <div className="d-flex align-items-center ml-3">
            <div className="mr-2">
              <span style={{ fontSize: 16 }}>{loadingLabel}</span>
            </div>
            <div
              className="spinner-border spinner-border-sm ml-3"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div>
        利用API:
        <span className="ml-2 font-weight-bold">
          {getApiTypeName(selectedApiType, routesApiParamSettings)}
        </span>
      </div>
      <div>実行しますか？</div>
      <div className="d-flex flex-column gap-4">
        <div className="d-flex justify-content-end my-2">
          <button
            className="btn btn-secondary mr-2"
            onClick={() => handleClickClose()}
            disabled={loading}
          >
            キャンセル
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={loading}
            onClick={async () => {
              await callRoutesApiAndSetSequences();
            }}
          >
            はい
          </button>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 20,
  },
  overlay: {
    zIndex: 1050,
  },
};
