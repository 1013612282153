import React from "react";
import { observer } from "mobx-react";
import { Coords } from "google-map-react";
import Wkt, { parse } from "wicket";
import { centerOfMass } from "@turf/turf";
import MapAreaPicker from "./MapAreaPicker/MapAreaPicker";

declare var gon: any;

interface Props {
  lat: number;
  lng: number;
  wkt: string;
  wktInputElementId: string;
}

interface State {
  wkt: string;
  center: Coords;
}

class NoDeliveryAreaMapEditor extends React.Component<Props, State> {
  wkt: any = null;

  constructor(props: Props) {
    super(props);

    const { lat, lng, wkt } = props;
    const center = getCenterCoords(wkt);
    this.state = {
      wkt,
      center: center ? center : { lat, lng },
    };

    this.wkt = new Wkt.Wkt();
  }

  updateByWkt = async (targetArea) => {
    const isValid = this.validateWktText(targetArea);
    return new Promise<void>(async (resolve, reject) => {
      try {
        const withinAreaWktInput = document.getElementById(
          this.props.wktInputElementId
        );

        if (!isValid) {
          if (withinAreaWktInput) {
            (withinAreaWktInput as HTMLInputElement).value = "";
          }

          throw new Error("wktの形式が不正です");
        }

        if (withinAreaWktInput) {
          (withinAreaWktInput as HTMLInputElement).value = targetArea;
        }
        this.setState({
          wkt: targetArea,
        });
        resolve();
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  validateWktText = (wktText: string) => {
    try {
      this.wkt.read(wktText);
    } catch (error) {
      return false;
    }
    return true;
  };

  render() {
    const { wkt, center } = this.state;
    return (
      <div style={{ width: "100%", height: 720 }}>
        <MapAreaPicker
          gmapsApiKey={gon.google_api_key}
          center={center}
          wkt={wkt}
          onUpdateByWkt={this.updateByWkt}
          notNull={true}
        />
      </div>
    );
  }
}

export default observer(NoDeliveryAreaMapEditor);

// ポリゴン全体の中心点を取得する
const getCenterCoords = (wkt: string): Coords | undefined => {
  try {
    const geoJSON = parse(wkt);
    const centerPoint = centerOfMass(geoJSON);
    return {
      lat: centerPoint.geometry.coordinates[1],
      lng: centerPoint.geometry.coordinates[0],
    };
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
