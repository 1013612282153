import format from 'date-fns/format';
import _ from 'lodash';
import { observer } from "mobx-react";
import React from "react";
import MapAttributes from "../constants/MapAttributes";
import RequestTimeLog from "../interfaces/RequestTimeLog";
import Request from "../interfaces/Request";
import ReceiverMarker from './ReceiverMarker';
import SenderMarker from './SenderMarker';
import GoogleMap from '../components/Common/GoogleMap';


declare var gon: any;

interface Props {
  request: Request;
  assignedAtLocation: RequestTimeLog;
  confirmedAtLocation: RequestTimeLog;
  senderArrivedAtLocation: RequestTimeLog;
  recievedAtLocation: RequestTimeLog;
  receiverArrivedAtLocation: RequestTimeLog;
  deliveredAtLocation: RequestTimeLog;
  deliveryFailedAtLocation: RequestTimeLog;
  cancelledAtLocation: RequestTimeLog;
  locationLogs: RequestTimeLog;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
}

class RequestChangeSummariesMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
    };
  }

  render() {
    const { request, locationLogs, assignedAtLocation, confirmedAtLocation, senderArrivedAtLocation, recievedAtLocation, receiverArrivedAtLocation, deliveredAtLocation, deliveryFailedAtLocation, cancelledAtLocation } = this.props;
    return (
      <div className={'request-map-container'}>
        <span
          id="request-map-postion-read-at"
          style={{ position: 'absolute', zIndex: 100, top: 0, left: 4 }}
        >
          {`位置情報 読込日時:  ${format(new Date(), 'HH:mm:ss')}`}
        </span>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={request.sender}
          defaultZoom={16}
          center={request.sender}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => this.setState({
            map: map,
            mapApi: maps,
            mapLoaded: true
          })}
        >
          <SenderMarker
            lat={request.sender.lat}
            lng={request.sender.lng}
            request={request}
          />
          <ReceiverMarker
            lat={request.receiver.lat}
            lng={request.receiver.lng}
            request={request}
          />
          {this.createPolylines(locationLogs)}

          {this.assignedAtCarryStaffMarkers(assignedAtLocation)}
          {this.confirmedAtCarryStaffMarkers(confirmedAtLocation)}
          {this.senderArrivedAtCarryStaffMarkers(senderArrivedAtLocation)}
          {this.recievedAtCarryStaffMarkers(recievedAtLocation)}
          {this.receiverArrivedAtCarryStaffMarkers(receiverArrivedAtLocation)}
          {this.deliveredAtCarryStaffMarkers(deliveredAtLocation)}
          {this.deliveryFailedAtCarryStaffMarkers(deliveryFailedAtLocation)}
          {this.cancelledAtCarryStaffMarkers(cancelledAtLocation)}
        </GoogleMap>
      </div>
    );
  }
  private createPolylines(locations) {
    if (_.isEmpty(this.state.map)) {
      return null
    }

    if (locations) {
      const convLocations = locations.map(l => ({ lat: +l.lat, lng: +l.lng }));

      const locationLogsLine = new google.maps.Polyline({
        path: convLocations,
        geodesic: true,
        strokeColor: "rgba(47,149,220, 0.9)",
        strokeOpacity: 1,
        strokeWeight: 4,
      });
      locationLogsLine.setMap(this.state.map);
    }
  }

  private assignedAtCarryStaffMarkers(assignedAtLocation) {
    this.createMarkers(assignedAtLocation, "A");
  }
  private confirmedAtCarryStaffMarkers(confirmedAtLocation) {
    this.createMarkers(confirmedAtLocation, "B");
  }
  private senderArrivedAtCarryStaffMarkers(senderArrivedAtLocation) {
    this.createMarkers(senderArrivedAtLocation, "C");
  }
  private recievedAtCarryStaffMarkers(recievedAtLocation) {
    this.createMarkers(recievedAtLocation, "D");
  }
  private receiverArrivedAtCarryStaffMarkers(receiverArrivedAtLocation) {
    this.createMarkers(receiverArrivedAtLocation, "E");
  }
  private deliveredAtCarryStaffMarkers(deliveredAtLocation) {
    this.createMarkers(deliveredAtLocation, "F");
  }
  private deliveryFailedAtCarryStaffMarkers(deliveryFailedAtLocation) {
    this.createMarkers(deliveryFailedAtLocation, "G");
  }
  private cancelledAtCarryStaffMarkers(cancelledAtLocation) {
    this.createMarkers(cancelledAtLocation, "H");
  }

  private createMarkers(location, text) {
    if (_.isEmpty(this.state.map)) {
      return null
    }

    if (location) {
      const marker = new google.maps.Marker({
        map: this.state.map,
        position: new google.maps.LatLng(Number(location.lat), Number(location.lng)),
        label: {
          text: text,
          color: 'black',
          fontSize: '20px',
          fontWeight: '900',
        },
      });
      marker.setMap(this.state.map);
    }
  }
}



export default observer(RequestChangeSummariesMap);
