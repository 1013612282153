import type { EventEmitter } from "events";
import type { RawRoutesApiParamSetting } from "../../../interfaces/entities";
import { axiosGet } from "../../AxiosClient";
import type { RoutesApiCode } from "../api-code-utils";

export type ExcludedRequest = {
  requestId: string;
  reason: {
    // 一旦stringで
    code: string;
    label: string;
  };
};

export interface SequenceItem {
  carryStaffId: number;
  requestId: number;
  destinationType: "sender" | "receiver";
  estimatedDeliveryTime: string;
  workStartTime: string;
  distance: number;
}

export type DroppedSequenceItem = Omit<
  SequenceItem,
  "estimatedDeliveryTime" | "distance"
>;

export interface LoopControlParams {
  timeout?: number; // 解取得最大待機時間(単位: 秒)
  eventEmitter?: EventEmitter; // ループ中止用EventEmitter
}

export interface RoutesApiCalculateEatInnerParams {
  api_code: RoutesApiCode;
  target_date: string;
  carry_staff_id: number;
  sequences: {
    request_id: number;
    destination_type: "sender" | "receiver";
    sequence: number;
  }[];
  param_settings?: {
    pickup_duration?: number;
    tolerant_seconds_before_pickup_at?: number;
    tolerant_seconds_after_pickup_at?: number;
    dropoff_duration?: number;
    tolerant_seconds_before_dropoff_at?: number;
    tolerant_seconds_after_dropoff_at?: number;
    geoapify?: {
      optimization_type: RawRoutesApiParamSetting["geoapify_optimization_type"];
      travel_mode: RawRoutesApiParamSetting["geoapify_travel_mode"];
      max_speed: RawRoutesApiParamSetting["geoapify_max_speed"];
    };
  };
  post_processing?: {
    // デフォルトはともにfalse
    notify?: boolean;
    update?: boolean;
  };
}

export type RoutesApiCalculateEatResponse =
  RoutesApiCalculateEatInnerResponse & {
    inputParams: RoutesApiCalculateEatInnerParams;
  };

type RoutesApiCalculateEatInnerResponse =
  | RoutesApiCalculateEatSuccessResponse
  | RoutesApiErrorResponse;

interface RoutesApiCalculateEatSuccessResponse {
  isSuccess: true;
  sequences: CalculateEatOutputSequenceItem[];
  // sequenceで指定された並び順による移動距離(単位: メートル)
  totalDistance: number;
}

interface CalculateEatOutputSequenceItem {
  requestId: number;
  destinationType: "sender" | "receiver";
  sequence: number;
  estimatedDeliveryTime: string;
  workStartTime: string;
  distance: number;
}

export interface RoutesApiArrangeInnerParams {
  api_code: RoutesApiCode;
  target_date: string;
  include_past: boolean;
  carry_staff_id: number;
  // デフォルトはtrue
  ignore_schdules?: boolean;
  param_settings?: {
    pickup_duration?: number;
    tolerant_seconds_before_pickup_at?: number;
    tolerant_seconds_after_pickup_at?: number;
    dropoff_duration?: number;
    tolerant_seconds_before_dropoff_at?: number;
    tolerant_seconds_after_dropoff_at?: number;
    geoapify?: {
      optimization_type: RawRoutesApiParamSetting["geoapify_optimization_type"];
      travel_mode: RawRoutesApiParamSetting["geoapify_travel_mode"];
      max_speed: RawRoutesApiParamSetting["geoapify_max_speed"];
    };
  };
  post_processing?: {
    // デフォルトはともにfalse
    notify?: boolean;
    update?: boolean;
  };
}

export type RoutesApiArrangeResponse = RoutesApiArrangeInnerResponse & {
  excluded: ExcludedRequest[];
  inputParams: RoutesApiArrangeInnerParams;
};

type RoutesApiArrangeInnerResponse =
  | RoutesApiArrangeSuccessResponse
  | RoutesApiErrorResponse;

interface RoutesApiArrangeSuccessResponse {
  isSuccess: true;
  dropped: DroppedSequenceItem[];
  sequences: SequenceItem[];
  // sequenceで指定された並び順による移動距離(単位: メートル)
  totalDistance: number;
}

interface GetRoutesApiResultParams {
  transactionCode: string;
  timeout?: number;
  eventEmitter?: EventEmitter;
}

export interface RoutesApiBulkAssignInnerParams {
  api_code: RoutesApiCode;
  target_date: string;
  carry_staff_ids: number[];
  request_ids: number[];
  param_settings?: {
    pickup_duration?: number;
    tolerant_seconds_before_pickup_at?: number;
    tolerant_seconds_after_pickup_at?: number;
    dropoff_duration?: number;
    tolerant_seconds_before_dropoff_at?: number;
    tolerant_seconds_after_dropoff_at?: number;
    geoapify?: {
      optimization_type: RawRoutesApiParamSetting["geoapify_optimization_type"];
      travel_mode: RawRoutesApiParamSetting["geoapify_travel_mode"];
      max_speed: RawRoutesApiParamSetting["geoapify_max_speed"];
    };
  };
  post_processing?: {
    // デフォルトはfalse
    update?: boolean;
  };
}

export type RoutesApiBulkAssignResponse = RoutesApiBulkAssignInnerResponse & {
  excluded: ExcludedRequest[];
  inputParams: RoutesApiBulkAssignInnerParams;
};

type RoutesApiBulkAssignInnerResponse =
  | RoutesApiBulkAssignSuccessResponse
  | RoutesApiErrorResponse;

interface RoutesApiBulkAssignSuccessResponse {
  isSuccess: true;
  droppedReqIds: number[];
  carryStaffs: CarryStaffBulkAssignResponseItem[];
}

interface CarryStaffBulkAssignResponseItem {
  id: number;
  sequences: (SequenceItem & { wasAssigned: boolean })[];
}

export interface RoutesApiErrorResponse {
  isSuccess: false;
  // 同じ意味のコードがあるのは、GeoapifyとOR Toolsとで違ったりするため
  // => OR Toolsが消えたので、消せるコードもある気がする
  code:
    | "unexpected_error" // 想定外のエラー
    | "no_agents" // 配達スタッフなし
    | "invalid_request" // 形式エラー
    | "no_jobs_or_no_shipments" // 依頼なし
    | "no_vehicles" // 配達スタッフなし
    | "unsolvable" // 解なし
    | "validation_error" // 形式エラー
    | "custom_timeout" // タイムアウト
    | "interrupted" // 中断
    | "no_solution"; // 解なし
}

const DEFAULT_LOOP_TIMEOUT = 300;

const LOOP_INTERVAL = 5;

export async function getRoutesApiResultViaAdms(
  execType: "calc-eat",
  params: GetRoutesApiResultParams
): Promise<RoutesApiCalculateEatResponse>;

export async function getRoutesApiResultViaAdms(
  execType: "arrange",
  params: GetRoutesApiResultParams
): Promise<RoutesApiArrangeResponse>;

export async function getRoutesApiResultViaAdms(
  execType: "assign",
  params: GetRoutesApiResultParams
): Promise<RoutesApiBulkAssignResponse>;

// execTypeはoverloadを利用して返り値の型を切り替えるためのダミー引数
export async function getRoutesApiResultViaAdms(
  execType: "calc-eat" | "arrange" | "assign",
  {
    transactionCode,
    timeout = DEFAULT_LOOP_TIMEOUT,
    eventEmitter,
  }: GetRoutesApiResultParams
) {
  let stopLoop = false;
  try {
    if (eventEmitter) {
      eventEmitter.on("stop", () => {
        stopLoop = true;
      });
    }

    for (let i = 0; i < Math.ceil(timeout / LOOP_INTERVAL); i++) {
      if (stopLoop) {
        return {
          isSuccess: false,
          code: "interrupted",
          excluded: [],
        };
      }

      // 5秒待機
      await new Promise((resolve, reject) =>
        // 秒をミリ秒に変換するための1000倍
        setTimeout(resolve, LOOP_INTERVAL * 1000)
      );

      try {
        const getResponse = await _callGetRoutesApiResultViaAdms<
          | {
              code: "FAILED";
            }
          | {
              code: "FAILED_TO_SAVE";
            }
          | {
              code: "COMPLETED";
              result:
                | RoutesApiCalculateEatResponse
                | RoutesApiArrangeResponse
                | RoutesApiBulkAssignResponse;
            }
        >(transactionCode);
        if (getResponse.status == 202) {
          // 計算中
          continue;
        }

        if (
          // 計算後に計算結果を保存しようとした時にDBエラーが発生した時などを想定したコード
          // 普通に計算中に問題が発生した場合には、codeはCOMPLETEDとなり、resultがエラー内容になる
          getResponse.data.code == "FAILED" ||
          // 計算は完了しているのに計算結果が保存されていなかった場合のコード
          // だけど、普通はあり得ないはず(完了しかつ保存されているか、失敗しているかのどちらかのはず)
          getResponse.data.code == "FAILED_TO_SAVE"
        ) {
          return {
            isSuccess: false,
            code: "unexpected_error",
            excluded: [],
          };
        }

        // 完了しているからと言って成功しているわけではない
        // 成功して完了した場合と失敗として完了した場合の2通りある
        return getResponse.data.result;
      } catch (error) {
        // code(error.response.data.code)として、
        // INVALID_REQUESTもしくはINTERNAL_SERVER_ERRORのみを想定(それ以外はネットワークエラー)
        // transaction_codeに問題があるか、あるいはDBアクセスで何か問題が発生したかのどちらか
        // 後者の場合には再取得したらうまくいくかもだけど、一旦失敗として即返す形にする
        console.log(error);
        return {
          isSuccess: false,
          code: "unexpected_error",
          excluded: [],
        };
      }
    }

    return {
      isSuccess: false,
      code: "custom_timeout",
      excluded: [],
    };
  } finally {
    if (eventEmitter) {
      eventEmitter.removeAllListeners("stop");
    }
  }
}

async function _callGetRoutesApiResultViaAdms<Response>(
  transactionCode: string
) {
  const params = new URLSearchParams([["transaction_code", transactionCode]]);
  const response = await axiosGet.get<Response>(
    `/api/routes_apis/task/result?${params.toString()}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );
  return response;
}
