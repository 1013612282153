import React from "react";
import { Card, Image } from 'react-bootstrap';
import { infoWindowStyle } from '../styles/SenderMarkerStyles'
import { mapSmallMarkerPlaceStyle } from '../styles/StoreMarkerStyles'

interface Props {
  lat: number,
  lng: number,
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean,
  name: string,
}

interface State {
  showInfoWindow: boolean
}

export default class ServiceAreaSmallMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false
    };
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow;

    // S = start
    return (
      <div
        style={mapSmallMarkerPlaceStyle}
        onClick={this.onClickMarker.bind(this)}
      >
        <i className={"fa fa-cloud"} />
        {showInfoWindow && (
          <Card
            style={infoWindowStyle}
          >
            <div>
              {this.props.name}
            </div>
          </Card>
        )}
      </div>
    )
  }

  private onClickMarker(event: Event) {
    this.setState({ showInfoWindow: !this.state.showInfoWindow })
    event.preventDefault();
    event.stopPropagation();
  }
}
