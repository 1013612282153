import format from 'date-fns/format';
import _ from 'lodash';
import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import GoogleMap from '../components/Common/GoogleMap';
import MapAttributes from "../constants/MapAttributes";
import DeviceMarker from './DeviceMarker';

Modal.setAppElement("#wrapper");

declare var gon: any;

interface Props {
  lat: number;
  lng: number;
  locationUpdatedAt: Date;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  isModalOpen: boolean;
}

class DeviceMapModal extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      isModalOpen: false
    };
  }

  toggleModal = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };

  render() {
    const locationUpdatedAt = new Date(this.props.locationUpdatedAt);
    const { lat, lng } = this.props;
    const isModalOpen = this.state.isModalOpen;

    return (
      <>
        <div>
          <button
            className="btn btn-primary btn-sm ml-2"
            onClick={this.toggleModal}
          >
            MAP
          </button>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.toggleModal}
          style={modalStyles}
        >
          <div className="d-flex mb-3" style={{ position: 'relative' }}>
            <h5>位置情報</h5>
            <div
              style={{ position: 'absolute', right: 0 }}
              className='fas fa-times'
              onClick={this.toggleModal}
            >
            </div>
          </div>

          <div className='container' style={{ width: "calc(100vw - 280px)" }}>
            <div className='row'>
              <div className='col' style={{ height: "calc(100vh - 350px)" }}>
                <GoogleMap
                  yesIWantToUseGoogleMapApiInternals={true}
                  bootstrapURLKeys={{
                    key: gon.google_api_key
                  }}
                  defaultCenter={{ lat: lat, lng: lng }}
                  defaultZoom={16}
                  center={{ lat: lat, lng: lng }}
                  resetBoundsOnResize={true}
                  hoverDistance={MapAttributes.K_SIZE / 2}
                  onGoogleApiLoaded={
                    ({ map, maps }) => {
                      this.setState({
                        map: map,
                        mapApi: maps,
                        mapLoaded: true
                      })
                    }
                  }
                >
                  <DeviceMarker
                    lat={lat}
                    lng={lng}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className="text-right mt-2">
            {`位置情報 更新日時:  ${format(locationUpdatedAt, 'yyyy/MM/dd HH:mm')}`}
          </div>
        </Modal>
      </>
    );
  }

}

export default observer(DeviceMapModal);

const modalStyles: Modal.Styles = {
  content: {
    maxHeight: "calc(100vh - 200px)",
    maxWidth: "calc(100vw - 200px)",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26
  },
  overlay: {
    zIndex: 1050
  }
};
