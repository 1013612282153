import format from "date-fns/format";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import Colors from "../../../constants/BootstrapColors";
import I18n from "../../../packs/i18n/i18n";
import "../../../packs/i18n/ja";
import { ThirdPartyDeliveryTaskModel } from "../../../models/ThirdPartyDeliveryTaskModel";
import { getKeyByThirdPartyDeliveryTaskStatus } from "../utils/Utils";

interface Props {
  selectedItem: ThirdPartyDeliveryTaskModel | undefined;
  items: ThirdPartyDeliveryTaskModel[];
  onSelect: (tpdtId: number) => void;
}

class ThirdPartyDeliveryTaskTableBody extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleClickTpdt(tpdtId: number) {
    this.props.onSelect(tpdtId);
  }

  render() {
    const { selectedItem, items } = this.props;

    // 外部配達をクリックした際にそのデータにセンタリングさせることで
    // 地図範囲に存在するデータが変わりindexも変わってしまうため
    // リスト上で選択したデータの位置を見失うことがあり、これを防ぐために選択したデータをリストの最初に配置する。
    if (selectedItem) {
      const dataIndex = items.findIndex((req) => req.id == selectedItem.id);
      if (dataIndex >= 0) {
        const selectedData = items.splice(dataIndex, 1)[0];
        items.unshift(selectedData);
      }
    }

    return (
      <tbody>
        {items.map((tpdt) => {
          const statusKey = getKeyByThirdPartyDeliveryTaskStatus(tpdt.status);
          const isSelected = selectedItem && tpdt.id == selectedItem.id;
          const indexWrapperStyle: CSSProperties = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `1px solid ${
              isSelected ? Colors.WARNING_COLOR : Colors.DANGER_COLOR
            }`,
            color: isSelected ? Colors.WARNING_COLOR : Colors.DANGER_COLOR,
            fontWeight: "bold",
            width: "36px",
            height: "24px",
          };

          return (
            <tr
              key={tpdt.shortCode}
              style={styles.tableRow}
              onClick={() => this.handleClickTpdt(tpdt.id)}
            >
              <td>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <div className="mb-2">
                      <div style={indexWrapperStyle}>
                        {tpdt.shortCode ?? "-"}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <p style={styles.itemFont}>外部依頼ID : </p>
                      <a href={`/third_party_delivery_tasks/${tpdt.id}`}>
                        {tpdt.id}
                      </a>
                    </div>
                    <div className="mb-1">
                      <p style={styles.itemFont}>ステータス : </p>
                      <span
                        className="request-status-label badge"
                        title={`${statusKey}`}
                      >
                        {I18n.t(`enums.request.status.${statusKey}`)}
                      </span>
                    </div>
                    <div className="mb-1">
                      <p style={styles.itemFont}>配達スタッフ : </p>
                      {tpdt.carryStaff ? tpdt.carryStaff.name : "(未アサイン)"}
                    </div>
                  </div>
                  <div style={{ flex: 2 }}>
                    <div className="mb-1">
                      <p style={styles.itemFont}>業務タイプ : </p>
                      <span
                        className="work-type-label badge"
                        style={{ fontSize: "0.7rem" }}
                        title={`${tpdt.workType}`}
                      >
                        {I18n.t(`enums.carry_staff.work_type.${tpdt.workType}`)}
                      </span>
                    </div>
                    <div>
                      <p style={styles.itemFont}>配達時間 : </p>
                      {tpdt.deliveryTimeAt &&
                        format(tpdt.deliveryTimeAt, "yyyy/MM/dd HH:mm")}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

const styles = {
  itemFont: {
    fontWeight: "bold",
    margin: 0,
  } as CSSProperties,
  tableRow: {
    fontSize: "0.8rem",
    margin: 0,
    cursor: "pointer",
  } as CSSProperties,
};

export default observer(ThirdPartyDeliveryTaskTableBody);
