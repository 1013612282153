import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";

import {GmapsUIContext} from "../index";
import type {MapApi} from "../index";

export interface Toast {
    content?: ReactNode
    id: string | number
    isDisappear?: boolean
    lifetimeMs?: number
    options?: ToastOptions
}

export interface ToastOptions {
    lifetimeMs?: number
}

interface Props {
    mapApi: MapApi,
    children: React.ReactNode,
}

export function Notifications(props: Props) {
    const componentRef = useRef(null)
    const {mapApi, children} = props
    const {toast} = useContext(GmapsUIContext)
    const [toasts, setToasts] = useState<Toast[]>([])
    const toastsRef = useRef(toasts);
    toastsRef.current = toasts
    const MAX_TOAST = 5

    useEffect(() => {
        if (componentRef.current) {
            mapApi.map.controls[mapApi.maps.ControlPosition.LEFT_BOTTOM].push(componentRef.current);
        }
        return () => {
            mapApi.map.controls[mapApi.maps.ControlPosition.LEFT_BOTTOM].clear()
        }
    }, [])

    useEffect(() => {
        if (!toast) return
        if (toastsRef.current.length > MAX_TOAST - 1) toastsRef.current[0].isDisappear = true
        const addedToasts = [...toastsRef.current, toast]

        setToasts(addedToasts)

        if (toast.options?.lifetimeMs) {
            setTimeout(() => {
                const updatedToasts = toastsRef.current.map((t) => {
                    if (t.id === toast.id) t.isDisappear = true
                    return t
                })
                setToasts(updatedToasts)
            }, toast.options?.lifetimeMs)
        }
    }, [toast])

    const handleDisappear = (id: number | string) => {
        const newToasts = toastsRef.current.filter((t) => {
            return t.id !== id
        })
        setToasts(newToasts)
    }
    return (
        <div style={{visibility: "hidden"}}>
            <div ref={componentRef} style={{zIndex: 3}}>
                {children}
                <div style={{
                    width: 320,
                    padding: 4,
                }}>
                    {
                        toasts.map((t) => {
                            return (
                                <Toast
                                    content={t.content}
                                    key={t.id}
                                    id={t.id}
                                    onDisappear={handleDisappear}
                                    isDisappear={t.isDisappear || false}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

interface ToastProps {
    content: ReactNode
    style?: any
    onDisappear: (id: number | string) => void
    id: number | string
    isDisappear: boolean
}

function Toast(props: ToastProps) {
    const {content, style, onDisappear, id, isDisappear} = props
    const toastRef = useRef<HTMLDivElement>(null)
    const [lifeInterval, setLifeInterval] = useState()
    const appearAnimationMs = 500
    const waitMs = 5000
    const disappearAnimationMs = 500

    useEffect(() => {
        const elm = toastRef.current as HTMLDivElement
        const animation = [
            {transform: `translateY(${elm.clientHeight}px)`, opacity: 0},
            {transform: 'translateY(0px)'},
            {opacity: 1}
        ];
        elm.animate(animation, {
            duration: appearAnimationMs,
            easing: 'ease-in-out',
        })
    }, [])


    useEffect(() => {
        if (isDisappear) disappear()
    }, [isDisappear])

    const handleClick = () => disappear()

    const disappear = () => {
        const elm = toastRef.current as HTMLDivElement
        elm.animate([
            {opacity: 1},
            {opacity: 0}
        ], {
            duration: disappearAnimationMs,
        })
        setTimeout(() => {
            onDisappear(id)
        }, disappearAnimationMs)
    }
    return (
        <div
            ref={toastRef}
            style={{
                ...style,
            }}
            onClick={handleClick}
        >
            {content}
        </div>
    )
}