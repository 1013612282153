import { observer } from "mobx-react";
import React from "react";
// @ts-ignore
import Wkt from "wicket";
import MapAreaPicker from "../components/MapAreaPicker/MapAreaPicker";
import { calcCenter } from "../utils/GeographyUtils";

declare var gon: any;

interface Props {
  areaWkt: string | null;
  comment: string | null;
  formInputName: string;
  columnName?: string;
}

interface State {
  areaWkt: string | null;
  comment: string;
}

class MapEditorInForm extends React.Component<Props, State> {
  wkt: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      areaWkt: props.areaWkt,
      comment: props.comment || "",
    };
    this.wkt = new Wkt.Wkt();
  }

  validateWktText = (wktText: string) => {
    try {
      this.wkt.read(wktText);
    } catch (error) {
      return false;
    }
    return true;
  };

  updateByWkt = (areaWkt: string) => {
    const isValid = this.validateWktText(areaWkt);
    return new Promise<void>((resolve, reject) => {
      try {
        if (!isValid) throw new Error("wktの形式が不正です");

        this.setState({ areaWkt: areaWkt });

        resolve();
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  resetArea = () => {
    this.setState({ areaWkt: null });
  };

  render() {
    const { areaWkt } = this.state;
    const { formInputName, columnName } = this.props;
    const center = calcCenter({
      areaWkts: areaWkt ? [areaWkt] : [],
    });
    return (
      <div className="d-flex flex-column w-100">
        <div className="w-100 d-flex flex-column" style={{ height: "700px" }}>
          <div className="mb-3">範囲</div>
          <input
            type="hidden"
            name={`${formInputName}[${columnName ?? "area_wkt"}]`}
            style={{ display: "none" }}
            onChange={() => {}}
            value={areaWkt || ""}
          ></input>
          <div className="w-100" style={{ height: "95%" }}>
            <MapAreaPicker
              zoom={13}
              gmapsApiKey={gon.google_api_key}
              center={center}
              unEditableWkts={[areaWkt]}
              wkt={areaWkt!}
              showToast={true}
              onUpdateByWkt={async (areaWkt) => {
                await this.updateByWkt(areaWkt);
              }}
              onDestroy={async () => this.resetArea()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(MapEditorInForm);
