import { observer } from "mobx-react";
import React from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import { axiosPost } from "../../utils/AxiosClient";

interface BaseTemplate {
  template_code: string;
  template_name: string;
  placeholder_code_map: { [key: string]: "common" | "with_st" | "without_st" };
  label_codes: string[];
}

interface StBranchTemplate extends BaseTemplate {
  exits_specified_time_branch: true;
  with_st_html: string;
  with_st_text: string;
  without_st_html: string;
  without_st_text: string;
}

interface CommonTemplate extends BaseTemplate {
  exits_specified_time_branch: false;
  html: string;
  text: string;
}

type Template = StBranchTemplate | CommonTemplate;

interface PreDeliveryNotificationMail {
  id: number | null;
  template_code: string | null;
  is_active: boolean;
  from_address: string | null;
  subject: string | null;
  scheduled_date: string | null;
  scheduled_time: string | null;
  placeholders: { [key: string]: string } | null;
}

interface Props {
  editable: boolean;
  templates: Template[];
  preDeliveryNotificationMail: PreDeliveryNotificationMail;
  allowedScheduledDates: { [key: string]: string };
  allowedScheduledTimes: string[];
}

interface State {
  formats: "html" | "text";
  isSpecifiedTime: boolean;
  selectedTemplateCode: string;
  fromAddress: string;
  isActive: boolean;
  subject: string;
  scheduledDate: string;
  scheduledTime: string;
  placeholders: { [key: string]: string };
  errors: { [key: string]: string };
}

class PreDeliveryNotificationMailForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { preDeliveryNotificationMail } = props;
    this.state = {
      formats: "html",
      isSpecifiedTime: true,
      selectedTemplateCode:
        preDeliveryNotificationMail.template_code || "all_default",
      fromAddress: preDeliveryNotificationMail.from_address || "",
      subject: preDeliveryNotificationMail.subject || "",
      scheduledDate:
        preDeliveryNotificationMail.scheduled_date ||
        Object.keys(this.props.allowedScheduledDates)[0],
      scheduledTime: preDeliveryNotificationMail.scheduled_time || "",
      placeholders: preDeliveryNotificationMail.placeholders || {},
      isActive: preDeliveryNotificationMail.is_active,
      errors: {},
    };
  }

  private escapeHTML(htmlStr: string) {
    return (
      htmlStr
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;")
        .replace(/\r\n|\n/g, "<br/>")
        // RegExpを利用して変換する際に$が入っていると置換元の文字列が表示されてしまうので、エスケープ
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp#static_properties
        .replace(/\$\&/g, "$$$$&")
        .replace(/\$\`/g, "$$$$`")
    );
  }

  private async handleSave() {
    this.setState({ errors: {} });

    if (
      this.props.allowedScheduledTimes.indexOf(this.state.scheduledTime) < 0
    ) {
      this.setState({
        errors: {
          scheduled_time: "配信時刻が不正な形式です。",
        },
      });
      return;
    }

    let id = this.props.preDeliveryNotificationMail.id;
    try {
      const params = {
        template_code: this.state.selectedTemplateCode,
        from_address: this.state.fromAddress,
        subject: this.state.subject,
        placeholders: this.state.placeholders,
        is_active: this.state.isActive,
        scheduled_date: this.state.scheduledDate,
        scheduled_time: this.state.scheduledTime,
      };

      if (id) {
        const response = await axiosPost.patch<{ message: string; id: number }>(
          `/api/pre_delivery_notification_mails/${id}`,
          params
        );
      } else {
        const response = await axiosPost.post<{ message: string; id: number }>(
          `/api/pre_delivery_notification_mails`,
          params
        );
        id = response.data.id;
      }

      location.href = `/pre_delivery_notification_mails/${id}`;
    } catch (error) {
      console.error(error);
      console.log(error.response?.data);
      let message = id ? "更新に失敗しました。" : "作成に失敗しました。";
      if (error.response?.data?.message) {
        message = error.response.data.message.join(", ");
      }

      toast.error(message, {
        autoClose: 1000,
        closeButton: false,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
        transition: Slide,
      });
    }
  }

  private getMailBody(selectedTemplate: Template) {
    if (selectedTemplate.exits_specified_time_branch) {
      if (this.state.isSpecifiedTime && this.state.formats == "html") {
        // 別タブに遷移させるようにしないと、iframeで実装している影響でそのiframe内のページが書き変わってしまうのでその対応
        // * ##.*## => 問い合わせページ用URL
        // * <%asm_group_unsubscribe_raw_url%>, <%asm_preferences_raw_url%> => SendGridの配信停止関連リンク
        return selectedTemplate.with_st_html.replace(
          /href=["'](##.*##|<%asm_group_unsubscribe_raw_url%>|<%asm_preferences_raw_url%>)["']/g,
          'href="https://example.com" target="_blank"'
        );
      } else if (!this.state.isSpecifiedTime && this.state.formats == "html") {
        return selectedTemplate.without_st_html.replace(
          /href=["'](##.*##|<%asm_group_unsubscribe_raw_url%>|<%asm_preferences_raw_url%>)["']/g,
          'href="https://example.com" target="_blank"'
        );
      } else if (this.state.isSpecifiedTime && this.state.formats == "text") {
        return selectedTemplate.with_st_text.replace(/\n/g, "<br>");
      } else {
        return selectedTemplate.without_st_text.replace(/\n/g, "<br>");
      }
    }

    if (this.state.formats == "html") {
      return selectedTemplate.html.replace(
        /href=["'](##.*##|<%asm_group_unsubscribe_raw_url%>|<%asm_preferences_raw_url%>)["']/g,
        'href="https://example.com" target="_blank"'
      );
    } else {
      return selectedTemplate.text.replace(/\n/g, "<br>");
    }
  }

  render() {
    const { editable, templates } = this.props;
    const selectedTemplate = templates.find(
      (template) => template.template_code == this.state.selectedTemplateCode
    );
    if (selectedTemplate == null) {
      return <div className="col-12 d-flex">テンプレートが存在しません。</div>;
    }

    let mailBody = this.getMailBody(selectedTemplate);
    for (const placeholderCode of Object.keys(
      selectedTemplate.placeholder_code_map
    )) {
      const placeholderVal = this.state.placeholders[placeholderCode];
      if (placeholderVal) {
        mailBody = mailBody.replace(
          new RegExp(`__${placeholderCode}__`, "g"),
          this.escapeHTML(placeholderVal)
        );
      }
    }

    const placeholderCodes = Object.keys(selectedTemplate.placeholder_code_map);

    return (
      <div className="col-12 d-flex px-0">
        <div className="col-6">
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <label
                  style={{ whiteSpace: "nowrap", margin: 0 }}
                  htmlFor="select-template-code"
                  className="col-4 pl-0"
                >
                  利用テンプレート
                </label>
                <select
                  id="select-template-code"
                  className="col-8 form-control"
                  defaultValue={this.state.selectedTemplateCode}
                  disabled={!editable}
                  onChange={(e) => {
                    this.setState({
                      selectedTemplateCode: e.target.value,
                      placeholders: {},
                    });
                  }}
                >
                  {templates.map((template) => {
                    return (
                      <option
                        key={template.template_code}
                        value={template.template_code}
                      >
                        {template.template_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="d-flex justify-content-between align-items-center mb-2">
                <label className="mb-0" htmlFor="checkbox-is_active">
                  有効・無効
                </label>
                <div className="custom-control custom-switch custom-switch-xl">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="checkbox-is_active"
                    checked={this.state.isActive}
                    disabled={!editable}
                    onChange={(e) => {
                      this.setState({ isActive: !this.state.isActive });
                    }}
                  />
                  <label
                    className="custom-control-label text-hide"
                    htmlFor="checkbox-is_active"
                  />
                </div>
              </div>

              <div className="d-flex flex-column mb-2">
                <div className="d-flex align-items-center mb-1">
                  <label
                    style={{ whiteSpace: "nowrap", margin: 0 }}
                    htmlFor="input-from-address"
                    className="col-4 pl-0"
                  >
                    送信元アドレス
                  </label>
                  <input
                    type="email"
                    id="input-from-address"
                    className="col-8 form-control"
                    value={this.state.fromAddress}
                    disabled={!editable}
                    onChange={(event) => {
                      this.setState({
                        fromAddress: event.target.value,
                      });
                    }}
                  />
                </div>

                <div style={{ fontSize: 12 }}>
                  <span>
                    ※ 未設定の場合はメールの送信元（From）が
                    support@anycarry.co.jp となります。
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center mb-2">
                <label
                  style={{ whiteSpace: "nowrap", margin: 0 }}
                  htmlFor="input-subject"
                  className="col-4 pl-0"
                >
                  件名
                </label>
                <input
                  type="text"
                  id="input-subject"
                  className="col-8 form-control"
                  value={this.state.subject}
                  disabled={!editable}
                  onChange={(event) => {
                    this.setState({
                      subject: event.target.value,
                    });
                  }}
                  required
                />
              </div>

              <div className="d-flex align-items-center mb-2">
                <label
                  style={{ whiteSpace: "nowrap", margin: 0 }}
                  htmlFor="select-scheduled_date"
                  className="col-4 pl-0"
                >
                  配信タイミング
                </label>
                <select
                  id="select-scheduled_date"
                  className="col-8 form-control"
                  defaultValue={this.state.scheduledDate}
                  disabled={!editable}
                  onChange={(e) => {
                    this.setState({
                      scheduledDate: e.target.value,
                    });
                  }}
                >
                  {Object.entries(this.props.allowedScheduledDates).map(
                    (entries) => (
                      <option key={entries[0]} value={entries[0]}>
                        {entries[1]}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className="d-flex flex-column mb-2">
                <div className="d-flex align-items-center">
                  <label
                    style={{ whiteSpace: "nowrap", margin: 0 }}
                    htmlFor="input-scheduled_time"
                    className="col-4 pl-0"
                  >
                    配信時刻
                  </label>
                  <input
                    type="time"
                    id="input-scheduled_time"
                    className="col-8 form-control"
                    min="06:00"
                    max="22:00"
                    list="input-scheduled_time-data"
                    required
                    defaultValue={this.state.scheduledTime}
                    disabled={!editable}
                    onChange={(e) => {
                      this.setState({
                        scheduledTime: e.target.value,
                      });
                    }}
                  />
                  <datalist id="input-scheduled_time-data">
                    {this.props.allowedScheduledTimes.map((timeLabel) => (
                      <option key={timeLabel} value={timeLabel}>
                        {timeLabel}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="d-flex justify-content-end">
                  {this.state.errors["scheduled_time"] && (
                    <span className="text-danger" style={{ fontSize: 14 }}>
                      {this.state.errors["scheduled_time"]}
                    </span>
                  )}
                </div>
              </div>

              {placeholderCodes.length > 0 && (
                <div className="d-flex flex-column">
                  <label
                    style={{ whiteSpace: "nowrap", margin: 0 }}
                    htmlFor="input-scheduled_time"
                    className="col-4 pl-0 mb-2"
                  >
                    プレースホルダー
                  </label>
                  <div className="d-flex flex-column ml-4">
                    {placeholderCodes.map((placeholderCode) => {
                      const branchType =
                        selectedTemplate.placeholder_code_map[placeholderCode];
                      return (
                        <div key={placeholderCode} className="mb-2">
                          <label
                            style={{ whiteSpace: "nowrap", margin: 0 }}
                            htmlFor={`textarea-${placeholderCode}`}
                          >
                            {placeholderCode}
                          </label>
                          {branchType == "common" ? null : (
                            <div>
                              <span
                                className="text-danger"
                                style={{ fontSize: 14 }}
                              >
                                ＊時間指定
                                {branchType == "with_st" ? "あり" : "なし"}のみ
                              </span>
                            </div>
                          )}
                          <textarea
                            id={`textarea-${placeholderCode}`}
                            className="form-control"
                            value={
                              this.state.placeholders[placeholderCode] || ""
                            }
                            disabled={!editable}
                            onChange={(e) => {
                              this.setState({
                                placeholders: {
                                  ...this.state.placeholders,
                                  [placeholderCode]: e.target.value,
                                },
                              });
                            }}
                            rows={3}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {editable && (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.handleSave();
                    }}
                  >
                    保存
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-6 px-0">
          <div className="card shadow mb-4">
            <div className="card-body">
              <div className="col-12 d-flex justify-content-end align-items-center mb-1">
                {selectedTemplate.exits_specified_time_branch && (
                  <div>
                    <input
                      type="checkbox"
                      id="checkbox-specified-time"
                      className="form-check-input"
                      defaultChecked={this.state.isSpecifiedTime}
                      onClick={(e) => {
                        this.setState({
                          isSpecifiedTime: !this.state.isSpecifiedTime,
                        });
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="checkbox-specified-time"
                    >
                      時間指定あり
                    </label>
                  </div>
                )}

                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({
                      formats: this.state.formats == "html" ? "text" : "html",
                    });
                  }}
                >
                  {this.state.formats == "html" ? "テキスト表示" : "HTML表示"}
                </button>
              </div>
              <div
                className="overflow-auto"
                style={{ height: "calc(100vh - 4rem)", color: "black" }}
              >
                <iframe
                  srcDoc={mailBody}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: "none" }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    );
  }
}

export default observer(PreDeliveryNotificationMailForm);
