import React from "react";

interface Props {
    durationMs?: number
    size?: number
    hexColor?: string
}
export class PinPulse extends React.Component<Props> {
    render() {
        const {
            durationMs = 1500,
            size = 20,
            hexColor = "#4285F4",
        } = this.props
        const rgbColor = this.hex2rgb(hexColor)
     return (
         <div className="map-marker-pin--pulse" style={{
             pointerEvents: "none"
         }}>
             <style>
                 {`
                    @keyframes pulse {
                    0% { transform: scale(0); opacity: 0 }
                    50% { transform: scale(1.5); opacity: 1 }        
                    100% { transform: scale(3); opacity: 0 }
                    }
                    `}
             </style>
             <div style={{
                 transform: `translate(-${size / 2}px, -${size / 2}px)`,
                 position: "absolute",
                 left: 0,
                 top: 0,
             }}>
                 <div style={{
                     background: `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.2)`,
                     border: `solid 1px rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, 0.5)`,
                     width: size,
                     height: size,
                     borderRadius: "100%",
                     animation: `pulse infinite ${durationMs}ms linear`,
                 }}>
                 </div>
            </div>
         </div>
     )
    }
    private hex2rgb ( hex: string ) {
        if ( hex.slice(0, 1) === "#" ) hex = hex.slice(1) ;
        if ( hex.length === 3 ) hex = hex.slice(0,1) + hex.slice(0,1) + hex.slice(1,2) + hex.slice(1,2) + hex.slice(2,3) + hex.slice(2,3) ;

        return [ hex.slice( 0, 2 ), hex.slice( 2, 4 ), hex.slice( 4, 6 ) ].map( function ( str ) {
            return parseInt( str, 16 ) ;
        } ) ;
    }
}