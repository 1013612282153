import { format } from "date-fns";
import { getPreciseDistance } from "geolib";
import { isNeedPickup } from "../constants/Request";
import {
  DestinationType,
  RawCarryStaffRequestSequence as _RawCarryStaffRequestSequence,
  RawCarryStaffSchedule,
  RawRequest,
} from "../interfaces/entities";
import { axiosGet } from "./AxiosClient";
import { isSameDate } from "./time-utils";

type RawCarryStaffRequestSequence = Pick<
  _RawCarryStaffRequestSequence,
  | "id"
  | "request_id"
  | "destination_type"
  | "sequence"
  | "estimated_delivery_time"
  | "work_start_time"
>;

export type RequestSequence<ReqType extends RawRequest> = Omit<
  RawCarryStaffRequestSequence,
  "id"
> & {
  // ソート管理用のID {requests.id}_{'sender'|'receiver'} という形式
  id: string;
  // carry_staff_request_sequencesテーブル上でのID
  sequence_id: number | null;
  // carry_staff_request_sequencesテーブル上でのsequence
  org_sequence: number | null;
  is_disallowed: boolean;
  is_outside_schedule: boolean;
  request: ReqType;
};

type RequestChangeSummary = {
  id: number;
  request_id: number;
  recieved_at: string;
  delivered_at: string;
};

export async function loadSequences(params: {
  targetDate: string;
  carryStaffId: number;
}) {
  const urlParams = new URLSearchParams([
    ["target_date", params.targetDate],
    ["carry_staff_id", `${params.carryStaffId}`],
  ]);
  const response = (await axiosGet.get(
    `/api/carry_staff_request_sequences?${urlParams.toString()}`
  )) as {
    data: {
      sequences: RawCarryStaffRequestSequence[];
    };
  };
  return response;
}

export async function loadRequestsAndSchedules(params: {
  targetFromDate: string | null;
  targetToDate: string;
  carryStaffId: number;
}) {
  let urlParams = new URLSearchParams([
    ["target_to_date", params.targetToDate],
    ["carry_staff_id", `${params.carryStaffId}`],
  ]);
  if (params.targetFromDate) {
    urlParams = new URLSearchParams([
      ["target_from_date", params.targetFromDate],
      ["target_to_date", params.targetToDate],
      ["carry_staff_id", `${params.carryStaffId}`],
    ]);
  }

  const response = (await axiosGet.get(
    `/api/carry_staff_request_sequences/requests_and_schedules?${urlParams.toString()}`
  )) as {
    data: {
      requests: RawRequest[];
      carry_staff_schedules: RawCarryStaffSchedule[];
    };
  };
  return response;
}

export async function loadRequestChangeSummaries(params: {
  targetDate: string;
  requestIds: number[];
}) {
  const urlParams = new URLSearchParams([
    ["target_date", params.targetDate],
    ["request_ids", `${params.requestIds}`],
  ]);

  const response = (await axiosGet.get(
    `/api/carry_staff_request_sequences/request_change_summaries?${urlParams.toString()}`
  )) as {
    data: {
      request_change_summaries: RequestChangeSummary[];
    };
  };
  return response;
}

/**
 * 取得したサマリーを元に実際の配達順シーケンスを生成する
 * @param requests
 * @param requestChangeSummaries
 * @returns
 */
export function createSequencesFromSummaries<ReqType extends RawRequest>(
  requests: ReqType[],
  requestChangeSummaries: RequestChangeSummary[],
  targetDate: Date
): RequestSequence<ReqType>[] {
  let actualSequences: {
    requestId: number;
    destinationType: "sender" | "receiver";
    timeAt: Date;
  }[] = [];
  // サマリーレコードをsender, receiverデータに分割
  requestChangeSummaries.forEach((summary) => {
    if (summary.recieved_at) {
      const senderDate = new Date(summary.recieved_at);
      if (isSameDate(senderDate, targetDate)) {
        actualSequences.push({
          requestId: summary.request_id,
          destinationType: "sender",
          timeAt: senderDate,
        });
      }
    }
    if (summary.delivered_at) {
      const receiverDate = new Date(summary.delivered_at);
      if (isSameDate(receiverDate, targetDate)) {
        actualSequences.push({
          requestId: summary.request_id,
          destinationType: "receiver",
          timeAt: receiverDate,
        });
      }
    }
  });

  // 分割したデータを時刻順で並び変える
  actualSequences.sort((a, b) => a.timeAt.getTime() - b.timeAt.getTime());

  let sequences: RequestSequence<ReqType>[] = [];
  // シーケンスの型に変換
  actualSequences.forEach((actualSeq, index) => {
    const req = requests.find((req) => req.id === actualSeq.requestId);
    if (req) {
      const sequence = createRequestSequencesFromRequest(
        req,
        actualSeq.destinationType
      )[0];
      sequence.sequence = index + 1;

      // サマリー由来の実際の配達順データの場合、
      // リストアイテムの日時表示はrecieved_atまたはdelivered_atの値を使いたいので
      // 依頼のreceiver_time_at, delivery_time_atに持たせておいてラベル表示で参照できるように
      const formattedTimeAt = format(
        actualSeq.timeAt,
        "yyyy-MM-dd'T'HH:mm:ss.SSS+09:00"
      );
      if (sequence.destination_type === "sender") {
        sequence.request.ready_time_at = formattedTimeAt;
      } else if (sequence.destination_type === "receiver") {
        sequence.request.delivery_time_at = formattedTimeAt;
      }
      sequences.push(sequence);
    }
  });
  return sequences;
}

/**
 * リクエストとシーケンスをマージする
 * @param requests
 * @param sequences
 */
export function mergeRequestsAndSequences<ReqType extends RawRequest>({
  requests,
  sequences,
  isPast = false,
}: {
  requests: ReqType[];
  sequences: RawCarryStaffRequestSequence[];
  isPast?: boolean;
}): RequestSequence<ReqType>[] {
  const defaultSequences = requests.flatMap((request) =>
    createDefaultSequenceForRequest(request, isPast)
  );
  const mergedSequences = mergeDefaultAndExistingSequences(
    defaultSequences,
    sequences,
    isPast ? "existing" : "default"
  );
  return sortAndRenumberSequences(mergedSequences);
}

/**
 * リクエストからデフォルトシーケンスを生成する
 * @param req
 */
function createDefaultSequenceForRequest<ReqType extends RawRequest>(
  req: ReqType,
  isPast: boolean
): RequestSequence<ReqType>[] {
  const status = req.status;
  const isExcludedStatus = [
    "delivered",
    "failed",
    "cancel",
    "store_cancel",
  ].includes(status);

  if (isPast && status === "delivered") {
    return createRequestSequencesFromRequest(req, "both");
  } else if (isPast || isExcludedStatus) {
    return [];
  } else if (isNeedPickup(status)) {
    return createRequestSequencesFromRequest(req, "both");
  } else {
    return createRequestSequencesFromRequest(req, "receiver");
  }
}

function mergeDefaultAndExistingSequences<ReqType extends RawRequest>(
  defaultSequences: RequestSequence<ReqType>[],
  existingSequences: RawCarryStaffRequestSequence[],
  useAsBase: "default" | "existing"
): RequestSequence<ReqType>[] {
  // 登録済みのシーケンスと一致するデフォルトシーケンスを取得
  const findMatchingSequence = (seq: RawCarryStaffRequestSequence) =>
    defaultSequences.find(
      (_seq) =>
        _seq.request_id == seq.request_id &&
        _seq.destination_type == seq.destination_type
    );

  // RequestSequence<ReqType>型のオブジェクトを生成
  // 登録済みのシーケンスがある場合はその情報を使い、ない場合はデフォルトの情報を使う
  const buildSequenceObject = (
    defaultSeq: RequestSequence<ReqType>,
    existingSeq?: RawCarryStaffRequestSequence
  ) => {
    if (existingSeq) {
      return {
        ...existingSeq,
        id: `${existingSeq.request_id}_${existingSeq.destination_type}`,
        sequence_id: existingSeq.id,
        org_sequence: existingSeq.sequence,
        request: defaultSeq.request,
        is_disallowed: false,
        is_outside_schedule: false,
      };
    } else {
      return { ...defaultSeq };
    }
  };

  if (useAsBase == "existing") {
    // 過去データの場合は、登録済みのシーケンスをベースに生成する
    // 登録済みのシーケンスがない場合は何も表示しない
    const sequenceObjectFotPast =
      existingSequences.length > 0
        ? existingSequences
            .map((existingSeq) => {
              const seq = findMatchingSequence(existingSeq);
              return seq ? buildSequenceObject(seq, existingSeq) : null;
            })
            .filter((seq): seq is RequestSequence<ReqType> => seq !== null)
        : [];
    return sequenceObjectFotPast;
  } else {
    // 実行日以降のデータの場合は、デフォルトシーケンスをベースに
    // 登録済みのシーケンスをマージして生成する
    return defaultSequences.map((defaultSeq) => {
      const existingSeq = existingSequences.find(
        (_seq) =>
          _seq.request_id == defaultSeq.request_id &&
          _seq.destination_type == defaultSeq.destination_type
      );
      return buildSequenceObject(defaultSeq, existingSeq);
    });
  }
}

/**
 * マージされたシーケンスをソートし、新しい番号を割り当てる関数
 * @param sequences
 */
function sortAndRenumberSequences<ReqType extends RawRequest>(
  sequences: RequestSequence<ReqType>[]
): RequestSequence<ReqType>[] {
  const sortedAndMergedSequences = sequences.sort((a, b) => {
    // 正なら b <- a
    // 負なら a <- b
    // 0なら そのまま
    if (a.sequence_id != null && b.sequence_id != null) {
      // 両方とも登録済みの場合
      if (a.sequence == b.sequence) {
        // かつ順番まで同じ場合
        // 一旦そのまま
        return 0;
      }

      return a.sequence < b.sequence ? -1 : 1;
    } else if (a.sequence_id == null && b.sequence_id == null) {
      // 両方とも未登録(DB上にデータが存在しない)の場合
      // 一旦対象地点の時間順で並べる
      const aTimeAt =
        a.destination_type == "sender"
          ? a.request.ready_time_at
          : a.request.delivery_time_at;
      const bTimeAt =
        b.destination_type == "sender"
          ? b.request.ready_time_at
          : b.request.delivery_time_at;
      if (aTimeAt == bTimeAt) {
        //　ないと思うけど
        return 0;
      }

      return aTimeAt < bTimeAt ? -1 : 1;
    } else {
      // aが未登録で、bが登録済みの場合 1
      // aが登録済みで、bが未登録の場合 -1
      return a.sequence_id == null ? 1 : -1;
    }
  });

  return sortedAndMergedSequences.map((seq, index) => ({
    ...seq,
    sequence: index + 1,
  }));
}

/**
 * ReqType型をRequestSequence型に変換する関数
 * @param req
 * @param type
 * @returns
 */
function createRequestSequencesFromRequest<ReqType extends RawRequest>(
  req: ReqType,
  type: "sender" | "receiver"
): [RequestSequence<ReqType>];
function createRequestSequencesFromRequest<ReqType extends RawRequest>(
  req: ReqType,
  type: "both"
): [RequestSequence<ReqType>, RequestSequence<ReqType>];
function createRequestSequencesFromRequest<ReqType extends RawRequest>(
  req: ReqType,
  type: "sender" | "receiver" | "both"
): RequestSequence<ReqType>[] {
  const commonParams = {
    sequence_id: null,
    org_sequence: null,
    sequence: -1,
    request_id: req.id,
    request: req,
    is_disallowed: false,
    is_outside_schedule: false,
    estimated_delivery_time: null,
    work_start_time: null,
  };
  if (type === "sender") {
    return [
      {
        id: `${req.id}_sender`,
        destination_type: "sender" as DestinationType,
        ...commonParams,
      },
    ];
  } else if (type === "receiver") {
    return [
      {
        id: `${req.id}_receiver`,
        destination_type: "receiver" as DestinationType,
        ...commonParams,
      },
    ];
  } else {
    return [
      {
        id: `${req.id}_sender`,
        destination_type: "sender" as DestinationType,
        ...commonParams,
      },
      {
        id: `${req.id}_receiver`,
        destination_type: "receiver" as DestinationType,
        ...commonParams,
      },
    ];
  }
}

interface Point {
  destination_type: DestinationType;
  request: RawRequest;
}

export function createEdgePointPairs({
  sequences,
}: {
  sequences: RequestSequence<RawRequest>[];
}) {
  let fromPoint: Point | undefined;
  let lines: { from: Point; to: Point; distance: number }[] = [];
  for (const sequence of sequences) {
    const request = sequence.request;

    if (!fromPoint) {
      fromPoint = {
        destination_type: sequence.destination_type,
        request: request,
      };
      continue;
    }

    const toPoint = {
      destination_type: sequence.destination_type,
      request: request,
    };
    lines.push({
      from: {
        ...fromPoint,
      },
      to: {
        ...toPoint,
      },
      // 一応距離を保存
      // 一定距離内のものには矢印を表示しない(みづらくなるから)というのを想定
      distance: getPreciseDistance(
        getPointLatLng(fromPoint),
        getPointLatLng(toPoint)
      ),
    });

    fromPoint = toPoint;
  }
  return lines;
}

function getPointLatLng(point: Point) {
  if (point.destination_type == "sender") {
    return {
      lat: point.request.sender_lat,
      lng: point.request.sender_lng,
    };
  }

  return {
    lat: point.request.receiver_lat,
    lng: point.request.receiver_lng,
  };
}
