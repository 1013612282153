import { Coords } from "google-map-react";
import { observer } from "mobx-react";
import React from "react";
import Card from "react-bootstrap/Card";
// @ts-ignore
import Wkt from "wicket";
import GoogleMap from "../components/Common/GoogleMap";
import MapAttributes from "../constants/MapAttributes";
import { axiosGet } from "../utils/AxiosClient";
import Polyline from "./Polyline";
import WktMultiPolygon from "./WktMultiPolygon";

declare var gon: any;

interface OtherDeliveryArea {
  id: number;
  sender_area_wkt: string;
  center_sender_area_wkt: string;
  receiver_area_wkt: string;
  center_receiver_area_wkt: string;
  comment: string | null;
}

interface Props {
  vendorCompanyId: number;
  // 初期の地図の中央をどこにするかだけのプロパティ
  center: {
    lat: number;
    lng: number;
  };
  otherDeliveryAreas: OtherDeliveryArea[];
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  otherDeliveryAreas: OtherDeliveryArea[];
  showInfoWindow: boolean;
  comment: string | null;
}

class DeliveryAreasMap extends React.Component<Props, State> {
  wkt: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      otherDeliveryAreas: props.otherDeliveryAreas,
      showInfoWindow: false,
      comment: null,
    };
    this.wkt = new Wkt.Wkt();
  }

  componentDidMount(): void {}

  private onClickArea(otherDelveryId: number) {
    location.href = `/vendor_companies/${this.props.vendorCompanyId}/vendor_company_other_delivery_areas/${otherDelveryId}/edit`;
  }

  private async getDeliveryAreas(
    ne: Coords,
    se: Coords,
    sw: Coords,
    nw: Coords
  ) {
    // 不正な値が入っている場合は何もしない
    const coords = [ne, se, sw, nw];
    for (const coord of coords) {
      if (!coord.lat || !coord.lng) return;
    }

    const latLngs = [
      ["neLng", ne.lng],
      ["neLat", ne.lat],
      ["seLng", se.lng],
      ["seLat", se.lat],
      ["swLng", sw.lng],
      ["swLat", sw.lat],
      ["nwLng", nw.lng],
      ["nwLat", nw.lat],
    ] as string[][];
    const params = new URLSearchParams(latLngs);
    const response = (await axiosGet.get(
      `/api/vendor_companies/${
        this.props.vendorCompanyId
      }/other_delivery_areas?${params.toString()}`
    )) as { data: { otherDeliveryAreas: OtherDeliveryArea[] } };

    this.setState({ otherDeliveryAreas: response.data.otherDeliveryAreas });
  }

  render() {
    const { center } = this.props;
    return (
      <div className={"request-map-container"}>
        <GoogleMap
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          defaultCenter={center}
          defaultZoom={14}
          center={center}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) =>
            this.setState({
              map: map,
              mapApi: maps,
              mapLoaded: true,
            })
          }
          onChange={async (value) => {
            await this.getDeliveryAreas(
              value.bounds.ne,
              value.bounds.se,
              value.bounds.sw,
              value.bounds.nw
            );
          }}
        >
          {this.state.mapApi &&
            this.state.otherDeliveryAreas.map((deliveryArea, i, _) => {
              const senderCenter = this.wkt
                .read(deliveryArea.center_sender_area_wkt)
                .toJson();
              const receiverCenter = this.wkt
                .read(deliveryArea.center_receiver_area_wkt)
                .toJson();
              return (
                <React.Fragment key={`sender_receiver_${i}`}>
                  <WktMultiPolygon
                    key={`sender_${i}`}
                    areaKey={`sender_${i}`}
                    map={this.state.map}
                    mapApi={this.state.mapApi}
                    wktText={deliveryArea.sender_area_wkt}
                    strokeColor="#32CD32"
                    fillColor="#32CD32"
                    fillOpacity={0.4}
                    onClickPolygon={() => this.onClickArea(deliveryArea.id)}
                    onMouseover={() =>
                      this.setState({
                        // @ts-ignore TODO: boolean型にnullや''が入り得る状態
                        showInfoWindow:
                          deliveryArea.comment &&
                          deliveryArea.comment.length > 0,
                        comment: deliveryArea.comment,
                      })
                    }
                    onMouseout={() =>
                      this.setState({
                        showInfoWindow: false,
                        comment: null,
                      })
                    }
                  />
                  <WktMultiPolygon
                    key={`receiver_${i}`}
                    areaKey={`receiver_${i}`}
                    map={this.state.map}
                    mapApi={this.state.mapApi}
                    wktText={deliveryArea.receiver_area_wkt}
                    strokeColor="#FF8C00"
                    fillColor="#FF8C00"
                    fillOpacity={0.4}
                    onClickPolygon={() => this.onClickArea(deliveryArea.id)}
                    onMouseover={() =>
                      this.setState({
                        // @ts-ignore TODO: boolean型にnullや''が入り得る状態
                        showInfoWindow:
                          deliveryArea.comment &&
                          deliveryArea.comment.length > 0,
                        comment: deliveryArea.comment,
                      })
                    }
                    onMouseout={() =>
                      this.setState({
                        showInfoWindow: false,
                        comment: null,
                      })
                    }
                  />
                  <Polyline
                    map={this.state.map}
                    mapApi={this.state.mapApi}
                    locations={[
                      {
                        lat: senderCenter.coordinates[1],
                        lng: senderCenter.coordinates[0],
                      },
                      {
                        lat: receiverCenter.coordinates[1],
                        lng: receiverCenter.coordinates[0],
                      },
                    ]}
                    addArrow={true}
                    zIndex={-1}
                  />
                </React.Fragment>
              );
            })}
        </GoogleMap>
        {this.state.showInfoWindow && (
          <Card style={infoWindowStyle}>{this.state.comment}</Card>
        )}
      </div>
    );
  }
}

const infoWindowStyle: React.CSSProperties = {
  position: "absolute",
  top: 10,
  right: 100,
  width: 200,
  height: "auto",
  textAlign: "center",
  fontSize: 14,
  fontWeight: "normal",
  padding: "10px",
};

export default observer(DeliveryAreasMap);
