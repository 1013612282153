import React from "react";

interface Props {
  editMode: null | "Polygon";
  onChangeEditMode: (mode: null | "Polygon") => void;
  onDeletePaths: () => void;
  map?: google.maps.Map;
}

export default function MAPToolboxContentForPaths(props: Props) {
  const { editMode, onChangeEditMode, map, onDeletePaths } = props;

  const handleChangeButton = (value: null | "Polygon") => {
    if (!map) return;
    map.data.setDrawingMode(value);
    onChangeEditMode(value);
  };

  return (
    <div>
      <button
        type="button"
        className={`btn btn-light d-flex mb-1 ${
          editMode === "Polygon" ? "active" : ""
        }`}
        style={{ width: "100%" }}
        onClick={() => handleChangeButton("Polygon")}
      >
        <div
          className="mr-1"
          style={{
            height: 16,
            width: 16,
            overflow: "hidden",
            marginTop: 2
          }}
        >
          <img
            src="https://maps.gstatic.com/mapfiles/drawing.png"
            style={{ transform: "translateY(-64px)", display: "block" }}
          />
        </div>
        <div>ポリゴンを追加</div>
      </button>
      <button
        type="button"
        className={`btn btn-light d-flex mb-1 ${
          editMode === null ? "active" : ""
        }`}
        style={{ width: "100%" }}
        onClick={() => handleChangeButton(null)}
      >
        <div
          className="mr-1"
          style={{
            height: 16,
            width: 16,
            overflow: "hidden",
            marginTop: 2
          }}
        >
          <img
            src="https://maps.gstatic.com/mapfiles/drawing.png"
            style={{ transform: "translateY(-80px)", display: "block" }}
          />
        </div>
        <div>パスを調整</div>
      </button>
      <hr style={{ borderWidth: 1, borderBottom: "none" }} className={"my-2"} />
      <button
        type="button"
        className={`btn btn-light d-flex`}
        style={{ width: "100%" }}
        onClick={onDeletePaths}
      >
        全てのパスを消去
      </button>
    </div>
  );
}
