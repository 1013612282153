import {
  addMinutes,
  setMinutes,
  setSeconds,
  setMilliseconds,
  format,
} from "date-fns";
import { ChangeEventValue, Coords } from "google-map-react";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import TimePicker from "react-bootstrap-time-picker";
import { Slide, ToastContainer, toast } from "react-toastify";
import DateSelection from "../../components/Common/DateSelection";
import GoogleMap from "../../components/Common/GoogleMap";
import MapControl from "../../components/ui/GmapsUI/controls/MapControl";
import WktMultiPolygon from "../../components/WktMultiPolygon";
import MapAttributes from "../../constants/MapAttributes";
import { H3IndexModel } from "../../models/H3IndexModel";
import deaasOverlookStore from "../../stores/DeaasAreaOverlookStore";
import { axiosPost } from "../../utils/AxiosClient";
import { getMapDefaultValueFunctions } from "../../utils/MapSettingStorageUtils";

declare var gon: any;

interface Props {
  canUpdate: boolean;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  center: {
    lat: number;
    lng: number;
  };
  vertices: {
    northEast: Coords;
    southEast: Coords;
    southWest: Coords;
    northWest: Coords;
  } | null;
  defaultZoom: number;
  loadingServiceArea: boolean;
  selectedDate: string;
  formFromTime: string;
  formToTime: string;
  minPickupMinutes: number;
  mouseoverH3Index: H3IndexModel | null;
  selectedH3Indices: string[];
  isModalOpen: boolean;
  selectedH3Index: H3IndexModel | null;
  zoom: number;
}

const hexColors = {
  selected: {
    fill: "#808080",
    stroke: "#232323",
  },
  existed: {
    fill: "#ff982a",
    stroke: "#ff982a",
  },
  default: {
    fill: "#79baff",
    stroke: "#79baff",
  },
};

const INTERVAL_MINUTES = 5;
const MIN_HOURS = 8;

class H3MinPickupMinutesSettingsBulkEdit extends React.Component<Props, State> {
  mapFuncs: ReturnType<typeof getMapDefaultValueFunctions>;

  constructor(props: Props) {
    super(props);
    this.mapFuncs = getMapDefaultValueFunctions(
      MapAttributes.LOCAL_STRAGE_BASE_KEY_H3_MIN_PICKUP_MINUTES_INDEX_SETTINGS
    );
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      center: {
        lat: this.mapFuncs.getDefaultCenterLat(),
        lng: this.mapFuncs.getDefaultCenterLng(),
      },
      vertices: null,
      defaultZoom: this.mapFuncs.getDefaultZoom(),
      loadingServiceArea: false,
      selectedDate: format(new Date(), "yyyy-MM-dd"),
      formFromTime: this.getNearTime(),
      formToTime: this.getNearTime(1),
      minPickupMinutes: 15,
      mouseoverH3Index: null,
      selectedH3Indices: [],
      isModalOpen: false,
      selectedH3Index: null,
      zoom: this.mapFuncs.getDefaultZoom(),
    };
  }

  componentDidMount() {}

  public async updateMinPickupMinutes() {
    let options = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
    };

    if (this.state.selectedH3Indices.length === 0) {
      // 来ないはずだけど念の為
      options["type"] = toast.TYPE.WARNING;
      toast.error("エリアが選択されていません", options);
      return;
    }

    this.setState({ loadingServiceArea: true });
    try {
      // エラーにならないということは200ということなので、responseは無視
      const response = (await axiosPost.post(
        "/api/h3_min_pickup_minutes_settings/bulk_update",
        {
          h3_indices: this.state.selectedH3Indices,
          target_date: this.state.selectedDate,
          from_time: this.state.formFromTime,
          to_time: this.state.formToTime,
          min_pickup_minutes: this.state.minPickupMinutes,
        }
      )) as { status: number; data: { message: string } };
      await this.loadServiceAreasAndH3Indices();

      this.setState({
        selectedH3Indices: [],
        minPickupMinutes: 15,
      });
      toast.success("更新しました", options);
    } catch (e) {
      options["type"] = toast.TYPE.ERROR;
      toast.error("更新に失敗しました", options);
    }

    this.setState({ loadingServiceArea: false });
  }

  private async loadServiceAreasAndH3Indices() {
    const vertices = this.state.vertices;
    if (vertices) {
      await deaasOverlookStore.loadServiceAreasInBoundsWithHex(
        vertices.northEast,
        vertices.southEast,
        vertices.southWest,
        vertices.northWest,
        this.state.center.lat,
        this.state.center.lng,
        ["min_pickup_minutes_settings"],
        this.state.zoom,
        { datetime: new Date(`${this.state.selectedDate} 00:00:00+09:00`) }
      );
    }
  }

  private selectH3Index(h3Index: string) {
    const targetIndex = this.state.selectedH3Indices.findIndex(
      (index) => index === h3Index
    );
    if (targetIndex < 0) {
      this.setState({
        selectedH3Indices: this.state.selectedH3Indices.concat([h3Index]),
      });
    } else {
      this.state.selectedH3Indices.splice(targetIndex, 1);
      this.setState({
        selectedH3Indices: [...this.state.selectedH3Indices],
      });
    }
  }

  private async onChangeMap(e: ChangeEventValue) {
    this.setState(
      {
        vertices: {
          northEast: e.bounds.ne,
          southEast: e.bounds.se,
          southWest: e.bounds.sw,
          northWest: e.bounds.nw,
        },
        zoom: e.zoom
      },
      async () => await this.loadServiceAreasAndH3Indices()
    );
    this.mapFuncs.setCurrentLatLngAndZoomToLocalStrage(
      e.center.lat,
      e.center.lng,
      e.zoom
    );
  }

  private createServiceAreaPolygon() {
    if (_.isEmpty(this.state.map)) return null;

    return _.map(
      deaasOverlookStore.visibleServiceAreas.slice(),
      (visibleServiceArea) => {
        if (!visibleServiceArea.visible) return;

        const { model } = visibleServiceArea;
        return (
          <WktMultiPolygon
            key={`service_area_${model.id}`}
            map={this.state.map}
            mapApi={this.state.mapApi}
            wktText={model.withinAreaWkt}
            strokeColor={"#333631"}
            fillOpacity={0}
            zIndex={-1}
          />
        );
      }
    );
  }

  private getH3IndexStyle(h3Index: H3IndexModel) {
    const selectedIndex = this.state.selectedH3Indices.findIndex(
      (idx) => idx === h3Index.h3Index
    );
    if (selectedIndex >= 0) {
      return {
        isSelected: true,
        fillColor: hexColors.selected.fill,
        strokeColor: hexColors.selected.stroke,
        strokeWidth: 3,
        zIndex: 10,
      };
    }

    // 設定がある部分はオレンジ色
    if (h3Index.minPickupMinutesSettings.length > 0) {
      return {
        isSelected: false,
        fillColor: hexColors.existed.fill,
        strokeColor: hexColors.existed.stroke,
        strokeWidth: 2,
        zIndex: 5,
      };
    }

    // 基本青色
    return {
      isSelected: false,
      fillColor: hexColors.default.fill,
      strokeColor: hexColors.default.stroke,
      strokeWidth: 2,
      zIndex: 1,
    };
  }

  private createH3IndicesPolygon() {
    if (_.isEmpty(this.state.map)) return null;

    return _.map(deaasOverlookStore.intersectH3Indices.slice(), (h3Index) => {
      const h3IndexStyle = this.getH3IndexStyle(h3Index);
      return (
        <WktMultiPolygon
          key={`h3_index_${h3Index.id}`}
          zIndex={h3IndexStyle.zIndex}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={h3Index.h3AreaWkt}
          fillColor={h3IndexStyle.fillColor}
          strokeColor={h3IndexStyle.strokeColor}
          strokeWeight={h3IndexStyle.strokeWidth}
          onMouseover={() => {
            // ここで比較して同じ場合にはsetStateを実行しないようにしておかないと、
            // ポリゴン上でマウスを動かすたびにsetStateが実行され、結果全WktMultiPolygonの再描画が走ってしまう
            if (h3Index !== this.state.mouseoverH3Index) {
              this.setState({ mouseoverH3Index: h3Index });
            }
          }}
          onClickPolygon={() => {
            this.selectH3Index(h3Index.h3Index);
          }}
          onRightClickPolygon={() => {
            this.handleRightClick(h3Index);
          }}
        />
      );
    });
  }

  private handleRightClick(h3Index: H3IndexModel) {
    this.setState({
      isModalOpen: true,
      selectedH3Index: h3Index,
    });
  }

  private renderColorPalette() {
    return (
      <div
        className="mb-3 d-flex flex-column justify-content-center align-items-start"
        style={{ width: "100%" }}
      >
        <div>カラーパレット</div>
        <div className="ml-3 d-flex flex-column justify-content-center align-items-start">
          <div className="d-flex align-items-center">
            <span className="mr-3">選択済み</span>
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: hexColors.selected.fill,
              }}
            ></div>
          </div>

          <div className="d-flex align-items-center">
            <span className="mr-3">設定あり</span>
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: hexColors.existed.fill,
              }}
            ></div>
          </div>

          <div className="d-flex align-items-center">
            <span className="mr-3">設定なし</span>
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: hexColors.default.fill,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  getNearTime(step = 0) {
    let now = new Date();
    if (now.getHours() >= 23) {
      return "23:55";
    } else if (now.getHours() < MIN_HOURS) {
      return ("00" + MIN_HOURS).slice(-2) + ":00";
    }

    const currentMinutes = now.getMinutes();
    const adjustedMinutes =
      Math.floor(currentMinutes / INTERVAL_MINUTES) * INTERVAL_MINUTES;
    // INTERVAL_MINUTES分刻みで揃えられた基準時間
    const nearTime = setMilliseconds(
      setSeconds(setMinutes(now, adjustedMinutes), 0),
      0
    );
    const responseTime = addMinutes(nearTime, INTERVAL_MINUTES * step);
    return format(responseTime, "HH:mm");
  }

  private convertToStr(timePickerTime: number) {
    const hours = Math.floor(timePickerTime / 3600);
    const minutes = Math.floor((timePickerTime - hours * 3600) / 60);
    return ("00" + hours).slice(-2) + ":" + ("00" + minutes).slice(-2);
  }

  private renderForm() {
    // クラス名による指定では".form-group lavel"の方が強く、きれいにならないので、
    // スタイル直接指定スタイル
    const formLabelWidth = "200px";
    // 未来の日付の場合は、すべての時間を選択できて良い
    // 当日の場合は、現在時刻以降の時刻のみを選択肢として用意
    const isToday = this.state.selectedDate == format(new Date(), "yyyy-MM-dd");
    const fromDefault = isToday
      ? this.getNearTime()
      : ("00" + MIN_HOURS).slice(-2) + ":00";
    const toDefault = isToday
      ? this.getNearTime(1)
      : ("00" + MIN_HOURS).slice(-2) + ":00";
    const isNotSelectedH3 = this.state.selectedH3Indices.length === 0;
    const isBeforeToday =
      this.state.selectedDate < format(new Date(), "yyyy-MM-dd");
    return (
      <Form>
        <Form.Group className="mb-3" controlId="formFromTime">
          <Form.Label style={{ width: formLabelWidth }}>日付</Form.Label>
          <DateSelection
            date={new Date(`${this.state.selectedDate} 00:00:00+09:00`)}
            onChangeDate={(newDate) => {
              this.setState(
                { selectedDate: format(newDate, "yyyy-MM-dd") },
                async () => await this.loadServiceAreasAndH3Indices()
              );
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formFromTime">
          <Form.Label style={{ width: formLabelWidth }}>開始時刻</Form.Label>
          <TimePicker
            start={fromDefault}
            end="23:50"
            step={INTERVAL_MINUTES}
            format={24}
            value={this.state.formFromTime}
            onChange={(v: number) => {
              this.setState({ formFromTime: this.convertToStr(v) });
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formToTime">
          <Form.Label style={{ width: formLabelWidth }}>終了時刻</Form.Label>
          <TimePicker
            start={toDefault}
            end="23:55"
            step={INTERVAL_MINUTES}
            format={24}
            value={this.state.formToTime}
            onChange={(v: number) => {
              this.setState({ formToTime: this.convertToStr(v) });
            }}
          />
          {this.state.formFromTime >= this.state.formToTime && (
            <Form.Text className="text-danger">不正な範囲です</Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRequiredMinutes">
          <Form.Label style={{ width: formLabelWidth }}>
            最低到着分数
          </Form.Label>
          <Form.Control
            type="number"
            step={1}
            value={`${this.state.minPickupMinutes}`}
            // @ts-ignore
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.setState({ minPickupMinutes: parseInt(e.target.value) })
            }
          />
        </Form.Group>

        <Row>
          <Col>
            <Row
              className="mx-1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="info"
                className="my-1"
                onClick={() => this.setState({ selectedH3Indices: [] })}
                disabled={this.state.selectedH3Indices.length === 0}
              >
                選択をリセット
              </Button>
              {this.props.canUpdate && (
                <Button
                  variant="primary"
                  className="my-1"
                  onClick={async () => await this.updateMinPickupMinutes()}
                  disabled={isNotSelectedH3 || isBeforeToday}
                >
                  更新
                </Button>
              )}
            </Row>
            {isNotSelectedH3 && (
              <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Form.Text className="text-danger">
                  エリアを選択してください
                </Form.Text>
              </Col>
            )}
            {isBeforeToday && (
              <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Form.Text className="text-danger">
                  過去分は更新できません
                </Form.Text>
              </Col>
            )}
          </Col>
        </Row>
      </Form>
    );
  }

  private createHexInfoWindow() {
    if (!this.state.map || !this.state.mouseoverH3Index) {
      return null;
    }

    const rowStyle = {
      marginBottom: 0,
    };

    const height =
      85 + this.state.mouseoverH3Index.minPickupMinutesSettings.length * 16;
    return (
      <MapControl
        mapApi={{ map: this.state.map, maps: this.state.mapApi }}
        position={"LEFT_TOP"}
        style={{ left: 0, right: 0, padding: 10, width: 300, height: height }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            borderRadius: 10,
          }}
        >
          <div style={{ color: "blue", padding: 10 }}>
            <p style={rowStyle}>
              ヘックス　　： {this.state.mouseoverH3Index.nickName || " - "}(
              {this.state.mouseoverH3Index.h3Index})
            </p>
            <div style={rowStyle}>
              <div>
                対象日：{" "}
                <span className="fw-bold">{this.state.selectedDate}</span>
              </div>
              最短到着分数設定
              {this.state.mouseoverH3Index.minPickupMinutesSettings.map(
                (setting) => {
                  return (
                    <div key={`${setting.fromTime.getTime()}`} className="ml-3">
                      <span className="fw-bold">
                        {format(setting.fromTime, "HH:mm")}
                      </span>
                      <span className="mx-1">〜</span>
                      <span className="fw-bold">
                        {format(setting.toTime, "HH:mm")}
                      </span>
                      ：
                      <span className="fw-bold">
                        {setting.minPickupMinutes}
                      </span>
                      分
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </MapControl>
    );
  }

  private handleButtonClick(h3Index: H3IndexModel) {
    window.open(
      `/h3_min_pickup_minutes_settings/${h3Index.h3Index}/index`,
      "_blank"
    );
  }

  private renderModal(h3Index: H3IndexModel) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1000, // 高いz-indexで地図の上に配置
        }}
      >
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div style={{ fontSize: "0.75rem" }}>H3Index</div>
              <div
                onClick={() =>
                  this.setState({ isModalOpen: false, selectedH3Index: null })
                }
              >
                閉じる
              </div>
            </div>
          </Card.Header>
          <Card.Body style={{ fontSize: "0.85rem" }} className="mx-0 mb-0 pb-0">
            <Card.Title style={{ fontSize: "1rem" }}>
              {h3Index.h3Index}
            </Card.Title>
          </Card.Body>
          <Card.Footer>
            <Button size="sm" onClick={() => this.handleButtonClick(h3Index)}>
              このエリアの時間設定画面へ
            </Button>
          </Card.Footer>
        </Card>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: "100%", display: "flex", position: "relative" }}>
        <div
          className="request-map-container flex-grow-1"
          style={{ height: "calc(100vh - 180px)" }}
        >
          <GoogleMap
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{
              key: gon.google_api_key,
            }}
            defaultZoom={this.state.defaultZoom}
            defaultCenter={this.state.center}
            center={this.state.center}
            options={{
              zoomControlOptions: {
                position: 7,
              },
            }}
            onChange={(e) => this.onChangeMap(e)}
            resetBoundsOnResize={true}
            hoverDistance={MapAttributes.K_SIZE / 2}
            onGoogleApiLoaded={({ map, maps }) => {
              deaasOverlookStore.setMap(map);
              this.setState({
                map: map,
                mapApi: maps,
                mapLoaded: true,
              });
            }}
          >
            {this.createH3IndicesPolygon()}
            {this.createServiceAreaPolygon()}
            {this.createHexInfoWindow()}
          </GoogleMap>
        </div>
        {this.state.isModalOpen &&
          this.state.selectedH3Index &&
          this.renderModal(this.state.selectedH3Index)}
        <div
          className="col-3 d-flex flex-column"
          style={{ padding: 10, minWidth: 280 }}
        >
          {this.renderColorPalette()}
          {this.renderForm()}
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default observer(H3MinPickupMinutesSettingsBulkEdit);
