import React, { useState } from "react";

type Props = {
  name: string,
  address: string,
  email: string,
  tel: string
};  

export default function RequestInfoModal({name, address, email, tel}: Props) {
  const [copyState, setCopyState] = useState({});

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    setCopyState((copyState) => ({ ...copyState, [text]: true}))
  
    let timer: number | undefined;
    timer = setTimeout(() => {
      setCopyState((copyState) => ({ ...copyState, [text]: false}))
    }, 1000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      {name &&
        <div style={{display: "flex"}}>
          <div className="request-index-name">{name}</div>
          <button style={styles.copy_bt} onClick={() => copyToClipboard(name)}><i className={copyState[name] ? "fas fa-check" : "far fa-copy"}></i></button>
        </div>
      }
      {address &&
        <div style={{display: "flex"}}>
          <div className="request-index-name">{address}</div>
          <button style={styles.copy_bt} onClick={() => copyToClipboard(address)}><i className={copyState[address] ? "fas fa-check" : "far fa-copy"}></i></button>
        </div>
      }
      {email &&
        <div style={{display: "flex"}}>
          <div className="request-index-name">{email}</div>
          <button style={styles.copy_bt} onClick={() => copyToClipboard(email)}><i className={copyState[email] ? "fas fa-check" : "far fa-copy"}></i></button>
        </div>
      }
      {tel &&
        <div style={{display: "flex"}}>
          <div className="request-index-name">{tel}</div>
          <button style={styles.copy_bt} onClick={() => copyToClipboard(tel)}><i className={copyState[tel] ? "fas fa-check" : "far fa-copy"}></i></button>
        </div>
      }
    </>
  );
}

const styles = {
  copy_bt: {
    border: "none",
    fontSize: "0.3em",
    backgroundColor: "transparent"
  }
}
