import { observable } from "mobx";
import { RawRequest } from "../interfaces/entities";
import { RequestSequence } from "../utils/CarryStaffRequestSequenceUtils";

export class CarryStaffRequestSequenceModel {
  // carry_staff_request_sequencesのidではないのでcustomId
  @observable.ref
  customId: string;
  request_id: number;
  request: RawRequest;
  destinationType: "receiver" | "sender";
  sequence: number;
  estimatedDeliveryTime: string | null;
  workStartTime: string | null;

  constructor(sequence: RequestSequence<RawRequest>) {
    this.customId = sequence.id;
    this.request_id = sequence.request_id;
    this.request = sequence.request;
    this.destinationType = sequence.destination_type;
    this.sequence = +sequence.sequence;
    this.estimatedDeliveryTime = sequence.estimated_delivery_time;
    this.workStartTime = sequence.work_start_time;
  }
}
