import React from "react";
import { observer } from "mobx-react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @ts-ignore
import Wkt from "wicket";
import DeliveryArea from "../interfaces/DeliveryArea";
import { axiosPost } from "../utils/AxiosClient";
import MapAreaPicker from "./MapAreaPicker/MapAreaPicker";

declare var gon: any;

interface Props {
  vendorCompanyId: number;
  deliveryArea: DeliveryArea;
}

interface State {
  deliveryAreaWkt: string;
}

class DeliveryAreaMapEditor extends React.Component<Props, State> {
  wkt: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      deliveryAreaWkt: props.deliveryArea.area_wkt,
    };
    this.wkt = new Wkt.Wkt();
  }

  validateWktText = (wktText: string) => {
    try {
      this.wkt.read(wktText);
    } catch (error) {
      return false;
    }
    return true;
  };

  updateByWkt = (targetArea: string) => {
    const isValid = this.validateWktText(targetArea);
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!isValid) throw new Error("wktの形式が不正です");
        const res = await axiosPost.post(
          `/api/vendor_companies/${this.props.vendorCompanyId}/update_delivery_area_wkt`,
          { delivery_area: targetArea }
        );
        this.setState({
          deliveryAreaWkt: res.data.delivery_area,
        });
        resolve();
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };

  destroy = async () => {
    let options = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
    };
    try {
      await axiosPost.post(
        `/api/vendor_companies/${this.props.vendorCompanyId}/delete_delivery_area`
      );
      this.setState({ deliveryAreaWkt: "" });
      toast.success("削除しました。", options);
    } catch (e) {
      toast.error("削除に失敗しました。", options);
      throw e;
    }
  };

  render() {
    const { deliveryArea } = this.props;
    const { deliveryAreaWkt } = this.state;

    return (
      <div style={{ width: "100%", height: "800px" }}>
        <MapAreaPicker
          gmapsApiKey={gon.google_api_key}
          center={deliveryArea}
          wkt={deliveryAreaWkt}
          onUpdateByWkt={this.updateByWkt}
          onDestroy={this.destroy}
        />
        <ToastContainer />
      </div>
    );
  }
}

export default observer(DeliveryAreaMapEditor);
