import { observer } from "mobx-react";
import React from "react";
import Pagination from "../../../../components/Common/Pagination";
import { pageStore } from "../../stores";

interface Props {}

interface State {}

class CasPagination extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    if (pageStore.totalCasCount == 0) {
      return null;
    }

    return (
      <Pagination
        displayPageCount={2}
        currentPage={pageStore.casPage}
        lastPage={Math.ceil(
          Math.max(pageStore.totalCasCount, 1) / pageStore.casPerPage
        )}
        onChangePage={(e, newPage) => {
          e.preventDefault();
          if (pageStore.existsAssigningChanged()) {
            const confirmed = confirm(
              "ページを移動すると保存前のアサインが全てリセットされます。ページを移動しますか？"
            );
            if (!confirmed) {
              return;
            }
          }

          pageStore.resetRequestAssignChange();
          pageStore.changeCasPage(newPage);
        }}
      />
    );
  }
}

export default observer(CasPagination);
