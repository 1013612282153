import format from "date-fns/format";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { RequestModel } from "../models/RequestModel";
import requestsStore from "../stores/RequestsStore";

interface Props {
  lat: number;
  lng: number;
}

class RequestList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {}

  private renderRequests() {
    return _.map(requestsStore.items.slice(), (request) => {
      return (
        <div key={request.id} className={"border-left-info pl-4 mt-3"}>
          <a href={`/requests/${request.id}/edit`}>
            <div className={"request-name"}>
              ID：#{request.id} {request.statusText}
            </div>
          </a>
          {this.renderDeliveryTimeAt(request)}
          {this.renderCarryStaff(request)}
          <div className={"request-name"}>
            店舗様名称：{request.sender.name}
          </div>
          <div className={"request-address"}>
            住所名称：{request.sender.address}
          </div>
          <div className={"request-name"}>
            お客様様名称：{request.receiver.name}
          </div>
          <div className={"request-address"}>
            住所名称：{request.receiver.address}
          </div>
        </div>
      );
    });
  }

  private renderDeliveryTimeAt(request: RequestModel) {
    if (request.deliveryTimeAt) {
      return (
        <div className="request-name">
          配達時間：{format(request.deliveryTimeAt, "yyyy/MM/dd HH:mm")}
        </div>
      );
    }

    return null;
  }

  private renderCarryStaff(request: RequestModel) {
    if (request.carryStaff) {
      return (
        <div className="request-name">
          配達スタッフ：{request.carryStaff.name}
        </div>
      );
    }

    return (
      <div className="no-assign-carry-staff">配達スタッフ：(未アサイン)</div>
    );
  }

  render() {
    return <div className={"request-list"}>{this.renderRequests()}</div>;
  }
}

export default observer(RequestList);
