import format from "date-fns/format";
import _ from "lodash";
import type { Location, MatchedLocation, SimpleLocation } from "../interfaces";
import { Env } from "../../../../constants/Env";

/**
 * 指定された日時文字列が有効なものかどうかを判定する関数.
 *
 * @param dateStr
 * @returns
 */
export function isInvalidDate(dateStr: string) {
  return Number.isNaN(new Date(dateStr).getTime());
}

/**
 * sent_atがnullの場合に、前後のsent_atを利用して埋める関数.
 * @param locations
 * @returns
 */
export function fillSentAtRange<T extends (Location | MatchedLocation)[]>(
  locations: T
) {
  // 元の配列(storeのlocations)は変更したくないのでディープコピーして操作し返却
  let _locations = _.cloneDeep(locations);

  for (let i = 0; i < locations.length; i++) {
    if (locations[i].sent_at) {
      _locations[i].sent_at = format(
        new Date(locations[i].sent_at!), // nullは来ないはず
        "yyyy/MM/dd HH:mm:ss"
      );
      continue;
    }

    const prevSentAt = getPrevSentAt(locations, i);
    const nextSentAt = getNextSentAt(locations, i);

    // nullのsent_atを前後の範囲で埋める
    if (prevSentAt && nextSentAt) {
      // 両方ある場合、見た目の問題上 日は跨がないという想定で後の日時は時刻だけにする
      const nextSentAtOnlyTime = format(new Date(nextSentAt), "HH:mm:ss");
      _locations[i].sent_at = `${prevSentAt}〜${nextSentAtOnlyTime}`;
    } else if (prevSentAt && !nextSentAt) {
      _locations[i].sent_at = `${prevSentAt}〜`;
    } else if (!prevSentAt && nextSentAt) {
      _locations[i].sent_at = `〜${nextSentAt}`;
    }
  }

  return _locations;
}

/**
 * sent_atがnullの場合に、前後のsent_atを利用して埋める関数.
 * @param locations
 * @returns
 */
export function fillSentAtPreviousValue<
  T extends (Location | MatchedLocation)[]
>(locations: T) {
  // 元の配列(storeのlocations)は変更したくないのでディープコピーして操作し返却
  let _locations = _.cloneDeep(locations);
  _locations.map((loc, index) => {
    if (loc.sent_at) {
      loc.sent_at = format(new Date(loc.sent_at), "yyyy/MM/dd HH:mm:ss");
    } else {
      loc.sent_at = getPrevSentAt(locations, index);
    }
  });
  return _locations;
}

/**
 * 与えられたlocationsからpolyline用のグループを作成する関数.
 * 地点が近い場合・30分未満の場合は1つのpolylineとして扱う
 * @param locations
 * @param env
 * @returns
 */
export function createPolylineGroups(
  locations: (Location | MatchedLocation)[],
  env: string
) {
  let polylineGroups: SimpleLocation[][] = [];
  let currentGroup: SimpleLocation[] = [];
  let beforeLocation: SimpleLocation | undefined = undefined;

  for (const location of locations) {
    if (env === Env.STAGING) {
      // 検証環境では各地点間をその距離に関わらずつなげる
      if (beforeLocation) {
        polylineGroups.push([beforeLocation, location]);
      }
      beforeLocation = location;
      continue;
    }

    if (currentGroup.length == 0) {
      currentGroup.push(location);
      polylineGroups.push(currentGroup);
      continue;
    }

    const lastLocation = currentGroup[currentGroup.length - 1];
    const { lat: lastLat, lng: lastLng, sent_at: lastSentAt } = lastLocation;
    const lastSentAtDate = lastSentAt ? new Date(lastSentAt) : undefined;
    const sentAtDate = location.sent_at
      ? new Date(location.sent_at)
      : undefined;

    // target_dateが異なる場合は新しいグループを作成する
    if (lastLocation.target_date != location.target_date) {
      currentGroup = [location];
      polylineGroups.push(currentGroup);
      continue;
    }

    if (
      lastLat - 0.001 <= location.lat &&
      location.lat <= lastLocation.lat + 0.001 &&
      lastLng - 0.001 <= location.lng &&
      location.lng <= lastLocation.lng + 0.001
    ) {
      // 範囲内の座標であれば同じグループに追加する
      currentGroup.push(location);
    } else if (
      lastSentAtDate &&
      sentAtDate &&
      isRangesLessThan30min(lastSentAtDate, sentAtDate)
    ) {
      // 直前のsent_atの値と比較して30分未満であれば同じグループに追加する
      currentGroup.push(location);
    } else {
      currentGroup = [location];
      polylineGroups.push(currentGroup);
    }
  }
  return polylineGroups;
}

/**
 * 与えられたlocationsからpolyline用のグループを作成する関数.
 * @param locations
 * @param env
 * @returns
 */
export function createTargetDateGroups(
  locations: (Location | MatchedLocation)[]
) {
  let targetDateGroups: (Location | MatchedLocation)[][] = [];
  let currentGroup: (Location | MatchedLocation)[] = [];

  for (const location of locations) {
    if (currentGroup.length == 0) {
      currentGroup.push(location);
      targetDateGroups.push(currentGroup);
      continue;
    }

    const lastLocation = currentGroup[currentGroup.length - 1];
    const { target_date: lastTargetDate } = lastLocation;

    // target_dateが異なる場合は新しいグループを作成する
    if (lastLocation.target_date != location.target_date) {
      currentGroup = [location];
      targetDateGroups.push(currentGroup);
      continue;
    }

    if (lastTargetDate == location.target_date) {
      // 同一日時のtarget_dateであれば同じグループに追加する
      currentGroup.push(location);
    } else {
      currentGroup = [location];
      targetDateGroups.push(currentGroup);
    }
  }
  return targetDateGroups;
}

/**
 * 与えられた2つの日時の差が30分未満かどうかを判定する関数.
 * @param date1
 * @param date2
 * @returns
 */
function isRangesLessThan30min(date1: Date, date2: Date): boolean {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  // 1000ms * 60s * 30m = 1800000ms(30分)
  return diff < 1000 * 60 * 30;
}

/**
 * 与えられたlocationsの中から、
 * 指定されたindexの位置情報のtarget_dateと同じもので、
 * "直前"のnullでないsent_atを取得する関数.
 * @param locations
 * @param i
 * @returns
 */
function getPrevSentAt(locations: (Location | MatchedLocation)[], i: number) {
  let prevSentAt: string | null = null;
  for (let j = i + 1; j < locations.length; j++) {
    if (
      locations[j].sent_at != null &&
      locations[j].target_date == locations[i].target_date
    ) {
      prevSentAt = format(
        new Date(locations[j].sent_at!), // nullは来ないはず
        "yyyy/MM/dd HH:mm:ss"
      );
      break;
    }
  }
  return prevSentAt;
}

/**
 * 与えられたlocationsの中から、
 * 指定されたindexの位置情報のtarget_dateと同じもので、
 * "直後"のnullでないsent_atを取得する関数.
 * @param locations
 * @param i
 * @returns
 */
function getNextSentAt(locations: (Location | MatchedLocation)[], i: number) {
  let nextSentAt: string | null = null;
  for (let j = i - 1; j >= 0; j--) {
    if (
      locations[j].sent_at != null &&
      locations[j].target_date == locations[i].target_date
    ) {
      nextSentAt = format(
        new Date(locations[j].sent_at!), // nullは来ないはず
        "yyyy/MM/dd HH:mm:ss"
      );
      break;
    }
  }
  return nextSentAt;
}
