import { endOfDay, format } from "date-fns";
import React, { useState } from "react";
import MapAreaPicker from "../../../../components/MapAreaPicker/MapAreaPicker";
import { MapMarkerPin } from "../../../../components/MapMarkerPin";
import Colors from "../../../../constants/BootstrapColors";
import {
  selectIntersectWith,
  selectWithin,
} from "../../../../utils/GeographyUtils";
import type { SimpleCarryStaffSchedule } from "../../../../utils/routes-apis/common-utils";
import type {
  RawBulkAssignArea,
  RawCarryStaff,
  RawRequestWithInfo,
  RawTerritory,
} from "../../interfaces";
import { pageStore } from "../../stores";
import { loadCarryStaffsWithSchedules } from "../../utils";
import ModalBodyWrapper from "./ModalBodyWrapper";

interface Props {
  zoom: number;
  center: { lat: number; lng: number };
  bulkAssignAreas: RawBulkAssignArea[];
  territories: RawTerritory[];
  onClickClose: () => void;
  onClickNext: (items: {
    carryStaffs: RawCarryStaff[];
    schedules: SimpleCarryStaffSchedule[];
    requests: RawRequestWithInfo[];
  }) => void;
}

export default function SelectAreaStageBody(props: Props) {
  const {
    zoom,
    center,
    bulkAssignAreas,
    territories,
    onClickClose,
    onClickNext,
  } = props;

  const [loading, setLoading] = useState(false);
  const [areaWkt, setAreaWkt] = useState("");

  // 対象をstoreに保存されている未アサイン依頼とする
  // (改めて取り直したりはしない)
  const unassignedRequests = pageStore.requests.filter(
    (req) => req.org_carry_staff_id == null
  );

  const getTerritoryIdsIntersectWithArea = (_areaWkt: string) => {
    const territoryIds = selectIntersectWith(
      areaWkt,
      territories.map((territory) => ({
        key: `${territory.id}`,
        wkt: territory.area_wkt,
      }))
    ).map((territoryId) => +territoryId);
    return territoryIds;
  };

  const filterRequestsWithinArea = (
    _areaWkt: string,
    _requests: RawRequestWithInfo[]
  ) => {
    const requestIds = selectWithin(
      areaWkt,
      _requests.map((req) => ({
        key: `${req.id}`,
        location: { lat: +req.receiver_lat, lng: +req.receiver_lng },
      }))
    ).map((requestId) => +requestId);

    return _requests.filter((req) => requestIds.indexOf(req.id) >= 0);
  };

  const filterRequestsUntilTargetTerm = (_requests: RawRequestWithInfo[]) => {
    const targetTermDate = pageStore.getTargetTermDate();
    return _requests.filter(
      (req) => new Date(req.delivery_time_at) <= endOfDay(targetTermDate)
    );
  };

  const handleClickNext = async () => {
    // エリアが指定されていない場合、何もしない
    // ボタン側で制御をかけているけれど、一応
    if (areaWkt == "") {
      return;
    }

    // 指定されたエリアと交差する担当エリアのIDを取得
    const territoryIds = getTerritoryIdsIntersectWithArea(areaWkt);

    // API経由で、上記担当エリアに紐づくその日にスケジュールを持っている配達スタッフを取得
    setLoading(true);
    const response = await loadCarryStaffsWithSchedules(
      "",
      territoryIds,
      format(pageStore.getTargetTermDate(), "yyyy-MM-dd")
    );
    setLoading(false);

    const carryStaffs = response.carryStaffs;
    const schedules = response.schedules.map((sch) => ({
      carry_staff_id: sch.carry_staff_id,
      territory_id: sch.territory_id,
      from_time: new Date(sch.from_time),
      to_time: new Date(sch.to_time),
    }));

    // 未アサイン依頼のうち、指定されたエリアに配達先を含んでいるものを抽出
    let requests = filterRequestsWithinArea(areaWkt, unassignedRequests);

    // 依頼のうち、配達希望時刻が対象日の終わりまでのものを抽出
    requests = filterRequestsUntilTargetTerm(requests);

    onClickNext({
      carryStaffs,
      schedules,
      requests,
    });
  };

  const createReceivers = () => {
    return filterRequestsUntilTargetTerm(unassignedRequests).map((req) => {
      return (
        <MapMarkerPin
          key={`receiver_marker_${req.id}`}
          lat={+req.receiver_lat}
          lng={+req.receiver_lng}
          pinShape={"rectangle"}
          size={27}
          pinBackgroundColor="#fff"
          pinText={req.shortCode}
          pinTextColor={Colors.INFO_COLOR}
          onClick={() => {}}
        />
      );
    });
  };

  return (
    <ModalBodyWrapper
      title="対象エリア選択"
      closeButtonDisabled={loading}
      onClickClose={onClickClose}
      showBackButton={false}
      nextButtonDisabled={areaWkt == "" || loading}
      onClickNext={handleClickNext}
    >
      <div style={{ width: "100%", height: 600 }}>
        <MapAreaPicker
          gmapsApiKey={gon.google_api_key}
          zoom={zoom}
          center={center}
          selectableAreas={bulkAssignAreas.map((area) => ({
            key: `${area.id}`,
            label: area.name,
            areaWkt: area.area_wkt,
          }))}
          unEditableWkts={territories.map((territory) => territory.area_wkt)}
          pins={createReceivers()}
          wkt={areaWkt}
          onUpdateByWkt={async (_areaWkt) => setAreaWkt(_areaWkt)}
          onDestroy={async () => setAreaWkt("")}
        />
      </div>
    </ModalBodyWrapper>
  );
}
