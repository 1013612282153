import React from "react";

type Selection = { id: string; value1: string; value2?: string };
type CheckList = { isChecked: boolean } & Selection;

interface Props {
  selections: Selection[];
  queryKey: string;
  sectionName: string;
  cookieKeyString: string;
  isSelectingAllByDefault: boolean; //デフォルトで全選択済みにする
  acceptUnselectedState: boolean; //未選択時でも適用ボタンが押せる
  isReverseCondition?: boolean; // not_in などの条件の場合true
  onChange?: (selectIds: string[]) => void;
}
interface State {
  checkList: CheckList[];
  prevCheckedIds: string[];
  isSelectionOpen: boolean;
  isShowingAll: boolean;
  searchText: string;
  buttonState: {
    isAllSelectButtonClickable: boolean;
    isClearButtonClickable: boolean;
    isApplyButtonClickable: boolean;
  };
}

//定期のページリロードによるStateの消失のためCookieに保存
type StateToKeep = { ids: string; prevCheckedIds: string } & Pick<
  State,
  "isSelectionOpen" | "searchText" | "isShowingAll"
>;

class CustomSelectionView extends React.Component<Props, State> {
  private submitRef = React.createRef<HTMLButtonElement>();

  constructor(props) {
    super(props);
    const checkList = this.createCheckList(
      props.selections,
      props.isSelectingAllByDefault
    );
    const buttonState = this.getNextButtonState(checkList);
    this.state = {
      checkList: checkList,
      prevCheckedIds: this.getCheckedIds(checkList),
      isSelectionOpen: false,
      isShowingAll: true,
      searchText: "",
      buttonState: buttonState,
    };
  }

  componentDidMount(): void {
    const filteringState = localStorage.getItem(this.props.cookieKeyString);
    if (filteringState) {
      const state: StateToKeep = JSON.parse(filteringState);
      const selectedIds = state.ids.split(",");

      let checkList: CheckList[] = [];
      let prevCheckedIds: string[] = [];

      if (selectedIds[0] === "" && !this.props.isReverseCondition) {
        checkList = this.state.checkList.map(
          (item: CheckList) =>
            ({
              ...item,
              isChecked: true,
            } as CheckList)
        );
        prevCheckedIds = this.state.checkList.map((item: CheckList) => item.id);
      } else {
        checkList = this.state.checkList.map(
          (item: CheckList) =>
            ({
              ...item,
              isChecked: selectedIds.indexOf(item.id) >= 0, //string[]のincludesはes2016以降
            } as CheckList)
        );
        prevCheckedIds = state.prevCheckedIds.split(",");
      }

      this.setState(
        {
          checkList: checkList,
          prevCheckedIds: prevCheckedIds,
          isSelectionOpen: state.isSelectionOpen,
          isShowingAll: state.isShowingAll,
          searchText: state.searchText,
          buttonState: this.getNextButtonState(checkList),
        },
        () =>
          this.props.onChange
            ? this.props.onChange(state.prevCheckedIds.split(","))
            : null
      );
    } else if (this.props.onChange) {
      this.props.onChange(
        this.state.checkList.map((item: CheckList) => item.id)
      );
    }
  }

  componentDidUpdate(): void {
    const {
      checkList,
      prevCheckedIds,
      isSelectionOpen,
      isShowingAll,
      searchText,
    } = this.state;
    const checkedIdsInStr = this.getCheckedIds(checkList);
    const isSelectingAll = checkedIdsInStr.length === checkList.length;

    const value: StateToKeep = {
      ids: isSelectingAll ? "" : checkedIdsInStr.join(","),
      prevCheckedIds: isSelectingAll ? "" : prevCheckedIds.join(","),
      isSelectionOpen,
      searchText,
      isShowingAll,
    };
    //定期のページリロード対策
    localStorage.setItem(this.props.cookieKeyString, JSON.stringify(value));
  }

  render() {
    const checkList = this.state.isShowingAll
      ? this.state.checkList
      : this.state.checkList.filter((item: CheckList) => item.isChecked);
    const selectedIds = this.getCheckedIds(this.state.checkList);
    const selectedIdsForRequest = this.getSelectedIdsForRequest(selectedIds);

    return (
      <div className="custom-searchable-selection">
        {this.state.isSelectionOpen ? (
          <>
            <div className="popup-container">
              <div className="switch-view-container">
                <button
                  disabled={this.state.isShowingAll}
                  onClick={() => this.setState({ isShowingAll: true })}
                  className="switch"
                >
                  {`すべて (${this.state.checkList.length})`}
                </button>
                <button
                  disabled={!this.state.isShowingAll}
                  onClick={() => this.setState({ isShowingAll: false })}
                  className="switch"
                >
                  {`選択済み (${selectedIds.length})`}
                </button>
              </div>

              <input
                className="search-box"
                value={this.state.searchText}
                onChange={(e) => this.handleChange(e)}
                placeholder={"検索"}
              />

              <div className="selection-button-container">
                {!this.state.isShowingAll ||
                this.props.isReverseCondition ? null : (
                  <button
                    className="button"
                    disabled={
                      !this.state.buttonState.isAllSelectButtonClickable
                    }
                    onClick={() =>
                      this.handleChangeAllCheckList({ allowCheckAll: true })
                    }
                  >
                    全て選択
                  </button>
                )}
                <button
                  className="button"
                  disabled={!this.state.buttonState.isClearButtonClickable}
                  onClick={() =>
                    this.handleChangeAllCheckList({ allowCheckAll: false })
                  }
                >
                  選択を解除
                </button>
              </div>
              <ul className="list-body">
                {checkList
                  .filter(
                    (item: CheckList) =>
                      item.value1.includes(this.state.searchText) ||
                      (item.value2 &&
                        item.value2.includes(this.state.searchText))
                  )
                  .map((selection: CheckList) => {
                    return (
                      <li
                        key={selection.id}
                        className="selection"
                        onClick={() => this.handleUpdateCheckList(selection)}
                      >
                        <input
                          type="checkbox"
                          checked={selection.isChecked}
                          onChange={() => this.handleUpdateCheckList(selection)}
                        />
                        <div className="label-wrapper">
                          <label className="value1-label">
                            {selection.value1}
                          </label>
                          <label className="value2-label">
                            {selection.value2}
                          </label>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              {selectedIdsForRequest.map((id) => {
                return (
                  <input
                    name={this.props.queryKey}
                    hidden
                    value={id}
                    key={id}
                    className="hidden-input"
                    readOnly
                  />
                );
              })}
              <button
                className="apply-button"
                disabled={!this.state.buttonState.isApplyButtonClickable}
                onClick={() => this.onClickSubmit()}
              >
                適用
              </button>
              <button
                ref={this.submitRef}
                className="js-simple-search submit"
                hidden={true}
              />
            </div>
            <div className="overlay" onClick={() => this.onCloseModal()} />
          </>
        ) : (
          <div className="label" onClick={() => this.onOpenModal()}>
            <p>{this.getLabel(this.state.checkList, selectedIds)}</p>
            <div className="icon-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                fill="#495057"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 16l-6-6h12z" />
              </svg>
            </div>
          </div>
        )}
      </div>
    );
  }

  getSelectedIdsForRequest(selectedIds: string[]): string[] {
    if (this.props.isReverseCondition) {
      // not_inなどの条件時
      if (selectedIds.length === 0) {
        return [""];
      }
      return selectedIds;
    }

    if (selectedIds.length === this.state.checkList.length) {
      return [""];
    }
    return selectedIds;
  }

  getLabel(checkList: CheckList[], selectedIds: string[]): string {
    const selectedItem = checkList
      .filter((item) => item.id === selectedIds[0])
      .pop();
    if (!selectedItem) {
      return `${this.props.sectionName}を選択してください`;
    } else if (selectedIds.length === 1) {
      return selectedItem.value1;
    } else if (
      1 < selectedIds.length &&
      selectedIds.length < checkList.length
    ) {
      return `${selectedItem.value1} +他${selectedIds.length - 1}件`;
    }
    return "全て";
  }

  onCloseModal(): void {
    const checkList = this.state.checkList.map((item: CheckList) => {
      return {
        ...item,
        isChecked: this.state.prevCheckedIds.indexOf(item.id) >= 0,
      } as CheckList;
    });
    this.setState({
      isSelectionOpen: false,
      checkList: checkList,
      buttonState: this.getNextButtonState(checkList),
    });
  }

  onOpenModal(): void {
    this.setState({
      isSelectionOpen: true,
    });
  }

  createCheckList(
    list: Selection[] | undefined,
    isSelectedByDefault: boolean
  ): CheckList[] {
    if (!list) return [];
    return list.map((item) => {
      return {
        id: item.id,
        value1: item.value1,
        value2: item.value2 ? item.value2 : "",
        isChecked: isSelectedByDefault,
      } as CheckList;
    });
  }

  handleChangeAllCheckList({ allowCheckAll }: { allowCheckAll: boolean }) {
    const checkList = this.state.checkList.map((item) => ({
      ...item,
      isChecked: allowCheckAll,
    }));
    const buttonState = this.getNextButtonState(checkList);
    this.setState({
      checkList: checkList,
      buttonState: buttonState,
    });
  }

  getCheckedIds(checkList: CheckList[]): string[] {
    return checkList.filter((item) => item.isChecked).map((item) => item.id);
  }

  getNextButtonState(checkList: CheckList[]): {
    isAllSelectButtonClickable: boolean;
    isClearButtonClickable: boolean;
    isApplyButtonClickable: boolean;
  } {
    const numberOfChecks = this.getCheckedIds(checkList).length;
    let isAllSelectButtonClickable = false;
    let isClearButtonClickable = false;
    let isApplyButtonClickable = false;

    if (numberOfChecks === checkList.length) {
      isClearButtonClickable = true;
      if (!this.props.isReverseCondition) {
        //not_inなどは全て選択できない様にする
        isApplyButtonClickable = true;
      }
    } else if (numberOfChecks > 0) {
      isClearButtonClickable = true;
      isAllSelectButtonClickable = true;
      isApplyButtonClickable = true;
    } else {
      //未選択
      isAllSelectButtonClickable = true;
      if (this.props.acceptUnselectedState) {
        isApplyButtonClickable = true;
      }
    }
    return {
      isAllSelectButtonClickable,
      isClearButtonClickable,
      isApplyButtonClickable,
    };
  }

  handleUpdateCheckList(selection: CheckList): void {
    const checkList = this.state.checkList.map((item: CheckList) => {
      if (item.id === selection.id) {
        return {
          ...item,
          isChecked: !item.isChecked,
        } as CheckList;
      }
      return item;
    });
    const buttonState = this.getNextButtonState(checkList);
    this.setState({
      checkList: checkList,
      buttonState: buttonState,
    });
  }

  handleChange(event): void {
    this.setState({ searchText: event.target.value });
  }

  async onClickSubmit(): Promise<void> {
    this.setState(
      (prev) => ({
        prevCheckedIds: this.getCheckedIds(prev.checkList),
      }),
      () => {
        this.onCloseModal();
        if (this.props.onChange) {
          this.props.onChange(this.getCheckedIds(this.state.checkList));
        } else if (this.submitRef.current) {
          this.submitRef.current.click();
        }
      }
    );
  }
}

export default CustomSelectionView;
