import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import {
  getStatusText,
  getStoreStatusText,
} from "../../utils/RequestEntityUtils";
import { getRangeDeliveryTimeSlotTimeLabel } from "../../utils/routes-apis/common-utils";
import { ExpandedRawRequest, RawCarryStaff } from "./Interfaces";

interface Props {
  requests?: ExpandedRawRequest[];
  carryStaff?: RawCarryStaff;
  isOpen: boolean;
  onRequestClose: () => void;
}

interface State {}

class RequestInfoModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.requests == null || this.props.carryStaff == null) {
      return null;
    }

    const reqs = this.props.requests;
    const cas = this.props.carryStaff;
    const sum = reqs.length;
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        style={groupModalStyles}
      >
        <h5 className="fas fa-list"> 依頼詳細</h5>
        <p style={{ width: "100%", textAlign: "right" }}>依頼件数 : {sum} 件</p>
        <div
          className="my-3"
          style={{
            height: "70%",
            maxHeight: 400,
            overflowY: "auto",
          }}
        >
          {reqs.map((req) => {
            const readyTimeAt = format(
              parseISO(req.ready_time_at),
              "yyyy/MM/dd HH:mm"
            );
            const deliveryTimeAt = getRangeDeliveryTimeSlotTimeLabel(req);
            return (
              <div key={`request_${req.id}`} className="card m-3">
                <div className="card-header">
                  <a href={`/requests/${req.id}/edit`}>依頼No.{req.id}</a>
                </div>
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="d-flex border-bottom py-2">
                          <div>依頼No.</div>
                          <div className="ml-auto">
                            <a href={`/requests/${req.id}/edit`}>{req.id}</a>
                          </div>
                        </div>
                        <div className="d-flex border-bottom py-2">
                          <div>配達スタッフ</div>
                          <div className="ml-auto">
                            <a href={`/carry_staffs/${cas.id}`}>{cas.name}</a>
                          </div>
                        </div>
                        <div className="d-flex border-bottom py-2">
                          <div>配達先</div>
                          <div className="ml-auto">{req.receiver_name}</div>
                        </div>
                        <div className="d-flex border-bottom py-2">
                          <div>配達元</div>
                          <div className="ml-auto">{req.sender_name}</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="d-flex border-bottom py-2">
                          <div>ステータス</div>
                          <div className="ml-auto">
                            {getStatusText(req.status)}
                          </div>
                        </div>
                        <div className="d-flex border-bottom py-2">
                          <div>店舗ステータス</div>
                          <div className="ml-auto">
                            {getStoreStatusText(req.store_status)}
                          </div>
                        </div>
                        <div className="d-flex border-bottom py-2">
                          <div>準備完了時刻</div>
                          <div className="ml-auto">{readyTimeAt}</div>
                        </div>
                        <div className="d-flex border-bottom py-2">
                          <div>配達希望時刻</div>
                          <div className="ml-auto">{deliveryTimeAt}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="text-right">
          <button
            className="btn btn-secondary mr-2"
            type="button"
            onClick={this.props.onRequestClose}
          >
            戻る
          </button>
        </div>
      </Modal>
    );
  }
}

export default observer(RequestInfoModal);

const groupModalStyles: Modal.Styles = {
  content: {
    width: 800,
    height: 500,
    maxWidth: "calc(100vw - 180px)",
    maxHeight: "calc(100vh - 180px)",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
