import React from "react";
import { observer } from "mobx-react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import AssignLogRequest from "../interfaces/AssignLogRequest";


interface Props {
  body: {
    log_detail: any
  },
  logKey: string
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean
}


class RequestAssignLogDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false
    };
  }

  componentDidMount(): void {
  }

  render() {
    const {
      request,
      previous_request,
      angle,
      diff_angle,
      config_distance,
      actual_distance
    }: {
      request: AssignLogRequest,
      previous_request: AssignLogRequest,
      request_theta_degree: string,
      angle: string,
      diff_angle,
      config_distance: number,
      actual_distance: number
    } = this.props.body.log_detail;

    const requestReadyTimeText = format(parseISO(request.ready_time_at), "yyyy/MM/dd HH:mm");
    const previousRequestReadyTimeText = format(parseISO(previous_request.ready_time_at), "yyyy/MM/dd HH:mm");
    const logKey = this.props.logKey;
    return (
      <React.Fragment>
        {logKey === 'out_of_range' && (
          <div className={"border-left-info pl-4 mt-3"}>
            <div className={"request-name"}>設定角度(±)：{angle}°</div>
            <div className={"request-address"}>判定角度(±)：{Math.abs(Math.floor(diff_angle * 10) / 10)}°</div>
          </div>
        )}
        {logKey === 'out_of_radius' && (
          <div className={"border-left-info pl-4 mt-3"}>
            <div className={"request-name"}>設定半径：{config_distance} m</div>
            <div className={"request-address"}>実際の距離：{actual_distance} m</div>
          </div>
        )}
        <div className={"border-left-info pl-4 mt-3"}>
          <div className={"request-name"}>G) 注文ID：#{request.created_by_id}</div>
          <div className={"request-name"}>注文準備完了時間：{requestReadyTimeText}</div>
        </div>
        <div className={"border-left-info pl-4 mt-3"}>
          <div className={"request-name"}>pG) 過去注文ID：#{previous_request.created_by_id}</div>
          <div className={"request-name"}>注文準備完了時間：{previousRequestReadyTimeText}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default observer(RequestAssignLogDetail);
