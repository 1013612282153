import React, {useState, useEffect, CSSProperties} from 'react';
import {differenceInMinutes, format, parseISO} from 'date-fns';

interface Props {
    limitAt: string;
    limitTitle: string;
    safeMinutes?: number;
    warningMinutes?: number;
    onTimeUp?: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    type?: 'bar' | 'circle';
    visibleLabel?: boolean;
    style?: React.CSSProperties;
    tooltipPosition?: "right" | "top" | "bottom";
}

const COLORS = {
    safe: "#198754",
    warning: "#ffc107",
    // danger: "#aa3545",
    danger: "#aa3545", // 超過分増加する色、表現が不要かもしれないので背景色で見えなくしておく
    over: "#aa3545",
};

const SIZE_TO_HEIGHT = {
    xs: '15px',
    sm: '20px',
    md: '25px',
    lg: '30px',
};

const SIZE_TO_VIEWBOX = {
    xs: {size: 24, radius: 10},
    sm: {size: 30, radius: 13},
    md: {size: 36, radius: 15.9155},
    lg: {size: 42, radius: 18},
};

export default function TimeGauge({
                                      limitAt,
                                      safeMinutes = 60,
                                      warningMinutes = 15,
                                      size = "md",
                                      type = "circle",
                                      visibleLabel,
                                      onTimeUp,
                                      limitTitle,
                                      style,
                                      tooltipPosition
                                  }: Props) {


    const calcRemainingMinutes = (limitTime: string) => {
        const now = new Date();
        const limitDate = parseISO(limitTime);
        return differenceInMinutes(limitDate, now);
    };

    const [remainingMinutes, setRemainingMinutes] = useState(() => calcRemainingMinutes(limitAt));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingMinutes(calcRemainingMinutes(limitAt));
        }, 60000); // 1分ごとに更新
        return () => clearInterval(intervalId);
    }, [limitAt]);

    const [timeUpCalled, setTimeUpCalled] = useState(false);

    useEffect(() => {
        if (remainingMinutes <= 0 && !timeUpCalled) {
            onTimeUp?.();
            setTimeUpCalled(true); // onTimeUpが呼ばれたことを記録
        }
    }, [remainingMinutes, onTimeUp, timeUpCalled]);

    const gaugeWidth = remainingMinutes > 0 ?
        Math.max(0, Math.min(100, (remainingMinutes / safeMinutes) * 100)) :
        100 - Math.min(100, Math.abs(remainingMinutes) / safeMinutes * 100);

    const gaugeColor = remainingMinutes <= 0 ? COLORS.danger : remainingMinutes > warningMinutes ? COLORS.safe : COLORS.warning;

    // ゲージの背景色
    const containerBackground = remainingMinutes <= 0 ? COLORS.over : '#f1f1f1';
    const [hovered, setHovered] = useState(false);

    const renderCircleGauge = () => {
        const { size: circleSize, radius } = SIZE_TO_VIEWBOX[size];  // sizeに基づいたradiusを使用
        const viewBoxSize = radius * 2 + 20;  // 半径からviewBoxのサイズを計算（余白を加える）
        const center = radius + 10;  // 中心点を計算（viewBoxの余白分を加味）
        const strokeWidth = radius * 0.75;
        const circumference = 2 * Math.PI * radius;  // 円周の長さを計算
        const filledLength = gaugeWidth / 100 * circumference;
        const emptyLength = circumference - filledLength;
        const bgColor = remainingMinutes < 0 ? COLORS.over : '#eee';


        return (
            <div
                style={{
                    ...style,
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <span
                    style={{
                        position: 'relative',
                    }}
                >
                    <svg
                        width={circleSize}
                        height={circleSize}
                        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
                        className="circular-chart"
                    >
                        <path
                            className="circle-bg"
                            d={`M${center} ${center - radius} a ${radius} ${radius} 0 1 0 0 ${2 * radius} a ${radius} ${radius} 0 1 0 0 -${2 * radius}`}
                            fill="none"
                            stroke={bgColor}
                            strokeWidth={strokeWidth}
                            strokeDasharray={`${circumference}, ${circumference}`}
                        />
                        <path
                            className="circle"
                            d={`M${center} ${center - radius} a ${radius} ${radius} 0 1 0 0 ${2 * radius} a ${radius} ${radius} 0 1 0 0 -${2 * radius}`}
                            fill="none"
                            stroke={gaugeColor}
                            strokeWidth={strokeWidth}
                            strokeDasharray={`${filledLength}, ${emptyLength}`}
                            strokeDashoffset="0"
                            strokeLinecap={"round"}
                        />
                    </svg>
                        <Tooltip
                            visible={hovered}
                            limitTitle={limitTitle}
                            limitAt={limitAt}
                            gaugeColor={gaugeColor}
                            label={ renderLabel()}
                            position={tooltipPosition ? tooltipPosition : "right"}
                        />

                </span>

                {visibleLabel && (
                    <span style={{
                        fontSize: '14px',
                        marginLeft: '4px',
                        whiteSpace: 'nowrap',
                    }}>
                    {renderLabel()}
                </span>
                )}
            </div>
        );
    };

    const renderBarGauge = () => (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                width: '100%',
                background: containerBackground,
                borderRadius: '6px',
                padding: '2px',
                boxSizing: 'border-box',
                position: 'relative',
                border: '1px solid #ccc',
                height: SIZE_TO_HEIGHT[size],
                ...style,
            }}>
            <div style={{
                height: '100%',
                background: gaugeColor,
                borderRadius: '4px',
                width: `${gaugeWidth}%`,
                transition: 'width 0.5s ease',
                boxShadow: '1 1 1px rgba(0, 0, 0, 0.9)',
            }}/>
            <Tooltip
                visible={hovered}
                limitTitle={limitTitle}
                limitAt={limitAt}
                gaugeColor={gaugeColor}
                label={renderLabel()}
                position="bottom"
            />
            {
                visibleLabel && (
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        textAlign: 'center',
                        marginTop: 4,
                        fontSize: '14px',
                    }}>
                        {renderLabel()}
                    </div>
                )
            }
        </div>
    );
    const formatTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        if (hours > 0) {
            if (mins === 0) {
                // 時間が正確に1時間以上で分が0の場合、「分」を表示しない
                return `${hours}時間`;
            } else {
                // 1時間以上で分もある場合、通常通り「時間」と「分」を表示
                return `${hours}時間${mins}分`;
            }
        } else {
            // 1時間未満の場合は「分」のみを表示
            return `${mins}分`;
        }
    };

    const renderLabel = () => {
        if (remainingMinutes >= 0) {
            return `残り${formatTime(remainingMinutes)}`;
        } else {
            return `${formatTime(Math.abs(remainingMinutes))}超過`;
        }
    };

    return (
        <span style={{position: "relative"}}>
            {type === 'circle' ? renderCircleGauge() : renderBarGauge()}
        </span>
    )


}

interface TooltipProps {
    visible: boolean;
    limitTitle: string;
    limitAt: string;
    gaugeColor: string;
    label: string;
    position?: "right" | "top" | "bottom";
}
const Tooltip = ({ visible, limitTitle, limitAt, gaugeColor, label, position }:TooltipProps ) => {
    if (!visible) return null;
    const tooltipStyle: CSSProperties = {
        position: 'absolute',
        width: 'auto',
        background: 'white',
        padding: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1000,
        whiteSpace: 'nowrap',
        border: "1px solid #ccc",
    };

    if (position === 'right') {
        tooltipStyle.left = `calc(100% + 4px)`;
        tooltipStyle.top = '50%';
        tooltipStyle.transform = 'translateY(-50%)';
    } else if (position === "top") {
        tooltipStyle.left = "50%";
        tooltipStyle.bottom = "calc(100% + 4px)";
        tooltipStyle.transform = "translateX(-50%)";
    } else if (position === 'bottom') {
        tooltipStyle.left = '50%';
        tooltipStyle.top = `calc(100% + 4px)`;
        tooltipStyle.transform = 'translateX(-50%)';
    }
    return (
        <div
            style={tooltipStyle}
        >
            {limitTitle && (
                <div style={{fontSize: 14, fontWeight: "bold", color: "#666"}}>
                    {limitTitle}
                </div>
            )}
            <div>
                <span style={{fontSize: 16}}>
                    {format(parseISO(limitAt), 'yyyy/MM/dd HH:mm')}
                </span>
                <span style={{
                    marginLeft: 8,
                    fontSize: 14,
                    fontWeight: "bold",
                    color: gaugeColor,
                }}>{label}</span>
            </div>
        </div>
    );
};