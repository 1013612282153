import React, { ReactNode } from "react";

interface Props {
  title: string;
  errorMessage?: string | null;
  showLoading?: boolean;
  loadingLabel?: string;
  // 閉じるボタン
  closeButtonDisabled?: boolean;
  onClickClose: () => void;
  // 戻るボタン
  showBackButton?: boolean;
  backButtonDisabled?: boolean;
  backButtonLabel?: string;
  onClickBack?: () => void;
  // 進むボタン
  showNextButton?: boolean;
  nextButtonDisabled?: boolean;
  nextButtonLabel?: string;
  onClickNext?: () => void;
  // 子供
  children?: ReactNode;
}

export default function ModalBodyWrapper(props: Props) {
  const {
    title,
    errorMessage = null,
    showLoading = false,
    loadingLabel = "",
    closeButtonDisabled = false,
    onClickClose,
    showBackButton = false,
    backButtonDisabled = false,
    backButtonLabel = "戻る",
    onClickBack = () => {},
    showNextButton = true,
    nextButtonDisabled = false,
    nextButtonLabel = "確認",
    onClickNext = () => {},
    children
  } = props;

  return (
    <div className="col-12">
      <div className="col-12 d-flex justify-content-between my-2">
        <div className="h5 my-2 d-flex align-items-center">
          {title}
          {showLoading && (
            <div className="d-flex align-items-center ml-3">
              <div className="mr-2">
                <span style={{ fontSize: 16 }}>{loadingLabel}</span>
              </div>
              <div
                className="spinner-border spinner-border-sm ml-3"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <button
          className="btn btn-outline-secondary"
          disabled={closeButtonDisabled}
          onClick={() => onClickClose()}
        >
          <i className="fa fa-times" />
        </button>
      </div>

      {errorMessage && (
        <div className="col-12 d-flex">
          <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {errorMessage}
          </p>
        </div>
      )}

      {children}

      <div className="d-flex flex-column">
        <div className="d-flex justify-content-end my-2">
          {showBackButton && (
            <button
              className="btn btn-secondary mr-2"
              disabled={backButtonDisabled}
              onClick={() => onClickBack()}
            >
              {backButtonLabel}
            </button>
          )}
          {showNextButton && (
            <button
              className="btn btn-primary"
              type="submit"
              disabled={nextButtonDisabled}
              onClick={() => onClickNext()}
            >
              {nextButtonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
