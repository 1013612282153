import format from "date-fns/format";
import { ChangeEventValue, Coords } from "google-map-react";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import CircleArea from "../components/CircleArea";
import H3CapacityTimelineChart from "../components/H3CapacityTimelineChart";
import OfficeSmallMarker from "../components/OfficeSmallMarker";
import OverlookTempMarker from "../components/OverlookTempMarker";
import TimelineSlider from "../components/TimelineSlider";
import MapControl from "../components/ui/GmapsUI/controls/MapControl";
import VendorCompanySmallMarker from "../components/VendorCompanySmallMarker";
import WktMultiPolygon from "../components/WktMultiPolygon";
import { H3_CAPACITY_TIMELINE_INTERVAL_MINUTES } from "../constants/H3CapacityTimeline";
import MapAttributes from "../constants/MapAttributes";
import MarkerColors from "../constants/MarkerColors";
import { H3IndexModel } from "../models/H3IndexModel";
import deaasOverlookStore from "../stores/DeaasAreaOverlookStore";
import H3CapatityTimelinesStore from "../stores/H3CapatityTimelinesStore";
import GoogleMap from "../components/Common/GoogleMap";

declare var gon: any;

interface Props {
  showH3Areas: boolean;
  hideMarkers: boolean;
  showStaffCountPredictions: boolean;
  showPopInfoWindow: boolean;
  height?: string;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  centerLat: number;
  centerLng: number;
  northEast: null | Coords;
  southEast: null | Coords;
  southWest: null | Coords;
  northWest: null | Coords;
  defaultZoom: number;
  loadingServiceArea: boolean;
  targetTime: Date | undefined;
  clickedHex: H3IndexModel | null;
  mouseOveredHex: H3IndexModel | null;
  zoom: number;
}

class DeaasAreaOverlookMap extends React.Component<Props, State> {
  predictionMarkers: { [key: string]: google.maps.Marker };

  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      centerLat: this.getDefaultCenterLat(),
      centerLng: this.getDefaultCenterLng(),
      northEast: null,
      southEast: null,
      southWest: null,
      northWest: null,
      defaultZoom: this.getDefaultZoom(),
      loadingServiceArea: false,
      targetTime: undefined,
      clickedHex: null,
      mouseOveredHex: null,
      zoom: this.getDefaultZoom(),
    };
    this.predictionMarkers = {};
  }

  componentDidMount() {}

  render() {
    const handleChangeTimeline = async (value: Date) => {
      if (!value) return;
      this.setState({
        loadingServiceArea: true,
        targetTime: value,
      });
      await deaasOverlookStore.loadServiceAreasInBoundsWithHex(
        this.state.northEast!,
        this.state.southEast!,
        this.state.southWest!,
        this.state.northWest!,
        this.state.centerLat,
        this.state.centerLng,
        ["timelines", "predictions", "min_pickup_minutes_settings"],
        this.state.zoom,
        {
          datetime: value,
          // 15分未満のデータは取得しない
          thresholdRequiredMinutes: 15,
        }
      );
      this.setState({ loadingServiceArea: false });
    };

    return (
      <div
        className={"request-map-container"}
        style={{ height: this.props.height || "calc(100vh - 180px)" }}
      >
        <GoogleMap
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          defaultCenter={{
            lat: this.state.centerLat,
            lng: this.state.centerLng,
          }}
          defaultZoom={this.state.defaultZoom}
          center={{
            lat: this.state.centerLat,
            lng: this.state.centerLng,
          }}
          options={{
            zoomControlOptions: {
              position: 7,
            },
          }}
          onChange={(e) => {
            this.onChangeMap(e);
          }}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => {
            deaasOverlookStore.setMap(map);
            this.setState({
              map: map,
              mapApi: maps,
              mapLoaded: true,
            });
          }}
        >
          {!this.props.hideMarkers && this.createStoreMarkers()}
          {!this.props.hideMarkers && this.createStoreCircle()}
          {!this.props.hideMarkers && this.createOfficeMarkers()}
          {this.props.showH3Areas && this.createH3IndicesPolygon()}
          {this.props.showStaffCountPredictions &&
            this.createStaffCountPredictionMarkers()}
          {this.createServiceAreaPolygon()}
          {!this.props.hideMarkers && this.createNewMarkers()}
          {!this.props.hideMarkers && this.createNewMarkerCircle()}
          {this.props.showPopInfoWindow && this.createHexInfoWindow()}
          {this.state.map && this.props.showH3Areas && (
            <MapControl
              mapApi={{ map: this.state.map, maps: this.state.mapApi }}
              position={"BOTTOM_CENTER"}
              style={{ left: 0, right: 0 }}
            >
              <div>
                <div
                  style={{
                    zIndex: 12,
                    width: 480,
                    padding: 8,
                    marginLeft: 16,
                    marginBottom: -16,
                    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                    background: "#ffffff",
                  }}
                >
                  <TimelineSlider
                    step={H3_CAPACITY_TIMELINE_INTERVAL_MINUTES}
                    min={9 * 60}
                    max={24 * 60 - H3_CAPACITY_TIMELINE_INTERVAL_MINUTES}
                    onChange={handleChangeTimeline}
                    style={{ marginTop: -4 }}
                    disabled={this.state.loadingServiceArea}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    zIndex: 12,
                    width: "100%",
                    padding: 8,
                    paddingBottom: 16,
                    marginTop: this.state.mouseOveredHex !== null ? 36 : 0,
                    background: "#ffffff",
                    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                    transform: `translateY(${
                      this.state.mouseOveredHex !== null ? 0 : 120
                    }px)`,
                    transition: "transform 0.5s ease",
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      width: "2em",
                      height: "2em",
                      border: "none",
                      borderRadius: "100%",
                      fontSize: "1rem",
                      color: "#777",
                    }}
                    onClick={() => {
                      this.setState({ clickedHex: null });
                    }}
                  >
                    <i className="fas fa-times" />
                  </button>
                  <H3CapacityTimelineChart h3Index={this.state.clickedHex} />
                </div>
              </div>
            </MapControl>
          )}
        </GoogleMap>
      </div>
    );
  }

  private async onChangeMap(e: ChangeEventValue) {
    await deaasOverlookStore.loadServiceAreasInBoundsWithHex(
      e.bounds.ne,
      e.bounds.se,
      e.bounds.sw,
      e.bounds.nw,
      e.center.lat,
      e.center.lng,
      ["timelines", "predictions", "min_pickup_minutes_settings"],
      e.zoom,
      {
        datetime: this.state.targetTime,
        // 15分未満のデータは取得しない
        thresholdRequiredMinutes: 15,
      }
    );
    this.setState({
      northEast: e.bounds.ne,
      southEast: e.bounds.se,
      southWest: e.bounds.sw,
      northWest: e.bounds.nw,
      zoom: e.zoom,
    });
    this.setCurrentLatLngZoomToLocalStrage(e.center.lat, e.center.lng, e.zoom);
  }

  private createOfficeMarkers() {
    return _.map(deaasOverlookStore.visibleOffices.slice(), (visibleOffice) => {
      if (!visibleOffice.visible) {
        return;
      }
      const model = visibleOffice.model;

      return (
        <OfficeSmallMarker
          key={Math.random()}
          lat={model.lat}
          lng={model.lng}
        />
      );
    });
  }

  private createStoreMarkers() {
    return _.map(
      deaasOverlookStore.visibleVendorCompanies.slice(),
      (visibleVendorCompany) => {
        if (!visibleVendorCompany.visible) {
          return;
        }
        const { model } = visibleVendorCompany;
        return (
          <VendorCompanySmallMarker
            key={Math.random()}
            lat={model.lat}
            lng={model.lng}
            name={model.name}
            address={`${model.zipcode} ${model.state} ${model.city} ${model.address} `}
          />
        );
      }
    );
  }

  private createStoreCircle() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }

    return _.map(
      deaasOverlookStore.visibleVendorCompanies.slice(),
      (visibleVendorCompany) => {
        if (!visibleVendorCompany.visible) {
          return;
        }
        const { model } = visibleVendorCompany;
        return (
          <CircleArea
            key={Math.random()}
            center={{
              lat: model.lat,
              lng: model.lng,
            }}
            range={deaasOverlookStore.vendorCompanyRange}
            map={this.state.map}
            mapApi={this.state.mapApi}
            color={MarkerColors.DEFAULT_COLOR}
          />
        );
      }
    );
  }

  private createServiceAreaPolygon() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }
    return _.map(
      deaasOverlookStore.visibleServiceAreas.slice(),
      (visibleServiceArea) => {
        if (!visibleServiceArea.visible) {
          return;
        }
        const { model } = visibleServiceArea;
        // ヘックスと一緒に表示する際にはサービスエリア範囲の描画を変える
        return (
          <WktMultiPolygon
            key={`service_area_${model.id}`}
            map={this.state.map}
            mapApi={this.state.mapApi}
            wktText={model.withinAreaWkt}
            strokeColor={this.props.showH3Areas ? "#333631" : "#79baff"}
            strokeWeight={this.props.showH3Areas ? 2 : 2}
            fillOpacity={this.props.showH3Areas ? 0 : 0.2}
          />
        );
      }
    );
  }

  private createH3IndicesPolygon() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }

    return _.map(deaasOverlookStore.intersectH3Indices.slice(), (h3Index) => {
      return (
        <WktMultiPolygon
          key={`h3_index_${h3Index.id}`}
          areaKey={`h3_index_${h3Index.id}`}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={h3Index.h3AreaWkt}
          fillColor={h3Index.getH3IndexColor()}
          // fillOpacity={0.20}
          strokeColor={h3Index.getH3IndexColor()}
          // strokeOpacity={0.8}
          onClickPolygon={async () => {
            H3CapatityTimelinesStore.getTimelinesInfo(h3Index.h3Index);
            this.setState({ clickedHex: h3Index });
          }}
          onMouseover={() => {
            if (this.props.showPopInfoWindow) {
              this.setState({ mouseOveredHex: h3Index });
            }
          }}
        />
      );
    });
  }

  private createHexInfoWindow() {
    if (!this.state.map || !this.state.mouseOveredHex) {
      return null;
    }

    const rowStyle = {
      marginBottom: 0,
    };

    return (
      <MapControl
        mapApi={{ map: this.state.map, maps: this.state.mapApi }}
        position={"LEFT_TOP"}
        style={{ left: 0, right: 0, padding: 10, width: 300, height: 155 }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
            borderRadius: 10,
          }}
        >
          <div style={{ color: "blue", padding: 10 }}>
            <p style={rowStyle}>
              ヘックス　　： {this.state.mouseOveredHex.nickName || " - "}(
              {this.state.mouseOveredHex.h3Index})
            </p>
            <p style={rowStyle}>
              キャパシティ： {this.state.mouseOveredHex.capacity}
            </p>
            <p style={rowStyle}>
              見積もり時間(計算値)： {this.state.mouseOveredHex.requiredMinute}{" "}
              分
            </p>
            <p style={rowStyle}>
              見積もり時間(設定値)：{" "}
              {this.state.mouseOveredHex.manualRequiredMinute ?? "-"} 分
            </p>
            <p style={rowStyle}>
              スタッフ予想：{" "}
              {this.state.mouseOveredHex.prediction == null
                ? "なし"
                : this.state.mouseOveredHex.prediction + " 人"}
            </p>
            <p style={rowStyle}>
              更新日時　　：{" "}
              {this.state.mouseOveredHex.predictionUpdatedAt == null
                ? " - "
                : format(
                    this.state.mouseOveredHex.predictionUpdatedAt,
                    "MM月dd日 HH時mm分"
                  )}
            </p>
          </div>
        </div>
      </MapControl>
    );
  }

  private createStaffCountPredictionMarkers() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }

    return _.map(deaasOverlookStore.intersectH3Indices.slice(), (h3Index) => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(h3Index.centerLat, h3Index.centerLng),
        icon: {
          fillColor: "#f78733",
          fillOpacity: 0.8,
          path: google.maps.SymbolPath.CIRCLE,
          scale: 20,
          strokeColor: "#f78733",
          strokeWeight: 1.0,
        },
        label: {
          text: `${h3Index.prediction != null ? h3Index.prediction : "-"}`,
          color: "#FFFFFF",
          fontSize: "24px",
        },
      });

      if (h3Index.h3Index in this.predictionMarkers) {
        // 表示がちらつくので、可能な限り更新回数を減らすため、
        // ラベル(予想数)が変わらない場合はそのままにする
        const existedMarker = this.predictionMarkers[h3Index.h3Index];
        if (
          (h3Index.prediction == null &&
            existedMarker.getLabel()?.text == "-") ||
          `${h3Index.prediction}` == existedMarker.getLabel()?.text
        ) {
          return;
        } else {
          existedMarker.setMap(null);
        }
      }

      const popUp = new this.state.mapApi.InfoWindow({
        content: `
        <span style='color:blue; padding-right: 10px;'>
          ${
            h3Index.prediction == null
              ? "更新なし"
              : "更新日時：" +
                format(h3Index.predictionUpdatedAt!, "MM月dd日 HH時mm分")
          }
        </span>
        `,
      });

      marker.addListener("mouseover", (event: google.maps.MapMouseEvent) => {
        popUp.setPosition({ lat: h3Index.centerLat, lng: h3Index.centerLng });
        popUp.open({ map: this.state.map });
      });

      marker.addListener("mouseout", (event: google.maps.MapMouseEvent) => {
        popUp.close();
      });

      marker.setMap(this.state.map);
      this.predictionMarkers[h3Index.h3Index] = marker;
    });
  }

  private createNewMarkers() {
    return _.map(
      deaasOverlookStore.visibleNewMarkers.slice(),
      (visibleNewMarker) => {
        if (!visibleNewMarker.visible) {
          return;
        }
        return (
          <OverlookTempMarker
            key={Math.random()}
            lat={visibleNewMarker.lat}
            lng={visibleNewMarker.lng}
            map={this.state.map}
            mapApi={this.state.mapApi}
            dragend={(lat: number, lng: number) => {
              deaasOverlookStore.changeNewMarkerPosition(
                visibleNewMarker,
                lat,
                lng
              );
            }}
          />
        );
      }
    );
  }

  private createNewMarkerCircle() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }

    return _.map(deaasOverlookStore.visibleNewMarkers.slice(), (newMarker) => {
      if (!newMarker.visible) {
        return;
      }
      return (
        <CircleArea
          key={Math.random()}
          center={{
            lat: newMarker.lat,
            lng: newMarker.lng,
          }}
          range={deaasOverlookStore.newMarkerRange}
          map={this.state.map}
          mapApi={this.state.mapApi}
          color={MarkerColors.PROGRESS_COLOR}
        />
      );
    });
  }

  private setCurrentLatLngZoomToLocalStrage(lat, lng, zoom) {
    localStorage.setItem(this.getDefaultCenterLatKey(), lat);
    localStorage.setItem(this.getDefaultCenterLngKey(), lng);
    localStorage.setItem(this.getDefaultZoomKey(), zoom);
  }

  private getDefaultCenterLatKey() {
    return (
      MapAttributes.LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_CENTER_LAT +
      gon.current_user_id
    );
  }

  private getDefaultCenterLngKey() {
    return (
      MapAttributes.LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_CENTER_LNG +
      gon.current_user_id
    );
  }

  private getDefaultZoomKey() {
    return (
      MapAttributes.LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_ZOOM +
      gon.current_user_id
    );
  }

  private getDefaultCenterLat(): number {
    let lat = Number(localStorage.getItem(this.getDefaultCenterLatKey()));
    if (lat <= 1) {
      lat = MapAttributes.SHIBUYA_STATION_LATITUDE;
    }
    return lat;
  }

  private getDefaultCenterLng(): number {
    let lng = Number(localStorage.getItem(this.getDefaultCenterLngKey()));
    if (lng <= 1) {
      lng = MapAttributes.SHIBUYA_STATION_LONGITUDE;
    }
    return lng;
  }

  private getDefaultZoom(): number {
    let zoom: number = Number(localStorage.getItem(this.getDefaultZoomKey()));
    if (zoom <= 1) {
      zoom = MapAttributes.DEFAULT_ZOOM;
    }
    return zoom;
  }
}

export default observer(DeaasAreaOverlookMap);
