import React from 'react';
import {
  Data, 
  Cell, 
  TimelineData, 
  CarryStaffActivityTime
} from '../interfaces/CalendarTimeline';
import '../../assets/stylesheets/calendar.scss'
import _ from 'lodash';

const MAX_NUM_ELEMENTS = 30; //for pagenation
const MAX_PAGENATION_BUTTONS = 6; 
const INTERVAL = 5;
const CELL_WIDTH = 5;
const CELL_HEIGHT = 40;

const MAX_SUMMARY_SIZE = 2;

const summaryData: { [key: string]: {
  colorMap: { [key: number]: string};
  labelMap: { [key: number]: string};
  title: string;
}} = {
  "work_type": {
    colorMap: {
      0: "#f78733", //Anycarry
      10: "#EB3334", //出前館
      20: "#37475A", //AMAZON
      30: "#14a5e2", //Wolt
      100: "#383838", //休憩中
    },
    labelMap: {
      0: "Anycarry",
      10: "出前館",
      20: "AMAZON",
      30: "Wolt",
      100: "休憩中",
    },
    title: "業務区分"
  },
  "move_method": {
    colorMap: {
      0: "#f78733", //自転車
      10: "#51abff", //バイク
      20: "#656565", //車
    },
    labelMap: {
      0: "自転車",
      10: "バイク",
      20: "車",
    },
    title: "配達手段"
  }
};
interface Props{
  firstColumnTitle: string;
  secondColumnTitle: string;
  data: CarryStaffActivityTime[];
  isSummaryData: boolean;
}

interface State{
  timeline: {
    cells: Array<Cell>;
  };
  view: {
    pageNumber: number;
    currentPageIndex: number;
    pageList: Array<number>;
    sliceFrom: number;
    sliceTo: number;
  },
  data: Data[];
  isPagenationNeeded: boolean;
}

class CalendarTimelineView extends React.Component<Props, State>{

  constructor(props: Props) {
    super(props)

    const data = this.props.isSummaryData ? this.preprocessDataForSummary(this.props.data) : this.preprocessRawData(this.props.data);
    const pageNumber = Math.ceil(data.length / MAX_NUM_ELEMENTS);

    this.state = {
      timeline: {
        cells: this.getCells(INTERVAL),
      },
      view: {
        pageNumber: pageNumber,
        currentPageIndex: 0,
        pageList: Array.from(Array(pageNumber).keys()).slice(0, Math.floor(MAX_PAGENATION_BUTTONS / 2)),
        sliceFrom: 0,
        sliceTo: MAX_NUM_ELEMENTS,
      },
      data,
      isPagenationNeeded: data.length > MAX_NUM_ELEMENTS,
    };
  }


  render() {
    if (this.state.data.length === 0) {
      return (
        <div className="fade-in">
          <div className="wrapper">
            <span>該当するデータが見つかりませんでした。</span>
          </div>
        </div>
      )
    }

    return (
      <div className="fade-in">
        <div className="timeline">
          <div className="body">
            <div className="timeline-header">
              <div className="section section--title">
                {this.props.firstColumnTitle}
              </div>
              <div className="section section--sub-section">
                {this.props.secondColumnTitle}
              </div>
              <div className="section section--hour-tick-section">
                {
                  Array.from(Array(24).keys()).map((hour) => (
                    <div key={hour.toString()} className="cell">
                      {`${hour.toString()}時`}
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="timeline-content">
              {this.renderTimeline()}
            </div>
          </div>
          {
            this.state.isPagenationNeeded ? 
            <div className="pagenation">
              {this.renderPagenation()}
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }

  renderTimeline() {
    return (
      <div>
        {
          this.state.data.slice(this.state.view.sliceFrom, this.state.view.sliceTo).map((task) => {
            const labels = task.total.split(",");
            const cellHeight = labels.length === 1 ? CELL_HEIGHT : labels.length * (CELL_HEIGHT * 0.6);
            return (
              <div key={task.id} className="row">
                <div className="task" style={{ height: cellHeight }}>
                  {task.name}
                </div>
                <ul className="sub-section" style={{ height: cellHeight }}>
                  {
                    labels.map((label) => (
                      <li key={label}>
                        {label}
                      </li>
                    ))
                  }
                </ul>
                <div className="schedule-cell" style={{ height: cellHeight }}>
                  {
                    this.state.timeline.cells.map((cell, index) => {
                      const scheduleFound = task.timelineData.find((d) => {
                        return (d.endAt.getHours() === cell.startHour && d.endAt.getMinutes() === cell.endMinute)
                      });
                      const width = this.getScheduleWidth(scheduleFound);
                      return (
                        <div key={index.toString()} className={this.isBoldLineRequired(index) ? "bg-cell-bold" : "hidden-cell"}>
                          {
                            scheduleFound ?
                            <div 
                              className="highlighted-cell" 
                              style={{ 
                                backgroundColor: scheduleFound.color ? scheduleFound.color : "#51abff", 
                                minWidth: width ? `${width}px` : '5px',
                                height: cellHeight * 0.7,
                                position: 'absolute',
                                top: '0px',
                                left: `-${width - CELL_WIDTH}px`,  
                              }}>
                                {
                                  width >= 12 * CELL_WIDTH ?
                                  <label style={{ color: 'white' }}>
                                    {scheduleFound.label}
                                  </label>
                                  :
                                  null
                                }
                            </div>
                            :
                            null
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  renderPagenation() {
    const isLeftDot = this.state.view.currentPageIndex - Math.floor(MAX_PAGENATION_BUTTONS / 2) > 0;
    const isRightDot = this.state.view.currentPageIndex + Math.floor(MAX_PAGENATION_BUTTONS / 2) < this.state.view.pageNumber;
    return (
      <>
        {
          isLeftDot && <div className="dot dot--left">{'...'}</div>
        }
        {
          this.state.view.pageList.map((page) => {
            let style = {};
            if (page === 0) {
              style = { 'borderTopLeftRadius': 4, 'borderBottomLeftRadius': 4 };
            } else if (page === this.state.view.pageNumber - 1) {
              style = { 'borderTopRightRadius': 4, 'borderBottomRightRadius': 4 };
            }
            return (
              <button 
                key={page.toString()} 
                style={style}
                className={page === this.state.view.currentPageIndex ? "pagenation-button pagenation-button--highlighted" : "pagenation-button"}
                name={page.toString()}
                onClick={this.onPagenationButtonClicked}>
                {(page + 1).toString()}
              </button>
            );
          })
        }
        {
          isRightDot && <div className="dot dot--right">{'...'}</div>
        }
      </>
    )
  }

  onPagenationButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { name } = event.currentTarget;
    const pageIndex = parseInt(name);
    if (pageIndex === this.state.view.currentPageIndex) return;

    if (pageIndex !== null) {
      const offset = Math.floor(MAX_PAGENATION_BUTTONS / 2);
      const start = pageIndex - offset < 0 ? 0 : pageIndex - offset;
      const end = pageIndex + offset > this.state.view.pageNumber ? this.state.view.pageNumber : pageIndex + offset;
      const sliceTo = (pageIndex + 1) * MAX_NUM_ELEMENTS;
      const sliceFrom = sliceTo - MAX_NUM_ELEMENTS;
      this.setState((prev) => ({
        ...prev,
        view: {
          ...prev.view,
          currentPageIndex: pageIndex,
          sliceFrom,
          sliceTo,
          pageList: Array.from(Array(prev.view.pageNumber).keys()).slice(start, end)
        }
      }))
    }
  }

  isBoldLineRequired(index: number): boolean {
    if (INTERVAL === 5) {
      return (index + 1) % 12 === 0 || (index + 1) % 6 === 0;
    } else if (INTERVAL === 10) {
      return (index + 1) % 6 === 0 || (index + 1) % 3 === 0;
    }
    return (index + 1) % 4 === 0 || (index + 1) % 2 === 0;
  }

  getScheduleWidth(timelineData: TimelineData | undefined): number {
    if (!timelineData) return 0;
    const diffInMinute = Math.ceil(((timelineData.endAt.valueOf() - timelineData.startAt.valueOf()) / (INTERVAL * 60 * 1000)))
    return diffInMinute * CELL_WIDTH;
  }


  isMergeNeeded(t1: TimelineData, t2: TimelineData): boolean {
    if (t1.label !== t2.label) {
      //ラベルで色分けしてるのでラベルが異なれば区切る
      return false;
    }
    if (Math.abs(t1.endAt.valueOf() - t2.startAt.valueOf()) > 60 * 1000) {
      //5分間のインターバルで送られるので1分以上差がある連続するデータは区切る
      return false;
    }
    return true;
  }

  mergeTimelineData(timelineData: TimelineData[], index: number): TimelineData[] {
    if (index === timelineData.length - 1 || timelineData.length === 0) {
      return timelineData;
    }

    const t = timelineData[index];
    const isMergeNeeded = this.isMergeNeeded(t, timelineData[index + 1]);
    if (!isMergeNeeded) {
      return this.mergeTimelineData(timelineData, index + 1);
    }

    const t2 = timelineData.splice(index+1, 1).shift()!;
    const mergedTimeline = timelineData.map((data, ind) => {
      if (ind === index) {
        return {
          ...t,
          endAt: t2.endAt
        };
      }
      return data;
    });

    return this.mergeTimelineData(mergedTimeline, index);
  }

  preprocessRawData(rawData: CarryStaffActivityTime[]): Data[] {
    const data: Data[] = [];
    for (const item of rawData) {
      const timelineData: TimelineData = {
        id: item.id.toString(),
        color: "skyblue",
        label: "",
        startAt: new Date(item.sdate),
        endAt: new Date(item.edate)
      };
      let carryStaffDataIndex = data.findIndex((carryStaff) => carryStaff.id === String(item.carry_staff_id));
      if (carryStaffDataIndex === -1) {
        data.push({ 
          id: item.carry_staff_id.toString(),
          name: item.name,
          total: '',
          timelineData: [timelineData]
        });
      } else {
        data[carryStaffDataIndex].timelineData.push(timelineData);
      }
    }

    return data.map((carryStaff) => {
      const timelineData = this.mergeTimelineData(carryStaff.timelineData.sort((a, b) => a.startAt.valueOf() - b.startAt.valueOf()), 0);
      const activityDurationInMinute = timelineData.reduce((acc, timeline) => acc + (Math.ceil(((timeline.endAt.valueOf() - timeline.startAt.valueOf()) / (60 * 1000)))), 0);
      const total = Number((activityDurationInMinute / 60).toFixed(2));
      const h = Math.floor(total).toString();
      const m = Math.round(60 * Number((total % 1).toFixed(2)));
      return {
        ...carryStaff,
        timelineData,
        total: `${h}h ${m}m`,
      } as Data
    });
  }

  getTotalHourForSummary(timeline: TimelineData): string {
    const total = Number(((timeline.endAt.valueOf() - timeline.startAt.valueOf()) / (60 * 60 * 1000)).toFixed(2))
    const h = Math.floor(total).toString();
    const m = Math.round(60 * Number((total % 1).toFixed(2)));
    
    return `${timeline.label}: ${h}h ${m}m`;
  }

  preprocessDataForSummary(rawData: CarryStaffActivityTime[]) {
    //配達スタッフアクティビティの詳細（配達手段、業務区分）
    let data: Data[] = Object.keys(summaryData).map((key, index) => {
      //filterでnullはfilter out
      const timeline: TimelineData[] = rawData
        .filter((item) => item[key as keyof CarryStaffActivityTime] !== null) 
        .map((item) => {
        return {
          id: item.id,
          color: summaryData[key].colorMap[item[key as keyof CarryStaffActivityTime]!],
          startAt: new Date(item.sdate),
          endAt: new Date(item.edate),
          label: summaryData[key].labelMap[item[key as keyof CarryStaffActivityTime]!],
        } as TimelineData;
      });
      return {
        id: index.toString(),
        name: summaryData[key].title,
        total: '',
        timelineData: timeline,
      } as Data;
    });

    return data.map((d) => {
      const timelineData = this.mergeTimelineData(d.timelineData.sort((a, b) => a.startAt.valueOf() - b.startAt.valueOf()), 0);

      const totalActivities = timelineData.sort((a, b) => (b.endAt.valueOf() - b.startAt.valueOf()) - (a.endAt.valueOf() - a.startAt.valueOf())).map((timeline) => this.getTotalHourForSummary(timeline));
      const total = totalActivities.length > MAX_SUMMARY_SIZE ? totalActivities.slice(0, MAX_SUMMARY_SIZE).join(",") + ` + 他${totalActivities.length - MAX_SUMMARY_SIZE}` : totalActivities.join(",");

      return {
        ...d,
        timelineData,
        total,
      }
    });
  }
  
  getCells(division: 5 | 10 | 15): Array<Cell> {
    const cells: Cell[] = [];
    for (let hour=0; hour<24; hour++) {
      for (let minute=0; minute<60; minute+=division) {
        cells.push({
          startHour: hour,
          startMinute: minute,
          endMinute: minute + division - 1,
        });
      }
    }
    return cells;
  };

};


export default CalendarTimelineView;
