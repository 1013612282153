import React from "react";
import {Card, Table} from "react-bootstrap";

interface Props {
    name: string
    address: string
    onCloseClick?: (event: any) => void
}

export class VendorCompanyCard extends React.Component<Props> {
    render() {
        const name = this.props.name
        const address = this.props.address
        const onCloseClick = this.props.onCloseClick

        return (
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <div style={{fontSize: "0.75rem"}}>
                            店舗様
                        </div>
                        <div onClick={onCloseClick}>閉じる</div>
                    </div>
                </Card.Header>
                <Card.Body style={{fontSize: "0.85rem"}} className="mx-0 mb-0 pb-0">
                    <Card.Title style={{fontSize: "1rem"}}>
                        { name }
                    </Card.Title>
                    <Table>
                        <tbody>
                        <tr>
                            <th style={{whiteSpace: "nowrap"}}>
                                住所
                            </th>
                            <td>
                                {address}
                            </td>
                        </tr>

                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }
}