import format from 'date-fns/format'
import _ from 'lodash'
import { observer } from "mobx-react";
import React from "react";
import Modal from 'react-modal';


declare var gon: any;

interface ImageInfo {
  url: string;
  key: string;
  staffName: string;
  latitude: number;
  longitude: number;
  tookAt: string;
}

interface Props {
  imageInfoList: ImageInfo[]
}

interface State {
  modalIsOpen: boolean;
  targetImageInfo: ImageInfo|null;
}

Modal.setAppElement('#wrapper');

class PlacementImages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      targetImageInfo: null
    };
  }

  componentDidMount(): void {
  }

  clickImage(imageInfo: ImageInfo): void {
    // 「位置情報 読込日時」という文字が上に被ってしまうので一旦非表示にする
    const positionReadAtElement = document.getElementById("request-map-postion-read-at")!;  
    positionReadAtElement.style.display = 'none';
    // 依頼俯瞰へのリンクが上に被ってしまうので一旦非表示にする
    const requestMapOverlookLinkElement = document.getElementById("request-map-overlook-link");
    if (requestMapOverlookLinkElement) {
      requestMapOverlookLinkElement.style.display = 'none';
    }
    this.setState({ modalIsOpen: true, targetImageInfo: imageInfo });
  }

  closeModal(): void {
    // 消したものを戻す
    const positionReadAtElement = document.getElementById("request-map-postion-read-at")!;  
    positionReadAtElement.style.display = 'inline';
    const requestMapOverlookLinkElement = document.getElementById("request-map-overlook-link");
    if (requestMapOverlookLinkElement) {
      requestMapOverlookLinkElement.style.display = 'inline';
    }
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { imageInfoList } = this.props;
    if (!imageInfoList || imageInfoList.length === 0) {
      return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span className='h5 mb-2 text-gray-800'>置き配画像</span>
          <span>なし</span>
        </div>
      )
    }

    return (
      <div>
        <span className='h5 mb-2 text-gray-800'>置き配画像</span>
        <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center'}}>
          {this.renderImages()}
        </div>
        {this.state.targetImageInfo && <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.closeModal()}
          style={customStyles}
          contentLabel="Placement image detail dialog"
        >
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', marginBottom: 5}}>
              <div style={{justifyContent: 'flex-end', flexGrow: 2}}>撮影者 : </div>
              <div style={{justifyContent: 'flex-start', flexGrow: 10}}>{this.state.targetImageInfo.staffName}</div>
            </div>
            <div style={{display: 'flex', marginBottom: 5}}>
              <div style={{justifyContent: 'flex-end', flexGrow: 2}}>緯度 : </div>
              <div style={{justifyContent: 'flex-start', flexGrow: 10}}>{this.state.targetImageInfo.latitude}</div>
            </div>
            <div style={{display: 'flex', marginBottom: 5}}>
              <div style={{justifyContent: 'flex-end', flexGrow: 2}}>経度 : </div>
              <div style={{justifyContent: 'flex-start', flexGrow: 10}}>{this.state.targetImageInfo.longitude}</div>
            </div>
            <div style={{display: 'flex', marginBottom: 5}}>
              <div style={{justifyContent: 'flex-end', flexGrow: 2}}>撮影日時 : </div>
              <div style={{justifyContent: 'flex-start', flexGrow: 10}}>{format(new Date(this.state.targetImageInfo.tookAt), 'yyyy年MM月dd日　HH時mm分ss秒')}</div>
            </div>
            <div style={{margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={this.state.targetImageInfo.url} style={{maxHeight: 900, maxWidth: 900}}></img>
            </div>
            <div style={{margin: 10, display: 'flex', justifyContent: 'flex-end'}}>
              <button onClick={() => this.closeModal()} className='btn btn-secondary'>閉じる</button>
            </div>
          </div>
        </Modal>}
      </div>
    );
  }

  private renderImages() {
    const { imageInfoList } = this.props;
    return _.map(imageInfoList, (imageInfo) => {
      return (
        <div
          key={imageInfo.key}
          style={{height: 120, width: 120, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
          onClick={() => this.clickImage(imageInfo)}
        >
          <img src={imageInfo.url} style={{maxHeight: 100, maxWidth: 100}}></img>
        </div>
      )
    })
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'scroll',
    height: '600px'
  }
};

export default observer(PlacementImages);
