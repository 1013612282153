import React from "react";
import Colors from "../../../constants/BootstrapColors";
import { MapItemZindexConst } from "../../../constants/MapItemZIndices";
import Request from "../../../interfaces/Request";
import ThirdPartyDeliveryTask from "../../../interfaces/ThirdPartyDeliveryTask";
import requestsStore from "../../../stores/RequestsStore";
import thirdPartyDeliveryTasksStore from "../../../stores/ThirdPartyDeliveryTasksStore";
import { formatIso8601Time } from "../../../utils/TimeUtils";
import { MapInfoWindow } from "../../MapInfoWindow";
import { SenderCard } from "../../MapInfoWindow/SenderCard";
import { MapMarkerPin } from "../../MapMarkerPin";
import { isRequest } from "../utils/MarkerUtils";

interface Props {
  request: Request | ThirdPartyDeliveryTask;
  lat: number;
  lng: number;
  iconColor?: string;
  zIndex?: number;
  zoomLevel?: number;
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;
  latestClickedMapAt?: Date;
}

interface State {
  showInfoWindow: boolean;
}

export default class SenderSmallMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false,
    };

    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    // 地図がクリックされたときに開いている詳細ウィンドウを閉じるため
    if (this.props.latestClickedMapAt !== prevProps.latestClickedMapAt) {
      this.setState({ showInfoWindow: false }, () => {
        requestsStore.resetInfoWindowVisible();
        thirdPartyDeliveryTasksStore.resetInfoWindowVisible();
      });
    }
  }

  // 地図がクリックされたときのクリック対象が詳細ウィンドウ内の場合は閉じないよう
  private onClickInfoWindow(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault();
    event.stopPropagation();
  }

  private toggleInfoWindow(event: Event) {
    this.setState({ showInfoWindow: !this.state.showInfoWindow }, () => {
      // 詳細モーダルの表示・非表示に合わせて道順表示を切り替える
      const request = this.props.request;
      if (isRequest(request)) {
        requestsStore.updateInfoWindowVisible(
          request.id,
          this.state.showInfoWindow
        );
      } else {
        thirdPartyDeliveryTasksStore.updateInfoWindowVisible(
          request.id,
          this.state.showInfoWindow
        );
      }
    });
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const request = this.props.request;
    const showInfoWindow = this.state.showInfoWindow;
    const pinSize = 28;
    const zIndex = showInfoWindow
      ? MapItemZindexConst.SELECTED_MARKER
      : this.props.zIndex;
    const badgeText = () => {
      let text = "";
      if (
        isRequest(request) &&
        request.isReserved &&
        request.deliveryType !== "route"
      ) {
        text += "予約";
      }
      if ("estimatedPickupTime" in request && request.estimatedPickupTime) {
        // textが空じゃなければスペースをあける
        if (text !== "") {
          text += " ";
        }
        text += formatIso8601Time(request.estimatedPickupTime);
      }
      return text;
    };

    return (
      <div>
        <MapMarkerPin
          svgIcon="shop"
          pinShape="circle"
          size={showInfoWindow ? pinSize * 1.5 : pinSize}
          pinBackgroundColor="#fff"
          svgIconColor={this.props.iconColor ?? Colors.PRIMARY_COLOR}
          zIndex={zIndex}
          onClick={this.toggleInfoWindow}
          topRightBadgeText={badgeText()}
        />
        {showInfoWindow && (
          <div onClick={(e) => this.onClickInfoWindow(e)}>
            <MapInfoWindow>
              <SenderCard
                deliveryRequest={request}
                onCloseClick={() => {
                  this.setState(
                    {
                      showInfoWindow: false,
                    },
                    () => {
                      if (isRequest(request)) {
                        requestsStore.updateInfoWindowVisible(
                          request.id,
                          false
                        );
                      } else {
                        thirdPartyDeliveryTasksStore.updateInfoWindowVisible(
                          request.id,
                          false
                        );
                      }
                    }
                  );
                }}
              />
            </MapInfoWindow>
          </div>
        )}
      </div>
    );
  }
}
