import React, {useState} from 'react';

export interface ProgressGaugeData {
    value: number;
    title: string;
    color?: string;
}

interface Props {
    // 完了扱いのタスク一覧
    data: ProgressGaugeData[];
    // 未完了も含めたタスク数
    total: number;
    style?: React.CSSProperties;
    hidePopover?: boolean;
}

const ProgressGauge = (props: Props) => {
    const {data, total, hidePopover} = props;
    //  完了扱いのタスク総数
    const completedTasksTotal = data.reduce((acc, item) => acc + item.value, 0);
    //  totalに対して完了扱いのタスクの割合
    const containerPercentage = (completedTasksTotal / total) * 100;
    const [height, borderRadius] = [20, 12]
    const [onHover, setOnHover] = useState(false);
    ;
    return (
        <div
            className={"ProgressGauge"}
            style={{
                width: '100%',
                height: height,
                backgroundColor: total > 0 ? '#e0e0e0' : 'transparent',
                borderRadius: borderRadius,
                position: 'relative',
                ...props.style
            }}
            onMouseOver={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
        >
            {
                total > 0 &&
                <div
                    className={"GaugeContainer"}
                    style={{
                        display: 'flex',
                        width: `${containerPercentage}%`,
                        height: height,
                        borderRadius: borderRadius,
                        overflow: 'hidden',
                        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.4)'
                    }}
                >
                    {data.map((item, index) => {
                        const gaugeWidth = (item.value / completedTasksTotal) * 100;

                        return (
                            <div
                                className={"Gauge"}
                                key={index}
                                style={{
                                    width: `${gaugeWidth}%`,
                                    backgroundColor: item.color,
                                    textAlign: 'center',
                                    color: 'white',
                                    lineHeight: '1.5',
                                    whiteSpace: 'nowrap',
                                    height: '100%',
                                }}
                            />
                        );
                    })}
                </div>
            }
            {
                onHover && !hidePopover && (total > 0) && (
                    <div
                        className={"GaugeDetail"}
                        style={{
                            position: 'absolute',
                            top: height + 4,
                            left: 0,
                            width: '100%',
                            display: 'flex',
                            border: '1px solid #ccc',
                            boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                            padding: '4px',
                            backgroundColor: 'white',
                            borderRadius: '4px',
                            zIndex: 2
                        }}
                    >
                        <table style={{
                            textAlign: 'left',
                            border: "none"
                        }}>
                            <tbody>
                            {
                                data.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableHeading>
                                                <div
                                                    style={{
                                                        fontWeight: 'normal',
                                                        borderLeft: "6px solid " + item.color,
                                                        paddingLeft: 8,
                                                    }}
                                                >
                                                    {item.title}
                                                </div>

                                            </TableHeading>
                                            <TableCell>
                                                {item.value}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            <TableRow>
                                <TableHeading>
                                    <div
                                        style={{
                                            fontWeight: 'normal',
                                            borderLeft: "6px solid #e0e0e0",
                                            paddingLeft: 8,
                                        }}
                                    >

                                      残り
                                    </div>
                                </TableHeading>
                                <TableCell>
                                    {total - completedTasksTotal}
                                </TableCell>
                            </TableRow>
                            </tbody>
                        </table>
                    </div>
                )
            }
        </div>
    );
}
const TableRow = (props: { children: React.ReactNode }) => {
    return (
        <tr
            style={{
                border: "none",
            }}
        >
            {props.children}
        </tr>
    )
}

const TableHeading = (props: { children: React.ReactNode }) => {
    return (
        <th
            style={{
                border: "none",
                padding: "1px 8px 1px 0",
            }}
        >
            {props.children}
        </th>
    )
}
const TableCell = (props: { children: React.ReactNode }) => {
    return (
        <td
            style={{
                fontWeight: 'bold',
                textAlign: 'right',
                border: "none",
                padding: "1px 0"
            }}
        >
            {props.children}
        </td>
    )
}

export default ProgressGauge;