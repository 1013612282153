import { format } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import { ToastContainer, toast, Slide } from "react-toastify";

import DateSelection from "../../components/Common/DateSelection";
import Pagination from "../../components/Common/Pagination";
import BulkRegisterSchedulesModal from "./BulkRegisterSchedulesModal";
import CarryStaffScheduleRow from "./CarryStaffScheduleRow";
import { ScheduleConst, ScheduleStyleConst } from "./Constants";
import EditScheduleModal from "./EditScheduleModal";
import {
  ExpandedRawRequest,
  RawCarryStaff,
  RawCarryStaffCandidateTerritory,
  RawCarryStaffWithSchedules,
  RawTerritory,
  RawVehicleType,
  Schedule,
} from "./Interfaces";
import NumberInput from "./NumberInput";
import {
  createIndependentRequestsArrangementMap,
  classifyRequests,
} from "./PositionUtils";
import RequestInfoModal from "./RequestInfoModal";
import RequestMapModal from "./RequestMapModal";
import {
  convertRawReqsIntoExpandedReqs,
  convertResponseIntoCarryStaffs,
  createDefaultSchedule,
  deleteSchedule,
  loadRequests,
  loadSchedules,
  saveSchedule,
  bulkRegisterSchedules,
  updateCarryStaffImageUrlExpiredAt,
} from "./Utils";

Modal.setAppElement("#wrapper");

interface Props {
  territories: RawTerritory[];
  vehicleTypes: RawVehicleType[];
  selectableCarryStaffs: RawCarryStaff[];
  carryStaffCandTerritories: RawCarryStaffCandidateTerritory[];
  canCreate: boolean;
  canUpdate: boolean;
  canDestroy: boolean;
}

interface State {
  carryStaffs: RawCarryStaffWithSchedules[];
  requests: ExpandedRawRequest[];
  searchText: string | null;
  selectedDate: string; // YYYY-MM-dd形式
  targetSchedule: Schedule;
  page: number;
  lastPage: number;
  perPage: number;
  isModalOpen: boolean;
  modalErrorMessage: string | null;
  isLoading: boolean;
  isBulkRegisterSchedulesModalOpen: boolean;
  selectedBulkRegisterFile: File | null;
  isProcessingBulkRegister: boolean;
  bulkRegisterSchedulesModalErrorMessage: string | null;
  isRequestModalOpen: boolean;
  isRequestMapModalOpen: boolean;
  isRequestsVisible: boolean;
  selectedRequestData: {
    requests: ExpandedRawRequest[];
    carryStaff: RawCarryStaffWithSchedules;
  } | null;
  selectedCasData: {
    requests: ExpandedRawRequest[];
    carryStaff: RawCarryStaffWithSchedules;
  } | null;
  maxRequestRowCount: number; // 範囲の重複する依頼の表示行数上限
}

class CarryStaffSchedules extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      carryStaffs: [],
      requests: [],
      searchText: null,
      selectedDate: format(new Date(), "yyyy-MM-dd"),
      targetSchedule: createDefaultSchedule({}),
      page: 1,
      lastPage: 1,
      perPage: 10, // 現状変更する方法は用意していない(が、一応変更できるように)
      isModalOpen: false,
      modalErrorMessage: null,
      isLoading: false,
      isBulkRegisterSchedulesModalOpen: false,
      selectedBulkRegisterFile: null,
      isProcessingBulkRegister: false,
      bulkRegisterSchedulesModalErrorMessage: null,
      isRequestModalOpen: false,
      isRequestMapModalOpen: false,
      isRequestsVisible: false,
      selectedRequestData: null,
      selectedCasData: null,
      maxRequestRowCount: 5,
    };

    this.onSelectSchedule = this.onSelectSchedule.bind(this);
    this.onSaveSchedule = this.onSaveSchedule.bind(this);
    this.onCreateSchedule = this.onCreateSchedule.bind(this);
    this.onBulkRegisterSchedules = this.onBulkRegisterSchedules.bind(this);
    this.pressEnter = this.pressEnter.bind(this);
    this.onChangeWho = this.onChangeWho.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeFrom = this.onChangeFrom.bind(this);
    this.onChangeTo = this.onChangeTo.bind(this);
    this.onChangeTerritory = this.onChangeTerritory.bind(this);
    this.onChangeMemo = this.onChangeMemo.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitDelete = this.onSubmitDelete.bind(this);
    this.onChangeSelectedBulkRegisterFile = this.onChangeSelectedBulkRegisterFile.bind(this);
    this.onSubmitBulkRegister = this.onSubmitBulkRegister.bind(this);
    this.switchRequestsVisible = this.switchRequestsVisible.bind(this);
    this.calcRowBodyHeight = this.calcRowBodyHeight.bind(this);
    this.renderRequestItems = this.renderRequestItems.bind(this);
  }

  async componentDidMount() {
    await this.loadAndUpdateSchedulesAndRequests();
  }

  async loadAndUpdateSchedulesAndRequests() {
    try {
      this.setState({ isLoading: true });

      const [requestResponse, scheduleResponse] = await Promise.all([
        loadRequests({
          selectedDate: this.state.selectedDate,
        }),
        loadSchedules({
          selectedDate: this.state.selectedDate,
          searchText: this.state.searchText,
          page: this.state.page,
          perPage: this.state.perPage,
        }),
      ]);

      scheduleResponse.data.carry_staffs =
        await updateCarryStaffImageUrlExpiredAt(
          scheduleResponse.data.carry_staffs
        );
      const requests = convertRawReqsIntoExpandedReqs(requestResponse.data);
      const carrStaffs = convertResponseIntoCarryStaffs({
        carry_staffs: scheduleResponse.data.carry_staffs,
        schedules: scheduleResponse.data.schedules,
        territories: this.props.territories,
      });
      this.setState({
        requests: requests,
        carryStaffs: carrStaffs,
        lastPage: Math.ceil(
          Math.max(scheduleResponse.data.total_count, 1) / this.state.perPage
        ),
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async loadAndUpdateSchedules() {
    try {
      this.setState({ isLoading: true });
      const response = await loadSchedules({
        selectedDate: this.state.selectedDate,
        searchText: this.state.searchText,
        page: this.state.page,
        perPage: this.state.perPage,
      });
      response.data.carry_staffs = await updateCarryStaffImageUrlExpiredAt(
        response.data.carry_staffs
      );
      const carrStaffs = convertResponseIntoCarryStaffs({
        carry_staffs: response.data.carry_staffs,
        schedules: response.data.schedules,
        territories: this.props.territories,
      });
      this.setState({
        carryStaffs: carrStaffs,
        lastPage: Math.ceil(
          Math.max(response.data.total_count, 1) / this.state.perPage
        ),
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onCreateSchedule(params: { carryStaffId?: number }) {
    const newSchedule = createDefaultSchedule({
      carryStaffId: params.carryStaffId,
      dateStr: this.state.selectedDate,
      territories: this.props.territories,
    });
    this.setState({
      targetSchedule: newSchedule,
      isModalOpen: true,
    });
  }

  onSelectSchedule(schedule: Schedule) {
    this.setState({
      targetSchedule: schedule,
      isModalOpen: true,
    });
  }

  onSaveSchedule(schedule: Schedule) {
    this.setState({ targetSchedule: schedule }, async () => {
      await this.onSubmit();
    });
  }

  async pressEnter(e: React.KeyboardEvent) {
    if (e.key == "Enter") {
      await this.loadAndUpdateSchedulesAndRequests();
    }
  }

  onBulkRegisterSchedules() {
    this.setState({
      isBulkRegisterSchedulesModalOpen: true,
    });
  }

  onChangePage(e: React.MouseEvent, newPage: number) {
    e.preventDefault();
    if (newPage == this.state.page || newPage < 1) {
      return;
    }

    this.setState({ page: newPage }, async () => {
      await this.loadAndUpdateSchedulesAndRequests();
    });
  }

  onChangeWho(carrtStaffId: string) {
    this.setState({
      targetSchedule: {
        ...this.state.targetSchedule,
        carry_staff_id: carrtStaffId == "" ? null : +carrtStaffId,
      },
    });
  }

  onChangeDate(dateStr: string) {
    this.setState({
      targetSchedule: {
        ...this.state.targetSchedule,
        date: dateStr,
      },
    });
  }

  onChangeFrom(fromTime: string) {
    if (!fromTime.match(/\d{2}:\d{2}/g)) {
      return;
    }

    this.setState({
      targetSchedule: {
        ...this.state.targetSchedule,
        from_time: new Date(
          `${this.state.targetSchedule.date} ${fromTime}:00+09:00`
        ),
      },
    });
  }

  onChangeTo(toTime: string) {
    if (!toTime.match(/\d{2}:\d{2}/g)) {
      return;
    }

    this.setState({
      targetSchedule: {
        ...this.state.targetSchedule,
        to_time: new Date(
          `${this.state.targetSchedule.date} ${toTime}:00+09:00`
        ),
      },
    });
  }

  onChangeTerritory(territoryId: string) {
    this.setState({
      targetSchedule: {
        ...this.state.targetSchedule,
        territory_id: territoryId == "" ? null : +territoryId,
      },
    });
  }

  onChangeMemo(memo: string) {
    this.setState({
      targetSchedule: {
        ...this.state.targetSchedule,
        memo: memo,
      },
    });
  }

  switchRequestsVisible() {
    this.setState((prevState) => ({
      isRequestsVisible: !prevState.isRequestsVisible,
    }));
  }

  async onSubmit() {
    if (this.state.targetSchedule.carry_staff_id == null) {
      this.setState({
        modalErrorMessage: "配達スタッフを選択してください。",
      });
      return;
    }

    if (this.state.targetSchedule.territory_id == null) {
      this.setState({
        modalErrorMessage: "担当エリアを選択してください。",
      });
      return;
    }

    this.setState({
      modalErrorMessage: null,
    });

    try {
      const response = await saveSchedule(this.state.targetSchedule);
      await this.loadAndUpdateSchedules();
      this.setState(
        {
          isModalOpen: false,
          modalErrorMessage: null,
          targetSchedule: createDefaultSchedule({}),
        },
        () => {
          toast.info("更新しました。", {
            autoClose: 1000,
            closeButton: false,
            hideProgressBar: true,
            position: toast.POSITION.TOP_RIGHT,
            transition: Slide,
          });
        }
      );
    } catch (err) {
      this.setState({
        modalErrorMessage:
          err?.response?.data?.message || "問題が発生し、登録に失敗しました。",
      });
    }
  }

  async onSubmitDelete() {
    const scheduleId = this.state.targetSchedule.id;
    if (!scheduleId) {
      this.setState({
        modalErrorMessage: "対象のスケジュールが存在しませんでした。",
      });
      return;
    }

    this.setState({
      modalErrorMessage: null,
    });

    const isConfirmed = window.confirm("本当に削除しますか？");
    if (!isConfirmed) return;

    try {
      const response = await deleteSchedule(scheduleId);
      if (response.data.message == "failed") {
        this.setState({
          modalErrorMessage: response.data.message,
        });
        return;
      }
      await this.loadAndUpdateSchedules();
      this.setState(
        {
          isModalOpen: false,
          modalErrorMessage: null,
          targetSchedule: createDefaultSchedule({}),
        },
        () => {
          toast.info("削除しました。", {
            autoClose: 1000,
            closeButton: false,
            hideProgressBar: true,
            position: toast.POSITION.TOP_RIGHT,
            transition: Slide,
          });
        }
      );
    } catch (err) {
      this.setState({
        modalErrorMessage:
          err?.response?.data?.message || "問題が発生し、削除に失敗しました。",
      });
    }
  }

  onChangeSelectedBulkRegisterFile(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files as FileList;

    this.setState({
      selectedBulkRegisterFile: files[0] || null,
    });
  }

  async onSubmitBulkRegister() {
    if (!this.state.selectedBulkRegisterFile) {
      this.setState({
        bulkRegisterSchedulesModalErrorMessage: 'ファイルを選択してください',
      });
      return;
    }

    this.setState({
      isProcessingBulkRegister: true,
      bulkRegisterSchedulesModalErrorMessage: null,
    });

    try {
      const response = await bulkRegisterSchedules(this.state.selectedBulkRegisterFile);
      await this.loadAndUpdateSchedules();
      this.setState(
        {
          isBulkRegisterSchedulesModalOpen: false,
          selectedBulkRegisterFile: null,
          bulkRegisterSchedulesModalErrorMessage: null,
        },
        () => {
          toast.info('取込を開始しました。', {
            autoClose: 1000,
            closeButton: false,
            hideProgressBar: true,
            position: toast.POSITION.TOP_RIGHT,
            transition: Slide,
          });
        }
      );
    } catch (err) {
      this.setState({
        bulkRegisterSchedulesModalErrorMessage:
          err?.response?.data?.message || '問題が発生し、一括登録に失敗しました。',
      });
    } finally {
      this.setState({ isProcessingBulkRegister: false });
    }
  }

  // 依頼範囲の重複による高さ変動を考慮して親要素の高さを計算する
  calcRowBodyHeight(casRequests: ExpandedRawRequest[]) {
    const { independentRequests } = classifyRequests(
      this.state.selectedDate,
      casRequests,
      this.state.maxRequestRowCount
    );
    const arrangementMap = createIndependentRequestsArrangementMap(
      this.state.selectedDate,
      independentRequests
    );
    const rowBodyHeight = ScheduleStyleConst.ROW_BODY_HEIGHT;
    const requestItemHeight = ScheduleStyleConst.REQUEST_ITEM_HEIGHT;
    const requestItemBottomMargin =
      ScheduleStyleConst.REQUEST_ITEM_BOTTOM_MARGIN;

    return Math.max(
      rowBodyHeight,
      rowBodyHeight +
        (requestItemHeight + requestItemBottomMargin) *
          (arrangementMap.rowLength - 1)
    );
  }

  renderTopBar() {
    return (
      <div className="col-12 d-flex flex-wrap justify-content-between py-3">
        <div className="d-flex justify-content-start">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: 180 }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="名前"
              value={this.state.searchText || ""}
              onChange={(event) =>
                this.setState({ searchText: event.target.value })
              }
              onKeyPress={(e) => this.pressEnter(e)}
            />
            <div
              style={{
                position: "relative",
                transform: "translate(-30px, 0)",
                pointerEvents: "none",
              }}
            >
              <i className="fas fa-search" />
            </div>
          </div>

          <DateSelection
            date={new Date(`${this.state.selectedDate} 00:00:00+09:00`)}
            onChangeDate={(newDate) => {
              this.setState(
                { selectedDate: format(newDate, "yyyy-MM-dd") },
                async () => {
                  await this.loadAndUpdateSchedulesAndRequests();
                }
              );
            }}
          />
        </div>

        <div className="d-flex justify-content-end align-items-center">
          {this.state.isRequestsVisible && (
            <NumberInput
              value={this.state.maxRequestRowCount}
              onChange={(value: number) => {
                this.setState({ maxRequestRowCount: value });
              }}
            />
          )}
          <div className="mr-3">依頼表示</div>
          <div className="custom-control custom-switch custom-switch-xl">
            <input
              type="checkbox"
              id="isRequestVisible"
              className="custom-control-input"
              onClick={() => this.switchRequestsVisible()}
            ></input>
            <label
              className="custom-control-label text-hide"
              htmlFor="isRequestVisible"
            >
              dummy
            </label>
          </div>

          {this.props.canCreate && (
            <div className="d-flex align-items-center ml-2">
              <button
                className="btn btn-primary"
                onClick={() => this.onCreateSchedule({})}
              >
                スケジュール追加
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={() => this.onBulkRegisterSchedules()}
              >
                スケジュール一括登録
              </button>
              <a
                className="btn btn-info ml-2"
                href="/carry_staff_schedules_imports"
              >
                一括登録取込状況
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderTableRowHeaders() {
    return (
      <div className="border border-right-0" style={{ width: 150 }}>
        <div
          className="border-bottom d-flex justify-content-center align-items-center"
          style={{ height: ScheduleStyleConst.ROW_HEADER_HEIGHT }}
        >
          <span>配達スタッフ</span>
        </div>
        {this.state.carryStaffs.map((cas, index) => {
          const totalMnutes = cas.schedules
            .map((schedule) =>
              Math.floor(
                (schedule.to_time.getTime() - schedule.from_time.getTime()) /
                  1000 /
                  60
              )
            )
            .reduce((accm, current) => accm + current, 0);
          return (
            <div
              key={`cas_header_${index}`}
              className={
                index < this.state.carryStaffs.length - 1
                  ? "border-bottom d-flex"
                  : "d-flex"
              }
              style={{
                height: this.state.isRequestsVisible
                  ? this.calcRowBodyHeight(
                      this.state.requests.filter(
                        (req) => req.carry_staff_id === cas.id
                      )
                    )
                  : ScheduleStyleConst.ROW_BODY_HEIGHT,
              }}
            >
              <div className="col-4 px-1 d-flex justify-content-center align-items-center">
                <div>
                  {cas.image_url &&
                  cas.image_url_expired_at &&
                  new Date(cas.image_url_expired_at) > new Date() ? (
                    <img
                      style={{
                        backgroundImage: `url(${cas.image_url})`,
                        width: 45,
                        height: 45,
                        borderRadius: "50%",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                      }}
                    ></img>
                  ) : (
                    <i className="fas fa-user fa-lg" />
                  )}
                </div>
              </div>
              <div
                className="col-8 px-1 d-flex flex-column justify-content-center"
                style={{ fontSize: 10 }}
              >
                <div className="mb-1">{cas.name}</div>
                <div className="d-flex justify-content-atart align-items-center">
                  <i className="far fa-clock mr-2" />
                  <div>
                    <span>
                      {("00" + Math.floor(totalMnutes / 60)).slice(-2)}:
                      {("00" + (totalMnutes % 60)).slice(-2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  rendedTableRowBodies() {
    return (
      <div
        className="border d-flex flex-column"
        style={{ flexGrow: 1, flexBasis: 0, overflowX: "hidden" }}
      >
        <div className="d-flex" style={{ width: "100%" }}>
          {[...Array(ScheduleConst.TO_TIME - ScheduleConst.FROM_TIME)]
            .map((_, i) => i + ScheduleConst.FROM_TIME)
            .map((fromTime) => {
              return (
                <div
                  key={`table_column_${fromTime}`}
                  className="border-bottom border-left flex-grow-1 d-flex align-items-center"
                  style={{
                    height: ScheduleStyleConst.ROW_HEADER_HEIGHT,
                    backgroundColor: "rgba(236, 236, 236, 0.8)",
                    overflowX: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span>{("00" + `${fromTime}`).slice(-2) + ":00"}</span>
                </div>
              );
            })}
        </div>
        {this.state.carryStaffs.map((cas, index) => {
          return (
            <CarryStaffScheduleRow
              key={`cas_row_${cas.id}`}
              isLastRow={index == this.state.carryStaffs.length - 1}
              carryStaff={cas}
              requests={this.state.requests}
              territories={this.props.territories}
              selectedDate={this.state.selectedDate}
              isRequestsVisible={this.state.isRequestsVisible}
              canCreate={this.props.canCreate}
              canUpdate={this.props.canUpdate}
              calcRowBodyHeight={this.calcRowBodyHeight}
              renderRequestItems={this.renderRequestItems}
              onSelectSchedule={this.onSelectSchedule}
              onSaveSchedule={this.onSaveSchedule}
            />
          );
        })}
      </div>
    );
  }

  onClickRequest(
    request: ExpandedRawRequest,
    carryStaff: RawCarryStaffWithSchedules
  ) {
    this.setState({
      isRequestModalOpen: true,
      selectedRequestData: { requests: [request], carryStaff },
    });
  }

  onClickGroupRequest(
    requests: ExpandedRawRequest[],
    carryStaff: RawCarryStaffWithSchedules
  ) {
    this.setState({
      isRequestModalOpen: true,
      selectedRequestData: { requests, carryStaff },
    });
  }

  renderRequestItems(
    carryStaff: RawCarryStaffWithSchedules,
    requests: ExpandedRawRequest[]
  ) {
    const casRequests = requests.filter(
      (req) => req.carry_staff_id === carryStaff.id
    );
    const { independentRequests, groupRequests } = classifyRequests(
      this.state.selectedDate,
      casRequests,
      this.state.maxRequestRowCount
    );
    const arrangementMap = createIndependentRequestsArrangementMap(
      this.state.selectedDate,
      independentRequests
    );
    return (
      <>
        {independentRequests.map((req) => {
          const position = arrangementMap.positionMap[`${req.id}`];
          const insertedIndex = arrangementMap.indexMap[`${req.id}`];
          return (
            <div
              key={`cas_request_${req.id!}`}
              className="rounded-pill"
              style={{
                position: "absolute",
                left: `${position.startRatioOfRequestToBase}%`,
                width: `${position.widthRatioOfRequestToBase}%`,
                height: ScheduleStyleConst.REQUEST_ITEM_HEIGHT,
                marginTop:
                  ScheduleStyleConst.REQUEST_ITEM_TOP_MARGIN +
                  (ScheduleStyleConst.REQUEST_ITEM_HEIGHT +
                    ScheduleStyleConst.REQUEST_ITEM_BOTTOM_MARGIN) *
                    insertedIndex,
                backgroundColor: "rgb(244, 81, 30, 0.6)",
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() => this.onClickRequest(req, carryStaff)}
              // 新規スケジュール作成のドラッグを無効にする
              onMouseDown={(event) => event.stopPropagation()}
            >
              <div
                className="rounded-pill"
                style={{
                  position: "absolute",
                  left: `0%`,
                  width: `${position.widthRatioOfPickupToRequest}%`,
                  minWidth: ScheduleStyleConst.REQUEST_ITEM_HEIGHT,
                  height: ScheduleStyleConst.REQUEST_ITEM_HEIGHT,
                  backgroundColor: "rgb(244, 81, 30)",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <i className="fas fa-cube fa-xs" style={{ color: "white" }} />
              </div>
              <div
                className="rounded-pill"
                style={{
                  position: "absolute",
                  right: "0%",
                  width: `${position.widthRatioOfDeliveryToRequest}%`,
                  minWidth: ScheduleStyleConst.REQUEST_ITEM_HEIGHT,
                  height: ScheduleStyleConst.REQUEST_ITEM_HEIGHT,
                  backgroundColor: "rgb(244, 81, 30)",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <i
                  className="fas fa-running fa-xs"
                  style={{ color: "white" }}
                />
              </div>
            </div>
          );
        })}
        {groupRequests.map((group, index) => {
          const requestCounts = group.requests.length;
          group.requests.sort((a, b) => a.id - b.id); // id昇順
          return (
            <div
              key={`cas_request_group_${index!}`}
              className="rounded-pill"
              style={{
                position: "absolute",
                left: `${group.range.startPosition}%`,
                width: `${
                  group.range.endPosition - group.range.startPosition
                }%`,
                height: ScheduleStyleConst.REQUEST_ITEM_HEIGHT,
                marginTop: ScheduleStyleConst.REQUEST_ITEM_TOP_MARGIN,
                backgroundColor: "rgb(244, 81, 30, 0.3)",
                cursor: "pointer",
                display: "flex",
              }}
              onClick={() =>
                this.onClickGroupRequest(group.requests, carryStaff)
              }
              // 新規スケジュール作成のドラッグを無効にする
              onMouseDown={(event) => event.stopPropagation()}
            >
              <div
                className="rounded-pill"
                style={{
                  position: "absolute",
                  left: "0%",
                  minWidth: ScheduleStyleConst.REQUEST_ITEM_HEIGHT,
                  height: ScheduleStyleConst.REQUEST_ITEM_HEIGHT,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <span
                  className="badge rounded-pill bg-light border"
                  style={{ color: "rgb(244, 81, 30)" }}
                >
                  {requestCounts >= 99 ? "99+" : requestCounts}
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  onClickRequestSituationButton(
    requests: ExpandedRawRequest[],
    carryStaff: RawCarryStaffWithSchedules
  ) {
    this.setState({
      isRequestMapModalOpen: true,
      selectedCasData: { requests, carryStaff },
    });
  }

  renderTableRowFooters() {
    return (
      <div className="border border-left-0" style={{ width: 160 }}>
        <div
          className="border-bottom"
          style={{ height: ScheduleStyleConst.ROW_HEADER_HEIGHT }}
        ></div>
        {this.state.carryStaffs.map((cas, index) => {
          const borderClassName =
            index < this.state.carryStaffs.length - 1 ? "border-bottom" : "";
          const requests = this.state.requests.filter(
            (req) => req.carry_staff_id === cas.id
          );
          return (
            <div
              key={`cas_footer_${cas.id}`}
              className={`d-flex justify-content-center align-items-center ${borderClassName}`}
              style={{
                width: "100%",
                height: this.state.isRequestsVisible
                  ? this.calcRowBodyHeight(requests)
                  : ScheduleStyleConst.ROW_BODY_HEIGHT,
              }}
            >
              {this.props.canCreate && (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    this.onCreateSchedule({ carryStaffId: cas.id })
                  }
                >
                  追加
                </button>
              )}
              <button
                className="btn btn-info btn-sm ml-2"
                onClick={() =>
                  this.onClickRequestSituationButton(requests, cas)
                }
              >
                依頼状況
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="d-flex flex-column px-3" style={{ width: "100%" }}>
          {this.renderTopBar()}

          <div className="d-flex mb-3" style={{ width: "100%" }}>
            {this.renderTableRowHeaders()}
            {this.rendedTableRowBodies()}
            {this.renderTableRowFooters()}
          </div>

          <Pagination
            displayPageCount={2}
            currentPage={this.state.page}
            lastPage={this.state.lastPage}
            onChangePage={(e, newPage) => {
              this.onChangePage(e, newPage);
            }}
          />
        </div>

        <ToastContainer />

        <EditScheduleModal
          selectableCarryStaffs={this.props.selectableCarryStaffs}
          carryStaffCandTerritories={this.props.carryStaffCandTerritories}
          territories={this.props.territories}
          vehicleTypes={this.props.vehicleTypes}
          schedule={this.state.targetSchedule}
          isModalOpen={this.state.isModalOpen}
          canCreate={this.props.canCreate}
          canUpdate={this.props.canUpdate}
          canDestroy={this.props.canDestroy}
          errorMessage={this.state.modalErrorMessage}
          onRequestClose={() =>
            this.setState({
              isModalOpen: false,
              modalErrorMessage: null,
              targetSchedule: createDefaultSchedule({}),
            })
          }
          onChangeWho={this.onChangeWho}
          onChangeDate={this.onChangeDate}
          onChangeFrom={this.onChangeFrom}
          onChangeTo={this.onChangeTo}
          onChangeTerritory={this.onChangeTerritory}
          onChangeMemo={this.onChangeMemo}
          onSubmit={this.onSubmit}
          onSubmitDelete={this.onSubmitDelete}
        />

        <BulkRegisterSchedulesModal
          isOpen={this.state.isBulkRegisterSchedulesModalOpen}
          selectedFile={this.state.selectedBulkRegisterFile}
          isProcessing={this.state.isProcessingBulkRegister}
          errorMessage={this.state.bulkRegisterSchedulesModalErrorMessage}
          onRequestClose={() => this.setState({
            isBulkRegisterSchedulesModalOpen: false,
            selectedBulkRegisterFile: null,
            bulkRegisterSchedulesModalErrorMessage: null,
          })}
          onChangeSelectedFile={this.onChangeSelectedBulkRegisterFile}
          onSubmit={this.onSubmitBulkRegister}
        />

        <RequestInfoModal
          requests={this.state.selectedRequestData?.requests}
          carryStaff={this.state.selectedRequestData?.carryStaff}
          isOpen={this.state.isRequestModalOpen}
          onRequestClose={() => this.setState({ isRequestModalOpen: false })}
        />

        <RequestMapModal
          requests={this.state.selectedCasData?.requests}
          carryStaff={this.state.selectedCasData?.carryStaff}
          selectedDate={this.state.selectedDate}
          territories={this.props.territories}
          isOpen={this.state.isRequestMapModalOpen}
          onRequestClose={() => this.setState({ isRequestMapModalOpen: false })}
        />
      </>
    );
  }
}

export default observer(CarryStaffSchedules);
