import { addMinutes, format } from "date-fns";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import { axiosGet, axiosPost } from "../../utils/AxiosClient";

interface Props {
  request_id: string;
}

const OfferMenuForm: FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const [shouldGetQuote, setShouldGetQuote] = useState(true);
  const [quoteSuccess, setQuoteSuccess] = useState(false);
  const [quoteFailed, setQuoteFailed] = useState(false);
  const [quoteMessage, setQuoteMessage] = useState("");
  const [quoteResult, setQuoteResult] = useState({
    fee: 0,
    edt: 0,
  });

  const [canCreateOffer, setCanCreateOffer] = useState(false);
  const [offerSuccess, setOfferSuccess] = useState(false);
  const [offerFailed, setOfferFailed] = useState(false);
  const [offerMessage, setOfferMessage] = useState("");
  const [offerResult, setOfferResult] = useState({
    fee: 0,
    edt: 0,
    pickup_eta: new Date(),
    dropoff_eta: new Date(),
  });

  const getMenuQuote = async () => {
    setLoading(true);
    setQuoteFailed(false);
    setQuoteSuccess(false);
    setQuoteMessage("");

    try {
      const res = (await axiosGet.get(
        `/api/requests/menu-quote?request_id=${props.request_id}`
      )) as {
        data: {
          message?: string;
          fee: number;
          edt: number;
        };
      };
      console.debug(res);
      setQuoteMessage("");
      setQuoteResult({
        fee: res.data.fee,
        edt: res.data.edt,
      });
      setCanCreateOffer(true);
      setShouldGetQuote(false);
    } catch (e) {
      console.log(e);
      setQuoteFailed(true);
      setQuoteMessage(e.response?.data?.message || "エラーが発生しました。");
    } finally {
      setLoading(false);
    }
  };

  const createMenuOffer = async () => {
    setLoading(true);
    setOfferFailed(false);
    setOfferSuccess(false);
    setOfferMessage("");
    const params: {
      request_id: string;
    } = { request_id: props.request_id };

    try {
      const res = (await axiosPost.post(
        "/api/requests/menu-offer",
        params
      )) as {
        data: {
          message?: string;
          fee: number;
          edt: number;
          pickup_eta: string;
          dropoff_eta: string;
        };
      };
      console.debug(res);
      setOfferSuccess(true);
      setOfferResult({
        fee: res.data.fee,
        edt: res.data.edt,
        pickup_eta: new Date(res.data.pickup_eta),
        dropoff_eta: new Date(res.data.dropoff_eta),
      });
    } catch (e) {
      console.log(e);
      setOfferFailed(true);
      setOfferMessage(e.response?.data?.message || "エラーが発生しました。");
    } finally {
      setLoading(false);
    }
  };

  const quoteLabel = "Menu見積もりを取得する";
  const offerLabel = "Menuに配達を依頼する";

  let renderObject;
  if (shouldGetQuote) {
    renderObject = (
      <>
        {quoteFailed && (
          <div className="modal-body">
            <div className="alert alert-warning">{quoteMessage}</div>
          </div>
        )}
        <div className="modal-footer">
          <button
            disabled={loading}
            onClick={getMenuQuote}
            className="btn btn-primary menu-btn"
          >
            {quoteLabel}
          </button>
        </div>
      </>
    );
  } else {
    if (offerSuccess) {
      renderObject = (
        <>
          <div className="modal-body">
            <div className="alert alert-success">
              <div>
                <span>依頼結果</span>
              </div>
              <br />
              <div>
                <span>料金: {offerResult.fee}円</span>
              </div>
              <div>
                <span>
                  店舗到着予想時間:{" "}
                  {format(offerResult.pickup_eta, "yyyy-MM-dd HH:mm")}
                </span>
              </div>
              <div>
                <span>
                  配達予想時間:{" "}
                  {format(offerResult.dropoff_eta, "yyyy-MM-dd HH:mm")}
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              disabled={loading}
              onClick={() => {
                window.location.reload();
              }}
              className="btn btn-primary menu-btn"
            >
              閉じる
            </button>
          </div>
        </>
      );
    } else {
      renderObject = (
        <>
          <div className="modal-body">
            <div className="alert alert-success">
              <div>
                <span>見積もり結果 ※まだ実際に配達は依頼されていません</span>
              </div>
              <br />
              <div>
                <span>料金: {quoteResult.fee}円</span>
              </div>
              <div>
                <span>配達所要分数: {quoteResult.edt}分</span>
              </div>
              <div>
                <span>
                  配達予想時間:{" "}
                  {format(
                    addMinutes(new Date(), quoteResult.edt),
                    "yyyy-MM-dd HH:mm"
                  )}
                </span>
              </div>
            </div>

            {offerFailed && (
              <div className="modal-body">
                <div className="alert alert-warning">{offerMessage}</div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              disabled={loading}
              onClick={createMenuOffer}
              className="btn btn-primary menu-btn"
            >
              {offerLabel}
            </button>
          </div>
        </>
      );
    }
  }

  return renderObject;
};

export default observer(OfferMenuForm);
