export default {
    DEFAULT: {
        strokeColor: "#362ec4",
        strokeOpacity: 0.25,
        strokeWeight: 1,
        fillColor: "#362ec4",
        fillOpacity: 0.075,
    },
    DEFAULT_HOVER: {
        strokeColor: "#362ec4",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: "#362ec4",
        fillOpacity: 0.2,
    },
    DEFAULT_ACTIVE: {
        strokeColor: "#362ec4",
        strokeOpacity: 0.5,
        strokeWeight: 4,
        fillColor: "#362ec4",
        fillOpacity: 0.3,
    },
    BLACK_ACTIVE: {
        strokeColor: "#000000",
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: "#000000",
        fillOpacity: 0.5,
    },
    UN_ACTIVE: {
      strokeColor: "#808080",
      strokeOpacity: 0.5,
      strokeWeight: 1,
      fillColor: "#808080",
      fillOpacity: 0.5,
  },
}
