import type { Coords } from "google-map-react";
import React, { useState } from "react";

import MapPolygonStyles from "../../constants/MapPolygonStyles";
import type { TownBorder } from "../../interfaces/TownBorder";
import GmapsUI, { Marker, PolygonByH3Index, PolygonByWkt } from "../ui/GmapsUI";
import type { MapApi } from "../ui/GmapsUI";

import useMapAreaPicker from "./hooks/useMapAreaPicker";
import usePathMode from "./hooks/usePathMode";
import useHexMode from "./hooks/useHexMode";
import useTownBordersMode from "./hooks/useTownBordersMode";
import useRadiusMode from "./hooks/useRadiusMode";
import useSelectableAreaMode from "./hooks/useSelectableAreaMode";

import MAPToolboxContentForPaths from "./controls/MAPToolboxContentForPaths";
import MAPToolboxContentForTownBorder from "./controls/MAPToolboxContentForTownBorder";
import MAPToolboxContentForHex from "./controls/MAPToolboxContentForHex";
import MAPToolboxContentForRadius from "./controls/MAPToolboxContentForRadius";
import MAPToolboxContentForSelectableAreas from "./controls/MAPToolboxContentForSelectableAreas";
import MAPIndicatorContent from "./controls/MAPIndicatorContent";
import MAPActionsContent from "./controls/MAPActionsContent";
import MAPModalContent from "./controls/MAPModalContent";
import MAPToolboxContent from "./controls/MAPToolboxContent";
import MAPDrawerContent from "./controls/MAPDrawerContent";

export type PickMode =
  | "Paths"
  | "TownBorder"
  | "Hex"
  | "Radius"
  | "SelectableArea";
const DEFAULT_PICK_MODE: PickMode = "Paths";

interface Props {
  gmapsApiKey: string;
  center: Coords;
  zoom?: number;
  wkt: string;
  selectableAreas?: { key: string; label: string; areaWkt: string }[];
  unEditableWkts?: (string | null)[];
  pins?: JSX.Element[];
  showToast?: boolean;
  notNull?: boolean;
  onUpdateByWkt?: (multiPolygonsWkt: string) => Promise<void>;
  onDestroy?: () => Promise<void>;
}

export default function MapAreaPicker(props: Props) {
  const {
    gmapsApiKey,
    center,
    zoom = 15,
    wkt,
    selectableAreas,
    unEditableWkts,
    pins,
    showToast = true,
    onDestroy,
    onUpdateByWkt,
    notNull = false
  } = props;

  const [mapApi, setMapApi] = useState<MapApi | undefined>();
  const [pickMode, setPickMode] = useState<PickMode>(DEFAULT_PICK_MODE);
  const [openDestroyDialog, setOpenDestroyDialog] = useState<boolean>(false);
  const [isLoadSave, setIsLoadSave] = useState(false);

  // 共通のHooks
  const {
    refMap,
    selectedFeature, // 選択中のエリア
    setSelectedFeature, // 選択中のエリアを保存
    addArea, // 選択エリアを追加して更新するメソッド
    subArea, // 選択エリアを除外して更新するメソッド
    destroyAll, // 全て削除するメソッド
    toggleFeature, // エリアの選択、未選択を切り替えるメソッド
    resetSelectedFeatures // 選択中のエリアを全て解除する
  } = useMapAreaPicker({
    onUpdateByWkt,
    onDestroy,
    wkt,
    notNull,
    showToast,
    map: mapApi?.map
  });

  // パスで選択モードのHooks
  const { editMode, setEditMode } = usePathMode({
    map: mapApi?.map,
    pickMode,
    wkt,
    selectedFeature
  });

  // 町域選択機能のHooks
  const {
    townBorders,
    hoverTownBorder,
    setHoverTownBorder,
    handleClickTownBorder,
    fetchTownBordersByCenter,
    fetchTownBordersByKeyword,
    changeCenter,
    townBordersCenter,
    centerTown
  } = useTownBordersMode({ toggleFeature, defaultCenter: center });

  // Hex選択機能のHooks
  const {
    hexSize,
    setHexSize,
    hoverHexIndex,
    clearHoverHexIndex,
    setHoverIndexByMouseCursor,
    handleClickHexArea
  } = useHexMode({ toggleFeature });

  // Radius選択機能のHooks
  const { radiusSize, latitude, longitude, setRadiusSize, setLatitude, setLongitude } = useRadiusMode({ toggleFeature });

  // 選択肢機能のHooks
  const { hoverSelectableArea, setHoverSelectableArea, handleClickSelection } =
    useSelectableAreaMode({ toggleFeature });

  // GoogleMapAPIの読み込み完了時に実行
  const handleMapLoad = (map: google.maps.Map, maps: typeof google.maps) => {
    setMapApi({ map, maps });

    // Hexモード時、選択済みエリアにマウスオーバーした時にホバー効果が出るように
    map.data.addListener("mouseover", setHoverIndexByMouseCursor);
  };

  // パスで選択モード中にマップ範囲内をクリックした時に現在の選択状態を保存する
  const handleClickMap = (event) => {
    if (pickMode === "Paths") {
      mapApi?.map.data.toGeoJson((feature) => {
        // @ts-ignore
        setSelectedFeature(feature as google.maps.Data.Feature);
      });
    }

    // 円で指定するモードの時にmapをクリックすると円を作成する
    if (pickMode === "Radius") {
      // クリックした座標とフォームに入力されている半径で円の作成を行う
      // @ts-ignore
      const myLatLng = new google.maps.LatLng(event.lat, event.lng);
      // @ts-ignore
      const circle = new google.maps.Circle({
        strokeOpacity: 0.7,
        strokeWeight: 2,
        fillOpacity: 0.3,
        center: myLatLng,
        radius: radiusSize
      });

      // mapに表示円をさせる
      mapApi!.map.data.add({
        // @ts-ignore
        geometry: new google.maps.Data.Polygon([getCirclePath(circle)])
      });
    }
  };

  // @ts-ignore
  const handleDragendCenterMarker = (e: google.maps.MouseEvent) => {
    const coords = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    };
    mapApi!.map.panTo(coords);
    changeCenter(coords);
  };

  /**
   * Actionsのイベントハンドラ(追加・除去・全削除など)
   */
  const handleClickAddArea = async () => {
    setIsLoadSave(true);
    addArea(mapApi!.map.data)
      .then(() => {
        resetSelectedFeatures(mapApi!.map);
      })
      .finally(() => setIsLoadSave(false));
  };

  const handleClickRemoveArea = async () => {
    setIsLoadSave(true);
    subArea(mapApi!.map.data)
      .then(() => {
        resetSelectedFeatures(mapApi!.map);
      })
      .finally(() => setIsLoadSave(false));
  };

  const handleClickDestroyConfirm = () => {
    setOpenDestroyDialog(true);
  };

  const handleClickDestroyExecute = () => {
    if (!onDestroy) return;
    destroyAll()
      .then(() => {
        resetSelectedFeatures(mapApi!.map);
      })
      .finally(() => {
        setOpenDestroyDialog(false);
      });
  };

  /**
   * 緯度経度指定で円を追加する
   */
  const handleClickAddCircle = (latitude: string, longitude: string, radiusSize: number) => {
    const numLatitude = Number(latitude);
    const numLongitude = Number(longitude);

    const myLatLng = new google.maps.LatLng(numLatitude, numLongitude);
    const circle = new google.maps.Circle({
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillOpacity: 0.3,
      center: myLatLng,
      radius: radiusSize
    });

    // mapに表示円をさせる
    mapApi!.map.data.add({
      geometry: new google.maps.Data.Polygon([getCirclePath(circle)])
    });

    mapApi!.map.setCenter({ lat: numLatitude, lng: numLongitude });
  }

  /**
   * circle関数で作成された円を渡し、geometryライブラリでそのpathを作成する関数
   */
  function getCirclePath(circle) {
    var numPts = 512;
    var path: google.maps.LatLng[] = [];
    for (var i = 0; i < numPts; i++) {
      path.push(
        google.maps.geometry.spherical.computeOffset(
          circle.getCenter(),
          circle.getRadius(),
          (i * 360) / numPts
        )
      );
    }
    return path;
  }

  /**
   * MAPに表示するUI
   */
  const toolbox = (
    <MAPToolboxContent
      pickMode={pickMode}
      setPickMode={setPickMode}
      pathsToolbox={
        <MAPToolboxContentForPaths
          editMode={editMode}
          onChangeEditMode={setEditMode}
          map={mapApi?.map}
          onDeletePaths={() => resetSelectedFeatures(mapApi?.map)}
        />
      }
      townBordersToolbox={
        <MAPToolboxContentForTownBorder
          fetchTownBorders={fetchTownBordersByCenter}
          centerPosition={townBordersCenter}
          centerTown={centerTown}
          onPanToCenter={(coords) => {
            if (!mapApi) return;
            mapApi.map.panTo(coords);
          }}
          onClickSearchAddress={() => refMap.current.toggleDrawer()}
        />
      }
      hexToolbox={
        <MAPToolboxContentForHex
          hexSize={hexSize}
          onChangeHexSize={setHexSize}
        />
      }
      radiusToolbox={
        <MAPToolboxContentForRadius
          radiusSize={radiusSize}
          latitude={latitude}
          longitude={longitude}
          onChangeRadiusSize={setRadiusSize}
          onChangeLatitude={setLatitude}
          onChangeLongitude={setLongitude}
          onClickAddCircle={handleClickAddCircle}
          hideAddButton={false}
        />
      }
      selectableAreasToolbox={
        selectableAreas && selectableAreas.length > 0 ? (
          <MAPToolboxContentForSelectableAreas
            areas={selectableAreas}
            onHover={(area) => {
              setHoverSelectableArea(area);
            }}
            onSelect={handleClickSelection}
          />
        ) : undefined
      }
    />
  );

  const indicator = (
    <MAPIndicatorContent
      content={
        (hoverTownBorder?.pref || "") +
        (hoverTownBorder?.city || "") +
        (hoverTownBorder?.town || "")
      }
    />
  );

  const actions = (
    <MAPActionsContent
      loading={isLoadSave}
      onClickRemove={handleClickRemoveArea}
      onClickAdd={handleClickAddArea}
      onClickDestroy={handleClickDestroyConfirm}
      pickMode={pickMode}
      canDestroy={!notNull}
    />
  );

  const modal = (
    <MAPModalContent
      onCancel={() => {
        setOpenDestroyDialog(false);
      }}
      onDestroy={handleClickDestroyExecute}
    />
  );

  const drawer = (
    <MAPDrawerContent
      mapApi={mapApi}
      wkt={wkt}
      selectedFeature={selectedFeature}
      onClickTown={(town: TownBorder) => {
        handleClickTownBorder(town);
      }}
      changeCenter={changeCenter}
      onSubmitSearch={fetchTownBordersByKeyword}
    />
  );

  const uiControls = {
    indicator,
    toolbox,
    actions,
    modal,
    drawer
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      <GmapsUI
        ref={refMap}
        apiKey={gmapsApiKey}
        center={center}
        zoom={zoom}
        onClickMap={handleClickMap}
        onMapLoaded={handleMapLoad}
        onMousemove={setHoverIndexByMouseCursor}
        onMouseout={clearHoverHexIndex}
        controlsComponents={uiControls}
        modalOpen={openDestroyDialog}
        onModalClose={() => setOpenDestroyDialog(false)}
      >
        {mapApi && mapApi.map && (
          <>
            {/* 町域読み込み範囲の中心点を示すマーカー */}
            <Marker
              options={{
                position: {
                  lat: townBordersCenter.lat,
                  lng: townBordersCenter.lng
                },
                draggable: true,
                visible: pickMode === "TownBorder"
              }}
              mapApi={mapApi}
              onDragend={handleDragendCenterMarker}
            />

            {/* 編集不可エリア */}
            {unEditableWkts?.map(
              (unEditableWkt, index) =>
                unEditableWkt && (
                  <PolygonByWkt
                    // 変更されたりはしないのでindexをkeyとして代用
                    key={index}
                    wkt={unEditableWkt}
                    mapApi={mapApi}
                    style={MapPolygonStyles.UN_ACTIVE}
                    options={{ zIndex: -1 }}
                  />
                )
            )}

            {/* 保存済みのエリア */}
            {wkt && (
              <>
                <PolygonByWkt
                  wkt={wkt}
                  mapApi={mapApi}
                  style={MapPolygonStyles.DEFAULT_ACTIVE}
                  onMousemove={setHoverIndexByMouseCursor}
                  options={{ zIndex: -1 }}
                />
              </>
            )}

            {/* 町域のポリゴン */}
            {pickMode === "TownBorder" &&
              townBorders.map((p) => {
                return (
                  <PolygonByWkt
                    mapApi={mapApi}
                    wkt={p.border}
                    key={`${p.id}-${townBorders.length}`}
                    style={MapPolygonStyles.DEFAULT}
                    hoverStyle={MapPolygonStyles.DEFAULT_HOVER}
                    onClick={() => handleClickTownBorder(p)}
                    onHover={() => setHoverTownBorder(p)}
                    onHoverOut={() => setHoverTownBorder(undefined)}
                    eventKey={`${p.id}-${townBorders.length}`}
                  />
                );
              })}

            {/* Hexカーソル位置を示すポリゴン */}
            {pickMode === "Hex" && (
              <>
                <PolygonByH3Index
                  hexIndex={hoverHexIndex}
                  mapApi={mapApi}
                  style={MapPolygonStyles.DEFAULT_HOVER}
                  onClick={handleClickHexArea}
                />
              </>
            )}

            {/* 選択肢のポリゴン */}
            {pickMode === "SelectableArea" && hoverSelectableArea && (
              <PolygonByWkt
                mapApi={mapApi}
                wkt={hoverSelectableArea.areaWkt}
                style={MapPolygonStyles.DEFAULT_HOVER}
                // hoverStyle={MapPolygonStyles.DEFAULT_HOVER}
              />
            )}
          </>
        )}
        {/* pinはGoogleMapReactの直下に持っていきたいので */}
        {pins}
      </GmapsUI>
    </div>
  );
}
