import { Coords } from "google-map-react";
import React from "react";
import { CarryStaffModel } from "../models/CarryStaffModel";


/**
 * RequestModelとThirdPartyDeliveryModelを共通で扱えるように、必要なプロパティだけ抽出した型
 */
interface DeliveryItem {
  sender: Coords;
  receiver: Coords;
  carryStaff: CarryStaffModel | null;
  routes: Coords[] | null;
  isEarlyStage: () => boolean;
  isDeliveryStage: () => boolean;
}

interface Props {
  mapApi: any;
  map: any;
  deliveryItem: DeliveryItem;
  useCasLocation?: boolean;
}

class RequestPolyline extends React.Component<Props> {
  // mapapiを使って描画する場合、reactのlife cycleとタイミングが合わない
  // そのため、state相当のlineを自分で管理する
  line1: any = null;
  line2: any = null;

  constructor(props: Props) {
    super(props);
  }

  componentWillUpdate() {
    // propsが更新されば場合は既存のlineをmap上から削除
    this.resetLines();
  }

  componentWillUnmount() {
    // コンポーネントが消されたとき、ラインが地図上からも表示されないようにする
    this.resetLines();
  }

  resetLines() {
    if (this.line1) this.line1.setMap(null);
    if (this.line2) this.line2.setMap(null);
  }

  /**
   * carry_staffから配達元までのパス(実線)と、配達元から配達先までのパス(破線)を描画するメソッド.
   * @param mapApi 
   * @param map 
   * @param sender 
   * @param receiver 
   * @param routes 
   * @param carryStaff 
   */
  private renderEarlyStage(
    mapApi: any,
    map: any,
    sender: Coords,
    receiver: Coords,
    routes: Coords[] | null,
    carryStaff: CarryStaffModel
  ) {
    this.line1 = new mapApi.Polyline({
      map: map,
      path: [
        { lat: carryStaff.lat, lng: carryStaff.lng },
        { lat: sender.lat, lng: sender.lng }
      ],
      ...this.getSolidPolylineStyles(mapApi)
    });

    this.line2 = new mapApi.Polyline({
      map: map,
      path: routes || [
        { lat: sender.lat, lng: sender.lng },
        { lat: receiver.lat, lng: receiver.lng }
      ],
      ...this.getDotPolylineStyles()
    });
  }

  /**
   * 配達元からcarryStaffまでのパス(破線)と、carryStaffから配達先までのパス(実線)を描画するメソッド.
   * @param mapApi 
   * @param map 
   * @param sender 
   * @param receiver 
   * @param carryStaff 
   */
  private renderDeliveryStage(
    mapApi: any,
    map: any,
    sender: Coords,
    receiver: Coords,
    carryStaff: CarryStaffModel
  ) {
    this.line1 = new mapApi.Polyline({
      map: map,
      path: [
        { lat: sender.lat, lng: sender.lng },
        { lat: carryStaff.lat, lng: carryStaff.lng }
      ],
      ...this.getDotPolylineStyles()
    });

    this.line2 = new mapApi.Polyline({
      map: map,
      path: [
        { lat: carryStaff.lat, lng: carryStaff.lng },
        { lat: receiver.lat, lng: receiver.lng }
      ],
      ...this.getSolidPolylineStyles(mapApi)
    });
  }

  /**
   * 配達元から配達先までのパス(破線)を描画するメソッド.
   * @param mapApi 
   * @param map 
   * @param sender 
   * @param receiver 
   * @param routes 
   */
  renderDotPolylineFromSenderToReceiver(
    mapApi: any,
    map: any,
    sender: Coords,
    receiver: Coords,
    routes: Coords[] | null
  ) {
    this.line1 = new mapApi.Polyline({
      map: map,
      path: routes || [
        { lat: sender.lat, lng: sender.lng },
        { lat: receiver.lat, lng: receiver.lng }
      ],
      ...this.getDotPolylineStyles()
    });
    this.line2 = null;
  }

  getDotPolylineStyles() {
    return {
      geodesic: true,
      strokeColor: 'rgba(47,149,220, 0.9)',
      strokeOpacity: 0,
      icons: [
        {
          icon: {
            path: 'M 0,-1 0,1',
            strokeOpacity: 0.8,
            scale: 3
          },
          offset: '0',
          repeat: '20px'
        }
      ]
    } as const;
  }

  getSolidPolylineStyles(mapApi: any) {
    return {
      geodesic: true,
      strokeColor: 'rgba(255, 193, 7, 0.8)',
      strokeOpacity: 1,
      strokeWeight: 3,
      icons: [
        {
          icon: {
            anchor: mapApi.Point(30, 0),
            path: mapApi.SymbolPath.FORWARD_CLOSED_ARROW,
            fillOpacity: 1,
            scale: 4
          },
          offset: '25%'
        }
      ]
    }
  }

  render() {
    const {
      mapApi,
      map,
      deliveryItem,
      useCasLocation
    } = this.props;

    const {
      sender,
      receiver,
      routes,
      carryStaff
    } = deliveryItem;

    // useCasLocationが指定されており、かつcarryStaffの位置情報が利用できる状態であることを示すフラグ
    const canUseCasLocation = !!useCasLocation &&
      !!carryStaff &&
      carryStaff.lat > 0 &&
      carryStaff.lng > 0;

    if (deliveryItem.isEarlyStage() && canUseCasLocation) {
      this.renderEarlyStage(mapApi, map, sender, receiver, routes, carryStaff);
    } else if (deliveryItem.isDeliveryStage() && canUseCasLocation) {
      this.renderDeliveryStage(mapApi, map, sender, receiver, carryStaff);
    } else {
      this.renderDotPolylineFromSenderToReceiver(mapApi, map, sender, receiver, routes);
    }

    return null;
  }
}

export default RequestPolyline;
