import React from "react";
import { ToastContainer, toast, Slide, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosPost } from "../../utils/AxiosClient";
import ConditionUnitComponent, {
  OnChangeStructureArgs,
} from "./ConditionUnitComponent";
import { AcceptableTableKey } from "./Constants";
import {
  MasterTag,
  MasterTagRule,
  MasterTagRuleDetail,
  ConditionUnit,
  RootConditionUnit,
  NotRootConditionUnit,
} from "./Interfaces";
import {
  addNode,
  convertPropsIntoTree,
  deleteNode,
  createDefaultTree,
  checkValid,
  convertToPostBody,
} from "./NodeUtils";

// 参考 : https://github.com/denoland/deno/issues/12754#issuecomment-970386235
declare global {
  interface Crypto {
    randomUUID: () => `${string}-${string}-${string}-${string}-${string}`;
  }
}

interface Props {
  tableKey: AcceptableTableKey;
  masterTag: MasterTag;
  masterTagRule: MasterTagRule | null;
  masterTagRuleDetails: MasterTagRuleDetail[];
}

interface State {
  rootNode: RootConditionUnit;
}

export default class MasterTagRuleEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      rootNode: convertPropsIntoTree(props),
    };
    this.onChangeStructure = this.onChangeStructure.bind(this);
    this.onChangeRelation = this.onChangeRelation.bind(this);
    this.createDefaultRules = this.createDefaultRules.bind(this);
    this.convertToJsxConditionExpression =
      this.convertToJsxConditionExpression.bind(this);
    this.reRender = this.reRender.bind(this);
    this.save = this.save.bind(this);
  }

  onChangeStructure(args: OnChangeStructureArgs) {
    const { node, operation, relationType } = args;
    if (operation === "create") {
      addNode({ tableKey: this.props.tableKey, node, relationType });
    } else {
      deleteNode({ node });
    }
    this.reRender();
  }

  onChangeRelation(node: ConditionUnit) {
    if (node.type === "root" || node.relation_to_right == null) {
      return;
    }

    node.relation_to_right = node.relation_to_right === "and" ? "or" : "and";
    this.reRender();
  }

  createDefaultRules() {
    this.setState({
      rootNode: createDefaultTree(this.props.tableKey),
    });
  }

  getOperatorLabel(node: NotRootConditionUnit) {
    if (node.relation_to_right) {
      return node.relation_to_right === "and" ? " かつ " : " または ";
    }

    return "";
  }

  convertToJsxConditionExpression(node: ConditionUnit) {
    if (node.type === "leaf") {
      return (
        <>
          {this.getOperatorLabel(node)}
          <span className="font-weight-bold">{node.codeLabel}</span>
        </>
      );
    }

    let expression: JSX.Element | null = null;
    for (const childNode of node.children) {
      if (childNode.type === "node") {
        expression = (
          <>
            {expression}
            {this.getOperatorLabel(childNode)}
            {"（"}
            {this.convertToJsxConditionExpression(childNode)}
            {"）"}
          </>
        );
      } else {
        expression = (
          <>
            {expression}
            {this.convertToJsxConditionExpression(childNode)}
          </>
        );
      }
    }

    return expression;
  }

  reRender() {
    // stateで管理されているrootノードを差し替えることで再描画
    const newRootNode: RootConditionUnit = {
      type: "root",
      code: "root",
      children: this.state.rootNode.children,
    };

    this.setState({
      rootNode: newRootNode,
    });
  }

  async save() {
    const validationResult = checkValid(this.state.rootNode);
    this.reRender();
    let toastOptions: ToastOptions = {
      autoClose: 1000,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
    };
    if (!validationResult.valid) {
      toastOptions.type = toast.TYPE.WARNING;
      const errorMessage =
        validationResult.invalidType == "no_conditions"
          ? "少なくとも1つ条件を指定してください。"
          : "指定されていない条件があります。入力してください。";
      toast.warning(errorMessage, toastOptions);
      return;
    }

    try {
      const response = await axiosPost.post(
        `/api/master_tags/${this.props.masterTag.id}/master_tag_rule`,
        convertToPostBody(this.props.tableKey, this.state.rootNode)
      );
      toast.success("保存しました。", toastOptions);
    } catch (error) {
      console.error(error);
      toastOptions.type = toast.TYPE.ERROR;
      toast.error("保存に失敗しました。", toastOptions);
    }
  }

  render() {
    return (
      <div className="d-flex flex-column">
        <div className="ml-3 mb-2" style={{ fontSize: 15 }}>
          条件 : {this.convertToJsxConditionExpression(this.state.rootNode)}
        </div>
        <div className="d-flex flex-column overflow-auto mb-2">
          <ConditionUnitComponent
            tableKey={this.props.tableKey}
            node={this.state.rootNode}
            position={{ depth: 1, breadth: 1 }}
            onChangeStructure={(args) => this.onChangeStructure(args)}
            onChangeRelation={(node) => this.onChangeRelation(node)}
          />
        </div>
        <div className="d-flex justify-content-between my-2">
          <div className="d-flex">
            <a
              data-confirm="削除してもよろしいですか？"
              className="btn btn-danger mr-3"
              rel="nofollow"
              data-method="delete"
              href={`/master_tags/${this.props.masterTag.id}/master_tag_rule/${this.props.tableKey}`}
            >
              削除
            </a>
            <button
              className="btn btn-outline-dark"
              onClick={() => this.createDefaultRules()}
              data-confirm="設定済みの条件を破棄してデフォルト条件に変更してもよろしいですか？"
            >
              デフォルト作成
            </button>
          </div>
          <div className="d-flex mr-2">
            <button
              className="btn btn-primary"
              onClick={async () => await this.save()}
            >
              保存
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
