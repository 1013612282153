import { action, observable } from "mobx";
import OfficesResponse from "../interfaces/OfficesResponse";
import { OfficeModel } from "../models/OfficeModel";
import { axiosGet } from "../utils/AxiosClient";

export class OfficesStore {
  offices = observable<OfficeModel>([]);

  @action
  public async loadOffices() {
    try {
      const response = await axiosGet.get<OfficesResponse[]>("/api/offices", {
        params: {},
      });
      const offices = response.data.map((item) => new OfficeModel(item));
      this.replaceOffices(offices);
    } catch (err) {
      console.log("Error", err);
      this.replaceOffices([]);
    }
  }

  @action
  private replaceOffices(offices: OfficeModel[]) {
    this.offices.replace(offices);
  }
}

const singleton = new OfficesStore();
export default singleton;
