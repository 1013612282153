import MapAttributes from "../constants/MapAttributes";


export function getMapDefaultValueFunctions(localStorageBaseKey: string, useGonDefaultLatLng = false) {

  const defaultCenterLatKey = `${localStorageBaseKey}_centerlat-${gon.current_user_id}`;
  const defaultCenterLngKey = `${localStorageBaseKey}_centerlng-${gon.current_user_id}`;
  const defaultZoomKey = `${localStorageBaseKey}_zoom-${gon.current_user_id}`;

  function setCurrentLatLngAndZoomToLocalStrage(lat: number, lng: number, zoom: number) {
    localStorage.setItem(defaultCenterLatKey, `${lat}`);
    localStorage.setItem(defaultCenterLngKey, `${lng}`);
    localStorage.setItem(defaultZoomKey, `${zoom}`);
  }

  function getDefaultCenterLat() {
    const lat = Number(localStorage.getItem(defaultCenterLatKey));
    const _lat = lat <= 1 ? (useGonDefaultLatLng ? gon.default_lat : MapAttributes.SHIBUYA_STATION_LATITUDE) : lat;
    return +_lat;
  }

  function getDefaultCenterLng() {
    const lng = Number(localStorage.getItem(defaultCenterLngKey));
    const _lng = lng <= 1 ? (useGonDefaultLatLng ? gon.default_lng : MapAttributes.SHIBUYA_STATION_LONGITUDE) : lng;
    return +_lng;
  }

  function getDefaultZoom() {
    const zoom: number = Number(localStorage.getItem(defaultZoomKey));
    const _zoom = zoom <= 1 ? MapAttributes.DEFAULT_ZOOM : zoom;
    return +_zoom;
  }

  return {
    setCurrentLatLngAndZoomToLocalStrage,
    getDefaultCenterLat,
    getDefaultCenterLng,
    getDefaultZoom
  }
}
