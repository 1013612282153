/**
 * =========================================================================================
 * この領域に限定されない汎用的なUtility関数群
 * =========================================================================================
 */

/**
 * targetSetがsetの部分集合であるかどうか(targetSet ⊆ set ？)を判定する関数.
 *
 * @param set
 * @param targetSet
 * @returns
 */
export function isSuperset<T>(set: Set<T>, targetSet: Set<T>) {
  for (const elem of Array.from(targetSet)) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

export function getIntersection<T>(setA: Set<T>, setB: Set<T>) {
  const _intersection = new Set();
  for (const elem of Array.from(setB)) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}
