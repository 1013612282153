// このコンポーネントを読み込むページでは
// %script{type:"text/javascript", src:"https://maps.google.com/maps/api/js?key=#{ENV["GOOGLE_MAP_API_KEY"]}&libraries=geometry"}
// を宣言してgeometry libraryを有効にしておくこと

import React from "react";
import { observer } from "mobx-react";
import MapAttributes from "../constants/MapAttributes";
import AssignLogRequest from "../interfaces/AssignLogRequest";
import AssignLogPreviousReceiverMarker from "../components/AssignLogPreviousReceiverMarker";
import AssignLogLatestReceiverMarker from "../components/AssignLogLatestReceiverMarker";
import AssignLogLatestVendorMarker from "../components/AssignLogLatestVendorMarker";
import Polyline from "../components/Polyline";
import GoogleMap from '../components/Common/GoogleMap';

declare var gon: any;

interface Props {
  body: {
    log_detail: any
  },
  logKey: string
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean
}


class RequestAssignLogMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false
    };
  }

  componentDidMount(): void {
  }

  render() {
    const {
      request,
      previous_request,
      request_theta_degree,
      angle,
      config_distance
    }: {
      request: AssignLogRequest,
      previous_request: AssignLogRequest,
      request_theta_degree: string,
      angle: string,
      config_distance: number
    }
      = this.props.body.log_detail;
    const logKey = this.props.logKey;
    return (
      <div className={'request-map-container'}>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={this.senderCoord(request)}
          defaultZoom={16}
          center={this.senderCoord(request)}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => this.setState({
            map: map,
            mapApi: maps,
            mapLoaded: true
          })}
          yesIWantToUseGoogleMapApiInternals={true}
        >
          <AssignLogLatestVendorMarker
            lat={request.sender_lat}
            lng={request.sender_lng}
            assignLogRequest={request}
          />
          <Polyline
            map={this.state.map}
            mapApi={this.state.mapApi}
            locations={
              [
                this.senderCoord(request),
                this.receiverCoord(previous_request)
              ]
            }
          />
          <Polyline
            map={this.state.map}
            mapApi={this.state.mapApi}
            locations={
              [
                this.senderCoord(request),
                this.receiverCoord(request)
              ]
            }
          />
          <AssignLogLatestReceiverMarker
            lat={request.receiver_lat}
            lng={request.receiver_lng}
            assignLogRequest={request}
          />
          {(previous_request) && (
            <AssignLogPreviousReceiverMarker
              lat={previous_request.receiver_lat}
              lng={previous_request.receiver_lng}
              assignLogRequest={previous_request}
            />
          )}
          {logKey === 'out_of_range' && this.renderArc(previous_request, Number(request_theta_degree), Number(angle))}
          {logKey === 'out_of_range' && this.renderPastArc(request, Number(request_theta_degree), Number(angle))}
          {logKey === 'out_of_radius' && this.renderCircle(previous_request, config_distance)}
        </GoogleMap>
      </div>
    );
  }

  /**
   * requestの配達先位置を中心とする半径radiusの円を描画するメソッド.
   * @param request 
   * @param radius 
   * @returns 
   */
  private renderCircle(request: AssignLogRequest, radius: number) {
    if (!this.state.mapLoaded) return;

    const circle = new this.state.mapApi.Circle({
      center: this.receiverCoord(request),
      radius: radius,
      strokeColor: '#ff2300',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#ff0034',
      fillOpacity: 0.35
    });
    circle.setMap(this.state.map);
  }

  private renderArc(request: AssignLogRequest, thetaDegree: number, angle: number) {
    if (!this.state.mapLoaded) {
      return;
    }

    const plus = this.getArcPoint(request, thetaDegree, angle);
    const minus = this.getArcPoint(request, thetaDegree, angle * -1);

    const polygon = new this.state.mapApi.Polygon({
      paths: [
        this.senderCoord(request),
        plus,
        minus
      ],
      strokeColor: '#ff2300',
      strokeOpacity: 0.3,
      strokeWeight: 2,
      fillColor: '#ff0034',
      fillOpacity: 0.35
    });
    polygon.setMap(this.state.map);

    return
  }

  private renderPastArc(request: AssignLogRequest, thetaDegree: number, angle: number) {
    if (!this.state.mapLoaded) {
      return;
    }

    const plus = this.getArcPoint(request, thetaDegree, angle);
    const minus = this.getArcPoint(request, thetaDegree, angle * -1);

    const polygon = new this.state.mapApi.Polygon({
      paths: [
        this.senderCoord(request),
        plus,
        this.receiverCoord(request),
        minus
      ],
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35
    });
    polygon.setMap(this.state.map);

    return
  }

  private getArcPoint(request: AssignLogRequest, thetaDegree: number, degree: number) {
    if (!this.state.mapLoaded) {
      return;
    }
    const distance = this.state.mapApi.geometry.spherical.computeDistanceBetween(
      this.senderLatLng(request),
      this.receiverLatLng(request),
    )

    return this.state.mapApi.geometry.spherical.computeOffset(
      this.senderLatLng(request),
      distance, this.getMapDegree(thetaDegree) + degree);
  }

  private senderCoord(request: AssignLogRequest) {
    return {
      lat: Number(request.sender_lat),
      lng: Number(request.sender_lng),
    }
  }

  private receiverCoord(request: AssignLogRequest) {
    return {
      lat: Number(request.receiver_lat),
      lng: Number(request.receiver_lng),
    }
  }

  private senderLatLng(request: AssignLogRequest) {
    return new this.state.mapApi.LatLng(
      Number(request.sender_lat),
      Number(request.sender_lng)
    )
  }

  private receiverLatLng(request: AssignLogRequest) {
    return new this.state.mapApi.LatLng(
      Number(request.receiver_lat),
      Number(request.receiver_lng)
    )
  }

  private getMapDegree(thetaDegree: number) {
    // ADMSで計算されるthetaDegreeは東方向から基準にした反時計周り角度なので、-90して北方向、360から引いて時計回り基準に変換
    return 360 - (thetaDegree - 90)
  }

}

export default observer(RequestAssignLogMap);
