import type {
  RawLocationsRoute,
  RawRoutesApiParamSetting,
} from "../interfaces/entities";
import { axiosGet, axiosPost } from "./AxiosClient";
import {
  RoutesApiCode,
  RoutesApiParamSetting,
  SelectableApiType,
  parseApiType,
  selectApiParamSetting,
} from "./routes-apis/api-code-utils";
import {
  DEFAULT_GEOAPIFY_OPTIMIZATION_TYPE_FOR_EC_AND_NM,
  DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM,
} from "./routes-apis/consts";

export function convIntoRoutingApiParams(
  selectedApiType: SelectableApiType,
  routesApiParamSettings: RawRoutesApiParamSetting[]
): {
  apiCode: RoutesApiCode;
  apiParamSetting: RoutesApiParamSetting;
  options: {
    geoapify: {
      optimizationType: RawLocationsRoute["geoapify_optimization_type"];
      travelMode: RawLocationsRoute["geoapify_travel_mode"];
      maxSpeed: RawLocationsRoute["geoapify_max_speed"];
    };
  };
} {
  const { apiCode } = parseApiType(selectedApiType);
  const apiParamSetting = selectApiParamSetting(
    selectedApiType,
    routesApiParamSettings
  );
  return {
    apiCode,
    apiParamSetting,
    options: {
      geoapify: {
        // 一旦Geapify固定なので、デフォルト値を設定させる
        optimizationType:
          apiParamSetting.geoapify_optimization_type ||
          DEFAULT_GEOAPIFY_OPTIMIZATION_TYPE_FOR_EC_AND_NM,
        travelMode:
          apiParamSetting.geoapify_travel_mode ||
          DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM,
        maxSpeed: apiParamSetting.geoapify_max_speed,
      },
    },
  };
}

export async function loadLocationsRoutes(params: {
  targets: {
    from: { lat: string; lng: string };
    to: { lat: string; lng: string };
  }[];
  apiCode: RawLocationsRoute["api_code"];
  options: {
    geoapify: {
      optimizationType: RawLocationsRoute["geoapify_optimization_type"];
      travelMode: RawLocationsRoute["geoapify_travel_mode"];
      maxSpeed: RawLocationsRoute["geoapify_max_speed"];
    };
  };
}) {
  const paramsList = ([] as string[][])
    .concat(
      ...params.targets.map((target) => [
        [`targets[][from_lat]`, target.from.lat],
        [`targets[][from_lng]`, target.from.lng],
        [`targets[][to_lat]`, target.to.lat],
        [`targets[][to_lng]`, target.to.lng],
      ])
    )
    .concat([
      ["api_code", params.apiCode],
      [
        "geoapify_optimization_type",
        `${params.options.geoapify.optimizationType}`,
      ],
      ["geoapify_travel_mode", `${params.options.geoapify.travelMode}`],
      ["geoapify_max_speed", `${params.options.geoapify.maxSpeed || ""}`],
    ]);
  const urlParams = new URLSearchParams(paramsList);

  const response = (await axiosGet.get(
    `/api/locations_routes?${urlParams.toString()}`
  )) as {
    data: {
      locations_routes: RawLocationsRoute[];
    };
  };
  return response;
}

export async function getAndSaveLocationsRoutes(params: {
  targets: { lat: string; lng: string }[];
  apiCode: RawLocationsRoute["api_code"];
  options: {
    geoapify: {
      optimizationType: RawLocationsRoute["geoapify_optimization_type"];
      travelMode: RawLocationsRoute["geoapify_travel_mode"];
      maxSpeed: RawLocationsRoute["geoapify_max_speed"];
    };
  };
}) {
  const response = (await axiosPost.post(`/api/locations_routes/register`, {
    pathway: params.targets,
    api_code: params.apiCode,
    options: {
      geoapify: {
        optimization_type: params.options.geoapify.optimizationType,
        travel_mode: params.options.geoapify.travelMode,
        max_speed: params.options.geoapify.maxSpeed,
      },
    },
  })) as {
    data: {
      message: "OK";
    };
  };
  return response;
}
