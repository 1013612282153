import { observable } from "mobx";
import { MasterTagRelationResponse } from "../interfaces/MasterTagRelationsResponse";

export class MasterTagRelationModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  master_tag_id: number;
  target_id: number;

  constructor(response: MasterTagRelationResponse) {
    this.id = response.id;
    this.key = response.id;
    this.master_tag_id = response.master_tag_id;
    this.target_id = response.target_id;
  }
}
