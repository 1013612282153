import { format } from "date-fns";
import { RawRequest } from "../interfaces/entities";
import Request from "../interfaces/Request";
import ThirdPartyDeliveryTask from "../interfaces/ThirdPartyDeliveryTask";
import { convertSameJstTimeFromUtc } from "./TimeUtils";

export function getRawRequestShortDeliveryTimeLabel(request: RawRequest) {
  const deliveryTimeSlotStartTime = request.delivery_time_slot_start_time
    ? convertSameJstTimeFromUtc(request.delivery_time_slot_start_time)
    : null;

  const deliveryTimeSlotEndTime = request.delivery_time_slot_end_time
    ? convertSameJstTimeFromUtc(request.delivery_time_slot_end_time)
    : null;

  let additional = "";
  if (deliveryTimeSlotStartTime && deliveryTimeSlotEndTime) {
    additional = `[${format(deliveryTimeSlotStartTime, "HH:mm")} 〜 ${format(
      deliveryTimeSlotEndTime,
      "HH:mm"
    )}]`;
  }

  // 指定があればその名前を利用
  if (request.is_delivery_time_specified) {
    return `${request.delivery_time_slot_name} ${additional}`;
  }

  return ` 指定なし`;
}

export function getDeliveryTimeLabel(
  deliveryRequest: Request | ThirdPartyDeliveryTask
) {
  let deliveryTimeLabel = "";

  if (deliveryRequest.deliveryTimeAt) {
    deliveryTimeLabel = format(deliveryRequest.deliveryTimeAt!, "MM/dd HH:mm");

    if (
      "deliveryType" in deliveryRequest &&
      deliveryRequest.deliveryType == "route"
    ) {
      // 指定があればその名前を利用
      if (deliveryRequest.isDeliveryTimeSpecified) {
        return `${format(deliveryRequest.deliveryTimeAt!, "MM/dd")} ${
          deliveryRequest.deliveryTimeSlotName
        }`;
      }

      // ここに来たら指定なし
      return `${format(deliveryRequest.deliveryTimeAt!, "MM/dd")} 指定なし`;
    }
  }

  return deliveryTimeLabel;
}

export function getLongDeliveryTimeLabel(
  deliveryRequest: Request | ThirdPartyDeliveryTask
) {
  let deliveryTimeLabel = "";
  if (deliveryRequest.deliveryTimeAt) {
    deliveryTimeLabel = format(
      deliveryRequest.deliveryTimeAt!,
      "yyyy/MM/dd HH:mm"
    );

    if (
      "deliveryType" in deliveryRequest &&
      deliveryRequest.deliveryType == "route"
    ) {
      // 指定があればその名前を利用
      if (deliveryRequest.isDeliveryTimeSpecified) {
        let additional = "";
        if (deliveryRequest.deliveryTimeSlotStartTime) {
          additional = `[${format(
            deliveryRequest.deliveryTimeSlotStartTime!,
            "HH:mm"
          )} 〜 ${format(deliveryRequest.deliveryTimeAt!, "HH:mm")}]`;
        }

        return `${format(deliveryRequest.deliveryTimeAt!, "yyyy/MM/dd")} ${
          deliveryRequest.deliveryTimeSlotName
        } ${additional}`;
      }

      // ここに来たら指定なし
      return `${format(
        deliveryRequest.deliveryTimeAt!,
        "yyyy/MM/dd"
      )} 指定なし`;
    }
  }

  return deliveryTimeLabel;
}
