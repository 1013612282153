import React from "react";
import { Card } from "react-bootstrap";
import { STYLES } from "../constants/Styles";
import { MinimumLocation } from "../interfaces";
import { StoreClass } from "../stores/StoreInterface";

interface BaseProps<T extends MinimumLocation> {
  lat: number;
  lng: number;
  earliest: boolean;
  latest: boolean;
  index: number;
  latestClickedMapAt: Date;
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;
  // keyを渡すとhoverがうまく判定できないので、key propsは任意にして値をセットしない
  key?: number;
  location: T;
  nextLocation: T | null;
  store: StoreClass<T>;
}

interface State {
  showInfoWindow: boolean;
}

export default class LocationMarker<
  T extends MinimumLocation
> extends React.Component<BaseProps<T>, State> {
  constructor(props: BaseProps<T>) {
    super(props);
    this.state = {
      showInfoWindow: false,
    };

    this.onClickMarker = this.onClickMarker.bind(this);
  }

  componentDidUpdate(prevProps: BaseProps<T>) {
    // 地図がクリックされたときに開いている詳細ウィンドウを閉じるため
    if (this.props.latestClickedMapAt !== prevProps.latestClickedMapAt) {
      this.setState({ showInfoWindow: false });
    }
  }

  private onClickMarker(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();

    const { location, store } = this.props;
    store.setSelectedLocation(location);
    this.setState({ showInfoWindow: !this.state.showInfoWindow });
  }

  private getStyle() {
    const { latest, earliest, index, location, store } = this.props;
    const selectedLocation = store.selectedLocation;

    if (earliest) {
      return STYLES.earliestMapMarkerStyle;
    } else if (latest) {
      return STYLES.latestMapMarkerStyle;
    } else if (index % 10 !== 0) {
      // 全て表示すると詰まってしまってみづらいので、
      // 10個に1個だけ表示してそれ以外は透過することで見た目上の間引き
      return STYLES.transparentMapMarkerStyle;
    } else if (
      selectedLocation &&
      selectedLocation.target_date == location.target_date
    ) {
      return STYLES.selectedMapMarkerStyle;
    } else {
      return STYLES.mapTriangleMarkerStyle;
    }
  }

  private calcRotateDeg(loc1: T, loc2: T | null) {
    if (!loc2) {
      return 0;
    }

    const lat1 = loc1.lat;
    const lng1 = loc1.lng;
    const lat2 = loc2.lat;
    const lng2 = loc2.lng;

    const radian = Math.atan2(lat2 - lat1, lng2 - lng1);
    // locationsデータはsent_atの降順でソートされているので、
    // 180度ひっくり返していることに注意
    return -((radian * 180) / Math.PI + 90);
  }

  render() {
    const { lat, lng, location, nextLocation, $hover } = this.props;
    const showInfoWindow = this.state.showInfoWindow || $hover;
    const markerStyle = this.getStyle();
    const infoTextStyle = STYLES.infoTextStyle;

    return (
      <>
        <div
          style={{
            ...markerStyle,
            transform: `rotate(${this.calcRotateDeg(
              location,
              nextLocation
            )}deg)`,
          }}
          onClick={(event) => this.onClickMarker(event)}
        />
        {showInfoWindow && (
          <Card style={STYLES.infoWindowStyle}>
            <div style={infoTextStyle}>
              時刻：{location.sent_at ? location.sent_at : "-"}
            </div>
            <div style={infoTextStyle}>緯度：{lat}</div>
            <div style={infoTextStyle}>経度：{lng}</div>
          </Card>
        )}
      </>
    );
  }
}
