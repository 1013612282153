import { useDroppable } from "@dnd-kit/core";
import {
  format,
  startOfToday,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
} from "date-fns";
import { observer } from "mobx-react";
import React, { FC } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { requestConst } from "../../../../constants/Request";
import {
  UNASSIGNED_REQ_CARD_WIDTH,
  UNASSIGNED_REQUESTS_DROPPABLE_ID,
} from "../../consts";
import { RawRequestWithInfo } from "../../interfaces";
import { pageStore } from "../../stores";
import DraggableRequestCard from "./DraggableRequestCard";

interface Props {}

const UnassignedRequestsDroppable: FC<Props> = (props) => {
  const targetTerm = pageStore.requestsTargetTerm;

  const draggingRequest = pageStore.draggingRequest;
  const requests = pageStore.requests;
  const isDragging = pageStore.draggingRequest != null;
  const { setNodeRef } = useDroppable({
    id: UNASSIGNED_REQUESTS_DROPPABLE_ID,
  });

  const style: React.CSSProperties = {
    backgroundColor: isDragging ? "rgba(51, 163, 247, 0.2)" : undefined,
  };

  const unassignedRequests = requests.filter(
    (request) => request.carry_staff_id == null
  );

  const beforeTodayOnHoldRequests = unassignedRequests.filter(
    (req) =>
      new Date(req.delivery_time_at) < startOfToday() &&
      req.status == requestConst.STATUS_CODES.onhold
  );

  const beforeTodayExceptOnHoldRequests = unassignedRequests.filter(
    (req) =>
      new Date(req.delivery_time_at) < startOfToday() &&
      req.status !== requestConst.STATUS_CODES.onhold
  );

  const todayRequests = unassignedRequests.filter(
    (req) =>
      new Date(req.delivery_time_at) >= startOfToday() &&
      new Date(req.delivery_time_at) <= endOfToday()
  );

  const tomorrowRequests = unassignedRequests.filter(
    (req) =>
      new Date(req.delivery_time_at) >= startOfTomorrow() &&
      new Date(req.delivery_time_at) <= endOfTomorrow()
  );

  const theDayAfterTomorrowOrLaterRequests = unassignedRequests.filter(
    (req) => new Date(req.delivery_time_at) > endOfTomorrow()
  );

  let targetDateRequests: RawRequestWithInfo[] = [];
  if (targetTerm.type == "specified") {
    const beginningOfTargetDate = new Date(`${targetTerm.date} 00:00:00+09:00`);
    const endOfTargetDate = new Date(
      format(
        new Date(beginningOfTargetDate.getTime() + 1000 * 60 * 60 * 24 * 1),
        "yyyy-MM-dd 00:00:00+09:00"
      )
    );
    targetDateRequests = unassignedRequests.filter(
      (req) =>
        new Date(req.delivery_time_at) >= beginningOfTargetDate &&
        new Date(req.delivery_time_at) < endOfTargetDate
    );
  }

  /**
   * dateStrで与えられた日付文字列を日本形式の表記に変換するメソッド.
   * @param dateStr YYYY-MM-DD
   */
  const convertDateStrToJpLabel = (dateStr: string) => {
    const date = new Date(`${dateStr} 00:00:00+09:00`);
    return `${date.getMonth() + 1}月${date.getDate()}日`;
  };

  const renderRequests = (label: string, _requests: RawRequestWithInfo[]) => {
    return (
      <Accordion
        defaultActiveKey="1"
        style={{ width: UNASSIGNED_REQ_CARD_WIDTH }}
      >
        <Card>
          <Card.Header style={{ marginBottom: 0 }}>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              <div>
                {label}: {pageStore.reqLoading ? "-" : _requests.length}件
              </div>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            {!pageStore.reqLoading ? (
              <div
                className="d-flex flex-column"
                style={{
                  maxHeight: 500,
                  overflowY: "scroll",
                  width: UNASSIGNED_REQ_CARD_WIDTH,
                }}
              >
                {_requests
                  .filter((request) => request.id != draggingRequest?.id)
                  .map((request) => {
                    return (
                      <DraggableRequestCard
                        key={request.id}
                        isMini={false}
                        request={request}
                      />
                    );
                  })}
              </div>
            ) : (
              <div></div>
            )}
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  // 未アサイン状態に戻す場合のことを考えて、依頼がない場合には何も表示しない、とはしない
  return (
    <div ref={setNodeRef} className="py-3 px-2 my-1" style={{ ...style }}>
      {(targetTerm.type == "all" || targetTerm.type == "passed") && (
        <>
          {renderRequests("昨日以前(留置)", beforeTodayOnHoldRequests)}
          {renderRequests(
            "昨日以前(留置以外)",
            beforeTodayExceptOnHoldRequests
          )}
        </>
      )}
      {targetTerm.type == "all" && (
        <>
          {renderRequests("本日", todayRequests)}
          {renderRequests("翌日", tomorrowRequests)}
          {renderRequests("翌々日以降", theDayAfterTomorrowOrLaterRequests)}
        </>
      )}
      {targetTerm.type == "specified" &&
        renderRequests(
          convertDateStrToJpLabel(targetTerm.date),
          targetDateRequests
        )}
      {props.children}
    </div>
  );
};

export default observer(UnassignedRequestsDroppable);
