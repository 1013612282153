import { Bounds, Coords } from "google-map-react";
import _ from "lodash";
import { action, observable } from "mobx";
import CurrentLocationsResponse from "../interfaces/CurrentLocationsResponse";
import { MasterTagRelationResponse } from "../interfaces/MasterTagRelationsResponse";
import { CurrentLocationWithCarryStaffModel } from "../models/CurrentLocationWithCarryStaffModel";
import { MasterTagRelationModel } from "../models/MasterTagRelationModel";
import { axiosPost } from "../utils/AxiosClient";

interface LoadInfo {
  loading: boolean;
  latestLoadedAt: Date | undefined;
}

export class CarryStaffLocationsStore {
  items = observable<CurrentLocationWithCarryStaffModel>([]);
  masterTagRelations = observable<MasterTagRelationModel>([]);

  // selectedItemIdとしてしまうとcurrent_locations.idを指しているのかと思ってしまうので、
  // ちゃんとcasのidであることを示すためにselectedCasIdとする
  @observable
  selectedCasId: number | "unassigned" | undefined;

  // load完了後にloadingとlatestLoadedAtを別々に更新してactionが2回走るのが嫌なので
  // 1回で終えられるように連想配列として設定
  @observable.ref
  loadingInfo: LoadInfo = { loading: false, latestLoadedAt: undefined };

  intervalTimer: number | undefined;
  abortController: AbortController | undefined;

  @action
  public subscribeIn(
    center: Coords,
    bounds: Bounds | null,
    callbackAfterFirstLoaded?: () => void
  ) {
    if (bounds == null) return;

    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.loadIn(center, bounds, callbackAfterFirstLoaded);
    this.intervalTimer = setInterval(
      this.loadIn.bind(this, center, bounds),
      30000
    );
  }

  public unsubscribe() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = undefined;
    }
  }

  @action
  private loadIn(
    center: Coords,
    bounds: Bounds,
    callbackAfterLoaded?: () => void
  ) {
    const _params: [string, string][] = [
      ["lat", `${center.lat}`],
      ["lng", `${center.lng}`],
      ["neLng", `${bounds.ne.lng}`],
      ["neLat", `${bounds.ne.lat}`],
      ["seLng", `${bounds.se.lng}`],
      ["seLat", `${bounds.se.lat}`],
      ["swLng", `${bounds.sw.lng}`],
      ["swLat", `${bounds.sw.lat}`],
      ["nwLng", `${bounds.nw.lng}`],
      ["nwLat", `${bounds.nw.lat}`],
    ];
    const params = new URLSearchParams(_params);

    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    this.setLoadingInfo(true);
    fetch("/api/current_locations?" + params.toString(), {
      signal: this.abortController.signal,
    })
      .then((response) => response.json())
      .catch((err) => {
        if (err.code != DOMException.ABORT_ERR) {
          // Abort Errorの場合には、別のローディングが入っていると言うことのはずなので、
          // loadingフラグはそのままにする(Abort Error以外のエラーの場合にだけfalseにする)
          this.setLoadingInfo(false);
          throw err;
        }
      })
      .then(
        async (
          result:
            | {
                current_locations: CurrentLocationsResponse[];
                master_tag_relations: MasterTagRelationResponse[];
              }
            | undefined
        ) => {
          if (!result) return;

          const items = this.convertToModel(result.current_locations);
          const updateItems = await this.updateCarryStaffImageUrl(items);
          for (const updateItem of updateItems) {
            const targetLoc = items.find(
              (item) => item.carryStaffId == updateItem.carry_staff_id
            );
            if (targetLoc) {
              targetLoc.updateImageInfo(
                updateItem.image_url,
                updateItem.image_url_expired_at
                  ? new Date(updateItem.image_url_expired_at)
                  : null
              );
            }
          }

          this.replaceItems(items);
          this.replaceMasterTagRelations(
            result.master_tag_relations.map(
              (rel) => new MasterTagRelationModel(rel)
            )
          );
          this.setLoadingInfo(false, new Date());
          callbackAfterLoaded && callbackAfterLoaded();
        }
      );
  }

  /**
   * image_urlの期限が切れているものに関して、全て更新するためのメソッド.
   * @param items
   * @returns
   */
  private async updateCarryStaffImageUrl(
    items: CurrentLocationWithCarryStaffModel[]
  ) {
    // プロフィール画像が設定されており、かつ期限が切れているものを取得
    let targetItems = items.filter(
      (item) =>
        item.imageUrl &&
        item.imageUrlExpiredAt &&
        item.imageUrlExpiredAt < new Date()
    );
    const casIdsWithExpiredUrl = targetItems.map((item) => item.carryStaffId);
    if (casIdsWithExpiredUrl.length == 0) return [];

    try {
      const response = (await axiosPost.post(
        `/api/cas_image_presigned_urls/update`,
        { carry_staff_ids: casIdsWithExpiredUrl }
      )) as {
        data: {
          result: string;
          items: {
            id: number;
            image_url: string;
            image_url_expired_at: string;
          }[];
        };
      };

      return response.data.items.map((item) => ({
        carry_staff_id: item.id,
        image_url: item.image_url,
        image_url_expired_at: item.image_url_expired_at,
      }));
    } catch (error) {
      console.error(error);
      return targetItems.map((cas) => ({
        carry_staff_id: cas.carryStaffId,
        image_url: null,
        image_url_expired_at: null,
      }));
    }
  }

  @action
  public setSelectedItemId(selectedCasId: number | "unassigned" | undefined) {
    this.selectedCasId = selectedCasId;
  }

  @action
  private replaceItems(items: CurrentLocationWithCarryStaffModel[]) {
    this.items.replace(items);
  }

  @action
  private replaceMasterTagRelations(
    masterTagRelations: MasterTagRelationModel[]
  ) {
    this.masterTagRelations.replace(masterTagRelations);
  }

  @action
  private setLoadingInfo(loading: boolean, loadedAt?: Date) {
    this.loadingInfo = {
      loading,
      latestLoadedAt: loadedAt ?? this.loadingInfo.latestLoadedAt,
    };
  }

  public getSelectedItem() {
    return this.items.find((item) => item.carryStaffId == this.selectedCasId);
  }

  public isSelectedUnAssigned() {
    return this.selectedCasId == "unassigned";
  }

  private convertToModel(responseItems: CurrentLocationsResponse[]) {
    const items = responseItems.map(
      (item) => new CurrentLocationWithCarryStaffModel(item)
    );
    return items;
  }
}

const singleton = new CarryStaffLocationsStore();
export default singleton;
