import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import { Slide, ToastContainer, toast } from "react-toastify";
import CustomGoogleMap from "./components/CustomGoogleMap";
import OperationalComponent from "./components/OperationalPane";
import BulkAssignModal from "./components/BulkAssignModal";
import AssigningConfirmModal from "./components/AssigningConfirmModal";
import type {
  RawBulkAssignArea,
  RawRoutesApiParamSetting,
  RawTerritory,
  RawVehicleType,
} from "./interfaces";
import { pageStore } from "./stores";

Modal.setAppElement("#wrapper");

interface Props {
  bulkAssignAreas: RawBulkAssignArea[];
  territories: RawTerritory[];
  vehicleTypes: RawVehicleType[];
  routesApiParamSettings: RawRoutesApiParamSetting[];
  canUpdate: boolean;
}

interface State {
  isAssignConfirmModalOpen: boolean;
  isBulkAssignModalOpen: boolean;
}

class RouteDeliveryAssign extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAssignConfirmModalOpen: false,
      isBulkAssignModalOpen: false,
    };

    this.handleBeforeUnloadEvent = this.handleBeforeUnloadEvent.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener("beforeunload", this.handleBeforeUnloadEvent);
    const searchParams = new URL(location.href).searchParams;
    pageStore.resetUnassignedRequestsAndCarryStaffs({
      searchText: searchParams.get("name"),
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener("beforeunload", this.handleBeforeUnloadEvent);
  }

  private handleBeforeUnloadEvent(event: BeforeUnloadEvent) {
    // 別ページへ遷移する前に、もしアサイン変更を行っているにも関わらず保存されていないものがあればアラート表示
    if (pageStore.existsAssigningChanged()) {
      event.preventDefault();
      event.returnValue = "";
    }
  }

  render() {
    return (
      <>
        <div className="d-flex flex-wrap">
          <div className="col-12 d-flex" style={{ height: "50vh" }}>
            <CustomGoogleMap
              territories={this.props.territories}
              vehicleTypes={this.props.vehicleTypes}
            />
          </div>

          {this.props.canUpdate && (
            <div className="col-12 d-flex justify-content-end my-1">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.setState({ isBulkAssignModalOpen: true });
                }}
              >
                一括割当
              </button>
            </div>
          )}

          <div className="col-12 d-flex">
            <OperationalComponent
              territories={this.props.territories}
              vehicleTypes={this.props.vehicleTypes}
              canUpdate={this.props.canUpdate}
              onClickAssignButton={() =>
                this.setState({ isAssignConfirmModalOpen: true })
              }
            />
          </div>
        </div>

        <ToastContainer />

        <AssigningConfirmModal
          isOpen={this.state.isAssignConfirmModalOpen}
          onCloseModal={async (success) => {
            this.setState({ isAssignConfirmModalOpen: false });
            if (success) {
              toast.info("更新しました。", {
                autoClose: 1000,
                closeButton: false,
                hideProgressBar: true,
                position: toast.POSITION.TOP_RIGHT,
                transition: Slide,
              });
              await pageStore.resetUnassignedRequestsAndCarryStaffs();
            }
          }}
        />

        <BulkAssignModal
          bulkAssignAreas={this.props.bulkAssignAreas}
          routesApiParamSettings={this.props.routesApiParamSettings}
          territories={this.props.territories}
          vehicleTypes={this.props.vehicleTypes}
          isOpen={this.state.isBulkAssignModalOpen}
          onCloseModal={() => this.setState({ isBulkAssignModalOpen: false })}
        />
      </>
    );
  }
}

export default observer(RouteDeliveryAssign);
