import { format, subHours } from "date-fns";

export function getBeginningAndEndOfToday() {
  const now = new Date();
  const beginningOfToday = new Date(format(now, "yyyy-MM-dd 00:00:00+09:00"));
  const endOfToday = new Date(
    format(
      new Date(beginningOfToday.getTime() + 1000 * 60 * 60 * 24 * 1),
      "yyyy-MM-dd 00:00:00+09:00"
    )
  );

  return {
    beginningOfToday,
    endOfToday,
  };
}

/**
 * UTCで渡された日付時刻と、ローカルの同時刻のDateオブジェクトを返す。
 * ex 1900-01-01 15:00:00(UTC)の文字列が渡された場合、 ローカルで1900-01:01 15:00:00(JST)のDateオブジェクトを返す。
 *
 * railsのtime型は日本時刻表示でUTCで保存している。そのため、javascriptの世界に持ってきた時、15時がUTCで15時になるため、そのままDateに変換すると
 * 日本時間の翌日0時になってしまう。そのため、日本時間の15時と扱える日付オブジェクトを返す。
 *
 * @param utcIsoText
 * @param date
 */
export function convertSameJstTimeFromUtc(utcIsoText: string): Date;
export function convertSameJstTimeFromUtc(utcIsoText: null): null;
export function convertSameJstTimeFromUtc(utcIsoText: string | null) {
  if (utcIsoText) {
    return subHours(new Date(utcIsoText), 9);
  }

  return null;
}

/**
 * "2024-03-01T11:00:00.000+09:00"というような
 * タイムゾーン指定子が日本として設定されている文字列から
 * "hh:mm"を取得するメソッド
 * @param dateString
 * @returns
 */
export function getHhMmFromJstDateStr(dateString: string): string {
  const dateObject = new Date(dateString);
  const date = new Date(dateObject.getTime());
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");

  return `${hour}:${minute}`;
}

/**
 * hhmmは日本時間として扱いたいが
 * タイムゾーン指定子がUTCとして設定されている文字列から
 *  例: "2000-01-01T10:30:00.000Z"は年月日に意味がなく"10:30"として扱われているような場合
 * "hh:mm"を取得するメソッド
 * @param dateString
 * @returns
 */
export function getHhMmFromJstTimeUtcDateStr(dateString: string) {
  const date = convertSameJstTimeFromUtc(dateString);
  const hhmm = `${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}`;
  return hhmm;
}

/**
 * "hh:mm"形式の文字列で与えられた2つの時間幅の重複を判定するメソッド
 * @param range1
 * @param range2
 * @returns
 */
export function isOverlapStrTimes(
  range1: { from: string; to: string },
  range2: { from: string; to: string }
) {
  const [fromHourRange1, fromMinuteRange1] = range1.from.split(":").map(Number);
  const [toHourRange1, toMinuteRange1] = range1.to.split(":").map(Number);
  const [fromHourRange2, fromMinuteRange2] = range2.from.split(":").map(Number);
  const [toHourRange2, toMinuteRange2] = range2.to.split(":").map(Number);

  if (
    toHourRange1 < fromHourRange2 ||
    (toHourRange1 == fromHourRange2 && toMinuteRange1 < fromMinuteRange2) ||
    fromHourRange1 > toHourRange2 ||
    (fromHourRange1 == toHourRange2 && fromMinuteRange1 > toMinuteRange2)
  ) {
    return false;
  }

  return true;
}

/**
 * ISO8601形式の日付文字列をHH:MMにフォーマットして返す
 * @param isoString
 */
export function formatIso8601Time(isoString: string | null): string {
  if (!isoString || isoString === "") {
    return "";
  }
  // Dateオブジェクトを作成
  const date = new Date(isoString);
  // Intl.DateTimeFormatを使用してロケールに基づいた時間の文字列を取得
  const timeString = new Intl.DateTimeFormat("ja-JP", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 24時間表記
  }).format(date);

  return timeString;
}
