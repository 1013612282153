import { format } from "date-fns";
import Rails from "rails-ujs";
import React, { useEffect, useState } from "react";

const RELOAD_INTERVAL_MS = 60 * 1000;

type Props = {
};

function RequestsReloadButton({}: Props) {
  const [lastLoadedAt, setLastLoadedAt] = useState(new Date());

  const reload = () => {
    setLastLoadedAt(new Date());
    reloadRequests();
  };

  function reloadRequests() {
    const currentPageLink = document.querySelector("#requests-pagination .active .page-link") as HTMLElement;
    if (currentPageLink) {
      return currentPageLink.click();
    }

    // 1ページ以内に収まる場合はページャーが存在しないため、検索フォームを送信させて更新する
    const searchForm = document.querySelector("#request_search") as HTMLElement;
    Rails.fire(searchForm, "submit");
  }

  // 最後のリロードからRELOAD_INTERVAL_MSミリ秒後にリロードする
  useEffect(() => {
    const timeoutId = setTimeout(reload, RELOAD_INTERVAL_MS);
    return () => {
      clearTimeout(timeoutId)
    };
  }, [lastLoadedAt]);

  useEffect(() => {
    function reloadVendorCompanyHeadlines() {
      setLastLoadedAt(new Date());
    }

    window.addEventListener('reload_requests', reloadVendorCompanyHeadlines)
    return () => {
      window.removeEventListener('reload_requests', reloadVendorCompanyHeadlines)
    };
  }, []);

  return (
    <>
      <span className="mr-2">最終読込日時</span>
      <span>{format(lastLoadedAt, "HH:mm:ss")}</span>
    </>
  );
}

export default RequestsReloadButton;