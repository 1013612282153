import { format } from "date-fns";
import { action, observable, runInAction } from "mobx";
import { convertNumToShortCode } from "../../../utils/NumberUtils";
import {
  RawCarryStaffWithSchedules,
  RawRequestWithInfo,
  TargetTerm,
  TargetHighlighting,
} from "../interfaces";
import {
  loadCarryStaffsWithSchedules,
  loadUnassignedRequests,
  mergeSchedulesIntoCarryStaffs,
} from "../utils";

export class PageStore {
  @observable.ref
  selectedTerritoryIds: number[] = [];

  @observable
  searchText = "";

  @observable
  requestsTargetTerm: TargetTerm = { type: "all" };

  @observable
  schedulesTargetDate = format(new Date(), "yyyy-MM-dd");

  @observable
  deliveryTimeRangeFrom = "00:00";

  @observable
  deliveryTimeRangeTo = "23:59";

  @observable
  selectedCasId: number | null = null;

  @observable.ref
  carryStaffs: RawCarryStaffWithSchedules[] = [];

  @observable
  totalCasCount = 0;

  @observable
  casPage = 1;

  @observable
  casPerPage = 5;

  @observable
  selectedReqId: number | null = null;

  @observable.ref
  requests: RawRequestWithInfo[] = [];

  @observable
  draggingRequest: RawRequestWithInfo | null = null;

  @observable
  totalUnassinedReqCount = 0;

  @observable
  casLoading = false;

  @observable
  reqLoading = false;

  @observable
  targetHighlighting: TargetHighlighting = "all";

  @action
  public selectTerritories(territoryIds: number[]) {
    this.selectedTerritoryIds = territoryIds;
    this.casPage = 1;
  }

  @action
  public changeSearchText(text: string) {
    this.searchText = text;
  }

  @action
  public selectCarryStaff(carryStaffId: number | null) {
    this.selectedCasId = carryStaffId;
  }

  public getSelectedCas() {
    if (this.selectedCasId == null) return undefined;

    return this.carryStaffs.find((cas) => cas.id == this.selectedCasId);
  }

  @action
  public async changeCasPage(newPage: number) {
    this.casPage = newPage;
    await this.loadAndUpdateCarryStaffs();
  }

  @action
  public selectRequest(requestId: number | null) {
    if (this.selectedReqId == requestId) {
      // すでに選択済みのものであれば、選択解除と判断
      this.selectedReqId = null;
    } else {
      this.selectedReqId = requestId;
    }
  }

  @action
  public setRequests(requests: RawRequestWithInfo[]) {
    this.requests = requests;
  }

  public getSelectedReq() {
    if (this.selectedReqId == null) return undefined;

    return this.requests.find((req) => req.id == this.selectedReqId);
  }

  @action
  public setDraggingRequest(request: RawRequestWithInfo | null) {
    this.draggingRequest = request;
  }

  @action
  public resetRequestAssignChange() {
    this.requests = this.requests.map((req) => ({
      ...req,
      carry_staff_id: req.org_carry_staff_id,
    }));
  }

  public existsAssigningChanged() {
    return this.requests.some(
      (req) => req.org_carry_staff_id != req.carry_staff_id
    );
  }

  @action
  public async changeTargetTermAndLoadUnassignedRequests(
    targetTerm: TargetTerm
  ) {
    if (
      this.requestsTargetTerm.type == "specified" &&
      targetTerm.type == "specified" &&
      this.requestsTargetTerm.date == targetTerm.date
    ) {
      // 日付指定で、日付が同じ場合には何もしない
      return;
    }

    this.requestsTargetTerm = targetTerm;
    await this.loadAndUpdateUnassignedRequests();
  }

  @action
  public async changeTargetDateAndLoadCarryStaffs(targetDate: string) {
    if (this.schedulesTargetDate == targetDate) {
      // 日付が同じ場合には何もしない
      return;
    }

    this.casPage = 1;
    this.schedulesTargetDate = targetDate;
    await this.loadAndUpdateCarryStaffs();
  }

  @action
  public async changeTargetDeliveryTimeRange(
    type: "from" | "to",
    targetTime: string
  ) {
    if (type == "from") {
      this.deliveryTimeRangeFrom = targetTime;
    } else if (type == "to") {
      this.deliveryTimeRangeTo = targetTime;
    }
    return;
  }

  @action
  public async setTargetHighlighting(target: TargetHighlighting) {
    this.targetHighlighting = target;
  }

  @action
  public async resetUnassignedRequestsAndCarryStaffs(initParams?: {
    searchText?: string | null;
  }) {
    this.reqLoading = true;
    this.casLoading = true;
    // 検索文言はリセットするけど、担当エリアの方はそのままにする
    // (担当エリアをリセットする場合、検索フォーム部分との同期を取らないといけないが、面倒そうなので)
    this.searchText = initParams?.searchText || "";
    this.casPage = 1;
    this.requestsTargetTerm = { type: "all" };
    this.schedulesTargetDate = format(new Date(), "yyyy-MM-dd");

    const [reqResponse, casResponse] = await Promise.all([
      loadUnassignedRequests(this.requestsTargetTerm),
      loadCarryStaffsWithSchedules(
        this.searchText,
        this.selectedTerritoryIds,
        this.schedulesTargetDate,
        {
          page: this.casPage,
          perPage: this.casPerPage,
        }
      ),
    ]);

    runInAction(() => {
      this.reqLoading = false;
      this.casLoading = false;
      const assignedReqs = casResponse.assignedRequests.map((req) => ({
        ...req,
        shortCode: convertNumToShortCode(req.id),
        org_carry_staff_id: req.carry_staff_id,
      }));
      const unassignedReqs = reqResponse.data.unassigned_requests.map(
        (req) => ({
          ...req,
          shortCode: convertNumToShortCode(req.id),
          org_carry_staff_id: null,
        })
      );
      this.requests = ([] as RawRequestWithInfo[]).concat(
        unassignedReqs,
        assignedReqs
      );

      this.totalUnassinedReqCount =
        reqResponse.data.total_unassigned_request_count;

      this.carryStaffs = mergeSchedulesIntoCarryStaffs(
        casResponse.carryStaffs,
        casResponse.schedules
      );
      this.totalCasCount = casResponse.totalCarryStaffsCount;
    });
  }

  @action
  public async loadAndUpdateUnassignedRequests() {
    this.reqLoading = true;
    const response = await loadUnassignedRequests(this.requestsTargetTerm);
    runInAction(() => {
      this.reqLoading = false;
      this.requests = Array.from(
        new Map(
          this.requests
            .filter((req) => req.org_carry_staff_id != null)
            .concat(
              response.data.unassigned_requests.map((req) => ({
                ...req,
                shortCode: convertNumToShortCode(req.id),
                org_carry_staff_id: null,
              }))
            )
            // 後勝ち
            .map((req) => [req.id, req])
        ).values()
      );
      this.totalUnassinedReqCount =
        response.data.total_unassigned_request_count;
    });
  }

  @action
  public async loadAndUpdateCarryStaffs() {
    this.casLoading = true;
    const response = await loadCarryStaffsWithSchedules(
      this.searchText,
      this.selectedTerritoryIds,
      this.schedulesTargetDate,
      {
        page: this.casPage,
        perPage: this.casPerPage,
      }
    );
    runInAction(() => {
      this.casLoading = false;
      this.carryStaffs = mergeSchedulesIntoCarryStaffs(
        response.carryStaffs,
        response.schedules
      );
      this.totalCasCount = response.totalCarryStaffsCount;
      this.requests = Array.from(
        new Map(
          this.requests
            .concat(
              response.assignedRequests.map((req) => ({
                ...req,
                shortCode: convertNumToShortCode(req.id),
                org_carry_staff_id: req.carry_staff_id,
              }))
            )
            // 後勝ち
            .map((req) => [req.id, req])
        ).values()
      );
    });
  }

  public getTargetTermDate() {
    if (this.requestsTargetTerm.type == "specified") {
      return new Date(this.requestsTargetTerm.date);
    }
    return new Date();
  }
}
