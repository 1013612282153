import React from "react";
import { observer } from "mobx-react";
import spotRemarkOverlookStore from "../stores/SpotRemarkOverlookStore";
import _ from "lodash";

interface Props {
  canUpdate: boolean;
  canDestroy: boolean;
}

interface State {
  searchCondition: string;
}

class SpotRemarkOverlookList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchCondition: "",
    };
    spotRemarkOverlookStore.listFilterFunc = this.applyListFilter;
  }

  componentDidMount(): void {}

  render() {
    if (_.isEmpty(spotRemarkOverlookStore.visibleMarkers)) {
      return (
        <div>
          {this.renderSearchBox()}
          有効なスポット情報がありませんでした。
        </div>
      );
    }

    return (
      <div>
        {this.renderSearchBox()}
        <div className={"area-topic-overlook-list"}>
          {this.renderSpotRemarkList()}
        </div>
      </div>
    );
  }

  private renderSearchBox() {
    return (
      <React.Fragment>
        <div className="form-group mb-2">
          <input
            type="text"
            className="form-control"
            value={this.state.searchCondition}
            placeholder={"スペースで複数指定"}
            onChange={(event) => {
              this.setState(
                {
                  searchCondition: event.target.value,
                },
                () => {
                  const markers = this.applyListFilter(
                    spotRemarkOverlookStore.visibleMarkers
                  );
                  spotRemarkOverlookStore.updateMarkers(markers);
                }
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  private applyListFilter = (markers) => {
    return _.forEach(markers, (marker) => {
      if (
        this.matchSearchCondition([
          marker.model.name,
          marker.model.useKeyword === 1 ? marker.model.keyword : null,
          marker.model.memo,
        ])
      ) {
        marker.visible = true;
      } else {
        marker.visible = false;
      }
    });
  };

  private renderSpotRemarkList() {
    return _.map(spotRemarkOverlookStore.visibleMarkers, (marker) => {
      if (!marker.visible) {
        return null;
      }
      return (
        <div className={"list-item"} key={marker.model.id}>
          <div className={"list-item-header"}>
            <label className={"name-label"}>
              {marker.model.name}
              {marker.model.negative_reviews_count > 0 &&
                marker.model.negative_reviews_count_updated_at && (
                  <i
                    className="fas fa-tired"
                    style={{
                      color: this.setColor(
                        marker.model.negative_reviews_count_updated_at
                      ),
                    }}
                    title={this.formatDateToJST(
                      marker.model.negative_reviews_count_updated_at
                    )}
                  ></i>
                )}
            </label>
            <a
              className={"btn btn-primary"}
              href={`/spot_remarks/${marker.model.id}`}
            >
              詳細
            </a>
            {this.props.canUpdate && (
              <a
                className={"btn btn-secondary"}
                href={`/spot_remarks/${marker.model.id}/edit`}
              >
                変更
              </a>
            )}
            {this.props.canDestroy && (
              <a
                className={"btn btn-danger"}
                href={`/spot_remarks/${marker.model.id}`}
                data-confirm={"削除してもよろしいですか？"}
                rel={"nofollow"}
                data-method={"delete"}
              >
                削除
              </a>
            )}
          </div>
          <div className={"list-item-detail"}>
            {marker.model.useKeyword === 1 && (
              <div>表示条件: {marker.model.keyword}</div>
            )}
            <div>{marker.model.memo}</div>
          </div>
        </div>
      );
    });
  }

  private setColor(dateString: Date | null) {
    if (!dateString) return;
    const updatedAt = new Date(dateString);
    const now = new Date();
    const oneWeekAgo = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7
    );
    const threeMonthsAgo = new Date(
      now.getFullYear(),
      now.getMonth() - 3,
      now.getDate()
    );

    if (updatedAt > oneWeekAgo) {
      return "red";
    } else if (updatedAt > threeMonthsAgo) {
      return "blue";
    } else {
      return "";
    }
  }

  private formatDateToJST(dateString) {
    const date = new Date(dateString);
    const jstOffset = 9 * 60;
    const localOffset = date.getTimezoneOffset();
    const offset = jstOffset - localOffset;
    const jstDate = new Date(date.getTime() + offset * 60000);

    return jstDate.toLocaleString("ja-JP");
  }

  private matchSearchCondition(values: string[]) {
    const searchConditions = this.state.searchCondition.trim().split(/[　 ]/);
    if (searchConditions.length == 0) {
      return true;
    }

    return _.some(searchConditions, (searchCondition) => {
      for (const value of values) {
        if (value === undefined || value === null) {
          continue;
        }
        if (value.indexOf(searchCondition) >= 0) {
          return true;
        }
      }
      return false;
    });
  }
}

export default observer(SpotRemarkOverlookList);
