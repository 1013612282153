import { useCallback, useEffect, useState } from "react";
import { Coords } from "google-map-react";
import { geoToH3, h3ToGeoBoundary } from "h3-js";

interface Props {
  toggleFeature: (geometry: any, id: number | string) => void;
}

export default function useHexMode(props: Props) {
  const { toggleFeature } = props;
  const [hoverCoords, setHoverCoords] = useState<Coords>();
  const [hoverHexIndex, setHoverHexIndex] = useState("");
  const [hexSize, setHexSize] = useState(5);

  useEffect(() => {
    if (!hoverCoords) return;
    const INVERT_SUM = 14; // 16で最小になるが流石に小さすぎたので14に設定
    const resolution = INVERT_SUM - hexSize;
    const h3Index = geoToH3(hoverCoords.lat, hoverCoords.lng, resolution);
    setHoverHexIndex(h3Index);
  }, [hoverCoords, hexSize]);

  const setHoverIndexByMouseCursor = useCallback(
    (e: any) => {
      if (!e.latLng) return;
      setHoverCoords({
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      });
    },
    [hoverHexIndex]
  );

  // 画面からマウスカーソルが外れた時などに、どこもHoverしていない状態にする
  const clearHoverHexIndex = () => {
    setHoverHexIndex("");
  };

  // Hexクリック時に実行
  const handleClickHexArea = () => {
    toggleFeature(h3IndexToGeometry(hoverHexIndex), hoverHexIndex);
  };

  return {
    hexSize,
    setHexSize,
    hoverHexIndex,
    clearHoverHexIndex,
    setHoverIndexByMouseCursor,
    handleClickHexArea
  };
}

export const h3IndexToGeometry = (h3Index: string) => {
  return {
    type: "Polygon",
    coordinates: [h3ToGeoBoundary(h3Index, true)]
  };
};
