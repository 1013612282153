import React, { useRef, useState } from "react";
import Modal from "react-modal";
import { axiosPost } from "../utils/AxiosClient";

Modal.setAppElement("#wrapper");

type Props = {
  twimlElementId: string;
};

type CallParams = {
  to: string;
  twiml: string | null;
};

export default function CasTestCallModal({ twimlElementId }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [to, setTo] = useState("");
  const [twiml, setTwiml] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const toInput = useRef<HTMLInputElement | null>(null);

  const openModal = () => {
    setIsOpen(true);
    setTwiml(getTwimlValue());
    setTimeout(() => {
      toInput.current?.focus();
    }, 100);
  };

  const closeModal = () => {
    setIsOpen(false);
    clearResults();
    setTwiml(null);
  };

  const getTwimlValue = () => {
    const input = document.getElementById(
      twimlElementId
    ) as HTMLTextAreaElement;
    return input.value;
  };

  const clearResults = () => {
    if (isSuccess) setIsSuccess(false);
    if (errorMessage) setErrorMessage(null);
  };

  const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTo(e.target.value);
    clearResults();
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    try {
      const params: CallParams = { to, twiml };
      await axiosPost.post("/api/cas_test_calls", params);
      setIsSuccess(true);
      setErrorMessage(null);
    } catch (e) {
      console.log(e);
      const message = e.response?.data?.message || "エラーが発生しました。";
      setErrorMessage(message);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={() => openModal()}
        disabled={isOpen}
      >
        発信確認
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        style={modalStyles}
      >
        <h2 className="h5 mb-4">発信確認</h2>
        {isLoading && <p>発信中...</p>}
        {isSuccess && <p>発信しました。</p>}
        {errorMessage && (
          <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {errorMessage}
          </p>
        )}
        <form onSubmit={onSubmit}>
          <div className="border-left-info px-4">
            <div className="row align-items-center mb-3">
              <label className="col-4 mb-0">確認用発信先電話番号:</label>
              <input
                className="form-control col"
                value={to}
                placeholder="08011111111"
                ref={toInput}
                onChange={onChangeTo}
              />
            </div>
            <div className="row mb-3">
              <label className="col-12">発信内容:</label>
              <p className="col-12" style={twimlStyle}>
                {twiml}
              </p>
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              発信する
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};

const twimlStyle: React.CSSProperties = {
  whiteSpace: "pre-wrap",
  maxHeight: 380,
  overflowY: "auto",
};
