import _ from "lodash";
import { action, observable } from "mobx";
import ThirdPartyDeliveryTaskResponse from "../interfaces/ThirdPartyDeliveryTaskResponse";
import { ThirdPartyDeliveryTaskModel } from "../models/ThirdPartyDeliveryTaskModel";
import { axiosGet } from "../utils/AxiosClient";

export class ThirdPartyDeliveryTaskStore {
  @observable
  thirdPartyDelivery: ThirdPartyDeliveryTaskModel;

  @observable
  latestLoadedAt: Date;

  intervalTimer: number | null = null;

  @action
  public subscribeThirdPartyDelivery(thirdPartyDeliveryTaskId: number) {
    this.loadThirdPartyDeliveryTask(thirdPartyDeliveryTaskId);

    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
    this.intervalTimer = setInterval(
      this.loadThirdPartyDeliveryTask.bind(this, thirdPartyDeliveryTaskId),
      60000
    );
  }

  @action
  private async loadThirdPartyDeliveryTask(thirdPartyDeliveryTaskId: number) {
    const response = await axiosGet.get(
      `/api/third_party_delivery_tasks/${thirdPartyDeliveryTaskId}`
    );
    const result: ThirdPartyDeliveryTaskResponse = response.data;
    this.replaceThirdPartyDelivery(new ThirdPartyDeliveryTaskModel(result));
    this.latestLoadedAt = new Date();
  }

  @action
  private replaceThirdPartyDelivery(
    thirdPartyDeliveryTask: ThirdPartyDeliveryTaskModel
  ) {
    this.thirdPartyDelivery = thirdPartyDeliveryTask;
  }
}

const singleton = new ThirdPartyDeliveryTaskStore();
export default singleton;
