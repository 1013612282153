import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { MasterTag } from "./interfaces/Interfaces";
import CarryStaffOverlookMap from "./MapComponents/CarryStaffOverlookMap";
import CarryStaffList from "./Panes/CarryStaffList";
import RequestList from "./Panes/RequestList";
import ThirdPartyDeliveryTaskList from "./Panes/ThirdPartyDeliveryTaskList";
import searchConditionsStore, {
  PageType,
} from "./stores/SearchConditionsStore";
import { selectItem } from "./utils/Utils";

interface Props {
  masterTags: MasterTag[];
  carryStaff: { id: number; name: string } | null;
}

interface State {
  // SearchConditionsStoreのdisplayPageと意味が重複してしまっているので、
  // いつかリファクタが必要になるかも
  activeTab: PageType;
  isMenuOpen: boolean;
}

class CarryStaffOverlook extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: "carry_staffs",
      isMenuOpen: true,
    };
  }

  componentDidMount() {
    const { carryStaff } = this.props;
    if (carryStaff) {
      selectItem("carryStaff", carryStaff.id);
      searchConditionsStore.changeCondAndMoveTo(
        "carry_staff_requests_or_tpdts",
        "carryStaff",
        { id: carryStaff.id, name: carryStaff.name }
      );
    }
  }

  toggleMenu = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  };

  changeTab(tabName: PageType) {
    if (this.state.activeTab == tabName) {
      // 現在表示中のタブの場合には遷移させない
      return;
    }

    selectItem(undefined, undefined);
    searchConditionsStore.moveTo(tabName);
    this.setState({ activeTab: tabName });
  }

  render() {
    const masterTags = this.props.masterTags;
    const { activeTab, isMenuOpen } = this.state;
    return (
      <div style={styles.container}>
        <div style={styles.map}>
          <CarryStaffOverlookMap />
        </div>
        <div style={isMenuOpen ? styles.openContainer : styles.closeContainer}>
          {isMenuOpen ? (
            <div onClick={this.toggleMenu} style={styles.openIcon}>
              <i className="fas fa-angle-double-left" />
            </div>
          ) : (
            <div onClick={this.toggleMenu} style={styles.closeIcon}>
              <i className="fas fa-bars" />
            </div>
          )}
          {/* メニューを縮めた際もstateを保持するためにアンマウントしないようdisplayで制御 */}
          <div style={isMenuOpen ? styles.wrapper : { display: "none" }}>
            <nav className="mb-3">
              <div
                className="nav nav-tabs"
                id="nav-tab"
                role="tablist"
                style={{ fontSize: "0.9rem" }}
              >
                <a
                  className={`nav-item nav-link ${
                    activeTab === "carry_staffs" ? "active" : ""
                  }`}
                  id="nav-cas-tab"
                  data-toggle="tab"
                  href="#nav-cas"
                  role="tab"
                  aria-controls="nav-cas"
                  aria-selected="true"
                  onClick={() => {
                    this.changeTab("carry_staffs");
                  }}
                >
                  配達スタッフ
                </a>
                <a
                  className={`nav-item nav-link ${
                    activeTab === "requests" ? "active" : ""
                  }`}
                  id="nav-req-tab"
                  data-toggle="tab"
                  href="#nav-req"
                  role="tab"
                  aria-controls="nav-req"
                  aria-selected="false"
                  onClick={() => {
                    this.changeTab("requests");
                  }}
                >
                  依頼
                </a>
                <a
                  className={`nav-item nav-link ${
                    activeTab === "third_party_delivery_tasks" ? "active" : ""
                  }`}
                  id="nav-tpd-tab"
                  data-toggle="tab"
                  href="#nav-tpd"
                  role="tab"
                  aria-controls="nav-tpd"
                  aria-selected="false"
                  onClick={() => {
                    this.changeTab("third_party_delivery_tasks");
                  }}
                >
                  外部配達
                </a>
              </div>
            </nav>
            <div
              className="tab-content"
              id="nav-tabContent"
              style={{ flexGrow: 1, overflow: "hidden" }}
            >
              <div
                className="tab-pane fade show active"
                id="nav-cas"
                role="tabpanel"
                aria-labelledby="nav-cas-tab"
                style={{ height: "100%" }}
              >
                {activeTab == "carry_staffs" && (
                  <CarryStaffList masterTags={masterTags} />
                )}
              </div>
              <div
                className="tab-pane fade"
                id="nav-req"
                role="tabpanel"
                aria-labelledby="nav-req-tab"
                style={{ height: "100%" }}
              >
                {activeTab == "requests" && (
                  <RequestList masterTags={masterTags} />
                )}
              </div>
              {/*<div*/}
              {/*  className="tab-pane fade"*/}
              {/*  id="nav-tpd"*/}
              {/*  role="tabpanel"*/}
              {/*  aria-labelledby="nav-tpd-tab"*/}
              {/*  style={{ height: '100%' }}*/}
              {/*>*/}
              {/*  {*/}
              {/*    activeTab == 'third_party_delivery_tasks' &&*/}
              {/*    <ThirdPartyDeliveryTaskList />*/}
              {/*  }*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 4.375rem - 10rem)",
  } as CSSProperties,
  openContainer: {
    position: "absolute",
    left: 0,
    width: "45%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 10,
    marginLeft: 30,
    zIndex: 2,
    transition: "width 0.5s ease-in-out",
  } as CSSProperties,
  closeContainer: {
    position: "absolute",
    left: 0,
    width: "30px",
    height: "100%",
    backgroundColor: "#fff",
    paddingTop: 10,
    zIndex: 2,
    transition: "width 0.5s ease-in-out",
  } as CSSProperties,
  openIcon: {
    cursor: "pointer",
    width: "24px",
    height: "24px",
    margin: "0 0 0 auto",
    textAlign: "center",
  } as CSSProperties,
  closeIcon: {
    cursor: "pointer",
    width: "24px",
    height: "24px",
    textAlign: "center",
  } as CSSProperties,
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  } as CSSProperties,
  map: {
    position: "absolute",
    right: 0,
    width: "calc(100% - 30px)", // closeContainer.width分
  } as CSSProperties,
};

export default observer(CarryStaffOverlook);
