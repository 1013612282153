export const excludedReasonCodes = {
  ALREADY_ASSIGNED: "ALREADY_ASSIGNED",
  NEED_NOT_DELIVER: "NEED_NOT_DELIVER",
  NO_SCHEDULE_PICKUP: "NO_SCHEDULE_PICKUP",
  NO_TERRITORY_PICKUP: "NO_TERRITORY_PICKUP",
  NO_SCHEDULE_PICKUP_AND_TERRITORY: "NO_SCHEDULE_PICKUP_AND_TERRITORY",
  NO_SCHEDULE_DELIVER: "NO_SCHEDULE_DELIVER",
  NO_TERRITORY_DELIVER: "NO_TERRITORY_DELIVER",
  NO_SCHEDULE_DELIVER_AND_TERRITORY: "NO_SCHEDULE_DELIVER_AND_TERRITORY",
  NO_CARRY_STAFF_CAN_PICKUP_AND_DELIVER:
    "NO_CARRY_STAFF_CAN_PICKUP_AND_DELIVER",
  UNKNOWN: "UNKNOWN",
} as const;

// んー、label部分がstringになってしまうのが微妙、だが
export const excludedReasonLabels: {
  [key in keyof typeof excludedReasonCodes]: string;
} = {
  ALREADY_ASSIGNED: "すでにアサインされています。",
  NEED_NOT_DELIVER: "配達の必要がない依頼です。",
  NO_SCHEDULE_PICKUP:
    "集荷時刻を含むスケジュールを持つ配達スタッフがいません。",
  NO_TERRITORY_PICKUP: "集荷場所がどの担当エリアにも含まれていません。",
  NO_SCHEDULE_PICKUP_AND_TERRITORY:
    "集荷時刻と集荷場所を満たすスケジュールを持つ配達スタッフがいません。",
  NO_SCHEDULE_DELIVER:
    "配達希望時刻を含むスケジュールを持つ配達スタッフがいません。",
  NO_TERRITORY_DELIVER: "配達場所がどの担当エリアにも含まれていません。",
  NO_SCHEDULE_DELIVER_AND_TERRITORY:
    "配達時刻と配達場所を満たすスケジュールを持つ配達スタッフがいません。",
  NO_CARRY_STAFF_CAN_PICKUP_AND_DELIVER:
    "スケジュール内で集荷と配達を共に実行可能な配達スタッフがいません。",
  UNKNOWN:
    "配達スタッフおよびスケジュールの設定状況により自動アサイン対象から外されました。",
} as const;

export const DEFAULT_EXCLUDED_REASON_CODE = "UNKNOWN";

/**
 * ここを変更する場合には、
 * `app/views/routes_api_param_settings/index.html.haml`の
 * ```haml
 * %td= I18n.t("enums.routes_api_param_setting.geoapify_optimization_type.balanced")
 * %td= I18n.t("enums.routes_api_param_setting.geoapify_travel_mode.drive")
 * ```
 * も合わせて変更すること。
 */
export const DEFAULT_GEOAPIFY_OPTIMIZATION_TYPE_FOR_EC_AND_NM = "balanced";
export const DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM = "drive";
