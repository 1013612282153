import React, { FC } from "react";
import { COLORS } from "../../../constants/Colors";
import { pageStore } from "../stores";
import { format } from "date-fns";
import { RelatedCarryStaffWithSchedules } from "../interfaces";

interface Props {
  carryStaff: RelatedCarryStaffWithSchedules;
  onSelectCarryStaff?: (casId: number) => void;
  selectedCarryStaffId?: number;
}

export const RelatedCarryStaffItem: FC<Props> = (props) => {
  const { carryStaff, onSelectCarryStaff, selectedCarryStaffId } = props;
  const handleSetSelectedCarryStaffId = (casId: number) => {
    if (!onSelectCarryStaff) {
      return;
    }
    onSelectCarryStaff(casId);
  };

  return (
    <li
      className={`p-2 list-group-item ${onSelectCarryStaff ? "list-group-item-action" : ""} ${selectedCarryStaffId == carryStaff.id ? "bg-gray-200" : ""}`}
      style={onSelectCarryStaff ? { cursor: "pointer" } : {}}
      onClick={() => handleSetSelectedCarryStaffId(carryStaff.id)}
    >
      <a
        href={`/carry_staffs/${carryStaff.id}/carry_staff_request_sequence/edit`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ width: "fit-content" }}
      >
        {carryStaff.name}
      </a>
      <div className="d-flex">
        <div className="d-flex align-items-center mr-2">
          <i className="fas fa-fw fa-street-view" />
          <span>
            {carryStaff.territory_name ? carryStaff.territory_name : " - "}
          </span>
        </div>
        <div className="d-flex align-items-center">
          <i className="fas fa-fw fa-truck" />
          <span>
            {carryStaff.vehicle_type_name
              ? carryStaff.vehicle_type_name
              : " - "}
          </span>
        </div>
      </div>
      <div>
        依頼数:&nbsp;
        <span
          style={{
            fontWeight: "bold",
            textAlign: "center",
            color: COLORS.brand.anycarry,
          }}
        >
          {
            pageStore.assignedRequests.filter(
              (req) => req.carry_staff_id == carryStaff.id,
            ).length
          }
        </span>
      </div>
      <div className="d-flex align-items-center" style={{ fontSize: 14 }}>
        <div className="d-flex flex-column align-items-start">
          <i className="fas fa-clock" />
        </div>
        <div className="px-1 d-flex flex-column justify-content-center">
          {carryStaff.schedules.map((schedule, index) => {
            return (
              <div key={`schedule_${schedule.id}`}>
                <span>
                  {format(new Date(`${schedule.from_time}`), "HH:mm")}
                </span>
                〜
                <span>{format(new Date(`${schedule.to_time}`), "HH:mm")}</span>
                {index < carryStaff.schedules.length - 1 ? (
                  <span className="mr-1">,</span>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </li>
  );
};
