import React from "react";
import Modal from "react-modal";
import { formatIso8601Time } from "../../../utils/TimeUtils";
import type { RequestSequence } from "../interfaces";
import { getTimeLabel } from "../utils";

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  sequences: RequestSequence[];
  onCloseModal: () => void;
}

export default function EatResultConfirmModal(props: Props) {
  const { isOpen, isLoading, sequences, onCloseModal } = props;

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <div className="h5 my-2 d-flex align-items-center">
        到着予想時刻確認
        {isLoading && (
          <div className="d-flex align-items-center ml-3">
            <div className="mr-2">
              <span style={{ fontSize: 16 }}>更新中...</span>
            </div>
            <div
              className="spinner-border spinner-border-sm ml-3"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-column">
        <div
          className="px-4 d-flex flex-column overflow-auto"
          style={{
            maxHeight: "calc(100vh - 250px)",
          }}
        >
          <div className="d-flex flex-column gap-4">
            {sequences.map((seq, index) => {
              return (
                <React.Fragment key={seq.sequence}>
                  <div className="d-flex col-12">
                    <div className="d-flex align-items-center">
                      <span>{index + 1}</span>
                    </div>
                    <div className="flex-grow-1">
                      <div
                        className="col-12 d-flex justify-content-start align-items-start gap-1"
                        style={{ fontSize: 16 }}
                      >
                        <div className="d-flex align-items-center col-3">
                          {seq.destination_type == "sender" ? (
                            <i className="fas fa-fw fa-store mr-2" />
                          ) : (
                            <i className="fas fa-fw fa-user mr-2" />
                          )}
                          <span>依頼No.{seq.request_id}</span>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center col-9">
                          <div className="d-flex col-12">
                            <div className="col-4">希望時間</div>
                            <div className="col-8">{getTimeLabel(seq)}</div>
                          </div>
                          <div className="d-flex col-12">
                            <div className="col-4">到着予想時間</div>
                            <div className="col-8">
                              {formatIso8601Time(seq.work_start_time)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {index < sequences.length - 1 ? (
                    <div className="border-top"></div>
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" onClick={() => onCloseModal()}>
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 900,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
