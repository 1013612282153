import _ from 'lodash';
import { observer } from "mobx-react";
import React from "react";
import deaasAreaOverlookStore from '../stores/DeaasAreaOverlookStore';


interface Props {
}

interface State {
  searchCondition: string;
}

class DeaasAreaOverlookSenderList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchCondition: ""
    }
  }

  componentDidMount(): void {
  }

  render() {
    if (_.isEmpty(deaasAreaOverlookStore.visibleVendorCompanies)) {
      return (
        <div>
          {this.renderSearchBox()}
          有効な配達先がありませんでした。
        </div>
      )
    }

    return (
      <div>
        {this.renderSearchBox()}
        {this.renderSenderList()}
      </div>
    );
  }

  private renderSenderList() {
    return _.map(deaasAreaOverlookStore.visibleVendorCompanies, (vendorCompany) => {

      if (!this.matchSearchCondition(vendorCompany.model.name)) {
        return null
      }
      return (
        <div
          key={`vendorcompany-${vendorCompany.id}`}  
          className={"form-check mb-2"}
        >
          <input 
            id={`vendorcompany-${vendorCompany.id}`}
            type="checkbox"
            className={"form-check-input"}
            defaultChecked={vendorCompany.visible}
            onClick={() => {
              deaasAreaOverlookStore.toggleVendorCompanyVisible(vendorCompany);
            }}
          />
          <label
            className={"form-check-label"}
            htmlFor={`vendorcompany-${vendorCompany.id}`}
          >
            {vendorCompany.model.name}
          </label>
        </div>
      )
    })
  }

  private renderSearchBox() {
    return (
      <React.Fragment>
        <div className="form-group form-inline mb-2">
          <input
            type="number"
            className="form-control input-overlook-range"
            value={deaasAreaOverlookStore.vendorCompanyRange}
            placeholder={""}
            onChange={(event) => {
              deaasAreaOverlookStore.setVendorCompanyRange(Number(event.target.value))
            }}
          />
          <span>㍍</span>
          <button className="btn btn-primary ml-2" onClick={()=> {
            deaasAreaOverlookStore.viewAllVendorCompanies()
          }}>
            <i className={"fas fa-eye"}/>
          </button>
          <button className="btn btn-primary ml-2" onClick={()=> {
            deaasAreaOverlookStore.hiddenAllVendorCompanies()
          }}>
            <i className={"fas fa-eye-slash"}/>
          </button>
        </div>
        <div className="form-group mb-2">
          <input type="text" className="form-control" value={this.state.searchCondition}
                 placeholder={"スペースで複数指定"}
                 onChange={(event) => {
                   this.setState({
                     searchCondition: event.target.value
                   })
                 }}
          />
        </div>
      </React.Fragment>
    )
  }

  private matchSearchCondition(name: string) {
    const searchConditions = this.state.searchCondition.trim().split(/[　 ]/);
    if (searchConditions.length == 0) {
      return true
    }

    return _.some(searchConditions, (searchCondition) => {
      return (name.indexOf(searchCondition) > -1)
    })
  }
}

export default observer(DeaasAreaOverlookSenderList);
