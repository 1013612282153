import React from "react";
import { PinShape, Shape } from "./PinShape";
import { MarkerIcon, SVGIcon } from "./MarkerIcon";
import { PinPulse } from "./PinPulse";
import { PinBadge, Position } from "./PinBadge";
import { PinSpinner } from "./PinSpinner";
import { MarkerText } from "./MarkerText";

interface PulseStyle {
  hexColor?: string;
  durationMs?: number;
  size?: number;
}
interface BadgeStyle {
  // PinBadge.tsx内で枠線は"#fff"固定
  backgroundColor?: string;
  iClass?: string | null;
}
interface SpinnerStyle {
  size?: number;
  hexColor?: string;
  borderWidth?: number;
  durationMs?: number;
  opacity?: number;
  progress?: number;
}

type Props = {
  lat?: number;
  lng?: number;
  size: number;
  pulse?: boolean;
  pulseStyle?: PulseStyle;
  spinner?: boolean;
  spinnerStyle?: SpinnerStyle;
  pinShape?: Shape;
  pinText?: string | undefined;
  pinImageUrl?: string;
  pinTextColor?: string;
  pinBackgroundColor?: string;
  svgIcon?: SVGIcon | undefined;
  svgIconColor?: string;
  strokeWidth?: number;
  topRightBadgeIcon?: SVGIcon;
  topRightBadgeText?: string | null;
  topRightBadgeStyle?: BadgeStyle;
  topLeftBadgeIcon?: SVGIcon;
  topLeftBadgeText?: string | null;
  topLeftBadgeStyle?: BadgeStyle;
  middleRightBadgeIcon?: SVGIcon;
  middleRightBadgeText?: string | null;
  middleRightBadgeStyle?: BadgeStyle;
  middleLeftBadgeIcon?: SVGIcon;
  middleLeftBadgeText?: string | null;
  middleLeftBadgeStyle?: BadgeStyle;
  zIndex?: number;
  onClick: (event: any) => void;
};
export class MapMarkerPin extends React.Component<Props> {
  render() {
    const {
      size,
      pulse,
      pulseStyle,
      spinner,
      spinnerStyle,
      pinShape = "drop",
      pinText,
      pinImageUrl,
      pinTextColor,
      pinBackgroundColor = "#666",
      svgIcon,
      svgIconColor = "#fff",
      strokeWidth = 2,
      topRightBadgeIcon,
      topRightBadgeText,
      topRightBadgeStyle,
      topLeftBadgeIcon,
      topLeftBadgeText,
      topLeftBadgeStyle,
      middleRightBadgeIcon,
      middleRightBadgeText,
      middleRightBadgeStyle,
      middleLeftBadgeIcon,
      middleLeftBadgeText,
      middleLeftBadgeStyle,
      onClick,
      zIndex,
    } = this.props;

    // ピンの枠線はテキストまたはsvgアイコンの色と揃える
    const stroke = pinTextColor ?? svgIconColor;
    const svgIconSize = size * 0.7;

    return (
      <div
        className="map-marker-pin"
        style={{
          position: "relative",
          width: 0,
          height: 0,
          zIndex: zIndex,
        }}
      >
        {pulse && <PinPulse {...pulseStyle} />}
        {spinner && <PinSpinner {...spinnerStyle} />}
        <div
          className="map-marker-pin--inner"
          onClick={onClick}
          style={{
            width: size,
            height: size * 1.4, // ピン画像のアスペクト比率 1:1.4
            transform: `translate(-${size / 2}px, -${size * 1.4}px)`, // 座標中心に表示を合わせる
          }}
        >
          {this.getBadge(
            pinShape,
            "top-right",
            topRightBadgeIcon,
            topRightBadgeText,
            topRightBadgeStyle,
            svgIconColor
          )}
          {this.getBadge(
            pinShape,
            "top-left",
            topLeftBadgeIcon,
            topLeftBadgeText,
            topLeftBadgeStyle,
            svgIconColor
          )}
          {this.getBadge(
            pinShape,
            "middle-right",
            middleRightBadgeIcon,
            middleRightBadgeText,
            middleRightBadgeStyle,
            svgIconColor
          )}
          {this.getBadge(
            pinShape,
            "middle-left",
            middleLeftBadgeIcon,
            middleLeftBadgeText,
            middleLeftBadgeStyle,
            svgIconColor
          )}

          <PinShape
            size={size}
            // 画像が存在する場合、画像に枠線をつけるために塗りつぶしの色をstrokeと合わせる
            fill={pinImageUrl ? stroke : pinBackgroundColor}
            shape={pinShape}
            stroke={stroke}
            strokeWidth={strokeWidth}
            pinImageUrl={pinImageUrl}
          />
          <div
            className="map-marker-pin--icon-wrapper"
            style={{
              position: "absolute",
              bottom: size * 0.55, // アイコンが中心に合うように微調整している
              right: size * 0.145, // アイコンが中心に合うように微調整している
            }}
          >
            {this.getPinContents(
              svgIcon,
              pinText,
              pinTextColor,
              pinImageUrl,
              svgIconSize,
              svgIconColor
            )}
          </div>
        </div>
      </div>
    );
  }

  private getPinContents(
    svgIcon: SVGIcon | undefined,
    pinText: string | undefined,
    pinTextColor: string | undefined,
    pinImageUrl: string | undefined,
    iconSize: number,
    svgIconColor: string
  ) {
    // 表示優先順位：画像 > テキスト > アイコン
    if (pinImageUrl) {
      return null;
    } else if (pinText) {
      return <MarkerText text={pinText} size={iconSize} color={pinTextColor} />;
    } else if (svgIcon) {
      return <MarkerIcon icon={svgIcon} size={iconSize} color={svgIconColor} />;
    }
    return null;
  }

  private getBadge(
    sourcePinShape: Shape,
    position: Position,
    badgeIcon: SVGIcon | undefined,
    badgeText: string | null | undefined,
    badgeStyle: BadgeStyle | undefined,
    svgIconColor: string
  ) {
    if (badgeIcon || badgeText || badgeStyle?.iClass) {
      return (
        <PinBadge
          position={position}
          sourcePinShape={sourcePinShape}
          {...badgeStyle}
        >
          {this.getBadgeContents(badgeIcon, badgeText, svgIconColor)}
        </PinBadge>
      );
    }
  }

  private getBadgeContents(
    badgeIcon: SVGIcon | undefined,
    badgeText: string | null | undefined,
    svgIconColor: string
  ) {
    // 表示優先順位：テキスト > アイコン
    if (badgeText) {
      return badgeText;
    } else if (badgeIcon) {
      // アイコンをバッジ化した際はマウスオーバーで拡大させたくないためsizeを手動設定
      return <MarkerIcon icon={badgeIcon} color={svgIconColor} size={11} />;
    }
    return null;
  }
}
