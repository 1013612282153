import { action, observable, runInAction } from "mobx";
import { axiosGet } from "../../../../utils/AxiosClient";
import type { RawStaffLocationState, StaffLocationState } from "../interfaces";

export class StaffLocationStatesStore {
  @observable.shallow
  states: StaffLocationState[] = [];

  @observable
  isLoading = false;

  perPage = 8;

  @observable
  lastPage = 1;

  @observable
  updatedAt: Date | undefined = undefined;

  @action
  public async loadStaffLocationStates(carryStaffId: number, page: number) {
    this.isLoading = true;

    try {
      const { states, total_count } = await this._load({
        carryStaffId,
        page,
        perPage: this.perPage,
      });
      runInAction(() => {
        this.states = states.map((state) => ({
          ...state,
          on_off: state.on_off ? "on" : "off",
        }));
        this.lastPage = Math.ceil(total_count / this.perPage);
        this.isLoading = false;
        this.updatedAt = new Date();
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  private async _load(params: {
    carryStaffId: number;
    page: number;
    perPage: number;
  }) {
    const { carryStaffId, page, perPage } = params;
    const urlParams = new URLSearchParams([
      ["page", `${page}`],
      ["per_page", `${perPage}`],
    ]);

    const response = (await axiosGet.get(
      `/api/carry_staffs/${carryStaffId}/states?${urlParams.toString()}`
    )) as {
      data: {
        states: RawStaffLocationState[];
        total_count: number;
      };
    };
    return response.data;
  }
}

const singleton = new StaffLocationStatesStore();
export default singleton;
