import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { requestConst } from "../../../constants/Request";
import { RequestModel } from "../../../models/RequestModel";
import I18n from "../../../packs/i18n/i18n";
import "../../../packs/i18n/ja";
import requestsStore from "../../../stores/RequestsStore";
import { MasterTag } from "../interfaces/Interfaces";
import RequestTableBody from "../Panes/RequestTableBody";
import searchConditionsStore from "../stores/SearchConditionsStore";
import { filterRequests } from "../utils/FilterUtils";
import { isLoading, selectItem } from "../utils/Utils";

interface Props {
  masterTags: MasterTag[];
}

class RequestList extends React.Component<Props> {
  scrollableElementRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.scrollableElementRef = React.createRef();
  }

  handleChangeText(searchText: string) {
    searchConditionsStore.changeCond("requests", "text", searchText);
  }

  handleChangeStatus(newStatus: string) {
    const value =
      newStatus == "all" || newStatus == "processing" ? newStatus : +newStatus;

    searchConditionsStore.changeCond("requests", "status", value);
  }

  handleChangeTagId(newTagId: string) {
    searchConditionsStore.changeCond(
      "requests",
      "tagId",
      newTagId === "all" ? newTagId : +newTagId
    );
  }

  handleChangeIsUnassined(oldIsUnassined: boolean) {
    searchConditionsStore.changeCond(
      "requests",
      "isUnassined",
      !oldIsUnassined
    );
  }

  scrollToTop() {
    if (this.scrollableElementRef.current) {
      this.scrollableElementRef.current.scrollTop = 0;
    }
  }

  private renderSearchInput(requestCount: number) {
    const masterTags = this.props.masterTags;
    const isUnassined = searchConditionsStore.requestsConditions.isUnassined;

    return (
      <div style={styles.searchWrapper}>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="依頼NO / 受注NO / 外部NO / 住所 / 氏名検索"
            value={searchConditionsStore.requestsConditions.text}
            onChange={(event) => this.handleChangeText(event.target.value)}
          />
          <div
            style={{
              position: "relative",
              transform: "translate(-30px, 0)",
              pointerEvents: "none",
            }}
          >
            <i className="fas fa-search" />
          </div>
        </div>
        <div className="d-flex mt-2 mr-2">
          <div
            className="d-flex align-items-center mr-2"
            style={{ width: "80%" }}
          >
            <label style={styles.searchLabel} htmlFor="statusSelect">
              ステータス{" "}
            </label>
            <select
              id="statusSelect"
              className="form-control form-control-sm ml-2"
              onChange={(e) => this.handleChangeStatus(e.target.value)}
            >
              <option key="processing" value="processing">
                処理中
              </option>
              {Object.keys(requestConst.PROCESSING_STATUSES).map((key) => {
                const statusNumber = requestConst.PROCESSING_STATUSES[key];
                return (
                  <option key={statusNumber} value={statusNumber}>
                    {I18n.t(`enums.request.status.${key}`)}
                  </option>
                );
              })}
            </select>
          </div>

          <div
            className="d-flex align-items-center mr-2"
            style={{ width: "80%" }}
          >
            <label style={styles.searchLabel} htmlFor="tagSelect">
              タグ{" "}
            </label>
            <select
              id="tagSelect"
              className="form-control form-control-sm ml-2"
              onChange={(e) => this.handleChangeTagId(e.target.value)}
            >
              <option key="all" value="all">
                全て
              </option>
              {masterTags.map((tag) => {
                return (
                  <option key={tag.id} value={tag.id}>
                    {tag.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="text-right mt-2">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="unassignedCheckbox"
              className="custom-control-input"
              checked={isUnassined}
              onChange={() => this.handleChangeIsUnassined(isUnassined)}
            />
            <label
              className="custom-control-label"
              htmlFor="unassignedCheckbox"
            >
              未アサイン依頼のみ表示
            </label>
          </div>
        </div>
        <div className="text-right mt-2">件数：{requestCount}件</div>
      </div>
    );
  }

  private renderTable(requests: RequestModel[]) {
    const loading = searchConditionsStore.showLoadingIcon && isLoading();
    const selectedItem = requestsStore.getSelectedItem();
    return (
      <div style={styles.tableWrapper} ref={this.scrollableElementRef}>
        {loading ? (
          <div className="fa-2x mt-5" style={styles.loading}>
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        ) : (
          <table className="table">
            <thead>
              <tr style={styles.tableLabel}>
                <th scope="col">依頼詳細</th>
              </tr>
            </thead>
            <RequestTableBody
              selectedItem={selectedItem}
              items={requests}
              onSelect={(reqId) => {
                const selectedItem = requestsStore.getSelectedItem();
                if (selectedItem && reqId == selectedItem.id) {
                  requestsStore.clickSameItem();
                } else {
                  selectItem("request", reqId);
                }
                this.scrollToTop();
              }}
            />
          </table>
        )}
      </div>
    );
  }

  render() {
    const targetRequests = filterRequests();
    return (
      <div style={styles.contents}>
        <div style={{ height: "100%" }}>
          {this.renderSearchInput(targetRequests.length)}
          {this.renderTable(targetRequests)}
        </div>
      </div>
    );
  }
}

const styles = {
  contents: {
    overflow: "hidden",
    height: "100%",
  } as CSSProperties,
  searchWrapper: {
    fontSize: "0.8rem",
    margin: "10px",
  } as CSSProperties,
  searchLabel: {
    whiteSpace: "nowrap",
    margin: 0,
  } as CSSProperties,
  tableWrapper: {
    width: "100%",
    height: "75%",
    overflowY: "auto",
  } as CSSProperties,
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  } as CSSProperties,
  tableLabel: {
    fontSize: "0.9rem",
  } as CSSProperties,
};

export default observer(RequestList);
