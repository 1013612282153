import React, {useEffect, useRef} from "react";
import type {MapApi} from "../index";

interface Props {
  mapApi: MapApi,
  children: React.ReactNode,
  left?: number
  top?: number
}

export function Toolbox(props: Props) {
  const {mapApi, children, left} = props
  const toolboxRef = useRef(null)

  useEffect(() => {
    if (toolboxRef.current) {
      mapApi.map.controls[mapApi.maps.ControlPosition.TOP_LEFT].push(toolboxRef.current);
    }
    return () => {
      mapApi.map.controls[mapApi.maps.ControlPosition.TOP_LEFT].clear()
    }
  }, [])

  return (
      <div style={{visibility: "hidden"}}>
        <div ref={toolboxRef} style={{
          background: "#ffffff",
          padding: 8,
          margin: 8,
          boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
          transform: `TranslateX(${left}px)`,
          transition: "0.2s transform",
        }}>
          {children}
        </div>
      </div>
  )
}