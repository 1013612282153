import React from 'react';

interface ImageInfo {
  url: string;
  key: string;
}
interface Props {
  imageInfo: ImageInfo|null
}

interface State {
  modalIsOpen: boolean;
  imageInfo: ImageInfo|null;
}

class ThirdPartyDeliveryTaskScreenshot extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      imageInfo: props.imageInfo
    };
  }

  render() {
    const { imageInfo } = this.state;
    if (!imageInfo) {
      return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span className='h5 mb-2 text-gray-800'>
            外部依頼キャプチャ
          </span>
          <span>なし</span>
        </div>
      )
    }
    return (
      <div style={{
        display: 'flex', 
        flexDirection: 'column'
        }}>
        <span className='h5 mb-2 text-gray-800'>
          外部依頼キャプチャ
        </span>
        <div className={'third-party-delivery-task-screenshot-container'}>
          <img src={imageInfo.url} style={{ height: '100%', width: '100%',maxHeight: 600 }} />
        </div>
      </div>
    )
  }
};

export default ThirdPartyDeliveryTaskScreenshot;