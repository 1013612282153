import type { RawRoutesApiParamSetting } from "../../../interfaces/entities";
import { axiosPost } from "../../../utils/AxiosClient";
import type { SelectableApiType } from "../api-code-utils";
import { parseApiType, selectApiParamSetting } from "../api-code-utils";
import type {
  LoopControlParams,
  RoutesApiCalculateEatInnerParams,
  RoutesApiCalculateEatResponse,
} from "./common";
import { getRoutesApiResultViaAdms } from "./common";

interface Params {
  apiType: SelectableApiType;
  targetDate: string;
  carryStaffId: number;
  sequences: {
    requestId: number;
    destinationType: "sender" | "receiver";
    sequence: number;
  }[];
  routesApiParamSettings: RawRoutesApiParamSetting[];
}

export async function callCalculateEatApi(
  params: Params & { sync: boolean } & LoopControlParams
) {
  const { sync, timeout, eventEmitter, ..._params } = params;

  const innerParams = convertParamsIntoInnerParams(_params);

  // 実際の本番環境で利用されるようなデータ数でどうなるかが、
  // (API Gatewayのタイムアウトである30秒以上かかるかどうかが)
  // 明確ではないので、一応基本は同期で行くけれど、
  // もしタイムアウトエラーになった場合ようにプロパティ指定1つで非同期に切り替えられるようにしておく。
  if (sync) {
    // 同期処理の場合
    const result = await postCalculateEatSyncTaskViaAdms(innerParams);
    return result;
  }

  // 非同期処理の場合
  const postResponse = await postCalculateEatTaskViaAdms(innerParams);
  const result = await getRoutesApiResultViaAdms("calc-eat", {
    transactionCode: postResponse.data.transactionCode,
    timeout,
    eventEmitter,
  });

  return result;
}

function convertParamsIntoInnerParams(params: Params) {
  const {
    apiType,
    targetDate,
    carryStaffId,
    sequences,
    routesApiParamSettings,
  } = params;

  const { apiCode } = parseApiType(apiType);
  const apiParamSetting = selectApiParamSetting(
    apiType,
    routesApiParamSettings
  );

  const innerParams: RoutesApiCalculateEatInnerParams = {
    api_code: apiCode,
    target_date: targetDate,
    carry_staff_id: carryStaffId,
    sequences: sequences.map((seq) => ({
      request_id: seq.requestId,
      destination_type: seq.destinationType,
      sequence: seq.sequence,
    })),
    param_settings: {
      pickup_duration: apiParamSetting.pickup_duration,
      tolerant_seconds_before_pickup_at:
        apiParamSetting.tolerant_seconds_before_pickup_at,
      tolerant_seconds_after_pickup_at:
        apiParamSetting.tolerant_seconds_after_pickup_at,
      dropoff_duration: apiParamSetting.dropoff_duration,
      tolerant_seconds_before_dropoff_at:
        apiParamSetting.tolerant_seconds_before_dropoff_at,
      tolerant_seconds_after_dropoff_at:
        apiParamSetting.tolerant_seconds_before_dropoff_at,
    },
    post_processing: {
      notify: false,
      update: false,
    },
  };

  if (
    (innerParams.api_code == "ec-and-nm" ||
      innerParams.api_code == "geoapify") &&
    innerParams.param_settings
  ) {
    innerParams.param_settings["geoapify"] = {
      travel_mode: apiParamSetting.geoapify_travel_mode,
      max_speed: apiParamSetting.geoapify_max_speed,
      optimization_type: apiParamSetting.geoapify_optimization_type,
    };
  }

  return innerParams;
}

async function postCalculateEatTaskViaAdms(
  params: RoutesApiCalculateEatInnerParams
) {
  const response = (await axiosPost.post("/api/routes_apis/task/calc-eat", {
    ...params,
  })) as {
    data: {
      message: string;
      transactionCode: string;
    };
  };
  return response;
}

async function postCalculateEatSyncTaskViaAdms(
  params: RoutesApiCalculateEatInnerParams
): Promise<RoutesApiCalculateEatResponse> {
  try {
    const response = (await axiosPost.post(
      "/api/routes_apis/task/calc-eat/sync",
      {
        ...params,
      }
    )) as {
      data: {
        code: "COMPLETED";
        result: RoutesApiCalculateEatResponse;
      };
    };
    return response.data.result;
  } catch (error) {
    console.log(error);
    // エラーになった場合、inputParamsはこちら側で設定する(どうしようもないので)
    return {
      isSuccess: false,
      code: "unexpected_error",
      inputParams: params,
    };
  }
}
