import "jquery.easing/jquery.easing.min.js";
import "chart.js"

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import { configure } from 'mobx';

configure({
  enforceActions: 'observed'
});

const gon = window.gon || [];
if (gon && gon.onesignal_external_user_id) {
  window.OneSignalDeferred = window.OneSignalDeferred || [];
  OneSignalDeferred.push(async function(OneSignal) {  
    // OneSignal v11(User Model)
    // https://documentation.onesignal.com/docs/web-sdk-setup#7-add-code-to-site
    // console.log(OneSignal.VERSION) -> 160202
    // https://documentation.onesignal.com/docs/web-sdk-reference
    await OneSignal.init({
      appId: gon.onesignal_app_id,
      persistNotification: false,
      autoResubscribe: true
    });

    const permission = OneSignal.Notifications.permission;
    if (permission) {
      const userId = gon.onesignal_external_user_id;
      // https://documentation.onesignal.com/docs/web-sdk-reference#login
      await OneSignal.login(userId.toString());
      console.debug("[OneSignalDeferred] onesignalId", OneSignal.User.onesignalId);
    } else {
      OneSignal.Notifications.requestPermission();
    }

    OneSignal.User.PushSubscription.addEventListener('change', async function (event) {
      console.debug("[OneSignalDeferred] PushSubscription changed", event);
    });
  });
}
