import { Coords } from "google-map-react";
import { action, observable } from "mobx";
import { thirdPartyDeliveryTaskConst } from "../../../constants/ThirdPartyDeliveryTask";

export type PageType =
  | "carry_staffs"
  | "carry_staff_requests_or_tpdts"
  | "requests"
  | "third_party_delivery_tasks";

type CarryStaffsConditions = {
  text: string;
  staffType: number | "all";
  tagId: number | "all";
};

type CarryStaffRequestsConditions = {
  carryStaff: { id: number | "unassigned"; name: string } | undefined;
  // 表示している選択肢は「処理中」だが、条件としては留置も含めた未配達依頼なので"undelivered"としている
  // 処理が不要な留置はアサイン解除されるはずなので、
  // この画面(CASに紐づく依頼一覧)に関しては留置も含めて配達順を確認できるように
  status: number | "all" | "undelivered";
  visibleSequenceNumber: boolean;
};

type RequestsConditions = {
  text: string;
  status: number | "processing";
  tagId: number | "all";
  isUnassined: boolean;
};

type ThirdPartyDeliveryTasksConditions = {
  text: string;
  status: number | "all";
  workType: keyof typeof thirdPartyDeliveryTaskConst.WORK_TYPES | "all";
};

export class SearchConditionsStore {
  // 正直Conditionsという名前のついたstoreに表示しているページ保存用の変数を持たせるのはどうかと思うけど、
  // このCarryStaffOverlooksでしか利用されないので許して
  @observable
  displayPage: PageType = "carry_staffs";

  // ペイン部分の遷移後初回ロード時、もしくはCAS変更後の初回ロード時だけ、
  // ロードアイコンを出したいので、それ用のフラグ
  // (初回以降のロード時も出してしまうと、30秒単位でぐるぐるしてしまうので邪魔になる)
  @observable
  showLoadingIcon = false;

  // filterなどは実行させず、ただ単純にその地点に地図の中心を持っていきたい時に利用する変数
  // CAS一覧でCASを選択(詳細画面への遷移はなし)した場合に、そのCASを中心に持ってきたく、
  // それ用の変数
  @observable.ref
  locationAt: Coords | undefined;

  @observable
  carryStaffsConditions: CarryStaffsConditions = this.createCasInitCond();

  @observable.shallow
  carryStaffRequestsConditions: CarryStaffRequestsConditions =
    this.createCasReqInitCond();

  @observable
  requestsConditions: RequestsConditions = this.createReqInitCond();

  @observable
  thirdPartyDeliveryTasksConditions: ThirdPartyDeliveryTasksConditions =
    this.createTpdtInitCond();

  /**
   * 単純にペインを移動する場合用のメソッド.
   * @param page
   */
  @action
  public moveTo(page: PageType) {
    // 対象のページの条件をリセットしてから
    this.resetCondition(page);
    // ページ情報を更新する前に、ローディングフラグを立てる
    this.setShowLodingIcon(true);
    // ページ情報を更新
    this.displayPage = page;
  }

  /**
   * CAS一覧からCASを選択してCASに紐づく依頼一覧に遷移する場合用のメソッド.
   * @param page
   * @param target
   * @param value
   */
  @action
  public changeCondAndMoveTo(page: PageType, target: string, value: any) {
    // 対象のページの条件をリセットしてから
    this.resetCondition(page);
    this.changeCond(page, target, value);
    // ページ情報を更新する前に、ローディングフラグを立てる
    this.setShowLodingIcon(true);
    // ページ情報を更新
    this.displayPage = page;
  }

  @action
  public changeCond(page: PageType, target: string, value: any) {
    switch (page) {
      case "carry_staffs":
        this.carryStaffsConditions[target] = value;
        break;
      case "carry_staff_requests_or_tpdts":
        this.carryStaffRequestsConditions[target] = value;
        break;
      case "requests":
        this.requestsConditions[target] = value;
        break;
      case "third_party_delivery_tasks":
        this.thirdPartyDeliveryTasksConditions[target] = value;
        break;
    }
  }

  @action
  public resetCondition(page: PageType) {
    switch (page) {
      case "carry_staffs":
        this.carryStaffsConditions = this.createCasInitCond();
        break;
      case "carry_staff_requests_or_tpdts":
        this.carryStaffRequestsConditions = this.createCasReqInitCond();
        break;
      case "requests":
        this.requestsConditions = this.createReqInitCond();
        // 配達員詳細ページでの指定条件を持ち込みたくないため
        this.carryStaffRequestsConditions = this.createCasReqInitCond();
        break;
      case "third_party_delivery_tasks":
        this.thirdPartyDeliveryTasksConditions = this.createTpdtInitCond();
        break;
    }
  }

  @action
  public moveToLocation(location: Coords) {
    this.locationAt = { ...location };
  }

  @action
  public setShowLodingIcon(show: boolean) {
    this.showLoadingIcon = show;
  }

  private createCasInitCond(): CarryStaffsConditions {
    return {
      text: "",
      staffType: "all",
      tagId: "all",
    };
  }

  private createCasReqInitCond(): CarryStaffRequestsConditions {
    return {
      carryStaff: undefined,
      status: "undelivered",
      visibleSequenceNumber: false,
    };
  }

  private createReqInitCond(): RequestsConditions {
    return {
      text: "",
      status: "processing",
      tagId: "all",
      isUnassined: false,
    };
  }

  private createTpdtInitCond(): ThirdPartyDeliveryTasksConditions {
    return {
      text: "",
      status: "all",
      workType: "all",
    };
  }
}

const singleton = new SearchConditionsStore();
export default singleton;
