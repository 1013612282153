import { useState } from "react";

interface Props {
  toggleFeature: (geometry: any, id: number | string) => void;
}

export default function useRadiusMode(props: Props) {
  const [radiusSize, setRadiusSize] = useState(5);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  return {
    radiusSize,
    latitude,
    longitude,
    setRadiusSize,
    setLatitude,
    setLongitude
  };
}
