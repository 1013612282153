import { endOfDay, format, startOfDay, subHours } from "date-fns";

/**
 * こんな独自実装じゃなくて、date-fnsのstartOfDay, endOfDayを利用すべきだったと反省
 * @returns
 */
export function getBeginningAndEndOfToday() {
  const now = new Date();
  const beginningOfToday = new Date(format(now, "yyyy-MM-dd 00:00:00+09:00"));
  const endOfToday = new Date(
    format(
      new Date(beginningOfToday.getTime() + 1000 * 60 * 60 * 24 * 1),
      "yyyy-MM-dd 00:00:00+09:00"
    )
  );

  return {
    beginningOfToday,
    endOfToday,
  };
}

/**
 * UTCで渡された日付時刻と、ローカルの同時刻のDateオブジェクトを返す。
 * ex 1900-01-01 15:00:00(UTC)の文字列が渡された場合、 ローカルで1900-01:01 15:00:00(JST)のDateオブジェクトを返す。
 *
 * railsのtime型は日本時刻表示でUTCで保存している。そのため、javascriptの世界に持ってきた時、15時がUTCで15時になるため、そのままDateに変換すると
 * 日本時間の翌日0時になってしまう。そのため、日本時間の15時と扱える日付オブジェクトを返す。
 *
 * @param utcIsoText
 * @param date
 */
export function convertSameJstTimeFromUtc(utcIsoText: string) {
  return subHours(new Date(utcIsoText), 9);
}

/**
 * targetDateがyyyy-MM-dd形式の文字列かどうかを判定する関数.
 *
 * こっそり予想外の挙動をされるのは面倒なので、違ったら即エラー
 * 基本的にtargetDateに渡されるのは日付文字列だけの想定で、
 * それ以外の文字列になっている箇所があるかどうかの判定に実質利用
 * @param targetDate
 */
export function validateDateStr(targetDate: string) {
  // \dを使うと、prettierで"\"を消されてしまうので仕方なく[0-9]
  if (!new RegExp("2[0-1][0-9]{2}-[0-1][0-9]-[0-3][0-9]").test(targetDate)) {
    throw Error(`Invalid date format:${targetDate}`);
  }
}

export function getTodayStr() {
  const today = format(new Date(), "yyyy-MM-dd");
  return today;
}

export function getBeginningAndEndOfDay(targetDate: string) {
  validateDateStr(targetDate);
  const baseDate = new Date(`${targetDate}T09:00:00.000+09:00`);
  const _beginningOfDay = startOfDay(baseDate);
  const _endOfDay = endOfDay(baseDate);
  return {
    beginningOfDay: _beginningOfDay,
    endOfDay: _endOfDay,
  };
}

export function checkIsFuture(targetDate: string) {
  validateDateStr(targetDate);
  const today = getTodayStr();
  return today < targetDate;
}

export function isSameDate(date1: string | Date, date2: string | Date) {
  const dateStr1 =
    typeof date1 == "string"
      ? date1.substring(0, 10)
      : format(date1, "yyyy-MM-dd");
  const dateStr2 =
    typeof date2 == "string"
      ? date2.substring(0, 10)
      : format(date2, "yyyy-MM-dd");
  return dateStr1 == dateStr2;
}

export function isOverlapTimes(
  range1: { from: Date; to: Date },
  range2: { from: Date; to: Date }
) {
  if (range1.to < range2.from) {
    return false;
  }

  if (range2.to < range1.from) {
    return false;
  }

  return true;
}
