import React, { ChangeEvent, MouseEvent } from "react";

interface Props {
  radiusSize: number;
  latitude: string;
  longitude: string;
  onChangeRadiusSize: (radiusSize: number) => void;
  onChangeLatitude: (latitude: string) => void;
  onChangeLongitude: (longitude: string) => void;
  onClickAddCircle: (latitude: string, longitude: string, radiusSize: number) => void;
  hideAddButton?: boolean | undefined;
}

export default function MAPToolboxContentForRadius(props: Props) {
  const {
    radiusSize,
    latitude,
    longitude,
    onChangeRadiusSize,
    onChangeLatitude,
    onChangeLongitude,
    onClickAddCircle,
    hideAddButton
  } = props;

  const handleChangeRadiusSize = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeRadiusSize(Number(e.target.value));
  };

  const handleChangeLatitude = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeLatitude(e.target.value);
  };

  const handleChangeLongitude = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeLongitude(e.target.value);
  };

  const handleClickAddCircle = (e: MouseEvent<HTMLButtonElement>) => {
    onClickAddCircle(latitude, longitude, Number(radiusSize));
  };

  return (
    <div>
      <div>
        <div className="row">
          <label htmlFor="radiusSize" className="col-sm-4 col-form-label">
            半径(m)
          </label>
          <div className="col-sm-8">
            <div className="d-flex">
              <div className="col-auto">
                <input
                  className="form-control"
                  value={radiusSize}
                  onChange={handleChangeRadiusSize}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div style={ hideAddButton ? { display: "none" } : undefined }>
          <hr />
          <div className="row">
            <label htmlFor="latitude" className="col-sm-4 col-form-label">
              緯度
            </label>
            <div className="col-sm-8">
              <div className="d-flex">
                <div className="col-auto">
                  <input
                    id="latitude"
                    value={latitude}
                    onChange={handleChangeLatitude}
                    className="form-control"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <label htmlFor="longitude" className="col-sm-4 col-form-label">
              経度
            </label>
            <div className="col-sm-8">
              <div className="d-flex">
                <div className="col-auto">
                  <input
                    id="longitude"
                    value={longitude}
                    onChange={handleChangeLongitude}
                    className="form-control"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className={`btn btn-light d-flex mb-1 active col-sm-12`}
              style={{ width: "100%" }}
              onClick={handleClickAddCircle}
              disabled={latitude === "" || longitude === "" || isNaN(Number(latitude)) || isNaN(Number(longitude))}
            >
              <div>指定座標に円を追加</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
