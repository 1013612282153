import React from "react";

interface Facility {
  id: number;
  name: string;
}

interface Props {
  selectedParentIds: string[];
  selectableFacilitiesAsParent: Facility[];
}

interface State {
  selectedParentIds: string[];
  selectableFacilitiesAsParentList: Facility[][];
}

export default class FacilityClosureForm extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    const { selectedParentIds, selectableFacilitiesAsParentList } = recreateStateValues(this.props.selectedParentIds, this.props.selectableFacilitiesAsParent);
    this.state = {
      selectedParentIds,
      selectableFacilitiesAsParentList,
    };
  }

  /**
   * 経由元の選択状態が変更された際の処理
   *
   * @param value 経由元として選択された拠点のID
   * @param index 選択状態が変更された要素番号(N番目)
   */
  handleChange = (selectedParentId: string, index: number) => {
    // N+1番目以降の経由元の選択状態をリセットする。
    const _selectedParentIds: string[] = [
      ...this.state.selectedParentIds.slice(0, index),
      selectedParentId,
    ];

    const { selectedParentIds, selectableFacilitiesAsParentList } = recreateStateValues(_selectedParentIds, this.props.selectableFacilitiesAsParent);
    this.setState({
      selectedParentIds,
      selectableFacilitiesAsParentList,
    });
  }

  render() {
    return (
      <div className="form-group flex">
        <label htmlFor="facility_facility_closures">経由元</label>
        <div className="w-100">
          {this.state.selectableFacilitiesAsParentList.map((facilities: Facility[], index: number) => (
            <select
              key={`facility_facility_closures-${index}`}
              id="facility_facility_closures"
              name={"facility[parent_ids][]"}
              className="form-control mb-2"
              defaultValue={this.state.selectedParentIds[index] || ""}
              onChange={(e) => this.handleChange(e.target.value, index)}
            >
              <option value="">
                経由元を選択してください
              </option>
              {facilities.map((facility) => (
                <option key={`facility_${facility.id}`} value={facility.id}>
                  {facility.name}
                </option>
              ))}
            </select>
          ))}
        </div>
      </div>
    );
  }
}

/**
 * ステートとして管理する値を生成し直す
 *
 * @param selectedParentIds 経由元として選択されている拠点のIDのリスト
 * @param selectableFacilities 経由元として選択可能な拠点のリスト
 */
function recreateStateValues(selectedParentIds: string[], selectableFacilitiesAsParent: Facility[]): State {
  console.log("selectedParentIds: ", selectedParentIds);

  let newSelectedParentIds: string[] = [];
  let newSelectableFacilitiesAsParentList: Facility[][] = [[...selectableFacilitiesAsParent]];

  for (let index = 0; index < selectedParentIds.length; index++) {
    // 経由元として選択されている拠点のIDが選択可能な拠点のリストに含まれない場合は、
    // その拠点は経由元として選択されていないものとして扱いループを終了。（ない想定だが、念のため確認）
    const currentSelectableFacilitiesAsParent = newSelectableFacilitiesAsParentList[index];
    const selectedParentId = selectedParentIds[index];
    const selectedFacility: Facility|undefined = currentSelectableFacilitiesAsParent.find((facility) => String(facility.id) === selectedParentId);
    if (!selectedFacility) break;

    newSelectedParentIds.push(selectedParentId);

    // 経由元として選択済の拠点以外を次の選択肢として追加する。
    // 次の選択肢が存在しない場合、リストに追加せずにループを終了。
    let nextSelectableFacilitiesAsParent: Facility[] = currentSelectableFacilitiesAsParent.filter((facility) => facility.id !== selectedFacility.id);
    if (nextSelectableFacilitiesAsParent.length === 0) break;

    newSelectableFacilitiesAsParentList.push(nextSelectableFacilitiesAsParent);
  }

  return {
    selectedParentIds: newSelectedParentIds,
    selectableFacilitiesAsParentList: newSelectableFacilitiesAsParentList,
  }
}
