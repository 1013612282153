import { format } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import DateSelection from "../../../../components/Common/DateSelection";
import { TargetTerm, TargetTermType } from "../../interfaces";
import { pageStore } from "../../stores";

interface Props {}

class UnassignedRequestsSearchComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  private async handleSelectTermType(termType: TargetTermType) {
    const targetTerm: TargetTerm =
      termType == "specified"
        ? {
            type: "specified",
            date: format(new Date(), "yyyy-MM-dd")
          }
        : {
            type: termType
          };
    await pageStore.changeTargetTermAndLoadUnassignedRequests(targetTerm);
  }

  private async handleChangeTargetDate(targetDate: string) {
    await pageStore.changeTargetTermAndLoadUnassignedRequests({
      type: "specified",
      date: targetDate
    });
  }

  render() {
    const targetTerm = pageStore.requestsTargetTerm;
    return (
      <div className="d-flex flex-column justify-content-center align-items-start">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "90%" }}
        >
          <label
            className="my-0 ml-2"
            htmlFor="select-term-type"
            style={{ whiteSpace: "nowrap" }}
          >
            対象期間
          </label>
          <select
            id="select-term-type"
            className="form-control form-control-sm ml-2"
            defaultValue={targetTerm.type}
            onChange={(e) =>
              this.handleSelectTermType(e.target.value as TargetTermType)
            }
          >
            <option key="all" value="all">
              全て
            </option>
            <option key="passed" value="passed">
              過去分
            </option>
            <option key="specified" value="specified">
              日付指定
            </option>
          </select>
        </div>
        {targetTerm.type == "specified" && (
          <div className="ml-2 mt-3 d-flex justify-content-center align-items-center">
            <DateSelection
              date={new Date(`${targetTerm.date} 00:00:00+09:00`)}
              minDate={new Date()}
              showTodayButton={false}
              onChangeDate={(newDate) => {
                this.handleChangeTargetDate(format(newDate, "yyyy-MM-dd"));
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default observer(UnassignedRequestsSearchComponent);
