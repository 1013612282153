import React from 'react';

interface Props  {
    size?: number,
    borderWidth?: number,
    color?: string,
    subColor?: string
    durationMs?: number
    progress?: number
}
export class Spinner extends React.Component<Props> {
    render() {
        const {
            durationMs = 2000,
            size = 28,
            color = "#4285F4",
            subColor = "rgba(0,0,0, 0.1)",
            borderWidth = 6,
            progress = 25,
        } = this.props

        return (
            <>
                <div
                    className={"spinner-tsx__001"}
                    style={{
                        borderRadius: "50%",
                        width: size,
                        height: size,
                        fontSize: 10,
                        position: "absolute",
                        textIndent: "-9999em",
                        borderTop: `${borderWidth}px solid ${(progress >= 100) ?color : subColor}`,
                        borderRight: `${borderWidth}px solid ${(progress >= 75) ?color : subColor}`,
                        borderBottom: `${borderWidth}px solid ${(progress >= 50) ?color : subColor}`,
                        borderLeft: `${borderWidth}px solid ${(progress >= 25) ?color : subColor}`,
                        transform: "translateZ(0)",
                        animation: `spinner-tsx-animation__001 ${durationMs}ms infinite linear`,
                        marginLeft: `-${(size)/ 2 + borderWidth}px`,
                        marginTop: `-${(size )/ 2 + borderWidth}px`,
                        boxSizing: `content-box`,
                    }}
                >
                    <div
                        style={{
                            borderRadius: "50%",
                            width: size,
                            height: size
                        }}

                    >
                    </div>
                </div>
                <style>
                    {
`
@-webkit-keyframes spinner-tsx-animation__001 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-tsx-animation__001 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`
                    }
                </style>
            </>
        )
    }
}
export default Spinner;
