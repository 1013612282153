import React from "react";
import { observer } from "mobx-react";
import "react-toastify/dist/ReactToastify.css";
import DeliveryArea from "../interfaces/DeliveryArea";
import { axiosPost } from "../utils/AxiosClient";
import DeliveryAreaMapWithInputWkt from "./DeliveryAreaMapWithInputWkt";

interface Props {
  vendorCompanyId: number;
  deliveryArea: DeliveryArea;
}

class DeliveryAreaMapWithInputWktVendorCompany extends React.Component<Props> {
  render() {
    return (
      <DeliveryAreaMapWithInputWkt
        deliveryArea={this.props.deliveryArea}
        updateDeliveryArea={async (deliveryAreaWkt) => {
          return await axiosPost.post(
            `/api/vendor_companies/${this.props.vendorCompanyId}/update_delivery_area_wkt`,
            { delivery_area: deliveryAreaWkt }
          );
        }}
        deleteDeliveryArea={async () => {
          return await axiosPost.post(
            `/api/vendor_companies/${this.props.vendorCompanyId}/delete_delivery_area`
          );
        }}
      />
    );
  }
}

export default observer(DeliveryAreaMapWithInputWktVendorCompany);
