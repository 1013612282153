import React from "react";
import { axiosGet, axiosPost } from "../utils/AxiosClient";

interface Props {
  src: string;
  userId: number;
}

interface State {
  hasSoundAlert: boolean;
}

class UserNewRequestAlertAudio extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasSoundAlert: false,
    };
  }

  async componentDidMount() {
    await this.getNewRequestAlert();
  }

  componentDidUpdate() {}

  public async getNewRequestAlert() {
    await axiosGet.get(`/api/user_new_request_alert/`).then((response) => {
      this.setNewRequestAlert(response.data.is_sound);
    });
  }

  private setNewRequestAlert(isSound: boolean) {
    this.setState(
      {
        hasSoundAlert: isSound,
      },
      () => {
        this.playOrStopAudio();
      }
    );
  }

  public async deleteNewRequestAlert(userId: number) {
    await axiosPost.delete(`/api/user_new_request_alert/${userId}`);
  }

  render() {
    return (
      <audio id="new_requst_alert_audio" src={this.props.src} preload="auto" />
    );
  }

  private async playOrStopAudio() {
    const audio = document.querySelector("#new_requst_alert_audio") as any;
    if (this.state.hasSoundAlert) {
      audio.play();
      await this.deleteNewRequestAlert(this.props.userId);
    }
  }
}

export default UserNewRequestAlertAudio;
