import React from "react";
import { observer } from "mobx-react";
import MapAttributes from "../constants/MapAttributes";
import ServiceArea from "../interfaces/ServiceArea";
import ServiceAreaMarker from "../components/ServiceAreaMarker";
import WktMultiPolygon from "../components/WktMultiPolygon";
import GoogleMap from "../components/Common/GoogleMap";

declare var gon: any;

interface Props {
  woltMerchantOfferArea: {
    lat: number;
    lng: number;
    within_area_wkt: string;
  };
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
}

class WoltMerchantAreaMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
    };
  }

  componentDidMount(): void {
    console.log(this.props);
  }

  render() {
    const { woltMerchantOfferArea } = this.props;
    return (
      <div className={"request-map-container"}>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          defaultCenter={woltMerchantOfferArea}
          defaultZoom={13}
          center={woltMerchantOfferArea}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) =>
            this.setState({
              map: map,
              mapApi: maps,
              mapLoaded: true,
            })
          }
        >
          {this.state.mapApi && (
            <WktMultiPolygon
              map={this.state.map}
              mapApi={this.state.mapApi}
              wktText={woltMerchantOfferArea.within_area_wkt}
            />
          )}
        </GoogleMap>
      </div>
    );
  }
}

export default observer(WoltMerchantAreaMap);
