import { getPreciseDistance } from "geolib";
import _ from "lodash";

type TimedLocation = {
  // TimedLocationの`lat`とMinimumLocationの`lat`の方が違うのは、
  // 単純にあるコンポーネントないで利用されていたコードを共通利用できるようにした際の影響。
  // こちらをいじって既存コードに手を入れるより、
  // 新規コードを既存インターフェースに合わせる方が簡単だし安全だと判断。
  lat: string;
  lng: string;
  sent_at: string | null;
};

interface MinimumLocation {
  lat: number;
  lng: number;
}

interface Item {
  distance: number;
  duration: number;
}

export function calcWeights(locations: TimedLocation[]) {
  return calcRawWeights(locations).map((weight) => stepWeight(weight));
}

/**
 * 指定されたlocationの配列から、その各要素における速度比率を計算して返す関数.
 *
 * locationsは時間降順で並んでいることに注意。
 *
 * locations = l_0, l_1, l_2, ... ,l_m とあり、
 * sent_atが設定されているのが、l_i, l_j, l_k (0 < i < j < k < m)のみの場合、
 * 各地点での速度はそれぞれ
 * ```
 * v_l0 = 距離(l_i, l_j) / 時間(l_i, l_j)
 * ...
 * v_li = 距離(l_i, l_j) / 時間(l_i, l_j)
 * ...
 * v_lj = 距離(l_i, l_k) / 時間(l_i, l_k)
 * ...
 * v_lk = 距離(l_j, l_k) / 時間(l_j, l_k)
 * ...
 * v_lm = 距離(l_j, l_k) / 時間(l_j, l_k)
 * ```
 * とし、平均速度は
 * ```
 * v_ave = 距離(l_i, l_k) / 時間(l_i, l_k)
 * ```
 * とする。
 *
 * @param locations
 * @returns
 */
export function calcRawWeights(locations: TimedLocation[]): number[] {
  try {
    const firstLocIdx = locations.findIndex((loc) => loc.sent_at != null);
    const lastLocIdx = findLastIndex(locations, (loc) => loc.sent_at != null);
    if (firstLocIdx < 0 || firstLocIdx == lastLocIdx) {
      // sent_atを含む位置情報が存在しない、あるいは存在しても1つしかない場合、
      // 全ての重みを1として返す(計算のしようがないので)
      return locations.map((loc) => 1);
    }

    // 送信時刻から平均速度を算出する上で利用可能な最大距離
    // (sent_atが存在する最初と最後の地点の間の距離)
    const calculableDistance = calcDistance(
      locations
        .slice(firstLocIdx, lastLocIdx + 1)
        .map((loc) => ({ lat: +loc.lat, lng: +loc.lng, sent_at: null }))
    );

    // 時間的には最後
    const firstLoc = locations[firstLocIdx];
    // 時間的には最初
    const lastLoc = locations[lastLocIdx];
    const calculableTotalSeconds = Math.ceil(
      (new Date(firstLoc.sent_at!).getTime() -
        new Date(lastLoc.sent_at!).getTime()) /
        1000
    );
    const totalAveSpeed =
      Math.ceil((calculableDistance * 100) / calculableTotalSeconds) / 100;

    const sentAtIndices = locations
      .map((loc, index) => {
        if (loc.sent_at == null) return undefined;
        return index;
      })
      .filter((locIdx): locIdx is NonNullable<typeof locIdx> => locIdx != null);

    // itemsの要素数はsentAtIndicesより1つ少ない
    let items: Item[] = [];
    for (let i = 0; i < sentAtIndices.length; i++) {
      if (i == sentAtIndices.length - 1) continue;

      const idx = sentAtIndices[i];
      const nextIdx = sentAtIndices[i + 1];

      const distance = calcDistance(
        locations.slice(idx, nextIdx + 1).map((loc) => ({
          lat: +loc.lat,
          lng: +loc.lng,
          sent_at: null,
        }))
      );
      const duration = Math.ceil(
        (new Date(locations[idx].sent_at!).getTime() -
          new Date(locations[nextIdx].sent_at!).getTime()) /
          1000
      );

      items.push({
        distance,
        duration,
      });
    }

    let itemsIdx = -1;
    // 重みは小数点一桁
    let weights: number[] = [];
    for (let i = 0; i < locations.length; i++) {
      let item: Item;
      const location = locations[i];
      if (location.sent_at == null) {
        if (itemsIdx < 0) {
          item = items[0];
        } else if (itemsIdx == items.length) {
          item = items[itemsIdx - 1];
        } else {
          item = items[itemsIdx];
        }
      } else {
        itemsIdx += 1;
        if (itemsIdx == 0) {
          item = items[0];
        } else if (itemsIdx == items.length) {
          // itemsの要素数はsentAtIndicesより1つ少ないので、itemsIdx=items.lengthとなる
          item = items[items.length - 1];
        } else {
          const prev = items[itemsIdx - 1];
          const current = items[itemsIdx];
          item = {
            distance: prev.distance + current.distance,
            duration: prev.duration + current.duration,
          };
        }
      }

      // item.duration > 0 は全く同じ点が連続する可能性があり、その対応。
      // 時間が0秒であればその距離に関わらず平均速度を0とする。
      const aveSpeed =
        item.duration > 0
          ? Math.ceil((item.distance * 100) / item.duration) / 100
          : 0;
      // 平均速度が0の場合、重みも0
      const weight =
        aveSpeed > 0 ? Math.ceil((totalAveSpeed * 10) / aveSpeed) / 10 : 0;
      weights.push(weight);
    }

    return weights;
  } catch (error) {
    // 一応確認しているので大丈夫なはずだけど、エラーになった場合には全て1とする
    console.error(error);
    return locations.map((loc) => 1);
  }
}

/**
 * 指定されたlocationの配列から、その各間隔の直線距離を合計して返す関数.
 *
 * @param locations
 * @returns
 */
function calcDistance(locations: MinimumLocation[]) {
  const distance = locations.reduce((accum, current, idx, list) => {
    if (idx == 0) return accum;

    const beforeLoc = list[idx - 1];
    const distance = getPreciseDistance(beforeLoc, current);
    return accum + distance;
  }, 0);

  return distance;
}

/**
 * 配列の末尾から、指定される条件を満たす最初の要素のindexを返す関数.
 *
 * ES2023にはfindLastIndexがあるのだけど、現状使えそうにないので諦めの自作。
 * 効率とかは知らない。
 * @param items
 * @param func
 * @returns
 */
function findLastIndex<T>(items: T[], func: (item: T) => boolean) {
  for (let i = items.length - 1; i >= 0; i--) {
    if (func(items[i])) return i;
  }

  return -1;
}

/**
 * 実数値(小数第一位)で算出される速度比率を、HeatMapに渡す値用の重みに変換するための関数.
 * @param weight
 * @returns
 */
function stepWeight(weight: number) {
  if (weight <= 1) return 1;
  return Math.min(weight, 10);
}
