import {
  DndContext,
  DragStartEvent,
  DragEndEvent,
  DragOverlay,
} from "@dnd-kit/core";
import { observer } from "mobx-react";
import React from "react";
import {
  UNASSIGNED_REQUESTS_DROPPABLE_ID,
  CARRY_STAFF_DROPPABLE_TYPE,
} from "../../consts";
import { RawTerritory, RawVehicleType } from "../../interfaces";
import { pageStore } from "../../stores";
import CarryStaffDroppable from "./CarryStaffDroppable";
import CarryStaffsSearchComponent from "./CarryStaffsSearchComponent";
import CasPagination from "./CasPagination";
import DraggableRequestCard from "./DraggableRequestCard";
import UnassignedRequestsDroppable from "./UnassignedRequestsDroppable";
import UnassignedRequestsSearchComponent from "./UnassignedRequestsSearchComponent";

interface Props {
  territories: RawTerritory[];
  vehicleTypes: RawVehicleType[];
  canUpdate: boolean;
  onClickAssignButton: () => void;
}

class OperationalComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  private handleDragStart(event: DragStartEvent) {
    const draggingRequest = pageStore.requests.find(
      (req) => req.id == event.active.data.current?.requestId
    );
    if (draggingRequest) {
      // ドラッグ開始時、選択済みの依頼があればそれは解除する
      pageStore.selectRequest(null);
      pageStore.setDraggingRequest(draggingRequest);
    }
  }

  private handleDragEnd(event: DragEndEvent) {
    const draggingRequest = pageStore.draggingRequest;
    if (!draggingRequest) {
      return;
    }

    const _request = pageStore.requests.find(
      (req) => req.id == draggingRequest.id
    );
    if (!_request) {
      pageStore.setDraggingRequest(null);
      return;
    }

    if (event.over?.id == UNASSIGNED_REQUESTS_DROPPABLE_ID) {
      _request.carry_staff_id = null;
    } else if (event.over?.data.current?.type == CARRY_STAFF_DROPPABLE_TYPE) {
      const _carryStaff = pageStore.carryStaffs.find(
        (cas) => cas.id == event.over?.data.current?.carryStaffId
      );
      if (_carryStaff) {
        _request.carry_staff_id = _carryStaff.id;
      }
    }

    pageStore.setDraggingRequest(null);
    pageStore.setRequests([...pageStore.requests]);
  }

  render() {
    return (
      <DndContext
        onDragEnd={this.handleDragEnd}
        onDragStart={this.handleDragStart}
      >
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center my-2">
            <i className="fas fa-fw fa-file-alt" />
            <span className="mx-1">未アサイン依頼一覧</span>
            {pageStore.reqLoading && (
              <div
                className="spinner-border spinner-border-sm ml-3"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
          <UnassignedRequestsSearchComponent />
          <UnassignedRequestsDroppable />
        </div>

        <div className="d-flex flex-column overflow-auto">
          <div className="d-flex">
            <div className="col-12 d-flex align-items-center my-2">
              <i className="fas fa-fw fa-biking" />
              <span className="mx-1">ルート配達スタッフ一覧</span>
            </div>
          </div>

          <div className="d-flex mb-2">
            <div className="d-flex justify-content-between">
              <div className="flex-grow">
                <CarryStaffsSearchComponent
                  territories={this.props.territories}
                />
              </div>
            </div>
            {this.props.canUpdate && (
              <div className="d-flex justify-content-end align-items-end ml-auto">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.onClickAssignButton();
                  }}
                >
                  アサイン変更
                </button>
              </div>
            )}
          </div>

          <div className="d-flex flex-wrap">
            {pageStore.carryStaffs.map((cas) => {
              return (
                <div key={cas.id} className="col-12 my-1">
                  <CarryStaffDroppable
                    carryStaff={cas}
                    draggingRequest={pageStore.draggingRequest}
                    requests={pageStore.requests}
                    territories={this.props.territories}
                    vehicleTypes={this.props.vehicleTypes}
                    isSelected={cas.id == pageStore.selectedCasId}
                    onSelect={() => {
                      pageStore.selectCarryStaff(
                        pageStore.selectedCasId == cas.id ? null : cas.id
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
          <CasPagination />
        </div>
        {/* ドラッグ中のアイテムが最前面に表示されるように必要 */}
        <DragOverlay>
          {pageStore.draggingRequest ? (
            <DraggableRequestCard
              isMini={false}
              request={pageStore.draggingRequest}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    );
  }
}

export default observer(OperationalComponent);
