import React from "react";
import { MapMarkerPin } from "./MapMarkerPin";
import Colors from "../constants/BootstrapColors";
import { MapInfoWindow } from "./MapInfoWindow";
import { VendorCompanyCard } from "./MapInfoWindow/VendorCompanyCard";

interface Props {
  lat: number;
  lng: number;
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;
  name: string;
  address: string;
}

interface State {
  showInfoWindow: boolean;
}

export default class ReceiverSmallMarker extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false,
    };
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow;
    const pinSize = 24;
    const { name, address } = this.props;
    return (
      <div>
        <MapMarkerPin
          svgIcon="shop"
          pinShape="circle"
          size={showInfoWindow ? pinSize * 1.5 : pinSize}
          pinBackgroundColor="#fff"
          svgIconColor={Colors.PRIMARY_COLOR}
          onClick={this.onClickMarker.bind(this)}
        />
        {showInfoWindow && (
          <MapInfoWindow>
            <VendorCompanyCard
              name={name}
              address={address}
              onCloseClick={() => {
                this.setState({
                  showInfoWindow: false,
                });
              }}
            />
          </MapInfoWindow>
        )}
      </div>
    );
  }

  private onClickMarker(event: Event) {
    this.setState({ showInfoWindow: !this.state.showInfoWindow });
    event.preventDefault();
    event.stopPropagation();
  }
}
