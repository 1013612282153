import _ from "lodash";
import { action, observable } from "mobx";
import RequestsResponse from "../interfaces/RequestsResponse";
import { RequestModel } from "../models/RequestModel";
import { axiosGet } from "../utils/AxiosClient";

export class RequestStore {
  @observable
  request: RequestModel;

  @observable
  latestLoadedAt: Date;

  intervalTimer: number | null = null;

  @action
  public subscribeRequest(requestId: number) {
    this.loadRequest(requestId);

    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.intervalTimer = setInterval(
      this.loadRequest.bind(this, requestId),
      60000
    );
  }

  @action
  private async loadRequest(requestId: number) {
    const response = await axiosGet.get<RequestsResponse>(
      `/api/requests/${requestId}`
    );
    this.replaceRequest(new RequestModel(response.data));
    this.latestLoadedAt = new Date();
  }

  @action
  private replaceRequest(request: RequestModel) {
    this.request = request;
  }
}

const singleton = new RequestStore();
export default singleton;
