import React from "react";

interface Props {
  selectType: string;
  onChangeSelectType: (name: string) => void;
}

export default function MAPToolboxContentForDestLatLng(props: Props) {
  return (
    <div>
      <div>
        <div>
          <div>
            <div>行き先の場所を指定</div>
          </div>
        </div>
      </div>
    </div>
  );
}
