import { Coords } from "google-map-react";
import { observable } from "mobx";
import { thirdPartyDeliveryTaskConst } from "../constants/ThirdPartyDeliveryTask";
import ThirdPartyDeliveryTaskResponse from "../interfaces/ThirdPartyDeliveryTaskResponse";
import ThirdPartyDeliveryTask from "../interfaces/ThirdPartyDeliveryTask";
import { convertNumToShortCode } from "../utils/NumberUtils";
import { CarryStaffModel } from "./CarryStaffModel";

export class ThirdPartyDeliveryTaskModel implements ThirdPartyDeliveryTask {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  shortCode: string;
  statusText: string;
  status: number;
  sender: Coords;
  receiver: Coords;
  carryStaff: CarryStaffModel;
  carryStaffId: number | null;
  deliveryTimeAt: Date | null;
  readyTimeAt: Date | null;
  deliveryType: string | null;
  workType: keyof typeof thirdPartyDeliveryTaskConst.WORK_TYPES;
  isReserved: boolean | null;
  visible: boolean | null;
  // RequestModelと合わせるため、存在しなけど設定
  routes: null;

  constructor(response: ThirdPartyDeliveryTaskResponse) {
    this.id = response.id;
    this.shortCode = convertNumToShortCode(response.id);
    this.key = response.id;
    this.sender = {
      lat: +response.sender.lat,
      lng: +response.sender.lng,
    };
    this.receiver = {
      lat: +response.receiver.lat,
      lng: +response.receiver.lng,
    };
    this.status = response.status;
    this.statusText = response.status_text;

    this.deliveryTimeAt = null;
    if (response.delivery_time_at) {
      this.deliveryTimeAt = new Date(response.delivery_time_at);
      this.readyTimeAt = new Date(
        this.deliveryTimeAt.valueOf() - 15 * 60 * 1000
      ); // minus 15 minutes
    }

    if (response.carry_staff) {
      this.carryStaff = new CarryStaffModel(response.carry_staff);
      this.carryStaffId = response.carry_staff.id;
    }

    this.workType = response.work_type;
    this.visible = true;
    this.routes = null;
  }

  public isEarlyStage() {
    // 未確認、または、確認済み
    return this.status < 2;
  }

  public isDeliveryStage() {
    // ピックアップ完了以降
    return this.status === 2;
  }

  public isDoneStage() {
    // ピックアップ完了以降
    return this.status > 2;
  }
}
