import _ from 'lodash'
import { observer } from "mobx-react";
import React from "react";
import serviceAreasStore from '../../../stores/ServiceAreasStore';


interface Props {
}

interface State {
  searchCondition: string;
}

class ServiceAreaList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchCondition: ""
    }
  }

  private renderServiceAreaList() {
    return _.map(
      serviceAreasStore.serviceAreas,
      (serviceArea) => {

        if (!this.matchSearchCondition(serviceArea.name)) {
          return null;
        }

        const isVisible = serviceAreasStore.isVisible(serviceArea);
        return (
          <div className={"form-check mb-2"} key={serviceArea.id}>
            <input
              type="checkbox"
              id={`servicearea-${serviceArea.id}`}
              className="form-check-input"
              checked={isVisible}
              onChange={() => serviceAreasStore.toggleVisible(serviceArea)}
            />
            <label className={"form-check-label"} htmlFor={`servicearea-${serviceArea.id}`}>
              {serviceArea.name}
            </label>
            <a href={`/service_areas/${serviceArea.id}`}>
              詳細
            </a>
          </div>
        )
      }
    )
  }

  private renderSearchBox() {
    return (
      <React.Fragment>
        <div className="form-group form-inline mb-2">
          <button
            className="btn btn-primary ml-2"
            onClick={() => { serviceAreasStore.showAllServiceAreas() }}
          >
            <i className={"fas fa-eye"} />
          </button>
          <button
            className="btn btn-primary ml-2"
            onClick={() => { serviceAreasStore.hideAllServiceAreas() }}
          >
            <i className={"fas fa-eye-slash"} />
          </button>
        </div>
        <div className="form-group mb-2">
          <input
            type="text"
            className="form-control"
            placeholder={"スペースで複数指定"}
            value={this.state.searchCondition}
            onChange={(event) => {
              this.setState({
                searchCondition: event.target.value
              })
            }}
          />
        </div>
      </React.Fragment>
    )
  }

  private matchSearchCondition(name: string) {
    const searchConditions = this.state.searchCondition.trim().split(/[　 ]/);
    if (searchConditions.length == 0) {
      return true;
    }

    return _.some(searchConditions, (cond) => {
      return name.indexOf(cond) >= 0;
    })
  }

  render() {
    if (serviceAreasStore.serviceAreas.length == 0) {
      return (
        <div>
          {this.renderSearchBox()}
          有効なサービスエリアがありませんでした。
        </div>
      )
    }

    return (
      <div>
        {this.renderSearchBox()}
        {this.renderServiceAreaList()}
      </div>
    );
  }
}

export default observer(ServiceAreaList);
