import React, {useEffect, useRef} from "react";
import type {MapApi} from "../index";

interface Props {
    mapApi: MapApi,
    children: React.ReactNode,
    left: number
}

export function Indicator(props: Props) {
    const {mapApi, children, left} = props
    const indicatorRef = useRef(null)
    useEffect(() => {
        if (indicatorRef.current) {
            mapApi.map.controls[mapApi.maps.ControlPosition.LEFT_BOTTOM].push(indicatorRef.current);
        }
        return () => {
            mapApi.map.controls[mapApi.maps.ControlPosition.LEFT_BOTTOM].clear()
        }
    }, [])
    return (
        <>
            <div style={{visibility: "hidden"}}>
                <div ref={indicatorRef} style={{
                    background: "#ffffff",
                    width: "100%",
                    height: "5em",
                    padding: 8,
                    paddingBottom: 54,
                    marginBottom: "-30px",
                    marginLeft: left,
                    transition: "0.2s margin-left",
                    borderTop: "1px solid #ccc"
                }}>
                    {
                        children
                    }
                </div>
            </div>
        </>
    )
}