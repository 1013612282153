import React, { useEffect} from "react";
import requestAlertStore from '../stores/RequestAlertStore';
import { observer } from "mobx-react";

interface Props {
  src: string,
}

interface State {}

@observer
class RequestAlertAudio extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    requestAlertStore.getAlertNum();
  }

  componentDidMount() {
    this.playOrStopAudio();
  }
  componentDidUpdate() {
    this.playOrStopAudio();
  }

  render() {
    return (
      <audio id="requst_alert_audio" src={this.props.src} preload="auto" loop autoPlay={requestAlertStore.hasSoundAlert} />
    )
  }

  private playOrStopAudio() {
    const audio = document.querySelector("#requst_alert_audio") as any;
    // 使い方が確定するまでサウンド再生は止める
    // if (requestAlertStore.hasSoundAlert) {
    //  audio.play();
    // } else {
    //  audio.pause();
    //  audio.currentTime = 0;
    // }
  }
}

export default RequestAlertAudio;
