import React, {useMemo} from "react";
import {h3ToGeoBoundary} from "h3-js";

import type {PolygonProps} from "./Polygon";
import {Polygon} from "./Polygon";

interface Props extends PolygonProps {
    hexIndex: string,
}

export function PolygonByH3Index(props: Props) {
    const {hexIndex} = props
    const paths = useMemo(() => {


        const geoBoundary = h3ToGeoBoundary(hexIndex, true)

        return geoBoundary.map(geo => {
            return {
                lat: geo[1],
                lng: geo[0],
            }
        })
    }, [hexIndex])
    return (
        <Polygon {...props} paths={[paths]}/>
    )
}
