import { MapApi } from "../index";
import React, {
  useContext,
  useEffect,
  useRef
} from "react";
import { GmapsUIContext } from "../index"

const DEFAULT_WIDTH = 280
export interface DrawerOptions {
  width?: number
  defaultOpen?: boolean
}
interface Props {
  mapApi: MapApi,
  children: React.ReactNode | React.ReactNode[],
  width?: number,
  title?: string
  drawerOption?: DrawerOptions
}
export function Drawer(props: Props) {
  const { mapApi, children, drawerOption, title } = props
  const drawerRef = useRef(null)

  const { isOpenDrawer, setIsOpenDrawer } = useContext(GmapsUIContext)

  useEffect(() => {
    if (drawerRef.current) {
      mapApi.map.controls[mapApi.maps.ControlPosition.LEFT_CENTER].push(drawerRef.current);
    }
  }, [])

  const handleClickOpen = () => {
    setIsOpenDrawer(true)
  }
  const handleClickClose = () => {
    setIsOpenDrawer(false)
  }
  return (
    <div style={{ visibility: "hidden" }}>
      <div
        ref={drawerRef}
        style={{
          background: "#ffffff",
          width: drawerOption?.width || DEFAULT_WIDTH,
          height: "100%",
          boxShadow: "1px -1px 15px rgba(0, 0, 0, 0.2)",
          position: "relative",
          transform: isOpenDrawer ? `translateX(-${0}px)` : `translateX(-${(drawerOption?.width || DEFAULT_WIDTH) - 24}px)`,
          transition: "0.2s transform",
          zIndex: 2
        }}
      >
        <div>
          <button
            type="button"
            onClick={handleClickOpen}
            style={{
              display: !isOpenDrawer ? "flex" : "none",
              alignItems: "center",
              position: "absolute",
              top: 0,
              height: "100%",
              background: "#f9f9f9",
              right: 0,
              border: "none",
            }}
          >
            <div style={{
              display: !isOpenDrawer ? "block" : "none",
              marginRight: 6,
              marginLeft: 2,
              width: 16,
              height: 16,
              borderTop: "4px solid #999",
              borderRight: "4px solid #999",
              transform: "rotate(45deg)"
            }}>
            </div>
          </button>

          <header
            style={{
              borderBottom: "1px solid #CCC",
              padding: 8,
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <h3>{title}</h3>
            <button
              type="button"
              onClick={handleClickClose}
              style={{
                display: isOpenDrawer ? "block" : "none",
                border: "none",
                backgroundColor: "transparent"
              }}
            >
              <div style={{
                marginRight: 6,
                width: 12,
                height: 12,
                borderTop: "3px solid #999",
                borderRight: "3px solid #999",
                transform: "rotate(-135deg)"
              }}>
              </div>
            </button>
          </header>
        </div>
        <div style={{ overflowY: "scroll", height: "100%" }}>
          {children}
        </div>
      </div>
    </div>
  )
}
