/**
 * 数値を短縮コードに変換するための関数で使用する定数
 */
const ALPHABET_COUNT = 26; // AtoZ
const MAX_NUMBER_ONE_DIGIT = 9; // 1~9
const MAX_NUMBER_TWO_DIGITS = 99; // 1~99
// A1~ZZ9の総数(=8658)
const SUBSTITUABLE_MAX_NUM =
  ALPHABET_COUNT * MAX_NUMBER_TWO_DIGITS +
  ALPHABET_COUNT ** 2 * MAX_NUMBER_ONE_DIGIT;

/**
 * 与えられた数値をA1~Z99, AA1~ZZ9の短縮コードに置換可能な数値に変換する関数
 * @param num
 * @returns
 */
function replaceNumWithSubstitutable(num: number): number {
  return num <= SUBSTITUABLE_MAX_NUM
    ? num
    : ((num - 1) % SUBSTITUABLE_MAX_NUM) + 1;
}

/**
 * 与えられた数値をA1~Z99, AA1~ZZ9の短縮コードに変換する関数
 * 1=A1, 2=A2, 10=B1, 2574=Z99, 2575=AA1, 8658=ZZ9
 * @param num
 * @returns
 */
export function convertNumToShortCode(num: number): string {
  const substitutedNum = replaceNumWithSubstitutable(num);

  if (substitutedNum < 1 || substitutedNum > SUBSTITUABLE_MAX_NUM) return "-";

  // 与えられた数値からASCIIコードで値を取得する
  // '65'は'A'のASCIIコード
  if (substitutedNum <= ALPHABET_COUNT * MAX_NUMBER_TWO_DIGITS) {
    /* A1~Z99の場合 */
    const firstAlphabetCode =
      Math.floor((substitutedNum - 1) / MAX_NUMBER_TWO_DIGITS) + 65;
    const firstAlphabet = String.fromCharCode(firstAlphabetCode);
    const secondNumber = ((substitutedNum - 1) % MAX_NUMBER_TWO_DIGITS) + 1;

    return `${firstAlphabet}${secondNumber}`;
  } else {
    /* AA1~ZZ9の場合 */
    // 2575(=ALPHABET_DIGITS*MAX_NUMBER_TWO_DIGITS+1)を1として計算
    const resetNumber = substitutedNum - ALPHABET_COUNT * MAX_NUMBER_TWO_DIGITS;
    const firstAlphabetCode =
      (Math.floor((resetNumber - 1) / (ALPHABET_COUNT * MAX_NUMBER_ONE_DIGIT)) %
        ALPHABET_COUNT) +
      65;
    const secondAlphabetCode =
      (Math.floor((resetNumber - 1) / MAX_NUMBER_ONE_DIGIT) % ALPHABET_COUNT) +
      65;
    const firstAlphabet = String.fromCharCode(firstAlphabetCode);
    const secondAlphabet = String.fromCharCode(secondAlphabetCode);
    const thirdNumber = ((resetNumber - 1) % MAX_NUMBER_ONE_DIGIT) + 1;

    return `${firstAlphabet}${secondAlphabet}${thirdNumber}`;
  }
}
