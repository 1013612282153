import { Bounds, Coords } from "google-map-react";
import { action, observable } from "mobx";
import { MasterTagRelationResponse } from "../interfaces/MasterTagRelationsResponse";
import RequestsResponse from "../interfaces/RequestsResponse";
import { MasterTagRelationModel } from "../models/MasterTagRelationModel";
import { RequestModel } from "../models/RequestModel";

interface LoadInfo {
  loading: boolean;
  latestLoadedAt: Date | undefined;
}

export class RequestsStore {
  items = observable<RequestModel>([]);
  masterTagRelations = observable<MasterTagRelationModel>([]);

  @observable
  selectedItemId: number | undefined;

  @observable
  infoWindowVisibleList: number[] = [];

  @observable
  timeRange = 0;

  @observable.ref
  loadingInfo: LoadInfo = { loading: false, latestLoadedAt: undefined };

  // 依頼一覧ペインで、ある依頼をクリックしてその依頼を中心に持ってきた後、
  // 地図を移動させてその依頼が中心から外れた後に、
  // 再度同じ依頼をクリックした時にその依頼を中心に持ってきたく、
  // そのためだけのobservable用変数。
  // 上記の場合、selectedItemIdは変化しないことになるので、別の変数が必要になった。
  @observable
  clickSameItemEvent = 0;

  intervalTimer: number | undefined;
  abortController: AbortController | undefined;

  @action
  public subscribeIn(
    center: Coords,
    bounds: Bounds | null,
    onlyUnassigned: boolean,
    callbackAfterFirstLoaded?: () => void
  ) {
    if (bounds == null) return;

    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.loadIn(center, bounds, onlyUnassigned, callbackAfterFirstLoaded);
    this.intervalTimer = setInterval(
      this.loadIn.bind(this, center, bounds, onlyUnassigned),
      30000
    );
  }

  @action
  public subscribeWith(
    carryStaffIds: number[] | "unassigned",
    callbackAfterFirstLoaded?: () => void
  ) {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.loadWith(carryStaffIds, callbackAfterFirstLoaded);
    this.intervalTimer = setInterval(
      this.loadWith.bind(this, carryStaffIds),
      30000
    );
  }

  public unsubscribe() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = undefined;
    }
  }

  @action
  public loadWith(
    carryStaffIds: number[] | "unassigned",
    callbackAfterLoaded?: () => void
  ) {
    const _params: [string, string][] = [
      ["carry_staff_ids", `${carryStaffIds}`],
    ];
    if (this.timeRange) {
      _params.push(["limit_time_value", `${this.timeRange}`]);
      _params.push(["limit_time_unit", "MINUTE"]);
    }
    this.load(
      "/api/requests/index_with",
      new URLSearchParams(_params),
      callbackAfterLoaded
    );
  }

  @action
  private loadIn(
    center: Coords,
    bounds: Bounds,
    only_unassigned: boolean,
    callbackAfterLoaded?: () => void
  ) {
    const _params: string[][] = [
      ["lat", `${center.lat}`],
      ["lng", `${center.lng}`],
      ["neLng", `${bounds.ne.lng}`],
      ["neLat", `${bounds.ne.lat}`],
      ["seLng", `${bounds.se.lng}`],
      ["seLat", `${bounds.se.lat}`],
      ["swLng", `${bounds.sw.lng}`],
      ["swLat", `${bounds.sw.lat}`],
      ["nwLng", `${bounds.nw.lng}`],
      ["nwLat", `${bounds.nw.lat}`],
    ];
    if (only_unassigned) {
      _params.push(["only_unassigned", "1"]);
    }
    if (this.timeRange) {
      _params.push(["limit_time_value", `${this.timeRange}`]);
      _params.push(["limit_time_unit", "MINUTE"]);
    }

    this.load(
      "/api/requests/index_in",
      new URLSearchParams(_params),
      callbackAfterLoaded
    );
  }

  @action
  private load(
    path: string,
    params: URLSearchParams,
    callbackAfterLoaded?: () => void
  ) {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    this.setLoadingInfo(true);
    fetch(`${path}?` + params.toString(), {
      signal: this.abortController.signal,
    })
      .then((response) => response.json())
      .catch((err) => {
        if (err.code != DOMException.ABORT_ERR) {
          // Abort Errorの場合には、別のローディングが入っていると言うことのはずなので、
          // loadingフラグはそのままにする(Abort Error以外のエラーの場合にだけfalseにする)
          this.setLoadingInfo(false);
          throw err;
        }
      })
      .then(
        (
          result:
            | {
                requests: RequestsResponse[];
                master_tag_relations: MasterTagRelationResponse[];
              }
            | undefined
        ) => {
          if (!result) return;

          const items = this.convertToModel(result.requests);
          this.replaceItems(items);
          this.replaceMasterTagRelations(
            result.master_tag_relations.map(
              (rel) => new MasterTagRelationModel(rel)
            )
          );
          this.setLoadingInfo(false, new Date());
          callbackAfterLoaded && callbackAfterLoaded();
        }
      );
  }

  @action
  public setSelectedItemId(selectedItemId: number | undefined) {
    this.selectedItemId = selectedItemId;
  }

  @action
  private replaceItems(items: RequestModel[]) {
    this.items.replace(items);
  }

  @action
  private replaceMasterTagRelations(
    masterTagRelations: MasterTagRelationModel[]
  ) {
    this.masterTagRelations.replace(masterTagRelations);
  }

  @action
  private setLoadingInfo(loading: boolean, loadedAt?: Date) {
    this.loadingInfo = {
      loading,
      latestLoadedAt: loadedAt ?? this.loadingInfo.latestLoadedAt,
    };
  }

  @action
  public setTimeRange(minutes: number) {
    this.timeRange = minutes;
  }

  @action
  public clickSameItem() {
    this.clickSameItemEvent += 1;
  }

  @action
  public updateInfoWindowVisible(itemId: number, visible: boolean) {
    const targetItem = this.items.find((item) => item.id === itemId);
    if (targetItem) {
      if (visible) {
        this.infoWindowVisibleList.push(targetItem.id);
      } else {
        const index = this.infoWindowVisibleList.indexOf(targetItem.id);
        if (index >= 0) {
          this.infoWindowVisibleList.splice(index, 1);
        }
      }
    }
  }

  @action
  public resetInfoWindowVisible() {
    this.infoWindowVisibleList = [];
  }

  public getItemById(itemId: number): RequestModel | undefined {
    return this.items.find((item) => item.id === itemId);
  }

  public getSelectedItem() {
    // これがなくても結果は変わらないけど、無駄な処理を省くため
    if (this.selectedItemId == null) return undefined;

    return this.items.find((item) => item.id == this.selectedItemId);
  }

  private convertToModel(responseItems: RequestsResponse[]) {
    const items = responseItems.map((item) => new RequestModel(item));
    return items;
  }
}

const singleton = new RequestsStore();
export default singleton;
