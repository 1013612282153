import { action, observable, set } from "mobx";
import VendorCompanyResponse from "../interfaces/VendorCompanyResponse";

export class VendorCompanyModel{
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  name: string;
  
  zipcode: string;
  
  state: string;
  
  city: string;
  
  address: string;
  
  lat: number;
  
  lng: number;
  
  constructor(response: VendorCompanyResponse) {
    this.id = response.id;
    this.key = response.id;
    
    this.name = response.name;
    this.zipcode = response.zipcode;
    this.state = response.state;
    this.city = response.city;
    this.address = response.address;
    
    this.lat = Number(response.lat);
    this.lng = Number(response.lng);
  }
}
