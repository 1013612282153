import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import {addMinutes, format} from 'date-fns'

const HOUR_MINUTES = 60
const DAY_MINUTES = HOUR_MINUTES * 24 // 1440

interface SliderProps {
    onChange?: (val: Date) => void | Promise<void>
    step?: number
    min?: number
    max?: number
    style?: React.CSSProperties
    disabled?: boolean
}

export default function TimelineSlider(props: SliderProps) {

    const {
        onChange = () => {
        },
        step = 15,
        min = 0,
        max = DAY_MINUTES - step,
        style,
        disabled = false
    } = props

    const dayStart = useMemo(() => {
        const now = new Date()
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    }, [])

    const defaultValue = useCallback(() => {
        const nowStepTime = new Date()
        nowStepTime.setSeconds(0)
        nowStepTime.setMinutes(Math.round(nowStepTime.getMinutes() / step) * step)
        const diff = nowStepTime.getTime() - dayStart.getTime()
        return Math.round(diff / (60 * 1000))
    }, [])


    const [value, setValue] = useState<number>(defaultValue());
    const defaultDateValue = useCallback(() => {
        return addMinutes(dayStart, value)
    }, [value, dayStart])

    const [dateValue, setDateValue] = useState<Date>(defaultDateValue());

    const tooltipLabel = (v: number) => {
        const addedDay = addMinutes(dayStart, v)
        return format(addedDay, "HH:mm")
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value)
        setValue(value)
    }

    const handeMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
        const dateValue = addMinutes(dayStart, value)
        setDateValue(dateValue)
        onChange(dateValue)
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
            const dateValue = addMinutes(dayStart, value)
            setDateValue(dateValue)
            onChange(dateValue)
        }
    }

    const handleClickSub = () => {
        setValue(value - step)
        const dateValue = addMinutes(dayStart, value - step)
        setDateValue(dateValue)
        onChange(dateValue)
    }

    const handleClickAdd = () => {
        setValue(value + step)
        const dateValue = addMinutes(dayStart, value + step)
        setDateValue(dateValue)
        onChange(dateValue)
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            gap: 4
        }}>
      <span>
        {dateValue ? format(dateValue, "HH:mm") : ""}
      </span>
            <span style={{flex: 1}}>
               <RangeSlider
                   value={value}
                   step={step}
                   min={min}
                   max={max}
                   onChange={handleChange}
                   onMouseUp={handeMouseUp}
                   onKeyUp={handleKeyUp}
                   tooltipLabel={tooltipLabel}
                   style={{...style, width: "100%"}}
                   disabled={disabled}
               />
            </span>

            <button className={"btn btn-sm"} onClick={handleClickSub}>-</button>
            <button className={"btn btn-sm"} onClick={handleClickAdd}>+</button>
        </div>
    );
}
