import { observable } from "mobx";


export class CarryStaffModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  name: string;
  lat: number;
  lng: number;

  constructor(carryStaffResponse: {
    id: number;
    name: string;
    lat: string;
    lng: string;
  }) {
    this.id = carryStaffResponse.id;
    this.key = carryStaffResponse.id;
    this.name = carryStaffResponse.name;
    this.lat = +carryStaffResponse.lat;
    this.lng = +carryStaffResponse.lng;
  }
}
