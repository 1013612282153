import React, { CSSProperties, FC, useEffect, useState } from "react";
import { pageStore, searchConditionsStore } from "../stores";
import Modal from "react-modal";
import { observer } from "mobx-react";
import { ExtendedRawRequest } from "../../../utils/routes-apis/common-utils";
import { convertNumToShortCode } from "../../../utils/NumberUtils";
import Colors from "../../../constants/BootstrapColors";
import { getRangeDeliveryTimeSlotTimeLabel } from "../../../utils/RequestEntityUtils";
import { reassignRequestToAnotherCarryStaff } from "../utils";
import { toast } from "react-toastify";
import { RawCarryStaff } from "../interfaces";
import { RelatedCarryStaffItem } from "../components/RelatedCarryStaffItem";

interface Props {
  isModalOpen: boolean;
  onAfterModalOpen?: () => void;
  onRequestClose: () => void;
  onAfterAssignSucceed: () => void;
  requests: ExtendedRawRequest[];
  carryStaff: RawCarryStaff;
}

const AssignOtherStaffModal: FC<Props> = (props) => {
  const {
    isModalOpen,
    onAfterModalOpen,
    onRequestClose,
    requests,
    carryStaff,
    onAfterAssignSucceed,
  } = props;
  const [selectedCarryStaffId, setSelectedCarryStaffId] = useState<number>();

  useEffect(() => {
    (async () => {
      if (requests.length < 1) {
        return;
      }
      await pageStore.loadAssignableStaffs(
        searchConditionsStore.targetTerm.date,
        carryStaff.id,
        props.requests.map((req) => req.id),
      );
    })();
  }, [requests, searchConditionsStore.targetTerm.date]);

  const getDeliveryTimeLabel = (request: ExtendedRawRequest | null) => {
    return request &&
      request.is_delivery_time_specified &&
      request.delivery_time_at
      ? getRangeDeliveryTimeSlotTimeLabel(request)
      : "時間指定なし";
  };

  const handleAssign = () => {
    if (!selectedCarryStaffId) {
      return;
    }
    const targetReqIds = requests.map((request) => request.id);
    reassignRequestToAnotherCarryStaff({
      reqIds: targetReqIds,
      casId: selectedCarryStaffId,
    })
      .then(() => {
        pageStore.setSequencesAfterAssign(targetReqIds);
        onRequestClose();
        onAfterAssignSucceed();
        toast.info("更新しました。");
      })
      .catch((e) => {
        console.log(e);
        onRequestClose();
        toast.error("更新に失敗しました。");
      });
  };
  const handleCloseModal = () => {
    setSelectedCarryStaffId(undefined);
    onRequestClose();
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={onAfterModalOpen}
      onRequestClose={handleCloseModal}
      style={modalStyles}
    >
      <div className="h5">他の配達スタッフへのアサイン変更</div>
      <div
        className={
          "d-flex align-items-center justify-content-center flex-row h-100"
        }
      >
        <ul className="w-100" style={{ height: "50vh", overflow: "auto" }}>
          {requests &&
            requests.map((request) => {
              return (
                <li className={"card p-3 w-100 d-flex"} key={request.id}>
                  <div className={"d-flex flex-row"}>
                    <div style={indexWrapperStyle}>
                      {request && convertNumToShortCode(request.id)}
                    </div>
                    <a
                      href={`/requests/${request?.id}/edit`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      依頼No: {request?.id}
                    </a>
                  </div>
                  <div>
                    <span className={"font-weight-bold"}>配達時間: </span>
                    {getDeliveryTimeLabel(request)}
                  </div>
                  <div>
                    <span className={"font-weight-bold"}>配達先: </span>
                    {request?.receiver_full_address}
                  </div>
                </li>
              );
            })}
        </ul>
        <i
          className="fas fa-2x fa-chevron-right"
          style={{ margin: "0 50px 0 50px" }}
        />
        <div className="d-flex flex-column">
          <div className="w-100">
            <div className={"p-2 font-weight-bold w-100"}>
              アサイン先の配達スタッフを選択してください
            </div>
            <ul
              className={"w-100 list-group list-group-flush"}
              style={{ height: "50vh", overflow: "auto" }}
            >
              {pageStore.relCasLoading && (
                <div className={"d-flex justify-content-center mt-4"}>
                  <div className="spinner-border spinner-border-lg">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {!pageStore.relCasLoading &&
                pageStore.assignableCarryStaffs.length > 0 &&
                pageStore.assignableCarryStaffs.map((cas) => {
                  return (
                    <RelatedCarryStaffItem
                      carryStaff={cas}
                      onSelectCarryStaff={setSelectedCarryStaffId}
                      selectedCarryStaffId={selectedCarryStaffId}
                    />
                  );
                })}
              {!pageStore.relCasLoading &&
                pageStore.assignableCarryStaffs.length == 0 && (
                  <div
                    className={
                      "d-flex justify-content-center align-items-center h-100"
                    }
                  >
                    <p>アサイン可能な配達員が見つかりませんでした。</p>
                  </div>
                )}
            </ul>
          </div>
          <div className={"w-100 mt-auto"}>
            <div className={"d-flex justify-content-flex-end mt-4"}>
              <button
                className={"btn btn-primary mr-4"}
                disabled={!selectedCarryStaffId}
                onClick={handleAssign}
              >
                選択した配達スタッフにアサインする
              </button>
              <button
                className={"btn btn-secondary"}
                onClick={handleCloseModal}
              >
                キャンセル
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default observer(AssignOtherStaffModal);

const modalStyles: Modal.Styles = {
  overlay: {
    zIndex: 1050,
  },
  content: {
    width: 1000,
    top: "50%",
    left: "50%",
    height: "70%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
};

const indexWrapperStyle: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${Colors.INFO_COLOR}`,
  color: Colors.INFO_COLOR,
  fontSize: "0.8rem",
  fontWeight: "bold",
  width: "36px",
  height: "24px",
  marginRight: "8px",
};
