import React from "react";
import { observer } from "mobx-react";
import spotRemarkOverlookStore from '../stores/SpotRemarkOverlookStore';

interface Props {
}

interface State {
  address: string,
  searchWord: string
}

// class OverlookAddMarker extends React.Component<Props, State> {
class SpotRemarkOverlookSearchCond extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      address: "",
      searchWord: ""
    }
  }

  componentDidMount(): void {
  }

  render() {
    return (
      <div>
        {this.renderSearchCond()}
      </div>
    );
  }

  private renderSearchCond() {
    return (
      <div className="form-group form-inline mb-2">
        <input
          type="text" className="form-control input-area-topic-overlook-address"
          value={this.state.address}
          placeholder={"住所（一部分でも可）"}
          onChange={(event) => {
            this.setState({
              address: event.target.value
            })
          }}
        />
        <button className="btn btn-info ml-2" onClick={() => {
          spotRemarkOverlookStore.moveCenter(this.state.address)
        }}>住所検索
        </button>

      </div>
    )
  }
}

export default observer(SpotRemarkOverlookSearchCond);
