import type { RawRoutesApiParamSetting } from "../../interfaces/entities";
import {
  DEFAULT_GEOAPIFY_OPTIMIZATION_TYPE_FOR_EC_AND_NM,
  DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM,
} from "./consts";

export type RoutesApiParamSetting = Omit<
  RawRoutesApiParamSetting,
  "id" | "name" | "api_code"
>;

// 上記パラメーターのデフォルト値
// ここの数値をいじる場合は`app/views/routes_api_param_settings/index.html.haml`の表示内容も合わせて変更すること
export const defaultApiParamSetting = {
  pickup_duration: 10,
  tolerant_seconds_before_pickup_at: 300,
  tolerant_seconds_after_pickup_at: 300,
  dropoff_duration: 300,
  tolerant_seconds_before_dropoff_at: 300,
  tolerant_seconds_after_dropoff_at: 300,
  geoapify_travel_mode: DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM,
  geoapify_max_speed: null,
  geoapify_optimization_type: DEFAULT_GEOAPIFY_OPTIMIZATION_TYPE_FOR_EC_AND_NM,
} as const;

export const strictApiParamSetting = {
  pickup_duration: 10,
  tolerant_seconds_before_pickup_at: 0,
  tolerant_seconds_after_pickup_at: 0,
  dropoff_duration: 300,
  tolerant_seconds_before_dropoff_at: 0,
  tolerant_seconds_after_dropoff_at: 0,
  geoapify_travel_mode: null,
  geoapify_max_speed: null,
  geoapify_optimization_type: null,
} as const;

/**
 * Routes APIの種類(大元のみ)
 */
export type RoutesApiCode = "ec-and-nm" | "geoapify";

// 選択肢に表示する時の順番
const API_CODE_DISPLAY_ORDER = {
  "ec-and-nm": 1,
  geoapify: 2,
} as const;

const API_CODES: RoutesApiCode[] = ["ec-and-nm", "geoapify"];

export const API_CODE_JOINT_CHAR = "_" as const;

export type SelectableApiType =
  | RoutesApiCode
  | `${RoutesApiCode}${typeof API_CODE_JOINT_CHAR}${number}`;

export function parseApiType(apiType: SelectableApiType): {
  apiCode: RoutesApiCode;
  routesApiSettingId: number | undefined;
} {
  const jointIndex = apiType.lastIndexOf("_");
  if (jointIndex >= 0) {
    const apiCode = apiType.substring(0, jointIndex) as RoutesApiCode;
    const routesApiSettingId = +apiType.substring(jointIndex + 1);
    return {
      apiCode,
      routesApiSettingId,
    };
  }

  return {
    apiCode: apiType as RoutesApiCode,
    routesApiSettingId: undefined,
  };
}

export function getApiCode(apiType: SelectableApiType) {
  const parseResult = parseApiType(apiType);
  return parseResult.apiCode;
}

/**
 * 並び替え、もしくは一括アサインの際に利用できるAPIの種類を返す関数.
 *
 * 一応分けられるように作ってみたけど、不要か？
 * @param execType
 * @returns
 */
export function getSelectableApiTypes(
  execType: "sort",
  routesApiParamSettings: RawRoutesApiParamSetting[]
): SelectableApiType[];
export function getSelectableApiTypes(
  execType: "assign",
  routesApiParamSettings: RawRoutesApiParamSetting[]
): SelectableApiType[];
export function getSelectableApiTypes(
  execType: "sort" | "assign",
  routesApiParamSettings: RawRoutesApiParamSetting[]
): SelectableApiType[] {
  const results: SelectableApiType[] = (
    [...API_CODES] as SelectableApiType[]
  ).concat(
    routesApiParamSettings.map(
      (setting) =>
        `${setting.api_code}${API_CODE_JOINT_CHAR}${setting.id}` as SelectableApiType
    )
  );

  results.sort((a, b) => {
    const { apiCode: aApiCode, routesApiSettingId: aRoutesApiSettingId } =
      parseApiType(a);
    const { apiCode: bApiCode, routesApiSettingId: bRoutesApiSettingId } =
      parseApiType(b);

    if (aApiCode != bApiCode) {
      const aOrder = API_CODE_DISPLAY_ORDER[aApiCode];
      const bOrder = API_CODE_DISPLAY_ORDER[bApiCode];
      if (aOrder < bOrder) return -1;
      if (aOrder > bOrder) return 1;
      return 0;
    }

    if (aRoutesApiSettingId == null) {
      return -1;
    }
    if (bRoutesApiSettingId == null) {
      return 1;
    }

    if (aRoutesApiSettingId < bRoutesApiSettingId) return -1;
    if (aRoutesApiSettingId > bRoutesApiSettingId) return 1;
    return 0;
  });

  return results;
}

/**
 * APIの名前を返す関数.
 *
 * セレクトボックスに各APIを表示する際に利用。
 * @param apiCode
 * @returns
 */
export function getApiTypeName(
  apiType: SelectableApiType,
  routesApiParamSettings: RawRoutesApiParamSetting[]
) {
  const parsedResult = parseApiType(apiType);
  const apiCode = parsedResult.apiCode;
  const apiSetting =
    parsedResult.routesApiSettingId == null
      ? undefined
      : routesApiParamSettings.find(
          (setting) => setting.id == parsedResult.routesApiSettingId
        );
  const paramLabel = apiSetting == null ? "" : ` ${apiSetting.name}`;
  switch (apiCode) {
    case "ec-and-nm":
      return `標準${paramLabel}`;
    case "geoapify":
      return `速度優先${paramLabel}`;
  }
}

export function selectApiParamSetting(
  apiType: SelectableApiType,
  routesApiParamSettings: RawRoutesApiParamSetting[]
): RoutesApiParamSetting {
  const { apiCode, routesApiSettingId } = parseApiType(apiType);

  if (routesApiSettingId == null) {
    return defaultApiParamSetting;
  }

  const setting = routesApiParamSettings.find(
    (_setting) => _setting.id == routesApiSettingId
  );
  if (setting == null) {
    return defaultApiParamSetting;
  }

  return setting;
}
