import React, {
  useState,
  createContext,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  Ref
} from "react";
import {
  ChangeEventValue,
  ClickEventValue,
  Coords,
  MapOptions
} from "google-map-react";
import GoogleMap from "../../../components/Common/GoogleMap";
import Controls from "./controls/index";
import type { ControlComponents } from "./controls";
import { Toast, ToastOptions } from "./controls/Notifications";
import { DrawerOptions } from "./controls/Drawer";
export { Polygon } from "./elements/Polygon";
export { PolygonByH3Index } from "./elements/PolygonByH3Index";
export { PolygonByWkt } from "./elements/PolygonByWkt";
export { Marker } from "./elements/Marker";

export const GmapsUIContext = createContext<{
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  toast?: Toast;
  setToast: (newToast: Toast) => void;
  isOpenDrawer: boolean;
  setIsOpenDrawer: (isOpenDrawer: boolean) => void;
}>({
  isOpenModal: false,
  setIsOpenModal: () => {},
  setToast: () => {},
  isOpenDrawer: false,
  setIsOpenDrawer: () => {}
});

export interface MapApi {
  map: google.maps.Map;
  maps: typeof google.maps;
}

interface Props {
  apiKey: string;
  children?: React.ReactNode;
  center: Coords;
  zoom?: number;
  options?: MapOptions;
  // Map読み込み完了後に実行
  onMapLoaded?: (map: google.maps.Map, maps: typeof google.maps) => void;
  onClickMap?: (e: ClickEventValue) => void;
  onChangeMap?: (e: ChangeEventValue) => void;
  onMousemove?: (e: Event) => void;
  onMouseout?: (e: Event) => void;
  controlsComponents?: ControlComponents;
  // Modalの表示・非表示
  modalOpen?: boolean;
  // Modalを閉じた時に実行（親コンポーネントのModal表示状態の変更を行う場合などに使用）
  onModalClose?: () => void;
  drawerOptions?: DrawerOptions;
}

interface Refs {
  pushToast: (
    content: ReactNode,
    options?: ToastOptions,
    id?: string | number
  ) => void;
  toggleDrawer: (isOpen?: boolean) => void;
}

function GmapsUI(props: Props, ref: Ref<Refs>) {
  const {
    children,
    center,
    zoom = 15,
    apiKey,
    options = { minZoom: 6, maxZoom: 18 },
    controlsComponents,
    modalOpen,
    onModalClose = () => {},
    onMapLoaded = () => {},
    onMousemove = undefined,
    onMouseout = undefined,
    drawerOptions
  } = props;

  useImperativeHandle(ref, () => ({
    pushToast: (
      content: ReactNode,
      options?: ToastOptions,
      id?: string | number
    ) => {
      if (!ref) return;
      setToast({
        content,
        id: id || new Date().getTime(),
        isDisappear: false,
        options
      });
    },
    toggleDrawer: (isOpen) => {
      if (isOpen === undefined) {
        setIsOpenDrawer(!isOpenDrawer);
      } else {
        setIsOpenDrawer(isOpen);
      }
    }
  }));

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [maps, setMaps] = useState(undefined);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [toast, setToast] = useState<Toast>();
  const [isOpenDrawer, setIsOpenDrawer] = useState(
    drawerOptions?.defaultOpen || false
  );

  const handleApiLoaded = (map: google.maps.Map, maps: typeof google.maps) => {
    onMapLoaded(map, maps);
  };

  const handleClickMap = (e: ClickEventValue) => {
    if (props.onClickMap) props.onClickMap(e);
  };

  const handleChangeMap = (e: ChangeEventValue) => {
    if (props.onChangeMap) props.onChangeMap(e);
  };

  useEffect(() => {
    setIsOpenModal(modalOpen || false);
  }, [modalOpen]);

  // @ts-ignore
  return (
    <GmapsUIContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        toast,
        setToast,
        isOpenDrawer,
        setIsOpenDrawer
      }}
    >
      {map && maps && controlsComponents && (
        <Controls
          components={controlsComponents}
          mapApi={{ map, maps }}
          modalOpen={modalOpen || false}
          onModalClose={onModalClose}
          drawerOptions={drawerOptions}
        />
      )}

      <GoogleMap
        bootstrapURLKeys={{ key: apiKey }}
        // defaultCenter={{
        //     lat: center.lat,
        //     lng: center.lng
        // }}
        defaultZoom={zoom}
        center={{
          lat: center.lat,
          lng: center.lng
        }}
        resetBoundsOnResize={true}
        hoverDistance={32 / 2}
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          setMaps(maps);
          handleApiLoaded(map, maps);
          if (onMousemove) {
            map.addListener("mousemove", function (argument: Event) {
              onMousemove(argument);
            });
          }
          if (onMouseout) {
            map.addListener("mouseout", function (argument: Event) {
              onMouseout(argument);
            });
          }
        }}
        onChange={handleChangeMap}
        onClick={handleClickMap}
        options={{
          // styles: styles.json
          ...options,
          zoomControlOptions: {
            position: 7
          }
        }}
      >
        {children}
      </GoogleMap>
    </GmapsUIContext.Provider>
  );
}

export default forwardRef(GmapsUI);
