import React from "react";
import { Shape } from "./PinShape";

export type Position =
  | "top-right"
  | "top-left"
  | "middle-right"
  | "middle-left"
  | "bottom-right"
  | "bottom-left";

interface Props {
  children?: React.ReactNode;
  backgroundColor?: string;
  iClass?: string | null;
  position?: Position;
  sourcePinShape?: Shape;
}
export class PinBadge extends React.Component<Props> {
  private getPositionStyle(sourcePinShape: Shape, position: Position) {
    if (sourcePinShape === "square") {
      switch (position) {
        case "top-right":
          return { top: "-1.5em", right: "-1.5em" };
        case "top-left":
          return { top: "-1.5em", left: "-1.5em" };
        // middle, bottomのtop位置は必要に応じて調整してください
        case "middle-right":
          return { top: "0.5em", right: "-1.5em" };
        case "middle-left":
          return { top: "0.5em", left: "-1.5em" };
        case "bottom-right":
          return { top: "1.0em", right: "-1.5em" };
        case "bottom-left":
          return { top: "1.0em", left: "-1.5em" };
      }
    } else {
      switch (position) {
        case "top-right":
          return { top: "-1.5em", right: "-0.9em" };
        case "top-left":
          return { top: "-1.5em", left: "-0.9em" };
        case "middle-right":
          return { top: "0.5em", right: "-0.9em" };
        case "middle-left":
          return { top: "0.5em", left: "-0.9em" };
        // bottomのtop位置は必要に応じて調整してください
        case "bottom-right":
          return { top: "1.0em", right: "-0.9em" };
        case "bottom-left":
          return { top: "1.0em", left: "-0.9em" };
      }
    }
  }
  render() {
    const {
      backgroundColor = "#f78733",
      iClass,
      children,
      sourcePinShape = "drop",
      position = "top-right",
    } = this.props;
    return (
      <div
        style={{
          background: backgroundColor,
          color: "#fff",
          padding: "0 4px",
          borderRadius: "2em",
          border: "2px solid #fff",
          position: "absolute",
          minHeight: "2em",
          minWidth: "2em",
          lineHeight: "1em",
          fontWeight: "bold",
          zIndex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          whiteSpace: "nowrap",
          ...this.getPositionStyle(sourcePinShape, position),
        }}
      >
        {iClass && <i className={iClass} />}
        {children && <span>{children}</span>}
      </div>
    );
  }
}
