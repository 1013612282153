import React from "react"
import { getPreciseDistance } from "geolib";

interface Props {
  sender: {
    lat: number;
    lng: number;
  };
  receiver: {
    lat: number;
    lng: number;
  }
};

class DeliveryDistanceLabel extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { sender, receiver } = this.props;
    const distance = getPreciseDistance({ lat: sender.lat, lng: sender.lng }, { lat: receiver.lat, lng: receiver.lng });
    if (distance) {
      return (
        <p>
          {distance}m
        </p>
      )
    }
    return (
      <p>
        ---m
      </p>
    )
  }
}

export default DeliveryDistanceLabel;
