import { requestConst } from "../../../constants/Request";
import { thirdPartyDeliveryTaskConst } from "../../../constants/ThirdPartyDeliveryTask";
import { CurrentLocationWithCarryStaffModel } from "../../../models/CurrentLocationWithCarryStaffModel";
import { RequestModel } from "../../../models/RequestModel";
import { ThirdPartyDeliveryTaskModel } from "../../../models/ThirdPartyDeliveryTaskModel";
import carryStaffLocationsStore from "../../../stores/CarryStaffLocationsStore";
import requestsStore from "../../../stores/RequestsStore";
import thirdPartyDeliveryTasksStore from "../../../stores/ThirdPartyDeliveryTasksStore";
import { TargetEntityType } from "../interfaces/Interfaces";
import searchConditionsStore from "../stores/SearchConditionsStore";

export function getCarryStaffNameById(carryStaffId: number) {
  const carryStaffLocation = carryStaffLocationsStore.items.find(
    (loc) => loc.carryStaffId === carryStaffId
  );
  return carryStaffLocation?.name;
}

export function getKeyByRequestStatus(status: number) {
  return Object.keys(requestConst.STATUSES).find(
    (key) => requestConst.STATUSES[key] == status
  ) as keyof typeof requestConst.STATUSES | undefined;
}

export function getKeyByThirdPartyDeliveryTaskStatus(status: number) {
  return Object.keys(thirdPartyDeliveryTaskConst.STATUSES).find(
    (key) => thirdPartyDeliveryTaskConst.STATUSES[key] == status
  ) as keyof typeof thirdPartyDeliveryTaskConst.STATUSES | undefined;
}

export function getWorkTypeByNumber(workType: number) {
  return Object.keys(thirdPartyDeliveryTaskConst.WORK_TYPES).find(
    (key) => thirdPartyDeliveryTaskConst.WORK_TYPES[key] == workType
  ) as keyof typeof thirdPartyDeliveryTaskConst.WORK_TYPES | undefined;
}

/**
 * 何かが選択された時に、それに対応するstoreのselected〜プロパティを更新し、
 * それ以外のstoreのselected〜プロパティをundefinedで更新する関数.
 *
 * @param target
 * @param targetId
 * @param resetCarryStaff 依頼もしくは外部配達選択時にCASの選択をリセットするかどうかのフラグ
 * @returns
 */
export function selectItem(
  target: TargetEntityType | undefined,
  targetId: number | "unassigned" | undefined,
  resetCarryStaff = true
) {
  if (targetId == "unassigned") {
    // 本当は一番下の部分とまとめたいが、型の対応が面倒なので、unassignedだけ個別対応
    carryStaffLocationsStore.setSelectedItemId("unassigned");
    requestsStore.setSelectedItemId(undefined);
    thirdPartyDeliveryTasksStore.setSelectedItemId(undefined);
    return;
  }

  if (!resetCarryStaff) {
    switch (target) {
      case "request":
        requestsStore.setSelectedItemId(targetId);
        thirdPartyDeliveryTasksStore.setSelectedItemId(undefined);
        return;
      case "thirdPartyDeliveryTask":
        requestsStore.setSelectedItemId(undefined);
        thirdPartyDeliveryTasksStore.setSelectedItemId(targetId);
        return;
    }
  }

  carryStaffLocationsStore.setSelectedItemId(
    target == "carryStaff" ? targetId : undefined
  );
  requestsStore.setSelectedItemId(target == "request" ? targetId : undefined);
  thirdPartyDeliveryTasksStore.setSelectedItemId(
    target == "thirdPartyDeliveryTask" ? targetId : undefined
  );
}

type SelectedItemResponse =
  | {
      targetType: "carryStaff";
      targetId: number | "unassigned";
      target: CurrentLocationWithCarryStaffModel | undefined;
    }
  | {
      targetType: "request";
      targetId: number;
      target: RequestModel | undefined;
    }
  | {
      targetType: "thirdPartyDeliveryTask";
      targetId: number;
      target: ThirdPartyDeliveryTaskModel | undefined;
    }
  | {
      targetType: "none";
      targetId: undefined;
      target: undefined;
    };

export function getSelectedItem(): SelectedItemResponse {
  const selectedCasId = carryStaffLocationsStore.selectedCasId;
  const selectedReqId = requestsStore.selectedItemId;
  const selectedTpdtId = thirdPartyDeliveryTasksStore.selectedItemId;
  if (selectedCasId != null) {
    return {
      targetType: "carryStaff",
      targetId: selectedCasId,
      target: carryStaffLocationsStore.getSelectedItem(),
    };
  } else if (selectedReqId != null) {
    return {
      targetType: "request",
      targetId: selectedReqId,
      target: requestsStore.getSelectedItem(),
    };
  } else if (selectedTpdtId != null) {
    return {
      targetType: "thirdPartyDeliveryTask",
      targetId: selectedTpdtId,
      target: thirdPartyDeliveryTasksStore.getSelectedItem(),
    };
  }

  return {
    targetType: "none",
    targetId: undefined,
    target: undefined,
  };
}

export function getInfoWindowVisibleItems() {
  const infoWindowVisibleRequests = requestsStore.items.filter(
    (item) => requestsStore.infoWindowVisibleList.indexOf(item.id) >= 0
  );

  const infoWindowVisibleTpdts = thirdPartyDeliveryTasksStore.items.filter(
    (item) =>
      thirdPartyDeliveryTasksStore.infoWindowVisibleList.indexOf(item.id) >= 0
  );

  return {
    requests: infoWindowVisibleRequests,
    thirdPartyDeliveryTasks: infoWindowVisibleTpdts,
  };
}

/**
 * 選択(表示)しているペインのタブごとに、ローディングフラグを統合するためのメソッド.
 *
 * 表示しているタブに関わらず、ロード中かどうかはこのメソッドの返り値を見るだけでよくするため
 * @returns
 */
export function isLoading() {
  const displayPage = searchConditionsStore.displayPage;
  switch (displayPage) {
    case "carry_staffs":
      return (
        carryStaffLocationsStore.loadingInfo.loading ||
        requestsStore.loadingInfo.loading ||
        thirdPartyDeliveryTasksStore.loadingInfo.loading
      );
    case "carry_staff_requests_or_tpdts":
      return (
        requestsStore.loadingInfo.loading ||
        thirdPartyDeliveryTasksStore.loadingInfo.loading
      );
    case "requests":
      return requestsStore.loadingInfo.loading;
    case "third_party_delivery_tasks":
      return thirdPartyDeliveryTasksStore.loadingInfo.loading;
  }
}
