// こいつらさえちゃんとできていれば、他の部分で間違い(タイポや更新漏れ)があった場合に型エラーになる
const statuses = {
  wait_store: -1,
  uncheck: 0,
  checked: 1,
  pickup: 2,
  delivered: 3,
  onhold: 10,
  return: 80,
  failed: 90,
  cancel: 99,
  store_cancel: 100,
} as const;

// 留め置き以外の未配達ステータスを画面側における「処理中」ステータスとして扱う。
// 依頼俯瞰の依頼一覧では、以下ステータスの依頼しか取得しておらず、それに対応している。
const processing_statuses = {
  wait_store: -1,
  uncheck: 0,
  checked: 1,
  pickup: 2,
  return: 80,
} as const;

const storeStatuses = {
  failed: -99,
  waiting_check: -20,
  waiting_settlement: -10,
  waiting_approve: 0,
  denied: 10,
  approved: 20,
  shipped: 30,
  delivered: 40,
  cancel: 99,
} as const;

const deliveryTypes = {
  delivery: 0,
  robo: 1,
  takeout: 2,
  route: 3,
} as const;

export const requestConst: {
  STATUSES: { [key in keyof typeof statuses]: (typeof statuses)[key] };
  PROCESSING_STATUSES: {
    [key in keyof typeof processing_statuses]: (typeof processing_statuses)[key];
  };
  STATUS_CODES: { [key in keyof typeof statuses]: key };
  STORE_STATUSES: {
    [key in keyof typeof storeStatuses]: (typeof storeStatuses)[key];
  };
  STORE_STATUS_CODES: { [key in keyof typeof storeStatuses]: key };
  DELIVERY_TYPES: {
    [key in keyof typeof deliveryTypes]: (typeof deliveryTypes)[key];
  };
  DELIVERY_TYPE_CODES: { [key in keyof typeof deliveryTypes]: key };
} = {
  STATUSES: statuses,
  PROCESSING_STATUSES: processing_statuses,
  STATUS_CODES: {
    wait_store: "wait_store",
    uncheck: "uncheck",
    checked: "checked",
    pickup: "pickup",
    delivered: "delivered",
    onhold: "onhold",
    return: "return",
    failed: "failed",
    cancel: "cancel",
    store_cancel: "store_cancel",
  },
  STORE_STATUSES: storeStatuses,
  STORE_STATUS_CODES: {
    failed: "failed",
    waiting_check: "waiting_check",
    waiting_settlement: "waiting_settlement",
    waiting_approve: "waiting_approve",
    denied: "denied",
    approved: "approved",
    shipped: "shipped",
    delivered: "delivered",
    cancel: "cancel",
  },
  DELIVERY_TYPES: {
    delivery: 0,
    robo: 1,
    takeout: 2,
    route: 3,
  },
  DELIVERY_TYPE_CODES: {
    delivery: "delivery",
    robo: "robo",
    takeout: "takeout",
    route: "route",
  },
} as const;

// 定数じゃないけど、実質定数みたいなものなので...
// 本当は、inProgress: [0, 1, 2]とかしたいけど、数値版と文字列版を両方きれいに扱う書き方を思いつかないので仕方なく
export function isProcessingOrUndelivered(
  status: (typeof statuses)[keyof typeof statuses] | keyof typeof statuses,
  type: "processing" | "undelivered" = "processing"
): status is
  | typeof requestConst.STATUSES.wait_store
  | typeof requestConst.STATUS_CODES.wait_store
  | typeof requestConst.STATUSES.uncheck
  | typeof requestConst.STATUS_CODES.uncheck
  | typeof requestConst.STATUSES.checked
  | typeof requestConst.STATUS_CODES.checked
  | typeof requestConst.STATUSES.pickup
  | typeof requestConst.STATUS_CODES.pickup
  | typeof requestConst.STATUSES.onhold
  | typeof requestConst.STATUS_CODES.onhold
  | typeof requestConst.STATUSES.return
  | typeof requestConst.STATUS_CODES.return {
  if (
    status == requestConst.STATUSES.wait_store ||
    status == requestConst.STATUS_CODES.wait_store
  ) {
    return true;
  }
  if (
    status == requestConst.STATUSES.uncheck ||
    status == requestConst.STATUS_CODES.uncheck
  ) {
    return true;
  }
  if (
    status == requestConst.STATUSES.checked ||
    status == requestConst.STATUS_CODES.checked
  ) {
    return true;
  }
  if (
    status == requestConst.STATUSES.pickup ||
    status == requestConst.STATUS_CODES.pickup
  ) {
    return true;
  }
  if (
    type === "undelivered" &&
    (status == requestConst.STATUSES.onhold ||
      status == requestConst.STATUS_CODES.onhold)
  ) {
    return true;
  }
  if (
    status == requestConst.STATUSES.return ||
    status == requestConst.STATUS_CODES.return
  ) {
    return true;
  }

  return false;
}

/**
 * 集荷する必要のあるステータスかどうかを返す関数.
 *
 * @param status
 * @returns
 */
export function isNeedPickup(
  status: (typeof statuses)[keyof typeof statuses] | keyof typeof statuses
): status is
  | typeof requestConst.STATUSES.wait_store
  | typeof requestConst.STATUS_CODES.wait_store
  | typeof requestConst.STATUSES.uncheck
  | typeof requestConst.STATUS_CODES.uncheck
  | typeof requestConst.STATUSES.checked
  | typeof requestConst.STATUS_CODES.checked
  | typeof requestConst.STATUSES.onhold
  | typeof requestConst.STATUS_CODES.onhold {
  if (
    status == requestConst.STATUSES.wait_store ||
    status == requestConst.STATUS_CODES.wait_store
  ) {
    return true;
  }
  if (
    status == requestConst.STATUSES.uncheck ||
    status == requestConst.STATUS_CODES.uncheck
  ) {
    return true;
  }
  if (
    status == requestConst.STATUSES.checked ||
    status == requestConst.STATUS_CODES.checked
  ) {
    return true;
  }
  if (
    status == requestConst.STATUSES.onhold ||
    status == requestConst.STATUS_CODES.onhold
  ) {
    return true;
  }

  return false;
}

export function isNotNeedDeliver(
  status: (typeof statuses)[keyof typeof statuses] | keyof typeof statuses
): status is
  | typeof requestConst.STATUSES.store_cancel
  | typeof requestConst.STATUS_CODES.store_cancel
  | typeof requestConst.STATUSES.cancel
  | typeof requestConst.STATUS_CODES.cancel
  | typeof requestConst.STATUSES.delivered
  | typeof requestConst.STATUS_CODES.delivered {
  if (
    status == requestConst.STATUSES.store_cancel ||
    status == requestConst.STATUS_CODES.store_cancel
  ) {
    return true;
  }

  if (
    status == requestConst.STATUSES.cancel ||
    status == requestConst.STATUS_CODES.cancel
  ) {
    return true;
  }

  if (
    status == requestConst.STATUSES.delivered ||
    status == requestConst.STATUS_CODES.delivered
  ) {
    return true;
  }

  return false;
}
