import React from "react";
import { RawRoutesApiParamSetting } from "../../interfaces/entities";
import {
  getApiTypeName,
  SelectableApiType,
} from "../../utils/routes-apis/api-code-utils";

interface Props {
  showLabel?: boolean;
  selectableApiTypes: SelectableApiType[];
  defaultValue?: SelectableApiType;
  routesApiParamSettings: RawRoutesApiParamSetting[];
  disabled?: boolean;
  onSelect: (apiType: SelectableApiType) => void;
}

export default function RoutesApiSelection(props: Props) {
  const {
    showLabel = true,
    defaultValue = "ec-and-nm",
    selectableApiTypes,
    routesApiParamSettings,
    disabled = false,
    onSelect,
  } = props;
  return (
    <div className="d-flex align-items-center">
      {showLabel && <label htmlFor="routes-api-selection">利用API</label>}
      <div>
        <select
          id="routes-api-selection"
          className="form-control"
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={(e) => {
            const apiName = e.target.value as SelectableApiType;
            onSelect(apiName);
          }}
        >
          {selectableApiTypes.map((apiType) => (
            <option key={apiType} value={apiType}>
              {getApiTypeName(apiType, routesApiParamSettings)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
