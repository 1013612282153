import { action, observable, runInAction } from "mobx";
import { axiosGet } from "../../../../utils/AxiosClient";
import { calcWeights } from "../../../../utils/HeatMapUtils";
import type { MatchedLocation, RawMatchedLocation } from "../interfaces";
import { isInvalidDate } from "../utils";
import { format } from "date-fns";
import { StoreClass } from "./StoreInterface";

export class MatchedLocationsStore implements StoreClass<MatchedLocation> {
  @observable.shallow
  locations: MatchedLocation[] = [];

  @observable
  selectedLocation: MatchedLocation | undefined = undefined;

  @observable
  isLoading = false;

  @observable
  errorMessage: string | undefined = undefined;

  @observable
  updatedAt: Date | undefined = undefined;

  @action
  public setSelectedLocation(location: MatchedLocation | undefined) {
    this.selectedLocation = location;
  }

  @action
  public async loadLocations(
    carryStaffId: number,
    fromDate: string,
    toDate: string
  ) {
    if (isInvalidDate(fromDate)) {
      this.errorMessage = "開始日付が不正です。";
      return;
    } else if (isInvalidDate(toDate)) {
      this.errorMessage = "終了日付が不正です。";
      return;
    } else if (new Date(fromDate) > new Date(toDate)) {
      this.errorMessage =
        "終了日付は開始日付より後になるように指定してください。";
      return;
    }

    this.isLoading = true;
    this.errorMessage = undefined;

    try {
      const locations = await this._load(carryStaffId, fromDate, toDate);

      runInAction(() => {
        const weights = calcWeights(locations);
        this.locations = locations.map((loc, index) => ({
          ...loc,
          lat: +loc.lat,
          lng: +loc.lng,
          sent_at: loc.sent_at
            ? format(new Date(loc.sent_at), "yyyy/MM/dd HH:mm:ss")
            : null,
          weight: weights[index],
        }));
        this.isLoading = false;
        this.updatedAt = new Date();
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.errorMessage =
          error.response?.data?.message || "データの取得に失敗しました。";
        this.isLoading = false;
      });
    }
  }

  private async _load(carryStaffId: number, fromDate: string, toDate: string) {
    const urlParams = new URLSearchParams([
      ["from_date", fromDate],
      ["to_date", toDate],
    ]);

    const response = (await axiosGet.get(
      `/api/carry_staffs/${carryStaffId}/matched_locations?${urlParams.toString()}`
    )) as {
      data: {
        locations: RawMatchedLocation[];
      };
    };
    return response.data.locations;
  }
}

const singleton = new MatchedLocationsStore();
export default singleton;
