import { addSeconds, format, subSeconds } from "date-fns";
import { carryStaffConst } from "../../constants/CarryStaff";
import {
  isNeedPickup,
  isNotNeedDeliver,
  requestConst,
} from "../../constants/Request";
import type {
  RawCarryStaff as _RawCarryStaff,
  RawRequest as _RawRequest,
  RawTerritory as _RawTerritory,
  RawVehicleType as _RawVehicleType,
  RawCurrentLocation as _RawCurrentLocation,
  RawRoutesApiParamSetting,
} from "../../interfaces/entities";
import { convertIntoPoint, isOverlap } from "../GeographyUtils";
import {
  convertSameJstTimeFromUtc,
  checkIsFuture,
  getTodayStr,
  validateDateStr,
  getBeginningAndEndOfDay,
  isOverlapTimes,
} from "../time-utils";
import {
  RoutesApiParamSetting,
  SelectableApiType,
  selectApiParamSetting,
} from "./api-code-utils";
import {
  excludedReasonCodes,
  excludedReasonLabels,
  DEFAULT_EXCLUDED_REASON_CODE,
} from "./consts";
import { getIntersection, isSuperset } from "./generic-utils";

export type RawCarryStaff = Pick<
  _RawCarryStaff,
  | "id"
  | "name"
  | "staff_type"
  | "can_route_delivery"
  | "territory_id"
  | "vehicle_type_id"
>;

export type RawCurrentLocation = Pick<
  _RawCurrentLocation,
  "id" | "carry_staff_id" | "lat" | "lng" | "updated_at"
>;

export type RawRequest = _RawRequest;

export type RawTerritory = Pick<_RawTerritory, "id" | "name" | "area_wkt">;

export type RawVehicleType = Pick<
  _RawVehicleType,
  "id" | "name" | "max_luggage"
>;

export interface SimpleCarryStaffSchedule {
  carry_staff_id: number;
  territory_id: number | null;
  from_time: Date;
  to_time: Date;
}

export interface LatLng {
  lat: number;
  lng: number;
}

type FilterType = "default" | "loose" | "strict";

/**
 * 指定された配達のタスク(集荷と配達)に関して、スケジュールに収まっていないものが存在するかどうかを返す関数.
 *
 * @param carryStaff
 * @param schedules
 * @param requests
 * @returns
 */
export function existsTasksNotOnSchedules(
  carryStaff: RawCarryStaff,
  schedules: SimpleCarryStaffSchedule[],
  requests: ExtendedRawRequest[]
) {
  // ルート配達スタッフ、または通常タイプでルート配達オプションあり、またはベンダータイプでルート配達オプションありのスタッフ以外には
  // スケジュールが存在しないので問答無用でfalse
  if (!isRouteAssignable(carryStaff)) {
    return false;
  }

  const existsInvalidRequests = requests
    .map((req) => {
      if (req.status == "pickup") {
        return [
          {
            name: `${req.id}_receiver`,
            fromTimeAt: new Date(req.dropoff.earliest),
            toTimeAt: new Date(req.dropoff.latest),
            isSender: false,
          },
        ];
      }

      return [
        {
          name: `${req.id}_sender`,
          fromTimeAt: new Date(req.pickup.earliest),
          toTimeAt: new Date(req.pickup.latest),
          isSender: true,
        },
        {
          name: `${req.id}_receiver`,
          fromTimeAt: new Date(req.dropoff.earliest),
          toTimeAt: new Date(req.dropoff.latest),
          isSender: false,
        },
      ];
    })
    .reduce((a, b) => a.concat(b), [])
    .some((item) => {
      const isIncludedInSchedule = schedules.some((sch) => {
        return !(
          item.fromTimeAt > sch.to_time || item.toTimeAt < sch.from_time
        );
      });
      return !isIncludedInSchedule;
    });

  return existsInvalidRequests;
}

/**
 * 指定された依頼を配達可能なスタッフが存在するかどうかを返す関数.
 *
 * @param requests
 * @param carryStaff
 * @param schedules
 * @param territories ルート配達スタッフ以外では利用しないので、その場合は空配列で良い
 * @returns
 */
export function checkRequestIsDeliverable(
  request: ExtendedRawRequest,
  carryStaffs: RawCarryStaff[],
  schedules: SimpleCarryStaffSchedule[],
  territories: RawTerritory[],
  options?: {
    filterType: FilterType;
  }
) {
  const isDeliverable = carryStaffs.some((cas) =>
    checkRequestIsAssignable(request, cas, schedules, territories, options)
  );
  return isDeliverable;
}

/**
 * 指定された配達スタッフが配達可能な依頼のIDを返す関数.
 *
 * @param requests
 * @param carryStaff
 * @param schedules
 * @param territories ルート配達スタッフ以外では利用しないので、その場合は空配列で良い
 * @returns
 */
export function extractAssignableRequestIds(
  requests: ExtendedRawRequest[],
  carryStaff: RawCarryStaff,
  schedules: SimpleCarryStaffSchedule[],
  territories: RawTerritory[],
  options?: {
    filterType: FilterType;
  }
) {
  const assignableRequestIds = requests
    .filter((req) =>
      checkRequestIsAssignable(req, carryStaff, schedules, territories, options)
    )
    .map((req) => req.id);
  return assignableRequestIds;
}

type CodesOfWhenRequestIsAssignable = "assigned_to_target";

type CodesOfWhenRequestIsNotAssignable =
  // 配達不要(配達キャンセル、店舗キャンセル、配達完了)
  | "need_not_delivery"
  // 他の人にアサイン済み
  | "assigned_to_other"
  // 未アサインだが、対象スタッフがルート配達タイプ以外
  | "need_assign_but_not_route_type"
  // 対象スタッフに紐づくスケジュールが存在しない
  | "no_schedules"
  // 対象スタッフに紐づくスケジュールはあるが、準備完了時刻を含むスケジュールが存在しない
  | "no_schedules_for_pick_up"
  // 対象スタッフに紐づく準備完了時刻を含むスケジュールは存在するが、そのスケジュールに紐づく担当エリアが存在しない(あり合えないはず)
  | "no_territories_for_pick_up_schedule"
  // 対象スタッフに紐づく準備完了時刻を含むスケジュールは存在するが、そのスケジュールに紐づく担当エリア内に配達先地点が含まれていない
  | "outside_territory_for_pick_up_schedule"
  // 対象スタッフに紐づくスケジュールはあるが、配達希望時刻を含むスケジュールが存在しない
  | "no_schedules_for_drop_off"
  // 対象スタッフに紐づく配達希望時刻を含むスケジュールは存在するが、そのスケジュールに紐づく担当エリアが存在しない(あり合えないはず)
  | "no_territories_for_drop_off_schedule"
  // 対象スタッフに紐づく配達希望時刻を含むスケジュールは存在するが、そのスケジュールに紐づく担当エリア内に配達先地点が含まれていない
  | "outside_territory_for_drop_off_schedule";

type CodesOfWhetherRequestIsAssignable =
  | CodesOfWhenRequestIsAssignable
  | CodesOfWhenRequestIsNotAssignable;

// アサイン可能とするコードリスト(デフォルト)
const defaultAssignableCodes: CodesOfWhenRequestIsAssignable[] = [
  "assigned_to_target",
];

// アサイン可能とするコードリスト(緩和版)
const looseAssignableCodes: CodesOfWhetherRequestIsAssignable[] = [
  "assigned_to_target",
  // 準備完了時刻、もしくは配達希望時刻を含むスケジュールを持っていないくてもよしとするため
  "no_schedules_for_pick_up",
  "no_territories_for_pick_up_schedule",
  "no_schedules_for_drop_off",
  "no_territories_for_drop_off_schedule",
];

/**
 * 指定された配達スタッフが指定された依頼を配達可能かどうか判断する関数.
 *
 * 効率的には最悪(呼び出し側まで考えると何重にもループされている)であるが、
 * 効率を重視して実装してしまうとあとで読むのが大変になりそうなので、
 * 効率は無視(理解しやすさ重視)で実装。
 *
 * @param request
 * @param carryStaff
 * @param schedules
 * @param territories ルート配達スタッフ以外では利用しないので、その場合は空配列で良い
 * @returns
 */
export function checkRequestIsAssignable(
  request: ExtendedRawRequest,
  carryStaff: RawCarryStaff,
  schedules: SimpleCarryStaffSchedule[],
  territories: RawTerritory[],
  options?: {
    filterType: FilterType;
  }
) {
  const resultCodes = getCodesOfWhetherRequestIsAssignable(
    request,
    carryStaff,
    schedules,
    territories
  );

  const allowedCodes =
    options == null || options.filterType == "default"
      ? defaultAssignableCodes
      : looseAssignableCodes;

  const assignable = isSuperset(new Set(allowedCodes), new Set(resultCodes));

  return assignable;
}

/**
 * 指定された配達スタッフが指定された依頼を配達可能かどうか判断した結果を返す関数.
 *
 * ただし、未アサイン依頼の場合にはルート配達スタッフでない場合、固定のコードを返す。
 * もし未アサイン依頼を対象とし、かつルート配達スタッフ以外を考慮するようにしたい場合には修正が必要。
 * 配達スタッフに担当エリアは最低限紐づいていることを前提としているため。
 *
 * @param request
 * @param carryStaff
 * @param schedules
 * @param territories
 * @returns
 */
export function getCodesOfWhetherRequestIsAssignable(
  request: ExtendedRawRequest,
  carryStaff: RawCarryStaff,
  schedules: SimpleCarryStaffSchedule[],
  territories: RawTerritory[]
): CodesOfWhetherRequestIsAssignable[] {
  // 配達が必要な依頼かどうか
  if (isNotNeedDeliver(request.status)) {
    return ["need_not_delivery"];
  }

  // 対象のスタッフへアサイン済みであればそのままOK
  // 並び替えの場合にはルート配達スタッフ以外も考慮する必要があるが、
  // 並び替えの場合にはすでに対象スタッフにアサインされていることが前提になるので、ここで通る、はず。
  // ということで、これ以降はルート配達スタッフを前提とする(つまり担当エリアもスケジュールも存在する前提)
  if (request.carry_staff_id == carryStaff.id) {
    return ["assigned_to_target"];
  }

  // 対象のスタッフ以外にアサイン済みであればその時点でNG
  if (request.carry_staff_id != null) {
    return ["assigned_to_other"];
  }

  // 未アサインでかつルート配達スタッフ以外の場合、その時点でNGとする
  if (!isRouteAssignable(carryStaff)) {
    return ["need_assign_but_not_route_type"];
  }

  // 以降全てルート配達可能なスタッフ

  // スケジュールが存在するかどうか
  // スケジュールそのものが存在しない場合にはそこで終了
  const _schedules = schedules.filter(
    (sch) => sch.carry_staff_id == carryStaff.id
  );
  if (_schedules.length == 0) {
    return ["no_schedules"];
  }

  let resultCodes: CodesOfWhetherRequestIsAssignable[] = [];
  // 未集荷の場合、集荷での条件確認を実施
  if (isNeedPickup(request.status)) {
    // 準備完了時間を含むスケジュールが存在するかどうか
    const scheduleInvolvedPickupTime = _schedules.find((sch) => {
      const isOverlap = isOverlapTimes(
        {
          from: new Date(request.pickup.earliest),
          to: new Date(request.pickup.latest),
        },
        { from: sch.from_time, to: sch.to_time }
      );

      return isOverlap;
    });
    if (scheduleInvolvedPickupTime == null) {
      resultCodes.push("no_schedules_for_pick_up");
    }

    const territoryIdWhenPickup =
      scheduleInvolvedPickupTime?.territory_id || carryStaff.territory_id;
    if (territoryIdWhenPickup == null) {
      resultCodes.push("no_territories_for_pick_up_schedule");
    } else {
      const territoryWhenPickup = territories.find(
        (ter) => ter.id == territoryIdWhenPickup
      );
      if (territoryWhenPickup == null) {
        resultCodes.push("no_territories_for_pick_up_schedule");
      } else {
        const isSenderWithin = isOverlap(
          territoryWhenPickup.area_wkt,
          convertIntoPoint({
            lat: +request.sender_lat,
            lng: +request.sender_lng,
          })
        );
        if (!isSenderWithin) {
          resultCodes.push("outside_territory_for_pick_up_schedule");
        } else {
          // 準備完了時刻を含むスケジュールを持っており、
          // かつそのスケジュールに紐づく担当エリアが存在し、
          // かつその担当エリア内に受け取り地点が含まれている場合にのみ、
          // コードが追加されない
        }
      }
    }
  }

  // 配達完了時間を含むスケジュールが存在するかどうか
  const schduleInvolvedDeliveryTime = _schedules.find((sch) => {
    const isOverlap = isOverlapTimes(
      {
        from: new Date(request.dropoff.earliest),
        to: new Date(request.dropoff.latest),
      },
      { from: sch.from_time, to: sch.to_time }
    );
    return isOverlap;
  });
  if (schduleInvolvedDeliveryTime == null) {
    resultCodes.push("no_schedules_for_drop_off");
  }

  // 配達時点を含むスケジュールでの担当エリアが、依頼の配達先地点を含んでいるかどうか
  // スケジュールに担当エリアが紐づいていない場合には、配達スタッフに紐づいている担当エリアを利用
  // (実装順の関係で、スケジュールには紐づいていないかもしれないが、ルート配達の配達スタッフであれば必ず紐づいている(はず))
  const territoryIdWhenDelivery =
    schduleInvolvedDeliveryTime?.territory_id || carryStaff.territory_id;
  if (territoryIdWhenDelivery == null) {
    resultCodes.push("no_territories_for_drop_off_schedule");
  } else {
    const territoryWhenDelivery = territories.find(
      (ter) => ter.id == territoryIdWhenDelivery
    );
    if (territoryWhenDelivery == null) {
      resultCodes.push("no_territories_for_drop_off_schedule");
    } else {
      const isReceiverWithin = isOverlap(
        territoryWhenDelivery.area_wkt,
        convertIntoPoint({
          lat: +request.receiver_lat,
          lng: +request.receiver_lng,
        })
      );
      if (!isReceiverWithin) {
        resultCodes.push("outside_territory_for_drop_off_schedule");
      }
    }
  }

  return resultCodes;
}

/**
 * 対象の依頼をAPIへのパラメーターとして含めないことにした理由を取得するための関数.
 *
 * 本当は、checkRequestIsAssignableとうまく同じメソッドを利用するようにしたいが、
 * 上手い作り方が思いつかず、諦めている。
 * 現状だと、checkRequestIsAssignableとgetExcludedReasonで結果がズレる可能性がある。
 * (片方の判定ロジックを変更した際に、もう片方も編集することを忘れるとズレることになりうる)
 *
 * checkRequestIsAssignableで判定するのは、あるスタッフがある依頼を配達できるかどうかで、
 * このメソッドで判定するのは、ある依頼が配達できないその理由となっている。
 * ある依頼を各スタッフが配達できない理由は様々であるが、それを一つの理由としてまとめ上げる必要がある。
 * なので、まとめづらい。
 *
 * @param request
 * @param carryStaffs
 * @param schedules
 * @param territories
 * @returns
 */
export function getExcludedReason(
  request: ExtendedRawRequest,
  carryStaffs: RawCarryStaff[],
  schedules: SimpleCarryStaffSchedule[],
  territories: RawTerritory[],
  options?: {
    filterType: FilterType;
  }
): keyof typeof excludedReasonCodes {
  // filterTypeが"loose"の場合にはスケジュールに関する制限は無視する
  const filterType = options?.filterType || "default";

  if (request.carry_staff_id != null) {
    return excludedReasonCodes.ALREADY_ASSIGNED;
  }

  if (isNotNeedDeliver(request.status)) {
    return excludedReasonCodes.NEED_NOT_DELIVER;
  }

  const schdulesInvolvedDeliveryTime = schedules.filter((sch) => {
    return isOverlapTimes(
      {
        from: new Date(request.dropoff.earliest),
        to: new Date(request.dropoff.latest),
      },
      { from: sch.from_time, to: sch.to_time }
    );
  });
  if (filterType == "default" && schdulesInvolvedDeliveryTime.length == 0) {
    return excludedReasonCodes.NO_SCHEDULE_DELIVER;
  }

  const includedTerritoryIds = territories
    .filter((ter) => {
      return isOverlap(
        ter.area_wkt,
        convertIntoPoint({
          lat: +request.receiver_lat,
          lng: +request.receiver_lng,
        })
      );
    })
    .map((ter) => ter.id);
  if (includedTerritoryIds.length == 0) {
    return excludedReasonCodes.NO_TERRITORY_DELIVER;
  }

  const schdulesInvolvedDeliveryTimeAndWithinTheCasTerritory =
    schdulesInvolvedDeliveryTime.filter((sch) => {
      const cas = carryStaffs.find((cas) => cas.id == sch.carry_staff_id);
      const territoryId = sch.territory_id || cas?.territory_id;
      if (territoryId == null) return false;

      return includedTerritoryIds.indexOf(territoryId) >= 0;
    });
  if (
    filterType == "default" &&
    schdulesInvolvedDeliveryTimeAndWithinTheCasTerritory.length == 0
  ) {
    return excludedReasonCodes.NO_SCHEDULE_DELIVER_AND_TERRITORY;
  }

  if (isNeedPickup(request.status)) {
    const schedulesInvolvedPickupTime = schedules.filter((sch) => {
      return isOverlapTimes(
        {
          from: new Date(request.pickup.earliest),
          to: new Date(request.pickup.latest),
        },
        { from: sch.from_time, to: sch.to_time }
      );
    });
    if (filterType == "default" && schedulesInvolvedPickupTime.length == 0) {
      return excludedReasonCodes.NO_SCHEDULE_PICKUP;
    }

    const includedTerritoryIds = territories
      .filter((ter) => {
        return isOverlap(
          ter.area_wkt,
          convertIntoPoint({
            lat: +request.sender_lat,
            lng: +request.sender_lng,
          })
        );
      })
      .map((ter) => ter.id);
    if (includedTerritoryIds.length == 0) {
      return excludedReasonCodes.NO_TERRITORY_PICKUP;
    }

    const schdulesInvolvedPickupTimeAndWithinTheCasTerritory =
      schedulesInvolvedPickupTime.filter((sch) => {
        const cas = carryStaffs.find((cas) => cas.id == sch.carry_staff_id);
        const territoryId = sch.territory_id || cas?.territory_id;
        if (territoryId == null) return false;

        return includedTerritoryIds.indexOf(territoryId) >= 0;
      });
    if (
      filterType == "default" &&
      schdulesInvolvedPickupTimeAndWithinTheCasTerritory.length == 0
    ) {
      return excludedReasonCodes.NO_SCHEDULE_PICKUP_AND_TERRITORY;
    }

    // 集荷場所と配達場所が別の担当エリアになっている場合など
    // (もちろんそれぞれの時刻でそのそれぞれの担当エリアに割り当てられている配達スタッフがいればアサイン可能だけど)
    const casIdsCanPickupAndDeliver = getIntersection(
      new Set(
        schdulesInvolvedDeliveryTimeAndWithinTheCasTerritory.map(
          (sch) => sch.carry_staff_id
        )
      ),
      new Set(
        schdulesInvolvedPickupTimeAndWithinTheCasTerritory.map(
          (sch) => sch.carry_staff_id
        )
      )
    );
    if (casIdsCanPickupAndDeliver.size == 0) {
      return excludedReasonCodes.NO_CARRY_STAFF_CAN_PICKUP_AND_DELIVER;
    }
  }

  return DEFAULT_EXCLUDED_REASON_CODE;
}

export interface ExcludedRequestReasonsParams {
  apiType: SelectableApiType;
  targetDate: string;
  requests: RawRequest[];
  carryStaffs: RawCarryStaff[];
  schedules: SimpleCarryStaffSchedule[];
  territories: RawTerritory[];
  routesApiParamSettings: RawRoutesApiParamSetting[];
}

export type ExcludedRequestReasonsResponse = {
  [requestId: string]: string;
};

export function getCommonExcludedRequestReasons(
  params: ExcludedRequestReasonsParams
) {
  const {
    targetDate,
    requests,
    carryStaffs,
    schedules,
    territories,
    apiType,
    routesApiParamSettings,
  } = params;

  const routesApiParamSetting = selectApiParamSetting(
    apiType,
    routesApiParamSettings
  );

  const baseDateInfo = getCorrectedDateForRoutesApi(targetDate);

  const extendeRequests = convertIntoExtendedRequests(
    baseDateInfo.dateStr,
    requests,
    routesApiParamSetting
  );

  console.debug("[extendedRequests] ", extendeRequests);

  const assignableRequests = extendeRequests.filter((req) =>
    checkRequestIsDeliverable(req, carryStaffs, schedules, territories)
  );

  console.debug("[assignableRequests] ", assignableRequests);

  const assignableRequestIds = assignableRequests.map((req) => req.id);
  const unassignableRequests = extendeRequests.filter(
    (req) => assignableRequestIds.indexOf(req.id) < 0
  );

  console.debug("[unassignableRequests] ", unassignableRequests);

  const unassignableRequestReasonMap: ExcludedRequestReasonsResponse =
    unassignableRequests
      .map((req) => {
        const reasonCode = getExcludedReason(
          req,
          carryStaffs,
          schedules,
          territories
        );
        return {
          requestId: req.id,
          reason: excludedReasonLabels[reasonCode],
        };
      })
      .reduce((accum, current) => {
        accum[`request_${current.requestId}`] = current.reason;
        return accum;
      }, {});

  return unassignableRequestReasonMap;
}

export function isRouteAssignable(carryStaff: RawCarryStaff) {
  const { staff_type, can_route_delivery } = carryStaff;
  const { STAFF_TYPE_CODES } = carryStaffConst;

  const assignableTypes: (typeof STAFF_TYPE_CODES)[keyof typeof STAFF_TYPE_CODES][] =
    [STAFF_TYPE_CODES.anycarry, STAFF_TYPE_CODES.vendor];

  return (
    staff_type === STAFF_TYPE_CODES.route ||
    (assignableTypes.includes(staff_type) && can_route_delivery)
  );
}

export const getRangeDeliveryTimeSlotTimeLabel = (request: RawRequest) => {
  const deliveryTimeAt = new Date(request.delivery_time_at);

  if (!request.delivery_time_slot_start_time) {
    return format(deliveryTimeAt, "MM/dd HH:mm");
  }

  const deliveryTimeSlotStartTime = convertSameJstTimeFromUtc(
    request.delivery_time_slot_start_time
  );
  return `${format(deliveryTimeAt, "MM/dd")} ${format(
    deliveryTimeSlotStartTime!,
    "HH:mm"
  )} 〜 ${format(deliveryTimeAt, "HH:mm")}`;
};

export const LOC_KEY_JOINT_CHAR = "_" as const;

export const PICK_UP_LOC_KEY_MARK = "pickup" as const;
export const PICK_UP_LOC_KEY_PREFIX =
  `${PICK_UP_LOC_KEY_MARK}${LOC_KEY_JOINT_CHAR}` as const;
export type PickUpLocKey = `${typeof PICK_UP_LOC_KEY_PREFIX}${number}`;

export const DROP_OFF_LOC_KEY_MARK = "dropoff" as const;
export const DROP_OFF_LOC_KEY_PREFIX =
  `${DROP_OFF_LOC_KEY_MARK}${LOC_KEY_JOINT_CHAR}` as const;
export type DropOffLocKey = `${typeof DROP_OFF_LOC_KEY_PREFIX}${number}`;

export type LocKeyMark =
  | typeof PICK_UP_LOC_KEY_MARK
  | typeof DROP_OFF_LOC_KEY_MARK;

export type LocKey = PickUpLocKey | DropOffLocKey;

// Locationとすると、下記
// > Location インターフェイスは、関連付けられたオブジェクトの場所 (URL) を表します。
// https://developer.mozilla.org/ja/docs/Web/API/Location
// とかぶってしまうので、Loc
// (Pointとすると@turf/turfのものとかぶってしまう)
export interface Loc {
  // sender_${requests.id} or receiver_${requests.id} 形式の文字列
  // 配達先より前に配達元地点を経由していなければならないという制約を実現するために利用
  key: LocKey;
  lat: number;
  lng: number;
}

// 時間を利用するのは、各配達スタッフに割り当て後、2-opt法などを利用して準回路の近似解を探索する際の制約条件としてのみなので、
// (ある依頼をある配達スタッフにアサイン可能かどうか、という点に関してはReqKeyCandidateCarryStaffsMatrixを利用する)
// それ以外の場面(近似解を求める以外の場面)ではLocの型で問題ない、はず。
export type TimeSpecifiedLoc = Loc & {
  // earliest, latestともに"yyyy-MM-ddTHH:mm:ss.SSS+09:00"形式の文字列想定
  earliest: string;
  latest: string;
};

// ExpandedRawRequest と名前をつけてしまった(ほぼ同じような)別のインターフェースがあるので、
// 被らないようにこちらは Extended。
// ExpandedRawRequest とつけられている型をこちらに直したい。
// ready_time_at, delivery_time_at はそのまま。
// pickup の earliest と latest, dropoff の earliest と latestは補正されている。
export type ExtendedRawRequest = RawRequest & {
  pickup: TimeSpecifiedLoc;
  dropoff: TimeSpecifiedLoc;
};

export function convertIntoExtendedRequests(
  correctedDate: string,
  requests: RawRequest[],
  routesApiParamSetting: RoutesApiParamSetting
) {
  const _requests: ExtendedRawRequest[] = requests.map((req) => {
    const { pickup, dropoff } = getTimeSpecifiedLocsWith(
      correctedDate,
      req,
      routesApiParamSetting
    );
    return {
      ...req,
      pickup,
      dropoff,
    };
  });
  return _requests;
}

function createPickUpLoc(
  request: RawRequest,
  routesApiParamSetting: RoutesApiParamSetting,
  correctedDate: string
) {
  const readyTimeAt = new Date(request.ready_time_at);
  // 一旦、引いたり足したりすることによって日付を跨いでしまう場合のことについては考えずに実装
  let earliestRta: Date;
  let latestRta: Date;
  console.debug("[existsPickupTime]", existsPickupTime(request));
  console.debug("[existsPickupTime]", request.pickup_start_time);
  if (existsPickupTime(request)) {
    const pickupStartTime = request.pickup_start_time.substring(11, 16);
    earliestRta = subSeconds(
      new Date(`${correctedDate}T${pickupStartTime}:00.000+09:00`),
      routesApiParamSetting.tolerant_seconds_before_pickup_at
    );
    console.debug("[earliestRta]", earliestRta);

    const pickupEndTime = request.pickup_end_time.substring(11, 16);
    latestRta = addSeconds(
      new Date(`${correctedDate}T${pickupEndTime}:00.000+09:00`),
      routesApiParamSetting.tolerant_seconds_after_pickup_at
    );
    console.debug("[latestRta]", latestRta);
  } else {
    earliestRta = subSeconds(
      readyTimeAt,
      routesApiParamSetting.tolerant_seconds_before_pickup_at
    );
    latestRta = addSeconds(
      readyTimeAt,
      routesApiParamSetting.tolerant_seconds_after_pickup_at
    );
  }

  const pickUpLoc: TimeSpecifiedLoc = {
    key: `${PICK_UP_LOC_KEY_PREFIX}${request.id}`,
    earliest: `${correctedDate}T${getHHmmTimeStrFromDate(earliestRta)}`,
    latest: `${correctedDate}T${getHHmmTimeStrFromDate(latestRta)}`,
    lat: +request.sender_lat,
    lng: +request.sender_lng,
  };
  return pickUpLoc;
}

function createDropOffLoc(
  request: RawRequest,
  correctedDate: string,
  routesApiParamSetting: RoutesApiParamSetting
) {
  let earliestDta: Date;
  let latestDta: Date;
  if (
    request.delivery_type == requestConst.DELIVERY_TYPE_CODES.route &&
    // DMS経由では設定されることになるが、テストデータなどで存在しないものがあったりするので
    // (テーブル上でNOT NULL製薬がついているわけではないので)
    // 存在確認をし、もしなければdelivery_time_atを基準とする
    existsDtSlot(request)
  ) {
    // 2001-01-01THH:mm:00.000...
    const dtsStartTime = request.delivery_time_slot_start_time;
    const earliestDHhMmStr = dtsStartTime.substring(11, 16);
    earliestDta = subSeconds(
      new Date(`${correctedDate}T${earliestDHhMmStr}:00.000+09:00`),
      routesApiParamSetting.tolerant_seconds_before_dropoff_at
    );

    const dtsEndTime = request.delivery_time_slot_end_time;
    const latestDHhMmStr = dtsEndTime.substring(11, 16);
    latestDta = addSeconds(
      new Date(`${correctedDate}T${latestDHhMmStr}:00.000+09:00`),
      routesApiParamSetting.tolerant_seconds_after_dropoff_at
    );
  } else {
    const dta = new Date(request.delivery_time_at);
    earliestDta = subSeconds(
      dta,
      routesApiParamSetting.tolerant_seconds_before_dropoff_at
    );
    latestDta = addSeconds(
      dta,
      routesApiParamSetting.tolerant_seconds_after_dropoff_at
    );
  }
  const dropOffLoc: TimeSpecifiedLoc = {
    key: `${DROP_OFF_LOC_KEY_PREFIX}${request.id}`,
    earliest: `${correctedDate}T${getHHmmTimeStrFromDate(earliestDta)}`,
    latest: `${correctedDate}T${getHHmmTimeStrFromDate(latestDta)}`,
    lat: +request.receiver_lat,
    lng: +request.receiver_lng,
  };
  return dropOffLoc;
}

export function getTimeSpecifiedLocsWith(
  correctedDate: string,
  request: RawRequest,
  routesApiParamSetting: RoutesApiParamSetting
) {
  const pickUpLoc = createPickUpLoc(
    request,
    routesApiParamSetting,
    correctedDate
  );
  const dropOffLoc = createDropOffLoc(
    request,
    correctedDate,
    routesApiParamSetting
  );

  return {
    pickup: pickUpLoc,
    dropoff: dropOffLoc,
  };
}

/**
 * requestにdelivery_time_slot_start_timeとdelivery_time_slot_end_timeの両方が設定されているかどうかを判定する関数.
 *
 * @param request
 * @returns
 */
function existsDtSlot(request: RawRequest) {
  if (
    request.delivery_time_slot_start_time == null ||
    request.delivery_time_slot_start_time.length == 0
  ) {
    return false;
  }
  if (
    request.delivery_time_slot_end_time == null ||
    request.delivery_time_slot_end_time.length == 0
  ) {
    return false;
  }

  return true;
}

function existsPickupTime(request: RawRequest) {
  if (
    request.pickup_start_time == null ||
    request.pickup_start_time.length == 0 ||
    request.pickup_end_time == null ||
    request.pickup_end_time.length == 0
  ) {
    return false;
  }
  return true;
}

function getHHmmTimeStrFromDate(date: Date) {
  return format(date, "HH:mm:00.000+09:00");
}

export interface BaseDateInfo {
  isFuture: boolean;
  dateStr: string;
  startTime: Date;
  endTime: Date;
}

/**
 * Routes API用に、対象日から補正後の日付を取得するメソッド.
 *
 * 対象日が今日以前の日付であれば、今日、
 * 明日以降の日付であれば、その日、を返す。
 *
 * @param targetDate
 * @returns
 */
export function getCorrectedDateForRoutesApi(srcDate: string): BaseDateInfo {
  validateDateStr(srcDate);

  // もし対象日が過去であれば、実行日の日付に補正
  const isFuture = checkIsFuture(srcDate);
  let correctedDate = isFuture ? srcDate : getTodayStr();

  const dayTimeWindow = getBeginningAndEndOfDay(correctedDate);

  return {
    isFuture,
    dateStr: correctedDate,
    startTime: dayTimeWindow.beginningOfDay,
    endTime: dayTimeWindow.endOfDay,
  };
}
