import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import Colors from "../../../constants/BootstrapColors";
import { COLORS } from "../../../constants/Colors";
import type { RawCarryStaffRequestSequence } from "../../../interfaces/entities";
import I18n from "../../../packs/i18n/i18n";
import "../../../packs/i18n/ja";
import { RequestModel } from "../../../models/RequestModel";
import { getLongDeliveryTimeLabel } from "../../../utils/DeliveryTimeLabelUtils";
import { formatIso8601Time } from "../../../utils/TimeUtils";
import { getKeyByRequestStatus } from "../utils/Utils";
import searchConditionsStore from "../stores/SearchConditionsStore";
import TimeGauge from "../../ui/TimeGauge";
interface Props {
  selectedItem: RequestModel | undefined;
  items: RequestModel[];
  onSelect: (requestId: number) => void;
}

class RequestTableBody extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleClickRequest(requestId: number) {
    this.props.onSelect(requestId);
  }

  render() {
    const { items, selectedItem } = this.props;

    // 依頼をクリックした際にそのデータにセンタリングさせることで
    // 地図範囲に存在するデータが変わりindexも変わってしまうため
    // リスト上で選択したデータの位置を見失うことがあり、
    // これを防ぐために選択したデータをリストの最初に配置する。
    if (selectedItem) {
      const dataIndex = items.findIndex((req) => req.id == selectedItem.id);
      if (dataIndex >= 0) {
        const selectedData = items.splice(dataIndex, 1)[0];
        items.unshift(selectedData);
      }
    }

    return (
      <tbody>
        {items.map((req) => {
          const statusKey = getKeyByRequestStatus(req.status);
          const isSelected = selectedItem && req.id == selectedItem.id;
          const visibleSequenceNumber =
            searchConditionsStore.carryStaffRequestsConditions
              .visibleSequenceNumber;
          const sequencesMap =
            req.carryStaffRequestSequences &&
            req.carryStaffRequestSequences.length > 0
              ? req.carryStaffRequestSequences.reduce(
                  (accum, current) => {
                    accum[current.destination_type] = current.sequence;
                    return accum;
                  },
                  {} as {
                    [key in RawCarryStaffRequestSequence["destination_type"]]: number;
                  }
                )
              : undefined;

          return (
            <tr
              key={req.shortCode}
              style={styles.tableRow}
              onClick={() => this.handleClickRequest(req.id)}
            >
              <td>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <div className="mb-2">
                      {visibleSequenceNumber && sequencesMap ? (
                        <div className="row ml-auto">
                          {sequencesMap.sender != null && (
                            <div
                              style={styles.senderSequenceWrapperStyles({
                                isSelectedItem: isSelected,
                              })}
                            >
                              {sequencesMap.sender}
                            </div>
                          )}
                          <div className="mx-2 d-flex align-items-center">
                            <i className="fa fa-arrow-right" />
                          </div>
                          {sequencesMap.receiver != null && (
                            <div
                              style={styles.receiverSequenceWrapperStyles({
                                isSelectedItem: isSelected,
                              })}
                            >
                              {sequencesMap.receiver}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={styles.indexWrapperStyles({
                            isSelectedItem: isSelected,
                          })}
                        >
                          {req.shortCode ?? "-"}
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <p style={styles.itemFont}>依頼ID : </p>
                      <a href={`/requests/${req.id}/edit`}>{req.id}</a>
                    </div>
                    <div className="mb-1">
                      <p style={styles.itemFont}>ステータス : </p>
                      <span
                        className="request-status-label badge"
                        title={`${statusKey}`}
                      >
                        {I18n.t(`enums.request.status.${statusKey}`)}
                      </span>
                    </div>
                    <div className="mb-2">
                      <p style={styles.itemFont}>配達スタッフ : </p>
                      {req.carryStaff ? req.carryStaff.name : "(未アサイン)"}
                    </div>
                  </div>
                  <div style={{ flex: 2 }}>
                    <div className="mb-1">
                      <span style={styles.itemFont}>店舗様 : </span>
                      {req.sender.name}
                      <br />
                      {req.sender.address}
                    </div>
                    <div className="mb-1">
                      <span style={styles.itemFont}>お客様 : </span>
                      {req.receiver.name}
                      <br />
                      {req.receiver.address}
                    </div>
                    {req.pickupStartTime && req.pickupEndTime ? (
                      <div style={{ display: "flex" }} className="mb-1">
                        <div style={{ flex: 1 }}>
                          <p style={styles.itemFont}>集荷時間 : </p>
                          {formatIso8601Time(
                            req.pickupStartTime.toISOString()
                          )}{" "}
                          〜{" "}
                          {formatIso8601Time(req.pickupEndTime.toISOString())}
                        </div>
                      </div>
                    ) : null}
                    <div style={{ display: "flex" }}>
                      <TimeGauge
                        limitAt={req.deliveryTimeAt!.toISOString()}
                        type="circle"
                        limitTitle="配達希望時刻"
                        tooltipPosition="top"
                      />
                      <div style={{ flex: 1 }}>
                        <p style={styles.itemFont}>配達希望時間 : </p>
                        {getLongDeliveryTimeLabel(req)}
                      </div>
                      {req.estimatedDeliveryTime ? (
                        <div style={{ flex: 1 }}>
                          <p style={styles.itemFont}>到着予想時間 : </p>
                          {formatIso8601Time(req.estimatedDeliveryTime)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

const styles = {
  indexWrapperStyles: ({
    isSelectedItem,
  }: {
    isSelectedItem: boolean | undefined;
  }): CSSProperties => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${
      isSelectedItem ? Colors.WARNING_COLOR : Colors.INFO_COLOR
    }`,
    color: isSelectedItem ? Colors.WARNING_COLOR : Colors.INFO_COLOR,
    fontSize: "0.8rem",
    fontWeight: "bold",
    width: "36px",
    height: "24px",
  }),
  senderSequenceWrapperStyles: ({
    isSelectedItem,
  }: {
    isSelectedItem: boolean | undefined;
  }): CSSProperties => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${
      isSelectedItem ? Colors.WARNING_COLOR : COLORS.startMarker
    }`,
    borderRadius: "50%",
    color: isSelectedItem ? Colors.WARNING_COLOR : COLORS.startMarker,
    fontSize: "0.8rem",
    fontWeight: "bold",
    width: "24px",
    height: "24px",
  }),
  receiverSequenceWrapperStyles: ({
    isSelectedItem,
  }: {
    isSelectedItem: boolean | undefined;
  }): CSSProperties => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${
      isSelectedItem ? Colors.WARNING_COLOR : COLORS.goalMarker
    }`,
    color: isSelectedItem ? Colors.WARNING_COLOR : COLORS.goalMarker,
    fontSize: "0.8rem",
    fontWeight: "bold",
    width: "24px",
    height: "24px",
  }),
  itemFont: {
    fontWeight: "bold",
    margin: 0,
  } as CSSProperties,
  tableRow: {
    fontSize: "0.8rem",
    margin: 0,
    cursor: "pointer",
  } as CSSProperties,
};

export default observer(RequestTableBody);
