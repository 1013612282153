import { format } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import { ScheduleStyleConst } from "./Constants";
import {
  RawCarryStaffWithSchedules,
  RawTerritory,
  Schedule,
} from "./Interfaces";

interface Props {
  carryStaff: RawCarryStaffWithSchedules;
  schedule: Schedule;
  selectedDate: string;
  territories: RawTerritory[];
  leftRatio: number;
  widthRatio: number;
  baseElementWidth: number;
  dragGhostElement: HTMLDivElement | null;
  canUpdate: boolean;
  isCreateDragSchedule?: boolean;
  onSelectSchedule: (schedule: Schedule) => void;
  onSaveSchedule: (schedule: Schedule) => void;
  calcDraggingEdgeValue: (newRatio: number) => [number, Date];
}

interface State {
  leftRatio: number;
  widthRatio: number;
  fromTime: Date;
  toTime: Date;
  startX: number;
  isDragging: boolean;
}

const marginTop =
  (ScheduleStyleConst.ROW_BODY_HEIGHT -
    ScheduleStyleConst.SCHEDULE_ITEM_HEIGHT) /
  2;

class ScheduleBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      leftRatio: props.leftRatio,
      widthRatio: props.widthRatio,
      fromTime: props.schedule.from_time,
      toTime: props.schedule.to_time,
      startX: 0,
      isDragging: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    // スケジュール変更時にリセット
    if (prevProps.schedule !== this.props.schedule) {
      this.setState({
        leftRatio: this.props.leftRatio,
        widthRatio: this.props.widthRatio,
        fromTime: this.props.schedule.from_time,
        toTime: this.props.schedule.to_time,
      });
    }
  }

  handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const startX = e.clientX;
    if (this.props.dragGhostElement) {
      e.dataTransfer.setDragImage(this.props.dragGhostElement, 0, 0);
    }
    this.setState({
      startX: startX,
      isDragging: true,
    });
  };

  handleDragEnd = () => {
    const { schedule, onSaveSchedule } = this.props;
    const { fromTime, toTime, isDragging } = this.state;
    if (!isDragging) return;

    this.setState({ isDragging: false });
    onSaveSchedule({
      ...schedule,
      from_time: fromTime,
      to_time: toTime,
    });
  };

  /*
   * スケジュールバーの左端をドラッグ中の処理
   */
  handleLeftDrag = (e: React.DragEvent<HTMLDivElement>) => {
    const { leftRatio, widthRatio, baseElementWidth } = this.props;
    const { startX, isDragging } = this.state;
    if (!isDragging) return;

    const leftChangeX = startX - e.clientX; // ドラッグによる変化量
    const position = leftRatio - (leftChangeX / baseElementWidth) * 100;
    const [snappedRatio, fromTime] = this.props.calcDraggingEdgeValue(position);
    if (snappedRatio >= 0 && snappedRatio < leftRatio + widthRatio) {
      this.setState({
        leftRatio: snappedRatio,
        widthRatio: leftRatio + widthRatio - snappedRatio,
        fromTime: fromTime,
      });
    }
  };

  /*
   * スケジュールバーの右端をドラッグ中の処理
   */
  handleRightDrag = (e: React.DragEvent<HTMLDivElement>) => {
    const { leftRatio, widthRatio, baseElementWidth } = this.props;
    const { startX, isDragging } = this.state;
    if (!isDragging) return;

    const rightChangeX = startX - e.clientX; // ドラッグによる変化量
    const position =
      leftRatio + widthRatio - (rightChangeX / baseElementWidth) * 100;
    const [snappedRatio, toTime] = this.props.calcDraggingEdgeValue(position);
    if (snappedRatio > this.state.leftRatio && snappedRatio <= 100) {
      this.setState({
        widthRatio: snappedRatio - leftRatio,
        toTime: toTime,
      });
    }
  };

  /*
   * スケジュールバーごとドラッグ中の処理
   */
  handleScheduleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    const { leftRatio, widthRatio, baseElementWidth } = this.props;
    const { startX, isDragging } = this.state;
    if (!isDragging) return;

    const changeX = startX - e.clientX; // ドラッグによる変化量
    // 変化量を要素内での単位に変換し、元の値に（移動方向に合わせて）増減することで位置を決定する
    const dragPositionLeft = leftRatio - (changeX / baseElementWidth) * 100;
    const dragPositionRight =
      leftRatio + widthRatio - (changeX / baseElementWidth) * 100;
    const [snappedRatioLeft, fromTime] =
      this.props.calcDraggingEdgeValue(dragPositionLeft);
    const [snappedRatioRight, toTime] =
      this.props.calcDraggingEdgeValue(dragPositionRight);
    if (
      snappedRatioLeft >= 0 &&
      snappedRatioRight >= 0 &&
      snappedRatioLeft <= 100 &&
      snappedRatioRight <= 100
    ) {
      this.setState({
        leftRatio: snappedRatioLeft,
        fromTime: fromTime,
        toTime: toTime,
      });
    }
  };

  renderScheduleBar() {
    const { schedule, canUpdate, onSelectSchedule } = this.props;
    const { leftRatio, widthRatio, fromTime, toTime } = this.state;

    return (
      <div
        style={{
          position: "absolute",
          left: `${leftRatio}%`,
          width: `${widthRatio}%`,
          marginTop: marginTop,
          height: ScheduleStyleConst.SCHEDULE_ITEM_HEIGHT,
          border: "1px solid rgb(3, 155, 229)",
          borderRadius: 5,
          whiteSpace: "nowrap",
          overflowY: "hidden",
          cursor: "pointer",
          display: "flex",
        }}
        data-toggle="tooltip"
        data-original-title={`${format(fromTime, "HH:mm")}〜${format(
          toTime,
          "HH:mm"
        )}`}
        onClick={() => {
          onSelectSchedule(schedule);
        }}
        // 既存要素上では新規スケジュール作成のドラッグ関連操作を全て無効にしておく
        onDragStart={(event) => event.stopPropagation()}
        onDrag={(event) => event.stopPropagation()}
        onDragEnd={(event) => event.stopPropagation()}
      >
        <div
          style={{
            position: "absolute",
            width: "20px",
            height: ScheduleStyleConst.SCHEDULE_ITEM_HEIGHT,
            cursor: "col-resize",
            left: 0,
            zIndex: 100,
          }}
          draggable={canUpdate}
          onDragStart={canUpdate ? this.handleDragStart : () => {}}
          onDrag={this.handleLeftDrag}
          onDragEnd={this.handleDragEnd}
        ></div>
        <div
          style={{ flex: 1 }}
          draggable={canUpdate}
          onDragStart={canUpdate ? this.handleDragStart : () => {}}
          onDrag={this.handleScheduleDrag}
          onDragEnd={this.handleDragEnd}
        >
          <div
            style={{
              color: "rgb(3, 155, 229)",
              fontSize: 12,
              position: "absolute",
              top: -2,
              left: 2,
            }}
          >
            <span>
              {format(fromTime, "HH:mm")}〜{format(toTime, "HH:mm")}
            </span>
            <br />
            <span>{schedule.territory_name ?? "担当エリア: -"}</span>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            width: "20px",
            height: ScheduleStyleConst.SCHEDULE_ITEM_HEIGHT,
            cursor: "col-resize",
            right: 0,
          }}
          draggable={canUpdate}
          onDragStart={canUpdate ? this.handleDragStart : () => {}}
          onDrag={this.handleRightDrag}
          onDragEnd={this.handleDragEnd}
        ></div>
      </div>
    );
  }

  renderNewScheduleBar() {
    const { leftRatio, widthRatio, fromTime, toTime } = this.state;
    const territory = this.props.territories.find(
      (ter) => ter.id == this.props.carryStaff.territory_id
    );
    return (
      <div
        style={{
          position: "absolute",
          left: `${leftRatio}%`,
          width: `${widthRatio}%`,
          marginTop: marginTop,
          height: ScheduleStyleConst.SCHEDULE_ITEM_HEIGHT,
          border: "1px solid rgb(3, 155, 229)",
          borderRadius: 5,
          whiteSpace: "nowrap",
          overflowY: "hidden",
          cursor: "pointer",
          display: "flex",
        }}
        data-toggle="tooltip"
        data-original-title={`${format(fromTime, "HH:mm")}〜${format(
          toTime,
          "HH:mm"
        )}`}
      >
        <div
          style={{
            color: "rgb(3, 155, 229)",
            fontSize: 12,
            position: "absolute",
            top: -2,
            left: 2,
          }}
        >
          <span>
            {format(fromTime, "HH:mm")}〜{format(toTime, "HH:mm")}
          </span>
          <br />
          <span>{territory ? territory.name : "担当エリア: -"}</span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.props.isCreateDragSchedule
          ? this.renderNewScheduleBar()
          : this.renderScheduleBar()}
      </>
    );
  }
}

export default observer(ScheduleBar);
