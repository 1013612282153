import { Coords } from "google-map-react";
import { observable } from "mobx";
import { requestConst } from "../constants/Request";
import { AddressPoint } from "../interfaces/AddressPoint";
import { RawCarryStaffRequestSequence } from "../interfaces/entities";
import Request from "../interfaces/Request";
import RequestsResponse from "../interfaces/RequestsResponse";
import { CarryStaffModel } from "../models/CarryStaffModel";
import { convertNumToShortCode } from "../utils/NumberUtils";
import { convertSameJstTimeFromUtc } from "../utils/TimeUtils";
import { CarryStaffRequestSequenceModel } from "./CarryStaffRequestSequenceModel";

export class RequestModel implements Request {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  shortCode: string;
  statusText: string;
  status: (typeof requestConst.STATUSES)[keyof typeof requestConst.STATUSES];
  sender: AddressPoint;
  receiver: AddressPoint;
  carryStaff: CarryStaffModel | null;
  carryStaffId: number | null;
  deliveryTimeAt: Date | null;
  readyTimeAt: Date | null;
  pickupStartTime: Date | null;
  pickupEndTime: Date | null;
  deliveryType: string | null;
  createdById: string | null;
  otherSystemId: string | null;
  isReserved: boolean | null;
  visible: boolean | null;
  routes: Coords[] | null;
  deliveryTimeSlotName: string | null;
  deliveryTimeSlotStartTime: Date | null;
  deliveryTimeSlotEndTime: Date | null;
  isDeliveryTimeSpecified: boolean | null;
  carryStaffRequestSequences: RawCarryStaffRequestSequence[] | null;
  estimatedDeliveryTime: string | null;
  estimatedPickupTime: string | null;

  constructor(response: RequestsResponse) {
    this.id = response.id;
    this.shortCode = convertNumToShortCode(response.id);
    this.key = response.id;
    this.sender = {
      ...response.sender,
      lat: +response.sender.lat,
      lng: +response.sender.lng,
    };
    this.receiver = {
      ...response.receiver,
      lat: +response.receiver.lat,
      lng: +response.receiver.lng,
    };
    this.status = response.status;
    this.statusText = response.status_text;
    this.deliveryTimeAt = this.getTimeOrNull(response.delivery_time_at);
    this.readyTimeAt = this.getTimeOrNull(response.ready_time_at);
    this.pickupStartTime = convertSameJstTimeFromUtc(
      response.pickup_start_time
    );
    this.pickupEndTime = convertSameJstTimeFromUtc(response.pickup_end_time);
    this.carryStaff = response.carry_staff
      ? new CarryStaffModel(response.carry_staff)
      : null;
    this.carryStaffId = response.carry_staff ? response.carry_staff.id : null;
    this.deliveryType = response.delivery_type;
    this.createdById = response.created_by_id;
    this.otherSystemId = response.other_system_id;
    this.isReserved = response.is_reserved;
    this.visible = true;
    this.routes = response.routes;
    this.isDeliveryTimeSpecified = response.is_delivery_time_specified || false;
    this.deliveryTimeSlotStartTime = convertSameJstTimeFromUtc(
      response.delivery_time_slot_start_time
    );
    this.deliveryTimeSlotEndTime = convertSameJstTimeFromUtc(
      response.delivery_time_slot_end_time
    );
    this.deliveryTimeSlotName = response.delivery_time_slot_name || null;

    this.carryStaffRequestSequences =
      response.carry_staff_request_sequences_for_today
        ? response.carry_staff_request_sequences_for_today
        : null;
    this.estimatedDeliveryTime =
      response.carry_staff_request_sequences_for_today?.find(
        (seq) =>
          seq.destination_type == "receiver" &&
          seq.carry_staff_id == this.carryStaffId
      )?.work_start_time || null;
    this.estimatedPickupTime =
      response.carry_staff_request_sequences_for_today?.find(
        (seq) =>
          seq.destination_type == "sender" &&
          seq.carry_staff_id == this.carryStaffId
      )?.work_start_time || null;
  }

  public isEarlyStage() {
    // ルート配達用ステータス未対応
    // 店舗確認済み、未確認または確認済み
    const isEarly =
      this.status == requestConst.STATUSES.wait_store ||
      this.status == requestConst.STATUSES.uncheck ||
      this.status == requestConst.STATUSES.checked;
    return isEarly;
  }

  public isDeliveryStage() {
    // ルート配達用ステータス未対応
    // ピックアップ完了以降
    return this.status == requestConst.STATUSES.pickup;
  }

  public isDoneStage() {
    // ルート配達用ステータス未対応
    // ピックアップ完了以降
    const isDone =
      this.status == requestConst.STATUSES.delivered ||
      this.status == requestConst.STATUSES.failed ||
      this.status == requestConst.STATUSES.cancel ||
      this.status == requestConst.STATUSES.store_cancel;
    return isDone;
  }

  private getTimeOrNull(timeText: string | null) {
    return timeText ? new Date(timeText) : null;
  }
}
