import React from "react";
import { RelationType } from "./Interfaces";

interface Props {
  type: "inner" | "outer";
  onClick: (relationType: RelationType) => void;
}

export default class AndOrButtons extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let wrapperClassNames = "d-flex justify-content-start";
    wrapperClassNames += this.props.type === "outer" ? " ml-3 mt-1" : " ml-2";

    let buttonWrapperClassNames = "btn btn-primary btn-sm mr-2";
    if (this.props.type === "inner") {
      buttonWrapperClassNames += " small";
    }

    const style =
      this.props.type === "inner" ? styles.innerButton : styles.outerButton;

    return (
      <div className={wrapperClassNames}>
        <button
          className={buttonWrapperClassNames}
          style={style}
          onClick={() => this.props.onClick("and")}
        >
          <i className="fas fa-plus mr-1" style={{ color: "white" }}></i>
          かつ
        </button>
        <button
          className={buttonWrapperClassNames}
          style={style}
          onClick={() => this.props.onClick("or")}
        >
          <i className="fas fa-plus mr-1" style={{ color: "white" }}></i>
          または
        </button>
      </div>
    );
  }
}

const styles = {
  outerButton: {
    lineHeight: 1.5,
  },
  innerButton: {
    lineHeight: 0.5,
  },
};
