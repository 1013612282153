import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import { axiosGet, axiosPost } from "../utils/AxiosClient";

Modal.setAppElement("#wrapper");

interface CognitoUser {
  user_name: string;
  user_status: string;
  phone_number: string;
  phone_number_verified: string;
}

interface Props {
  canUpdate: boolean;
}

export default function CognitoUserSettingForm({ canUpdate }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>();
  const [user, setUser] = useState<CognitoUser>();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage("");

    if (searchText) {
      try {
        const response = await axiosGet.get(
          `/api/cognito_user_settings/search?user=${searchText}`
        );
        if (response.data.user) {
          setUser(response.data.user);
        } else {
          setErrorMessage(response.data?.message || "エラーが発生しました。");
        }
        toast.success("検索完了");
      } catch (e) {
        console.log(e);
        const message = e.response?.data?.message || "エラーが発生しました。";
        setErrorMessage(message);
        setUser(undefined);
      } finally {
      }
    }
  };

  const onUpdateSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (user?.user_name) {
      try {
        const response = await axiosPost.put(
          `/api/cognito_user_settings?user_name=${user.user_name}`
        );
        toast.success("更新しました。アプリからログインを行なってください。");
      } catch (e) {
        console.log(e);
        const message = e.response?.data?.message || "エラーが発生しました。";
        setErrorMessage(message);
      } finally {
      }
    }
  };

  return (
    <>
      <Card className="shadow mb-2">
        <Card.Header>未認証アプリユーザー検索</Card.Header>
        <Card.Body>
          <form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formRequiredMinutes">
              <Form.Control
                type="text"
                placeholder="検索対象者の名前を入力"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Form.Group>
            <button className="btn btn-primary">検索</button>
          </form>
        </Card.Body>
      </Card>
      <Card className="shadow mb-4">
        <Card.Body>
          {errorMessage && <div>{errorMessage}</div>}
          {user && (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>名前</th>
                    <th>電話番号</th>
                    <th>アカウント状態</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{user.user_name}</th>
                    <th>{user.phone_number}</th>
                    <th>{user.user_status}</th>
                    <th>
                      {canUpdate && (
                        <button
                          className="btn btn-primary"
                          onClick={() => openModal()}
                        >
                          編集
                        </button>
                      )}
                    </th>
                  </tr>
                </tbody>
              </Table>
              <Modal
                isOpen={isOpen}
                onRequestClose={() => closeModal()}
                style={modalStyles}
              >
                <form onSubmit={onUpdateSubmit}>
                  <label>アカウントの有効化</label>
                  <label>
                    アカウントを有効化した場合、そのアカウントでログインができるようになります
                  </label>
                  <label>
                    有効化した後、アプリからログイン処理を行うことでユーザーが作成されます
                  </label>
                  <button className="btn btn-primary">有効にする</button>
                </form>
              </Modal>
            </>
          )}
        </Card.Body>
      </Card>
      <ToastContainer />
    </>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
