import { observer } from "mobx-react";
import React, { useState } from "react";
import Modal from "react-modal";
import { pageStore } from "../stores";
import { assign, existsAssigningChanged } from "../utils";

interface Props {
  isOpen: boolean;
  onCloseModal: (success: boolean) => Promise<void>;
}

function AssigningConfirmModal(props: Props) {
  const { isOpen, onCloseModal } = props;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSubmit = async () => {
    if (!existsAssigningChanged(pageStore.requests)) {
      setErrorMessage("アサインを変更してください。");
      return;
    }

    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await assign(
        pageStore.requests
          .filter((req) => req.carry_staff_id != req.org_carry_staff_id)
          .map((req) => ({
            requestId: req.id,
            carryStaffId: req.carry_staff_id
          }))
      );
      setLoading(false);
      onCloseModal(true);
    } catch (err) {
      setLoading(false);
      setErrorMessage(
        err?.response?.data?.message || "問題が発生し、登録に失敗しました。"
      );
    }
  };

  const handleCloseModal = () => {
    setLoading(false);
    setErrorMessage(null);
    onCloseModal(false);
  };

  if (!existsAssigningChanged(pageStore.requests)) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => handleCloseModal()}
        style={modalStyles}
      >
        <div className="h5 mb-4">アサイン内容確認</div>
        <div className="d-flex flex-column">
          <div>アサインが変更された依頼がありません。</div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary mr-2"
              onClick={() => handleCloseModal()}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => handleCloseModal()}
      style={modalStyles}
    >
      <div className="h5 mb-4 d-flex align-items-center">
        アサイン内容確認
        {loading && (
          <div className="spinner-border spinner-border-sm ml-3" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
      {errorMessage && (
        <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
          {errorMessage}
        </p>
      )}
      <div className="d-flex flex-column">
        <div>下記の内容でアサインを実行しますか？</div>
        <div className="px-4 d-flex flex-column overflow-auto">
          {pageStore.requests
            .filter((req) => req.carry_staff_id !== req.org_carry_staff_id)
            .map((req) => {
              const carryStaff = pageStore.carryStaffs.find(
                (cas) => cas.id == req.carry_staff_id
              );
              return (
                <div key={req.id}>
                  依頼ID#{req.id}:{" "}
                  <span className="font-weight-bold">
                    {carryStaff ? carryStaff.name : "アサイン解除"}
                  </span>
                </div>
              );
            })}
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-secondary mr-2"
            disabled={loading}
            onClick={() => handleCloseModal()}
          >
            キャンセル
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={loading}
            onClick={async () => {
              await onSubmit();
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default observer(AssigningConfirmModal);

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    maxHeight: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26
  },
  overlay: {
    zIndex: 1050
  }
};
