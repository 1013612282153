import React from "react";
import {mapMarkerPlaceStyle} from '../../styles/StoreMarkerStyles'
import {infoWindowStyle, K_SIZE} from "../../styles/SenderMarkerStyles";
import Card from "react-bootstrap/Card";

interface Props {
  lat: number,
  lng: number,
  zoomLevel?: number,
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean,
}

interface State {
  showInfoWindow: boolean
}

export default class TemporaryAddressMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false
    };
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow;

    return (
      <div
        style={temporaryAddressMarkerStyle}
        onClick={this.onClickMarker.bind(this)}
      >
        <i className={"fa fa-building"}/>
        {showInfoWindow && (
          <Card
            style={infoWindowStyle}
          >
             住所(未確定)
          </Card>
        )}
      </div>
    )
  }

  private onClickMarker(event: Event) {
    this.setState({showInfoWindow: !this.state.showInfoWindow})
    event.preventDefault();
    event.stopPropagation();
  }
}

const temporaryAddressMarkerStyle = {
  ...mapMarkerPlaceStyle,
  borderColor: '#f44336'
};
