import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";

interface Props {
  isOpen: boolean;
  selectedFile: File | null;
  isProcessing: boolean;
  errorMessage: string | null;
  onRequestClose: () => void;
  onChangeSelectedFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

class BulkRegisterSchedulesModal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        style={modalStyles}
      >
        <h5 className="mb-4">スケジュール一括登録</h5>

        {this.props.errorMessage && (
          <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {this.props.errorMessage}
          </p>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.onSubmit();
          }}
        >
          <div className="mb-4">
            <div style={selectFileContainerStyle}>
              <label style={selectFileLabelStyle}>
                <span className="text-sm">
                  {this.props.selectedFile?.name || "ファイルを選択してください"}
                </span>
                <input
                  className="d-none"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                  onChange={this.props.onChangeSelectedFile}
                />
              </label>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <a
              className="btn btn-secondary mr-2"
              href="/carry_staff_schedules_imports/template"
            >
              テンプレートダウンロード
            </a>
            <button
              className="btn btn-primary"
              disabled={!this.props.selectedFile || this.props.isProcessing}
            >
              ファイルを取り込む
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default observer(BulkRegisterSchedulesModal);

const selectFileContainerStyle: React.CSSProperties = {
  borderWidth: "2px",
  borderRadius: "0.375rem",
  borderStyle: "dashed",
  borderColor: "#e5e7eb",
  display: "flex",
  justifyContent: "center",
};

const selectFileLabelStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  padding: "2.5rem 1.5rem",
  margin: 0,
  textAlign: "center",
  cursor: "pointer",
}

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
