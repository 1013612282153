import { format } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import { ScheduleConst } from "./Constants";
import {
  RawCarryStaffCandidateTerritory,
  RawCarryStaff,
  RawTerritory,
  RawVehicleType,
  Schedule,
} from "./Interfaces";

interface Props {
  selectableCarryStaffs: RawCarryStaff[];
  carryStaffCandTerritories: RawCarryStaffCandidateTerritory[];
  territories: RawTerritory[];
  vehicleTypes: RawVehicleType[];
  schedule: Schedule;
  isModalOpen: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDestroy: boolean;
  errorMessage: string | null;
  onRequestClose: () => void;
  onChangeWho: (carryStaffId: string) => void;
  onChangeDate: (dateStr: string) => void;
  onChangeFrom: (fromTime: string) => void;
  onChangeTo: (toTime: string) => void;
  onChangeMemo: (memo: string) => void;
  onChangeTerritory: (territoryId: string) => void;
  onSubmit: () => Promise<void>;
  onSubmitDelete: () => Promise<void>;
}

interface State {
  isLoading: boolean;
}

class EditScheduleModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };

    this.onAfternModalOpen = this.onAfternModalOpen.bind(this);
  }

  componentDidMount(): void {}

  onAfternModalOpen() {
    // constructor実行時点ではModalがHTML上に存在しないため、
    // Modalの描画完了後にdatetimepickerとselectizeの設定を行う

    // datetimepicker({})の中でthisは使えない(違うものを指す)ので一旦ローカル変数に入れる
    const onChangeDate = this.props.onChangeDate;
    $(".modal-datepicker").datetimepicker({
      format: "Y/m/d",
      timepicker: false,
      scrollInput: false, // マウスホイールの動きで月が変わらないよう
      onChangeDateTime: function (_dp: any, _input: any) {
        const seldate = new Date(_input.val());
        if (typeof seldate == "string") {
          console.log("typeof seldate is string", seldate);
          return;
        }

        onChangeDate(format(seldate, "yyyy-MM-dd"));
      },
    });

    $(".modal-datepicker").datetimepicker(
      "setDate",
      new Date(this.props.schedule.date)
    );
    $(".modal-datepicker").val(this.props.schedule.date.replace(/\-/g, "/"));

    // selectize({})の中でthisは使えない(違うものを指す)ので一旦ローカル変数に入れる
    const onChangeWho = this.props.onChangeWho;
    $(".selectize-carry-staff").selectize({
      onChange: (carryStaffId: string) => onChangeWho(carryStaffId),
    });
  }

  render() {
    const { canCreate, canUpdate, canDestroy } = this.props;
    const selectedCarryStaff = this.props.selectableCarryStaffs.find(
      (cas) => cas.id == this.props.schedule.carry_staff_id
    );

    // 過去のデータは削除できないようにするため
    const checkTodayOrLater = () => {
      const now = new Date();
      const dayStart = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0,
        0,
        0
      );
      return this.props.schedule.from_time >= dayStart;
    };

    return (
      <Modal
        isOpen={this.props.isModalOpen}
        onAfterOpen={this.onAfternModalOpen}
        onRequestClose={() => {
          this.props.onRequestClose();
        }}
        style={modalStyles}
      >
        <h2 className="h5 mb-4">スケジュール設定</h2>
        {this.props.errorMessage && (
          <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {this.props.errorMessage}
          </p>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.onSubmit();
          }}
        >
          <div className="border-left-info px-4">
            <div className="row align-items-center pb-3">
              <label className="col-4 mb-0">配達スタッフ</label>
              <select
                className="form-control col selectize-carry-staff"
                defaultValue={`${this.props.schedule.carry_staff_id || ""}`}
                onChange={(e) => this.props.onChangeWho(e.target.value)}
              >
                <option value="" disabled hidden>
                  選択してください
                </option>
                {this.props.selectableCarryStaffs.map((cas) => (
                  <option value={cas.id} key={cas.id}>
                    {cas.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="border-left-info px-4">
            <div className="row align-items-center pb-3">
              <label className="col-4 mb-0">担当エリア</label>
              {/* selectizeを適用しようとすると、配達スタッフの変更に従ってオプションが変化しないのでなし */}
              <select
                className="form-control col"
                defaultValue={`${this.props.schedule.territory_id || ""}`}
                onChange={(e) => this.props.onChangeTerritory(e.target.value)}
              >
                <option value="" disabled hidden>
                  選択してください
                </option>
                {this.props.carryStaffCandTerritories
                  .filter(
                    (candTerritory) =>
                      candTerritory.carry_staff_id ==
                      this.props.schedule.carry_staff_id
                  )
                  .map((candTerritory) => (
                    <option
                      value={candTerritory.territory_id}
                      key={candTerritory.territory_id}
                    >
                      {this.props.territories.find(
                        (tr) => tr.id == candTerritory.territory_id
                      )?.name || ""}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="border-left-info px-4">
            <div className="row align-items-center pb-3">
              <div className="col-4 mb-0">利用車種</div>
              <div>
                {this.props.vehicleTypes.find(
                  (vt) => vt.id == selectedCarryStaff?.vehicle_type_id
                )?.name || ""}
              </div>
            </div>
          </div>
          <div className="border-left-info px-4">
            <div className="row align-items-center pb-3">
              <label className="col-4 mb-0">対象日</label>
              <div className="px-0 col-8 d-flex">
                <div className="px-0 col-6 d-flex justify-content-start  align-items-center">
                  <input
                    type="text"
                    placeholder="年 / 月 / 日"
                    className="form-control modal-datepicker"
                  />
                  <div
                    style={{
                      position: "relative",
                      transform: "translate(-30px, 0)",
                      pointerEvents: "none",
                    }}
                  >
                    <i className="far fa-calendar-alt" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-left-info px-4">
            <div className="row align-items-center pb-3">
              <label className="col-4 mb-0">稼働時間</label>
              <div className="px-0 col-8 d-flex justify-content-start">
                <div className="px-0 col-6 d-flex justify-content-center align-items-center">
                  <label className="col-4 mb-0 px-0" htmlFor="from-time-form">
                    開始
                  </label>
                  <input
                    type="time"
                    min={`${("00" + ScheduleConst.FROM_TIME).slice(-2)}:00`}
                    max={`${("00" + ScheduleConst.TO_TIME).slice(-2)}:00`}
                    id="from-time-form"
                    className="form-control col"
                    required
                    defaultValue={format(
                      this.props.schedule.from_time,
                      "HH:mm"
                    )}
                    onChange={(e) => this.props.onChangeFrom(e.target.value)}
                  />
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <label className="col-4 mb-0 px-0" htmlFor="to-time-form">
                    終了
                  </label>
                  <input
                    type="time"
                    min={`${("00" + ScheduleConst.FROM_TIME).slice(-2)}:00`}
                    max={`${("00" + ScheduleConst.TO_TIME).slice(-2)}:00`}
                    id="to-time-form"
                    className="form-control col"
                    required
                    defaultValue={format(this.props.schedule.to_time, "HH:mm")}
                    onChange={(e) => this.props.onChangeTo(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-left-info px-4">
            <div className="row align-items-center mb-3">
              <label className="col-4 mb-0">メモ</label>
              <textarea
                className="form-control col-8"
                rows={2}
                placeholder="必要あれば"
                defaultValue={this.props.schedule.memo || ""}
                onChange={(e) => this.props.onChangeMemo(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end px-2">
            {canDestroy && this.props.schedule?.id && checkTodayOrLater() && (
              <button
                className="btn btn-danger mr-2"
                type="button"
                disabled={this.state.isLoading || !this.props.canDestroy}
                onClick={this.props.onSubmitDelete}
              >
                削除
              </button>
            )}
            {(canCreate || canUpdate) && (
              <button className="btn btn-primary" type="submit">
                {this.props.schedule?.id ? "更新" : "作成"}
              </button>
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

export default observer(EditScheduleModal);

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
