import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { carryStaffConst } from "../../../constants/CarryStaff";
import { CurrentLocationWithCarryStaffModel } from "../../../models/CurrentLocationWithCarryStaffModel";
import I18n from "../../../packs/i18n/i18n";
import "../../../packs/i18n/ja";
import { MasterTag } from "../interfaces/Interfaces";
import CarryStaffDetail from "../Panes/CarryStaffDetail";
import searchConditionsStore from "../stores/SearchConditionsStore";
import { filterCarryStaffLocations } from "../utils/FilterUtils";
import { isLoading, selectItem } from "../utils/Utils";
import ProgressGauge, {ProgressGaugeData} from "../../ui/ProgressGauge";
import {COLORS} from "../../../constants/Colors";

interface Props {
  masterTags: MasterTag[];
}

class CarryStaffList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  handleClickDetail(casLocation: CurrentLocationWithCarryStaffModel) {
    selectItem("carryStaff", casLocation.carryStaffId);
    searchConditionsStore.changeCondAndMoveTo(
      "carry_staff_requests_or_tpdts",
      "carryStaff",
      { id: casLocation.carryStaffId, name: casLocation.name }
    );
    this.setState({ isRequestListOpen: true });
  }

  private renderSearchInput(displayCount: number) {
    const masterTags = this.props.masterTags;
    const conditions = searchConditionsStore.carryStaffsConditions;
    return (
      <div style={styles.searchWrapper}>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="配達スタッフ名検索"
            value={conditions.text}
            onChange={(event) => {
              searchConditionsStore.changeCond(
                "carry_staffs",
                "text",
                event.target.value
              );
            }}
          />
          <div
            style={{
              position: "relative",
              transform: "translate(-30px, 0)",
              pointerEvents: "none",
            }}
          >
            <i className="fas fa-search" />
          </div>
        </div>
        <div className="d-flex mt-2 mr-2">
          <div
            className="d-flex align-items-center mr-2"
            style={{ width: "80%" }}
          >
            <label style={styles.searchLabel} htmlFor="staffTypeSelect">
              タイプ{" "}
            </label>
            <select
              id="staffTypeSelect"
              className="form-control form-control-sm ml-2"
              onChange={(e) => {
                const staffType = e.target.value;
                searchConditionsStore.changeCond(
                  "carry_staffs",
                  "staffType",
                  staffType == "all" ? staffType : +staffType
                );
              }}
            >
              <option key="all" value="all">
                全て
              </option>
              {Object.keys(carryStaffConst.STAFF_TYPES).map((key) => {
                const staffTypeNumber = carryStaffConst.STAFF_TYPES[key];
                return (
                  <option key={staffTypeNumber} value={staffTypeNumber}>
                    {I18n.t(`enums.carry_staff.staff_type.${key}`)}
                  </option>
                );
              })}
            </select>
          </div>

          <div
            className="d-flex align-items-center mr-2"
            style={{ width: "80%" }}
          >
            <label style={styles.searchLabel} htmlFor="tagSelect">
              タグ{" "}
            </label>
            <select
              id="tagSelect"
              className="form-control form-control-sm ml-2"
              onChange={(e) => {
                const tagId = e.target.value;
                searchConditionsStore.changeCond(
                  "carry_staffs",
                  "tagId",
                  tagId == "all" ? tagId : +tagId
                );
              }}
            >
              <option key="all" value="all">
                全て
              </option>
              {masterTags.map((tag) => {
                return (
                  <option key={tag.id} value={tag.id}>
                    {tag.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="text-right mt-2 mr-2">件数：{displayCount}件</div>
      </div>
    );
  }

  private renderDataTable(
    targetCarryStaffLocations: CurrentLocationWithCarryStaffModel[]
  ) {
    const loading = searchConditionsStore.showLoadingIcon && isLoading();
    return (
      <div style={styles.contentsWrapper}>
        {loading ? (
          <div className="fa-2x mt-5" style={styles.loading}>
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">配達スタッフ</th>
                <th scope="col">状況</th>
              </tr>
            </thead>
            <tbody>
              {targetCarryStaffLocations.map((loc, index) => {
                const progressGaugeData:ProgressGaugeData[] = [
                  {
                    value: loc.todaysReturenRequestCount,
                    title: '持ち戻り',
                    color: COLORS.status.return
                  },
                  {
                    value: loc.todaysOnholdRequestCount,
                    title: '留め置き',
                    color: COLORS.status.onhold
                  },
                  {
                    value: loc.todaysDeliveredRequestCount,
                    title: '配達済',
                    color: COLORS.status.delivered
                  }
                ]
                return (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    // ページは移動させずに、対象のCASを中心に持ってくる
                    onClick={() => searchConditionsStore.moveToLocation(loc)}
                  >
                    <td>
                      <a href="#" onClick={() => this.handleClickDetail(loc)}>
                        {loc.name}
                      </a>
                      <br />
                      <p style={styles.descriptionFont}>
                        配達スタッフID :{" "}
                        <a href={`/carry_staffs/${loc.carryStaffId}`}>
                          {loc.carryStaffId}
                        </a>
                      </p>
                    </td>
                    <td>
                      <div style={styles.requestsWrapper}>
                        <div>
                          <div className="mb-1">
                            <ProgressGauge
                              data={progressGaugeData}
                              total={loc.todaysRequestCount}
                            />
                          </div>
                          <div className="d-flex align-items-center mb-1">
                            本日の未完了依頼数 : 残 {loc.getTodaysRequestRemain()} 件 / 全 {loc.todaysRequestCount} 件
                          </div>
                          <div className="d-flex align-items-center">
                            未完了アサイン依頼数 : {loc.inProgressRequestCount} 件
                          </div>
                          {loc.todaysThirdPartyDeliveryTaskCount > 0 && (
                            <div className="d-flex align-items-center">
                              外部配達依頼数 :{" "}
                              {loc.todaysThirdPartyDeliveryTaskCount}
                            </div>
                          )}
                        </div>
                        <div className="ml-2">
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => this.handleClickDetail(loc)}
                          >
                            詳細
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }

  render() {
    const targetCarryStaffLocations = filterCarryStaffLocations();
    const displayPage = searchConditionsStore.displayPage;
    return (
      <div style={styles.contents}>
        {displayPage == "carry_staff_requests_or_tpdts" ? (
          <CarryStaffDetail />
        ) : (
          <div style={{ height: "100%" }}>
            {this.renderSearchInput(targetCarryStaffLocations.length)}
            {this.renderDataTable(targetCarryStaffLocations)}
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  contents: {
    overflow: "hidden",
    height: "100%",
  } as CSSProperties,
  searchWrapper: {
    fontSize: "0.8rem",
    margin: "10px",
  } as CSSProperties,
  searchLabel: {
    whiteSpace: "nowrap",
    margin: 0,
  } as CSSProperties,
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  } as CSSProperties,
  contentsWrapper: {
    height: "78%",
    overflowY: "auto",
    fontSize: "0.9rem",
  } as CSSProperties,
  descriptionFont: {
    fontSize: "0.8rem",
    margin: 0,
  },
  requestsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "0.8rem",
  } as CSSProperties,
};

export default observer(CarryStaffList);
