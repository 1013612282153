import {
  addMinutes,
  setMinutes,
  setSeconds,
  setMilliseconds,
  format,
} from "date-fns";
import { ChangeEventValue, Coords } from "google-map-react";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TimePicker from "react-bootstrap-time-picker";
import { Slide, ToastContainer, toast } from "react-toastify";
import GoogleMap from "../components/Common/GoogleMap";
import { H3_CAPACITY_TIMELINE_INTERVAL_MINUTES } from "../constants/H3CapacityTimeline";
import MapAttributes from "../constants/MapAttributes";
import WktMultiPolygon from "../components/WktMultiPolygon";
import { H3IndexModel } from "../models/H3IndexModel";
import deaasOverlookStore from "../stores/DeaasAreaOverlookStore";
import H3CapatityTimelinesStore from "../stores/H3CapatityTimelinesStore";
import { axiosPost } from "../utils/AxiosClient";
import MapControl from "./ui/GmapsUI/controls/MapControl";
import H3CapacityTimelineChart from "./H3CapacityTimelineChart";
import TimelineSlider from "./TimelineSlider";

declare var gon: any;

interface Props {
  canUpdate: boolean;
  height?: string;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  centerLat: number;
  centerLng: number;
  northEast: null | Coords;
  southEast: null | Coords;
  southWest: null | Coords;
  northWest: null | Coords;
  defaultZoom: number;
  loadingServiceArea: boolean;
  sliderTime: Date;
  formFromTime: string;
  formToTime: string;
  formRequiredMinutes: string;
  mouseoverH3Index: H3IndexModel | null;
  selectedH3Indices: string[];
  validTimeRange: boolean;
  zoom: number;
}

class H3CapacityTimelinesBulkEditPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const defaultSliderTime = new Date();
    defaultSliderTime.setSeconds(0);
    defaultSliderTime.setMinutes(
      Math.round(
        defaultSliderTime.getMinutes() / H3_CAPACITY_TIMELINE_INTERVAL_MINUTES
      ) * H3_CAPACITY_TIMELINE_INTERVAL_MINUTES
    );
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      centerLat: this.getDefaultCenterLat(),
      centerLng: this.getDefaultCenterLng(),
      northEast: null,
      southEast: null,
      southWest: null,
      northWest: null,
      defaultZoom: this.getDefaultZoom(),
      loadingServiceArea: false,
      sliderTime: defaultSliderTime,
      formFromTime: this.getNearTime(),
      formToTime: this.getNearTime(1),
      formRequiredMinutes: "15",
      mouseoverH3Index: null,
      selectedH3Indices: [],
      validTimeRange: true,
      zoom: this.getDefaultZoom(),
    };
  }

  componentDidMount() {}

  getNearTime(step = 0) {
    let now = new Date();
    if (now.getHours() >= 23) {
      return "23:50";
    } else if (now.getHours() < 9) {
      return "09:00";
    }

    const currentMinutes = now.getMinutes();
    const adjustedMinutes =
      Math.floor(currentMinutes / H3_CAPACITY_TIMELINE_INTERVAL_MINUTES) *
      H3_CAPACITY_TIMELINE_INTERVAL_MINUTES;
    // H3_CAPACITY_TIMELINE_INTERVAL_MINUTES分刻みで揃えられた基準時間
    const nearTime = setMilliseconds(
      setSeconds(setMinutes(now, adjustedMinutes), 0),
      0
    );
    const responseTime = addMinutes(
      nearTime,
      H3_CAPACITY_TIMELINE_INTERVAL_MINUTES * step
    );
    return format(responseTime, "HH:mm");
  }

  render() {
    const handleChangeTimeline = async (value: Date) => {
      if (!value) return;

      this.setState({ loadingServiceArea: true, sliderTime: value });
      await deaasOverlookStore.loadServiceAreasInBoundsWithHex(
        this.state.northEast!,
        this.state.southEast!,
        this.state.southWest!,
        this.state.northWest!,
        this.state.centerLat,
        this.state.centerLng,
        ["timelines", "predictions"],
        this.state.zoom,
        { datetime: value }
      );
      this.setState({ loadingServiceArea: false });
    };

    return (
      <div style={{ width: "100%", display: "flex" }}>
        <div
          className={"request-map-container"}
          style={{
            height: this.props.height || "calc(100vh - 180px)",
            flexBasis: "75%",
          }}
        >
          <GoogleMap
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{
              key: gon.google_api_key,
            }}
            defaultCenter={{
              lat: this.state.centerLat,
              lng: this.state.centerLng,
            }}
            defaultZoom={this.state.defaultZoom}
            center={{
              lat: this.state.centerLat,
              lng: this.state.centerLng,
            }}
            options={{
              zoomControlOptions: {
                position: 7,
              },
            }}
            onChange={(e) => {
              this.onChangeMap(e);
            }}
            resetBoundsOnResize={true}
            hoverDistance={MapAttributes.K_SIZE / 2}
            onGoogleApiLoaded={({ map, maps }) => {
              deaasOverlookStore.setMap(map);
              this.setState({
                map: map,
                mapApi: maps,
                mapLoaded: true,
              });
            }}
          >
            {this.createH3IndicesPolygon()}
            {this.createServiceAreaPolygon()}
            {this.state.map && (
              <MapControl
                mapApi={{ map: this.state.map, maps: this.state.mapApi }}
                position={"BOTTOM_CENTER"}
                style={{ left: 0, right: 0 }}
              >
                <div>
                  <div
                    style={{
                      zIndex: 12,
                      width: 480,
                      padding: 8,
                      marginLeft: 16,
                      marginBottom: -16,
                      boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                      background: "#ffffff",
                    }}
                  >
                    <TimelineSlider
                      step={H3_CAPACITY_TIMELINE_INTERVAL_MINUTES}
                      min={9 * 60}
                      max={24 * 60 - H3_CAPACITY_TIMELINE_INTERVAL_MINUTES}
                      onChange={handleChangeTimeline}
                      style={{ marginTop: -4 }}
                      disabled={this.state.loadingServiceArea}
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      zIndex: 12,
                      width: "100%",
                      padding: 8,
                      paddingBottom: 16,
                      marginTop: this.state.mouseoverH3Index ? 36 : 0,
                      background: "#ffffff",
                      boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                      transform: `translateY(${
                        this.state.mouseoverH3Index ? 0 : 120
                      }px)`,
                      transition: "transform 0.5s ease",
                    }}
                  >
                    <button
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 4,
                        width: "2em",
                        height: "2em",
                        border: "none",
                        borderRadius: "100%",
                        fontSize: "1rem",
                        color: "#777",
                      }}
                      onClick={() => {
                        this.setState({ mouseoverH3Index: null });
                      }}
                    >
                      <i className="fas fa-times" />
                    </button>
                    <H3CapacityTimelineChart
                      h3Index={this.state.mouseoverH3Index}
                    />
                  </div>
                </div>
              </MapControl>
            )}
          </GoogleMap>
        </div>
        <div style={{ flexBasis: "25%", padding: 10 }}>{this.renderForm()}</div>
        <ToastContainer />
      </div>
    );
  }

  private async onChangeMap(e: ChangeEventValue) {
    await deaasOverlookStore.loadServiceAreasInBoundsWithHex(
      e.bounds.ne,
      e.bounds.se,
      e.bounds.sw,
      e.bounds.nw,
      e.center.lat,
      e.center.lng,
      ["timelines", "predictions"],
      e.zoom,
      { datetime: this.state.sliderTime }
    );
    this.setState({
      northEast: e.bounds.ne,
      southEast: e.bounds.se,
      southWest: e.bounds.sw,
      northWest: e.bounds.nw,
      zoom: e.zoom,
    });
    this.setCurrentLatLngZoomToLocalStrage(e.center.lat, e.center.lng, e.zoom);
  }

  private createServiceAreaPolygon() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }

    return _.map(
      deaasOverlookStore.visibleServiceAreas.slice(),
      (visibleServiceArea) => {
        if (!visibleServiceArea.visible) {
          return;
        }

        const { model } = visibleServiceArea;
        return (
          <WktMultiPolygon
            key={`service_area_${model.id}`}
            map={this.state.map}
            mapApi={this.state.mapApi}
            wktText={model.withinAreaWkt}
            strokeColor={"#333631"}
            fillOpacity={0}
          />
        );
      }
    );
  }

  private createH3IndicesPolygon() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }

    const getH3IndexColor = (
      _h3Index: H3IndexModel,
      _capacity: number | null
    ) => {
      const selectedIndex = this.state.selectedH3Indices.findIndex(
        (idx) => idx === _h3Index.h3Index
      );
      if (selectedIndex >= 0) {
        return "#808080";
      }

      return _h3Index.getH3IndexColor();
    };

    const getH3IndexStrokeColor = (
      _h3Index: H3IndexModel,
      _capacity: number | null
    ) => {
      const selectedIndex = this.state.selectedH3Indices.findIndex(
        (idx) => idx === _h3Index.h3Index
      );
      if (selectedIndex >= 0) {
        return "#232323";
      }

      return _h3Index.getH3IndexColor();
    };

    const getH3IndexStrokeWight = (_h3Index: H3IndexModel) => {
      const selectedIndex = this.state.selectedH3Indices.findIndex(
        (idx) => idx === _h3Index.h3Index
      );
      if (selectedIndex >= 0) {
        return 3;
      }
      return 2;
    };

    return _.map(deaasOverlookStore.intersectH3Indices.slice(), (h3Index) => {
      return (
        <WktMultiPolygon
          key={`h3_index_${h3Index.id}`}
          areaKey={`h3_index_${h3Index.id}`}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={h3Index.h3AreaWkt}
          fillColor={getH3IndexColor(h3Index, h3Index.capacity)}
          // fillOpacity={0.20}
          strokeColor={getH3IndexStrokeColor(h3Index, h3Index.capacity)}
          strokeWeight={getH3IndexStrokeWight(h3Index)}
          // strokeOpacity={0.8}
          onMouseover={() => {
            this.setState({ mouseoverH3Index: h3Index });
            // 非同期で更新
            H3CapatityTimelinesStore.getTimelinesInfo(h3Index.h3Index);
          }}
          onClickPolygon={async () => {
            this.selectH3Index(h3Index.h3Index);
          }}
        />
      );
    });
  }

  private selectH3Index(h3Index: string) {
    const targetIndex = this.state.selectedH3Indices.findIndex(
      (index) => index === h3Index
    );
    if (targetIndex < 0) {
      this.setState({
        selectedH3Indices: this.state.selectedH3Indices.concat([h3Index]),
      });
    } else {
      this.state.selectedH3Indices.splice(targetIndex, 1);
      const newSelectedH3Indices = this.state.selectedH3Indices;
      this.setState({
        selectedH3Indices: [...newSelectedH3Indices],
      });
    }
  }

  private renderForm() {
    // クラス名による指定では".form-group lavel"の方が強く、きれいにならないので、
    // スタイル直接指定スタイル
    const formLabelWidth = "200px";
    return (
      <Form>
        <Form.Group className="mb-3" controlId="formFromTime">
          <Form.Label style={{ width: formLabelWidth }}>開始時刻</Form.Label>
          <TimePicker
            start={this.getNearTime()}
            end="22:50"
            step={H3_CAPACITY_TIMELINE_INTERVAL_MINUTES}
            format={24}
            value={this.state.formFromTime}
            onChange={(v: number) => {
              const vStr = this.convertToStr(v);
              this.setState({
                formFromTime: vStr,
                validTimeRange: vStr < this.state.formToTime,
              });
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formToTime">
          <Form.Label style={{ width: formLabelWidth }}>終了時刻</Form.Label>
          <TimePicker
            start={this.getNearTime(1)}
            end="23:00"
            step={H3_CAPACITY_TIMELINE_INTERVAL_MINUTES}
            format={24}
            value={this.state.formToTime}
            onChange={(v: number) => {
              const vStr = this.convertToStr(v);
              this.setState({
                formToTime: vStr,
                validTimeRange: this.state.formFromTime < vStr,
              });
            }}
          />
          {!this.state.validTimeRange && (
            <Form.Text className="text-danger">不正な範囲です</Form.Text>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formRequiredMinutes">
          <Form.Label style={{ width: formLabelWidth }}>
            見積もり到着分数
          </Form.Label>
          <Form.Control
            type="number"
            step={1}
            value={this.state.formRequiredMinutes}
            // @ts-ignore
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.setState({ formRequiredMinutes: e.target.value })
            }
          />
        </Form.Group>

        <Row>
          <Col>
            <Row
              className="mx-1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="info"
                className="my-1"
                onClick={() => this.setState({ selectedH3Indices: [] })}
                disabled={this.state.selectedH3Indices.length === 0}
              >
                選択をリセット
              </Button>
              {this.props.canUpdate && (
                <Button
                  variant="primary"
                  className="my-1"
                  onClick={async () => await this.updateCapacityInfo()}
                  disabled={
                    this.state.selectedH3Indices.length === 0 ||
                    !this.state.validTimeRange
                  }
                >
                  更新
                </Button>
              )}
            </Row>
            {this.state.selectedH3Indices.length === 0 && (
              <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Form.Text className="text-danger">
                  エリアを選択してください
                </Form.Text>
              </Col>
            )}
          </Col>
        </Row>
      </Form>
    );
  }

  /**
   * react-bootstrap-time-pickerのonChangeで渡ってくる数値を文字列形式に直す関数.
   *
   * https://github.com/yury-dymov/react-bootstrap-time-picker#onchange-func-default---
   * @param timePickerTime
   * @returns
   */
  private convertToStr(timePickerTime: number) {
    const hours = Math.floor(timePickerTime / 3600);
    const minutes = Math.floor((timePickerTime - hours * 3600) / 60);
    return ("00" + hours).slice(-2) + ":" + ("00" + minutes).slice(-2);
  }

  private async updateCapacityInfo() {
    await this.updateTimelines();
  }

  public async updateTimelines() {
    let options = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide,
    };

    if (this.state.selectedH3Indices.length === 0) {
      // 来ないはずだけど念の為
      options["type"] = toast.TYPE.WARNING;
      toast.error("エリアが選択されていません", options);
      return;
    }

    this.setState({ loadingServiceArea: true });

    try {
      const response = (await axiosPost.post(
        "/api/h3_capacity_timelines/bulk_update",
        {
          from: this.state.formFromTime,
          to: this.state.formToTime,
          manualRequiredMinutes: this.state.formRequiredMinutes,
          h3Indices: this.state.selectedH3Indices,
        }
      )) as { status: number; data: { message: string } };
      if (response.status === 200) {
        await deaasOverlookStore.loadServiceAreasInBoundsWithHex(
          this.state.northEast!,
          this.state.southEast!,
          this.state.southWest!,
          this.state.northWest!,
          this.state.centerLat,
          this.state.centerLng,
          ["timelines", "predictions"],
          this.state.zoom
        );

        H3CapatityTimelinesStore.resetH3Indices(this.state.selectedH3Indices);
        this.setState({ selectedH3Indices: [] });
        toast.success("更新しました", options);
      } else {
        options["type"] = toast.TYPE.ERROR;
        toast.error("更新に失敗しました", options);
      }
    } catch (e) {
      options["type"] = toast.TYPE.ERROR;
      toast.error("更新に失敗しました", options);
    }

    this.setState({ loadingServiceArea: false });
  }

  private setCurrentLatLngZoomToLocalStrage(lat, lng, zoom) {
    localStorage.setItem(this.getDefaultCenterLatKey(), lat);
    localStorage.setItem(this.getDefaultCenterLngKey(), lng);
    localStorage.setItem(this.getDefaultZoomKey(), zoom);
  }

  private getDefaultCenterLatKey() {
    return (
      MapAttributes.LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_CENTER_LAT +
      gon.current_user_id
    );
  }

  private getDefaultCenterLngKey() {
    return (
      MapAttributes.LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_CENTER_LNG +
      gon.current_user_id
    );
  }

  private getDefaultZoomKey() {
    return (
      MapAttributes.LOCAL_STRAGE_KEY_DEAASAREAOVERLOOKMAP_ZOOM +
      gon.current_user_id
    );
  }

  private getDefaultCenterLat(): number {
    let lat = Number(localStorage.getItem(this.getDefaultCenterLatKey()));
    if (lat <= 1) {
      lat = MapAttributes.SHIBUYA_STATION_LATITUDE;
    }
    return lat;
  }

  private getDefaultCenterLng(): number {
    let lng = Number(localStorage.getItem(this.getDefaultCenterLngKey()));
    if (lng <= 1) {
      lng = MapAttributes.SHIBUYA_STATION_LONGITUDE;
    }
    return lng;
  }

  private getDefaultZoom(): number {
    let zoom: number = Number(localStorage.getItem(this.getDefaultZoomKey()));
    if (zoom <= 1) {
      zoom = MapAttributes.DEFAULT_ZOOM;
    }
    return zoom;
  }
}

export default observer(H3CapacityTimelinesBulkEditPage);
