import React, {CSSProperties, ReactNode, useMemo} from "react";

export interface NotificationItemProps {
  id: number | string
  actionTitle?: string
  onClick?: (id: number | string) => void
  children?: ReactNode
  type?: "default" |　"info"
}

interface Props extends NotificationItemProps {}
export const NotificationItem = (props: Props) => {
  const { id, children, type = "default", onClick, actionTitle = "表示" } = props

  const handleClickAction = () => {
    if(!onClick) return
    onClick(id)
  }

  const styles = createStyles(props)


  const iconClass = useMemo(() => {
    if (type === "default") {
      return "fas fa-exclamation-triangle"
    } else {
      return "fas fa-info-circle"
    }
  }, [type])

  return(
    <div style={styles.wrapStyle}>
      <div style={{
        display: "flex",
        width: "100%",
      }}>
        <div style={{
          display: "flex",
          marginRight: 16,
          flex: 1,
          borderRight: "1px solid #ccc",
          paddingRight: 8,
        }}>
          <div style={{ marginRight: 16}}>
            <i className={iconClass}/>
          </div>
          <div>
              {children}
          </div>
        </div>
        {
          !!onClick && (
            <div style={{
              marginLeft: "auto",
              flexBasis: 48,
              alignSelf: "center",
              textAlign: "center"
            }}>
              <button
                style={{
                  border: "none",
                  whiteSpace: "nowrap",
                  fontSize: 12,
                  borderRadius: 6,
                  background: "#e1e1e1",
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: "#666666"
                }}
                onClick={handleClickAction}
              >
                {actionTitle}
              </button>
            </div>
          )
        }
      </div>
    </div>
  )
}

/**
 * Styles
 * @param props
 */
const createStyles = (props: Props) => {
  return useMemo(() => {
    function makeColor(type: "default" | "info") {
      if( type === "default") {
        return {
          base: "#f1f1f1",
          border: "#ccc",
          text: "#333"
        }
      } else {
        return {
          base: "#f1f1f1",
          border: "#ccc",
          text: "#333"
        }
      }
    }

    const colors = makeColor(props.type as "default" | "info")

    const wrapStyle: CSSProperties = {
      background: colors.base,
      padding: 16,
      border: "1px solid",
      borderColor: colors.border,
      marginTop: 4,
      borderRadius: 14,
      pointerEvents: "all",
    }

    return {
      wrapStyle
    }
  }, [])
}
