import { observer } from "mobx-react";
import React from "react";
import Modal from "react-modal";
import MapAttributes from "../../../../constants/MapAttributes";
import { getMapDefaultValueFunctions } from "../../../../utils/MapSettingStorageUtils";
import type { RoutesApiBulkAssignResponse } from "../../../../utils/routes-apis/apis/common";
import type { SimpleCarryStaffSchedule } from "../../../../utils/routes-apis/common-utils";
import type {
  RawBulkAssignArea,
  RawCarryStaff,
  RawRequest,
  RawRequestWithInfo,
  RawRoutesApiParamSetting,
  RawTerritory,
  RawVehicleType,
} from "../../interfaces";
import ConfirmAssigningStageBody from "./ConfirmAssigningStageBody";
import ExecuteAssigningStageBody from "./ExecuteAssigningStageBody";
import SelectAreaStageBody from "./SelectAreaStageBody";

interface Props {
  bulkAssignAreas: RawBulkAssignArea[];
  routesApiParamSettings: RawRoutesApiParamSetting[];
  territories: RawTerritory[];
  vehicleTypes: RawVehicleType[];
  isOpen: boolean;
  onCloseModal: () => void;
}

interface State {
  stage: "select_area" | "execute_assigning" | "confirm_assigning";
  removedCarryStaffIds: number[];
  carryStaffs: RawCarryStaff[];
  schedules: SimpleCarryStaffSchedule[];
  assignedRequests: RawRequest[];
  unassignedRequests: RawRequestWithInfo[];
  removedUnassignedRequestIds: number[];
  bulkAssignResponse: RoutesApiBulkAssignResponse | null;
}

class BulkAssignModal extends React.Component<Props, State> {
  mapFuncs: ReturnType<typeof getMapDefaultValueFunctions>;

  constructor(props: Props) {
    super(props);
    this.state = {
      stage: "select_area",
      removedCarryStaffIds: [],
      carryStaffs: [],
      schedules: [],
      assignedRequests: [],
      removedUnassignedRequestIds: [],
      unassignedRequests: [],
      bulkAssignResponse: null,
    };
    this.mapFuncs = getMapDefaultValueFunctions(
      MapAttributes.LOCAL_STRAGE_BASE_KEY_ROUTEDELIVERYASSIGN,
      false
    );
  }

  private handleCloseModal = () => {
    this.setState({
      stage: "select_area",
      removedCarryStaffIds: [],
      carryStaffs: [],
      schedules: [],
      assignedRequests: [],
      removedUnassignedRequestIds: [],
      unassignedRequests: [],
      bulkAssignResponse: null,
    });
    this.props.onCloseModal();
  };

  private completeSelectAreaStage = (items: {
    carryStaffs: RawCarryStaff[];
    schedules: SimpleCarryStaffSchedule[];
    requests: RawRequestWithInfo[];
  }) => {
    this.setState({
      stage: "execute_assigning",
      carryStaffs: items.carryStaffs,
      schedules: items.schedules,
      unassignedRequests: items.requests,
    });
  };

  private completeExecuteAssigningStage = (
    response: RoutesApiBulkAssignResponse
  ) => {
    this.setState({
      stage: "confirm_assigning",
      bulkAssignResponse: response,
    });
  };

  private completeConfirmAssigningStage = () => {
    this.handleCloseModal();
  };

  render() {
    const { stage } = this.state;
    return (
      <Modal isOpen={this.props.isOpen} style={modalStyles}>
        {stage == "select_area" && (
          <SelectAreaStageBody
            zoom={this.mapFuncs.getDefaultZoom()}
            center={{
              lat: this.mapFuncs.getDefaultCenterLat(),
              lng: this.mapFuncs.getDefaultCenterLng(),
            }}
            bulkAssignAreas={this.props.bulkAssignAreas}
            territories={this.props.territories}
            onClickNext={(items) => this.completeSelectAreaStage(items)}
            onClickClose={this.handleCloseModal}
          />
        )}

        {stage == "execute_assigning" && (
          <ExecuteAssigningStageBody
            territories={this.props.territories}
            vehicleTypes={this.props.vehicleTypes}
            carryStaffs={this.state.carryStaffs}
            schedules={this.state.schedules}
            requests={this.state.unassignedRequests}
            routesApiParamSettings={this.props.routesApiParamSettings}
            onClickNext={(response) =>
              this.completeExecuteAssigningStage(response)
            }
            onClickClose={this.handleCloseModal}
          />
        )}

        {stage == "confirm_assigning" && (
          <ConfirmAssigningStageBody
            carryStaffs={this.state.carryStaffs}
            response={this.state.bulkAssignResponse}
            onClickNext={() => this.completeConfirmAssigningStage()}
            onClickClose={this.handleCloseModal}
          />
        )}
      </Modal>
    );
  }
}

export default observer(BulkAssignModal);

const modalStyles: Modal.Styles = {
  content: {
    width: "80%",
    maxHeight: 800,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingRight: 10,
    paddingLeft: 10,
  },
  overlay: {
    zIndex: 1050,
  },
};
