import { observer } from "mobx-react";
import React from "react";
import { ScheduleConst, ScheduleStyleConst } from "./Constants";
import {
  ExpandedRawRequest,
  RawCarryStaffWithSchedules,
  RawTerritory,
  Schedule,
} from "./Interfaces";
import { calcScheduleDataPosition } from "./PositionUtils";
import ScheduleBar from "./ScheduleBar";

interface Props {
  carryStaff: RawCarryStaffWithSchedules;
  requests: ExpandedRawRequest[];
  territories: RawTerritory[];
  isLastRow: boolean;
  selectedDate: string;
  isRequestsVisible: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  calcRowBodyHeight: (casRequests: ExpandedRawRequest[]) => number;
  renderRequestItems: (
    carryStaff: RawCarryStaffWithSchedules,
    requests: ExpandedRawRequest[]
  ) => JSX.Element;
  onSelectSchedule: (schedule: Schedule) => void;
  onSaveSchedule: (schedule: Schedule) => void;
}

interface State {
  startLeftRatio: number;
  dragWidthRatio: number;
  fromTime: Date;
  toTime: Date;
  excludeWidth: number;
  isCreateDragging: boolean;
  dragGhostElement: HTMLDivElement | null;
}

// 1時間を何分割でスナップするか
const splitUnitPerHour = 4;
// スケジュールのベースグリッド数
const totalGridNumber =
  (ScheduleConst.TO_TIME - ScheduleConst.FROM_TIME) * splitUnitPerHour;
// 1グリッドの大きさ
const gridSize = 100 / totalGridNumber;

class CarryStaffScheduleRow extends React.Component<Props, State> {
  baseElementRef: React.RefObject<HTMLDivElement>;
  dragGhostElementRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      startLeftRatio: 0,
      dragWidthRatio: 0,
      fromTime: new Date(props.selectedDate),
      toTime: new Date(props.selectedDate),
      excludeWidth: 0,
      isCreateDragging: false,
      dragGhostElement: null,
    };

    this.baseElementRef = React.createRef();
    this.dragGhostElementRef = React.createRef();
  }

  componentDidMount() {
    // render中に渡されたref要素はnullとなり、描画直後のドラッグ操作が正常に行われないので対策として
    this.setState({ dragGhostElement: this.dragGhostElementRef.current });
  }

  // 実際の位置データを分割されたグリッドに合うように変換するメソッド
  calcDraggingEdgeValue = (position: number): [number, Date] => {
    // グリッドにスナップさせる
    const snappedRatio = Math.floor(position / gridSize) * gridSize;
    // グリッドに対応する時間データを取得する
    const gridPosition = Math.floor((snappedRatio / 100) * totalGridNumber);
    const snappedTime = this.convertGridPositionToDate(
      this.props.selectedDate,
      gridPosition
    );
    return [snappedRatio, snappedTime];
  };

  convertGridPositionToDate = (targetDate: string, gridPosition: number) => {
    const baseTime = new Date(targetDate);
    baseTime.setHours(
      ScheduleConst.FROM_TIME + Math.floor(gridPosition / splitUnitPerHour)
    ); // 時間を変換
    baseTime.setMinutes(
      (gridPosition % splitUnitPerHour) * (60 / splitUnitPerHour)
    ); // 分を変換
    baseTime.setSeconds(0);
    return baseTime;
  };

  /*
   * ドラッグで新しいスケジュールを生成
   */
  handleNewScheduleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    // スケジュール要素内での位置を特定するために不要な要素幅
    const excludeWidth = e.currentTarget.getBoundingClientRect().left;
    const startX = e.clientX - excludeWidth;

    // ドラッグイベントで生成されるドラッグイメージを非表示に出来ないので空の要素に置き換える
    const dragGhostElement = this.dragGhostElementRef.current;
    if (dragGhostElement) {
      e.dataTransfer.setDragImage(dragGhostElement, 0, 0);
    }

    const baseElementWidth = this.baseElementRef.current?.offsetWidth;
    if (!baseElementWidth) return;

    // // positionは要素内左端からの正確な位置, snappedRatioはグリッドにスナップさせた位置
    const startPosition = (startX / baseElementWidth) * 100;
    const [snappedRatioStart, fromTime] =
      this.calcDraggingEdgeValue(startPosition);
    if (snappedRatioStart >= 0 && snappedRatioStart <= 100) {
      this.setState({
        isCreateDragging: true,
        excludeWidth: excludeWidth,
        startLeftRatio: snappedRatioStart,
        fromTime: fromTime,
      });
    }
  };

  handleNewScheduleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    const { excludeWidth, isCreateDragging } = this.state;
    if (!isCreateDragging) return;

    const baseElementWidth = this.baseElementRef.current?.offsetWidth;
    if (!baseElementWidth) return;

    const moveX = e.clientX - excludeWidth;
    const position = (moveX / baseElementWidth) * 100;
    const [snappedRatio, toTime] = this.calcDraggingEdgeValue(position);
    if (snappedRatio >= this.state.startLeftRatio && snappedRatio <= 100) {
      this.setState({
        dragWidthRatio: snappedRatio - this.state.startLeftRatio,
        toTime: toTime,
      });
    }
  };

  handleNewScheduleDragEnd = () => {
    this.setState({ isCreateDragging: false });
    const { carryStaff, selectedDate, onSaveSchedule } = this.props;
    const { fromTime, toTime } = this.state;
    const territory = this.props.territories.find(
      (ter) => ter.id == carryStaff.territory_id
    );
    const newSchedule: Schedule = {
      id: null,
      carry_staff_id: carryStaff.id,
      territory_id: carryStaff.territory_id,
      territory_name: territory?.name,
      date: selectedDate,
      from_time: fromTime,
      to_time: toTime,
      memo: null,
    };
    onSaveSchedule(newSchedule);
  };

  render() {
    const {
      carryStaff,
      requests,
      territories,
      selectedDate,
      isRequestsVisible,
      canCreate,
      canUpdate,
      calcRowBodyHeight,
      renderRequestItems,
      onSelectSchedule,
      onSaveSchedule,
    } = this.props;

    const { fromTime, toTime, isCreateDragging, dragGhostElement } = this.state;
    const baseElementWidth = this.baseElementRef.current?.offsetWidth ?? 0;
    const height = isRequestsVisible
      ? calcRowBodyHeight(
          requests.filter((req) => req.carry_staff_id === carryStaff.id)
        )
      : ScheduleStyleConst.ROW_BODY_HEIGHT;
    const termStart = new Date(
      `${selectedDate} ${("00" + ScheduleConst.FROM_TIME).slice(
        -2
      )}:00:00+09:00`
    );
    const termEnd = new Date(
      `${selectedDate} ${("00" + ScheduleConst.TO_TIME).slice(-2)}:00:00+09:00`
    );
    const territory = this.props.territories.find(
      (ter) => ter.id == carryStaff.territory_id
    );
    const newSchedule: Schedule = {
      id: null,
      carry_staff_id: carryStaff.id,
      territory_id: carryStaff.territory_id,
      territory_name: territory?.name,
      date: selectedDate,
      from_time: fromTime,
      to_time: toTime,
      memo: null,
    };

    return (
      <div
        className="d-flex"
        style={{ width: "100%", position: "relative", cursor: "pointer" }}
        ref={this.baseElementRef}
        draggable={canCreate}
        onDragStart={canCreate ? this.handleNewScheduleDragStart : () => {}}
        onDrag={this.handleNewScheduleDrag}
        onDragEnd={this.handleNewScheduleDragEnd}
      >
        <div
          ref={this.dragGhostElementRef}
          style={{ width: 1, height: 1, opacity: 0 }}
        ></div>
        <div className="d-flex" style={{ width: "100%", height: height }}>
          {[...Array(ScheduleConst.TO_TIME - ScheduleConst.FROM_TIME)]
            .map((_, i) => i + ScheduleConst.FROM_TIME)
            .map((fromTime) => {
              return (
                <div
                  key={`cas_body_${fromTime}`}
                  className={this.props.isLastRow ? "" : "border-bottom"}
                  style={{ height: "100%", flexGrow: 1 }}
                ></div>
              );
            })}
        </div>
        {carryStaff.schedules.map((schedule) => {
          const { leftRatio, widthRatio } = calcScheduleDataPosition(
            { start: termStart, end: termEnd },
            { start: schedule.from_time, end: schedule.to_time }
          );

          return (
            <ScheduleBar
              key={`cas_schedule_${schedule.id}`}
              carryStaff={carryStaff}
              schedule={schedule}
              territories={territories}
              selectedDate={selectedDate}
              leftRatio={leftRatio}
              widthRatio={widthRatio}
              baseElementWidth={baseElementWidth}
              dragGhostElement={this.dragGhostElementRef.current}
              canUpdate={canUpdate}
              onSelectSchedule={onSelectSchedule}
              onSaveSchedule={onSaveSchedule}
              calcDraggingEdgeValue={this.calcDraggingEdgeValue}
            />
          );
        })}
        {isCreateDragging && (
          <ScheduleBar
            key={`new_cas_schedule`}
            carryStaff={carryStaff}
            schedule={newSchedule}
            territories={territories}
            selectedDate={selectedDate}
            leftRatio={this.state.startLeftRatio}
            widthRatio={this.state.dragWidthRatio}
            baseElementWidth={baseElementWidth}
            dragGhostElement={this.dragGhostElementRef.current}
            canUpdate={canUpdate}
            isCreateDragSchedule={true}
            onSelectSchedule={onSelectSchedule}
            onSaveSchedule={onSaveSchedule}
            calcDraggingEdgeValue={this.calcDraggingEdgeValue}
          />
        )}
        {isRequestsVisible && renderRequestItems(carryStaff, requests)}
      </div>
    );
  }
}

export default observer(CarryStaffScheduleRow);
