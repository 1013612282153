import React, {useContext, useEffect, useRef, useState} from "react";
import {GmapsUIContext} from "../index";
import type {MapApi} from "../index"

const TRANSITION_MS = 500

interface Props {
    mapApi: MapApi,
    children: React.ReactNode,
    open: boolean,
    onModalClose: () => void
}

export function Modal(props: Props) {
    const {
        mapApi,
        children,
        onModalClose = () => {
        }
    } = props
    const modalRef = useRef(null)
    const [opacity, setIsOpacity] = useState(0)
    const {isOpenModal} = useContext(GmapsUIContext)


    useEffect(() => {
        if (modalRef.current) {
            mapApi.map.controls[mapApi.maps.ControlPosition.RIGHT_CENTER].push(modalRef.current);
        }
        return () => {
            mapApi.map.controls[mapApi.maps.ControlPosition.RIGHT_CENTER].clear()
        }
    }, [])

    useEffect(() => {
        if (isOpenModal) {
            setTimeout(() => setIsOpacity(1), TRANSITION_MS)
        }
    }, [isOpenModal])

    const handleClickClose = () => {
        setIsOpacity(0)
        setTimeout(() => onModalClose(), TRANSITION_MS)
    }

    return (
        <div style={{visibility: "hidden"}}>
            {
                <div ref={modalRef} style={{
                    ...styles.container,
                    opacity,
                    transition: `${TRANSITION_MS}ms opacity`,
                    display: isOpenModal ? "flex" : "none",

                }}>
                    {
                        isOpenModal &&
                        <>
                            <div
                                style={{
                                    ...styles.overlay,
                                }}
                                onClick={handleClickClose}
                            />
                            <div
                                style={styles.contents}
                                onClick={() => {
                                }}
                            >
                                {children}
                            </div>
                            <div
                                onClick={handleClickClose}
                                style={styles.closeIcon}>
                                <div style={{...styles.closeIconBefore, ...styles.closeIconBeforeAfter}}/>
                                <div style={{...styles.closeIconAfter, ...styles.closeIconBeforeAfter}}/>
                            </div>
                        </>
                    }

                </div>
            }
        </div>
    )
}

const styles = {
    container: {
        zIndex: 100,
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    } as React.CSSProperties,
    overlay: {
        background: "rgba(0, 0, 0, 0.4)",
        width: "100%",
        height: "100%",
        position: "absolute",
    } as React.CSSProperties,
    contents: {
        position: "relative",
        boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
        background: "#fff",
    } as React.CSSProperties,
    closeIcon: {
        display: "block",
        width: 50,
        height: 50,
        position: "absolute",
        right: 8,
        top: 8,
        cursor: "pointer"
    } as React.CSSProperties,
    closeIconBeforeAfter: {
        content: "",
        display: "block",
        width: "100%",
        height: "2px",
        background: "#fff",
        position: "absolute",
        top: "calc(14% - 5px)",
    } as React.CSSProperties,
    closeIconBefore: {
        transform: "rotate(45deg)",
        transformOrigin: "0% 50%",
        left: "14%"
    } as React.CSSProperties,
    closeIconAfter: {
        transform: "rotate(-45deg)",
        transformOrigin: "100% 50%",
        left: "auto",
        right: "14%",
    } as React.CSSProperties,
}