import format from 'date-fns/format';
import { Bounds, Coords } from 'google-map-react';
import _ from 'lodash';
import { observer } from "mobx-react";
import React from "react";
import RequestPolyline from "../components/RequestPolyline";
import MapAttributes from "../constants/MapAttributes";
import ThirdPartyDeliveryTask from "../interfaces/ThirdPartyDeliveryTask";
import carryStaffLocationsStore from '../stores/CarryStaffLocationsStore';
import thirdPartyDeliveryTaskStore from "../stores/ThirdPartyDeliveryTaskStore";
import GoogleMap from '../components/Common/GoogleMap';
import CarryStaffMarker from './CarryStaffMarker';
import ThirdPartyDeliveryTaskReceiverSmallMarker from "./ThirdPartyDeliveryTaskReceiverSmallMarker";
import ThirdPartyDeliveryTaskSenderSmallMarker from './ThirdPartyDeliveryTaskSenderSmallMarker';


declare var gon: any;

interface Props {
  thirdPartyDeliveryTask: ThirdPartyDeliveryTask;
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  center: Coords;
  bounds: Bounds | null;
}

class ThirdPartyDeliveryTaskMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      center: this.props.thirdPartyDeliveryTask.sender,
      bounds: null
    };
  }

  componentDidMount(): void {
    thirdPartyDeliveryTaskStore.subscribeThirdPartyDelivery(this.props.thirdPartyDeliveryTask.id);
  }

  subscribe() {
    carryStaffLocationsStore.subscribeIn(this.state.center, this.state.bounds);
  }

  private renderCarryStaffMarkers() {
    return _.map(
      carryStaffLocationsStore.items.slice(),
      (location) => {
        return (
          <CarryStaffMarker
            lat={location.lat}
            lng={location.lng}
            location={location}
            request={this.props.thirdPartyDeliveryTask}
            key={location.id}
          />
        )
      }
    )
  }

  private renderCarryStaffPolyline() {
    if (!this.state.mapLoaded) {
      return
    }
    if (!thirdPartyDeliveryTaskStore.thirdPartyDelivery) {
      return
    }

    return (
      <RequestPolyline
        map={this.state.map}
        mapApi={this.state.mapApi}
        deliveryItem={thirdPartyDeliveryTaskStore.thirdPartyDelivery}
      />
    )
  }

  render() {
    const { thirdPartyDeliveryTask } = this.props;
    const latestLoadedAt = carryStaffLocationsStore.loadingInfo.latestLoadedAt ?? new Date();
    return (
      <div className={'request-map-container'}>
        <span
          id="request-map-postion-read-at"
          style={{ position: 'absolute', zIndex: 100, top: 0, left: 4 }}
        >
          {`位置情報 読込日時:  ${format(latestLoadedAt, 'HH:mm:ss')}`}
        </span>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={thirdPartyDeliveryTask.sender}
          defaultZoom={16}
          center={thirdPartyDeliveryTask.sender}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => this.setState({
            map: map,
            mapApi: maps,
            mapLoaded: true
          })}
          onChange={
            (value) => {
              this.setState(
                {
                  center: { ...value.center },
                  bounds: { ...value.bounds }
                }, () => {
                  this.subscribe();
                }
              )
            }
          }
        >
          <ThirdPartyDeliveryTaskSenderSmallMarker
            lat={thirdPartyDeliveryTask.sender.lat}
            lng={thirdPartyDeliveryTask.sender.lng}
            thirdPartyDeliveryTask={thirdPartyDeliveryTask}
          />
          <ThirdPartyDeliveryTaskReceiverSmallMarker
            lat={thirdPartyDeliveryTask.receiver.lat}
            lng={thirdPartyDeliveryTask.receiver.lng}
            thirdPartyDeliveryTask={thirdPartyDeliveryTask}
            index={thirdPartyDeliveryTask.id}
          />
          {this.renderCarryStaffMarkers()}
          {this.renderCarryStaffPolyline()}
        </GoogleMap>
      </div>
    );
  }
}

export default observer(ThirdPartyDeliveryTaskMap);
