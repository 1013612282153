import React, {createRef, CSSProperties, MutableRefObject, useEffect, useMemo, useRef, useState} from "react";
import {INotification} from "./interfaces";
import {TransitionGroup, Transition,} from 'react-transition-group';

interface INotificationWithRef extends  INotification {
  nodeRef:  MutableRefObject<any>
}

interface Props {
  notifications: INotification[]
  isShow: boolean
  width: number
  horizon: "left" | "right"
  vertical: "top" | "bottom"
}
export const NotificationList = (props: Props) => {
  const {
    notifications,
    isShow,
    width,
    horizon,
  } = props

  const [displayNotifications, setDisplayNotifications] = useState<INotificationWithRef[]>([])

  useEffect(() => {
    setDisplayNotifications(notifications.map((n) => {
      return {
        ...n,
        nodeRef: createRef()
      }
    }))
  }, [notifications])


  const duration = 250
  const margin = 16

  const refList = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if(!refList.current) return
    const startPos = horizon === "right" ? `translateX(${width + margin}px)` : `translateX(-${width}px)`

    if (isShow) {
      // リストを開くアニメーション
      refList.current.animate([
        {transform: startPos, visibility: "hidden"},
        {transform: 'translateX(0)', visibility: "visible"},
      ],{
        duration,
        fill: "both"
      })

    } else {
      // リストを閉じるアニメーション
      refList.current.animate([
        {transform: 'translateX(0)', visibility: "visible"},
        {transform: startPos, visibility: "hidden"},
      ],{
        duration,
        fill: "both"
      })
    }
  }, [isShow])


  // Stylesを作成
  const styles = createStyles(props)

  const defaultStyle= {
    transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
    transform: `translateX(${horizon == "right" ? width : width * -1}px)`,
    opacity: 1,
  };

  const transitionStyles: {[key: string]: CSSProperties} = {
    entering: {},
    entered:  { transform: `translateX(0)` },
    exiting:  { transform: `translateX(0)`,opacity: 0 },
    exited:  { transform: `translateX(0)`,opacity: 0 },
  };

  /**
   * Template
   */
  return(
    <div className="hidden-scrollbar"
         ref={refList}
         style={styles.listStyle}>
      {
          <>
            {

              <TransitionGroup component="div">
                {
                  displayNotifications.map((notification) => {
                    return (
                      <Transition
                        nodeRef={notification.nodeRef}
                        timeout={700}
                        classNames="notification-item"
                        key={notification.id}
                      >
                        {state => (
                          <div ref={notification.nodeRef} style={{...defaultStyle, ...transitionStyles[state]}}>
                            {notification.render}
                          </div>
                        )
                        }
                      </Transition>
                    )
                  })
                }
              </TransitionGroup>
            }
          </>
      }
    </div>
  )
}

/**
 * Styles
 * @param props
 */
const createStyles = (props: Props) => {
  const {vertical, horizon, width} = props
  return useMemo(() => {
    const listStyle: CSSProperties = {
      marginBottom: vertical == "bottom" ? -8: "inherit",
      marginTop:  vertical == "top" ? -16: "inherit",
      overflowY: "scroll",
      width,
      maxHeight: "calc(100vh - 50px)",
      display: "flex",
      flexDirection: vertical == "bottom" ? "column-reverse": "column",
      pointerEvents: "all",
      paddingLeft: horizon === "left" ? 16 : "inherit",
      paddingRight: horizon === "right" ? 16 : "inherit",
    }
    return {
      listStyle
    }
  }, [vertical, horizon, width])




}
