import React from 'react';
import PropTypes from 'prop-types';

interface Props {

  onMenuItemClick: Function

}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean,

  centerLat: number;
  centerLng: number;

  draggable: boolean;
}

// この辺を参考に、、、
// https://qiita.com/hanaitama-kaz/items/1f7feb55cb2f7ef1b035
class SpotRemarkMarkerMenu extends React.Component<Props, State> {

  menuElm: any = null; // メニューを表示するdiv要素を参照するための変数です。
  messages: string[] = ["マーカー削除"]; // メニューに表示するアイテムの配列

  // コンストラクタ
  constructor(props) {
    super(props);        // おまじないですね。

  }

  // メニュー要素を表示(visibility="visible")します。
  // 親要素から、呼び出されるメソッドです。
  show = (clientX, clientY) => {
    // 以下のようにstyle.top,style.leftを指定することで、好きな場所にメニューを表示できます。
    this.menuElm.style.top = clientY + "px";
    this.menuElm.style.left = clientX + "px";
    this.menuElm.style.visibility = "visible";


    // 表示したらフォーカスを割り当てます。
    // (これで、keyイベントを受け付けてくれるようになります。)
    this.menuElm.focus();
  }

  // メニューを閉じ(visibility="hidden"に変更し)ます。
  close = () => {
    this.menuElm.style.visibility = "hidden";
  }

  // "close"クリック時のイベントハンドラ
  onCloseButtonClick = (e) => {
    // close()を呼び出します。
    e.stopPropagation();  //  地図のクリックを避けるために追加
    this.close();
  }

  // メニューアイテムクリック時のイベントハンドラ
  onMenuItemClick = (event) => {
    event.stopPropagation(); // マップクリックにならないように
    // 自分自身を閉じて
    this.close();

    // 親要素から渡されたコールバック関数を呼び出します。
    // 引数には、アイテムに表示されているテキストを渡します。
    // this.props.onMenuItemClick(event.target.innerHTML);

    // 削除しかないので、クリックされたアイテムの判定不要
    this.props.onMenuItemClick();

  }

  // エスケープキーで閉じるためのイベントハンドラです。
  onKeyUp = (event) => {
    event.preventDefault();
    // 文字列で比較できるとは思いませんでした。。
    if ("Escape" == event.key) {
      this.close();
    }
  }

  // classはapp/assets/stylesheets/aplication.css.scssに追記
  render() {
    return (
      <React.Fragment>
        { /* refで要素を参照することで、styleの変更ができるようになります。 */}
        <div className="area-topic-menu-box" ref={(node) => this.menuElm = node} onKeyUp={this.onKeyUp}>
          {
            this.messages.map((message) =>
              <div className="area-topic-menu-item" onClick={this.onMenuItemClick} key={message}>{message}</div>
            )
          }
          <div className="area-topic-menu-item" onClick={this.onCloseButtonClick}>閉じる</div>
        </div>
      </React.Fragment>
    );
  }
}

export default SpotRemarkMarkerMenu;