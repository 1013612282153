import React, { useState } from "react";
import Modal from "react-modal";
import { axiosPost } from "../../utils/AxiosClient";

Modal.setAppElement("#wrapper");

type Props = {
  id: number;
  email: string;
  exitsSpecifiedTimeBranch: boolean;
};

export default function SendTestModal({
  id,
  email,
  exitsSpecifiedTimeBranch,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formats, setFormats] = useState("html");
  const [isTimeSpecified, setIsTimeSpecified] = useState(true);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    clearResults();
  };

  const clearResults = () => {
    if (isSuccess) setIsSuccess(false);
    if (isLoading) setIsSuccess(false);
    if (errorMessage) setErrorMessage(null);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    if (isSuccess) setIsSuccess(false);
    if (errorMessage) setErrorMessage(null);

    try {
      await axiosPost.post(
        `/api/pre_delivery_notification_mails/${id}/send_test`,
        {
          formats: formats,
          is_time_specified: isTimeSpecified,
        }
      );
      setIsSuccess(true);
      setErrorMessage(null);
    } catch (error) {
      console.log(error);
      const message = error.response?.data?.message || "エラーが発生しました。";
      setErrorMessage(message);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={() => openModal()}
        disabled={isOpen}
      >
        送信テスト
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        style={modalStyles}
      >
        <h2 className="h5 mb-4">送信テスト</h2>
        {isLoading && <p>送信中...</p>}
        {isSuccess && <p>送信しました。</p>}
        {errorMessage && (
          <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {errorMessage}
          </p>
        )}
        <form onSubmit={onSubmit}>
          <div className="border-left-info px-4">
            <div className="row align-items-center mb-3">
              <label className="col-4 mb-0">送信先アドレス:</label>
              <input className="form-control col" value={email} disabled />
            </div>

            <div className="row align-items-center mb-3">
              <label id="select-formats" className="col-4 mb-0">
                フォーマット:
              </label>
              <select
                id="select-formats"
                className="form-control col"
                onChange={(e) => {
                  setFormats(e.target.value);
                }}
              >
                <option value="html">HTML</option>
                <option value="text">テキスト</option>
              </select>
            </div>

            {exitsSpecifiedTimeBranch && (
              <div className="row align-items-center mb-3">
                <label
                  className="col-4 mb-0"
                  htmlFor="checkbox-is-time-specified"
                >
                  時間指定あり
                </label>
                <div className="col">
                  <div className="custom-control custom-switch custom-switch-xl">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkbox-is-time-specified"
                      checked={isTimeSpecified}
                      onChange={(e) => {
                        setIsTimeSpecified(!isTimeSpecified);
                      }}
                    />
                    <label
                      className="custom-control-label text-hide"
                      htmlFor="checkbox-is-time-specified"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isLoading}
            >
              送信
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 650,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
