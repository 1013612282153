import type { RawRoutesApiParamSetting } from "../../../interfaces/entities";
import { axiosPost } from "../../../utils/AxiosClient";
import type { SelectableApiType } from "../api-code-utils";
import { selectApiParamSetting, parseApiType } from "../api-code-utils";
import { DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM } from "../consts";
import type {
  LoopControlParams,
  RoutesApiBulkAssignInnerParams,
} from "./common";
import { getRoutesApiResultViaAdms } from "./common";

interface Params {
  apiType: SelectableApiType;
  targetDate: string;
  carryStaffIds: number[];
  requestIds: number[];
  routesApiParamSettings: RawRoutesApiParamSetting[];
}

export async function callAssignmentApi(params: Params & LoopControlParams) {
  const { timeout, eventEmitter, ..._params } = params;

  const innerParams = convertParamsIntoInnerParams(_params);

  const postResponse = await postAssignmentTaskViaAdms(innerParams);

  const result = await getRoutesApiResultViaAdms("assign", {
    transactionCode: postResponse.data.transactionCode,
    timeout,
    eventEmitter,
  });

  return result;
}

function convertParamsIntoInnerParams(params: Params) {
  const {
    apiType,
    targetDate,
    carryStaffIds,
    requestIds,
    routesApiParamSettings,
  } = params;

  const { apiCode } = parseApiType(apiType);
  const apiParamSetting = selectApiParamSetting(
    apiType,
    routesApiParamSettings
  );

  const innerParams: RoutesApiBulkAssignInnerParams = {
    api_code: apiCode,
    target_date: targetDate,
    carry_staff_ids: carryStaffIds,
    request_ids: requestIds,
    param_settings: {
      pickup_duration: apiParamSetting.pickup_duration,
      tolerant_seconds_before_pickup_at:
        apiParamSetting.tolerant_seconds_before_pickup_at,
      tolerant_seconds_after_pickup_at:
        apiParamSetting.tolerant_seconds_after_pickup_at,
      dropoff_duration: apiParamSetting.dropoff_duration,
      tolerant_seconds_before_dropoff_at:
        apiParamSetting.tolerant_seconds_before_dropoff_at,
      tolerant_seconds_after_dropoff_at:
        apiParamSetting.tolerant_seconds_before_dropoff_at,
    },
    post_processing: {
      update: false,
    },
  };

  if (
    (innerParams.api_code == "ec-and-nm" ||
      innerParams.api_code == "geoapify") &&
    innerParams.param_settings
  ) {
    innerParams.param_settings["geoapify"] = {
      travel_mode:
        apiParamSetting.geoapify_travel_mode ??
        DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM,
      max_speed: apiParamSetting.geoapify_max_speed,
      // ec-and-nmの時には不要だけど、
      // あったところで(nullが設定されたところで)動作に影響はないので面倒な分岐はしない
      optimization_type: apiParamSetting.geoapify_optimization_type,
    };
  }

  return innerParams;
}

async function postAssignmentTaskViaAdms(
  params: RoutesApiBulkAssignInnerParams
) {
  const response = (await axiosPost.post("/api/routes_apis/task/assign", {
    ...params,
  })) as {
    data: {
      message: string;
      transactionCode: string;
    };
  };
  return response;
}
