import React from "react";
import { observer } from "mobx-react";

import FacilityMarker from "../components/FacilityMarker";
import WktMultiPolygon from "../components/WktMultiPolygon";
import GoogleMap from '../components/Common/GoogleMap';
import MapAttributes from "../constants/MapAttributes";
import Facility from "../interfaces/Facility";

declare var gon: any;

interface Props {
  facility: Facility;
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean
}

class FacilityAreaMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false
    };
  }

  componentDidMount(): void {
  }

  render() {
    const { facility } = this.props;

    return (
      <div className={'request-map-container'}>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={facility}
          defaultZoom={13}
          center={facility}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => this.setState({
            map: map,
            mapApi: maps,
            mapLoaded: true
          })}
        >
          {(this.state.mapApi) && (
            <WktMultiPolygon
              map={this.state.map}
              mapApi={this.state.mapApi}
              wktText={facility.within_area_wkt}
            />
          )}
          <FacilityMarker
            lat={facility.lat}
            lng={facility.lng}
            facility={facility}
          />
        </GoogleMap>
      </div>
    );
  }å

}

export default observer(FacilityAreaMap);
