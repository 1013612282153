import format from 'date-fns/format'
import _ from 'lodash'
import { observer } from "mobx-react";
import React from "react";
import Modal from 'react-modal';

interface ImageInfo {
  url: string;
  key: string;
  staffName: string;
  tookAt: string;
}

interface Props {
  imageInfo: ImageInfo
}

interface State {
  modalIsOpen: boolean;
}

Modal.setAppElement('#wrapper');

class ElectronicSignatureImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
  }

  componentDidMount(): void {
  }

  clickImage(): void {
    // 「位置情報 読込日時」という文字が上に被ってしまうので一旦非表示にする
    const positionReadAtElement = document.getElementById("request-map-postion-read-at")!;  
    positionReadAtElement.style.display = 'none';
    this.setState({modalIsOpen: true});
  }

  closeModal(): void {
    // 消したものを戻す
    const positionReadAtElement = document.getElementById("request-map-postion-read-at")!;  
    positionReadAtElement.style.display = 'inline';
    this.setState({modalIsOpen: false});
  }

  render() {
    if (!this.props.imageInfo.key) {
      return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span className='h5 mb-2 text-gray-800'>電子受領サイン画像</span>
          <span>なし</span>
        </div>
      )
    }

    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <span className='h5 mb-2 text-gray-800'>電子受領サイン画像</span>
        <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center'}}>
          {this.renderImage()}
        </div>
        {this.props.imageInfo.key && this.renderModal()}
      </div>
    );
  }

  private renderImage() {
    const imageInfo = this.props.imageInfo;
    
    return (
      <div
        key={imageInfo.key}
        style={{height: 120, width: 120, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        onClick={() => this.clickImage()}
      >
        <img src={imageInfo.url} style={{maxHeight: 100, maxWidth: 100}}></img>
      </div>
    )
  }

  private renderModal() {
    const imageInfo = this.props.imageInfo;

    const signedTime = imageInfo.tookAt? format(new Date(imageInfo.tookAt), 'yyyy年MM月dd日 HH時mm分ss秒') : null;

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={() => this.closeModal()}
        style={customStyles}
        contentLabel="Electronic signature image detail dialog"
      >
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', marginBottom: 5}}>
            <div style={{justifyContent: 'flex-end', flexGrow: 2}}>サイン取得者 : </div>
            <div style={{justifyContent: 'flex-start', flexGrow: 10}}>{imageInfo.staffName}</div>
          </div>
          <div style={{display: 'flex', marginBottom: 5}}>
            <div style={{justifyContent: 'flex-end', flexGrow: 2}}>サイン日時 : </div>
            <div style={{justifyContent: 'flex-start', flexGrow: 10}}>{signedTime}</div>
          </div>
          <div style={{margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img src={imageInfo.url} style={{maxHeight: 900, maxWidth: 900}}></img>
          </div>
          <div style={{margin: 10, display: 'flex', justifyContent: 'flex-end'}}>
            <button onClick={() => this.closeModal()} className='btn btn-secondary'>閉じる</button>
          </div>
        </div>
      </Modal>
    )
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'scroll',
    height: '600px'
  }
};

export default observer(ElectronicSignatureImage);
