import { action, observable, set } from "mobx";
import {SpotRemarkModel} from "./SpotRemarkModel";
import SpotRemarksResponse from "../interfaces/SpotRemarksResponse";



export class VisibleSpotRemarkModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  model: SpotRemarkModel;

  visible: boolean = true;

  constructor(response: SpotRemarksResponse) {
    this.id = response.id;
    this.key = response.id;

    this.model = new SpotRemarkModel(response);
  }

  @action
  public toggleVisible() {
    this.visible = !this.visible
  }
}
