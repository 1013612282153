import React from "react";

import { MapMarkerPin } from "./MapMarkerPin";
import { FacilityInfoCard } from "../components/FacilityInfoCard";
import { MapInfoWindow } from "../components/MapInfoWindow";
import MarkerColors from "../constants/MarkerColors";
import Facility from "../interfaces/Facility";

interface Props {
  facility: Facility;
  lat: number;
  lng: number;
  zoomLevel?: number;
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;
}

interface State {
  showInfoWindow: boolean;
}

export default class FacilityMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showInfoWindow: false,
    };
  }

  onClickCardClose = (event: Event) => {
    this.setState({
      showInfoWindow: false,
    });
  }

  onClickMarker = (event: Event) => {
    this.setState({
      showInfoWindow: !this.state.showInfoWindow,
    });
  }

  render() {
    const pinSize = 24;

    const { facility } = this.props;
    const { showInfoWindow } = this.state;

    return (
      <div>
        <MapMarkerPin
          lat={facility.lat}
          lng={facility.lng}
          size={showInfoWindow ? pinSize * 1.5 : pinSize}
          svgIcon="building"
          svgIconColor="#ffffff"
          pinBackgroundColor={MarkerColors.ORANGE_CSS_COLOR}
          onClick={this.onClickMarker}
        />
        {showInfoWindow && (
          <MapInfoWindow>
            <FacilityInfoCard
              facility={facility}
              onCloseClick={this.onClickCardClose}
            />
          </MapInfoWindow>
        )}
      </div>
    );
  }
}
