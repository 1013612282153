import React from "react";
import { MapMarkerPin } from "../components/MapMarkerPin";
import MapAttributes from "../constants/MapAttributes";
import { calcCenter } from "../utils/GeographyUtils";
import GoogleMap from "../components/Common/GoogleMap";
import CircleArea from "./CircleArea";
import MarkerColors from "../constants/MarkerColors";
import _ from "lodash";

declare var gon: any;
const DEFAULT_ZOOM = 16;

interface Props {
  sender: {
    lat: number;
    lng: number;
  };
  receiver: {
    lat: number;
    lng: number;
  };
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  bounds: null;
}

class SevenRequestOfferMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    console.log(props);

    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      bounds: null,
    };
  }

  componentDidMount(): void {}

  render() {
    const center = calcCenter({
      points: [this.props.sender, this.props.receiver],
      areaWkts: [],
    });
    return (
      <div className="d-flex" style={{ height: "calc(100vh - 400px)" }}>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          // こいつ(defaultZoom)がないと「Error: Invalid LatLng object: (NaN, NaN)」というエラーになる
          defaultZoom={14}
          defaultCenter={{ lng: center.lng, lat: center.lat }}
          center={{ lng: center.lng, lat: center.lat }}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => {
            this.setState(
              {
                map: map,
                mapApi: maps,
                mapLoaded: true,
              },
              () => {
                this.executeFitBounds(this.props.sender, this.props.receiver);
              }
            );
          }}
        >
          {this.createShopMarkers()}
          {this.createCustomerMarkers()}
        </GoogleMap>
      </div>
    );
  }

  // 配送元・配送先が地図表示範囲に収まるようにビューポートを設定する
  private executeFitBounds(
    sender: { lat: number; lng: number },
    receiver: { lat: number; lng: number }
  ) {
    const bounds = new google.maps.LatLngBounds(
      // 南西隅, 北東隅の順に指定しないと正しく機能しないため計算して指定
      {
        lat: Math.min(sender.lat, receiver.lat),
        lng: Math.min(sender.lng, receiver.lng),
      },
      {
        lat: Math.max(sender.lat, receiver.lat),
        lng: Math.max(sender.lng, receiver.lng),
      }
    );

    if (this.state.map) {
      this.state.map.fitBounds(bounds);
      // 地点間の距離が近すぎる状態でズームすると周辺情報が全くわからないので、
      // fitBoundsを実行した結果のzoom値がDEFAULT_ZOOMより大きい場合はDEFAULT_ZOOMに戻す
      const zoomLevel = this.state.map.getZoom();
      if (zoomLevel > DEFAULT_ZOOM) {
        this.state.map.setZoom(DEFAULT_ZOOM);
      }
    }
  }

  createShopMarkers() {
    return (
      <MapMarkerPin
        key={"marker_" + Math.random()}
        lat={this.props.sender.lat}
        lng={this.props.sender.lng}
        svgIcon="shop"
        size={28}
        onClick={() => {}}
      />
    );
  }

  createCustomerMarkers() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }
    // 顧客の位置を示す円を200mの範囲で描画
    return (
      <CircleArea
        key={Math.random()}
        center={{
          lat: this.props.receiver.lat,
          lng: this.props.receiver.lng,
        }}
        range={200}
        map={this.state.map}
        mapApi={this.state.mapApi}
        color={MarkerColors.DEFAULT_COLOR}
      />
    );
  }
}

export default SevenRequestOfferMap;
