import { action, observable, set } from "mobx";
import { ServiceAreaModel } from "../models/ServiceAreaModel";
import ServiceAreasResponse from "../interfaces/ServiceAreasResponse";

export class VisibleServiceAreaModel{
  @observable.ref
  id: number;

  @observable.ref
  key: number;
  
  model: ServiceAreaModel;
  
  visible: boolean = true;
  
  constructor(response: ServiceAreasResponse) {
    this.id = response.id;
    this.key = response.id;
    
    this.model = new ServiceAreaModel(response);
  }
  
  @action
  public toggleVisible() {
    this.visible = !this.visible
  }
}
