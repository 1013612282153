import GoogleMapReact, {
  BootstrapURLKeys,
  MapOptions,
  Maps,
  Point,
  Coords,
  ClickEventValue,
  ChangeEventValue,
  MapTypeStyle,
} from "google-map-react";
import React from "react";
import { mapStyles } from "../../styles/MapStyles";

interface Props {
  bootstrapURLKeys?: BootstrapURLKeys;
  defaultCenter?: Coords;
  center?: Coords;
  defaultZoom?: number;
  zoom?: number;
  hoverDistance?: number;
  options?: MapOptions | ((maps: Maps) => MapOptions);
  margin?: any[];
  debounced?: boolean;
  draggable?: boolean;
  layerTypes?: string[];
  onClick?(value: ClickEventValue): any;
  onChange?(value: ChangeEventValue): any;
  resetBoundsOnResize?: boolean;
  onChildClick?(hoverKey: any, childProps: any): void;
  onChildMouseEnter?(hoverKey: any, childProps: any): void;
  onChildMouseLeave?(hoverKey: any, childProps: any): void;
  onChildMouseDown?(childKey: any, childProps: any, mouse: any): void;
  onChildMouseUp?(childKey: any, childProps: any, mouse: any): void;
  onChildMouseMove?(childKey: any, childProps: any, mouse: any): void;
  onDrag?(args: any): void;
  onZoomAnimationStart?(args: any): void;
  onZoomAnimationEnd?(args: any): void;
  onMapTypeIdChange?(args: any): void;
  distanceToMouse?(pt: Point, mousePos: Point, markerProps?: object): number;
  googleMapLoader?(bootstrapURLKeys: any): void;
  onGoogleApiLoaded?(maps: { map: any; maps: any }): void;
  onTilesLoaded?(): void;
  yesIWantToUseGoogleMapApiInternals?: boolean;
  style?: React.HTMLProps<HTMLDivElement>;
  children?: React.ReactNode;
}

export default class GoogleMap extends React.Component<Props> {
  /**
   * optionsのstyles内にsaturationのみが指定されたオブジェクトが存在するかどうかチェックするメソッド
   * @param options
   * @returns
   */
  existsGlobalSaturationSetting = (options: MapOptions | undefined) => {
    if (options == null || options.styles == null) {
      return false;
    }

    const hasGlobalSaturation = options.styles
      .filter((style) => style.featureType == null && style.elementType == null)
      .some((style) => style.stylers.some((styler) => "saturation" in styler));

    return hasGlobalSaturation;
  };

  /**
   * optionsにglobalな(全体に効く)saturationの設定がなされている場合、
   * mapStylesからsaturationの設定を取り除いてマージするメソッド
   * @param options
   * @returns
   */
  mergeStyles = (options: MapOptions): MapOptions => {
    const exists = this.existsGlobalSaturationSetting(options);
    const styles = ([] as MapTypeStyle[]).concat(
      exists
        ? mapStyles.filter(
            (style) =>
              // featureTypeが存在する、
              // もしくはelementTypeが存在する、
              // もしくはsaturationがstylersに存在しない、
              // もののみを設定
              !(
                style.featureType == null &&
                style.elementType == null &&
                style.stylers.some((styler) => "saturation" in styler)
              )
          )
        : mapStyles,
      options.styles || []
    );
    return {
      ...options,
      styles,
    };
  };

  /**
   * 指定されたoptionsに対して、mapStylesをマージするメソッド
   * @param options
   * @returns
   */
  mergeOptions = (
    options: MapOptions | ((maps: Maps) => MapOptions) | undefined
  ): MapOptions | ((maps: Maps) => MapOptions) => {
    if (options == null) {
      return { styles: mapStyles };
    }

    if (typeof options == "function") {
      return (maps: Maps) => {
        const _options = this.mergeStyles(options(maps));
        return _options;
      };
    }

    const _options = this.mergeStyles(options);
    return _options;
  };

  render() {
    const {
      bootstrapURLKeys,
      defaultCenter,
      center,
      defaultZoom,
      zoom,
      hoverDistance,
      options,
      margin,
      debounced,
      draggable,
      layerTypes,
      onClick,
      onChange,
      resetBoundsOnResize,
      onChildClick,
      onChildMouseEnter,
      onChildMouseLeave,
      onChildMouseDown,
      onChildMouseUp,
      onChildMouseMove,
      onDrag,
      onZoomAnimationStart,
      onZoomAnimationEnd,
      onMapTypeIdChange,
      distanceToMouse,
      googleMapLoader,
      onGoogleApiLoaded,
      onTilesLoaded,
      yesIWantToUseGoogleMapApiInternals,
      style,
      children,
    } = this.props;

    return (
      <GoogleMapReact
        bootstrapURLKeys={bootstrapURLKeys}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={defaultZoom}
        zoom={zoom}
        hoverDistance={hoverDistance}
        options={this.mergeOptions(options)}
        margin={margin}
        debounced={debounced}
        draggable={draggable}
        layerTypes={layerTypes}
        onClick={onClick}
        onChange={onChange}
        resetBoundsOnResize={resetBoundsOnResize}
        onChildClick={onChildClick}
        onChildMouseEnter={onChildMouseEnter}
        onChildMouseLeave={onChildMouseLeave}
        onChildMouseDown={onChildMouseDown}
        onChildMouseUp={onChildMouseUp}
        onChildMouseMove={onChildMouseMove}
        onDrag={onDrag}
        onZoomAnimationStart={onZoomAnimationStart}
        onZoomAnimationEnd={onZoomAnimationEnd}
        onMapTypeIdChange={onMapTypeIdChange}
        distanceToMouse={distanceToMouse}
        googleMapLoader={googleMapLoader}
        onGoogleApiLoaded={onGoogleApiLoaded}
        onTilesLoaded={onTilesLoaded}
        yesIWantToUseGoogleMapApiInternals={yesIWantToUseGoogleMapApiInternals}
        style={style}
      >
        {children}
      </GoogleMapReact>
    );
  }
}
