import React from "react";
import { MapMarkerPin } from "./MapMarkerPin";
import Colors from "../constants/BootstrapColors";
import { MapInfoWindow } from "./MapInfoWindow";
import { ReceiverCard } from "./MapInfoWindow/ReceiverCard";
import ThirdPartyDeliveryTask from "../interfaces/ThirdPartyDeliveryTask";

interface Props {
  lat: number;
  lng: number;
  iconColor?: string;
  zIndex?: number;
  zoomLevel?: number;
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean;

  thirdPartyDeliveryTask: ThirdPartyDeliveryTask;
  index: number;
}

interface State {
  showInfoWindow: boolean;
}

export default class ThirdPartyDeliveryTaskReceiverSmallMarker extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false,
    };
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow;
    const pinSize = 24;

    return (
      <div>
        <MapMarkerPin
          svgIcon="person"
          pinShape="square"
          size={showInfoWindow ? pinSize * 1.5 : pinSize}
          pinBackgroundColor="#fff"
          svgIconColor={this.props.iconColor ?? Colors.INFO_COLOR}
          zIndex={this.props.zIndex}
          onClick={this.onClickMarker.bind(this)}
          topRightBadgeStyle={{
            backgroundColor: "blue",
          }}
          topRightBadgeText={String(this.props.index)}
        />
        {showInfoWindow && (
          <MapInfoWindow>
            <ReceiverCard
              deliveryRequest={this.props.thirdPartyDeliveryTask}
              onCloseClick={() => {
                this.setState({
                  showInfoWindow: false,
                });
              }}
            />
          </MapInfoWindow>
        )}
      </div>
    );
  }

  private onClickMarker(event: Event) {
    this.setState({ showInfoWindow: !this.state.showInfoWindow });
    event.preventDefault();
    event.stopPropagation();
  }
}
