import { ReactNode, useCallback, useRef, useState } from "react";
import Data = google.maps.Data;
import Feature = google.maps.Data.Feature;
// @ts-ignore
import { stringify } from "wkt";
import {
  SaveFailureAlert,
  SaveSuccessAlert,
  DestroySuccessAlert,
  DestroyFailureAlert,
  TargetNotExistAlert,
  NotNullFailureAlert
} from "../parts/MAPAlerts";
import { ToastOptions } from "../../ui/GmapsUI/controls/Notifications";
import {
  multiPolygonsUnion,
  multiPolygonsDifference
} from "../utils/WktPolygonUtils";

const TOAST_OPTIONS = { lifetimeMs: 2000 };

interface Props {
  wkt: string;
  showToast: boolean;
  onDestroy?: () => Promise<void>;
  onRemoveArea?: (multiPolygonsWkt: string) => Promise<void>;
  onAddArea?: (multiPolygonsWkt: string) => Promise<void>;
  onUpdateByWkt?: (multiPolygonsWkt: string) => Promise<void>;
  map: google.maps.Map | undefined;
  notNull?: boolean;
}

export default function useMapAreaPicker(props: Props) {
  const {
    onUpdateByWkt,
    onDestroy,
    wkt,
    map,
    showToast = true,
    notNull = false
  } = props;

  const refMap = useRef<any>();

  // 選択中のエリアを保存する(町域及びHex選択モードから、パス選択モードに切り替え時に選択中エリアを引き継ぐため）
  const [selectedFeature, setSelectedFeature] =
    useState<google.maps.Data.Feature>();

  /**
   * DataLayerから全てのfeaturesを削除する
   * 使用例 ）パス選択、町域選択、Hex選択モードで更新後、選択状態を初期化する
   */
  const resetSelectedFeatures = (currentMap?: google.maps.Map) => {
    if (!currentMap) return;
    currentMap.data.forEach((feature) => currentMap.data.remove(feature));
    setSelectedFeature(undefined);
  };

  /**
   * 町域選択orHex選択でエリアをクリックした時に、選択済みの場合は選択解除し、未選択の場合は選択状態にする
   * @param geometry
   * @param id
   * @param resetIfExists すでに存在した場合、削除ではなく、新しいデータで上書き(削除=>追加)を行いたい時のフラグ
   * @returns
   */
  const toggleFeature = (
    geometry: any,
    id: number | string,
    resetIfExists = false
  ) => {
    if (!map) return;

    const feature = {
      type: "Feature",
      geometry,
      properties: {
        id
      }
    };
    const existFeature = map.data.getFeatureById(feature.properties.id);
    if (resetIfExists && existFeature) {
      map.data.remove(existFeature);
      map.data.addGeoJson(feature, { idPropertyName: "id" });
    } else if (existFeature) {
      // 選択済みだった場合は選択解除
      map.data.remove(existFeature);
    } else {
      // 未選択だった場合は選択状態
      map.data.addGeoJson(feature, { idPropertyName: "id" });
    }
    map.data.toGeoJson((feature) => {
      setSelectedFeature(feature as google.maps.Data.Feature);
    });
  };

  // 選択エリアを追加時に実行
  const addArea = useCallback(
    async (data: Data) => {
      const addAreas = await featuresToPolygonStrings(data);
      if (!addAreas.length) {
        _pushToast(TargetNotExistAlert, TOAST_OPTIONS);
        throw new Error("更新対象のエリアがありません");
      }
      if (wkt) addAreas.push(wkt);
      const unionPolygonString = multiPolygonsUnion(addAreas);

      return await _asyncUpdateByWkt(unionPolygonString)
        .then(() => _pushToast(SaveSuccessAlert, TOAST_OPTIONS))
        .catch((e) => {
          console.error(e);
          if (refMap.current)
            refMap.current.pushToast(SaveFailureAlert, TOAST_OPTIONS);
          throw e;
        });
    },
    [wkt, refMap]
  );

  // 選択エリアを除外時に実行
  const subArea = useCallback(
    async (data: Data) => {
      const removeAreas = await featuresToPolygonStrings(data);
      if (!removeAreas.length) {
        _pushToast(TargetNotExistAlert, TOAST_OPTIONS);
        throw new Error("更新対象のエリアがありません");
      }
      const differedPolygonString = multiPolygonsDifference(wkt, removeAreas);
      if (differedPolygonString) {
        return await _asyncUpdateByWkt(differedPolygonString)
          .then(() => {
            if (refMap.current)
              refMap.current.pushToast(SaveSuccessAlert, TOAST_OPTIONS);
          })
          .catch((e) => {
            console.error(e);
            if (refMap.current)
              refMap.current.pushToast(SaveFailureAlert, TOAST_OPTIONS);
            throw e;
          });
      } else {
        // 選択地域が空の場合は初期化扱い
        if (notNull) {
          _pushToast(NotNullFailureAlert, TOAST_OPTIONS);
          throw new Error("選択地域を空にすることはできません");
        }
        return await _asyncDestroyAll()
          .then(() => _pushToast(SaveSuccessAlert, TOAST_OPTIONS))
          .catch((e) => {
            console.error(e);
            _pushToast(SaveFailureAlert, TOAST_OPTIONS);
          });
      }
    },
    [wkt, refMap]
  );

  // 全て削除時に実行
  const destroyAll = useCallback(async () => {
    return await _asyncDestroyAll()
      .then(() => _pushToast(DestroySuccessAlert, TOAST_OPTIONS))
      .catch((e) => {
        console.error(e);
        _pushToast(DestroyFailureAlert, TOAST_OPTIONS);
        throw e;
      });
  }, [refMap]);

  // 親コンポーネントのupdate()を実行する
  const _asyncUpdateByWkt = useCallback(
    (polygonString: string) => {
      return new Promise((resolve, reject) => {
        if (!onUpdateByWkt) {
          reject("onUpdateByWktが定義されていません");
          return;
        }
        onUpdateByWkt(polygonString)
          .then(() => {
            resolve(true);
          })
          .catch((e: any) => {
            reject(e);
          });
      });
    },
    [onUpdateByWkt]
  );

  // 親コンポーネントのdestroyを実行する
  const _asyncDestroyAll = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!onDestroy) {
        reject("onDestroyが定義されていません");
        return;
      }

      onDestroy()
        .then(() => {
          resolve(true);
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }, [onDestroy]);

  // Toastを表示
  const _pushToast = (alert: ReactNode, options: ToastOptions) => {
    // refMapにGoogleMapsが設置されていない場合はエラーとなる
    if (!refMap.current) return;
    if (!showToast) return;

    refMap.current.pushToast(alert, options);
  };

  return {
    refMap,
    selectedFeature,
    setSelectedFeature,
    toggleFeature,
    resetSelectedFeatures,
    subArea,
    addArea,
    destroyAll
  };
}

// Utils

/**
 * DataLayerのFeaturesをwkk形式のテキストの配列に変換する
 */
const featuresToPolygonStrings = (data?: Data): Promise<string[]> => {
  return new Promise((resolve) => {
    if (!data) {
      resolve([]);
    } else {
      data.toGeoJson((json: any) => {
        const polygonStrings: string[] = (json.features as Feature[]).map(
          (feature) => {
            return stringify(feature);
          }
        );
        resolve(polygonStrings);
      });
    }
  });
};
