import { reaction } from "mobx";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineElement,
  LineController,
  Tooltip,
  Legend,
  PointElement,
  ChartData,
  ChartOptions
} from 'chart.js';
import { Chart } from "react-chartjs-2";
import { H3CapacityTimeline } from "../interfaces/H3CapacityTimeline";
import H3CapatityTimelinesStore from '../stores/H3CapatityTimelinesStore';
import { H3IndexModel } from "../models/H3IndexModel";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineElement,
  LineController,
  Tooltip,
  Legend,
  PointElement
);


interface NeedProps {
  h3Index: H3IndexModel | null
}
interface DefaultProps {
  useProps: boolean
  he3CapacityTimelines: H3CapacityTimeline[]
}

type Props = NeedProps & Partial<DefaultProps>

interface State {
  he3CapacityTimelines: H3CapacityTimeline[]
}

export default class H3CapacityTimelineChart extends React.Component<Props, State> {
  barChartRef: React.RefObject<ChartJS<'bar' | 'line'>>;
  timeInterbalId: any;

  public static defaultProps: Partial<DefaultProps> = {
    useProps: false,
    he3CapacityTimelines: []
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      he3CapacityTimelines: []
    };

    // 再描画させるためにupdatedHexKeyを監視して、更新があり次第、このstateのtimelinesを更新させる。
    if (!props.useProps) {
      reaction(
        (_) => H3CapatityTimelinesStore.updatedHexKey,
        (value, _) => {
          // 「|| ''」としてしまうと、h3Indexがnullの場合にtrueになってしまうので、一致しないはずの「none」
          if (value && value.startsWith(this.props.h3Index?.h3Index || 'none')) {
            this.setState({ he3CapacityTimelines: this.getRawTimelines() });
          }
        }
      );
    }
  }

  componentDidMount() {
    if (!this.props.useProps) {
      this.timeInterbalId = setInterval(
        () => H3CapatityTimelinesStore.loadTimelines(this.props.h3Index?.h3Index), 60_000
      );
    }
  }

  componentWillUnmount() {
    if (this.timeInterbalId) {
      clearInterval(this.timeInterbalId);
    }
  }

  render() {
    const h3Index = this.props.h3Index;
    if (!h3Index) {
      return (
        <div className={"hex-capacity-chart-title"}>
          エリア未選択です
        </div>
      );
    }

    const timelines = this.getRawTimelines();
    if (timelines.length === 0) {
      return (
        <div className={"hex-capacity-chart-title"}>
          ロード中
        </div>
      );
    }

    const data: ChartData<'bar' | 'line'> = {
      labels: this.getLabels(),
      datasets: [
        {
          type: 'line',
          label: 'キャパシティ',
          yAxisID: 'axisCapacity',
          fill: false,
          data: this.getCapacities(),
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
        },
        {
          type: 'bar',
          label: '見積もり到着時間',
          yAxisID: 'axisRequiredMinute',
          data: this.getRequiredMinutes(),
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
        },
        {
          type: 'line',
          label: 'スタッフ予想数',
          yAxisID: 'axisCapacity',
          fill: false,
          data: this.getPredictions().map(val => val ?? null), // undefinedをnullに変換しエラー回避
          backgroundColor: 'rgba(162, 235, 54, 0.2)',
          borderColor: 'rgba(162, 235, 54, 1)',
        },
      ],
    };
    const options: ChartOptions<'bar' | 'line'> = {
      scales: {
        axisCapacity: {
          position: 'left',
          title: {
            display: true,
            text: 'キャパシティ/スタッフ予想数',
            font: {
              size: 14
            }
          },
          beginAtZero: true,
          min: 0,
          max: 10
        },
        axisRequiredMinute: {
          position: 'right',
          // 軸ラベル設定
          title: {
            display: true,
            text: '見積もり到着時間',
            font: {
              size: 14
            },
          },
          beginAtZero: true,
          min: 0,
          max: 60,
        }
      },
      plugins: {
        legend: {
          display: true, // 複数表示なので必要
          onHover: function (e, legendItem, legend) {
            const target = e.native?.target as HTMLElement;
            if (target) {
              target.style.cursor = 'pointer';
            }
          }
        },
      },
      onHover: function (e, chartElement) {
        const target = e.native?.target as HTMLElement;
        if (chartElement.length) {
          if (target) target.style.cursor = 'pointer';
        } else {
          if (target) target.style.cursor = 'default';
        }
      },
      maintainAspectRatio: false
    };

    const h3IndexId = h3Index.id;
    const h3IndexNickName = h3Index.nickName || "名前をつける";

    const isTimelinesLoding = H3CapatityTimelinesStore.isLoading(h3Index.h3Index);

    return (
      <div className={"hex-capacity-chart-container"}
        style={{
          opacity: isTimelinesLoding ? 0.5 : 1,
          transition: "opacity 0.5s ease"
        }}
      >
        <div className={"hex-capacity-chart-title"}>
          <div>
            インデックス : {h3Index.h3Index}
          </div>
          <div className={"ml-3"}>
            <a href={`/h3_index/${h3IndexId}/edit`}>
              {h3IndexNickName}
            </a>
          </div>
        </div>
        <Chart
          type='bar'
          data={data}
          options={options}
          onClick={this.handleClick}
          ref={this.barChartRef}
          height={160}
        />
      </div>
    );
  }

  // 配列にはlineとbarのChartElementが0 1 で２個入っている
  handleClick = async (e: any) => { // ChartElementがimportできないので、、、
    if (
      e == null
      || !Array.isArray(e)
      || e.length === 0
      || e[0] == null
      || !e[0].hasOwnProperty('_index')
    ) {
      return;
    }
    const timeline = this.getRawTimelines()[e[0]._index];
    // 画面遷移の良い方法が思いつかないので、相対パスのlocation.hrefでの遷移とする
    location.href = '/h3_capacity_timelines/' + timeline.id + '/edit'
    // `${location.protocol}//${location.hostname}/h3_capacity_timelines/${timeline.id}/edit`
  }

  // x軸は同じ時間を使用
  private getLabels() {
    const labels = (this.getRawTimelines()).map(
      timeline => {
        const date: Date = new Date(timeline.sdate);
        let minutes = String(date.getMinutes());
        if (minutes.length === 1) {
          minutes = '0' + minutes;
        }
        return date.getHours() + ':' + minutes;
      }
    );
    return labels;
  }

  private getCapacities() {
    // capacityは小数点第２位を切り捨てて表示
    const capacities = (this.getRawTimelines()).map(
      timeline => Math.round(+timeline.capacity * 10) / 10
    );
    return capacities;
  }

  private getRequiredMinutes() {
    const requiredMinutes = (this.getRawTimelines()).map(
      timeline => timeline.manual_required_minute ?? timeline.required_minute
    );
    return requiredMinutes;
  }
  private getPredictions() {
    const predictions = (this.getRawTimelines()).map(
      timeline => timeline.staff_count_prediction
    );
    return predictions;
  }

  private getRawTimelines() {
    const timelines = this.props.useProps ?
      this.props.he3CapacityTimelines! :
      H3CapatityTimelinesStore.convertTimelinesToRaw(
        H3CapatityTimelinesStore.getTimelinesInfoWithoutLoad(this.props.h3Index?.h3Index).timelines
      );
    return timelines;
  }
}
