import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useState, FC, useEffect } from "react";
import { checkCanChangeAssigning } from "../../../utils/RequestEntityUtils";
import { formatIso8601Time } from "../../../utils/TimeUtils";
import type { RawCarryStaff, RequestSequence } from "../interfaces";
import { searchConditionsStore } from "../stores";
import { getTimeLabel, isRouteAssignable } from "../utils";
import I18n from "../../../packs/i18n/i18n";
import { toast } from "react-toastify";
import { ToastOptions } from "react-toastify/dist/types";
import { ExtendedRawRequest } from "../../../utils/routes-apis/common-utils";

interface Props {
  index: number;
  isDragging: boolean;
  canDrag: boolean;
  sequence: RequestSequence;
  carryStaff: RawCarryStaff;
  handleChangeReassignTarget: (
    request: ExtendedRawRequest,
    checked: boolean
  ) => void;
  toastOptions: ToastOptions;
  reassignTargetRequests: ExtendedRawRequest[];
}

export const SortableRequest: FC<Props> = (props) => {
  const [onMouseOver, setOnMouseOver] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.sequence.id });
  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    visibility: props.isDragging ? "hidden" : "visible",
    backgroundColor: props.canDrag ? undefined : "#f8f9fa",
  };

  // すでに対応済みのもの(ステータスがpickupで、destinationTypeがsenderのもの)は
  // ここには来ない(SortableRequestとして作成されない)ので、全てドラッグ可能とする
  const _props = {
    ...listeners,
    ...attributes,
  };

  useEffect(() => {
    $(`[data-toggle="tooltip_${props.sequence.id}"]`).tooltip();
  }, [props.sequence]);

  const handleOnClick = () => {
    const { request } = props.sequence;
    if (
      !searchConditionsStore.isReassignMode ||
      checkCanChangeAssigning(request)
    ) {
      return;
    }
    toast.warn(
      `ステータスが${I18n.t(
        `enums.request.status.${request.status}`
      )}のため変更することが出来ません。`,
      props.toastOptions
    );
  };

  const alertMessage = getAlertMessage(props.carryStaff, props.sequence);

  const getCursorStyle = () => {
    if (!searchConditionsStore.isReassignMode) {
      return {};
    }
    return {
      cursor: "pointer",
    };
  };

  return (
    <div
      className="d-flex"
      style={
        alertMessage ? { backgroundColor: "rgba(255, 0, 0, 0.2)" } : undefined
      }
      onClick={handleOnClick}
    >
      {props.index >= 0 ? (
        <div
          className="d-flex justify-content-center align-items-center p-2 col-1"
          data-toggle-id={`tooltip_${props.sequence.id}`}
          data-toggle={`tooltip_${props.sequence.id}`}
          data-original-title={alertMessage}
          style={{ width: "50px" }}
        >
          <span>{props.index + 1}</span>
        </div>
      ) : null}
      <div
        className={`card shadow d-flex flex-row flex-grow-1 p-2 ${
          props.isDragging ? "col-11" : ""
        }`}
        style={style}
        ref={setNodeRef}
      >
        {searchConditionsStore.isReassignMode && (
          <input
            type="checkbox"
            id={`assign-change-target-${props.sequence.id}`}
            className="mr-10"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.handleChangeReassignTarget(
                props.sequence.request,
                event.target.checked
              )
            }
            disabled={!checkCanChangeAssigning(props.sequence.request)}
            style={{ cursor: "pointer" }}
            checked={props.reassignTargetRequests.some(
              (req) => req.id === props.sequence.request.id
            )}
          />
        )}
        <label
          htmlFor={`assign-change-target-${props.sequence.id}`}
          className="w-100"
          style={getCursorStyle()}
        >
          <div className="d-flex flex-column flex-grow-1">
            <div
              className="d-flex align-items-center mb-1"
              style={{ fontSize: 15, fontWeight: 500 }}
            >
              <span className="mr-2">
                {props.sequence.destination_type == "sender" ? (
                  <span>
                    <i className="fas fa-store text-info" /> 配達元
                  </span>
                ) : (
                  <span>
                    <i className="fas fa-user" /> 配達先
                  </span>
                )}
              </span>
              <span className="mr-2">
                <i className="fas fa-angle-right" />
              </span>
              <span>
                {getTimeLabel(
                  props.sequence,
                  searchConditionsStore.targetTerm.isPast
                )}
              </span>
              {props.sequence.work_start_time ? (
                <span className="ml-2">
                  到着予想時間：
                  {formatIso8601Time(props.sequence.work_start_time)}
                </span>
              ) : null}
            </div>
            <div className="d-flex flex-column" style={{ fontSize: 12 }}>
              <div className={"d-flex"}>
                <a href={`/requests/${props.sequence.request.id}/edit`}>
                  依頼No.{props.sequence.request.id}
                </a>
                <div>
                  {searchConditionsStore.isReassignMode &&
                    !checkCanChangeAssigning(props.sequence.request) && (
                      <span
                        className={"badge truncate align-middle ml-2"}
                        style={{
                          border: "1px solid #e74a3b",
                          backgroundColor: "transparent",
                          color: "#e74a3b",
                        }}
                      >
                        アサイン変更不可
                      </span>
                    )}
                </div>
              </div>
              <div>
                <span>
                  {props.sequence.destination_type == "sender"
                    ? props.sequence.request.sender_full_address
                    : props.sequence.request.receiver_full_address}
                </span>
              </div>
            </div>
          </div>
        </label>
        {!props.canDrag ? null : (
          <div
            className="p-1 d-flex flex-column justify-content-center align-items-center"
            style={{
              cursor: "grab",
              fontSize: 10,
              backgroundColor: onMouseOver ? "#f8f9fa" : undefined,
            }}
            onMouseOver={() => setOnMouseOver(true)}
            onMouseLeave={() => setOnMouseOver(false)}
            data-cypress="draggable-handle"
            {..._props}
          >
            <i className="fas fa-lg fa-hand-paper" />
          </div>
        )}
      </div>
    </div>
  );
};

function getAlertMessage(carryStaff: RawCarryStaff, sequence: RequestSequence) {
  let alertMessage = sequence.is_disallowed
    ? "配達元より配達先が先に設定されています。"
    : undefined;
  if (isRouteAssignable(carryStaff)) {
    // is_disallowed条件による警告メッセージを優先して表示する
    // ルート配達員にしかスケジュールは現状存在しないのでその対応
    alertMessage =
      alertMessage ??
      (sequence.is_outside_schedule
        ? "設定されているスケジュール外での配達です。"
        : undefined);
  }

  return alertMessage;
}
