import { action, observable, runInAction } from "mobx";
import Location from "../../../../interfaces/Location";
import { getBboxCenterFrom } from "../../../../utils/GeographyUtils";

export class LocationsStore {
  @observable.shallow
  locations: Location[] = [];

  @observable
  isLoading = false;

  @observable
  errorMessage: string | undefined = undefined;

  @observable
  updatedAt: Date | undefined = undefined;

  private abortController: AbortController | undefined = undefined;

  constructor() {
    this.abortController = new AbortController();
  }

  @action
  public async loadLocations(withinAreaWkt: string, rangeMeter: number) {
    if (!withinAreaWkt || rangeMeter < 0) {
      this.errorMessage = "不正な値です。";
      return;
    }

    if (this.abortController) {
      this.abortController.abort();
    }

    this.isLoading = true;
    this.errorMessage = undefined;

    const bboxCenter = getBboxCenterFrom(withinAreaWkt);
    if (!bboxCenter) return;
    const centerLat = bboxCenter.lat.toString();
    const centerLng = bboxCenter.lng.toString();

    try {
      const locations = await this._load(centerLat, centerLng, rangeMeter);

      runInAction(() => {
        this.locations = locations;
        this.isLoading = false;
        this.updatedAt = new Date();
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.errorMessage =
          error.response?.data?.message || "データの取得に失敗しました。";
        this.isLoading = false;
      });
    }
  }

  private async _load(
    centerLat: string,
    centerLng: string,
    rangeMeter: number
  ) {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();

    const urlParams = new URLSearchParams([
      ["center_lat", centerLat],
      ["center_lng", centerLng],
      ["radius_meter", String(rangeMeter)],
    ]);

    try {
      const response = await fetch(
        "/api/spot_remarks/locations?" + urlParams.toString(),
        {
          signal: this.abortController.signal,
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      if (error.name === "AbortError") {
        console.debug("[LocationsStore] Fetch aborted");
      } else {
        runInAction(() => {
          this.errorMessage = "データの取得に失敗しました。";
        });
      }
    } finally {
      this.abortController = undefined;
    }
  }
}

const singleton = new LocationsStore();
export default singleton;
