import React, { ChangeEvent } from "react";
import { Button } from "react-bootstrap";

interface Props {
  selectType: string;
  onChangeSelectType: (name: string) => void;
}

export default function MAPToolboxContentForSpotMarker(props: Props) {
  const { selectType, onChangeSelectType } = props;

  const setSelectType = async (name: string) => {
    onChangeSelectType(name);
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className={"spot-button-container"}>
            <Button
              disabled={selectType == "bicycle" ? true : false}
              onClick={() => onChangeSelectType("bicycle")}
              style={{
                ...styles.mapButton,
                ...styles.senderButton,
                backgroundColor: selectType == "bicycle" ? "red" : "orange",
                flexDirection: "column"
              }}
            >
              <div className={"buttonView"}>
                <div className={"buttonLabel"}>駐輪</div>
                <div>
                  <i
                    className={"fas fa-bicycle"}
                    style={{ fontSize: "16px", color: "white" }}
                  />
                </div>
              </div>
            </Button>
            <Button
              disabled={selectType == "parking" ? true : false}
              onClick={() => onChangeSelectType("parking")}
              style={{
                ...styles.mapButton,
                ...styles.senderButton,
                backgroundColor: selectType == "parking" ? "red" : "orange",
                flexDirection: "column"
              }}
            >
              <div className={"buttonView"}>
                <div className={"buttonLabel"}>駐車</div>
                <div>
                  <i
                    className={"fas fa-parking"}
                    style={{ fontSize: "16px", color: "white" }}
                  />
                </div>
              </div>
            </Button>
            <Button
              disabled={selectType == "enter" ? true : false}
              onClick={() => onChangeSelectType("enter")}
              style={{
                ...styles.mapButton,
                ...styles.senderButton,
                backgroundColor: selectType == "enter" ? "red" : "orange",
                flexDirection: "column"
              }}
            >
              <div className={"buttonView"}>
                <div className={"buttonLabel"}>入口</div>
                <div>
                  <i
                    className={"fas fa-door-open"}
                    style={{ fontSize: "16px", color: "white" }}
                  />
                </div>
              </div>
            </Button>
            <Button
              disabled={selectType == "box" ? true : false}
              onClick={() => onChangeSelectType("box")}
              style={{
                ...styles.mapButton,
                ...styles.senderButton,
                backgroundColor: selectType == "box" ? "red" : "orange",
                flexDirection: "column"
              }}
            >
              <div className={"buttonView"}>
                <div className={"buttonLabel"}>宅配box</div>
                <i
                  className={"fas fa-box-open"}
                  style={{ fontSize: "16px", color: "white" }}
                />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = {
  mapButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    width: 50
  },
  senderButton: {
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    borderBottomColor: "white",
    borderBottomWidth: "1px"
  }
};
