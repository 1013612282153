const staffTypes = {
  disabled: -1,
  invalid_state: 0,
  anycarry: 1,
  vendor: 2,
  ato: 3,
  gigworker: 4,
  genie: 5,
  route: 6,
  uber_direct: 10,
  wolt: 15,
  menu: 20
} as const;

export const carryStaffConst: {
  STAFF_TYPES: { [key in keyof typeof staffTypes]: (typeof staffTypes)[key] };
  STAFF_TYPE_CODES: { [key in keyof typeof staffTypes]: key };
} = {
  STAFF_TYPES: staffTypes,
  STAFF_TYPE_CODES: {
    disabled: "disabled",
    invalid_state: "invalid_state",
    anycarry: "anycarry",
    vendor: "vendor",
    ato: "ato",
    gigworker: "gigworker",
    genie: "genie",
    route: "route",
    uber_direct: "uber_direct",
    wolt: "wolt",
    menu: "menu"
  }
} as const;
