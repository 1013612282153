import React from "react";
import { observer } from "mobx-react";
import { MapMarkerPin } from "../components/MapMarkerPin";
import WktMultiPolygon from "../components/WktMultiPolygon";
import MapAttributes from "../constants/MapAttributes";
import { calcCenter } from "../utils/GeographyUtils";
import GoogleMap from "../components/Common/GoogleMap";

declare var gon: any;

interface Props {
  points?: { lat: number; lng: number }[];
  areaWkts?: string[];
}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
}

class SimpleMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
    };
  }

  componentDidMount(): void {}

  render() {
    const center = calcCenter({
      points: this.props.points,
      areaWkts: this.props.areaWkts,
    });
    return (
      <div className="d-flex" style={{ height: "calc(100vh - 200px)" }}>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          // こいつ(defaultZoom)がないと「Error: Invalid LatLng object: (NaN, NaN)」というエラーになる
          defaultZoom={14}
          defaultCenter={{ lng: center.lng, lat: center.lat }}
          center={{ lng: center.lng, lat: center.lat }}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => {
            this.setState({
              map: map,
              mapApi: maps,
              mapLoaded: true,
            });
          }}
        >
          {this.createMarkers()}
          {this.createPolygons()}
        </GoogleMap>
      </div>
    );
  }

  createMarkers() {
    if (this.props.points == null || this.props.points.length == 0) {
      return null;
    }

    return this.props.points.map((point) => {
      return (
        <MapMarkerPin
          key={"marker_" + Math.random()}
          lat={point.lat}
          lng={point.lng}
          svgIcon="person"
          size={28}
          onClick={() => {}}
        />
      );
    });
  }

  createPolygons() {
    if (this.state.map == null) {
      return null;
    }

    if (this.props.areaWkts == null || this.props.areaWkts.length == 0) {
      return null;
    }

    return this.props.areaWkts.map((areaWkt) => {
      return (
        <WktMultiPolygon
          key={"area_" + Math.random()}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={areaWkt}
        />
      );
    });
  }
}

export default observer(SimpleMap);
