import React from "react";
import { observer } from "mobx-react";
import overlookStore from '../stores/OverlookStore';
import _ from 'lodash'
import MapAttributes from "../constants/MapAttributes";
import OfficeSmallMarker from "../components/OfficeSmallMarker";
import CircleArea from "../components/CircleArea";
import MarkerColors from "../constants/MarkerColors";
import VendorCompanySmallMarker from "../components/VendorCompanySmallMarker";
import OverlookTempMarker from "../components/OverlookTempMarker";
import GoogleMap from '../components/Common/GoogleMap';

declare var gon: any;

interface Props {
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean,

  centerLat: number;
  centerLng: number;
  defaultZoom: number;
}

class OverlookMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,

      centerLat: this.getDefaultCenterLat(),
      centerLng: this.getDefaultCenterLng(),
      defaultZoom: this.getDefaultZoom(),
    };
  }

  componentDidMount(): void {
    navigator.geolocation.getCurrentPosition((result: any) => {
      // this.setState({
      //   centerLat: result.coords.latitude,
      //   centerLng: result.coords.longitude,
      // })
    }, () => {
    });
  }

  render() {
    return (
      <div className={'request-map-container'}>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={{
            lat: this.state.centerLat,
            lng: this.state.centerLng
          }}
          defaultZoom={this.state.defaultZoom}
          center={{
            lat: this.state.centerLat,
            lng: this.state.centerLng
          }}
          onChange={(e) => { this.onChangeMap(e) }}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={
            ({ map, maps }) => {
              overlookStore.setMap(map);
              this.setState({
                map: map,
                mapApi: maps,
                mapLoaded: true
              })
            }
          }
        >
          {this.createStoreMarkers()}
          {this.createStoreCircle()}
          {this.createOfficeMarkers()}
          {this.createOfficeCircle()}
          {this.createNewMarkers()}
          {this.createNewMarkerCircle()}
          {/*{this.renderRequestSenderMarkers()}*/}
          {/*{this.renderRequestReceiverMarkers()}*/}
          {/*{this.renderRequestPolyline()}*/}
        </GoogleMap>
      </div>
    );
  }

  private onChangeMap(e) {
    this.setCurrentLatLngZoomToLocalStrage(e.center.lat, e.center.lng, e.zoom);
  }

  private createOfficeMarkers() {
    return _.map(overlookStore.visibleOffices.slice(), (visibleOffice) => {
      if (!visibleOffice.visible) {
        return
      }
      const model = visibleOffice.model;

      return (
        <OfficeSmallMarker
          key={Math.random()}
          lat={model.lat}
          lng={model.lng}
        />
      )
    })
  }

  private createOfficeCircle() {
    if (_.isEmpty(this.state.map)) {
      return null
    }

    return _.map(overlookStore.visibleOffices.slice(), (visibleOffice) => {
      if (!visibleOffice.visible) {
        return
      }
      const model = visibleOffice.model;
      return (
        <CircleArea
          key={Math.random()}
          center={{
            lat: model.lat,
            lng: model.lng
          }}
          range={overlookStore.officeRange}
          map={this.state.map}
          mapApi={this.state.mapApi}
          color={MarkerColors.BLUE_CSS_COLOR}
        />
      )
    })
  }

  private createStoreMarkers() {
    return _.map(overlookStore.visibleVendorCompanies.slice(), (visibleVendorCompany) => {
      if (!visibleVendorCompany.visible) {
        return
      }
      const { model } = visibleVendorCompany;
      return (
        <VendorCompanySmallMarker
          key={Math.random()}
          lat={model.lat}
          lng={model.lng}
          name={model.name}
          address={`${model.zipcode} ${model.state} ${model.city} ${model.address} `}
        />
      )
    })
  }

  private createStoreCircle() {
    if (_.isEmpty(this.state.map)) {
      return null
    }

    return _.map(overlookStore.visibleVendorCompanies.slice(), (visibleVendorCompany) => {
      if (!visibleVendorCompany.visible) {
        return
      }
      const { model } = visibleVendorCompany;
      return (
        <CircleArea
          key={Math.random()}
          center={{
            lat: model.lat,
            lng: model.lng
          }}
          range={overlookStore.vendorCompanyRange}
          map={this.state.map}
          mapApi={this.state.mapApi}
          color={MarkerColors.DEFAULT_COLOR}
        />
      )
    })
  }


  private createNewMarkers() {
    return _.map(overlookStore.visibleNewMarkers.slice(), (visibleNewMarker) => {
      if (!visibleNewMarker.visible) {
        return
      }
      return (
        <OverlookTempMarker
          key={Math.random()}
          lat={visibleNewMarker.lat}
          lng={visibleNewMarker.lng}
          map={this.state.map}
          mapApi={this.state.mapApi}
          dragend={(lat: number, lng: number) => {
            overlookStore.changeNewMarkerPosition(visibleNewMarker, lat, lng);
          }}
        />
      )
    })
  }

  private createNewMarkerCircle() {
    if (_.isEmpty(this.state.map)) {
      return null
    }

    return _.map(overlookStore.visibleNewMarkers.slice(), (newMarker) => {
      if (!newMarker.visible) {
        return
      }
      return (
        <CircleArea
          key={Math.random()}
          center={{
            lat: newMarker.lat,
            lng: newMarker.lng
          }}
          range={overlookStore.newMarkerRange}
          map={this.state.map}
          mapApi={this.state.mapApi}
          color={MarkerColors.PROGRESS_COLOR}
        />
      )
    })
  }

  private setCurrentLatLngZoomToLocalStrage(lat, lng, zoom) {
    localStorage.setItem(this.getDefaultCenterLatKey(), lat);
    localStorage.setItem(this.getDefaultCenterLngKey(), lng);
    localStorage.setItem(this.getDefaultZoomKey(), zoom);
  }

  private getDefaultCenterLatKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_OVERLOOKMAP_CENTER_LAT + gon.current_user_id;
  }

  private getDefaultCenterLngKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_OVERLOOKMAP_CENTER_LNG + gon.current_user_id;
  }

  private getDefaultZoomKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_OVERLOOKMAP_ZOOM + gon.current_user_id;
  }

  private getDefaultCenterLat(): number {
    let lat = Number(localStorage.getItem(this.getDefaultCenterLatKey()));
    if (lat <= 1) {
      lat = MapAttributes.SHIBUYA_STATION_LATITUDE;
    }
    return lat;
  }

  private getDefaultCenterLng(): number {
    let lng = Number(localStorage.getItem(this.getDefaultCenterLngKey()));
    if (lng <= 1) {
      lng = MapAttributes.SHIBUYA_STATION_LONGITUDE;
    }
    return lng;
  }

  private getDefaultZoom(): number {
    let zoom: number = Number(localStorage.getItem(this.getDefaultZoomKey()));
    if (zoom <= 1) {
      zoom = MapAttributes.DEFAULT_ZOOM;
    }
    return zoom;
  }
}

export default observer(OverlookMap);
