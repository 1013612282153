import React from "react";
import AddConditionButton from "./AddConditionButton";
import AndOrButtons from "./AndOrButtons";
import AndOrRelation from "./AndOrRelation";
import { AcceptableTableKey } from "./Constants";
import { LeafConditionUnit, ConditionUnit, RelationType } from "./Interfaces";
import LeafConditionUnitComponent from "./LeafConditionUnitComponent";

interface Props {
  tableKey: AcceptableTableKey;
  node: ConditionUnit;
  position: { depth: number; breadth: number };
  onChangeStructure: (args: OnChangeStructureArgs) => void;
  onChangeRelation: (node: ConditionUnit) => void;
}

interface State {}

export type OnChangeStructureArgs = { node: ConditionUnit } & (
  | { operation: "create"; relationType: RelationType }
  | { operation: "delete"; relationType: null }
);

export default class ConditionUnitComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.onClickAdd = this.onClickAdd.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  render() {
    const node = this.props.node;
    if (node.type === "root" && node.children.length === 0) {
      const wrapperClassNames = "d-flex flex-column mb-2";
      return (
        <div className="d-flex flex-column">
          <div className={wrapperClassNames}>
            <AddConditionButton onClick={() => this.onClickAdd(node, "and")} />
          </div>
        </div>
      );
    } else if (node.type === "root" || node.type === "node") {
      const wrapperClassNames =
        "d-flex flex-column mb-2" +
        (node.type === "node" ? " border-left-info" : "");
      return (
        <div className="d-flex flex-column">
          <AndOrRelation
            node={node}
            onChangeRelation={this.props.onChangeRelation}
          />
          <div className={wrapperClassNames}>
            <div className="ml-3">
              {node.children.map((childNode, breadth) => (
                <React.Fragment key={childNode.code}>
                  <ConditionUnitComponent
                    tableKey={this.props.tableKey}
                    node={childNode}
                    position={{
                      depth: this.props.position.depth + 1,
                      breadth: breadth,
                    }}
                    onChangeStructure={this.props.onChangeStructure}
                    onChangeRelation={this.props.onChangeRelation}
                  />
                </React.Fragment>
              ))}
            </div>
            <AndOrButtons
              type="outer"
              onClick={(relationType) => this.onClickAdd(node, relationType)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column">
          <AndOrRelation
            node={node}
            onChangeRelation={this.props.onChangeRelation}
          />
          <LeafConditionUnitComponent
            tableKey={this.props.tableKey}
            node={node}
            onClickAndOr={(relationType) => this.onClickAdd(node, relationType)}
            onDelete={() => this.onClickDelete(node)}
          />
        </div>
      );
    }
  }

  onClickAdd(node: ConditionUnit, relationType: RelationType) {
    this.props.onChangeStructure({ node, operation: "create", relationType });
  }

  onClickDelete(node: LeafConditionUnit) {
    this.props.onChangeStructure({
      node,
      operation: "delete",
      relationType: null,
    });
  }
}
