import React, { useEffect, useState } from "react";
import Rails from "rails-ujs";
import Cookies from "js-cookie";

import CookieKeys from "../constants/CookieKeys";

function CarryStaffPositionListReload() {
  const [reloadEnabled, setReloadEnabled] = useState(true);

  useEffect(() => {
    const enabled = Cookies.get(CookieKeys.CARRY_STAFF_LOCATIONS_POLLING_ENABLED);
    if (enabled === "false") {
      setReloadEnabled(false);
    }
  }, []);

  useEffect(() => {
    if (!reloadEnabled) return;

    const intervalId = setInterval(reloadLocations, 5000);
    return () => clearInterval(intervalId);
  }, [reloadEnabled]);

  // 現在のページのリンクを押すことで、非同期で再読み込みする
  // => CarryStaffPositionListが、新しいpropsで再マウントされる => ストアを通してCarryStaffMapも更新される
  function reloadLocations() {
    const currentPageLink = document.querySelector("#locations-pagination .active .page-link") as HTMLElement;
    if (currentPageLink) {
      return currentPageLink.click();
    }

    // 1ページ目の場合はページャーが存在しないため、検索フォームを送信させて更新する
    // （この場合、ページがリセットされてしまうため、この方法は1ページ目の時しか使えない）
    const searchForm = document.querySelector("#locations-search-form") as HTMLElement;
    Rails.fire(searchForm, "submit");
  }

  function toggleEnabled() {
    setReloadEnabled((enabled) => {
      const newValue = !enabled;
      Cookies.set(CookieKeys.CARRY_STAFF_LOCATIONS_POLLING_ENABLED, String(newValue));
      return newValue;
    });
  }

  return (
    <>
      <input
        className="custom-control-input"
        type="checkbox"
        checked={reloadEnabled}
        id="cas_position_list_reload_checkbox"
        onChange={toggleEnabled}
      />
      <label
        className="custom-control-label"
        htmlFor="cas_position_list_reload_checkbox"
      >
        画面自動更新
      </label>
    </>
  );
}

export default CarryStaffPositionListReload;
