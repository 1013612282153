import Axios from "axios";
import { csrfToken } from "rails-ujs";

// GET用(CSRFトークンなし)
export const axiosGet = Axios.create({
  headers: {
    Accept: "application/json",
  },
});

axiosGet.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status == 403 &&
      error.response.data.result == "NOT_AUTHORIZED_BY_POLICY_GROUP"
    ) {
      alert(error.response.data.message);
    }
    return Promise.reject(error);
  }
);

// GET以外全て用
export const axiosPost = Axios.create({
  headers: {
    "X-CSRF-Token": csrfToken(),
    Accept: "application/json",
  },
});

axiosPost.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response.status == 403 &&
      (error.response.data.result == "NOT_AUTHORIZED_BY_POLICY_GROUP" ||
        error.response.data.result == "NOT_AUTHORIZED")
    ) {
      alert(error.response.data.message);
    }
    return Promise.reject(error);
  }
);
