import React, {ReactNode, useContext, useMemo} from "react";
import {Actions} from "./Actions";
import type {MapApi} from "../index";
import {GmapsUIContext} from "../index";
import {Indicator} from "./Indicator";
import {Toolbox} from "./Toolbox";
import {Modal} from "./Modal";
import {Notifications} from "./Notifications";
import {Drawer} from "./Drawer";
import type {DrawerOptions} from "./Drawer";

export interface ControlComponents {
    actions?: ReactNode
    indicator?: ReactNode
    toolbox?: ReactNode
    modal?: ReactNode
    notifications?: ReactNode
    drawer?: ReactNode
}

interface Props {
    mapApi: MapApi
    components: ControlComponents
    modalOpen: boolean
    onModalClose: () => void
    drawerOptions?: DrawerOptions
}

export default function Controls(props: Props) {
    const {mapApi, modalOpen, onModalClose, drawerOptions} = props
    const {actions, indicator, toolbox, modal, notifications, drawer} = props.components
    const {isOpenDrawer} = useContext(GmapsUIContext)
    const leftPadding = useMemo((): number => {
        if (!drawer) return 0
        if (isOpenDrawer) {
            return drawerOptions?.width || 280
        } else {
            return 24
        }
    }, [drawer, isOpenDrawer])

    return (
        <>
            {
                actions &&
                <Actions mapApi={mapApi}>
                    {actions}
                </Actions>

            }
            {
                indicator &&
                <Indicator mapApi={mapApi} left={leftPadding}>
                    {indicator}
                </Indicator>
            }
            {
                toolbox &&
                <Toolbox mapApi={mapApi} left={leftPadding}>
                    {toolbox}
                </Toolbox>
            }
            {
                modal &&
                <Modal mapApi={mapApi} open={modalOpen} onModalClose={onModalClose}>
                    {modal}
                </Modal>
            }
            {
                drawer &&
                <Drawer mapApi={mapApi} drawerOption={drawerOptions}>
                    {drawer}
                </Drawer>
            }
            {

                <Notifications mapApi={mapApi}>
                    {notifications}
                </Notifications>
            }
        </>
    )
}