import { format, startOfDay, endOfDay } from "date-fns";
import { IReactionDisposer, reaction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import GoogleMap from "../../../components/Common/GoogleMap";
import Polyline from "../../../components/Polyline";
import MapAttributes from "../../../constants/MapAttributes";
import type { Location, MatchedLocation } from "./interfaces";
import LocationList from "./components/LocationList";
import LocationMarker from "./components/LocationMarker";
import OnOffList from "./components/OnOffList";
import { COLORS } from "./constants/Colors";
import { STYLES, COLOR_SAMPLE } from "./constants/Styles";
import {
  locationsStore,
  matchedLocationsStore,
  staffLocationStatesStore,
} from "./stores";
import {
  createPolylineGroups,
  createTargetDateGroups,
  fillSentAtPreviousValue,
  fillSentAtRange,
} from "./utils";

declare var gon: any;

interface Props {
  carryStaffId: number;
  env: string;
}

interface State {
  map: google.maps.Map | null;
  mapApi: typeof google.maps | null;
  mapLoaded: boolean;
  center: { lat: number; lng: number };
  showHeatMap: boolean;
  heatmap: any;
  activeTab: TabName;
  activeMapItem: MapItem;
  targetLocations: (Location | MatchedLocation)[];
  fromDate: string;
  toDate: string;
  latestClickedMapAt: Date;
}
type TabName = "raw_location" | "matched_location" | "on_off";
type MapItem = "raw_location" | "matched_location";

class CarryStaffMap extends React.Component<Props, State> {
  disposers: IReactionDisposer[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      center: { lat: 35.681236, lng: 139.767125 },
      showHeatMap: false,
      heatmap: undefined,
      activeTab: "raw_location",
      activeMapItem: "raw_location",
      targetLocations: [],
      // 初期表示タブは"raw_location"なので日付+時間で初期化
      fromDate: format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm"),
      toDate: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm"),
      latestClickedMapAt: new Date(),
    };
  }

  async componentDidMount() {
    this.disposers.push(
      // locationsStoreの位置情報を購読して、地図の中心を動かす
      reaction(
        () => locationsStore.updatedAt,
        (v, r) => {
          if (this.state.activeMapItem == "raw_location") {
            this.calcCenterAndUpdate(locationsStore.locations);
          }
        }
      )
    );

    this.disposers.push(
      // MatchedLocationsStoreの位置情報を購読して、地図の中心を動かす
      reaction(
        () => matchedLocationsStore.updatedAt,
        (v, r) => {
          if (this.state.activeMapItem == "matched_location") {
            this.calcCenterAndUpdate(matchedLocationsStore.locations);
          }
        }
      )
    );

    this.disposers.push(
      // MatchedLocationsStoreのselectedLocationを購読して、地図の中心を動かす
      reaction(
        () => matchedLocationsStore.selectedLocation,
        (v, r) => {
          if (v && this.state.activeMapItem == "matched_location") {
            this.setState({ center: { lat: v.lat, lng: v.lng } });
          }
        }
      )
    );

    this.disposers.push(
      // locationsStoreのselectedLocationを購読して、地図の中心を動かす
      reaction(
        () => locationsStore.selectedLocation,
        (v, r) => {
          if (v && this.state.activeMapItem == "raw_location") {
            this.setState({ center: { lat: v.lat, lng: v.lng } });
          }
        }
      )
    );

    await Promise.all([
      locationsStore.loadLocations(
        this.props.carryStaffId,
        format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm"),
        format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm")
      ),
      matchedLocationsStore.loadLocations(
        this.props.carryStaffId,
        format(startOfDay(new Date()), "yyyy-MM-dd"),
        format(endOfDay(new Date()), "yyyy-MM-dd")
      ),
      staffLocationStatesStore.loadStaffLocationStates(
        this.props.carryStaffId,
        1
      ),
    ]).then(() => {
      // 初期表示タブは"raw_location"なので
      this.setState({ targetLocations: locationsStore.locations });
    });
  }

  componentWillUnmount() {
    // reactionの解除
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  private async loadLocation() {
    const { activeTab, fromDate, toDate } = this.state;
    if (activeTab == "raw_location") {
      await locationsStore.loadLocations(
        this.props.carryStaffId,
        fromDate,
        toDate
      );
      this.setState({ targetLocations: locationsStore.locations });
      this.calcCenterAndUpdate(locationsStore.locations, {
        forceUpdate: true,
      });
    } else if (activeTab == "matched_location") {
      await matchedLocationsStore.loadLocations(
        this.props.carryStaffId,
        format(new Date(fromDate), "yyyy-MM-dd"),
        format(new Date(toDate), "yyyy-MM-dd")
      );
      this.setState({ targetLocations: matchedLocationsStore.locations });
      this.calcCenterAndUpdate(matchedLocationsStore.locations, {
        forceUpdate: true,
      });
    }
  }

  private calcCenterAndUpdate(
    locations: { lat: number; lng: number }[],
    options?: { forceUpdate?: boolean }
  ) {
    if (locations.length == 0) return;

    const forceUpdate = options?.forceUpdate || false;

    const { center, map } = this.state;

    const latestLat = locations[0].lat;
    const latestLng = locations[0].lng;
    if (!forceUpdate && latestLat === center.lat && latestLng === center.lng) {
      return;
    }

    this.setState({
      center: { lat: latestLat, lng: latestLng },
    });
    map?.setCenter({ lat: latestLat, lng: latestLng });

    if (this.state.showHeatMap) {
      this.resetHeatMap(locations);
    }
  }

  private onChangeDate<K extends "fromDate" | "toDate">(
    target: K,
    date: State[K]
  ) {
    let formattedDate: string = date;

    // inputのtypeがdateの場合、date-localにも対応できるようにフォーマットしておく
    if (this.state.activeTab == "matched_location") {
      switch (target) {
        case "fromDate":
          formattedDate = format(
            startOfDay(new Date(date)),
            "yyyy-MM-dd'T'HH:mm"
          );
          break;
        case "toDate":
          formattedDate = format(
            endOfDay(new Date(date)),
            "yyyy-MM-dd'T'HH:mm"
          );
          break;
      }
    }

    this.setState(
      (prevState) => ({ ...prevState, [target]: formattedDate }),
      async () => {
        await this.loadLocation();
      }
    );
  }

  private onChangeShowHeatMap() {
    if (this.state.showHeatMap) {
      this.state.heatmap.setMap(null);
      this.setState({ showHeatMap: false, heatmap: undefined });
    } else {
      this.resetHeatMap(this.state.targetLocations);
      this.setState({ showHeatMap: true });
    }
  }

  private onChangeTab(tab: TabName) {
    // on_offの場合は元の地図状態のままタブ表示だけ切り替え
    if (tab == "on_off") {
      this.setState({ activeTab: tab });
    } else {
      this.setState({ activeTab: tab, activeMapItem: tab }, async () => {
        // 諸々リセットして再取得
        if (this.state.heatmap) {
          this.state.heatmap.setMap(null);
        }
        matchedLocationsStore.setSelectedLocation(undefined);
        locationsStore.setSelectedLocation(undefined);
        await this.loadLocation();
      });
    }
  }

  private resetHeatMap(locations: { lat: number; lng: number }[]) {
    const { map, mapApi } = this.state;
    if (map && mapApi) {
      if (this.state.heatmap) {
        // すでに設定されている場合には非表示
        this.state.heatmap.setMap(null);
      }

      const heatMapData = locations.map((loc: Location | MatchedLocation) => ({
        location: new mapApi!.LatLng(loc.lat, loc.lng),
        weight: loc.weight,
      }));

      const heatmap = new mapApi.visualization.HeatmapLayer({
        data: heatMapData,
        radius: 15,
      });
      heatmap.setMap(map);
      this.setState({ heatmap: heatmap });
    }
  }

  // マップがクリックされたらマーカーの選択が解除されるように
  private updateLatestClickedMapAt = () => {
    const now = new Date();
    this.setState((prevState) => {
      if (!prevState.latestClickedMapAt || prevState.latestClickedMapAt < now) {
        locationsStore.setSelectedLocation(undefined);
        matchedLocationsStore.setSelectedLocation(undefined);
      }
      return { latestClickedMapAt: now };
    });
  };

  private createCarryStaffMarkers(locations: (Location | MatchedLocation)[]) {
    const activeMapItem = this.state.activeMapItem;
    const isRaw = activeMapItem == "raw_location";

    // sent_atを直前の値で埋めて、target_dateでグループ化する
    const filledLocations = fillSentAtRange(locations);
    const groups = createTargetDateGroups(filledLocations);

    return groups.map((group) =>
      group.map((loc, index) => {
        return (
          <LocationMarker
            key={loc.id}
            lat={loc.lat}
            lng={loc.lng}
            earliest={index === group.length - 1}
            latest={index === 0}
            index={index}
            latestClickedMapAt={this.state.latestClickedMapAt}
            location={loc}
            nextLocation={index == group.length - 1 ? null : group[index + 1]}
            store={isRaw ? locationsStore : matchedLocationsStore}
          />
        );
      })
    );
  }

  private createPolylines(_locations: (Location | MatchedLocation)[]) {
    const { map, mapApi, mapLoaded, activeMapItem } = this.state;
    const isRaw = activeMapItem == "raw_location";
    if (!mapLoaded) return;

    const { env } = this.props;
    let locations: (Location | MatchedLocation)[] = [];
    if (activeMapItem == "matched_location") {
      // sent_atがnullだと時間範囲での繋ぎ込みが出来ないので直前の値で埋める
      locations = fillSentAtPreviousValue(_locations);
    } else {
      locations = _locations;
    }

    // locationsを条件に応じてつなぎ合わせて、Polylineのグループを作成
    const polylineGroups = createPolylineGroups(locations, env);

    return polylineGroups.map((polylineGroup, index) => {
      const selectedLocation = isRaw
        ? locationsStore.selectedLocation
        : matchedLocationsStore.selectedLocation;
      const isSelected = polylineGroup.some(
        (item) =>
          item.target_date && item.target_date == selectedLocation?.target_date
      );
      return (
        <Polyline
          key={index}
          map={map}
          mapApi={mapApi}
          locations={polylineGroup}
          strokeColor={isSelected ? COLORS.orange : COLORS.blue}
        />
      );
    });
  }

  private renderMapAnnotations() {
    return (
      <div style={STYLES.mapAnnotationPosition}>
        <div style={STYLES.mapAnnotationStyle}>
          <div style={{ fontSize: 13 }}>
            <div>
              <span style={COLOR_SAMPLE(COLORS.blue)} />
              取得したすべての位置情報
            </div>
            <div>
              <span style={COLOR_SAMPLE(COLORS.orange)} />
              選択地点日付の位置情報
            </div>
            <div>
              <span style={COLOR_SAMPLE(COLORS.pink)} />
              同一日付内の始点位置情報
            </div>
            <div>
              <span style={COLOR_SAMPLE(COLORS.green)} />
              同一日付内の終点位置情報
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      center,
      showHeatMap,
      activeTab,
      activeMapItem,
      targetLocations,
      fromDate,
      toDate,
    } = this.state;

    const inputType =
      activeMapItem == "raw_location" ? "datetime-local" : "date";
    const inputFromValue =
      activeMapItem == "raw_location"
        ? fromDate
        : format(new Date(fromDate), "yyyy-MM-dd");
    const inputToValue =
      activeMapItem == "raw_location"
        ? toDate
        : format(new Date(toDate), "yyyy-MM-dd");

    const errorMessage =
      locationsStore.errorMessage || matchedLocationsStore.errorMessage;
    const isLoading =
      locationsStore.isLoading || matchedLocationsStore.isLoading;

    const locations =
      activeMapItem == "raw_location"
        ? locationsStore.locations
        : matchedLocationsStore.locations;

    return (
      <div className="row" style={{ fontSize: "0.9rem" }}>
        <div className="col-md-8">
          <div className="card shadow mb-4">
            <div className="card-body card-body-map">
              <div style={STYLES.mapContainerStyle}>
                <GoogleMap
                  bootstrapURLKeys={{
                    key: gon.google_api_key,
                  }}
                  defaultZoom={16}
                  center={{ lng: center.lng, lat: center.lat }}
                  resetBoundsOnResize={true}
                  hoverDistance={MapAttributes.K_SIZE / 2}
                  onGoogleApiLoaded={({ map, maps }) => {
                    this.setState({
                      map: map,
                      mapApi: maps,
                      mapLoaded: true,
                    });
                  }}
                  onClick={() => this.updateLatestClickedMapAt()}
                  yesIWantToUseGoogleMapApiInternals={true}
                >
                  {!this.state.showHeatMap &&
                    this.createCarryStaffMarkers(targetLocations)}
                  {!this.state.showHeatMap &&
                    this.createPolylines(targetLocations)}
                </GoogleMap>
                {!this.state.showHeatMap && this.renderMapAnnotations()}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card shadow mb-4 p-2">
            <div className="d-flex align-items-center justify-content-center">
              <input
                type={inputType}
                value={inputFromValue}
                className="form-control"
                style={{ width: "45%", fontSize: "0.9rem" }}
                required
                disabled={activeTab == "on_off"}
                onChange={(e) => this.onChangeDate("fromDate", e.target.value)}
              />
              <span className="mx-1">〜</span>
              <input
                type={inputType}
                value={inputToValue}
                className="form-control"
                style={{ width: "45%", fontSize: "0.9rem" }}
                required
                disabled={activeTab == "on_off"}
                onChange={(e) => this.onChangeDate("toDate", e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2">
              {errorMessage && (
                <span className="text text-danger">{errorMessage}</span>
              )}
            </div>
            <div className="d-flex my-2 ml-auto">
              <div className="col custom-control custom-switch">
                <input
                  id="heatmap-visivility-toggle-switch"
                  type="checkbox"
                  className="custom-control-input"
                  checked={showHeatMap}
                  onChange={() => this.onChangeShowHeatMap()}
                  disabled={!(this.state.map && this.state.mapApi)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="heatmap-visivility-toggle-switch"
                >
                  ヒートマップ表示
                </label>
              </div>
            </div>

            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#raw-locations-tab"
                  onClick={(e) => this.onChangeTab("raw_location")}
                >
                  位置情報
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#matched-locations-tab"
                  onClick={(e) => this.onChangeTab("matched_location")}
                >
                  正規化位置情報
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#on-off-tab"
                  onClick={(e) => this.onChangeTab("on_off")}
                >
                  ON/OFF
                </a>
              </li>
            </ul>

            <div className="tab-content p-1">
              <div className="card-body card-body-map">
                {isLoading ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "calc(100vh - 4.375rem - 10rem)" }}
                  >
                    <div
                      className="spinner-border spinner-border ml-3"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab == "raw_location" && (
                      <LocationList
                        locations={locations}
                        store={locationsStore}
                      />
                    )}
                    {activeTab == "matched_location" && (
                      <LocationList
                        locations={locations}
                        store={matchedLocationsStore}
                      />
                    )}
                    {activeTab == "on_off" && (
                      <OnOffList carryStaffId={this.props.carryStaffId} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(CarryStaffMap);
