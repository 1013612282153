import React, { ReactNode } from "react";
import { PickMode } from "../SpotRemarkMapAreaPicker";

interface Props {
  pickMode: PickMode;
  setPickMode: (mode: PickMode) => void;
  pathsToolbox: ReactNode;
  hexToolbox: ReactNode;
  radiusToolbox: ReactNode;
  spotToolBox: ReactNode;
  destToolBox: ReactNode;
}

export default function MAPToolboxContentForSpotRemark(props: Props) {
  const {
    pickMode,
    setPickMode,
    pathsToolbox,
    hexToolbox,
    radiusToolbox,
    spotToolBox,
    destToolBox
  } = props;

  return (
    <div style={{ width: "21em" }}>
      <h6 className={"text-left font-weight-bold text-gray-500"}>選択モード</h6>
      <div className={"accordion"}>
        <div className={"card"}>
          <h2 className="card-header py-0 px-0">
            <button
              className={`btn btn-link text-left ${
                pickMode === "Paths" ? "collapsed" : ""
              }`}
              onClick={() => setPickMode("Paths")}
              style={{ width: "100%" }}
            >
              パスで選択する
            </button>
          </h2>
          <div
            className={`accordion-collapse collapse ${
              pickMode === "Paths" ? "show" : ""
            }`}
          >
            <div className="card-body px-2 py-2">{pathsToolbox}</div>
          </div>
        </div>
        <div className={"card"}>
          <h2 className="card-header py-0 px-0">
            <button
              className={`btn btn-link text-left  ${
                pickMode === "Hex" ? "collapsed" : ""
              }`}
              onClick={() => setPickMode("Hex")}
              style={{ width: "100%" }}
            >
              ヘックスで選択
            </button>
          </h2>
          <div
            className={`accordion-collapse collapse ${
              pickMode === "Hex" ? "show" : ""
            }`}
          >
            <div className="card-body px-2 py-2">{hexToolbox}</div>
          </div>
        </div>
        <div className={"card"}>
          <h2 className="card-header py-0 px-0">
            <button
              className={`btn btn-link text-left  ${
                pickMode === "Radius" ? "collapsed" : ""
              }`}
              onClick={() => setPickMode("Radius")}
              style={{ width: "100%" }}
            >
              円で選択
            </button>
          </h2>
          <div
            className={`accordion-collapse collapse ${
              pickMode === "Radius" ? "show" : ""
            }`}
          >
            <div className="card-body px-2 py-2">{radiusToolbox}</div>
          </div>
        </div>
        <div className={"card"}>
          <h2 className="card-header py-0 px-0">
            <button
              className={`btn btn-link text-left  ${
                pickMode === "Dest" ? "collapsed" : ""
              }`}
              onClick={() => setPickMode("Dest")}
              style={{ width: "100%" }}
            >
              行先修正
            </button>
          </h2>
          <div
            className={`accordion-collapse collapse ${
              pickMode === "Dest" ? "show" : ""
            }`}
          >
            <div className="card-body px-2 py-2" style={{ height: "100px" }}>
              {destToolBox}
            </div>
          </div>
        </div>
        <div className={"card"}>
          <h2 className="card-header py-0 px-0">
            <button
              className={`btn btn-link text-left  ${
                pickMode === "Spot" ? "collapsed" : ""
              }`}
              onClick={() => setPickMode("Spot")}
              style={{ width: "100%" }}
            >
              スポットのマーカー設置
            </button>
          </h2>
          <div
            className={`accordion-collapse collapse ${
              pickMode === "Spot" ? "show" : ""
            }`}
          >
            <div className="card-body px-2 py-2" style={{ height: "100px" }}>
              {spotToolBox}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
