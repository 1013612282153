import { center } from "@turf/turf";
import React from "react";
import Modal from "react-modal";
// @ts-ignore
import { parse } from "wkt";
import AndOrButtons from "./AndOrButtons";
import {
  AcceptableTableKey,
  ConditionKey,
  typeConditionAllowMap,
  tableAndColumnMap,
  conditionCodeLavels,
  conditionKeys,
} from "./Constants";
import { LeafConditionUnit, RelationType } from "./Interfaces";
import MapAreaPicker from "../MapAreaPicker/MapAreaPicker";

Modal.setAppElement("#wrapper");

interface Props {
  tableKey: AcceptableTableKey;
  node: LeafConditionUnit;
  onClickAndOr: (type: RelationType) => void;
  onDelete: () => void;
}

interface State {
  selectedColumnKey: string;
  selectedConditionKey: keyof typeof conditionKeys;
  comparedValue: string | null;
  tempAreaWkt: string | null;
  openAreaSettingModal: boolean;
}

export default class LeafConditionUnitComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    const condition = this.props.node.condition;
    const conditionKey = this.props.node.condition.conditionKey;
    this.state = {
      selectedColumnKey: condition.columnKey,
      selectedConditionKey: conditionKey,
      comparedValue: condition.comparedValue,
      tempAreaWkt: conditionKey === "within" ? condition.comparedValue : null,
      openAreaSettingModal: false,
    };

    this.onChangeColumn = this.onChangeColumn.bind(this);
    this.onChangeCondition = this.onChangeCondition.bind(this);
    this.onChangeComparedValue = this.onChangeComparedValue.bind(this);
    this.createSelectBox = this.createSelectBox.bind(this);
    this.createInputBox = this.createInputBox.bind(this);
    this.getSelectedColumn = this.getSelectedColumn.bind(this);
  }

  render() {
    const selectableColumns =
      tableAndColumnMap.find((item) => item.table.key === this.props.tableKey)
        ?.columns || [];
    const selectedColumnKey = this.state.selectedColumnKey;
    const selectedColumn = this.getSelectedColumn();

    const selectedConditionKey = this.state.selectedConditionKey;
    const selectableConditions = selectedColumn
      ? conditionCodeLavels.filter(
          (cond) =>
            typeConditionAllowMap[selectedColumn.type].findIndex(
              (_cond) => _cond === cond.key
            ) >= 0
        )
      : [];

    const borderStyle = this.props.node.condition.invalid
      ? "solid 2px #dc3545" // 赤色
      : "solid 1px #007bff"; // 青色

    return (
      <div
        className="d-flex flex-column py-2"
        style={{
          border: borderStyle,
          borderRadius: 4,
          width: 820,
        }}
      >
        <div className="d-flex justify-content-between mb-2 mx-2">
          {this.props.node.codeLabel}
          <button
            className="btn btn-secondary btn-sm mr-2"
            onClick={() => this.props.onDelete()}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
        <div className="d-flex mb-2">
          {this.createTableNameLabel()}
          {this.createSelectBox(
            selectableColumns,
            selectedColumnKey,
            this.onChangeColumn,
            "が"
          )}
          {this.createInputBox(
            this.state.comparedValue || "",
            this.onChangeComparedValue
          )}
          {this.createSelectBox(
            selectableConditions,
            selectedConditionKey,
            this.onChangeCondition
          )}
        </div>
        <AndOrButtons
          type="inner"
          onClick={(relationType) => this.props.onClickAndOr(relationType)}
        />
        <Modal
          isOpen={this.state.openAreaSettingModal}
          onRequestClose={() =>
            this.setState({
              openAreaSettingModal: false,
            })
          }
          style={modalStyles}
        >
          <h2 className="h5 mb-4">エリア設定</h2>
          <div style={{ height: "80%" }}>
            <MapAreaPicker
              gmapsApiKey={gon.google_api_key}
              center={this.getCenterPoint(this.state.tempAreaWkt)}
              wkt={this.state.tempAreaWkt || ""}
              onUpdateByWkt={async (areaWkt) =>
                this.setState({
                  tempAreaWkt: areaWkt,
                })
              }
              onDestroy={async () =>
                this.setState({
                  tempAreaWkt: null,
                })
              }
            />
          </div>
          <div className="d-flex flex-row justify-content-end mt-3">
            <button
              className="btn btn-outline-danger mr-2"
              onClick={() =>
                this.setState({
                  openAreaSettingModal: false,
                  tempAreaWkt: null,
                })
              }
            >
              キャンセル
            </button>
            <button
              className="btn btn-primary"
              onClick={() => this.determinArea()}
            >
              決定
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  getSelectedColumn() {
    const selectedColumn = (
      tableAndColumnMap.find((item) => item.table.key === this.props.tableKey)
        ?.columns || []
    ).find((col) => col.key === this.state.selectedColumnKey);
    return selectedColumn;
  }

  onChangeColumn(columnKey: string) {
    this.props.node.condition.columnKey = columnKey;
    // 対象カラム変更時には比較値をリセット
    // (でないと、対象カラムをid以外のものからidに変更した場合に元の値がそのままDBに残ってしまう)
    this.props.node.condition.comparedValue = null;

    // selectedColumnは存在する前提で「!」
    const selectedColumn = (
      tableAndColumnMap.find((item) => item.table.key === this.props.tableKey)
        ?.columns || []
    ).find((col) => col.key === columnKey)!;
    const selectableConditionKeys = typeConditionAllowMap[selectedColumn.type];
    if (selectableConditionKeys.indexOf(this.state.selectedConditionKey) < 0) {
      // 対象カラムを変更した際に、選択可能な条件キーにステートで持っている条件キーが含まれていない場合、
      // 選択可能な条件キーの一つ目を設定
      const newConditionKey = selectableConditionKeys[0];
      this.props.node.condition.conditionKey = newConditionKey;
      this.setState({
        selectedColumnKey: columnKey,
        selectedConditionKey: newConditionKey,
        comparedValue: null,
      });
    } else {
      this.setState({
        selectedColumnKey: columnKey,
        comparedValue: null,
      });
    }
  }

  onChangeCondition(conditionKey: ConditionKey) {
    this.props.node.condition.conditionKey = conditionKey;
    this.setState({ selectedConditionKey: conditionKey });
  }

  onChangeComparedValue(value: string) {
    this.props.node.condition.comparedValue = value;
    this.setState({ comparedValue: value });
  }

  createTableNameLabel() {
    const table = tableAndColumnMap
      .map((item) => item.table)
      .find((table) => table.key === this.props.tableKey);
    if (table) {
      return (
        <div className="d-flex ml-2">
          <div style={styles.charWrapper}>
            {table.label}
            <span className="mx-2">の</span>
          </div>
        </div>
      );
    }
  }

  createSelectBox(
    options: { key: string; label: string }[],
    defaultValue: string,
    onChangeCallback: (key: string) => void,
    afterChar?: string
  ) {
    return (
      <div className="d-flex">
        <select
          className="mx-2"
          style={styles.selectInput}
          defaultValue={defaultValue}
          onChange={(e) => onChangeCallback(e.target.value)}
        >
          {options.map((cond) => (
            <option value={cond.key} key={cond.key}>
              {cond.label}
            </option>
          ))}
        </select>
        {afterChar && <div style={styles.charWrapper}>{afterChar}</div>}
      </div>
    );
  }

  createInputBox(value: string, onChange: (key: string) => void) {
    const selectedColumn = this.getSelectedColumn();

    if (
      !selectedColumn ||
      selectedColumn.type == "string" ||
      selectedColumn.type === "number"
    ) {
      return (
        <div className="d-flex">
          <input
            type={
              !selectedColumn || selectedColumn.type == "string"
                ? "text"
                : "number"
            }
            className="mx-2"
            style={styles.selectInput}
            onChange={(e) => onChange(e.target.value)}
            value={value}
          />
        </div>
      );
    } else if (selectedColumn.type === "id") {
      return null;
    } else {
      return (
        <div className="d-flex">
          <button
            className="btn btn-primary btn-sm mx-2"
            onClick={() =>
              this.setState({
                openAreaSettingModal: true,
                tempAreaWkt: this.state.comparedValue,
              })
            }
          >
            エリア設定{!value || value.length === 0 ? "(未設定)" : ""}
          </button>
        </div>
      );
    }
  }

  getCenterPoint(areaWkt: string | null) {
    if (!areaWkt || areaWkt.length === 0) {
      // デフォルト渋谷駅
      return {
        lat: 35.658034,
        lng: 139.701636,
      };
    }

    try {
      const centerFeature = center(parse(areaWkt));
      const centerCoords = centerFeature.geometry.coordinates;
      return {
        lat: centerCoords[1],
        lng: centerCoords[0],
      };
    } catch (error) {
      return {
        lat: 35.658034,
        lng: 139.701636,
      };
    }
  }

  determinArea() {
    this.props.node.condition.comparedValue = this.state.tempAreaWkt;
    this.setState({
      comparedValue: this.state.tempAreaWkt,
      tempAreaWkt: null,
      openAreaSettingModal: false,
    });
  }
}

const styles = {
  selectInput: {
    width: 180,
    height: 30,
    lineHeight: "30px",
  },
  charWrapper: {
    height: 30,
    lineHeight: "30px",
  },
};

const modalStyles: Modal.Styles = {
  content: {
    width: "80vw",
    height: "90vh",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
