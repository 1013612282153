import React from "react";
import { Card } from 'react-bootstrap';
import { mapMarkerPlaceStyle, infoWindowStyle } from '../styles/SenderMarkerStyles';
import AssignLogRequest from "../interfaces/AssignLogRequest";

interface Props {
  lat: number,
  lng: number,
  zoomLevel?: number,
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean,

  assignLogRequest: AssignLogRequest
}

interface State {
  showInfoWindow: boolean
}

export default class AssignLogLatestVendorMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false
    };
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow;

    // S = start
    return (
      <div
        style={mapMarkerPlaceStyle}
        onClick={this.onClickMarker.bind(this)}
      >
        S
        {showInfoWindow && (
          <Card
            style={infoWindowStyle}
          >
            店舗様
            <div>
              {this.props.assignLogRequest.sender_name}
            </div>
            <div>
              {this.props.assignLogRequest.sender_address}
            </div>
          </Card>
        )}
      </div>
    )
  }

  private onClickMarker(event: Event) {
    this.setState({showInfoWindow: !this.state.showInfoWindow})
    event.preventDefault();
    event.stopPropagation();
  }
}
