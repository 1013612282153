import { Coords } from "google-map-react";
import React from "react";


interface Props {
  mapApi: any;
  map: any;
  path: [Coords, Coords] | Coords[];
  // デフォルトは青
  strokeColor?: 'blue' | 'orange';
  // デフォルトはsolid
  lineType?: 'solid' | 'dot' | 'arrow_dot';
}

class RequestPolyline extends React.Component<Props> {
  // mapapiを使って描画する場合、reactのlife cycleとタイミングが合わない
  // そのため、state相当のlineを自分で管理する
  line: any = null;

  constructor(props: Props) {
    super(props);
  }

  componentWillUpdate() {
    // propsが更新されば場合は既存のlineをmap上から削除
    this.resetLines();
  }

  componentWillUnmount() {
    // コンポーネントが消されたとき、ラインが地図上からも表示されないようにする
    this.resetLines();
  }

  resetLines() {
    if (this.line) this.line.setMap(null);
  }

  getColorHex() {
    if (!this.props.strokeColor || this.props.strokeColor == 'blue') {
      return "rgba(47,149,220, 0.9)";
    }

    return "rgba(255, 193, 7, 0.8)";
  }

  getLineStyle(mapApi: any) {
    if (this.props.lineType == 'arrow_dot') {
      return {
        geodesic: true,
        strokeOpacity: 0,
        icons: [
          {
            icon: {
              path: this.props.mapApi.SymbolPath.FORWARD_OPEN_ARROW,
              strokeOpacity: 1,
              strokeWeight: 2,
              scale: 1
            },
            offset: '0',
            repeat: '20px'
          }
        ]
      }
    }

    if (this.props.lineType == 'dot') {
      return {
        geodesic: true,
        strokeOpacity: 0,
        icons: [
          {
            icon: {
              path: 'M 0,-1 0,1',
              strokeOpacity: 0.8,
              scale: 3
            },
            offset: '0',
            repeat: '20px'
          }
        ]
      };
    }

    return {
      geodesic: true,
      strokeOpacity: 1,
      strokeWeight: 3,
      icons: [
        {
          icon: {
            anchor: mapApi.Point(30, 0),
            path: mapApi.SymbolPath.FORWARD_CLOSED_ARROW,
            fillOpacity: 1,
            scale: 4
          },
          offset: '25%'
        }
      ]
    }
  }

  private renderPolyline(
    mapApi: any,
    map: any,
    path: [Coords, Coords] | Coords[]
  ) {
    this.line = new mapApi.Polyline({
      map: map,
      path: path,
      ...this.getLineStyle(mapApi),
      ...{ strokeColor: this.getColorHex() }
    });
  }

  render() {
    const {
      mapApi,
      map,
      path,
    } = this.props;

    this.renderPolyline(mapApi, map, path);
    return null;
  }
}

export default RequestPolyline;
