import {
  bbox,
  multiPolygon,
  union,
  difference,
  distance,
  Feature,
  centerOfMass,
  intersect,
  point,
  booleanPointInPolygon,
  polygon,
  Point,
  Properties,
  Polygon,
  MultiPolygon,
} from "@turf/turf";
// @ts-ignore
import Wkt from "wicket";
// @ts-ignore
import { parse, stringify } from "wkt";

export function multiPolygonsUnion(polygonStrings: string[]) {
  // ポリゴンが一個しかない場合、結合する必要がないのでそのまま返す
  if (!polygonStrings[1]) return polygonStrings[0];

  const polygonObjects = polygonStrings.reduce((prev: any[], current) => {
    try {
      const obj = parse(current);
      const polygon: Feature<any> = multiPolygon(obj.coordinates);
      return [...prev, polygon];
    } catch (e) {
      console.error(e);
      return prev;
    }
  }, []);

  const unionedPolygon = polygonObjects.reduce(
    (accUnion: any, feature: any) => {
      if (!accUnion) return feature;
      try {
        return union(feature, accUnion);
      } catch (e) {
        console.error(e);
        return accUnion;
      }
    },
    null
  );
  if (!unionedPolygon || !unionedPolygon.geometry) return "";
  return stringify(unionedPolygon.geometry) as string;
}

export function multiPolygonsDifference(
  basePolygonWkt: string,
  removeAreaPolygonWkts: string[]
) {
  const basePolygonObject = multiPolygon(parse(basePolygonWkt).coordinates);

  const polygonObjects = removeAreaPolygonWkts.reduce(
    (prev: any[], current) => {
      try {
        const obj = parse(current);
        const polygon: Feature<any> = multiPolygon(obj.coordinates);
        return [...prev, polygon];
      } catch (e) {
        console.error(e);
        return prev;
      }
    },
    []
  );
  const differencedPolygon = polygonObjects.reduce(
    (accUnion: any, feature: any) => {
      try {
        return difference(accUnion, feature);
      } catch (e) {
        console.error(e);
        return accUnion;
      }
    },
    basePolygonObject
  );
  if (!differencedPolygon || !differencedPolygon.geometry) return "";

  return stringify(differencedPolygon.geometry) as string;
}

/**
 * 厳密な中心でも重心でもないが、とりあえずそれらしい中心点を算出する関数.
 * @param params
 * @returns
 */
export function calcCenter(params: {
  points?: { lat: number; lng: number }[];
  areaWkts?: string[];
}) {
  let sumCount = 0;
  let latSum = 0;
  let lngSum = 0;
  for (const point of params.points || []) {
    latSum += point.lat;
    lngSum += point.lng;
    sumCount += 1;
  }

  const wktClient = new Wkt.Wkt();
  for (const areaWkt of params.areaWkts || []) {
    try {
      wktClient.read(areaWkt);
    } catch (error) {
      console.log("error", error);
      continue;
    }

    if (wktClient.type === "multipolygon") {
      // components : { x: number, y: number }[][]
      for (const components of wktClient.components) {
        // 一番外側のエリアの中心を利用する(内部は考慮しない)
        const outer = components[0];
        for (let i = 0; i < outer.length; i++) {
          const item = outer[i];
          latSum += item.y;
          lngSum += item.x;
          sumCount += 1;
        }
      }
    } else if (wktClient.type === "polygon") {
      // components : { x: number, y: number }[][]
      const outer = wktClient.components[0];
      for (let i = 0; i < outer.length; i++) {
        const item = outer[i];
        latSum += item.y;
        lngSum += item.x;
        sumCount += 1;
      }
    }
  }

  if (sumCount == 0) {
    // 渋谷駅
    return {
      lat: 35.658034,
      lng: 139.701636,
    };
  }

  const center = { lat: latSum / sumCount, lng: lngSum / sumCount };
  return center;
}

/**
 * 指定されたエリアの中心座標を取得する関数.
 * @param wkt
 * @returns
 */
export function getCenterFrom(wkt: string | null) {
  if (wkt == null) return null;

  try {
    const geoJSON = parse(wkt);
    const centerPoint = centerOfMass(geoJSON);
    return {
      lat: centerPoint.geometry.coordinates[1],
      lng: centerPoint.geometry.coordinates[0],
    };
  } catch (e) {
    console.error("[getCenterFrom] Error", e);
  }

  return null;
}

/**
 * 指定されたエリアを包含する四角形を取得する関数.
 * @param wkt
 * @returns
 */
export function getBboxFrom(wkt: string | null) {
  if (!wkt) return null;

  const geoJSON = parse(wkt);
  const [minLng, minLat, maxLng, maxLat] = bbox(geoJSON);

  return { minLat, minLng, maxLat, maxLng };
}

/**
 * 指定されたエリアを包含する四角形の中心座標を取得する関数.
 * @param wkt
 * @returns
 */
export function getBboxCenterFrom(wkt: string | null) {
  if (!wkt) return null;

  const geoJSON = parse(wkt);
  const [minLng, minLat, maxLng, maxLat] = bbox(geoJSON);
  const center = [(minLng + maxLng) / 2, (minLat + maxLat) / 2];

  return { lat: center[1], lng: center[0] };
}

/**
 * 指定されたエリアを包含する円の半径をメートル単位で取得する関数.
 * (= bbox対角線の長い方 / 2)
 * @param wkt
 * @returns
 */
export function getEnclosingCircleRadiusFrom(
  wkt: string | null
): number | null {
  if (!wkt) return null;

  const geoJSON = parse(wkt);
  const [minLng, minLat, maxLng, maxLat] = bbox(geoJSON);
  const diagonalLength = distance(
    point([minLng, minLat]),
    point([maxLng, maxLat]),
    {
      units: "meters",
    }
  );

  return diagonalLength / 2;
}

function convertToFeature(areaWkt: string) {
  const areaJson = parse(areaWkt);
  if (areaJson.type === "Polygon") {
    return polygon(areaJson.coordinates);
  } else {
    return multiPolygon(areaJson.coordinates);
  }
}

/**
 * targetAreasのうち、areaWktと交差するもののkeyを抽出する関数.
 * @param areaWkt
 * @param targetAreas
 * @returns
 */
export function selectIntersectWith(
  areaWkt: string,
  targetAreas: { wkt: string; key: string }[]
) {
  try {
    const baseArea = convertToFeature(areaWkt);
    const intersectKeys = targetAreas
      .map((target) => ({
        key: target.key,
        area: convertToFeature(target.wkt),
      }))
      .filter((target) => !!intersect(baseArea, target.area))
      .map((target) => target.key);
    return intersectKeys;
  } catch (e) {
    console.error("[selectIntersectWith] Error", e);
    return [];
  }
}

/**
 * targetLocationsのうち、areaWktに含まれるもののkeyを抽出する関数.
 * @param areaWkt
 * @param targetLocations
 * @returns
 */
export function selectWithin(
  areaWkt: string,
  targetLocations: { location: { lat: number; lng: number }; key: string }[]
) {
  try {
    const _targets = targetLocations.map((target) => ({
      key: target.key,
      location: point([target.location.lng, target.location.lat]),
    }));
    const areaGeoJson = parse(areaWkt);
    let withinKeys: string[] = [];
    // まとめたいが、booleanPointInPolygonのオーバーロードのせいで型が上手く合わないので諦める
    if (areaGeoJson.type === "Polygon") {
      const baseArea = polygon(areaGeoJson.coordinates);
      withinKeys = _targets
        .filter((target) => booleanPointInPolygon(target.location, baseArea))
        .map((target) => target.key);
    } else {
      const baseArea = multiPolygon(areaGeoJson.coordinates);
      withinKeys = _targets
        .filter((target) => booleanPointInPolygon(target.location, baseArea))
        .map((target) => target.key);
    }

    return withinKeys;
  } catch (e) {
    console.error("[selectIncludeIn] Error", e);
    return [];
  }
}

/**
 * targetAreasのうち、locationを含んでいるもののkeyを抽出する関数.
 * @param location
 * @param targetAreas
 * @returns
 */
export function selectBelongsTo(
  location: { lat: number; lng: number },
  targetAreas: { wkt: string; key: string }[]
) {
  try {
    const areas = targetAreas.map((target) => ({
      key: target.key,
      geoJson: parse(target.wkt),
    }));

    const belongsKey = areas
      .filter((_area) => {
        if (_area.geoJson.type === "Polygon") {
          const baseArea = polygon(_area.geoJson.coordinates);
          return booleanPointInPolygon(
            point([location.lng, location.lat]),
            baseArea
          );
        } else {
          const baseArea = multiPolygon(_area.geoJson.coordinates);
          return booleanPointInPolygon(
            point([location.lng, location.lat]),
            baseArea
          );
        }
      })
      .map((_area) => _area.key);

    return belongsKey;
  } catch (e) {
    console.error("[selectBelongsTo] Error", e);
    return [];
  }
}

export function convertIntoPoint({
  lat,
  lng,
}: {
  lat: number;
  lng: number;
}): Point {
  return {
    type: "Point",
    coordinates: [lng, lat],
  };
}

/**
 * argAreaにargPointが含まれるかどうかを返す関数.
 * @param argArea PolygonやMultiPolygonは、wktのparseメソッドを利用した結果の値
 * @param argPoint Pointは、wktのparseメソッドを利用した結果の値
 * @returns
 */
export function isOverlap(
  argArea: string | Polygon | MultiPolygon,
  argPoint: string | Point | Feature<Point, Properties>
): boolean {
  if (typeof argArea == "string" && !argArea) return false;
  if (typeof argPoint == "string" && !argPoint) return false;

  try {
    const areaGeoJson: Polygon | MultiPolygon =
      typeof argArea == "string" ? parse(argArea) : argArea;
    const pointGeoJson: Feature<Point, Properties> =
      typeof argPoint == "string"
        ? point(parse(argPoint).coordinates)
        : argPoint.type == "Point"
        ? point(argPoint.coordinates)
        : argPoint;
    if (areaGeoJson.type === "Polygon") {
      const poly = polygon(areaGeoJson.coordinates);
      return booleanPointInPolygon(pointGeoJson, poly);
    } else {
      const multiPoly = multiPolygon(areaGeoJson.coordinates);
      return booleanPointInPolygon(pointGeoJson, multiPoly);
    }
  } catch (err) {
    console.error("[isOverlap] Error", err);
    return false;
  }
}

/**
 * wktTextがパース可能なものかどうかを判定する関数.
 * @param wkt wkt = new Wkt.Wkt()
 * @param wktText WKTテキスト
 * @returns
 */
export function validateWktText(wkt: any, wktText: string) {
  try {
    wkt.read(wktText);
  } catch (error) {
    return false;
  }
  return true;
}
