import { Bounds, ChangeEventValue, Coords } from "google-map-react";
import _ from "lodash";
import { IReactionDisposer, reaction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import GoogleMap from "../../../components/Common/GoogleMap";
import OfficeCarryStaffMarker from "../../../components/OfficeCarryStaffMarker";
import OfficeSmallMarker from "../../../components/OfficeSmallMarker";
import DeliveredTimeBeforeMinutesSelect from "../../../components/MapTools/DeliveredTimeBeforeMinutesSelect";
import Colors from "../../../constants/BootstrapColors";
import { COLORS } from "../../../constants/Colors";
import { carryStaffConst } from "../../../constants/CarryStaff";
import MapAttributes from "../../../constants/MapAttributes";
import { MapItemZindexConst } from "../../../constants/MapItemZIndices";
import { RequestModel } from "../../../models/RequestModel";
import { CurrentLocationWithCarryStaffModel } from "../../../models/CurrentLocationWithCarryStaffModel";
import { ThirdPartyDeliveryTaskModel } from "../../../models/ThirdPartyDeliveryTaskModel";
import carryStaffLocationsStore from "../../../stores/CarryStaffLocationsStore";
import officesStore from "../../../stores/OfficesStore";
import requestsStore from "../../../stores/RequestsStore";
import serviceAreasStore from "../../../stores/ServiceAreasStore";
import thirdPartyDeliveryTasksStore from "../../../stores/ThirdPartyDeliveryTasksStore";
import { getMapDefaultValueFunctions } from "../../../utils/MapSettingStorageUtils";
import Polyline from "../MapComponents/Polyline";
import ReceiverSmallMarker from "../MapComponents/ReceiverSmallMarker";
import SenderSmallMarker from "../MapComponents/SenderSmallMarker";
import ReceiverSequenceMarker from "../../ReceiverSequenceMarker";
import SenderSequenceMarker from "../../SenderSequenceMarker";
import carryStaffRequestSequencesStore from "../stores/CarryStaffRequestSequencesStore";
import searchConditionsStore, {
  PageType,
} from "../stores/SearchConditionsStore";
import {
  filterCarryStaffLocations,
  filterRequests,
  filterThirdPartyDeliveryTasks,
} from "../utils/FilterUtils";
import {
  getRoutesToShowOnMap,
  getRoutesToShowOnMapForInfoWindow,
  RoutesToShowOnMapResponse,
} from "../utils/RoutesUtils";
import { isLoading } from "../utils/Utils";

declare var gon: any;

type PolylineItem = {
  solidOrange: [Coords, Coords] | null;
  solid: Coords[][] | null;
  arrowDot: [Coords, Coords][] | null;
};

interface Props {}

interface State {
  map: any;
  mapApi: any;
  mapLoaded: boolean;
  zoom: number;
  center: Coords;
  bounds: Bounds | null;
  isMapLoaded: boolean;
  latestClickedMapAt: Date;
}

class CarryStaffOverlookMap extends React.Component<Props, State> {
  mapFuncs: ReturnType<typeof getMapDefaultValueFunctions>;
  disposers: IReactionDisposer[] = [];

  constructor(props: Props) {
    super(props);
    this.mapFuncs = getMapDefaultValueFunctions(
      MapAttributes.LOCAL_STRAGE_BASE_KEY_CARRYSTAFFOVERLOOKMAP,
      true
    );
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      zoom: this.mapFuncs.getDefaultZoom(),
      center: {
        lat: this.mapFuncs.getDefaultCenterLat(),
        lng: this.mapFuncs.getDefaultCenterLng(),
      },
      bounds: null,
      isMapLoaded: false,
      latestClickedMapAt: new Date(),
    };
    const defaultMinutes = this.getDefaultTimeRange();
    this.setTimeRange(defaultMinutes);
  }

  componentDidMount(): void {
    // > The second argument to new LatLng() was ignored and can be removed.
    // 対応によってコメントアウトされていた部分でのみ利用されているものなので、こちらもコメントアウト
    // setInterval(() => serviceAreasStore.loadNotExistStaffServiceAreas(), 60_000 * 5);

    // carryStaffLocationの変更を検知して地図表示部分の中心にcenterをセットする
    this.disposers.push(
      reaction(
        () => carryStaffLocationsStore.selectedCasId,
        async (value, r) => {
          if (
            this.state.bounds &&
            carryStaffLocationsStore.isSelectedUnAssigned()
          ) {
            this.subscribe("changed_carry_staff", () =>
              searchConditionsStore.setShowLodingIcon(false)
            );
            return;
          }

          const selectedCasLocation =
            carryStaffLocationsStore.getSelectedItem();
          if (this.state.bounds && selectedCasLocation) {
            this.setState({
              center: this.calcPositionToShift(
                selectedCasLocation,
                this.state.bounds
              ),
            });

            this.subscribe("changed_carry_staff", () =>
              searchConditionsStore.setShowLodingIcon(false)
            );
          }

          // CASが変更された場合、もしルート配達スタッフであれば依頼順を取得する
          if (
            selectedCasLocation == null ||
            selectedCasLocation.staffType != carryStaffConst.STAFF_TYPES.route
          ) {
            // 存在しない、もしくはルート配達スタッフ以外の場合、リセット
            carryStaffRequestSequencesStore.resetSequences();
            return;
          }

          await carryStaffRequestSequencesStore.loadTodaySequences(
            selectedCasLocation.carryStaffId
          );
        }
      )
    );

    // selectedItemIdの変更を検知して地図表示部分の中心にその地点をセットする
    this.disposers.push(
      reaction(
        () => requestsStore.selectedItemId,
        (v, r) =>
          this.moveToSelectedItemReceiver(requestsStore.getSelectedItem())
      )
    );

    this.disposers.push(
      reaction(
        () => requestsStore.clickSameItemEvent,
        (v, r) =>
          this.moveToSelectedItemReceiver(requestsStore.getSelectedItem())
      )
    );

    // selectedItemIdの変更を検知して地図表示部分の中心にその地点をセットする
    this.disposers.push(
      reaction(
        () => thirdPartyDeliveryTasksStore.selectedItemId,
        (value, r) =>
          this.moveToSelectedItemReceiver(
            thirdPartyDeliveryTasksStore.getSelectedItem()
          )
      )
    );

    this.disposers.push(
      reaction(
        () => thirdPartyDeliveryTasksStore.clickSameItemEvent,
        (value, r) =>
          this.moveToSelectedItemReceiver(
            thirdPartyDeliveryTasksStore.getSelectedItem()
          )
      )
    );

    this.disposers.push(
      reaction(
        () => searchConditionsStore.displayPage,
        (value, r) => {
          this.subscribe("changed_display_page", () =>
            searchConditionsStore.setShowLodingIcon(false)
          );
        }
      )
    );

    // locationAtの変更を検知して地図表示部分の中心にその地点をセットする
    this.disposers.push(
      reaction(
        () => searchConditionsStore.locationAt,
        (v, r) => {
          if (this.state.bounds && v) {
            this.setState({
              center: this.calcPositionToShift(v, this.state.bounds),
            });
          }
        }
      )
    );

    // 依頼タブで未アサインの依頼のみを表示する際は地図上にないデータも含めて表示するため
    this.disposers.push(
      reaction(
        () => searchConditionsStore.requestsConditions.isUnassined,
        (value, r) => {
          this.subscribe("changed_is_unassigned", () =>
            searchConditionsStore.setShowLodingIcon(false)
          );
        }
      )
    );
  }

  componentWillUnmount() {
    // reactionの解除
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  private moveToSelectedItemReceiver(
    item: RequestModel | ThirdPartyDeliveryTaskModel | undefined
  ) {
    if (this.state.bounds && item) {
      this.setState({
        center: this.calcPositionToShift(item.receiver, this.state.bounds),
      });
    }
  }

  /**
   * 指定された緯度経度の場所を、依頼俯瞰地図の見えている部分(左部の一覧画面部分を除いた地図)の真ん中へと
   * 移動させるために必要な幅の割合を計算するメソッド.
   * @param target
   * @param bounds
   * @returns
   */
  private calcPositionToShift(target: Coords, bounds: Bounds) {
    // 0.225は、メニューの幅を除外した中心地点に配置するための係数
    // > (1 - 0.45) / 2 + 0.45 - 0.5 = 0.225
    // メニュー幅(openContainer.width)の割合を変更した場合はこの数値も変更する必要有
    const shiftToCenterRatio = 0.225;
    const lat = target.lat;
    const lng =
      target.lng + (bounds.nw.lng - bounds.ne.lng) * shiftToCenterRatio;

    return { lat, lng };
  }

  /**
   * 各種イベントをトリガーとし、サブスクライブをやり直すメソッド.
   *
   * ### trigger
   * * mounted
   *   * 初回ロード時(マウント直後)
   * * changed_display_page
   *   * ペインの表示タブ変更時
   * * changed_map
   *   * 地図移動時
   * * changed_delivered_time
   *   * お届け時間範囲変更時
   * * changed_carry_staff
   *   * CAS選択時
   * * changed_is_unassigned
   *   * 依頼タブ内「未アサイン依頼のみ表示」のチェックが切り替わった時
   *
   * ### callbackAfterFirstLoaded
   * サブスクライブ対象の全データの初回ロードが終わった直後に実行するメソッドで、
   * 例えばペインで依頼一覧を表示している状態で配達スタッフ一覧タブを選択した場合、
   * 選択後の初回描画では、CASとそれに紐づく依頼および外部配達を全てロードし終わるまでローディングアイコンを出しておきたく、
   * そのためのもの。
   * タブ変更後の2回目以降のロード(サブスクライブによるポーリングや地図変更による再サブスクライブ)ではローディングアイコンを表示したくないので、
   * その場合にはcallbackAfterFirstLoadedを指定せずにsubscribe(このメソッド)を実行する。
   *
   * @param trigger
   * @param callbackAfterFirstLoaded
   * @returns
   */
  private async subscribe(
    trigger:
      | "mounted"
      | "changed_display_page"
      | "changed_map"
      | "changed_delivered_time"
      | "changed_carry_staff"
      | "changed_is_unassigned",
    callbackAfterFirstLoaded?: () => void
  ) {
    // CAS、依頼、外部配達以外で必要なデータ取得は
    // マウント時 => オフィス一覧とサービスエリア一覧
    // 地図移動時 => サービスエリア一覧
    // だけ
    if (trigger == "mounted") {
      officesStore.loadOffices();
      serviceAreasStore.loadInterSectServiceAreas(
        this.state.bounds,
        this.state.center
      );
    } else if (trigger == "changed_map") {
      // 地図が移動されたら、毎回service_areasは取り直す必要がある
      // (地図の移動以外では取り直す必要はない)
      serviceAreasStore.loadInterSectServiceAreas(
        this.state.bounds,
        this.state.center
      );
    }

    // 表示ページに関わらずdisplayPageが変更されたら
    // 詳細モーダル表示・非表示の状態をリセットする
    if (trigger == "changed_display_page") {
      thirdPartyDeliveryTasksStore.resetInfoWindowVisible();
      requestsStore.resetInfoWindowVisible();
    }

    const displayPage = searchConditionsStore.displayPage;
    if (displayPage == "carry_staffs") {
      if (
        trigger == "changed_carry_staff" ||
        trigger == "changed_is_unassigned"
      ) {
        // トリガーが存在しないので、対応不要
        return;
      } else if (
        trigger == "mounted" ||
        trigger == "changed_display_page" ||
        trigger == "changed_map"
      ) {
        await this.reSubscribe(
          { type: "in" },
          { type: "in" },
          { type: "in" },
          callbackAfterFirstLoaded
        );
        return;
      } else if (trigger == "changed_delivered_time") {
        // お届け時間を変更した場合、依頼と外部配達のみ再サブスクライブを実行
        await this.reSubscribe(
          { type: "pass" },
          { type: "in" },
          { type: "in" },
          callbackAfterFirstLoaded
        );
        return;
      }
    } else if (displayPage == "carry_staff_requests_or_tpdts") {
      const selectedCasId = carryStaffLocationsStore.selectedCasId;
      if (selectedCasId == null) {
        // CAS詳細で、CASが何も選択されていないということはないはずなので、無視
        return;
      }

      if (trigger == "mounted") {
        if (selectedCasId == "unassigned") {
          // トリガーが存在しないので、対応不要
          return;
        } else {
          // クエリパラメーターでcarryStaffIdが指定された場合の対応
          // loadTodaySequencesの実行によって当該store内のデータが変更されてもrenderが再実行されないため、
          // reSubscribeの前に実行を終えている必要があり、直列でawait。
          // reSubscribeを実行するのは、クエリパラメーターでcarryStaffIdが指定された場合、
          // 描画地図内の配達スタッフ一覧の取得が実行されるタイミングが存在しないため。
          await carryStaffRequestSequencesStore.loadTodaySequences(
            selectedCasId
          );
          await this.reSubscribe(
            { type: "in" },
            { type: "pass" },
            { type: "pass" },
            callbackAfterFirstLoaded
          );
        }
      } else if (trigger == "changed_is_unassigned") {
        // トリガーが存在しないので、対応不要
        return;
      } else if (trigger == "changed_display_page") {
        if (selectedCasId == "unassigned") {
          // CAS詳細、かつページ変更時、かつ選択対象が未アサインの場合はありえない
          // (ページ遷移時には誰かしらのCASが選択されているはず)
          return;
        } else {
          await this.reSubscribe(
            { type: "pass" }, // CAS詳細では、CAS一覧での最後のサブスクライブ設定を引き継ぐことにする
            { type: "with", targets: [selectedCasId] },
            { type: "with", targets: [selectedCasId] },
            callbackAfterFirstLoaded
          );
          return;
        }
      } else if (trigger == "changed_map") {
        if (selectedCasId == "unassigned") {
          await this.reSubscribe(
            { type: "pass" }, // CAS詳細では、CAS一覧での最後のサブスクライブ設定を引き継ぐことにする
            { type: "in_unassigned" }, // 描画地図内に存在する未アサイン依頼を取り直す
            { type: "none" }, // 未アサインの外部配達は存在しない
            callbackAfterFirstLoaded
          );
          return;
        } else {
          return;
        }
      } else if (
        trigger == "changed_carry_staff" ||
        trigger == "changed_delivered_time"
      ) {
        if (selectedCasId == "unassigned") {
          await this.reSubscribe(
            { type: "pass" }, // CAS詳細では、CAS一覧での最後のサブスクライブ設定を引き継ぐことにする
            { type: "in_unassigned" },
            { type: "none" }, // 未アサインの外部配達は存在しない
            callbackAfterFirstLoaded
          );
          return;
        } else {
          await this.reSubscribe(
            { type: "pass" }, // CAS詳細では、CAS一覧での最後のサブスクライブ設定を引き継ぐことにする
            { type: "with", targets: [selectedCasId] },
            { type: "with", targets: [selectedCasId] },
            callbackAfterFirstLoaded
          );
          return;
        }
      }
    } else if (displayPage == "requests") {
      const isUnassined = searchConditionsStore.requestsConditions.isUnassined;
      if (trigger == "mounted" || trigger == "changed_carry_staff") {
        // トリガーが存在しないので、対応不要
        return;
      } else if (
        trigger == "changed_display_page" ||
        trigger == "changed_map"
      ) {
        await this.reSubscribe(
          { type: "in" },
          { type: isUnassined ? "in_unassigned" : "in" },
          { type: "none" }, // 依頼一覧の場合、外部配達は不要なのでサブスクライブを解除
          callbackAfterFirstLoaded
        );
      } else if (
        trigger == "changed_delivered_time" ||
        trigger == "changed_is_unassigned"
      ) {
        await this.reSubscribe(
          { type: "pass" },
          { type: isUnassined ? "in_unassigned" : "in" },
          { type: "none" },
          callbackAfterFirstLoaded
        );
      }
    } else if (displayPage == "third_party_delivery_tasks") {
      if (
        trigger == "mounted" ||
        trigger == "changed_carry_staff" ||
        trigger == "changed_is_unassigned"
      ) {
        // トリガーが存在しないので、対応不要
        return;
      } else if (
        trigger == "changed_display_page" ||
        trigger == "changed_map"
      ) {
        // 外部配達依頼一覧の場合、依頼は不要なのでサブスクライブを解除
        await this.reSubscribe(
          { type: "in" },
          { type: "none" },
          { type: "in" },
          callbackAfterFirstLoaded
        );
        return;
      } else if (trigger == "changed_delivered_time") {
        await this.reSubscribe(
          { type: "pass" },
          { type: "none" },
          { type: "in" },
          callbackAfterFirstLoaded
        );
        return;
      }
    }
  }

  private async reSubscribe(
    // CAS
    cas:
      | { type: "none" } // 取得しない(既存サブスクライブは解除)
      | { type: "pass" } // 何もしない(既存のサブスクライブ設定のまま)
      | { type: "in" }, // 描画地図内のものを取得
    // 依頼
    req:
      | { type: "none" } // 取得しない(既存サブスクライブは解除)
      | { type: "pass" } // 何もしない(既存のサブスクライブ設定のまま)
      | { type: "in" } // 描画地図内のものを取得
      | { type: "in_unassigned" } // 描画地図内の未アサインのものを取得
      | { type: "with"; targets: number[] }, // 指定されたCASに紐づくものを取得
    // 外部配達
    tpdt:
      | { type: "none" } // 取得しない(既存サブスクライブは解除)
      | { type: "pass" } // 何もしない(既存のサブスクライブ設定のまま)
      | { type: "in" } // 描画地図内のものを取得
      | { type: "with"; targets: number[] }, // 指定されたCASに紐づくものを取得
    callbackAfterFirstLoaded?: () => void
  ) {
    const promises: Promise<unknown>[] = [];

    if (cas.type == "none") {
      carryStaffLocationsStore.unsubscribe();
    } else if (cas.type == "pass") {
    } else if (cas.type == "in") {
      promises.push(
        new Promise((resolve, reject) => {
          carryStaffLocationsStore.subscribeIn(
            this.state.center,
            this.state.bounds,
            () => resolve(true)
          );
        })
      );
    }

    if (req.type == "none") {
      requestsStore.unsubscribe();
    } else if (req.type == "pass") {
    } else if (req.type == "in") {
      promises.push(
        new Promise((resolve, reject) => {
          requestsStore.subscribeIn(
            this.state.center,
            this.state.bounds,
            false,
            () => resolve(true)
          );
        })
      );
    } else if (req.type == "in_unassigned") {
      promises.push(
        new Promise((resolve, reject) => {
          requestsStore.subscribeIn(
            this.state.center,
            this.state.bounds,
            true,
            () => resolve(true)
          );
        })
      );
    } else {
      promises.push(
        new Promise((resolve, reject) => {
          requestsStore.subscribeWith(req.targets, () => resolve(true));
        })
      );
    }

    if (tpdt.type == "none") {
      thirdPartyDeliveryTasksStore.unsubscribe();
    } else if (tpdt.type == "pass") {
    } else if (tpdt.type == "in") {
      promises.push(
        new Promise((resolve, reject) => {
          thirdPartyDeliveryTasksStore.subscribeIn(
            this.state.center,
            this.state.bounds,
            () => resolve(true)
          );
        })
      );
    } else {
      promises.push(
        new Promise((resolve, reject) => {
          thirdPartyDeliveryTasksStore.subscribeWith(tpdt.targets, () =>
            resolve(true)
          );
        })
      );
    }

    if (promises.length > 0) {
      await Promise.all(promises);
      callbackAfterFirstLoaded && callbackAfterFirstLoaded();
    }
  }

  private getTimeRangeKey() {
    return (
      MapAttributes.LOCAL_STRAGE_KEY_DELIVERD_TIME_BEFORE_MINUTES +
      gon.current_user_id
    );
  }

  private getDefaultTimeRange() {
    const minutes = Number(localStorage.getItem(this.getTimeRangeKey())) || 0;
    return minutes;
  }

  private setTimeRange(minutes: number) {
    localStorage.setItem(this.getTimeRangeKey(), `${minutes}`);

    requestsStore.setTimeRange(+minutes);
    thirdPartyDeliveryTasksStore.setTimeRange(+minutes);

    this.subscribe("changed_delivered_time", () =>
      searchConditionsStore.setShowLodingIcon(false)
    );
  }

  private handleMapChanged(value: ChangeEventValue) {
    const isMapLoaded = this.state.isMapLoaded;
    if (!isMapLoaded) {
      // 一番初めに地図が読み込まれた時にはローディングアイコンを表示したいので
      searchConditionsStore.setShowLodingIcon(true);
      this.setState(
        {
          center: { ...value.center },
          bounds: { ...value.bounds },
          isMapLoaded: true,
        },
        () =>
          this.subscribe("mounted", () =>
            searchConditionsStore.setShowLodingIcon(false)
          )
      );
    } else {
      this.setState(
        {
          center: { ...value.center },
          bounds: { ...value.bounds },
        },
        () =>
          this.subscribe("changed_map", () =>
            searchConditionsStore.setShowLodingIcon(false)
          )
      );
    }

    this.mapFuncs.setCurrentLatLngAndZoomToLocalStrage(
      value.center.lat,
      value.center.lng,
      value.zoom
    );
  }

  private createOfficeMarkers() {
    return _.map(officesStore.offices.slice(), (office) => {
      return (
        <OfficeSmallMarker key={office.id} lat={office.lat} lng={office.lng} />
      );
    });
  }

  private createServiceAreaPolygon() {
    if (_.isEmpty(this.state.map)) {
      return null;
    }

    return _.map(serviceAreasStore.serviceAreas.slice(), (serviceArea) => {
      if (!serviceAreasStore.isVisible(serviceArea)) {
        return;
      }

      // バックエンドでの判定で不在、となったときのみServiceAreaPolygon内で判定を行わせる
      // const notExistFlg = serviceAreasStore.notExistStaffServiceAreas.find(
      //   x => x.id === visibleServiceArea.model.id
      // );

      // TODO 依頼俯瞰で、2023-07-12から急にzoomレベルを高域にするとブラウザ consoleで
      // > The second argument to new LatLng() was ignored and can be removed.
      // とアラートメッセージが表示され続けて、画面操作ができなくなった
      // しかし、zoomレベルをアップにするとメッセージが出なくなる
      // このServiceAreaPolygonを描画しないとzoomレベルを高域にしても大丈夫になった。一時回避とする
      // return (
      //   <ServiceAreaPolygon
      //     key={model.id}
      //     map={this.state.map}
      //     mapApi={this.state.mapApi}
      //     wktText={model.withinAreaWkt}
      //     staffLocations={notExistFlg ? carryStaffLocationsStore.locations : undefined}
      //   />
      // )
    });
  }

  private createCarryStaffMarkers(
    displayPage: PageType,
    items: CurrentLocationWithCarryStaffModel[],
    requestsCount: number
  ) {
    return _.map(items, (location) => {
      const isSelectedCas =
        carryStaffLocationsStore.selectedCasId == location.carryStaffId;
      // 表示依頼件数が100件以下の場合CASマーカーが最前面に表示されるように
      const zIndex = isSelectedCas
        ? MapItemZindexConst.SELECTED_MARKER
        : requestsCount <= 100
        ? MapItemZindexConst.FRONT_MARKER
        : undefined;

      if (location.visible) {
        return (
          <OfficeCarryStaffMarker
            key={location.id}
            lat={location.lat}
            lng={location.lng}
            location={location}
            latestClickedMapAt={this.state.latestClickedMapAt}
            zIndex={zIndex}
          />
        );
      }
    });
  }

  private renderRequestSenderMarkers(
    displayPage: PageType,
    items: RequestModel[]
  ) {
    if (displayPage == "third_party_delivery_tasks") {
      return null;
    }

    const visibleSequenceNumber =
      displayPage == "carry_staff_requests_or_tpdts" &&
      searchConditionsStore.carryStaffRequestsConditions.visibleSequenceNumber;

    return _.map(items, (request) => {
      if (request.visible) {
        const isSelectedRequest = requestsStore.selectedItemId == request.id;
        const zIndex = isSelectedRequest
          ? MapItemZindexConst.SELECTED_MARKER
          : undefined;
        if (visibleSequenceNumber) {
          const sequence =
            request.carryStaffRequestSequences &&
            request.carryStaffRequestSequences
              .filter((csrs) => {
                return csrs.destination_type == "sender";
              })
              .map((csrs) => {
                return csrs.sequence;
              })
              .shift();
          if (sequence) {
            return (
              <SenderSequenceMarker
                key={`request-sender-marker-${request.id}`}
                pinText={sequence.toString()}
                request={request}
                lat={request.sender.lat}
                lng={request.sender.lng}
                iconColor={
                  isSelectedRequest ? Colors.WARNING_COLOR : COLORS.startMarker
                }
                zIndex={zIndex}
                latestClickedMapAt={this.state.latestClickedMapAt}
              />
            );
          }
        }
        return (
          <SenderSmallMarker
            key={`request-sender-marker-${request.id}`}
            request={request}
            lat={request.sender.lat}
            lng={request.sender.lng}
            iconColor={isSelectedRequest ? Colors.WARNING_COLOR : undefined}
            zIndex={zIndex}
            latestClickedMapAt={this.state.latestClickedMapAt}
          />
        );
      }
    });
  }

  private renderRequestReceiverMarkers(
    displayPage: PageType,
    items: RequestModel[]
  ) {
    if (displayPage == "third_party_delivery_tasks") {
      return null;
    }

    const visibleSequenceNumber =
      displayPage == "carry_staff_requests_or_tpdts" &&
      searchConditionsStore.carryStaffRequestsConditions.visibleSequenceNumber;

    return _.map(items, (request) => {
      if (request.visible) {
        const isSelectedRequest = requestsStore.selectedItemId == request.id;
        const zIndex = isSelectedRequest
          ? MapItemZindexConst.SELECTED_MARKER
          : undefined;
        if (visibleSequenceNumber) {
          const sequence =
            request.carryStaffRequestSequences &&
            request.carryStaffRequestSequences
              .filter((csrs) => {
                return csrs.destination_type == "receiver";
              })
              .map((csrs) => {
                return csrs.sequence;
              })
              .shift();
          if (sequence) {
            return (
              <ReceiverSequenceMarker
                key={`request-receiver-marker-${request.id}`}
                pinText={sequence.toString()}
                request={request}
                lat={request.receiver.lat}
                lng={request.receiver.lng}
                iconColor={
                  isSelectedRequest ? Colors.WARNING_COLOR : COLORS.goalMarker
                }
                zIndex={zIndex}
                latestClickedMapAt={this.state.latestClickedMapAt}
              />
            );
          }
        }
        return (
          <ReceiverSmallMarker
            key={`request-receiver-marker-${request.id}`}
            request={request}
            lat={request.receiver.lat}
            lng={request.receiver.lng}
            iconColor={isSelectedRequest ? Colors.WARNING_COLOR : undefined}
            zIndex={zIndex}
            latestClickedMapAt={this.state.latestClickedMapAt}
          />
        );
      }
    });
  }

  private renderThirdPartySenderMarkers(
    displayPage: PageType,
    items: ThirdPartyDeliveryTaskModel[]
  ) {
    if (displayPage == "requests") {
      return null;
    }

    return _.map(items, (thirdPartyDelivery) => {
      if (thirdPartyDelivery.visible) {
        const isSelectedthirdPartyDelivery =
          thirdPartyDeliveryTasksStore.selectedItemId == thirdPartyDelivery.id;
        const zIndex = isSelectedthirdPartyDelivery
          ? MapItemZindexConst.SELECTED_MARKER
          : undefined;
        return (
          <SenderSmallMarker
            key={`third-party-sender-marker-${thirdPartyDelivery.id}`}
            request={thirdPartyDelivery}
            lat={thirdPartyDelivery.sender.lat}
            lng={thirdPartyDelivery.sender.lng}
            iconColor={
              isSelectedthirdPartyDelivery ? Colors.WARNING_COLOR : undefined
            }
            zIndex={zIndex}
            latestClickedMapAt={this.state.latestClickedMapAt}
          />
        );
      }
    });
  }

  private renderThirdPartyReceiverMarkers(
    displayPage: PageType,
    items: ThirdPartyDeliveryTaskModel[]
  ) {
    if (displayPage == "requests") {
      return null;
    }

    return _.map(items, (thirdPartyDelivery) => {
      if (thirdPartyDelivery.visible) {
        const isSelectedthirdPartyDelivery =
          thirdPartyDeliveryTasksStore.selectedItemId == thirdPartyDelivery.id;
        const zIndex = isSelectedthirdPartyDelivery
          ? MapItemZindexConst.SELECTED_MARKER
          : undefined;
        return (
          <ReceiverSmallMarker
            key={`third-party-receiver-marker-${thirdPartyDelivery.id}`}
            request={thirdPartyDelivery}
            lat={thirdPartyDelivery.receiver.lat}
            lng={thirdPartyDelivery.receiver.lng}
            iconColor={
              isSelectedthirdPartyDelivery
                ? Colors.WARNING_COLOR
                : Colors.DANGER_COLOR
            }
            zIndex={zIndex}
            latestClickedMapAt={this.state.latestClickedMapAt}
          />
        );
      }
    });
  }

  private getPolylineItems(
    routesInfo: RoutesToShowOnMapResponse
  ): PolylineItem {
    if (routesInfo.type == "none") {
      return {
        solidOrange: null,
        solid: null,
        arrowDot: null,
      };
    }

    if (routesInfo.type == "single") {
      // ある依頼の、配達元から配達先へのルートを表示するということなので
      return {
        solidOrange: null,
        solid: [routesInfo.info.routes],
        arrowDot: null,
      };
    }

    // オレンジ矢印は配達スタッフが次に向かうべき場所に向くべきなので、
    // 並び替えた全ての配達順矢印が表示され得る検索条件のときだけオレンジ矢印を表示する
    const searchCond = searchConditionsStore.carryStaffRequestsConditions;
    const visibleOrangeLine =
      searchCond.status == "all" || searchCond.status == "undelivered";

    if (routesInfo.deliveryType == "route") {
      return {
        solidOrange: visibleOrangeLine ? routesInfo.info.fromCas : null,
        solid: routesInfo.info.routes,
        arrowDot: null,
      };
    }

    return {
      solidOrange: visibleOrangeLine ? routesInfo.info.fromCas : null,
      solid: routesInfo.info.inner,
      arrowDot: routesInfo.info.inter,
    };
  }

  private renderLine(
    index: number, // renderLinesでmapして利用しているため、keyを指定しろと警告されるので仕方なくindex
    path: Coords[] | [Coords, Coords] | null,
    options: { isOrange?: boolean; lineType?: "dot" | "arrow_dot" }
  ) {
    if (!this.state.mapLoaded || path == null) {
      return null;
    }

    return (
      <Polyline
        mapApi={this.state.mapApi}
        map={this.state.map}
        key={index}
        path={path}
        strokeColor={options.isOrange ? "orange" : "blue"}
        lineType={options.lineType}
      />
    );
  }

  private renderLines(
    pathes: (Coords[] | [Coords, Coords])[] | null,
    options: { isOrange?: boolean; lineType?: "dot" | "arrow_dot" }
  ) {
    if (!this.state.mapLoaded || pathes == null) {
      return null;
    }

    return pathes.map((path, index) => this.renderLine(index, path, options));
  }

  private renderPolyLines() {
    // 配達順の直線経路と被ってしまうため、
    // 依頼俯瞰マップの配達元・配達先マーカーの詳細ウィンドウが
    // アクティブな依頼の経路が存在する場合は詳細ウィンドウの経路のみを表示する
    const infoWindowRoutes = getRoutesToShowOnMapForInfoWindow();
    const polylineItems =
      infoWindowRoutes.length > 0 ? infoWindowRoutes : [getRoutesToShowOnMap()];

    if (!this.state.mapLoaded || polylineItems.length < 1) {
      return null;
    }

    return _.map(polylineItems, (polylineItem, index) => {
      const { solidOrange, solid, arrowDot } =
        this.getPolylineItems(polylineItem);
      return (
        <React.Fragment key={index}>
          {solidOrange && this.renderLine(0, solidOrange, { isOrange: true })}
          {solid && this.renderLines(solid, {})}
          {arrowDot && this.renderLines(arrowDot, { lineType: "arrow_dot" })}
        </React.Fragment>
      );
    });
  }

  private updateLatestClickedMapAt() {
    this.setState({ latestClickedMapAt: new Date() });
  }

  render() {
    const loading = searchConditionsStore.showLoadingIcon && isLoading();
    const displayPage = searchConditionsStore.displayPage;
    const targetCasLocations = filterCarryStaffLocations();
    const targetRequests = filterRequests();
    const targetThirdPartyDeliveryTasks = filterThirdPartyDeliveryTasks();
    const targetReqAndTpdtCount =
      targetRequests.length + targetThirdPartyDeliveryTasks.length;
    return (
      <div className="request-map-container">
        <div className="time-span-controller">
          <DeliveredTimeBeforeMinutesSelect
            defaultValue={requestsStore.timeRange}
            disabled={requestsStore.loadingInfo.loading}
            onChange={(e) => {
              this.setTimeRange(+e.target.value);
            }}
          />
        </div>
        <GoogleMap
          bootstrapURLKeys={{
            key: gon.google_api_key,
          }}
          defaultZoom={this.state.zoom}
          center={this.state.center}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => {
            this.setState({
              map: map,
              mapApi: maps,
              mapLoaded: true,
            });
          }}
          onChange={(value) => this.handleMapChanged(value)}
          onClick={() => this.updateLatestClickedMapAt()}
          options={{minZoom: 7}}
        >
          {this.createOfficeMarkers()}
          {this.createServiceAreaPolygon()}
          {!loading &&
            this.createCarryStaffMarkers(
              displayPage,
              targetCasLocations,
              targetReqAndTpdtCount
            )}
          {!loading &&
            this.renderRequestSenderMarkers(displayPage, targetRequests)}
          {!loading &&
            this.renderRequestReceiverMarkers(displayPage, targetRequests)}
          {!loading &&
            this.renderThirdPartySenderMarkers(
              displayPage,
              targetThirdPartyDeliveryTasks
            )}
          {!loading &&
            this.renderThirdPartyReceiverMarkers(
              displayPage,
              targetThirdPartyDeliveryTasks
            )}
          {!loading && this.renderPolyLines()}
        </GoogleMap>
      </div>
    );
  }
}

export default observer(CarryStaffOverlookMap);
