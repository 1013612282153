import { when } from "mobx";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { thirdPartyDeliveryTaskConst } from "../../../constants/ThirdPartyDeliveryTask";
import { ThirdPartyDeliveryTaskModel } from "../../../models/ThirdPartyDeliveryTaskModel";
import I18n from "../../../packs/i18n/i18n";
import "../../../packs/i18n/ja";
import thirdPartyDeliveryTasksStore from "../../../stores/ThirdPartyDeliveryTasksStore";
import ThirdPartyDeliveryTaskTableBody from "../Panes/ThirdPartyDeliveryTaskTableBody";
import searchConditionsStore from "../stores/SearchConditionsStore";
import { filterThirdPartyDeliveryTasks } from "../utils/FilterUtils";
import { selectItem, isLoading } from "../utils/Utils";


interface Props {
}

class ThirdPartyDeliveryList extends React.Component<Props> {

  scrollableElementRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.scrollableElementRef = React.createRef();
  }

  componentDidMount() {
  }

  handleChangeText(searchText: string) {
    searchConditionsStore.changeCond(
      'third_party_delivery_tasks',
      'text',
      searchText
    );
  }

  handleChangeStatus(newStatus: string) {
    searchConditionsStore.changeCond(
      'third_party_delivery_tasks',
      'status',
      newStatus === "all" ? newStatus : +newStatus
    );
  }

  handleChangeWorkType(newWorkType: string) {
    searchConditionsStore.changeCond(
      'third_party_delivery_tasks',
      'workType',
      newWorkType === "all" ? newWorkType : newWorkType
    );
  }

  scrollToTop() {
    if (this.scrollableElementRef.current) {
      this.scrollableElementRef.current.scrollTop = 0;
    }
  }

  private renderSearchInput(itemsCount: number) {
    return (
      <div style={styles.searchWrapper}>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="外部依頼ID / 配達スタッフ名検索"
            value={searchConditionsStore.thirdPartyDeliveryTasksConditions.text}
            onChange={(event) => this.handleChangeText(event.target.value)}
          />
          <div style={{ position: 'relative', transform: 'translate(-30px, 0)', pointerEvents: 'none' }}>
            <i className="fas fa-search" />
          </div>
        </div>
        <div className="d-flex mt-2 mr-2">
          <div className="d-flex align-items-center mr-2" style={{ width: "80%" }}>
            <label style={styles.searchLabel} htmlFor="statusSelect">ステータス </label>
            <select
              id="statusSelect"
              className="form-control form-control-sm ml-2"
              onChange={(e) => this.handleChangeStatus(e.target.value)}
            >
              <option key="all" value="all">全て</option>
              {Object.keys(thirdPartyDeliveryTaskConst.STATUSES).map((key) => {
                const statusNumber = thirdPartyDeliveryTaskConst.STATUSES[key];
                return (
                  <option key={statusNumber} value={statusNumber}>
                    {I18n.t(`enums.request.status.${key}`)}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="d-flex align-items-center mr-2" style={{ width: "80%" }}>
            <label style={styles.searchLabel} htmlFor="tagSelect">業務 </label>
            <select
              id="tagSelect"
              className="form-control form-control-sm ml-2"
              onChange={(e) => this.handleChangeWorkType(e.target.value)}
            >
              <option key="all" value="all">全て</option>
              {Object.keys(thirdPartyDeliveryTaskConst.WORK_TYPES).map((key) => {
                return (
                  <option key={key} value={key}>
                    {I18n.t(`enums.carry_staff.work_type.${key}`)}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="text-right mt-2">
          件数：{itemsCount}件
        </div>
      </div>
    )
  }

  private renderTable(items: ThirdPartyDeliveryTaskModel[]) {
    const loading = searchConditionsStore.showLoadingIcon && isLoading();
    const selectedItem = thirdPartyDeliveryTasksStore.getSelectedItem();
    return (
      <div
        style={styles.tableWrapper}
        ref={this.scrollableElementRef}
      >
        {loading ?
          <div className="fa-2x mt-5" style={styles.loading}>
            <i className="fas fa-spinner fa-spin"></i>
          </div> :
          <table className="table">
            <thead>
              <tr style={styles.tableLabel}>
                <th scope="col">外部配達タスク詳細</th>
              </tr>
            </thead>
            <ThirdPartyDeliveryTaskTableBody
              selectedItem={selectedItem}
              items={items}
              onSelect={(tpdtId) => {
                const selectedItem = thirdPartyDeliveryTasksStore.getSelectedItem();
                if (selectedItem && tpdtId == selectedItem.id) {
                  thirdPartyDeliveryTasksStore.clickSameItem();
                } else {
                  selectItem('thirdPartyDeliveryTask', tpdtId);
                }
                this.scrollToTop();
              }}
            />
          </table>
        }
      </div>
    )
  }

  render() {
    const targetThirdPartyDeliveryTasks = filterThirdPartyDeliveryTasks();
    return (
      <div style={styles.contents}>
        <div style={{ height: "100%" }}>
          {this.renderSearchInput(targetThirdPartyDeliveryTasks.length)}
          {this.renderTable(targetThirdPartyDeliveryTasks)}
        </div>
      </div>
    )
  }
}

const styles = {
  contents: {
    overflow: "hidden",
    height: "100%"
  } as CSSProperties,
  searchWrapper: {
    fontSize: "0.8rem",
    margin: "10px"
  } as CSSProperties,
  searchLabel: {
    whiteSpace: "nowrap",
    margin: 0
  } as CSSProperties,
  tableWrapper: {
    width: "100%",
    height: "75%",
    overflowY: "auto"
  } as CSSProperties,
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  } as CSSProperties,
  tableLabel: {
    fontSize: "0.9rem"
  } as CSSProperties
}

export default observer(ThirdPartyDeliveryList)
