import { action, observable, set } from "mobx";
import CurrentLocationsResponse from "../interfaces/CurrentLocationsResponse";

/** 現在の業務 */
export enum WorkType {
  ANYCARRY = 0,
  DEMAECAN = 10,
  AMAZON = 20,
  WOLT = 30,
  REST = 100
}

export class CurrentLocationWithCarryStaffModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  name: string;

  moveMethod: number;

  @observable lat: number;

  @observable lng: number;

  @observable status: number;

  @observable staffType: number;

  @observable todaysRequestCount: number;

  @observable todaysThirdPartyDeliveryTaskCount: number;

  @observable todaysDeliveredRequestCount: number;

  @observable todaysReturenRequestCount: number;

  @observable todaysOnholdRequestCount: number;

  @observable inProgressRequestCount: number;

  @observable mayStayForAWhile: boolean;

  @observable workType: WorkType;

  carryStaffId: number;

  visible: boolean;

  carryStaffAvoidAssign: boolean;

  @observable imageUrl: string | null;

  imageUrlExpiredAt: Date | null;

  constructor(response: CurrentLocationsResponse) {
    this.id = response.id;
    this.key = response.id;
    this.name = response.carry_staff_name;
    this.moveMethod = response.carry_staff_move_method;
    this.lat = +response.lat;
    this.lng = +response.lng;
    this.staffType = response.carry_staff_staff_type;
    this.todaysRequestCount = Number(response.todays_request_count);
    this.todaysDeliveredRequestCount = Number(response.todays_delivered_request_count || 0);
    this.todaysReturenRequestCount = Number(response.todays_return_request_count || 0);
    this.todaysOnholdRequestCount = Number(response.todays_onhold_request_count || 0);
    this.todaysThirdPartyDeliveryTaskCount = Number(response.todays_third_party_delivery_task_count);
    this.inProgressRequestCount = Number(response.in_progress_request_count);
    this.carryStaffId = Number(response.carry_staff_id)
    this.mayStayForAWhile = response.carry_staff_may_stay_for_a_while;
    this.visible = true;
    this.carryStaffAvoidAssign = response.carry_staff_avoid_assign;
    this.workType = response.carry_staff_work_type;
    this.imageUrl = response.carry_staff_image_url;
    this.imageUrlExpiredAt = response.carry_staff_image_url_expired_at != null ? new Date(response.carry_staff_image_url_expired_at) : null;
  };

  get workTypeText() {
    switch (this.workType) {
      case WorkType.ANYCARRY: return "エニキャリ";
      case WorkType.DEMAECAN: return "出前館";
      case WorkType.AMAZON: return "Amazon";
      case WorkType.WOLT: return "Wolt";
      case WorkType.REST: return "休憩中";
    }
  }

  @action
  updateImageInfo(imageUrl: string | null, imageUrlExpiredAt: Date | null) {
    this.imageUrl = imageUrl;
    this.imageUrlExpiredAt = imageUrlExpiredAt;
  }

  getTodaysRequestRemain() {
    return this.todaysRequestCount - this.todaysDeliveredRequestCount - this.todaysReturenRequestCount - this.todaysOnholdRequestCount;
  }
}
