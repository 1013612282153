import React, {CSSProperties, MouseEvent, useEffect, useMemo, useRef, useState} from "react";
import {NotificationAlertButton} from "./NotificationAlertButton";
import {NotificationList} from "./NotificationList";
import {INotification} from "./interfaces";

interface Props {
  // 通知一覧
  notifications: INotification[]
  // 画面上の水平向位置
  horizon?: "left" | "right"
  // 画面上の垂直向位置
  vertical?: "top" | "bottom"
  // リストを含めた横幅の長さ
  width?: number
  // リスト表示中に外側クリックでリストを閉じるか？
  clickOutsideToClose?: boolean
  // 表示位置をfixedではなくstaticにする
  positionStatic?: boolean
  defaultShowList?: boolean
  // リスト開閉時に発するイベント
  onToggle?: (isOpen: boolean) => void
}
export default function NotificationCenter(props: Props) {
  // propsを分割代入
  const {
    notifications,
    horizon = "right",
    vertical = "bottom",
    width = 480,
    clickOutsideToClose = false,
    defaultShowList = false,
    onToggle,
  } = props

  // リストの表示・非表示
  const [showList, setShowList] = useState<boolean>(defaultShowList)
  // リスト開閉動作時の処理
  useEffect(() => {
    if(onToggle) {
      onToggle(showList)
    }
  }, [showList])

  // アラートボタン押下時の動作
  const handleClickAlertButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setShowList(!showList)
  }

  // NotificationCenterコンポーネント全体のDOM
  const refNotificationCenter = useRef<HTMLDivElement>(null)

  // 外側クリックで閉じる
  useEffect(() => {
    if (!clickOutsideToClose) return
    useClickOutsideToClose(refNotificationCenter.current, () => setShowList(false))
  }, [refNotificationCenter, clickOutsideToClose, setShowList])


  // CSSスタイル
  const { wrapperStyle } = createStyles(props)


  /**
   * Template
   */
  return (
      <div
          style={wrapperStyle}
          ref={refNotificationCenter}>
        <NotificationList
            notifications={notifications}
            isShow={showList}
            horizon={horizon}
            vertical={vertical}
            width={width}
        />

        <div>
          <NotificationAlertButton
              notifications={notifications}
              onClick={handleClickAlertButton}
              isOpen={showList}
              horizon={horizon}
              vertical={vertical}
          />
        </div>
      </div>
  )
}

/**
 * 画面外側をクリックした時の挙動
 */
const useClickOutsideToClose = (elm: HTMLElement | null, callback: () => void) => {
  console.log("close")
  if(!elm) return

  const handleClickOutside = (e: any) => {
    if (!elm?.contains(e.target as Node)) callback()
  };
  document.addEventListener("click", handleClickOutside);
  return () => {
    document.removeEventListener("click", handleClickOutside);
  }
}

/**
 * Styles
 */
const createStyles = (props: Props) => {
  const {horizon, vertical, positionStatic} = props
  return useMemo(() => {
    const wrapperStyle: CSSProperties = {
      position: !positionStatic ? "fixed": "static",
      right: horizon === "right" ? 0: "auto",
      left: horizon === "left" ? 0: "auto",
      bottom: vertical === "bottom" ? 0: "auto",
      top: vertical === "top" ? 0: "auto",
      display: "flex",
      flexDirection: vertical === "bottom" ? "column": "column-reverse",
      alignItems: horizon === "right" ? "end": "start",
      pointerEvents: "none",
    }
    return {wrapperStyle}
  }, [horizon, vertical, positionStatic])

}