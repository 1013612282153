import { EventEmitter } from "events";
import React, { useEffect, useMemo, useState } from "react";
import type { SimpleCarryStaffSchedule } from "../../../../utils/routes-apis/common-utils";
import { getExcludedRequestReasons } from "../../../../utils/routes-apis";
import {
  SelectableApiType,
  getSelectableApiTypes,
} from "../../../../utils/routes-apis/api-code-utils";
import { callAssignmentApi } from "../../../../utils/routes-apis/apis";
import type { RoutesApiBulkAssignResponse } from "../../../../utils/routes-apis/apis/common";
import { getTodayStr } from "../../../../utils/time-utils";
import RoutesApiSelection from "../../../Common/RoutesApiSelection";
import type {
  RawCarryStaff,
  RawRequestWithInfo,
  RawRoutesApiParamSetting,
  RawTerritory,
  RawVehicleType,
} from "../../interfaces";
import { pageStore } from "../../stores";
import ModalBodyWrapper from "./ModalBodyWrapper";

const stopLoopEventEmitter = new EventEmitter();

interface Props {
  carryStaffs: RawCarryStaff[];
  schedules: SimpleCarryStaffSchedule[];
  requests: RawRequestWithInfo[];
  routesApiParamSettings: RawRoutesApiParamSetting[];
  territories: RawTerritory[];
  vehicleTypes: RawVehicleType[];
  onClickNext: (response: RoutesApiBulkAssignResponse) => void;
  onClickClose: () => void;
}

export default function ExecuteAssigningStageBody(props: Props) {
  const {
    territories,
    vehicleTypes,
    carryStaffs,
    schedules,
    requests,
    routesApiParamSettings,
    onClickClose,
    onClickNext,
  } = props;
  const [removedCasIds, setRemovedCasIds] = useState<number[]>([]);
  const [removedReqIds, setRemovedReqIds] = useState<number[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState("");
  const [selectableApiType, setSelectableApiType] =
    useState<SelectableApiType>("ec-and-nm");

  useEffect(() => {
    return () => {
      stopLoopEventEmitter.removeAllListeners("stop");
    };
  }, []);

  const targetDate =
    pageStore.requestsTargetTerm.type == "specified"
      ? pageStore.requestsTargetTerm.date
      : getTodayStr();

  const excludedRequestReeasonMap = useMemo(() => {
    const result = getExcludedRequestReasons({
      apiType: selectableApiType,
      targetDate: targetDate,
      requests: requests,
      carryStaffs,
      schedules,
      territories,
      routesApiParamSettings: routesApiParamSettings,
    });
    return result;
  }, [selectableApiType]);

  const handleChangeCheck = (
    targetType: "request" | "carry_staff",
    targetId: number
  ) => {
    const processor = {
      carry_staff: {
        // l: list, f: function
        l: removedCasIds,
        f: setRemovedCasIds,
      },
      request: {
        l: removedReqIds,
        f: setRemovedReqIds,
      },
    };
    const { l, f } = processor[targetType];
    const index = l.indexOf(targetId);
    if (index < 0) {
      l.push(targetId);
    } else {
      l.splice(index, 1);
    }
    f([...l]);
  };

  const handleClickNext = async () => {
    if (carryStaffs.length == 0) {
      alert("配達スタッフが存在しないため、アサインを実行できません。");
      return;
    }

    if (requests.length == 0) {
      alert("未アサイン依頼が存在しないため、アサインを実行できません。");
      return;
    }

    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await callAssignmentApi({
        apiType: selectableApiType,
        targetDate: targetDate,
        carryStaffIds: carryStaffs
          .filter((cas) => removedCasIds.indexOf(cas.id) < 0)
          .map((cas) => cas.id),
        requestIds: requests
          .filter((req) => removedReqIds.indexOf(req.id) < 0)
          .map((req) => req.id),
        routesApiParamSettings: props.routesApiParamSettings,
        timeout: 300,
        eventEmitter: stopLoopEventEmitter,
      });
      setLoading(false);
      if (!response.isSuccess && response.code == "interrupted") {
        onClickClose();
      } else {
        onClickNext(response);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage("アサインを実行できませんでした。");
      setLoading(false);
    }
  };

  const handleClickClose = () => {
    if (loading) {
      const confirmed = confirm("まだ実行中ですが、終了しますか？");
      if (confirmed) {
        setLoadingLabel("終了中です...");
        stopLoopEventEmitter.emit("stop");
      }
    } else {
      onClickClose();
    }
  };

  return (
    <ModalBodyWrapper
      title="アサイン対象確認"
      errorMessage={errorMessage}
      showLoading={loading}
      loadingLabel={loadingLabel}
      onClickClose={handleClickClose}
      nextButtonDisabled={loading}
      nextButtonLabel="実行"
      onClickNext={handleClickNext}
    >
      <div className="d-flex flex-column mx-3">
        <div className="d-flex flex-column">
          <div className="font-weight-bold">利用API</div>
          <div className="py-3">
            <RoutesApiSelection
              showLabel={false}
              selectableApiTypes={getSelectableApiTypes(
                "assign",
                routesApiParamSettings
              )}
              routesApiParamSettings={routesApiParamSettings}
              onSelect={(apiType) => {
                setSelectableApiType(apiType);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="font-weight-bold mb-2">
            配達スタッフ ({carryStaffs.length - removedCasIds.length}人)
          </div>
          <div
            className="col-12 py-3 background-gray border rounded"
            style={{ overflowY: "scroll", maxHeight: 100 }}
          >
            {carryStaffs.map((cas) => {
              const isChecked = removedCasIds.indexOf(cas.id) < 0;
              const territory = territories.find(
                (ter) => ter.id == cas.territory_id
              );
              return (
                <label className="row mx-2 py-1" key={cas.id}>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => handleChangeCheck("carry_staff", cas.id)}
                  />
                  <span className="ml-2">
                    スタッフ名: {cas.name} / 担当エリア:{" "}
                    {territory ? territory.name : "-"}
                  </span>
                </label>
              );
            })}
          </div>
        </div>
        <div className="d-flex flex-column mt-2">
          <div className="font-weight-bold mb-2">
            未アサイン依頼 (
            {requests.length -
              removedReqIds.length -
              Object.keys(excludedRequestReeasonMap).length}
            件)
          </div>
          <div
            className="col-12 py-3 border rounded"
            style={{ overflowY: "scroll", maxHeight: 300 }}
          >
            {requests.map((req) => {
              const isChecked = removedReqIds.indexOf(req.id) < 0;
              const excludedReason: string | undefined =
                excludedRequestReeasonMap[`request_${req.id}`];
              return (
                <div key={req.id} className="row d-flex flex-column mx-2 mb-3">
                  <label className="py-0 mb-0">
                    <input
                      type="checkbox"
                      checked={excludedReason == null && isChecked}
                      disabled={excludedReason != null}
                      onChange={() => handleChangeCheck("request", req.id)}
                    />
                    <span className="ml-2">
                      ID: {req.id} / 配達先: {req.receiver_full_address}
                    </span>
                  </label>
                  {excludedReason && (
                    <div className="ml-3">
                      <span style={{ fontSize: 12, color: "red" }}>
                        {excludedReason}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ModalBodyWrapper>
  );
}
