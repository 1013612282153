import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


interface Props {
  onChange?: (value: number) => void
  value: number
}

export default function NumberInput(props: Props) {
  const {
    onChange = () => { },
    value
  } = props;

  const tooltip = (
    <Tooltip id='tooltip'>
      時間帯で重複する依頼が<br />
      設定値を上回る場合はまとめて表示します。
    </Tooltip>
  );

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='mr-3'>
        <OverlayTrigger placement='top' overlay={tooltip}>
          <span>表示行数</span>
        </OverlayTrigger>
      </div>
      <input
        type='number'
        className='form-control mr-3'
        value={value}
        style={{ width: '70px' }}
        onChange={(e) => onChange(parseInt(e.target.value))}
      />
    </div>
  );
};
