import { getPreciseDistance } from "geolib";
import { Coords } from "google-map-react";
import { carryStaffConst } from "../../../constants/CarryStaff";
import { CurrentLocationWithCarryStaffModel } from "../../../models/CurrentLocationWithCarryStaffModel";
import carryStaffRequestSequencesStore from "../stores/CarryStaffRequestSequencesStore";
import {
  filterRequests,
  filterThirdPartyDeliveryTasks,
} from "../utils/FilterUtils";
import { getSelectedItem, getInfoWindowVisibleItems } from "../utils/Utils";

export type RoutesToShowOnMapResponse =
  | {
      // singleは1つの依頼、multiは複数の依頼
      type: "single";
      info: {
        routes: Coords[];
      };
    }
  | {
      type: "multi";
      deliveryType: "route";
      info: {
        fromCas: [Coords, Coords] | null;
        routes: [Coords, Coords][];
      };
    }
  | {
      type: "multi";
      deliveryType: "not_route";
      info: {
        fromCas: [Coords, Coords] | null;
        // "[Coords, Coords] | Coords[]"がある場所から別の場所へのパス
        inner: ([Coords, Coords] | Coords[])[];
        inter: [Coords, Coords][]; // メモ：これは直線
      };
    }
  | {
      type: "none";
    };

/**
 * 依頼俯瞰メニューで必要な経路を取得するためのメソッド.
 */
export function getRoutesToShowOnMap(): RoutesToShowOnMapResponse {
  const itemInfo = getSelectedItem();
  if (itemInfo.target && itemInfo.targetType == "request") {
    // 依頼が選択されている状態で表示するのは、その依頼の配達元と配達先を結ぶ線だけ
    const filteredRequests = filterRequests();
    const needsDisplay = filteredRequests
      .map((item) => item.id)
      .some((itemId) => itemId == itemInfo.targetId);
    if (!needsDisplay) {
      return {
        type: "none",
      };
    }

    const routes: Coords[] = itemInfo.target.routes || [
      { lat: itemInfo.target.sender.lat, lng: itemInfo.target.sender.lng },
      { lat: itemInfo.target.receiver.lat, lng: itemInfo.target.receiver.lng },
    ];
    return {
      type: "single",
      info: {
        routes: routes,
      },
    };
  }

  if (itemInfo.target && itemInfo.targetType == "thirdPartyDeliveryTask") {
    // 外部配達が選択されている状態で表示するのは、その依頼の配達元と配達先を結ぶ線だけ
    const filteredThirdPartyDeliveryTasks = filterThirdPartyDeliveryTasks();
    const needsDisplay = filteredThirdPartyDeliveryTasks
      .map((item) => item.id)
      .some((itemId) => itemId == itemInfo.targetId);
    if (!needsDisplay) {
      return {
        type: "none",
      };
    }

    const routes: Coords[] = itemInfo.target.routes || [
      { lat: itemInfo.target.sender.lat, lng: itemInfo.target.sender.lng },
      { lat: itemInfo.target.receiver.lat, lng: itemInfo.target.receiver.lng },
    ];
    return {
      type: "single",
      info: {
        routes: routes,
      },
    };
  }

  if (itemInfo.targetType == "carryStaff" && itemInfo.target) {
    const selectedCasLocation = itemInfo.target;
    if (selectedCasLocation.staffType == carryStaffConst.STAFF_TYPES.route) {
      // ルート配達の場合には、carry_staff_request_sequencesに従った順序で返す
      const routesInfo = getRoutesForTypeRoute(selectedCasLocation);
      return {
        type: "multi",
        deliveryType: "route",
        info: routesInfo,
      };
    }

    const routesInfo = getRoutesForOtherThanTypeRoute(selectedCasLocation);
    return {
      type: "multi",
      deliveryType: "not_route",
      info: routesInfo,
    };
  }

  // 上記以外の場合(何も選択されていない場合)、表示すべきルートはなし
  return {
    type: "none",
  };
}

/**
 * 依頼俯瞰マップ上で開かれている詳細モーダルの経路を取得するためのメソッド.
 */
export function getRoutesToShowOnMapForInfoWindow(): RoutesToShowOnMapResponse[] {
  let requestRoutes: RoutesToShowOnMapResponse[] = [];
  const { requests, thirdPartyDeliveryTasks } = getInfoWindowVisibleItems();

  requests.forEach((req) => {
    const sender: Coords = { lat: req.sender.lat, lng: req.sender.lng };
    const receiver: Coords = { lat: req.receiver.lat, lng: req.receiver.lng };
    requestRoutes.push({
      type: "single",
      info: {
        routes: req.routes ?? [sender, receiver],
      },
    });
  });

  thirdPartyDeliveryTasks.forEach((tpdt) => {
    const sender: Coords = { lat: tpdt.sender.lat, lng: tpdt.sender.lng };
    const receiver: Coords = { lat: tpdt.receiver.lat, lng: tpdt.receiver.lng };
    requestRoutes.push({
      type: "single",
      info: {
        routes: [sender, receiver],
      },
    });
  });

  return requestRoutes;
}

/**
 * ルート配達の場合の線のための緯度経度列を取得するためのメソッド.
 */
function getRoutesForTypeRoute(
  selectedCasLoc: CurrentLocationWithCarryStaffModel
) {
  const sequences = carryStaffRequestSequencesStore.sequences;

  if (
    sequences.length == 0 ||
    carryStaffRequestSequencesStore.targetCarryStaffId !=
      selectedCasLoc.carryStaffId
  ) {
    return {
      fromCas: null,
      routes: [] as [Coords, Coords][],
    };
  }

  const firstSeq = sequences[0];
  const fromCasPath: [Coords, Coords] = [
    { lat: selectedCasLoc.lat, lng: selectedCasLoc.lng },
    firstSeq.destinationType == "sender"
      ? { lat: +firstSeq.request.sender_lat, lng: +firstSeq.request.sender_lng }
      : {
          lat: +firstSeq.request.receiver_lat,
          lng: +firstSeq.request.receiver_lng,
        },
  ];

  const _routes = sequences
    .reduce(
      // [1, 2, 3, 4, ...] => [[1, 2], [2, 3], [3, 4], ...]
      (prev, curVal, idx, array) =>
        idx == array.length - 1
          ? prev
          : [...prev, { from: array[idx], to: array[idx + 1] }],
      []
    )
    .map((pair) => {
      const fromLatLng =
        pair.from.destinationType == "sender"
          ? {
              lat: +pair.from.request.sender_lat,
              lng: +pair.from.request.sender_lng,
              request_id: +pair.from.request.id,
            }
          : {
              lat: +pair.from.request.receiver_lat,
              lng: +pair.from.request.receiver_lng,
              request_id: +pair.from.request.id,
            };

      const toLatLng =
        pair.to.destinationType == "sender"
          ? {
              lat: +pair.to.request.sender_lat,
              lng: +pair.to.request.sender_lng,
              request_id: +pair.to.request.id,
            }
          : {
              lat: +pair.to.request.receiver_lat,
              lng: +pair.to.request.receiver_lng,
              request_id: +pair.to.request.id,
            };

      const path: [
        Coords & { request_id: number },
        Coords & { request_id: number }
      ] = [fromLatLng, toLatLng];
      return path;
    });

  // 生成した並び順の位置情報から、
  // 依頼俯瞰の左側検索条件に合致する依頼データのみを抽出
  const filteredRequests = filterRequests();
  const routes = _routes.filter((route) => {
    return filteredRequests.some((req) => {
      return req.id == route[0].request_id || req.id == route[1].request_id;
    });
  });

  return {
    fromCas: fromCasPath,
    routes: routes,
  };
}

/**
 * ルート配達以外の場合の線のための緯度経度列を取得するためのメソッド.
 */
function getRoutesForOtherThanTypeRoute(
  selectedCasLoc: CurrentLocationWithCarryStaffModel
) {
  const assignedRequests = [
    ...filterRequests(),
    ...filterThirdPartyDeliveryTasks(),
  ];

  // 対象のCASにアサインされている依頼が存在しない場合、空配列
  if (assignedRequests.length <= 0) {
    return {
      fromCas: null,
      inner: [],
      inter: [],
    };
  }

  // APIではcurrent_locationsベースで取得しているので、確実にlat / lngは存在するはず
  // ルート配達以外の場合には、既存ロジックに従って返す
  const requestsSortedByDistance = assignedRequests
    .filter((req) => req.isEarlyStage() || req.isDeliveryStage())
    .map((req) => {
      const path: [Coords, Coords] = [
        { lat: selectedCasLoc.lat, lng: selectedCasLoc.lng },
        req.isEarlyStage()
          ? { lat: req.sender.lat, lng: req.sender.lng }
          : { lat: req.receiver.lat, lng: req.receiver.lng },
      ];

      return {
        requestId: req.id,
        path: path,
        distance: getPreciseDistance(path[0], path[1]),
      };
    })
    .sort((a, b) => {
      if (a.distance == b.distance) return 0;

      return a.distance < b.distance ? -1 : 1;
    });

  const fromCasPath =
    requestsSortedByDistance.length > 0
      ? requestsSortedByDistance[0].path
      : null;

  // 依頼内での、配達元から配達先へのパス(緯度軽度ペア)のリスト
  const innerRequestsCoords: ([Coords, Coords] | Coords[])[] =
    assignedRequests.map((req) => {
      if (req.routes == null) {
        return [
          { lat: req.sender.lat, lng: req.sender.lng },
          { lat: req.receiver.lat, lng: req.receiver.lng },
        ];
      }

      return req.routes;
    });

  // 準備完了時刻順に並べた時の依頼間の接続用緯度経度ペア
  // [[ある依頼の配達先緯度軽度, 次の依頼の配達元緯度軽度], ...]
  const interRequestsCoords = assignedRequests
    .sort((a, b) => {
      // 準備完了時刻に従って並び替え

      // 両方とも準備完了時間が指定されていない場合、そのまま
      if (a.readyTimeAt == null && b.readyTimeAt == null) return 0;

      // 片方だけ準備完了時間が指定されている場合、その指定されている方を優先
      if (b.readyTimeAt == null) return -1;
      if (a.readyTimeAt == null) return 1;

      return a.readyTimeAt <= b.readyTimeAt ? -1 : 1;
    })
    .reduce(
      // [1, 2, 3, 4, ...] => [[1, 2], [2, 3], [3, 4], ...]
      (prev, curVal, idx, array) =>
        idx == array.length - 1
          ? prev
          : [...prev, { from: array[idx], to: array[idx + 1] }],
      []
    )
    .map(
      (pair) =>
        [
          { lat: pair.from.receiver.lat, lng: pair.from.receiver.lng },
          { lat: pair.to.sender.lat, lng: pair.to.sender.lng },
        ] as [Coords, Coords]
    );

  return {
    fromCas: fromCasPath,
    inner: innerRequestsCoords,
    inter: interRequestsCoords,
  };
}
