import { observable } from "mobx";
import { H3CapacityTimeline } from "../interfaces/H3CapacityTimeline";

export class H3CapacityTimelineModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  h3Index: string;
  sdate: string;
  edate: string;
  capacity: string
  requiredMinute: number
  manualRequiredMinute: number | null
  orgStaffCount: number
  staffCountPrediction: number | null
  createdAt: string
  updatedAt: string

  constructor(response: H3CapacityTimeline) {
    this.id = response.id;
    this.key = response.id;
    this.h3Index = response.h3_index;
    this.sdate = response.sdate;
    this.edate = response.edate;
    this.capacity = response.capacity;
    this.requiredMinute = response.required_minute;
    this.manualRequiredMinute = response.manual_required_minute;
    this.orgStaffCount = response.org_staff_count;
    this.staffCountPrediction = response.staff_count_prediction ?? null;
    this.createdAt = response.created_at;
    this.updatedAt = response.updated_at;
  }
}
