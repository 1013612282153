import { format } from "date-fns";
import { axiosGet, axiosPost } from "../../utils/AxiosClient";
import {
  ExpandedRawRequest,
  RawCarryStaff,
  RawCarryStaffSchedule,
  RawCarryStaffWithSchedules,
  RawRequest,
  RawTerritory,
  Schedule,
} from "./Interfaces";

export function createDefaultSchedule(params: {
  carryStaffId?: number;
  territoryId?: number;
  dateStr?: string;
  territories?: RawTerritory[];
}): Schedule {
  const _dateStr = params.dateStr ?? format(new Date(), "yyyy-MM-dd");
  const territory = params.territoryId
    ? (params.territories || []).find((ter) => ter.id == params.territoryId)
    : undefined;
  return {
    id: null,
    carry_staff_id: params.carryStaffId ?? null,
    territory_id: params.territoryId ?? null,
    territory_name: territory?.name,
    date: _dateStr,
    from_time: new Date(`${_dateStr} 09:00:00+09:00`),
    to_time: new Date(`${_dateStr} 20:00:00+09:00`),
    memo: null,
  };
}

export async function loadSchedules(params: {
  selectedDate: string;
  searchText: string | null;
  page: number;
  perPage: number;
}) {
  const urlParams = new URLSearchParams([
    ["target_date", params.selectedDate],
    ["search_text", params.searchText || ""],
    ["page", `${params.page}`],
    ["per_page", `${params.perPage}`],
  ]);
  const response = (await axiosGet.get(
    `/api/carry_staff_schedules?${urlParams.toString()}`
  )) as {
    data: {
      schedules: RawCarryStaffSchedule[];
      total_count: number;
      carry_staffs: RawCarryStaff[];
    };
  };
  return response;
}

export async function saveSchedule(schedule: Schedule) {
  const response = (await axiosPost.post(`/api/carry_staff_schedules/save`, {
    schedule_id: schedule.id,
    carry_staff_id: schedule.carry_staff_id,
    territory_id: schedule.territory_id,
    target_date: schedule.date,
    from_time: format(schedule.from_time, "HH:mm:00"),
    to_time: format(schedule.to_time, "HH:mm:00"),
    memo: schedule.memo,
  })) as { data: { message: string } };
  return response;
}

export async function deleteSchedule(scheduleId: number) {
  const response = (await axiosPost.post(`/api/carry_staff_schedules/destroy`, {
    schedule_id: scheduleId,
  })) as { data: { message: string } };
  return response;
}

export async function bulkRegisterSchedules(file: File) {
  const formData = new FormData();
  formData.append('file', file);

  const response = (await axiosPost.post(`/api/carry_staff_schedules_imports`, formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })) as { data: { message: string }, status: number };

  return response;
}

export function convertResponseIntoCarryStaffs(data: {
  schedules: RawCarryStaffSchedule[];
  carry_staffs: RawCarryStaff[];
  territories: RawTerritory[];
}) {
  const territoryIdNameMap = data.territories.reduce((accum, current) => {
    accum[`${current.id}`] = current.name;
    return accum;
  }, {});
  let carryStafs: RawCarryStaffWithSchedules[] = [];
  for (const carryStaff of data.carry_staffs) {
    const _carryStaff: RawCarryStaffWithSchedules = {
      id: carryStaff.id,
      name: carryStaff.name,
      image_url: carryStaff.image_url,
      image_url_expired_at: carryStaff.image_url_expired_at,
      territory_id: carryStaff.territory_id,
      vehicle_type_id: carryStaff.vehicle_type_id,
      schedules: data.schedules
        .filter((schedule) => schedule.carry_staff_id == carryStaff.id)
        .map((schedule) => {
          return {
            id: schedule.id,
            carry_staff_id: schedule.carry_staff_id,
            territory_id: schedule.territory_id,
            territory_name: schedule.territory_id
              ? territoryIdNameMap[schedule.territory_id]
              : undefined,
            date: schedule.from_time.substring(0, 10),
            from_time: new Date(schedule.from_time),
            to_time: new Date(schedule.to_time),
            memo: schedule.memo,
          };
        }),
    };
    carryStafs.push(_carryStaff);
  }

  return carryStafs;
}

export async function updateCarryStaffImageUrlExpiredAt(
  carrStaffs: RawCarryStaff[]
) {
  const casIdsWithExpiredUrl = carrStaffs
    .filter(
      (carrStaff) =>
        carrStaff.image_url &&
        carrStaff.image_url_expired_at &&
        new Date(carrStaff.image_url_expired_at) < new Date()
    )
    .map((carrStaff) => carrStaff.id);
  if (casIdsWithExpiredUrl.length > 0) {
    const updatedResponse = (await axiosPost.post(
      `/api/cas_image_presigned_urls/update`,
      { carry_staff_ids: casIdsWithExpiredUrl }
    )) as {
      data: {
        result: string;
        items: {
          id: number;
          image_url: string;
          image_url_expired_at: string;
        }[];
      };
    };

    for (const item of updatedResponse.data.items) {
      const carrStaff = carrStaffs.find((carrStaff) => carrStaff.id == item.id);
      if (!carrStaff) continue;

      carrStaff.image_url = item.image_url;
      carrStaff.image_url_expired_at = item.image_url_expired_at;
    }
  }

  return carrStaffs;
}

export async function loadRequests(params: { selectedDate: string }) {
  const urlParams = new URLSearchParams([["target_date", params.selectedDate]]);
  const response = (await axiosGet.get(
    `/api/carry_staff_schedules/requests/load?${urlParams.toString()}`
  )) as {
    data: {
      requests: RawRequest[];
    };
  };
  return response;
}

export function convertRawReqsIntoExpandedReqs(data: {
  requests: RawRequest[];
}) {
  let requests: ExpandedRawRequest[] = [];
  for (const request of data.requests) {
    const _request: ExpandedRawRequest = {
      id: request.id,
      status: request.status,
      store_status: request.store_status,
      carry_staff_id: request.carry_staff_id,
      delivery_type: request.delivery_type,
      sender_name: request.sender_name,
      sender_full_address: request.sender_full_address,
      sender_lat: request.sender_lat,
      sender_lng: request.sender_lng,
      receiver_name: request.receiver_name,
      receiver_full_address: request.receiver_full_address,
      receiver_lat: request.receiver_lat,
      receiver_lng: request.receiver_lng,
      ready_time_at: request.ready_time_at,
      pickup_start_time: request.pickup_start_time,
      pickup_end_time: request.pickup_end_time,
      delivery_time_at: request.delivery_time_at,
      // 集荷と配達を時間幅で設定することを想定して仮設定
      from_pickup_at: request.ready_time_at,
      to_pickup_at: request.ready_time_at,
      from_delivery_time_at: request.delivery_time_at,
      to_delivery_time_at: request.delivery_time_at,
      delivery_time_slot_start_time: request.delivery_time_slot_start_time,
      delivery_time_slot_end_time: request.delivery_time_slot_end_time,
      delivery_time_slot_name: request.delivery_time_slot_name,
      is_delivery_time_specified: request.is_delivery_time_specified,
    };
    requests.push(_request);
  }

  return requests;
}
