import _ from 'lodash'
import React from "react";
import { observer } from "mobx-react";


interface Tag {
  id: number
  name: string
}

interface Props {
  tags: Tag[];
  prefixIconType?: 'add'|'remove'
  onSelect?: (tagId: number) => void
  className?: string
}

interface State {
}

class SelectableTagList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(): void {
  }

  render() {

    return (
      <div className={this.props.className || ''}>
        {this.props.tags.map(tag => {
          let tagStyles = styles.tagWrapper;
          if (this.props.prefixIconType != null ) {
            tagStyles = {
              ...styles.tagWrapper,
              ...styles.tagWrapperSelectabe
            }
          }
          return (
            <div 
              key={`tag_${tag.id}`}
              style={tagStyles}
              onClick={() => this.props.onSelect && this.props.onSelect(tag.id)}
            >
              {this.props.prefixIconType === 'add' ? <i className="fas fa-plus" style={{
                ...styles.iconCommon, ...styles.addIcon
              }}></i> : null}
              {this.props.prefixIconType === 'remove' ? <i className="fas fa-minus-circle" style={{
                ...styles.iconCommon, ...styles.deleteIcon
              }}></i> : null}
              <div style={styles.tag}>
                #{tag.name}
              </div>
            </div>
            
          )
        })}
      </div>
    );
  }
}

export default observer(SelectableTagList);

const styles = {
  tagWrapper: {
    display: 'inline-block',
    marginRight: ".7em"
  },
  tagWrapperSelectabe: {
    cursor: "pointer",
  },
  iconCommon: {
    margin: "0px 0.3em 0.6em 0px",
    lineHeight: 1,
    fontSize: ".7em"
  },
  addIcon: {
    color: "#4e73df",
  },
  deleteIcon: {
    color: "red",
  },
  tag: {
    display: "inline-block",
    margin: "0 .1em .6em 0",
    padding: ".6em",
    lineHeight: 1,
    textDecoration: "none",
    color: "#4e73df",
    backgroundColor: "#fff",
    border: "1px solid #4e73df",
    borderRadius: "2em",
    fontSize: ".7em"
  }
}
