export const COLORS = {
  brand: {
    anycarry: "#f78733",
    demaecan: "#e83434",
    amazon: "#000000",
    wolt: "#14a5e2",
    rest: "#a8b0b5",
  },
  startMarker: "rgb(61,180,85)",
  goalMarker: "#f78733",
  status: {
    wait_store: 'hsl(190, 30%, 65%)',
    waiting_approve: 'hsl(200, 40%, 65%)',
    approved: 'hsl(200, 55%, 55%)',
    checked: 'hsl(210, 55%, 50%)',
    shipped: 'hsl(220, 60%, 50%)',
    pickup: 'hsl(250, 70%, 40%)',
    onhold: 'hsl(51, 100%, 50%)',
    return: 'hsl(9, 100%, 64%)',
    uncheck: 'hsl(0, 0%, 100%)',
    delivered: 'hsl(90, 90%, 39%)',
    cancel: 'hsl(0, 0%, 20%)',
  }
} as const;
