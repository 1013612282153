import React from "react";

interface Props {
  onClick: () => void;
}

export default class AddConditionButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="d-flex justify-content-start ml-3 mt-1">
        <button
          className="btn btn-primary btn-sm mr-2"
          style={{ lineHeight: 1.5 }}
          onClick={() => this.props.onClick()}
        >
          <i className="fas fa-plus mr-1" style={{ color: "white" }}></i>
          条件
        </button>
      </div>
    );
  }
}
