import type { RawRoutesApiParamSetting } from "../../../interfaces/entities";
import { axiosPost } from "../../../utils/AxiosClient";
import type { SelectableApiType } from "../api-code-utils";
import { parseApiType, selectApiParamSetting } from "../api-code-utils";
import { DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM } from "../consts";
import type { LoopControlParams, RoutesApiArrangeInnerParams } from "./common";
import { getRoutesApiResultViaAdms } from "./common";

interface Params {
  apiType: SelectableApiType;
  targetDate: string;
  includePast: boolean;
  carryStaffId: number;
  routesApiParamSettings: RawRoutesApiParamSetting[];
}

export async function callArrangementApi(params: Params & LoopControlParams) {
  const { timeout, eventEmitter, ..._params } = params;

  const innerParams = convertParamsIntoInnerParams(_params);

  const postResponse = await postArrangementTaskViaAdms(innerParams);

  const result = await getRoutesApiResultViaAdms("arrange", {
    transactionCode: postResponse.data.transactionCode,
    timeout,
    eventEmitter,
  });

  return result;
}

function convertParamsIntoInnerParams(params: Params) {
  const {
    apiType,
    targetDate,
    includePast,
    carryStaffId,
    routesApiParamSettings,
  } = params;

  const { apiCode } = parseApiType(apiType);
  const apiParamSetting = selectApiParamSetting(
    apiType,
    routesApiParamSettings
  );

  const innerParams: RoutesApiArrangeInnerParams = {
    api_code: apiCode,
    target_date: targetDate,
    include_past: includePast,
    carry_staff_id: carryStaffId,
    ignore_schdules: true,
    param_settings: {
      pickup_duration: apiParamSetting.pickup_duration,
      tolerant_seconds_before_pickup_at:
        apiParamSetting.tolerant_seconds_before_pickup_at,
      tolerant_seconds_after_pickup_at:
        apiParamSetting.tolerant_seconds_after_pickup_at,
      dropoff_duration: apiParamSetting.dropoff_duration,
      tolerant_seconds_before_dropoff_at:
        apiParamSetting.tolerant_seconds_before_dropoff_at,
      tolerant_seconds_after_dropoff_at:
        apiParamSetting.tolerant_seconds_before_dropoff_at,
    },
    post_processing: {
      // 画面側で通知を行なっているためLambda側では通知なし。
      // こちらに統合できないのは、こちらの設定は自動並び替えによるものだけだけど、
      // 画面側からは自動の場合に加えて手動の場合もあるため、
      // その両方を通知できるようにするとなると画面側から通知させる方が簡単。
      notify: false,
      // 結果を確認させてから画面側で更新APIを呼び出させるので、更新もなし
      update: false,
    },
  };

  if (
    (innerParams.api_code == "ec-and-nm" ||
      innerParams.api_code == "geoapify") &&
    innerParams.param_settings
  ) {
    innerParams.param_settings["geoapify"] = {
      travel_mode:
        apiParamSetting.geoapify_travel_mode ??
        DEFAULT_GEOAPIFY_TRAVEL_MODE_FOR_EC_AND_NM,
      max_speed: apiParamSetting.geoapify_max_speed,
      optimization_type: apiParamSetting.geoapify_optimization_type,
    };
  }

  return innerParams;
}

async function postArrangementTaskViaAdms(params: RoutesApiArrangeInnerParams) {
  const response = (await axiosPost.post("/api/routes_apis/task/arrange", {
    ...params,
  })) as {
    data: {
      message: string;
      transactionCode: string;
    };
  };
  return response;
}
