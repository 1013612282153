import React from "react";

interface Props {
  content: string;
  loading?: boolean;
  loadingText?: string;
  loadedText?: string;
}

export default function MAPIndicatorContent(props: Props) {
  const { content, loading, loadingText, loadedText } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <div>{content}</div>
      <div
        className="d-flex mr-2"
        style={{ marginLeft: "auto", alignItems: "center" }}
      >
        {loading && (
          <>
            <div
              className="spinner-border spinner-border-sm mr-2"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
            <div>{loadingText}</div>
          </>
        )}
        {!loading && <div>{loadedText}</div>}
      </div>
    </div>
  );
}
