import React from "react";
import {mapMarkerPlaceStyle} from '../styles/StoreMarkerStyles'

interface Props {
  lat: number,
  lng: number,
  zoomLevel?: number,
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean,
}

interface State {
  showInfoWindow: boolean
}

export default class OfficeMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false
    };
  }

  render() {
    return (
      <div
        style={mapMarkerPlaceStyle}
      >
        <i className={"fa fa-building"}/>
      </div>
    )
  }

  private onClickMarker(event: Event) {
    this.setState({showInfoWindow: !this.state.showInfoWindow})
    event.preventDefault();
    event.stopPropagation();
  }
}
