import { Coords } from "google-map-react";
import _ from "lodash";
import { action, observable, set } from "mobx";
import { RawH3MinPickupMinutesSetting } from "../interfaces/entities/RawH3MinPickupMinutesSetting";
import { H3Index } from "../interfaces/H3Index";
import OfficesResponse from "../interfaces/OfficesResponse";
import ServiceAreasResponse from "../interfaces/ServiceAreasResponse";
import VendorCompanyResponse from "../interfaces/VendorCompanyResponse";
import { H3IndexModel } from "../models/H3IndexModel";
import { VisibleOfficeModel } from "../models/VisibleOfficeModel";
import { VisibleServiceAreaModel } from "../models/VisibleServiceAreaModel";
import { VisibleVendorCompanyModel } from "../models/VisibleVendorCompanyModel";
import { axiosGet } from "../utils/AxiosClient";
import MapAttributes from "../constants/MapAttributes";

interface NewMarker {
  id: number;
  lat: number;
  lng: number;
  visible: boolean;
}

declare var google: any;

export class DeaasAreaOverlookStore {
  visibleOffices = observable<VisibleOfficeModel>([]);
  visibleVendorCompanies = observable<VisibleVendorCompanyModel>([]);
  visibleServiceAreas = observable<VisibleServiceAreaModel>([]);
  visibleNewMarkers = observable<NewMarker>([]);
  intersectH3Indices = observable<H3IndexModel>([]);

  @observable
  vendorCompanyRange: number = 30;

  @observable
  serviceAreaRange: number = 1500;

  @observable
  newMarkerRange: number = 1500;

  newMarkerCount: number = 0;

  map: any;

  constructor() {
    this.loadOffices();
    this.loadVendorCompanies();
    this.loadServiceAreas();
    set(this.visibleNewMarkers, []);
  }

  @action
  public setMap(map) {
    this.map = map;
  }

  @action
  public async loadOffices() {
    const response = await axiosGet.get("/api/offices", {
      params: {},
    });
    const results = response.data;

    const offices = _.map(results, (result: OfficesResponse) => {
      return new VisibleOfficeModel(result);
    });

    this.replaceOffices(offices);
  }

  @action
  private replaceOffices(offices) {
    this.visibleOffices.replace(offices);
  }

  @action
  public async loadVendorCompanies() {
    const response = await axiosGet.get("/api/vendor_companies", {
      params: {},
    });
    const results = response.data;

    const vendorCompanies = _.map(results, (result: VendorCompanyResponse) => {
      return new VisibleVendorCompanyModel(result);
    });

    this.replaceVendorCompanies(vendorCompanies);
  }

  @action
  public async loadServiceAreas() {
    const response = (await axiosGet.get("/api/service_areas", {
      params: {},
    })) as {
      data: { service_areas: ServiceAreasResponse[]; h3_indices: H3Index[] };
    };
    const results = response.data.service_areas;

    const serviceAreas = _.map(results, (result: ServiceAreasResponse) => {
      return new VisibleServiceAreaModel(result);
    });

    this.replaceServiceAreas(serviceAreas);
  }

  @action
  public async loadServiceAreasInBoundsWithHex(
    ne: Coords,
    se: Coords,
    sw: Coords,
    nw: Coords,
    centerLat: number,
    centerLng: number,
    hexAdditionals: (
      | "timelines"
      | "predictions"
      | "min_pickup_minutes_settings"
    )[],
    zoom: number,
    options?: {
      datetime?: Date;
      thresholdRequiredMinutes?: number;
    }
  ) {
    // マップを一定以上縮小している場合はhexAdditionalsを渡さないようにする。
    // マップ内のH3 Indexの数が増加すると、主にtimelinesの取得が非常に重くなるのでその対応。
    const hexAdditionalsFilteredByZoom = zoom > MapAttributes.MIN_SERVICE_AREA_HEX_ADDITIONAL_ZOOM ? hexAdditionals : []
    const response = (await axiosGet.get("/api/service_areas", {
      params: {
        inBounds: true,
        hexAdditionals: hexAdditionalsFilteredByZoom,
        neLng: String(ne.lng),
        neLat: String(ne.lat),
        seLng: String(se.lng),
        seLat: String(se.lat),
        swLng: String(sw.lng),
        swLat: String(sw.lat),
        nwLng: String(nw.lng),
        nwLat: String(nw.lat),
        centerlat: String(centerLat),
        centerlng: String(centerLng),
        datetime: options?.datetime?.toISOString(),
        thresholdRequiredMinutes: options?.thresholdRequiredMinutes,
      },
    })) as {
      data: {
        service_areas: ServiceAreasResponse[];
        h3_indices: H3Index[];
        min_pickup_minutes_settings?: Pick<
          RawH3MinPickupMinutesSetting,
          "h3_index" | "from_time" | "to_time" | "min_pickup_minutes"
        >[];
      };
    };

    const serviceAreas = _.map(
      response.data.service_areas,
      (result: ServiceAreasResponse) => {
        return new VisibleServiceAreaModel(result);
      }
    );
    this.replaceServiceAreas(serviceAreas);

    if (hexAdditionalsFilteredByZoom.length > 0) {
      const h3Indices = _.map(response.data.h3_indices, (result) => {
        const minPickupMinutesSettings = (
          response.data.min_pickup_minutes_settings ?? []
        ).filter((setting) => setting.h3_index == result.h3_index);
        return new H3IndexModel(result, minPickupMinutesSettings);
      });
      this.replaceH3Indices(h3Indices);
    }
  }

  @action
  private replaceVendorCompanies(vendorCompanies: VisibleVendorCompanyModel[]) {
    this.visibleVendorCompanies.replace(vendorCompanies);
  }

  @action
  private replaceNewMarkers(newMarkers: NewMarker[]) {
    this.visibleNewMarkers.replace(newMarkers);
  }

  @action
  private replaceServiceAreas(serviceAreas: VisibleServiceAreaModel[]) {
    this.visibleServiceAreas.replace(serviceAreas);
  }

  @action
  private replaceH3Indices(h3Indices: H3IndexModel[]) {
    this.intersectH3Indices.replace(h3Indices);
  }

  @action
  public toggleVendorCompanyVisible(visibleVendorCompanyModel) {
    const visibleVendorCompany = _.find(
      this.visibleVendorCompanies,
      (object) => {
        return object.id == visibleVendorCompanyModel.id;
      }
    );
    if (visibleVendorCompany == null) {
      return;
    }

    visibleVendorCompany.visible = !visibleVendorCompany.visible;
    this.replaceVendorCompanies(this.visibleVendorCompanies);
  }

  @action
  public toggleOfficeVisible(cisibleOfficeModel) {
    const visibleVendorOffice = _.find(this.visibleOffices, (object) => {
      return object.id == cisibleOfficeModel.id;
    });
    if (visibleVendorOffice == null) {
      return;
    }

    visibleVendorOffice.visible = !visibleVendorOffice.visible;
    this.replaceOffices(this.visibleOffices);
  }

  @action
  public toggleServiceAreaVisible(visibleServiceAreaModel) {
    const visibleServiceArea = _.find(this.visibleServiceAreas, (object) => {
      return object.id == visibleServiceAreaModel.id;
    });
    if (visibleServiceArea == null) {
      return;
    }

    visibleServiceArea.visible = !visibleServiceArea.visible;
    this.replaceServiceAreas(this.visibleServiceAreas);
  }

  @action
  public toggleNewMarker(newMarker: NewMarker) {
    const findNewMarker = _.find(this.visibleNewMarkers, (object) => {
      return newMarker.id == object.id;
    });
    if (findNewMarker == null) {
      return;
    }

    findNewMarker.visible = !findNewMarker.visible;
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public viewAllVendorCompanies() {
    _.each(this.visibleVendorCompanies, (object) => {
      object.visible = true;
    });
    this.replaceVendorCompanies(this.visibleVendorCompanies);
  }

  @action
  public hiddenAllVendorCompanies() {
    _.each(this.visibleVendorCompanies, (object) => {
      object.visible = false;
    });
    this.replaceVendorCompanies(this.visibleVendorCompanies);
  }

  @action
  public viewAllServiceAreas() {
    _.each(this.visibleServiceAreas, (object) => {
      object.visible = true;
    });
    this.replaceServiceAreas(this.visibleServiceAreas);
  }

  @action
  public hiddenAllServiceAreas() {
    _.each(this.visibleServiceAreas, (object) => {
      object.visible = false;
    });
    this.replaceServiceAreas(this.visibleServiceAreas);
  }

  @action
  public viewAllNewMarkers() {
    _.each(this.visibleNewMarkers, (object) => {
      object.visible = true;
    });
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public hiddenAllNewMarkers() {
    _.each(this.visibleNewMarkers, (object) => {
      object.visible = false;
    });
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public setVendorCompanyRange(meter: number) {
    this.vendorCompanyRange = meter;
  }

  @action
  public setServiceAreaRange(meter: number) {
    this.serviceAreaRange = meter;
  }

  @action
  public setNewMarkerRange(meter: number) {
    this.newMarkerRange = meter;
  }

  @action
  public addNewMarker(address: string) {
    this.newMarkerCount++;
    const newMarkers = this.visibleNewMarkers.slice();
    newMarkers.push({
      lat: this.map.getCenter().lat(),
      lng: this.map.getCenter().lng(),
      visible: true,
      id: this.newMarkerCount,
    });
    this.replaceNewMarkers(newMarkers);
  }

  @action
  public changeNewMarkerPosition(
    newMarker: NewMarker,
    lat: number,
    lng: number
  ) {
    const findNewMarker = _.find(this.visibleNewMarkers, (object) => {
      return newMarker.id == object.id;
    });
    if (findNewMarker == null) {
      return;
    }

    findNewMarker.lat = lat;
    findNewMarker.lng = lng;
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  public moveCenter(address: string) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: address,
        region: "jp",
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.map.setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        }
        console.log(status, results);
      }
    );
  }
}

const singleton = new DeaasAreaOverlookStore();
export default singleton;
