import React from "react";
import I18n from "../packs/i18n/i18n";
import "../packs/i18n/ja";
import {INotification} from "./ui/NotificationCenter/interfaces";
import {NotificationItem} from "./ui/NotificationCenter/NotificationItem";
import NotificationCenter from "./ui/NotificationCenter";

// 開閉状態を示すlocalStorageのキー
const REQUEST_NOTIFICATION_CENTER_CLOSE = "adms_request_notification_center_open"

interface State {
    notifications: INotification[]
    isDefaultOpen: boolean
}
interface Props {
    notice_request_alerts: any
    // 要望によって表示位置をhaml側で切り替えられるように。defaultはleft
    horizon?: "right" | "left"
}
interface State {}
export default class RequestNotificationCenter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            notifications: [],
            isDefaultOpen: !localStorage.getItem(REQUEST_NOTIFICATION_CENTER_CLOSE)
        }
    }

    //　リスト開閉時に実行
    handleToggleList(isOpen: boolean) {
        if (!isOpen) {
            // リストが閉じられ時にlocalStorageに閉じているフラグを追加
            localStorage.setItem(REQUEST_NOTIFICATION_CENTER_CLOSE, "true")
        } else {
            // リストが開いた時に閉じているフラグを除去
            localStorage.removeItem(REQUEST_NOTIFICATION_CENTER_CLOSE)
        }
    }

    componentDidMount() {
        const noticeRequestAlerts = this.props.notice_request_alerts
        const notifications = Object.keys(noticeRequestAlerts).map<INotification>((key) => {
            const notice = noticeRequestAlerts[key]
            const content = `${I18n.t(`enums.request_alert.alert_type.${key}`)}が${notice}件あります`
            const component = (
                <NotificationItem
                    id={key}
                    onClick={() => {
                        const path = `/requests/?q[${key}_all_true]=1&q[status_eq]=processing`
                        location.href = path
                    }}
                    actionTitle="表示"
                >
                    {content}
                </NotificationItem>
            )

            return {
                id: key,
                render: component
            }
        })
        this.setState({notifications})
    }

    render() {
        const { horizon = "right" }  = this.props
        const { notifications, isDefaultOpen } = this.state
        return (
            <NotificationCenter
                notifications={notifications}
                horizon={horizon}
                vertical="bottom"
                defaultShowList={isDefaultOpen}
                width={380}
                onToggle={this.handleToggleList}
            />
        )
    }
}