export const ScheduleConst = {
  FROM_TIME: 0,
  TO_TIME: 24
} as const;

export const ScheduleStyleConst = {
  ROW_HEADER_HEIGHT: 40,
  ROW_BODY_HEIGHT: 60,
  SCHEDULE_ITEM_HEIGHT: 35,
  REQUEST_ITEM_HEIGHT: 20,
  // 配達依頼をスケジュール画面に描画する際、縦に並べる必要がある場合の配達依頼バー間の空白
  REQUEST_ITEM_BOTTOM_MARGIN: 2,
  // 配達依頼をスケジュール画面に描画する際のその行上部からのマージン
  REQUEST_ITEM_TOP_MARGIN: 30
} as const;
