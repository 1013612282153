import React, { FC, useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { COLORS } from "../../../constants/Colors";
import { pageStore, searchConditionsStore } from "../stores";
import { RelatedCarryStaffItem } from "./RelatedCarryStaffItem";

interface Props {}

export const RelatedCarryStaffsAccordion: FC<Props> = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!pageStore.carryStaff.territory_id) {
        return;
      }
      await pageStore.loadRelatedCarryStaffs(
        searchConditionsStore.targetTerm.date,
        pageStore.carryStaff.id,
      );
    })();
  }, [searchConditionsStore.targetTerm.date]);

  return (
    <Accordion className={"mt-4"}>
      <Card className="border-0 rounded-0">
        <Accordion.Toggle
          className={"d-flex justify-content-between align-items-center"}
          as={Card.Header}
          eventKey={"0"}
          onClick={() => setOpen(!open)}
        >
          <div className="d-flex justify-content-between align-items-center">
            <strong>同じ日付・担当エリアの配達スタッフ</strong>
            <span
              style={{
                fontWeight: "bold",
                textAlign: "center",
                color: COLORS.brand.anycarry,
                marginLeft: 10,
                marginRight: 5,
              }}
            >
              {pageStore.relatedCarryStaffs.length}
            </span>
            件
          </div>
          {pageStore.relatedCarryStaffs.length > 0 && (
            <>
              {open ? (
                <i className="fas fa-angle-up" />
              ) : (
                <i className="fas fa-angle-down" />
              )}
            </>
          )}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={"0"}>
          <ListGroup style={{ maxHeight: "50vh", overflow: "auto" }}>
            {pageStore.relatedCarryStaffs.map((cas) => {
              return <RelatedCarryStaffItem key={cas.id} carryStaff={cas} />;
            })}
          </ListGroup>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
