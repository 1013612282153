import _ from "lodash";
import { IReactionDisposer, reaction } from "mobx";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import MapAttributes from "../../../constants/MapAttributes";
import { requestConst } from "../../../constants/Request";
import I18n from "../../../packs/i18n/i18n";
import "../../../packs/i18n/ja";
import { RequestModel } from "../../../models/RequestModel";
import { ThirdPartyDeliveryTaskModel } from "../../../models/ThirdPartyDeliveryTaskModel";
import carryStaffLocationsStore from "../../../stores/CarryStaffLocationsStore";
import requestsStore from "../../../stores/RequestsStore";
import thirdPartyDeliveryTasksStore from "../../../stores/ThirdPartyDeliveryTasksStore";
import RequestTableBody from "../Panes/RequestTableBody";
import ThirdPartyDeliveryTaskTableBody from "../Panes/ThirdPartyDeliveryTaskTableBody";
import searchConditionsStore from "../stores/SearchConditionsStore";
import {
  filterRequests,
  filterThirdPartyDeliveryTasks,
} from "../utils/FilterUtils";
import { getCarryStaffNameById, isLoading, selectItem } from "../utils/Utils";

interface Props {}

interface State {
  activeTable: "request" | "thirdPartyDeliveryTask";
  selectableTableLabel:
    | "request"
    | "thirdPartyDeliveryTask"
    | "both"
    | "nothing";
}

class CarryStaffDetail extends React.Component<Props, State> {
  scrollableElementRef: React.RefObject<HTMLDivElement>;
  dataLoadingDisposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.state = {
      activeTable: "request",
      selectableTableLabel: "both",
    };
    this.scrollableElementRef = React.createRef();
  }

  componentDidMount() {
    const visibleSequenceNumber = localStorage.getItem(
      this.getVisibleSequenceNumberKey()
    );
    if (visibleSequenceNumber) {
      const toBool: boolean = JSON.parse(visibleSequenceNumber.toLowerCase());
      this.handleChangeVisibleSequenceNumber(!toBool);
    }

    this.dataLoadingDisposer = reaction(
      () =>
        !carryStaffLocationsStore.loadingInfo.loading &&
        !requestsStore.loadingInfo.loading &&
        !thirdPartyDeliveryTasksStore.loadingInfo.loading,
      (value, r) => {
        if (value) {
          this.setSelectableTableLabel();
        }
      }
    );
  }

  componentWillUnmount() {
    // reactionの解除
    this.dataLoadingDisposer();
  }

  backToDriverList() {
    selectItem(undefined, undefined);
    searchConditionsStore.moveTo("carry_staffs");
  }

  setSelectableTableLabel() {
    const requests = filterRequests();
    const thirdPartyDeliveryTasks = filterThirdPartyDeliveryTasks();

    let afterStl: State["selectableTableLabel"] =
      this.state.selectableTableLabel;
    let afterAt: State["activeTable"] = this.state.activeTable;

    if (requests.length > 0 && thirdPartyDeliveryTasks.length > 0) {
      afterStl = "both";
    } else if (requests.length > 0) {
      afterStl = "request";
    } else if (thirdPartyDeliveryTasks.length > 0) {
      afterStl = "thirdPartyDeliveryTask";
    } else {
      afterStl = "nothing";
    }

    if (this.state.selectableTableLabel != afterStl) {
      // afterStlがbothもしくはnothingの場合には、activeTableは元のままで大丈夫
      if (afterStl == "request") {
        afterAt = "request";
      } else if (afterStl == "thirdPartyDeliveryTask") {
        afterAt = "thirdPartyDeliveryTask";
      }
    }

    this.setState({
      selectableTableLabel: afterStl,
      activeTable: afterAt,
    });
  }

  handleSelectCarryStaff(newCasId: string) {
    const carryStaffId = newCasId == "unassigned" ? newCasId : +newCasId;
    searchConditionsStore.changeCond(
      "carry_staff_requests_or_tpdts",
      "carryStaff",
      {
        id: carryStaffId,
        name:
          newCasId == "unassigned"
            ? "(未アサイン)"
            : getCarryStaffNameById(+newCasId)!,
      }
    );

    selectItem(
      "carryStaff",
      newCasId == "unassigned" ? "unassigned" : +newCasId
    );
  }

  handleSelectStatus(newStatus: string) {
    const value =
      newStatus == "all" || newStatus == "undelivered" ? newStatus : +newStatus;

    searchConditionsStore.changeCond(
      "carry_staff_requests_or_tpdts",
      "status",
      value
    );
    // ステータスが変わることで表示すべきデータが変わり、
    // そうするとヘッダー部分の表示が変わる可能性があるので
    this.setSelectableTableLabel();
  }

  handleChangeVisibleSequenceNumber(oldVisibleSequenceNumber: boolean) {
    searchConditionsStore.changeCond(
      "carry_staff_requests_or_tpdts",
      "visibleSequenceNumber",
      !oldVisibleSequenceNumber
    );
    localStorage.setItem(
      this.getVisibleSequenceNumberKey(),
      String(!oldVisibleSequenceNumber)
    );
  }

  changeTable(tableName: "request" | "thirdPartyDeliveryTask") {
    this.setState({ activeTable: tableName });
  }

  scrollToTop() {
    if (this.scrollableElementRef.current) {
      this.scrollableElementRef.current.scrollTop = 0;
    }
  }

  private getVisibleSequenceNumberKey() {
    return (
      MapAttributes.LOCAL_STRAGE_KEY_CARRYSTAFFOVERLOOKMAP_VISIBLE_SEQUENCE +
      gon.current_user_id
    );
  }

  private renderSearchInput(
    requestsCounts: number,
    tpdtCounts: number,
    sequenceCounts: number
  ) {
    const { activeTable } = this.state;
    const counts = activeTable == "request" ? requestsCounts : tpdtCounts;

    // このページにいるということは何かしらCASが選択されているはず、という前提
    const selectedCarryStaff =
      searchConditionsStore.carryStaffRequestsConditions.carryStaff!;
    const visibleSequenceNumber =
      searchConditionsStore.carryStaffRequestsConditions.visibleSequenceNumber;

    // 選択されているものの、地図外にいるかどうかのフラグ
    // (選択された時には中心にいるはずだが、選択後に地図が移動されてそのCASが地図外にいってしまった場合)
    const isOutOfBounds =
      typeof selectedCarryStaff.id == "number" &&
      !carryStaffLocationsStore.items.some(
        (loc) => loc.carryStaffId == selectedCarryStaff.id
      );
    // concatすることを前提に配列
    const outOfBoundsCas = (
      isOutOfBounds
        ? [
            {
              id: selectedCarryStaff.id,
              name: selectedCarryStaff.name,
            },
          ]
        : []
    ) as { id: number; name: string }[];

    return (
      <div style={styles.searchWrapper}>
        <div style={styles.searchContentsWrapper}>
          <div
            onClick={() => this.backToDriverList()}
            style={styles.iconWrapper}
          >
            <div style={styles.icon}>
              <i className="fas fa-angle-left"> 戻る</i>
            </div>
          </div>

          <div style={styles.inputWrapper}>
            <div
              className="d-flex align-items-center mb-2"
              style={{ width: "90%" }}
            >
              <label style={styles.searchLabel} htmlFor="carryStaffSelect">
                配達スタッフ
              </label>
              <select
                id="carryStaffSelect"
                className="form-control form-control-sm ml-2"
                defaultValue={`${selectedCarryStaff.id}`}
                onChange={(e) => this.handleSelectCarryStaff(e.target.value)}
              >
                <option key="unassigned" value="unassigned">
                  (未アサイン)
                </option>
                {carryStaffLocationsStore.items
                  .map((loc) => ({
                    id: loc.carryStaffId,
                    name: loc.name,
                  }))
                  .concat(outOfBoundsCas)
                  .map((cas) => {
                    return (
                      <option key={cas.id} value={cas.id}>
                        {cas.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="d-flex align-items-center" style={{ width: "90%" }}>
              <label style={styles.searchLabel} htmlFor="statusSelect">
                ステータス
              </label>
              <select
                id="statusSelect"
                className="form-control form-control-sm ml-2"
                onChange={(e) => this.handleSelectStatus(e.target.value)}
              >
                <option key="undelivered" value="undelivered">
                  処理中
                </option>
                {Object.keys(requestConst.STATUSES).map(
                  (key: keyof typeof requestConst.STATUSES) => {
                    const status = requestConst.STATUSES[key];
                    return (
                      <option key={status} value={status}>
                        {I18n.t(`enums.request.status.${key}`)}
                      </option>
                    );
                  }
                )}
                <option key="all" value="all">
                  全て
                </option>
              </select>
            </div>
            <div className="row mt-3">
              <div className="col custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="orderSequenceToggleSwitch"
                  checked={sequenceCounts == 0 ? false : visibleSequenceNumber}
                  disabled={sequenceCounts == 0}
                  onChange={() =>
                    this.handleChangeVisibleSequenceNumber(
                      visibleSequenceNumber
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="orderSequenceToggleSwitch"
                >
                  依頼順表示
                </label>
              </div>
              <div className="col text-right">件数：{counts}件</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderTableTabs() {
    const { activeTable, selectableTableLabel } = this.state;
    switch (selectableTableLabel) {
      case "request":
        return <th>依頼詳細</th>;
      case "thirdPartyDeliveryTask":
        return <th>外部配達タスク詳細</th>;
      case "both":
        return (
          <th>
            <div style={{ display: "flex" }}>
              <div
                style={
                  activeTable === "request"
                    ? styles.activeLabel
                    : styles.inActiveLabel
                }
                onClick={() => {
                  this.changeTable("request");
                }}
              >
                依頼詳細
              </div>
              <div className="mx-2" style={{ fontWeight: "normal" }}>
                {" "}
                /{" "}
              </div>
              <div
                style={
                  activeTable === "thirdPartyDeliveryTask"
                    ? styles.activeLabel
                    : styles.inActiveLabel
                }
                onClick={() => {
                  this.changeTable("thirdPartyDeliveryTask");
                }}
              >
                外部配達タスク詳細
              </div>
            </div>
          </th>
        );
      case "nothing":
        return <th>対象の依頼が存在しません</th>;
    }
  }

  private renderTable(
    requests: RequestModel[],
    thirdPartyDeliveryTasks: ThirdPartyDeliveryTaskModel[]
  ) {
    const { activeTable } = this.state;
    const loading = searchConditionsStore.showLoadingIcon && isLoading();
    const selectedRequest = requestsStore.getSelectedItem();
    const selectedTpdt = thirdPartyDeliveryTasksStore.getSelectedItem();
    return (
      <div style={styles.tableWrapper} ref={this.scrollableElementRef}>
        {loading ? (
          <div className="fa-2x mt-5" style={styles.loading}>
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        ) : (
          <table className="table" style={{ borderCollapse: "separate" }}>
            <thead className="bg-white sticky-top">
              <tr style={styles.tableLabel}>{this.renderTableTabs()}</tr>
            </thead>
            {activeTable === "request" && (
              <RequestTableBody
                selectedItem={selectedRequest}
                items={requests}
                onSelect={(requestId) => {
                  this.scrollToTop();
                  selectItem("request", requestId, false);
                }}
              />
            )}
            {activeTable === "thirdPartyDeliveryTask" && (
              <ThirdPartyDeliveryTaskTableBody
                selectedItem={selectedTpdt}
                items={thirdPartyDeliveryTasks}
                onSelect={(tpdtId) => {
                  this.scrollToTop();
                  selectItem("thirdPartyDeliveryTask", tpdtId, false);
                }}
              />
            )}
          </table>
        )}
      </div>
    );
  }

  render() {
    const targetRequests = filterRequests();
    const targetThirdPartyDeliveryTasks = filterThirdPartyDeliveryTasks();
    const sequenceCounts = targetRequests.filter(
      (req) => (req.carryStaffRequestSequences?.length ?? 0) > 0
    ).length;
    return (
      <div style={{ height: "100%" }}>
        {this.renderSearchInput(
          targetRequests.length,
          targetThirdPartyDeliveryTasks.length,
          sequenceCounts
        )}
        {this.renderTable(targetRequests, targetThirdPartyDeliveryTasks)}
      </div>
    );
  }
}

const styles = {
  searchWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: "0.8rem",
    margin: "10px",
  } as CSSProperties,
  searchContentsWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "20%",
  } as CSSProperties,
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    marginLeft: 20,
  } as CSSProperties,
  searchLabel: {
    width: "40%",
    whiteSpace: "nowrap",
    margin: 0,
  } as CSSProperties,
  tableWrapper: {
    width: "100%",
    height: "75%",
    overflowY: "auto",
  } as CSSProperties,
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  } as CSSProperties,
  tableLabel: {
    fontSize: "0.9rem",
  } as CSSProperties,
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "15%",
  } as CSSProperties,
  icon: {
    cursor: "pointer",
    display: "flex",
    height: "24px",
    margin: "0 auto",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "flex-start",
  } as CSSProperties,
  activeLabel: {
    fontWeight: "bold",
  } as CSSProperties,
  inActiveLabel: {
    fontWeight: "normal",
    textDecoration: "underline",
  } as CSSProperties,
};

export default observer(CarryStaffDetail);
