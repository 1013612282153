import React from 'react'

interface Props {
  text: string
  size?: number
  color?: string
}

export class MarkerText extends React.Component<Props> {

  render() {
    const { text, size, color } = this.props;
    // sizeをそのまま使用すると文字が大きすぎるので調整
    const fontSize = size ? size - 5 : undefined;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: size,
          height: size,
          color: color,
          fontSize: fontSize,
          fontWeight: "bold"
        }}
      >
        {text}
      </div>
    )
  }
}
