import { MinimumLocation } from "../interfaces";

export abstract class StoreClass<T extends MinimumLocation> {
  locations: T[] = [];
  selectedLocation: T | undefined = undefined;
  setSelectedLocation: (item: T | undefined) => void;
  loadLocations: (
    carryStaffId: number,
    fromDate: string,
    toDate: string
  ) => Promise<void>;
}
