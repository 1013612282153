export const thirdPartyDeliveryTaskConst = {
  WORK_TYPES: {
    anycarry: 0,
    demaecan: 10,
    amazon: 20,
    wolt: 30,
    rest: 100,
  },
  STATUSES: {
    wait_store: -1,
    uncheck: 0,
    checked: 1,
    pickup: 2,
    delivered: 3,
    failed: 90,
    cancel: 99,
    store_cancel: 100,
  },
  CUSTOM_STATUSES: {
    in_progress: [-1, 0, 1, 2],
  },
} as const;
