import {useEffect, useState} from "react";
import {MapApi} from "../index";
import {Coords} from "google-map-react";
import React from "react";
// @ts-ignore
import {parse} from "wkt"

export interface PolygonStyle {
  strokeColor: string,
  strokeOpacity: number,
  strokeWeight: number,
  fillColor: string,
  fillOpacity: number,
}

export const defaultStyle = {
  strokeColor: "#362ec4",
  strokeOpacity: 0.5,
  strokeWeight: 1,
  fillColor: "#362ec4",
  fillOpacity: 0.25,
}

export interface PolygonProps {
  mapApi: MapApi
  style?: PolygonStyle,
  hoverStyle?: PolygonStyle
  activeStyle?: PolygonStyle
  active?: boolean
  zIndex?: number,
  // イベントリスナーを更新したい場合はeventKeyを更新する
  eventKey?: string,
  onHover?: (e: any) => void
  onHoverOut?: (e: any) => void
  onMousemove?: (e: any) => void,
  onClick?: (e?: any) => void,
  options?: google.maps.PolygonOptions,
}

interface Props extends PolygonProps {
  paths: Coords[][] | Coords[],
}

export function Polygon(props: Props) {
  const {
    mapApi,
    paths,
    active = false,
    zIndex = 1,
    style = defaultStyle,
    hoverStyle = style,
    activeStyle = style,
    eventKey,
    options,
    onClick = () => {
    },
    onHover = () => {
    },
    onHoverOut = () => {
    },
    onMousemove = () => {
    }
  } = props
  const [polygon, setPolygon] = useState<google.maps.Polygon | undefined>(undefined)
  const [clickEvent, setClickEvent] = useState<google.maps.MapsEventListener>()
  const [hoverEvent, setHoverEvent] = useState<google.maps.MapsEventListener>()
  const [hoverOutEvent, setHoverOutEvent] = useState<google.maps.MapsEventListener>()
  const [mouseMoveEvent, setMouseMoveEvent] = useState<google.maps.MapsEventListener>()

  const handleOnClick = (e: google.maps.PolyMouseEvent) => {
    onClick(e)
  }

  const handleMouseOver = (e: google.maps.PolyMouseEvent) => {
    onHover(e)
    polygon?.setOptions({
      ...options,
      ...active ? {...activeStyle, ...options} : {...hoverStyle, ...options},

    })
  }
  const handleMouseOut = (e: google.maps.PolyMouseEvent) => {
    onHoverOut(e)
    polygon?.setOptions({
      ...options,
      ...active ? {...activeStyle} : {...style}
    })
  }

  const initialize = () => {
    // 既存のポリゴンをリセット
    if (polygon) {
      polygon.setMap(null)
    }

    // ポリゴンを生成
    const poly: google.maps.Polygon = new mapApi.maps.Polygon({
      paths,
      zIndex
    })

    // スタイルを設定
    poly.setOptions({
      ...options,
      ...active ? {...activeStyle} : {...style}
    })


    // ポリゴンを描画
    poly.setMap(mapApi.map)

    // イベントリスナーを追加
    setEventListener(poly)

    // ポリゴンの状態をStateに保管
    setPolygon(poly)

    return poly
  }

  /**
   * Polygonにイベントリスナーをセットする
   */
  const setEventListener = (poly: google.maps.Polygon) => {
    // イベントリスナーを追加
    if (clickEvent) clickEvent.remove()
    setClickEvent(
      poly.addListener("click", (e: google.maps.PolyMouseEvent) => {
        handleOnClick(e)
      })
    )

    if (hoverEvent) hoverEvent.remove()
    setHoverEvent(
      poly.addListener("mouseover", (e: google.maps.PolyMouseEvent) => {
        poly?.setOptions(
          {
            ...options,
            ...active ? {...activeStyle} : {...hoverStyle}
          }
        )
        handleMouseOver(e)
      })
    )

    if (hoverOutEvent) hoverOutEvent.remove()
    setHoverOutEvent(
      poly.addListener("mouseout", (e: google.maps.PolyMouseEvent) => {
        poly?.setOptions(
          {
            ...options,
            ...active ? {...activeStyle} : {...style}
          }
        )
        handleMouseOut(e)
      })
    )
    if (mouseMoveEvent) mouseMoveEvent.remove()
    setMouseMoveEvent(
      poly.addListener("mousemove", (e: google.maps.PolyMouseEvent) => {
        poly?.setOptions(
          {
            ...options,
            ...active ? {...activeStyle} : {...style}
          }
        )
        handleMouseOut(e)
      })
    )
  }


  useEffect(() => {
    const polygon = initialize()
    return () => {
      polygon.setMap(null)
    }
  }, [])

  // パスの位置が変更された場合
  useEffect(() => {
    if (!polygon) return
    // パスを新規登録
    polygon.setPaths(paths)
    // イベントリスナーを更新
    setEventListener(polygon)
    // 再描画
    polygon.setMap(mapApi.map)
    return () => {
      polygon.setMap(null)
    }
  }, [paths])


  // active変更時に実行
  useEffect(() => {
    if (!polygon) return

    polygon?.setOptions(
      active ? {...activeStyle} : {...style}
    )
    polygon.setMap(mapApi.map)
    setEventListener(polygon)
  }, [active])

  // eventKeyが更新されたらイベントリスナーを更新する
  useEffect(() => {
    if (!polygon) return
    setEventListener(polygon)
  }, [eventKey])

  return (
    <></>
  )
}
