import { MapTypeStyle } from 'google-map-react';


/**
 * 参考 : https://mapstyle.withgoogle.com/
 */

export const mapStyles: MapTypeStyle[] = [
  {
    "stylers": [
      {
        "saturation": -25
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];
