import { format } from "date-fns";
import React from "react";
import { Alert, Button, Card, Table } from "react-bootstrap";
import Request from "../../interfaces/Request";
import ThirdPartyDeliveryTask from "../../interfaces/ThirdPartyDeliveryTask";
import { getDeliveryTimeLabel } from "../../utils/DeliveryTimeLabelUtils";
import { formatIso8601Time } from "../../utils/TimeUtils";

interface Props {
  deliveryRequest: Request | ThirdPartyDeliveryTask;
  onCloseClick?: (event: any) => void;
}

export class SenderCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handlerClickMoveDetail = this.handlerClickMoveDetail.bind(this);
  }

  private isRequest(req: Request | ThirdPartyDeliveryTask): req is Request {
    return "isReserved" in req;
  }

  private buttonText(isRequest: boolean) {
    if (isRequest) {
      return `依頼No.${this.props.deliveryRequest.id}詳細へ`;
    }
    return `外部依頼No.${this.props.deliveryRequest.id}詳細へ`;
  }

  private handlerClickMoveDetail(isRequest: boolean, event: any) {
    const deliveryRequest = this.props.deliveryRequest;
    let requestEditUrl: string;
    if (isRequest) {
      requestEditUrl = `/requests/${deliveryRequest.id}/edit`;
    } else {
      requestEditUrl = `/third_party_delivery_tasks/${deliveryRequest.id}`;
    }

    open(requestEditUrl, "_blank");
    event.preventDefault();
    event.stopPropagation();
  }

  private renderAlert(isReserved: boolean) {
    if (isReserved) {
      return (
        <Alert variant={"warning"} className="mx-0 pa-1">
          <i className="fas fa-clock" style={{ marginRight: 4 }} />
          この依頼は予約です
        </Alert>
      );
    }
    return null;
  }

  render() {
    const { deliveryRequest, onCloseClick } = this.props;
    const isRequest = this.isRequest(deliveryRequest);
    const alert = this.renderAlert(
      isRequest && (deliveryRequest.isReserved ?? false)
    );

    const deliveryTimeLabel = getDeliveryTimeLabel(deliveryRequest);
    const estimatedPickupTime =
      "estimatedPickupTime" in deliveryRequest
        ? deliveryRequest.estimatedPickupTime
        : null;
    const pickupStartTime =
      "pickupStartTime" in deliveryRequest
        ? deliveryRequest.pickupStartTime
        : null;
    const pickupEndTime =
      "pickupEndTime" in deliveryRequest ? deliveryRequest.pickupEndTime : null;

    return (
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div style={{ fontSize: "0.75rem" }}>店舗様</div>
            <div onClick={onCloseClick}>閉じる</div>
          </div>
        </Card.Header>
        <Card.Body style={{ fontSize: "0.85rem" }} className="mx-0 mb-0 pb-0">
          <Card.Title style={{ fontSize: "1rem" }}>
            {isRequest ? deliveryRequest.sender.name : "-"}
          </Card.Title>
          <Table>
            <tbody>
              <tr>
                <th style={{ whiteSpace: "nowrap" }}>住所</th>
                <td>{isRequest ? deliveryRequest.sender.address : "-"}</td>
              </tr>
              {deliveryRequest.readyTimeAt && (
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>準備完了</th>
                  <td>{format(deliveryRequest.readyTimeAt, "MM/dd HH:mm")}</td>
                </tr>
              )}
              {pickupStartTime && pickupEndTime && (
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>集荷時間</th>
                  <td>
                    {format(pickupStartTime, "HH:mm")}〜
                    {format(pickupEndTime, "HH:mm")}
                  </td>
                </tr>
              )}
              {deliveryRequest.deliveryTimeAt && (
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>配達希望</th>
                  <td>{deliveryTimeLabel}</td>
                </tr>
              )}
              {estimatedPickupTime && (
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>到着予想時間</th>
                  <td>{formatIso8601Time(estimatedPickupTime)}</td>
                </tr>
              )}
            </tbody>
          </Table>
          {alert}
        </Card.Body>
        <Card.Footer>
          <Button
            size="sm"
            onClick={(e: React.MouseEvent) =>
              this.handlerClickMoveDetail(isRequest, e)
            }
          >
            {this.buttonText(isRequest)}
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}
