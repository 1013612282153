import React from "react";
import { Card, Table } from "react-bootstrap";

import Facility from "../interfaces/Facility";

interface Props {
  facility: Facility;
  onCloseClick?: (event: any) => void
}

export class FacilityInfoCard extends React.Component<Props> {
  render() {
    const { facility, onCloseClick } = this.props;

    return (
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <div style={{fontSize: "0.75rem"}}>
              輸送経路（拠点）
            </div>
            <div onClick={onCloseClick}>閉じる</div>
          </div>
        </Card.Header>
        <Card.Body style={{fontSize: "0.85rem"}} className="mx-0 mb-0 pb-0">
          <Card.Title style={{fontSize: "1rem"}}>
            { facility.name }
          </Card.Title>
          <Table>
            <tbody>
              <tr>
                <th style={{whiteSpace: "nowrap"}}>
                  住所
                </th>
                <td>
                  {`${facility.state}${facility.city}${facility.address1}${facility.address2}`}
                </td>
              </tr>
            </tbody>
        </Table>
        </Card.Body>
      </Card>
    )
  }
}