import React from "react";
import _ from "lodash";

interface Props {
  mapApi: any,
  map: any,
  lat: number,
  lng: number,
  dragend: (lat: number, lng: number) => void
}

interface State {
  lat: number,
  lng: number
}

class OverlookTempMarker extends React.Component<Props, State> {
  // mapapiを使って描画する場合、reactのlife cycleとタイミングが合わない
  // そのため、state相当のlineを自分で管理する
  marker: any = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      lat: this.props.lat,
      lng: this.props.lng,
    }
  }

  componentWillUpdate(newProps) {
    // markerは特殊処理なので、willupdateではnullにしない
    // if (this.marker) {
    //   this.marker.setMap(null)
    // }
  }

  componentWillUnmount() {
    // コンポーネントが消されたとき、ラインが地図上からも表示されないようにする
    if (this.marker) {
      this.marker.setMap(null)
    }
  }

  render() {
    if (_.isEmpty(this.marker) ||
      this.props.lat != this.state.lat ||
      this.props.lng != this.state.lng
    ) {
      if (this.marker) {
        this.marker.setMap(null)
      }
      this.renderMarker();
    }
    return null
  }

  private renderMarker() {
    const marker = new this.props.mapApi.Marker(
      {
        draggable: true,
        position: {
          lat: this.props.lat,
          lng: this.props.lng,
        },
        map: this.props.map
      }
    );

    marker.addListener("dragend", () => {
      this.props.dragend(marker.getPosition().lat(), marker.getPosition().lng());
    })

    this.marker = marker;
  }
}

export default OverlookTempMarker;
