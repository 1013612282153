import "jquery-slimscroll";
import { observer } from "mobx-react";
import React from "react";
import type { MinimumLocation } from "../interfaces";
import { StoreClass } from "../stores/StoreInterface";

interface BaseProps<T extends MinimumLocation> {
  locations: T[];
  store: StoreClass<T>;
}

function LocationList<T extends MinimumLocation>(props: BaseProps<T>) {
  const store = props.store;
  const locations = store.locations;

  // Locationにはactivityとbattery_levelがあるので
  const isLocation = locations.some((loc) => "activity" in loc);

  return (
    <div
      style={{
        height: "calc(100vh - 4.375rem - 10rem)",
        overflow: "auto",
      }}
    >
      <table className="border" style={{ fontSize: 14, width: "100%" }}>
        <thead>
          <tr>
            <th className="border font-weight-bold">時刻</th>
            <th className="border font-weight-bold">緯度</th>
            <th className="border font-weight-bold">経度</th>
            {isLocation && (
              <>
                <th className="border font-weight-bold">バッテリー</th>
                <th className="border font-weight-bold">状態</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {locations.map((loc) => {
            return (
              <tr
                key={loc.id}
                style={{
                  backgroundColor:
                    loc.id == store.selectedLocation?.id ? "#f8f9fa" : "",
                }}
                onClick={() => store.setSelectedLocation(loc)}
              >
                <td className="border font-weight-bold">
                  {loc.sent_at ? loc.sent_at : "-"}
                </td>
                <td className="border font-weight-normal ml-20">{loc.lat}</td>
                <td className="border font-weight-normal ml-20">{loc.lng}</td>
                {isLocation && (
                  <>
                    <td className="border font-weight-normal ml-20">
                      {loc.battery_level == null || loc.battery_level < 0
                        ? "-"
                        : loc.battery_level}
                    </td>
                    <td className="border font-weight-normal ml-20">
                      {loc.activity}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default observer(LocationList);
