import React from "react";
import {SpotReportMapMarkerPin} from "./SpotReportMapMarkerPin";
import Colors from "../constants/BootstrapColors";

interface Props {
  lat: number,
  lng: number,
  zoomLevel?: number,
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean,
  icon: string,
  index: number
  badgeText: string
}

interface State {
  showInfoWindow: boolean
}

export default class SpotReportMapSmallMarker extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false
    };
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow;
    const pinSize = 28

    return (
      <SpotReportMapMarkerPin
        icon={this.props.icon}
        shape="drop"
        size={showInfoWindow ? pinSize * 1.5: pinSize}
        pinColor={"#f44336"}
        iconColor={Colors.INFO_COLOR}
        badgeText={this.props.badgeText}
        {...this.getThirdBadgeProps()}
      />
    )
  }

  private getThirdBadgeProps() {
    return {
      thirdBadgeStyle: {
        color: "blue"
      },
      thirdBadgeText: this.props.index
    }
  }
}
