import React, {useEffect, useMemo, useState} from "react";
import type {PolygonProps, PolygonStyle} from "./Polygon";
import {defaultStyle} from "./Polygon";

import WktMultiPolygon from "../../../WktMultiPolygon";

interface Props extends PolygonProps {
    wkt: string,
}

/**
 * WktMultiPolygonをラップしただけのコンポーネント
 * MapAreaPicker内の書き方を揃えたかったので作成
 *
 * @param props
 * @constructor
 */
export function PolygonByWkt(props: Props) {
    const {wkt, mapApi, style = defaultStyle, hoverStyle = style, onClick, eventKey, onHoverOut, onHover, onMousemove} = props
    const [hover, setHover] = useState(false)
    const [currentStyle, setCurrentStyle] = useState<PolygonStyle>(defaultStyle)
    useEffect(() => {
        setCurrentStyle(hover ? hoverStyle: style)
    }, [hover])

    const memo = useMemo(() => {
        return (
            <WktMultiPolygon
                mapApi={mapApi.maps}
                wktText={wkt}
                map={mapApi.map}
                strokeColor={currentStyle.strokeColor}
                strokeOpacity={currentStyle?.strokeOpacity}
                strokeWeight={currentStyle?.strokeWeight}
                fillColor={currentStyle?.fillColor}
                fillOpacity={currentStyle?.fillOpacity}
                onClickPolygon={onClick}
                onMouseover={(e) => {
                    setHover(true)
                    if(onHover) onHover(e)
                }}
                onMouseout={(e) => {
                    setHover(false)
                    if(onHoverOut) onHoverOut(e)
                }}
                onMousemove={(e) => {
                    if(onMousemove) onMousemove(e)
                }}
                areaKey={eventKey}
            />
        )
    }, [hover, currentStyle, wkt, eventKey])
    return memo
}
