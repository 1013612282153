import React from "react";

interface Props {
    children?: React.ReactNode;
    color?: string
    iClass?: string | null
    position?: "top-right" | "top-left" | "middle-left" | "number"
}
export class PinBadge extends React.Component<Props> {
    render() {
      
        const {color = "#f78733", iClass, children} = this.props
        return (
            <div
                style={{
                    background: color,
                    color: "#fff",
                    padding: "0 4px",
                    borderRadius: "2em",
                    border: "2px solid #fff",
                    position: "absolute",
                    minHeight: "2em",
                    minWidth: "2em",
                    lineHeight: "1em",
                    fontWeight: "bold",
                    zIndex: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    whiteSpace: "nowrap",
                    ...this.getPositionStyle()
                }}
            >
                {iClass &&
                    <i className={iClass}/>
                }
                {children &&
                <span>
                    {children}
                </span>
                }

            </div>
        )
    }

    private getPositionStyle = () => {
        const { position = "top-right" } = this.props
        switch (position) {
            case "top-right":
                return { top: "-1.5em", right: "-0.9em" }
            case "top-left":
                return { top: "-1.5em", left: "-0.9em" }
            case "middle-left":
                return { top: "0.5em", left: "-0.9em" }
            case "number":
                return { top: "-0.5em", right: "-1.5em" }
        }
    }
}
