import React from "react";
import Modal from "react-modal";
import type { SelectableApiType } from "../../../utils/routes-apis/api-code-utils";
import { getApiTypeName } from "../../../utils/routes-apis/api-code-utils";
import type { RawRoutesApiParamSetting } from "../interfaces";

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  routesApiParamSettings: RawRoutesApiParamSetting[];
  selectedApiType: SelectableApiType;
  onSubmit: () => Promise<void>;
  onCloseModal: () => void;
}

export default function CalculateEatModal(props: Props) {
  const {
    isOpen,
    isLoading,
    routesApiParamSettings,
    selectedApiType,
    onSubmit,
    onCloseModal,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onCloseModal()}
      style={modalStyles}
    >
      <div className="h5 mb-4 d-flex align-items-center">
        到着予想時刻計算
        {isLoading && (
          <div className="d-flex align-items-center ml-3">
            <div className="mr-2">
              <span style={{ fontSize: 16 }}>計算中...</span>
            </div>
            <div
              className="spinner-border spinner-border-sm ml-3"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div>
        利用API:
        <span className="ml-2 font-weight-bold">
          {getApiTypeName(selectedApiType, routesApiParamSettings)}
        </span>
      </div>
      <div>実行しますか？</div>
      <div className="d-flex justify-content-end my-2">
        <button
          className="btn btn-secondary mr-2"
          onClick={() => onCloseModal()}
          disabled={isLoading}
        >
          キャンセル
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={isLoading}
          onClick={() => {
            onSubmit();
          }}
        >
          はい
        </button>
      </div>
    </Modal>
  );
}

const modalStyles: Modal.Styles = {
  content: {
    width: 600,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 26,
  },
  overlay: {
    zIndex: 1050,
  },
};
