import React from 'react'
export type SVGIcon = "bicycle" | "bike" | "car" | "walk" | "person" | "restaurant" | "shop" | "building"
interface Props  {
    size?: number
    color?: string
    icon: string
}
export class MarkerIcon extends React.Component<Props> {

    render() {
        const {size, color, icon} = this.props
        return (
            (() => {
                switch (icon) {
                    case "bicycle":
                        return <Bicycle />
                    case "parking":
                        return <Parking />
                    case "enter":
                        return <Enter />
                    case "box":
                        return <Box />
                    case "markerPin":
                        return <MakerPin />
                    default:
                        break;
                }
            })()
        )

    }
}

class Bicycle extends React.Component{
    render() {
        return (
            <i className={"fas fa-bicycle"} style={{fontSize: '16px', color: 'white'}}/>
        )
    }

}

class Parking extends React.Component {
    render() {
        return (
            <i className={"fas fa-parking"} style={{fontSize: '16px', color: 'white'}}/>
        )
    }
}

class Enter extends React.Component {
    render() {
        return (   
            <i className={"fas fa-door-open"} style={{fontSize: '16px', color: 'white'}}/>
        )
    }
}



class Box extends React.Component {
    render() {
        return (
            <i className={"fas fa-box-open"} style={{fontSize: '16px', color: 'white'}}/>
        )
    }
}

class MakerPin extends React.Component {
    render() {
        return (
            <i className={"fas fa-thumbtack"} style={{fontSize: '16px', color: 'white'}}/>
        )
    }
}