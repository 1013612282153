import React from "react";
// @ts-ignore
import Wkt from 'wicket';
import _ from "lodash";
import {CurrentLocationWithCarryStaffModel} from "../models/CurrentLocationWithCarryStaffModel";

interface Props {
  mapApi: any,
  map: any,
  wktText: string,
  staffLocations: CurrentLocationWithCarryStaffModel[] | undefined
}

interface PolygonGroup {
  // LatLng[]が一つしか無いときは穴なし。 pathが配列のときは穴あきで１つ目が外側。２つ目が内側
  path: google.maps.LatLng[] | [google.maps.LatLng[], google.maps.LatLng[]]
}

class ServiceAreaPolygon extends React.Component<Props> {
  wkt: any = null;
  polygonGroups: PolygonGroup[] = [];
  polygons: any[] = []

  constructor(props: Props) {
    super(props);
    this.wkt = new Wkt.Wkt();
    try {
      this.wkt.read(props.wktText);
      if (this.wkt.type === "polygon") {
        this.makePathsForPolygon();
      } else {
        // MultiPolygonの場合は、type === "multipolygon"
        this.makePathsForMulitiPolygon();
      }
    } catch (error) {
      console.log(error)
    }
  }

  componentWillUpdate() {
    // propsが更新されば場合は既存のオブジェクトをmap上から削除
    _.each(this.polygons, (polygon) => {
      polygon.setMap(null);
    })
  }

  componentWillUnmount() {
    _.each(this.polygons, (polygon) => {
      polygon.setMap(null);
    })
  }

  componentDidMount() {
  }

  render() {
    // FIXME
    // renderのタイミングじゃないと表示されない。
    _.each(this.polygonGroups, (polygonGroup) => {
      let existFlg = true;
      if (this.props.staffLocations) {
        const dummyPolygon = new this.props.mapApi.Polygon({paths: polygonGroup.path});
        existFlg = !!this.props.staffLocations.find(location => this.props.mapApi.geometry.poly.containsLocation(
          new this.props.mapApi.LatLng(location.lat, location.lng),
          dummyPolygon)
        );
      }

      const color = existFlg ? '#79baff' : '#FF7711FF';
      const polygon = new this.props.mapApi.Polygon({
          paths: polygonGroup.path,
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.20,
        }
      );
      polygon.setMap(this.props.map);
      this.polygons.push(polygon)
    })

    return null
  }

  private makePathsForMulitiPolygon() {
    const components = this.wkt.components;
    this.polygonGroups = [];
    _.each(components, (component) => {
      
      const outer = component[0];
      const outerPath: any[] = [];
      for (let i = 0; i < outer.length; i++) {
        const item = outer[i];
        outerPath.push(new this.props.mapApi.LatLng(item.y, item.x));
      }
      
      if (component.length == 1) {
        this.polygonGroups.push({ path: outerPath })
      } else {
        const hole = component[1];
        const innerPath: any[] = [];
        for (let i = 0; i < hole.length; i++) {
          const item = hole[i];
          innerPath.push(new this.props.mapApi.LatLng(item.y, item.x));
        }
        this.polygonGroups.push({ path: [outerPath, innerPath] })
      }
    })
  }

  private makePathsForPolygon() {
    const components = this.wkt.components;
    this.polygonGroups = [];

    let outerPath: any[] = [];
    let innerPath: any[] = [];
    _.each(components, (component, index: number) => {
      if (index === 0) {
        // 一つ目は外側
        const outer = component;
        for (let i = 0; i < outer.length; i++) {
          const item = outer[i];
          outerPath.push(new this.props.mapApi.LatLng(item.y, item.x));
        }
      } else {
        // 二つ目以降は内側
        const hole = component;
        for (let i = 0; i < hole.length; i++) {
          const item = hole[i];
          innerPath.push(new this.props.mapApi.LatLng(item.y, item.x));
        }
      }
    })
    if (components.length === 1) {
      this.polygonGroups.push({ path: outerPath })
    } else {
      this.polygonGroups.push({ path: [outerPath, innerPath] })
    }
  }
}

export default ServiceAreaPolygon;
