
import React from "react";


interface PageItem {
  label: string
  type: 'active' | 'non_active' | 'non_page'
}

interface Props {
  displayPageCount: number
  currentPage: number
  lastPage: number
  onChangePage: (e: React.MouseEvent, targetPage: number) => void
}

export default class Pagination extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const displayPageCount = this.props.displayPageCount;
    let pages: PageItem[] = [];
    let prefixPages: PageItem[] = [...Array(Math.min(this.props.currentPage - 1, displayPageCount))].map(
      (_, i) => i + 1 + Math.max((this.props.currentPage - (displayPageCount + 1)), 0)
    ).map(p => ({ label: `${p}`, type: 'non_active' }));
    if (this.props.currentPage > (displayPageCount + 1)) {
      prefixPages.unshift({ label: '...', type: 'non_page' });
    }
    pages = pages.concat(prefixPages);

    pages.push({ label: `${this.props.currentPage}`, type: 'active' });

    const suffixPages: PageItem[] = [...Array(Math.min(Math.max(this.props.lastPage - this.props.currentPage, 0), displayPageCount))].map(
      (_, i) => i + 1 + this.props.currentPage
    ).map(p => ({ label: `${p}`, type: 'non_active' }));
    if ((this.props.lastPage - this.props.currentPage) > displayPageCount) {
      suffixPages.push({ label: '...', type: 'non_page' });
    }
    pages = pages.concat(suffixPages);

    return (
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-end">
          <li className={this.props.currentPage > 1 ? 'page-item' : 'page-item disabled'}>
            <a
              className="page-link"
              onClick={(e) => this.props.onChangePage(e, this.props.currentPage - 1)}
            >
              <i className="fas fa-chevron-left" />
            </a>
          </li>
          {
            pages[0].type == 'non_page' ?
              (
                <li className='page-item disabled border-top border-bottom text-center' style={{ width: 34 }}>
                  {pages[0].label}
                </li>
              ) :
              null
          }
          {
            pages.filter(p => p.type != 'non_page').map(p => {
              return (
                <li
                  key={p.label}
                  className={p.type == 'active' ? 'page-item active' : 'page-item'}
                >
                  <a
                    className="page-link"
                    onClick={(e) => this.props.onChangePage(e, +p.label)}
                  >
                    {p.label}
                  </a>
                </li>
              )
            })
          }
          {
            pages[pages.length - 1].type == 'non_page' ?
              (
                <li className='page-item disabled border-top border-bottom text-center' style={{ width: 34 }}>
                  {pages[pages.length - 1].label}
                </li>
              ) :
              null
          }
          <li className={this.props.currentPage < this.props.lastPage ? 'page-item' : 'page-item disabled'}>
            <a
              className="page-link"
              onClick={(e) => this.props.onChangePage(e, this.props.currentPage + 1)}
            >
              <i className="fas fa-chevron-right" />
            </a>
          </li>
        </ul>
      </nav>
    )
  }
}
