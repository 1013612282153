import { format, startOfToday } from "date-fns";
import { action, observable } from "mobx";
import { TargetTerm } from "../interfaces";

export class SearchConditionsStore {
  @observable
  targetTerm: TargetTerm = {
    type: "all",
    date: format(new Date(), "yyyy-MM-dd"),
    isPast: false,
  };

  @observable
  isReassignMode = false;

  @observable
  isActualSequenceDisplayed = false;

  @action
  public setIsReassignMode(active: boolean) {
    this.isReassignMode = active;
  }

  @action
  public setIsActualSequenceDisplayed(active: boolean) {
    this.isActualSequenceDisplayed = active;
  }

  @action
  public setTargetTermDate(newDate: Date) {
    // 対象日が実行日以降から過去日付に変わった場合isReassignModeを無効に
    // 過去日付から実行日以降の日付に変わった場合isActualSequenceDisplayedを無効にしたい
    const _startOfToday = startOfToday();
    const targetTermDate = this.getTargetTermDate();
    if (targetTermDate >= _startOfToday && newDate < _startOfToday) {
      this.isReassignMode = false;
    } else if (targetTermDate < _startOfToday && newDate >= _startOfToday) {
      this.isActualSequenceDisplayed = false;
    }

    this.targetTerm = {
      type: this.targetTerm.type,
      date: format(newDate, "yyyy-MM-dd"),
      isPast: newDate < _startOfToday,
    };
  }

  @action
  public setTargetTermType(type: "specified" | "all") {
    this.targetTerm = {
      type: type,
      date: this.targetTerm.date,
      isPast: this.targetTerm.isPast,
    };
  }

  public getTargetTermDate() {
    return new Date(this.targetTerm.date);
  }
}
