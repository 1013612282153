import { action, observable } from "mobx";
import OfficesResponse from "../interfaces/OfficesResponse";
import { OfficeModel } from "../models/OfficeModel";

export class VisibleOfficeModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  model: OfficeModel;

  visible: boolean = true;

  constructor(response: OfficesResponse) {
    this.id = response.id;
    this.key = response.id;

    this.model = new OfficeModel(response);
  }

  @action
  public toggleVisible() {
    this.visible = !this.visible
  }
}
